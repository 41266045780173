<!-- Modal -->
<!-- <div class="modal fade" id="cancelApplicationModal" tabindex="-1" role="dialog" aria-labelledby="cancelApplicationModalLongTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title headerModal" id="exampleModalLabel">{{ 'cancelApplication' | translate }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
                <div class="row">
                  <div class="col-12">
                    <p class="font-weight-light alert alert-warning"><i class="fas fa-exclamation-triangle pr-1"></i>  {{ 'cancelApplicationText' | translate }}</p>
                  </div>
                </div>
      </div>
      <div class="row">
        <div class="col-12 float-right">
          <div class="modal-footer">
            <button type="button" class="btn btnNo" data-dismiss="modal">{{ 'no' | translate }}</button>
            <button type="button" class="btn btnYes" (click)="deleteApplication()">{{ 'yes' | translate }}</button>
          </div>
        </div>
      </div>
</div>
</div>
</div> -->

<div class="modal fade" id="cancelApplicationModal" style="background-color: #000000b3;">
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header modal-md">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-2">
            <img src="./../../../assets/images/talentbase/delete-icon.png">
          </div>
          <div class="col-10">
            <div class="title-design">
              <p style="font-weight:700; color: #54595E;">{{ 'cancelApplicationText' | translate }}</p>
              <div class="row pt-2 pb-3 d-flex justify-content-start">
                <div class="mr-2">
                  <button  type="button" class="btn discard-button" data-dismiss="modal">{{ 'discard' | translate }}</button>
                </div>
                <div>
                  <button (click)="deleteApplication()" type="button" class="btn delete-button" data-dismiss="modal">{{ 'delete' | translate }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
