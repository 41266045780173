<!-- Modal -->
<div class="modal fade" id="adminReviewModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- <div class="modal-header">
      </div> -->
      <div class="modal-body py-0" style="background-color: #F5F6F8;">
        <div *ngIf="review && review.id" class="container">
          <div class="row mt-4">
            <div>
              <div [style.background-color]="review?.imageColor" id="preimage"><span
                  style="color: white;">{{review?.imageText}}</span></div>
            </div>
            <div class="ml-4">
              <span class="comm-name">{{review?.fullName}}</span> <span class="comm-date ml-2">{{review?.createdAt |
                date}} {{review.createdAt | date:'shortTime'}}</span>
              <div class="col-12">
                <div class="row pb-2 pt-1 pl-1">
                  <span [ngClass]="review.rating > 0 ? 'fa fa-star' : 'far fa-star'"></span>
                  <span [ngClass]="review.rating > 1 ? 'fa fa-star' : 'far fa-star'"></span>
                  <span [ngClass]="review.rating > 2 ? 'fa fa-star' : 'far fa-star'"></span>
                  <span [ngClass]="review.rating > 3 ? 'fa fa-star' : 'far fa-star'"></span>
                  <span [ngClass]="review.rating > 4 ? 'fa fa-star' : 'far fa-star'"></span>

                </div>
              </div>

              <div class="col-12 row">
                <span class="comm-text ml-2">
                  {{review?.comment}}
                </span>

              </div>
            </div>

          </div>


        </div>

        <div class="row mt-3 mb-3">
          <div class="ml-auto d-flex justify-content-end">
            <button class="btn btn-control deactive-button mr-2" (click)="clicked(false)">
              {{'reject'|translate}}
            </button>
            <button class="btn btn-control active-button mr-3" (click)="clicked(true)" >
              {{'accept'|translate}}
            </button>
          </div>


        </div>

      </div>

    </div>
  </div>
</div>
