import { Component, OnInit }                                    from '@angular/core';
import { JobModel, JobFilterDataModel }                         from '../../../shared/models';
import { RequestPayload }                                       from '../../../shared/classes';
import { JobService, EmployerService, ApplicationCountService } from '../../../shared/services';
import { JobType }                                              from '../../../shared/enums';

@Component({
             selector: 'app-jobs-invited',
             templateUrl: './jobs-invited.component.html',
             styleUrls: ['./jobs-invited.component.scss']
           })
export class JobsInvitedComponent implements OnInit {

  jobs: JobModel[] = [];
  allJobs: JobModel[] = [];
  count: number;
  requestPayload: RequestPayload;
  filterData: JobFilterDataModel;
  storedItems: any[] = [];
  searchText: string = '';
  sortText: string = '&sort=createdAt,DESC';

  constructor(private jobService: JobService, private employerService: EmployerService,
              private applicationCountService: ApplicationCountService) {
    this.requestPayload = new RequestPayload();
    this.filterData = new JobFilterDataModel();
    this.requestPayload.limit = 5;
  }

  public get jobType(): typeof JobType {
    return JobType;
  }

  ngOnInit(): void {
    this.getJobs('1');
  }

  sorted(sort) {
    this.sortText = sort;
    this.getJobs(1);
  }

  getJobs(pageNo) {
    this.jobService.ServerGetWithText(`/invitation` +
                                        this.requestPayload.payloadURL(
                                          pageNo) + this.sortText + this.requestFilter() + '&filter=status::ACTIVE')
        .subscribe(x => {
          this.jobs = x.content;
          this.storedItems = this.jobs;
          this.searchText != '' ? this.search(this.searchText) : '';
          this.count = x.totalElements;
          this.jobs.forEach(x => {
            let time = Math.abs(new Date().getTime() - new Date(x.createdAt).getTime());
            x.postDate = Math.ceil(time / (1000 * 3600 * 24));
            this.employerService.ServerGetWithText('/' + x.employer.id + '/company-logo')
                .subscribe(a => {
                  x.logoUrl = a.url;

                });
            this.employerService.ServerGetWithText('/' + x.employer.id)
                .subscribe(employerDetails => {
                  x.aboutCompany = employerDetails.about;
                });
            this.applicationCountService.ServerGetWithText('/?jobId=' + x.id)
                .subscribe(a => {
                  x.managerReview = a.managerReview;
                  x.offerStage = a.offerStage;
                  x.pendingReview = a.pendingReview;
                  x.rejected = a.rejectedByEmployer;
                  x.clientReview = a.clientReview;
                  x.interviewStage = a.interviewStage;

                  x.openPosition = x.clientReview + x.managerReview + x.interviewStage + x.offerStage;

                });
          });
        });

  }

  requestFilter() {
    return '&filter=countryId**' + this.filterData.country +
      '&filter=cityId**' + this.filterData.city +
      '&filter=freeText~~' + this.filterData.jobTitle +
      '&filter=type**' + this.filterData.jobType +
      '&filter=functionId**' + this.filterData.jobFunction +
      '&filter=industryId**' + this.filterData.industry;
  }

  getFilteredJobs(e) {
    this.filterData = e;
    this.getJobs(1);

  }

  getClearFilterJobs(e) {
    this.filterData = e;
    this.getJobs(1);

  }

  search(data) {

    if (data == '') {
      this.getJobs(1);
    }

    this.searchText = data;
    // tslint:disable-next-line:max-line-length
    this.jobs = ((this.storedItems).filter((item: JobModel) =>
                                             (((item.employer.name).toLowerCase()
                                                                   .trim()).indexOf(data.toLowerCase()
                                                                                        .trim()) > -1) ||
                                             (((item.title).toLowerCase()
                                                           .trim()).indexOf(data.toLowerCase()
                                                                                .trim()) > -1)
    ));

  }

}
