import { Injectable }  from '@angular/core';
import { of }          from 'rxjs';
import { delay, take } from 'rxjs/operators';

declare let iziToast: any;

@Injectable({
              providedIn: 'root'
            })
export class NotificationService {
  toastMessage = '';

  notificationPosition = 'bottomRight'; // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter, center
  constructor() {
  }

  info(message: string): void {
    iziToast.info({
                    position: this.notificationPosition,
                    message,
                    color: '#0068C6'
                  });
  }

  success(message: any): void {
    iziToast.success({
                       position: this.notificationPosition,
                       message,
                       color: '#4BDE97',
                       imageWidth: 50
                     });
  }

  error(message: string): void {
    if (this.toastMessage !== message) {
      iziToast.error({
                       position: this.notificationPosition,
                       message,
                       color: '#FE2E56'
                     });
      this.toastMessage = message;
      of(true)
        .pipe(
          delay(5000),
          take(1)
        )
        .subscribe(() => {
          this.toastMessage = '';
        });
    }
  }

  warning(message: any): void {
    iziToast.warning({
                       position: this.notificationPosition,
                       message,
                       color: '#FFD760'
                     });
  }
}
