export class DropdownOptionsModel {

  value: any;
  label: string;
  disabled?: boolean;
  hide?: boolean;

    constructor() {
       this.value = '';
       this.label = '';
       this.disabled = false;
       this.hide = false;
    }
}
