import { Component, OnInit } from '@angular/core';
import { DropdownOptionsModel, RecruiterSignUpModel } from 'src/app/shared/models';
import { FunctionalRoleService, IndustryService, NotificationService, RecruiterMeService, server, TranslatePipe } from 'src/app/shared/services';

@Component({
  selector: 'app-recruiter-edit-profile',
  templateUrl: './recruiter-edit-profile.component.html',
  styleUrls: ['./recruiter-edit-profile.component.scss']
})
export class RecruiterEditProfileComponent implements OnInit {

  functionalRoleOptions: DropdownOptionsModel[] = [];
  industryOptions: DropdownOptionsModel[] = [];

  industries: any[] = [];
  roles: any[] = [];
  availableIndustries: any[] = [];
  availableFunctionalRoles: any[] = [];
  recruiterModel: any = {};
  isEdited: boolean = false;
  defaultModel: any = {};

  constructor(private functionalRoleService: FunctionalRoleService, private industryService: IndustryService, private translate: TranslatePipe,
    private notification: NotificationService, private recruiterMeService: RecruiterMeService) { }

  ngOnInit() {

    this.getRecruiter();
  }

  getRecruiter() {
    this.recruiterMeService.ServerGet().subscribe(res => {
      this.recruiterModel = res;
      this.defaultModel = Object.assign({}, this.recruiterModel);
      this.availableIndustries = this.recruiterModel.industries;
      this.availableFunctionalRoles = this.recruiterModel.functionalRoles;
      this.getOptions();
    });
  }


  getOptions() {
    this.functionalRoleService.ServerGetWithoutLogin().subscribe(x => {
      this.roles = x.content;
      this.functionalRoleOptions = this.roles.map(c => ({ value: c.id, label: c.defaultLocalization.name, disabled: this.availableFunctionalRoles.some(x => x.id == c.id) }));
    });

    this.industryService.ServerGetWithoutLogin().subscribe(x => {
      this.industries = x.content;
      this.industryOptions = this.industries.map(c => ({ value: c.id, label: c.defaultLocalization.name, disabled: this.availableIndustries.some(x => x.id == c.id) }));
    });
  }

  selectRole(e, index) {
    this.availableFunctionalRoles[index].id = e.value;
    this.availableFunctionalRoles[index].name = e.label;
    this.functionalRoleOptions = this.roles.map(c => ({ value: c.id, label: c.defaultLocalization.name, disabled: this.availableFunctionalRoles.some(x => x.id == c.id) }));
    this.isEdited = true;
  }

  addRole() {
    if (this.availableFunctionalRoles.length < 5) {
      this.availableFunctionalRoles.push({ id: "", name: "pleaseSelect", disabled: false });
      this.isEdited = true;
    }

  }

  deleteRole(index) {
    if (this.availableFunctionalRoles.length > 1) {
      this.availableFunctionalRoles.splice(index, 1);
      this.functionalRoleOptions = this.roles.map(c => ({ value: c.id, label: c.defaultLocalization.name, disabled: this.availableFunctionalRoles.some(x => x.id == c.id) }));
      this.isEdited = true;
    }

  }

  selectIndustry(e, index) {
    this.availableIndustries[index].id = e.value;
    this.availableIndustries[index].name = e.label;
    this.industryOptions = this.industries.map(c => ({ value: c.id, label: c.defaultLocalization.name, disabled: this.availableIndustries.some(x => x.id == c.id) }));
    this.isEdited = true;
  }

  addIndustry() {
    if (this.availableIndustries.length < 5) {
      this.availableIndustries.push({ id: "", name: "pleaseSelect", disabled: false });
      this.isEdited = true;
    }

  }

  deleteIndustry(index) {
    if (this.availableIndustries.length > 1) {
      this.availableIndustries.splice(index, 1);
      this.industryOptions = this.industries.map(c => ({ value: c.id, label: c.defaultLocalization.name, disabled: this.availableIndustries.some(x => x.id == c.id) }));
      this.isEdited = true;
    }

  }

  updateProfile() {

    let rolePost: any[] = [];
    let industryPost: any[] = [];
    this.availableIndustries.forEach(x => {
      if (x.id && x.id != "") {
        industryPost.push({ id: x.id });
      }
    });
    this.availableFunctionalRoles.forEach(x => {
      if (x.id && x.id != "") {
        rolePost.push({ id: x.id });
      }
    });

    this.recruiterModel.industries = [...industryPost];
    this.recruiterModel.functionalRoles = [...rolePost];

    let recruiterModel: RecruiterSignUpModel;
    let placementCounts: any = <any>{};

    Object.keys(this.recruiterModel.placementCounts).forEach(x => {
      if (this.recruiterModel.placementCounts[x] && (this.recruiterModel.placementCounts[x].permanent != "" || this.recruiterModel.placementCounts[x].temporary != ""))
        placementCounts[x] = <any>{}

      Object.keys(this.recruiterModel.placementCounts[x]).forEach(y => {
        if (this.recruiterModel.placementCounts[x] && this.recruiterModel.placementCounts[x][y] && this.recruiterModel.placementCounts[x][y] != '') {
          placementCounts[x][y] = this.recruiterModel.placementCounts[x][y];
        }
      });
    });

    if (this.recruiterModel.type == "RECRUITMENT_FIRM") {
      recruiterModel = <RecruiterSignUpModel>{
        type: this.recruiterModel.type,
        companyName: this.recruiterModel.companyName,
        primaryContactNumber: this.recruiterModel.primaryContactNumber,
        secondaryContactNumber: this.recruiterModel.secondaryContactNumber && this.recruiterModel.secondaryContactNumber.phoneNumber != ''
          && this.recruiterModel.secondaryContactNumber.phoneNumber != null ? this.recruiterModel.secondaryContactNumber : null, talentbaseReferenceType: this.recruiterModel.talentbaseReferenceType,
        individualPracticeTime: this.recruiterModel.individualPracticeTime,
        requisitionTypes: this.recruiterModel.requisitionTypes,
        paymentReferenceType: this.recruiterModel.paymentReferenceType,
        positionAtCompany: this.recruiterModel.positionAtCompany,
        website: this.recruiterModel.website,
        linkedinAccount: this.recruiterModel.linkedinAccount,
        recruiterCount: this.recruiterModel.recruiterCount,
        firmPracticeTime: this.recruiterModel.firmPracticeTime,
        functionalRoles: this.recruiterModel.functionalRoles,
        industries: this.recruiterModel.industries,
        jobMarkets: this.recruiterModel.jobMarkets,
      };
    }

    else if (this.recruiterModel.type == "OTHER") {
      recruiterModel = <RecruiterSignUpModel>{
        type: this.recruiterModel.type,
        primaryContactNumber: this.recruiterModel.primaryContactNumber,
        secondaryContactNumber: this.recruiterModel.secondaryContactNumber && this.recruiterModel.secondaryContactNumber.phoneNumber != ''
          && this.recruiterModel.secondaryContactNumber.phoneNumber != null ? this.recruiterModel.secondaryContactNumber : null,
          talentbaseReferenceType: this.recruiterModel.talentbaseReferenceType,
        individualPracticeTime: this.recruiterModel.individualPracticeTime,
        requisitionTypes: this.recruiterModel.requisitionTypes,
        paymentReferenceType: this.recruiterModel.paymentReferenceType,
        functionalRoles: this.recruiterModel.functionalRoles,
        industries: this.recruiterModel.industries,
        customType: this.recruiterModel.customType,
        placementCounts: placementCounts

      };
    }

    else {
      recruiterModel = <RecruiterSignUpModel>{
        type: this.recruiterModel.type,
        primaryContactNumber: this.recruiterModel.primaryContactNumber,
        secondaryContactNumber: this.recruiterModel.secondaryContactNumber && this.recruiterModel.secondaryContactNumber.phoneNumber != ''
          && this.recruiterModel.secondaryContactNumber.phoneNumber != null ? this.recruiterModel.secondaryContactNumber : null,
          talentbaseReferenceType: this.recruiterModel.talentbaseReferenceType,
        individualPracticeTime: this.recruiterModel.individualPracticeTime,
        requisitionTypes: this.recruiterModel.requisitionTypes,
        paymentReferenceType: this.recruiterModel.paymentReferenceType,
        functionalRoles: this.recruiterModel.functionalRoles,
        industries: this.recruiterModel.industries,
        placementCounts: placementCounts

      };

      this.recruiterMeService.ServerPut(server.recruiterMe, recruiterModel).subscribe(res => {
        this.notification.success(this.translate.transform('yourProfileHasBeenSuccessfullyUpdated'));
      });
    }
  }

}
