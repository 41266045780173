import { Component, Input, OnInit } from '@angular/core';
import { Router }                   from '@angular/router';
import { TranslatePipe } from '../../services';

@Component({
             selector: 'tlb-empty-page',
             templateUrl: './tlb-empty-page.component.html',
             styleUrls: ['./tlb-empty-page.component.scss']
           })
export class TlbEmptyPageComponent implements OnInit {

  @Input() warningLabel: string = '';
  @Input() small: boolean = false;
  @Input() subText: boolean = false;
  @Input() filterText: string = "";
  @Input() containerStyle = 'height: 95%;';

  filterTextWithTranslate: string = "";
  constructor(private router: Router, private translate: TranslatePipe) {
  }

  ngOnInit(): void {
    if(this.filterText != "") this.filterTextWithTranslate = this.translate.transform('searchNoResultFor') + " " + this.filterText;
  }

  activityNavigate() {
    this.router.navigate(['/activity']);
  }
}
