import { GeneralIdModel } from "../parameter";
import { JobAuthorizedPersonModel } from "./job-authorized-person.model";
import { JobCompensationModel } from "./job-compensation.model";
import { JobExperienceRequiredModel } from "./job-experience-required.model";
import { JobFeeModel } from "./job-fee.model";
import { JobLocationModel } from "./job-location.model";
import { JobQuestionnaireModel } from "./job-questionnaire.model";
import { JobRelocationAssistanceModel } from "./job-relocation-assistance.model";
import { JobVisaRequirementModel } from "./job-visa-requirement.model";

export class JobPostModel {

  title: string;
  description: string;
  positionCount: number;
  classification: any;
  function: any;
  industry: any;
  experienceLevel: string;
  educationLevel: string;
  type: string;
  compensation: JobCompensationModel;
  experienceRequired: JobExperienceRequiredModel;
  relocationAssistance: JobRelocationAssistanceModel;
  visaRequirement: JobVisaRequirementModel;
  location: JobLocationModel;
  atsJobId: string;
  differentNotificationEmail: string;
  authorizedPerson: JobAuthorizedPersonModel;
  fee: any;
  skills: string[];
  users: GeneralIdModel[];
  questionnaire: JobQuestionnaireModel;

constructor() {
  this.title = "";
  this.description = "";
  this.positionCount = null;
  this.classification = null;
  this.function = null;
  this.industry = null;
  this.experienceLevel = "";
  this.educationLevel = "";
  this.type = "";
  this.compensation = new JobCompensationModel();
  this.experienceRequired = new JobExperienceRequiredModel();
  this.relocationAssistance = new JobRelocationAssistanceModel();
  this.visaRequirement = new JobVisaRequirementModel();
  this.location = new JobLocationModel();
  this.atsJobId = "";
  this.differentNotificationEmail = "";
  this.authorizedPerson = new JobAuthorizedPersonModel();
  this.fee = null;
  this.skills = [];
  this.users = [];
  this.questionnaire = new JobQuestionnaireModel;
}
}
