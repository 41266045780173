import { Component, OnInit, ViewChild } from '@angular/core';
import { UserTypeEnum }                 from '../../../shared/enums/user/user-type.enum';
import { AdminPacketTabsEnum }          from './admin-packet-tabs-enum';
import { AdminPacketEditComponent }     from './admin-packet-edit/admin-packet-edit.component';

@Component({
             selector: 'app-admin-packets',
             templateUrl: './admin-packets.component.html',
             styleUrls: ['./admin-packets.component.scss']
           })
export class AdminPacketsComponent implements OnInit {

  selectedUserType = UserTypeEnum.RECRUITER;
  selectedTab = AdminPacketTabsEnum.BusinessFunctions;

  /*@ViewChild(AdminPacketEditComponent) adminPacketEditComponent: AdminPacketEditComponent;*/

  constructor() {

  }

  ngOnInit(): void {
  }

  selectCategory(type: UserTypeEnum): void {
    this.selectedUserType = type;
  }

  get UserTypeEnum(): typeof UserTypeEnum {
    return UserTypeEnum;
  }

  get AdminPacketTabs(): typeof AdminPacketTabsEnum {
    return AdminPacketTabsEnum;
  }

  selectTab(tab: AdminPacketTabsEnum): void {
    this.selectedTab = tab;
  }
}

