<div *ngIf="filterData" class="row">
  <div class="col-12 pr-1 pl-0">
    <div class="card border-0" style="background-color: #F5F6F8;">
      <div class="card-body">
        <div class="card-text row">

          <div class="col-12 col-lg-3 my-2 my-lg-0">
            <button class="search-button" (click)="search()" type="submit"><i class="fa fa-search search-icon"></i></button>
            <input [(ngModel)]="filterText"  #searchInput (keyup)="keyup(searchInput.value)" type="text" placeholder="{{'searchForRecruiterName' | translate}}"
              class="form-control d-inline float-right search-input w-100" style="height: 45px;">
              <br>
             <label *ngIf="filterText != ''" class="clear-search mt-2" for="" (click)="clearSearch()"><i class="fas fa-times mr-1"
              style="color: #707683;" ></i> {{'clearSearch' | translate}}  </label>

          </div>

          <div class="col-lg-6"></div>

          <div class="dropdown drp col-12 col-lg-3  my-2 my-lg-0" id="filer-dropdown">
            <button (click)="filterState = !filterState" class="btn dropdown-menus form-control mb-1 d-inline"
               id="filterButton" type="button">
              <span class="text-center">{{'showAllFilter' | translate}}</span>
            </button>
            <div [style.display]="filterState ? 'block':'none'"
              aria-labelledby="dropdownMenuButton" id="filter-dropdown-menu" class="dropdown-menu col-12 pb-0">


              <div class="dropdown-item color-dropdown-item" id="city">

                <tlb-multiselect-dropdown (selectResult)="selectExperience($event)" [isFilter]="false" [items]="recruitmentExperienceOptions"
                  [dropdownName]="recruitmentExperienceLabel != '' ? recruitmentExperienceLabel : 'recruitmentExperience'" [defaultItemKey]="''" [existItems]="selectedRecruitmentExperiences" [dropDownMenuWidth]="'15rem'"
                  [style]="'text-align: left; border: 1px solid #DCDCDC; box-sizing: border-box; box-shadow: 0px 2px 4px rgb(0 0 0 / 10%); border-radius: 7px; height: 40px;'"
                  [textStyle]="'font-family: Roboto; font-style: normal; font-weight: 500; font-size: 15px; line-height: 16px; letter-spacing: 0.1px; color: #252B42 !important;'">
                </tlb-multiselect-dropdown>
              </div>

              <div class="dropdown-item color-dropdown-item" id="city">

                <tlb-multiselect-dropdown (selectResult)="selectRequisition($event)" [isFilter]="false" [items]="requisitionTypeOptions"
                  [dropdownName]="requisitionTypeLabel != '' ? requisitionTypeLabel : 'requisitionType'" [defaultItemKey]="''" [existItems]="selectedRequisitionType"
                  [style]="'text-align: left; border: 1px solid #DCDCDC; box-sizing: border-box; box-shadow: 0px 2px 4px rgb(0 0 0 / 10%); border-radius: 7px; height: 40px;'"
                  [textStyle]="'font-family: Roboto; font-style: normal; font-weight: 500; font-size: 15px; line-height: 16px; letter-spacing: 0.1px; color: #252B42 !important;'">
                </tlb-multiselect-dropdown>
              </div>

              <div class="dropdown-item color-dropdown-item" id="type">

                <tlb-multiselect-dropdown (selectResult)="selectFunctionalRole($event)" [isFilter]="false" [items]="functionalRoleOptions"
                [dropdownName]="functionalRoleLabel != '' ? functionalRoleLabel : 'functionalRole'" [defaultItemKey]="''"  [existItems]="selectedFunctionalRoles"
                [style]="'text-align: left; border: 1px solid #DCDCDC; box-sizing: border-box; box-shadow: 0px 2px 4px rgb(0 0 0 / 10%); border-radius: 7px; height: 40px;'"
                [textStyle]="'font-family: Roboto; font-style: normal; font-weight: 500; font-size: 15px; line-height: 16px; letter-spacing: 0.1px; color: #252B42 !important;'">
                </tlb-multiselect-dropdown>
              </div>

              <div class="dropdown-item color-dropdown-item" id="type">

                <tlb-multiselect-dropdown (selectResult)="selectIndustry($event)" [isFilter]="false" [items]="industryOptions"
                [dropdownName]="industryLabel != '' ? industryLabel : 'industry'"  [defaultItemKey]="''" [existItems]="selectedIndustries"
                [style]="'text-align: left; border: 1px solid #DCDCDC; box-sizing: border-box; box-shadow: 0px 2px 4px rgb(0 0 0 / 10%); border-radius: 7px; height: 40px;'"
                [textStyle]="'font-family: Roboto; font-style: normal; font-weight: 500; font-size: 15px; line-height: 16px; letter-spacing: 0.1px; color: #252B42 !important;'">
                </tlb-multiselect-dropdown>
              </div>

              <div class="row mr-4" style="justify-content: end;">

                <div class="mt-2 mb-2 mr-1">
                  <button (click)="clearFilterEmit()" class="btn clear-filter-btn" type="button">{{'discard' |
                    translate}}</button>
                </div>
                <div class="mt-2">
                  <button (click)="emitData()" class="btn apply-filter-btn" type="button">{{'apply' |
                    translate}}</button>
                </div>


              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
