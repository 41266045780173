import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../auth';
import { server } from '../server';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RecruiterMeService extends ApiService {

  constructor(public http: HttpClient, public spinner: NgxSpinnerService, public translate: TranslatePipe, public notification: NotificationService) {
    super(http, server.recruiterMe, spinner, translate, notification)
  }

  getType(){
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.get(server.recruiterMe + "/type",  options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      );
  }

  putType(model){
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.put(server.recruiterMe + "/type", model,  options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      );
  }


  getContactInfo(){
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.get(server.recruiterMe + "/contact-info",  options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      );
  }

  putContactInfo(model){
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.put(server.recruiterMe + "/contact-info", model,  options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      );
  }


  getAbout(){
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.get(server.recruiterMe + "/about",  options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      );
  }

  putAbout(model){
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.put(server.recruiterMe + "/about", model,  options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      );
  }



  getCompanyInfo(){
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.get(server.recruiterMe + "/company-info",  options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      );
  }

  putCompanyInfo(model){
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.put(server.recruiterMe + "/company-info", model,  options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      );
  }


  getPaymentType(){
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.get(server.recruiterMe + "/payment-type",  options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      );
  }

  putPaymentType(model){
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.put(server.recruiterMe + "/payment-type", model,  options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      );
  }


  getSpecialization(){
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.get(server.recruiterMe + "/specialization",  options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      );
  }

  putSpecialization(model){
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.put(server.recruiterMe + "/specialization", model,  options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      );
  }

  putProfilePhoto(data: any) {
    this.spinner.show();
    const token = localStorage.getItem(this.TOKEN_KEY);
    let headers: HttpHeaders;
    headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Cache-Control', ' no-cache')
      .set('Accept-Language', this.lang)
      .set('Authorization', 'Bearer ' + token);

    return this.http.put(server.recruiterMe + "/profile-photo", data, {headers})
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        }));
  }

  putPhoneNumber(model){
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.put(server.recruiterMe + "/phone", model,  options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      );
  }
}
