<!-- Modal -->
<div class="modal fade" id="confirmationModal" style="z-index: 1000000000;">
  <div class="modal-dialog modal-md">
    <div class="modal-content">


      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="float-right mr-3 mt-3 fas fa-times" style="color: black;"></i>
      </button>

      <div class="row" style="text-align: center;">
        <div class="col-12">
          <img src="./../../../assets/images/yesornopopup.png">
        </div>
        <div class="col-12 mt-2">
          <div class="title-design">
            <p class="title-text">{{Title|translate}}</p>
            <p class="sub-text">{{Text|translate}}</p>
            <div class="row pt-2 pb-3 d-flex justify-content-center">
              <div class="mr-2">
                <button (click)="selectYesOrNo(false)" type="button" class="btn discard-button" data-dismiss="modal">{{ 'discard' | translate }}</button>
              </div>
              <div>
                <button (click)="selectYesOrNo(true)" type="button" class="btn delete-button" data-dismiss="modal">{{ 'yes' | translate }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
