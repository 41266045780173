import { GeneralIdModel } from "../parameter";

export class JobLocationModel {

  country: any;
  city: any;
  state: string;
  zipCode: string;
  additional: string;
  anyLocation: boolean;
  remote: boolean;

  constructor() {
    this.country = null;
    this.city = null;
    this.state = "";
    this.zipCode = "";
    this.additional = "";
    this.anyLocation = true;
    this.remote = true;
  }
}
