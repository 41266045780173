<div class="container-fluid mt-4">

    <div class="col-12">
      <span class="home-text">{{ 'home' | translate }}</span> <span class="my-jobs-text"> <i class="fas fa-chevron-right ml-3 mr-2"></i> {{ 'myJobs' | translate }} </span>
    </div>
    <br>
    <div class="header col-12 mt-3">
      {{ 'myJobs' | translate }}
    </div>

<hr class="border: 1px solid #C8D4DF !important; w-100" >
    <div class="row mt-3">
      <div class="col-12 col-md-9">

          <div class="header-box" *ngIf="!jobs || jobs.length == 0">
            <span class="set-a-free-job">
              {{ 'setAFreeJob' | translate }}
            </span>
            <br>
            <span class="set-a-free-job-sub">
              {{ 'setAFreeJobSubText' | translate }}
            </span>

            <br>
            <div class="row ml-1">
             <span class="create-first-job-div" style="cursor: pointer;" [routerLink]="['/dashboard/postAJob/null']">
              <i class="fas fa-plus-circle"></i> <span class="create-first-job-test ml-1">{{ 'createYourFirstJob' | translate }}</span>
            </span>
            <span class="learn-more ml-5" [routerLink]="['/support']">
              {{ 'learnMore' | translate }}
            </span>
            </div>

          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
              <app-my-jobs-filter (resultFilter)="getFilteredJobs($event)" (clearFilter)="getClearFilterJobs($event)"
              (resultSearch)="search($event)" (setStatusFilter)="setStatusFilter($event)"></app-my-jobs-filter>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8">

              <app-employer-job-list [jobs]="jobs" [count]="count" [limit]="5"
                                     (getJobListAgain)="ngOnInit()" (setFilter)="setFilterText($event)" [filterType]="filterType"
                (updateJobs)="getJobs($event)" (sorted)="sorted($event)" (updatedStatus)="getJobs(1)"></app-employer-job-list>
            </div>
          </div>



      </div>

      <div class="col-12 col-md-3 pr-5 pb-2 mt-3  img-container">


        <div class="row">
          <div class="col-xs-12 col-sm-12 col-lg-12 col-md-12">
            <img src="./assets/images/email-keep.png">

          </div>

        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-lg-12 col-md-12">
            <div class="centered want-keep-text ml-4"> {{ 'wantKeepUpToDate' | translate }} </div>

            <p class="centered ml-4 get-a-daily-text">{{ 'getADailyEmailOfAllNewJobs' | translate }}</p>

            <input [(ngModel)]="email" type="text"  autocomplete="off" class="ml-4 enter-email-plc"
                   style="background: #FFFFFF; border: 1px solid #D4D4D8; box-sizing: border-box; border-radius: 4px;
            margin-top: -6.5rem; position: absolute; height: 40px;"
                   placeholder="{{'enterYourEmail' | translate}}" name="placement"
            />
          </div>

        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-lg-12 col-md-12">


            <button (click)="sendMail()" class="btn send-btn">{{'send' |
              translate}}</button>
          </div>

        </div>





      </div>


    </div>



</div>
