import {GeneralIdModel, PhoneNumberModel} from '../parameter';

export class SignUpModel {

  accountType: string;
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  password: string;
  industries: GeneralIdModel[];
  termsAgreement: boolean;
  recruiter: boolean;
  employer: boolean;
  phoneNumber: PhoneNumberModel;


  constructor() {
    this.accountType = '';
    this.firstName = '';
    this.lastName = '';
    this.companyName = '';
    this.email = '';
    this.password = '';
    this.industries = [];
    this.termsAgreement = false;
    this.recruiter = false;
    this.employer = false;
    this.phoneNumber = new PhoneNumberModel();
  }
}
