import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RecruiterReviewModel } from 'src/app/shared/models';

@Component({
  selector: 'app-admin-review-modal',
  templateUrl: './admin-review-modal.component.html',
  styleUrls: ['./admin-review-modal.component.scss']
})
export class AdminReviewModalComponent implements OnInit {

  @Input() review: RecruiterReviewModel = null;
  @Output() clickButton = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  clicked(accept){
    this.clickButton.emit(accept);
  }

}
