<div class="modal fade"
     id="applicantsModalLong"
     style="background-color: #000000b3;">
  <div class="modal-dialog modal-lg"
       role="document">
    <div class="modal-content">
      <div class="modal-header modal-lg">
        <button aria-label="Close"
                class="close"
                data-dismiss="modal"
                type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>


      <div class="modal-body">
        <tlb-empty-page *ngIf="!applications || applications.length == 0"
                        [warningLabel]="'No applications yet.'">
        </tlb-empty-page>

        <div *ngIf="applications && applications.length > 0">
          <ng-container *ngFor="let app of applications">
            <div class="card mt-3"
                 style="border: none;">
              <div class="card-body mb-2">

                <div>
                  <div class="row">
                    <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12">
                      <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-1 mb-3 mb-sm-0">
                        <div [style.background-color]="app.imageColor"
                             id="preimage"><span
                          style="color: white;">{{app.imageText}}</span></div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mt-lg-0 mb-lg-0 mt-2 mb-2">
                      <span class="titles">{{app.candidate.fullName}}</span> <br>
                      <span *ngIf="app.candidate.resume && app.candidate.resume.url && app.candidate.resume.url != ''"
                            class="descriptions">
                        <a (click)="openUrl(app.candidate.resume.url)"
                           class="cursor-pointer color-brand">{{'resume' |
                          translate
                          |titlecase}}</a>
                      </span>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 ml-lg-0 ml-md-2 ml-2">
                      <div class="row">
                        <div *ngIf="role=='EMPLOYER_MANAGER' || role=== 'EMPLOYER_USER'"
                             class="mr-1 pb-xl-0 pb-md-1 pb-1">
                          <span *ngIf="app.selectableApplicationStatuses.length==0"
                                class="btn offer-stage cursor-default"><span class="px-2">{{app.status |
                            translate }}</span></span>
                          <button *ngIf="app.selectableApplicationStatuses.length>0"
                                  aria-expanded="false"
                                  class="btn offer-stage"
                                  data-toggle="dropdown"
                                  id="application-status-by-employer-dropdown-menu-button">
                            <span class="mr-2">{{app.status | translate }}</span>
                            <i class="fas fa-chevron-down"></i>
                          </button>
                          <div *ngIf="app.selectableApplicationStatuses.length>0"
                               aria-labelledby="application-status-by-employer-dropdown-menu-button"
                               class="dropdown-menu">
                            <a (click)="changeApplicationStatus(app.id,status)"
                               *ngFor="let status of app.selectableApplicationStatuses"
                               class="dropdown-item cursor-pointer">{{status | translate}}</a>
                          </div>
                        </div>
                        <div *ngIf="role==='RECRUITER_MANAGER' || role=== 'RECRUITER_USER' "
                             class="mr-1 pb-xl-0 pb-md-1 pb-1">
                          <span *ngIf="app.selectableApplicationStatuses.length==0"
                                class="btn offer-stage cursor-default"><span class="px-2">{{app.status |
                            translate }}</span></span>
                          <button *ngIf="app.selectableApplicationStatuses.length>0"
                                  aria-expanded="false"
                                  class="btn offer-stage"
                                  data-toggle="dropdown"
                                  id="application-status-by-recruiter-dropdown-menu-button">
                            <span class="mr-2">{{app.status | translate }}</span>
                            <!-- <i class="fas fa-chevron-down"></i> -->
                          </button>
                          <!-- <div class="dropdown-menu"
                            aria-labelledby="application-status-by-recruiter-dropdown-menu-button"
                            *ngIf="app.selectableApplicationStatuses.length>0">
                            <a class="dropdown-item cursor-pointer"
                              *ngFor="let status of app.selectableApplicationStatuses"
                              (click)="changeApplicationStatus(app.id,status)">{{status | translate}}</a>
                          </div> -->
                        </div>
                        <div *ngIf="role=='ADMIN'"
                             class="mr-1 pb-xl-0 pb-md-1 pb-1">
                          <span class="btn offer-stage cursor-default"><span class="px-2">{{app.status | translate
                            }}</span></span>
                        </div>
                        <div>
                          <button (click)="openCandidateDetail(app)"
                                  class="btn view-candidate-detail">{{'viewCandidateDetail' | translate }}</button>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <br>

                <!-- <div class="row ml-2">
                  <div class="mr-5 mt-md-0 mt-2">
                    <div class="loc-type-div mr-5">
                      <i class="far fa-envelope mr-2"></i><span class="loc-type-text">{{app.candidate.email}}</span>
                    </div>

                  </div>
                  <div class="ml-xl-5 ml-md-5 ml-0 mt-md-0 mt-2">
                    <div class="loc-type-div ml-xl-5 ml-md-5 ml-0">
                      <i class="fas fa-phone mr-2"></i> <span
                      class="loc-type-text">{{app.candidate.phoneNumber.countryCallingCode}}
                      {{app.candidate.phoneNumber.phoneNumber}}</span>

                    </div>
                  </div>
                </div> -->
                <div class="row">
                  <div  class="col-xl-6 col-md-6 col-12">
                    <div class="loc-type-div">
                      <i class="far fa-envelope mr-2"></i><span class="loc-type-text">{{app.candidate.email}}</span>
                    </div>
                  </div>
                  <div  class="col-xl-6 col-md-6 col-12 mt-md-0 mt-2">
                    <div class="loc-type-div">
                      <i class="fas fa-phone mr-2"></i> <span
                      class="loc-type-text">{{app.candidate.phoneNumber.countryCallingCode}}
                      {{app.candidate.phoneNumber.phoneNumber}}</span>
                  </div>
                  </div>



                </div>



              </div>
            </div>


            <div class="card  mt-2"
                 style="border: none;">
              <div class="card-body">
                <div class="row"
                     style="padding: 10px 18px;">
                  <div class="col-xl-8 col-12">
                    <div class="row">

                      <div class="col-6">
                        <p class="titles">{{ 'employer' | translate }}:</p>
                        <p class="titles">{{ 'jobAppliedByYou' | translate }}:</p>
                        <p class="titles">{{ 'appliedOn' | translate }}:</p>
                        <p class="titles">{{ 'jobType' | translate }}:</p>
                        <p class="titles">{{ 'location' | translate }}:</p>


                      </div>
                      <div class="col-6">
                        <p class="descriptions">{{app.employer.name}}</p>
                        <p class="descriptions">{{app.job.name}}</p>
                        <p class="descriptions">{{app.createdAt | date}}</p>
                        <p class="descriptions">{{app.jobType | translate}}</p>
                        <p class="descriptions">
                          {{app.location?.city.name}},
                          {{app.location?.country.name}}
                        </p>


                      </div>

                    </div>


                    <div *ngIf="role === 'RECRUITER_MANAGER' || role === 'RECRUITER_USER'">

                      <button (click)="changeApplicationStatus(app.id, 'CANCELLED')"
                              class="button cancel-btn2">{{
                        'cancelApplication' | translate }}</button>

                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="app.openedCandidateDetail">


              <div *ngIf="app.answers && app.answers.length > 0"
                   class="card mt-2"
                   style="border: none;">

                <div class="card-body">
                  <div class="row"
                       style="padding: 10px 18px;">
                    <div class="col-12">
                      <div class="headers">
                        {{'preScreenQuestions' | translate}}
                      </div>
                    </div>

                  </div>
                  <div *ngFor="let answer of app.answers"
                       class="row mt-2 quest-boxes">
                    <div class="col-12">
                      <div class="quest">
                        {{ answer.question.name }}

                      </div>

                      <div>
                        <span class="answer-name ml-3">{{ app.imageText }}: </span> <span class="answer-name"
                                                                                          style="color: #162A4C;"> {{ answer.content }}</span>

                      </div>

                    </div>

                  </div>

                </div>

              </div>


              <div *ngIf="app.candidate && app.candidate.coverLetter &&  app.candidate.coverLetter.content != ''"
                   class="card mt-2"
                   style="border: none;">

                <div class="card-body">
                  <div class="row"
                       style="padding: 10px 18px;">
                    <div class="col-12">
                      <div class="headers">
                        {{'coverLetter' | translate}}
                      </div>
                    </div>

                  </div>
                  <div class="row mt-2 cover-letter-box">
                    <div class="col-12">
                      <div class="cover-letter-text mt-2">
                        {{ app.candidate.coverLetter.content }}

                      </div>

                    </div>

                  </div>

                </div>

              </div>

              <div *ngIf="app.candidate && app.candidate.coverLetter &&  app.candidate.coverLetter.url ||
        app.candidate && app.candidate.resume &&  app.candidate.resume.url"
                   class="card mt-2"
                   style="border: none;">

                <div class="card-body">
                  <div class="row"
                       style="padding: 10px 18px;">
                    <div class="col-12">
                      <div class="headers">
                        {{'attachment' | translate}}
                      </div>
                    </div>

                  </div>
                  <div *ngIf="app.candidate.coverLetter.url!=null"
                       class="col-12">
                    <a (click)="openUrl(app.candidate.coverLetter.url)"
                       class="cursor-pointer color-brand">{{'openCoverLetter' |
                      translate |titlecase}}</a>
                  </div>

                  <div *ngIf="app.candidate.resume.url!=null"
                       class="col-12">
                    <a (click)="openUrl(app.candidate.resume.url)"
                       class="cursor-pointer color-brand">{{'openResume' |
                      translate
                      |titlecase}}</a>
                  </div>

                </div>

              </div>

            </div>


            <div *ngIf="role == 'EMPLOYER_MANAGER' || role=== 'EMPLOYER_USER'">
              <div class="card mt-2"
                   style="border: none;">

                <div class="card-body">
                  <div class="row"
                       style="padding: 10px 18px;">
                    <div class="col-12">
                      <div class="headers">
                        {{'applicationNotes' | translate}}
                      </div>

                      <div>
                        <button (click)="addEditNote(null,app.id)"
                                class="btn btn-outline btn-md mt-2 mr-4 mb-2"
                                id="button-son"
                                style="border: 1px solid #162A4C; float: right;"
                                type="button">
                          <span class="answer-name"
                                id="yaz"
                                style="color: #162A4C;;"><i class="fas fa-plus"
                                                            style="color: #162A4C;"></i> {{'addNote' |
                            translate}}</span></button>

                      </div>
                    </div>
                  </div>
                  <div *ngIf="app.notes && app.notes.length > 0 && app.notes  as notes "
                       class="notes-box">
                    <tlb-empty-page *ngIf="!notes || notes.length == 0"
                                    [warningLabel]="'noNotesYet' | translate"></tlb-empty-page>
                    <div *ngFor="let note of notes"
                         class="note-container">
                      <div class="note-content">
                        <div class="note-title">
                          {{note.title}}
                        </div>
                        <div class="note">
                          <span class="mr-3"></span>
                          {{note.note}}
                        </div>
                      </div>
                      <div class="note-actions">
                        <img (click)="addEditNote(note)"
                             alt=""
                             src="assets/images/talentbase/icons/pencil.png">
                        <img (click)="deleteNote(note)"
                             alt=""
                             src="assets/images/talentbase/icons/trash.png">
                      </div>
                    </div>
                  </div>
                  <div *ngIf="app.notes && app.notes.length > 0 && false">
                    <div *ngFor="let note of app.notes"
                         class="row mt-2 quest-boxes">
                      <div class="col-12">
                        <div class="row">
                          <div class="quest col-10">
                            {{ note.title }}
                          </div>

                          <div class="col-2">
                            <i (click)="addEditNote(note)"
                               class="far fa-edit"></i>
                            <i (click)="deleteNote(note)"
                               class="far fa-trash-alt ml-2"></i>
                          </div>

                        </div>

                        <div>
                          <span class="answer-name"
                                style="color: #162A4C;"> {{ note.note }}</span>

                        </div>

                      </div>

                    </div>
                  </div>

                  <tlb-empty-page *ngIf="!app.notes || app.notes.length == 0"
                                  [small]="true"
                                  [subText]="false"
                                  [warningLabel]="'noSavedNotesYet' | translate"></tlb-empty-page>

                </div>

              </div>

            </div>

          </ng-container>
        </div>

      </div>


      <div class="row">

        <div class="col-12 ">

          <div class="modal-footer">
            <button class="btn cancel-btn"
                    data-dismiss="modal"
                    type="button">{{ 'close' | translate }}</button>
            <button class="btn ok-btn m-2"
                    data-dismiss="modal"
                    type="button">{{ 'ok' |
              translate }}</button>
          </div>

        </div>
      </div>


    </div>
  </div>
</div>


<app-add-edit-application-note (updateOrSaveClick)="this.getNotes($event)"
                               [applicationId]="applicationId"
                               [changed]="changed"
                               [selectedNote]="selectedNote">
</app-add-edit-application-note>
