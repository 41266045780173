import { Component, OnInit, Input, Output, EventEmitter }           from '@angular/core';
import { CreateAvatar }                                             from '../../../../../shared/classes';
import { RecruiterService }                                         from '../../../../../shared/services';
import { RecruitersWithRecommendationModel, AllRecruiterInfoModel } from '../../../../../shared/models/recruiter/recruiters-with-recommendation-model';

declare var $: any;

@Component({
             selector: 'app-recruiter-list',
             templateUrl: './recruiter-list.component.html',
             styleUrls: ['./recruiter-list.component.scss']
           })
export class RecruiterListComponent implements OnInit {

  activeTab: RecruiterListTabEnum = RecruiterListTabEnum.AllRecruiters;
  recruitersWithRecommendation: RecruitersWithRecommendationModel = new RecruitersWithRecommendationModel();
  createAvatar: CreateAvatar;
  @Input() selectedRecruiters: AllRecruiterInfoModel[] = [];
  @Output() selectDone = new EventEmitter<AllRecruiterInfoModel[]>();
  listSize = 10;
  searchText = '';
  sortBy = '';

  constructor(
    private recruiterService: RecruiterService
  ) {
    this.createAvatar = new CreateAvatar();
  }

  ngOnInit(): void {
    this.getRecruiters();
  }

  get RecruiterListTabEnum(): typeof RecruiterListTabEnum {
    return RecruiterListTabEnum;
  }

  changeTab(tab: RecruiterListTabEnum): void {
    this.activeTab = tab;
    this.getRecruiters();
  }

  selectRecruiter(recruiter: AllRecruiterInfoModel): void {
    const index = this.selectedRecruiters.indexOf(recruiter);
    if (index === -1) {
      recruiter.isChecked = true;
      this.selectedRecruiters.push(recruiter);
      return;
    }
    this.selectedRecruiters.splice(index, 1);
    recruiter.isChecked = false;
  }

  private getRecruiters(): void {
    /*    this.recruiters.forEach((recruiter) => {
        });*/
    this.recruiterService.ServerGetWithText(this.getRecruiterParameters())
        .subscribe(x => {
          this.recruitersWithRecommendation = x;
          this.recruitersWithRecommendation.recruiters.forEach(recruiter => {
            recruiter.imageText = this.createAvatar.create(recruiter.user.fullName);
            recruiter.imageColor = this.createAvatar.color();
          });
          this.recruitersWithRecommendation.recruiterPagination.content.forEach(recruiter => {
            recruiter.imageText = this.createAvatar.create(recruiter.user.fullName);
            recruiter.imageColor = this.createAvatar.color();
          });
          this.selectedRecruiters.forEach(selectedRecruiter => {
            this.recruitersWithRecommendation.recruiters.forEach(recruiter => {
              if (recruiter.id === selectedRecruiter.id) {
                recruiter.isChecked = true;
              }
            });
            this.recruitersWithRecommendation.recruiterPagination.content.forEach(recruiter => {
              if (recruiter.id === selectedRecruiter.id) {
                recruiter.isChecked = true;
              }
            });
          });
        });
  }

  scrollEventForList(event): void {
    const scrollTop = document.getElementById('container-recruiter-list-items-all-recruiters').scrollTop;
    const scrollHeight = document.getElementById('container-recruiter-list-items-all-recruiters').scrollHeight; // added
    const offsetHeight = document.getElementById('container-recruiter-list-items-all-recruiters').offsetHeight;
    // var clientHeight = document.getElementById('box').clientHeight;
    const contentHeight = scrollHeight - offsetHeight; // added
    if (contentHeight <= scrollTop) // modified
    {
      if (this.listSize < this.recruitersWithRecommendation.recruiterPagination.totalElements) {
        this.listSize += 10;
        this.getRecruiters();
      }
    }
  }

  searchChangeEvent(): void {
    if (this.searchText.length >= 3 || this.searchText.length === 0) {
      this.getRecruiters();
    }
  }

  getRecruiterParameters(): string {
    let parameters = `?page=0&size=${this.listSize}`;
    if (this.searchText.length >= 3) {
      parameters += `&filter=name~~${this.searchText}`;
    }
    if (this.sortBy !== '') {
      const order = this.sortBy === 'highestFirst' ? 'asc' : 'desc';
      parameters += `&sort=rating,${order}`;
    }
    if (this.activeTab === RecruiterListTabEnum.MyFavorites) {
      parameters += `&filter=favorite::true`;
    }
    return parameters;
  }

  sortByChange(): void {
    this.getRecruiters();
  }

  removeFromInvitedRecruiters(recruiter: AllRecruiterInfoModel): void {
    recruiter.isChecked = false;
    const index = this.selectedRecruiters.indexOf(recruiter);
    this.selectedRecruiters.splice(index, 1);
  }

  confirm(): void {
    this.selectDone.emit(this.selectedRecruiters);
    $('#recruiterListModal')
      .modal('toggle');
  }

  cancel(): void {
    $('#recruiterListModal')
      .modal('toggle');
  }
}

export enum RecruiterListTabEnum {
  AllRecruiters = 'allRecruiters',
  MyFavorites = 'myFavorites'
}
