import { GeneralIdModel } from "../parameter";

export class JobVisaRequirementModel {

  visas: any[];
  additional: string;

 constructor() {
   this.visas = [];
   this.additional = "";
 }
 }
