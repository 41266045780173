import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { JobType } from 'src/app/shared/enums';
import { CountryModel, DropdownOptionsModel, JobFilterDataModel, JobFunctionModel } from 'src/app/shared/models';
import { CountryService, FunctionalRoleService } from 'src/app/shared/services';

@Component({
  selector: 'app-admin-jobs-filter',
  templateUrl: './admin-jobs-filter.component.html',
  styleUrls: ['./admin-jobs-filter.component.scss']
})
export class AdminJobsFilterComponent implements OnInit {

  countries: CountryModel[] = [];
  countryOptions: DropdownOptionsModel[] = [];
  countryLabel: string = "";
  filterData: JobFilterDataModel;
  filterState: boolean;
  jobTypeOptions: DropdownOptionsModel[] = [];
  jobTypeLabel: string = "";
  jobFunctions: JobFunctionModel[] = [];
  jobFunctionLabel: string = "";
  selectedJobTypes: any[] = [];
  selectedFunctions: JobFunctionModel[] = [];

  @Output() resultFilter = new EventEmitter();
  @Output() clearFilter = new EventEmitter();

  constructor(private countryService: CountryService, private functionalRoleService: FunctionalRoleService) {
    this.filterData = new JobFilterDataModel();
  }

  ngOnInit(): void {
    this.getOptions();
  }


  clearFilterEmit() {
    this.filterData.jobFunction = "";
    this.filterData.jobType = "";
    this.filterData.country = "";

    this.getOptions();
    this.clearFilter.emit(this.filterData);
  }


  getOptions() {
    this.filterData.jobFunction = "";
    this.filterData.jobType = "";
    this.filterData.country = "";

    this.countryLabel = "";
    this.jobTypeLabel = "";
    this.jobFunctionLabel = "";

    this.countryService.ServerGetWithoutLogin().subscribe(x => {
      this.countries = x.content;
      this.countryOptions = this.countries.map(c => ({ value: c.id, label: c.defaultLocalization.name }));
    });

    this.functionalRoleService.ServerGetWithoutLogin().subscribe(x => {
      this.jobFunctions = x.content;
    });

    this.jobTypeOptions = Object.keys(JobType).map(c => ({ value: c, label: c }));
  }



  selectCountry(data) {
    this.filterData.country = data.value;
    this.countryLabel = data.label;
  }


  selectType(datas) {
    this.filterData.jobType = '';
    for (const data of datas) {
      if (this.filterData.jobType === '') {
        this.filterData.jobType = data;
      } else {
        this.filterData.jobType = this.filterData.jobType + ',' + data;
      }
    }
    this.jobTypeLabel = datas.length > 0 ? datas.length + " selected" : "jobType";
    this.selectedJobTypes = [...datas];
  }

  selectFunction(datas) {
    this.filterData.jobFunction = '';
    for (const data of datas) {
      if (this.filterData.jobFunction === '') {
        this.filterData.jobFunction = data.id;
      } else {
        this.filterData.jobFunction = this.filterData.jobFunction + ',' + data.id;
      }
    }
    this.jobFunctionLabel = datas.length > 0 ? datas.length + " selected" : "jobFunction";
    this.selectedFunctions = [...datas];
  }


  emitData() {
    this.filterState = false;
    this.resultFilter.emit(this.filterData);

  }



}
