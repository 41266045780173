import { Component, OnInit } from '@angular/core';
import { RequestPayload } from 'src/app/shared/classes';
import { ApplicationModel, DropdownOptionsModel, JobFilterDataModel, JobModel } from 'src/app/shared/models';
import { ApplicationService, JobService, TranslatePipe } from 'src/app/shared/services';

@Component({
  selector: 'app-admin-all-jobs',
  templateUrl: './admin-all-jobs.component.html',
  styleUrls: ['./admin-all-jobs.component.scss']
})
export class AdminAllJobsComponent implements OnInit {

  jobs: JobModel[] = [];
  count: number;
  requestPayload: RequestPayload;
  currentPage: number;
  applications: ApplicationModel[] = [];
  selectedApplications: ApplicationModel[] = [];
  filterText: string = "";
  storedItems: any[] = [];
  filterData: JobFilterDataModel;

  sortLabel: string = this.translate.transform('newest');
  sortOptions: DropdownOptionsModel[] = [
    { label: this.translate.transform('newest'), value: '&sort=createdAt,DESC' },
    { label: this.translate.transform('oldest'), value: '&sort=createdAt,ASC' }
  ];
  sortText: string = "&sort=createdAt,DESC";
  fromSearch: boolean = false;
  selectedJob: any = null;

  constructor(private applicationService: ApplicationService, private jobService: JobService, private translate: TranslatePipe) {
    this.requestPayload = new RequestPayload();
    this.requestPayload.limit = 5;
    this.filterData = new JobFilterDataModel();
  }
  ngOnInit(): void {
    this.getApplications();

  }

  requestFilter() {
    return '&filter=countryId**' + this.filterData.country +
      '&filter=type**' + this.filterData.jobType +
      '&filter=functionId**' + this.filterData.jobFunction+
      '&filter=title~~' + this.filterText
  }

  setFilter(data){

    this.filterData = data;
    this.getJobs(1);

  }

  selectSort(data) {
    this.sortLabel = data.label;
    this.sortText = data.value;

    this.getJobs(1);
  }

  getApplications() {
    this.applicationService.ServerGet().subscribe(x => {
      this.applications = x.content;
      this.applications.forEach(f=> {
        f.date = new Date(f.createdAt).toLocaleDateString();
      });
      this.getJobs(1);
    });
  }

  getJobs(pageNo) {
    this.jobService.ServerGetWithText(this.requestPayload.payloadURL(pageNo) + this.requestFilter() + this.sortText).subscribe(x => {
      this.fromSearch ? this.currentPage = 1 : null;
      this.fromSearch = false;
      this.jobs = x.jobPagination.content as JobModel[];
      this.count = x.jobPagination.totalElements;
      this.jobs.forEach(x => {
        let time = Math.abs(new Date().getTime() - new Date(x.createdAt).getTime());
        x.postDate = Math.ceil(time / (1000 * 3600 * 24));
        x.pendingReview = this.applications.some(s=> (s.job.id == x.id) && (s.status != "CANCELLED") && (s.status != "REJECTED_BY_CANDIDATE") &&
        (s.status != "REJECTED_BY_EMPLOYER") && (s.status != "HIRED")) ? this.applications.filter(s=> (s.job.id == x.id) && (s.status != "CANCELLED")
        && (s.status != "REJECTED_BY_CANDIDATE") && (s.status != "REJECTED_BY_EMPLOYER") && (s.status != "HIRED")).length : 0;
        x.applications = this.applications.some(s=> (s.job.id == x.id) && (s.status != "CANCELLED") && (s.status != "REJECTED_BY_CANDIDATE") &&
        (s.status != "REJECTED_BY_EMPLOYER") && (s.status != "HIRED")) ? this.applications.filter(s=> (s.job.id == x.id) && (s.status != "CANCELLED")
        && (s.status != "REJECTED_BY_CANDIDATE") && (s.status != "REJECTED_BY_EMPLOYER") && (s.status != "HIRED")) : [];

        x.applications.forEach(f=>{
          f.location = x.location;
          f.jobType = x.type;
        });

        let allApplications = this.applications.some(s=> s.job.id == x.id) ? this.applications.filter(s=> s.job.id == x.id).length : 0;
        x.totalApplications = allApplications;
        x.underConsideration = x.applications.filter(a=> a.status == "CLIENT_REVIEW" || a.status == "MANAGER_REVIEW").length;
      });
    });
  }

  openApplicantsModal(job) {
    this.selectedApplications = job.applications.filter(x=> x.status != "CANCELLED");

    // @ts-ignore
    $('#applicantsModalLong').modal('show');
  }

  openFullJobDescription(job){
    this.selectedJob = job;
    // @ts-ignore
    $('#jobDetailFromAdmin').modal('show');
  }

   search() {

    this.fromSearch = true;
    this.getJobs(1);
}

clearSearch(){
  this.filterText = "";
  this.search();
}

}
