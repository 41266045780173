import { GeneralIdNameModel }    from '../general';
import { CurrencyModel }         from '../parameter';
import { BusinessFunctionModel } from './business-function-model';
import { CommissionModel }       from './commission-model';
import { UserTypeEnum }          from '../../enums/user/user-type.enum';
import { PackageCycleEnum }      from './package-cycle.enum';

export class Package {
  createdAt?: Date;
  createdBy?: GeneralIdNameModel;
  updatedAt?: Date;
  updatedBy?: GeneralIdNameModel;
  id?: string;
  name?: string;
  type?: UserTypeEnum;
  status?: string;
  description?: string;
  subDescription?: string;
  free?: boolean;
  planId?: null | string;
  planName?: null | string;
  trialInDays?: number | null;
  price?: number | null;
  currency?: GeneralIdNameModel | CurrencyModel | null;
  cycle?: null | PackageCycleEnum;
  screenOrder?: number;
  level?: number;
  commission?: CommissionModel | null;
  usageRestrictions?: UsageRestriction[];
  businessFunctions?: GeneralIdNameModel[] | BusinessFunctionModel[];
  totalElements?: number;

  constructor() {
    this.createdAt = new Date();
    this.createdBy = new GeneralIdNameModel();
    this.updatedAt = new Date();
    this.updatedBy = new GeneralIdNameModel();
    this.id = '';
    this.name = '';
    this.type = UserTypeEnum.RECRUITER;
    this.status = '';
    this.description = '';
    this.subDescription = '';
    this.free = false;
    this.planId = '';
    this.planName = '';
    this.trialInDays = null;
    this.price = null;
    this.currency = new GeneralIdNameModel();
    this.cycle = null;
    this.screenOrder = 0;
    this.level = 0;
    this.commission = new CommissionModel();
    this.businessFunctions = [];
    this.usageRestrictions = [];
    this.totalElements = 0;
  }

}

export class UsageRestriction {
  functionalRole?: string;
  countLimit?: number | null;
  initialCountLimit  = 20;

  /* unLimited?: boolean ;*/
}
