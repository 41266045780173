<!-- Modal -->
<div aria-hidden="true" aria-labelledby="searchModal" class="modal fade modal-backdrop" id="searchModal" role="dialog"
  style="z-index: 1000000000;" tabindex="-1">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content" style="background:none; border: none;">
      <div class="modal-header pb-0" style="border: none;">
        <div class="input-group inputDiv w-100">
          <input (keyup.enter)="search()" [(ngModel)]="searchModel.value" aria-describedby="basic-addon2" autofocus
            id="search-input" class="form-control inputSize w-100" placeholder="{{'search' | translate}}"
            maxlength="128" style="height: 75px;" type="text"
            [style.border-radius]="(!searchDatas || searchDatas.length==0)?'10px':'10px 10px 0 0'"
            [ngClass]="{'is-invalid':(isLessThanMinimum && isSearchClick)}" autofocus>
          <div class="input-group-append position-absolute input-cls">
            <span (click)="search()" class="input-group-text cursor-pointer" id="basic-addon2"
              style="border-top-right-radius: 25px;border-bottom-right-radius: 25px; ">
              <span class="mr-2" *ngIf="(isLessThanMinimum && isSearchClick)" style="color:red">{{ 'leastThreeCharacter'
                | translate }}</span>
              <i class="fas fa-search"></i></span>
          </div>

        </div>
      </div>

      <div class="modal-body pt-0">
        <ul *ngIf="searchDatas && searchDatas.length>=1" class="bg-white px-5 py-3 searchResult"
          style="overflow-y: auto;">
          <div class="headers mt-2">
            {{ 'imLookingFor' | translate }}
          </div>

          <div class="row mt-3">
            <div *ngFor="let cat of categories">
              <span class="ml-2" (click)="selectCategory(cat)"
                [ngClass]="selectedCategories && selectedCategories.indexOf(cat) > -1 ? 'category-badge-active' : 'category-badge'">
                <span class="category-text"><i [class]="cat.icon" class="mr-2"></i>{{ "SRC" + cat.name |
                  translate}}s</span> </span>
            </div>
          </div>


          <div class="headers mt-4">
            {{ 'searchResult' | translate }}
          </div>
          <div class="mt-3 ml-2" *ngFor="let searchData of results">
            <div class="row">
              <div class="col-10 result-text" (click)="openDetailModal(searchData)">{{searchData.text}}</div>
              <div class="col-2 result-type">{{ 'SRC' + searchData.type | translate}}</div>
            </div>
          </div>
        </ul>
        <div *ngIf="(!searchDatas || searchDatas.length<=0) && (!isLessThanMinimum && isSearchClick)">
          <ul class="bg-white px-5 py-3 searchResult" style="overflow-y: auto;font-style: italic;list-style: none;">
            <li class="mt-2 mb-2 search-item" style="text-decoration: none;">
              {{'searchNoResult' | translate}}&nbsp;{{searchKey}}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<app-employer-job-detail-popup [modalId]="'jobDetailFromGlobalSearch'" [job]="job"></app-employer-job-detail-popup>

<app-edit-candidate [modalId]="'editCandidateModalFromGlobalSearch'" [candidateModel]="candidate"></app-edit-candidate>

<app-admin-employer-modal [employer]='employer'></app-admin-employer-modal>

<app-user-modal [modalId]="'userModalFromGeneralSearch'" [selectedUser]="user"></app-user-modal>

<app-admin-recruiter-detail-popup [modalId]="'adminRecruiterDetailModalFromGlobalSearch'" [recruiter]='recruiter'>
</app-admin-recruiter-detail-popup>
