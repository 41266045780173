import { Component, Input, OnInit } from '@angular/core';
import { RequestPayload } from 'src/app/shared/classes';
import { JobType } from 'src/app/shared/enums';
import { CreditsModel, JobFilterDataModel, JobModel, NewsModel } from 'src/app/shared/models';
import { ApplicationCountService, CandidateCountService, CreditService, EmployerService, JobService } from 'src/app/shared/services';

@Component({
  selector: 'app-recruiter-dashboard',
  templateUrl: './recruiter-dashboard.component.html',
  styleUrls: ['./recruiter-dashboard.component.scss']
})
export class RecruiterDashboardComponent implements OnInit {

  @Input() news: NewsModel[] = [];
  jobs: JobModel[] = [];
  count: number;
  requestPayload: RequestPayload;
  filterData: JobFilterDataModel;
  recentNews: any[] = [];
  new: NewsModel = new NewsModel;
  credits: CreditsModel[] = [];
  totalCredits: number = 0;
  spentCredits: number = 0;
  applicationQuotaUsed: number;
  applicationQuotaUsedPercent: number;
  applicationQuotaRemaining: number;
  storedItems: any[] = [];
  searchText: string = "";
  sortText: string= "&sort=createdAt,DESC";

  constructor(private candidateCountService: CandidateCountService, private creditService: CreditService,
    private jobService: JobService, private employerService: EmployerService, private applicationCountService: ApplicationCountService) {
    this.requestPayload = new RequestPayload();
    this.filterData = new JobFilterDataModel();
    this.requestPayload.limit = 3;
  }

  ngOnInit(): void {

    this.getCredits();
    this.getCounts();
    this.getJobs('1');
  }

  getCounts() {
    this.candidateCountService.ServerGet().subscribe(x => {
      this.applicationQuotaUsed = x.applicationQuotaUsed;
      this.applicationQuotaRemaining = x.applicationQuotaRemaining;
      this.applicationQuotaUsedPercent = x.applicationQuotaUsed / (x.applicationQuotaUsed + x.applicationQuotaRemaining) * 100;

    });
  }

  sorted(sort){
    this.sortText = sort;
    this.getJobs(1);

  }

  getCredits() {
    this.creditService.ServerGet().subscribe(x => {
      this.credits = x.content;
      this.totalCredits = this.credits.reduce((a, b) => a + b.amount, 0);
      let spent = this.credits.filter(f => f.amount < 0).reduce((a, b) => a + b.amount, 0);
      let beforeSpent = this.credits.filter(f => f.amount > 0).reduce((a, b) => a + b.amount, 0);
      this.spentCredits = 100 * Math.abs(spent) / beforeSpent;
    });
  }

  getJobs(pageNo) {
    this.jobService.ServerGetWithText(this.requestPayload.payloadURL(pageNo) + this.sortText + this.requestFilter()).subscribe(x => {
      this.jobs = x.jobPagination.content;
      this.count = x.jobPagination.totalElements;
      this.storedItems = this.jobs;
      this.searchText != "" ? this.search(this.searchText) : "";
      this.jobs.forEach(x => {
        let time = Math.abs(new Date().getTime() - new Date(x.createdAt).getTime());
        x.postDate = Math.ceil(time / (1000 * 3600 * 24));
        this.employerService.ServerGetWithText("/" + x.employer.id + "/company-logo").subscribe(a => {
          x.logoUrl = a.url;

        });

        this.employerService.ServerGetWithText('/' + x.employer.id).subscribe(employerDetails => {
          x.aboutCompany = employerDetails.about;
        });

        this.applicationCountService.ServerGetWithText('/?jobId=' + x.id).subscribe(a => {
          x.managerReview = a.managerReview;
          x.offerStage = a.offerStage;
          x.pendingReview = a.pendingReview;
          x.rejected = a.rejectedByEmployer;
          x.clientReview = a.clientReview;
          x.interviewStage = a.interviewStage;
          x.openPosition = x.clientReview + x.managerReview + x.interviewStage + x.offerStage

        });
      });
    });

  }

  requestFilter() {
    return '&filter=countryId**' + this.filterData.country +
      '&filter=cityId**' + this.filterData.city +
      '&filter=title~~' + this.filterData.jobTitle +
      '&filter=freeText~~' + this.filterData.freeText +
      '&filter=type**' + this.filterData.jobType +
      '&filter=functionId**' + this.filterData.jobFunction +
      '&filter=industryId**' + this.filterData.industry +
      '&filter=favorite::' + true
  }

  getFilteredJobs(e) {
    this.filterData = e;
    this.getJobs(1);
  }

  public get jobType(): typeof JobType {
    return JobType;
  }

  getClearFilterJobs(e) {
    this.filterData = e;
    this.getJobs(1);
  }

  openModalNew(e) {
    this.new = e;

    // @ts-ignore
    $('#modalNews').modal('show');
  }

  search(data) {
    this.searchText = data;

    if (data == "") this.getJobs(1);
    // tslint:disable-next-line:max-line-length
    this.jobs = ((this.storedItems).filter((item: JobModel) =>
      (((item.employer.name).toLowerCase().trim()).indexOf(data.toLowerCase().trim()) > -1) ||
      (((item.title).toLowerCase().trim()).indexOf(data.toLowerCase().trim()) > -1)
    ));
  }

}
