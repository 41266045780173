export class UserEmailPreferencesModel {

  applicationStatusChanged: boolean;
  newJobCreated: boolean;
  recruitingTipsEtc: boolean;
  webinarsAndIntakeCalls: boolean;

  constructor() {
    this.applicationStatusChanged= true;
    this.newJobCreated= true;
    this.recruitingTipsEtc= true;
    this.webinarsAndIntakeCalls= true;

  }
}



