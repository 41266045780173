<div class="container-fluid mt-4">
  <div class="row">

    <div class="col-12">

      <div class="top-page mt-2 mb-4">
        <div class="header">
          {{ 'allJobs' | translate }}
        </div>
        <div class="sub-header mt-2 mb-4">
          {{ 'allJobsListing' | translate }}
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-6 col-md-12 col-xl-3">
                <button class="search-button"  (click)="search()" type="submit"><i class="fa fa-search search-icon"></i></button>
                <input [(ngModel)]="filterText" #searchInput type="text" placeholder="{{'searchForJobs' | translate}}"
                       class="form-control d-inline float-right search-input w-100" style="border: none; border-radius: 4px 0px 0px 4px; height: 40px;">

                       <br>
             <label *ngIf="filterText != ''" class="clear-search mt-2" for="" (click)="clearSearch()"><i class="fas fa-times mr-1"
              style="color: #707683;" ></i> {{'clearSearch' | translate}}  </label>

        </div>

        <div class="col-12 col-lg-6 col-md-12 col-xl-3 pt-md-2 pt-2 ml-auto">
          <div>
            <app-admin-jobs-filter (resultFilter)="setFilter($event)"></app-admin-jobs-filter>
          </div>
        </div>
      </div>


      <div class="showing-test mt-3">
        {{ 'showing' | translate }} {{ ' ' + jobs.length }} {{ 'resultOfJobs' | translate }}
      </div>

      <div class="row mb-3">
        <div class="col-8 based-text">
          {{ 'basedOnYourPreferences' | translate }}
        </div>

        <div class="col-4">
          <div class="row" style="float: right;">
            <div class="mt-1 sort-by-text">{{ 'sortBy' | translate }}:</div>
            <div><tlb-dropdown [items]="sortOptions" [dropDownMenuWidth]="'16rem'"
              [style]="'float: right;  border: none; box-sizing: border-box; width: 163px; height: 53px; margin: 0px 15px;'"
              [textStyle]="'font-style: normal; font-weight: normal; font-size: 14px; color: #252B42; margin-top: 5px;'"
              [dropdownName]="sortLabel != '' ? sortLabel : 'selectSort'" (searchData)="selectSort($event)"
              [isFilter]="false">
            </tlb-dropdown></div>
          </div>

        </div>
      </div>

      <div
        *ngFor="let job of jobs | paginate:{ itemsPerPage: requestPayload.limit, currentPage: currentPage,totalItems:count}"
        class="row mt-3">
        <div class="col-12">
          <div class="card" style="border-radius: 4px; border: none;">
            <div class="card-body">
              <div class="container-fluid">


                <div class="row">
                  <div class="col-8">

                    <div class="row">
                      <div>
                        <img src="assets/images/default-logo.png" alt="" width="50" height="50">
                      </div>
                      <div class="ml-3 mt-1">
                        <div class="title">
                          {{job.title}}
                        </div>

                        <div class="comp-text mt-1">
                          {{job.employer.name}}
                        </div>
                      </div>
                      <div class="ml-3">
                        <div class="status-badge">{{job.status | translate}}</div>

                      </div>
                    </div>

                    <div class="desc-text mt-3">
                      {{job.description ? job.description.substring(0,200) : ''}} {{job.description && job.description.length > 200 ? '...' : ''}}  <br>
                      <div class="mt-2">
                        <button class="btn view-application-btn" (click)="openFullJobDescription(job)">
                         {{'fullJobDescription' | translate }}
                       </button>
                   </div>
                    </div>

                    <div class="mt-5 row">
                      <div class="col-4">
                        <span class="sub-info-headers ml-3">{{ 'pendingReview' | translate }}</span> <span (click)="openApplicantsModal(job)" class="pend-rev-badge ml-2"> {{job.pendingReview}} </span>
                      </div>

                      <div class="col-4">
                        <span class="sub-info-headers">{{ 'underConsideration' | translate }}</span> <span class="gray-badge ml-2"> {{job.underConsideration}} </span>
                      </div>

                      <div class="col-4">
                        <span class="sub-info-headers">{{ 'TotalApplications' | translate }}</span> <span class="gray-badge ml-2"> {{job.totalApplications}} </span>
                      </div>
                    </div>

                  </div>

                  <div class="col-4 border-left">
                    <div class="info-box">
                      <div>
                        <i class="far fa-calendar"></i> <span class="info-header ml-3">{{job.postDate}} {{ 'daysAgo' | translate }}</span>
                      </div>

                      <div class="mt-1">
                        <i class="fas fa-map-marker-alt"></i> <span class="info-header ml-3">{{job.location?.city?.name}},
                          {{job.location?.country?.name}}</span>
                      </div>

                      <div class="mt-1">
                        <i class="far fa-clock"></i> <span class="info-header ml-3">{{(job.type) | translate }}</span>
                      </div>

                      <div class="mt-1">
                        <i class="fas fa-user-friends"></i> <span class="info-header ml-3">{{'totalNumberOfPositions' | translate}}: </span>
                        <span class="info-text">{{job.positionCount}}</span>
                      </div>

                      <div class="mt-1">
                        <i class="fas fa-briefcase"></i> <span class="info-header ml-3">{{'jobClassification' | translate}}: </span>
                        <span class="info-text">{{job.classification?.name}}</span>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>

      </div>
        <tlb-empty-page *ngIf="!jobs || jobs.length == 0" [warningLabel]="filterText == ''  ? 'noJobYet' : filterText" [filterText]="filterText"></tlb-empty-page>
    </div>
    <div class=" container-fluid row mt-3 pagination">
      <pagination-controls (pageChange)="currentPage = $event;getJobs(currentPage)"
        [autoHide]="count <= requestPayload.limit" nextLabel="{{'next' | translate}}"
        previousLabel="{{'previous' | translate}}" responsive="true"></pagination-controls>
    </div>
  </div>
  <app-applicants-modal [applications]="selectedApplications"></app-applicants-modal>

  <app-employer-job-detail-popup  [job]="selectedJob" [modalId]="'jobDetailFromAdmin'"

                               ></app-employer-job-detail-popup>
