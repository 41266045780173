export class UserPostModel {

  id: string;
  email: string;
  role: string;
  firstName: string;
  lastName: string;
  fullName: string;
  preferredLanguage: string;

  constructor() {
    this.id = '';
    this.email = '';
    this.role = '';
    this.firstName = '';
    this.lastName = '';
    this.fullName = '';
    this.preferredLanguage = '';
  }
}
