import { ServerErrorList }           from './../../lists/serverErrorList';
import { Injectable }                from '@angular/core';
import { HttpClient, HttpHeaders }   from '@angular/common/http';
import { Router }                    from '@angular/router';
import { Observable, throwError }    from 'rxjs';
import { catchError, map, finalize } from 'rxjs/operators';
import { TranslatePipe }             from '../../pipes/translate/translate.pipe';
import { NgxSpinnerService }         from 'ngx-spinner';
import { NotificationService }       from '../notification/notification.service';
import { server }                    from '../server';

@Injectable({
              providedIn: 'root'
            })

export class ApiService {

  TOKEN_KEY = 'access_token';
  REFRESH_TOKEN_KEY = 'refresh_token';
  lang = localStorage.getItem('lang');
  headerWithoutToken = new HttpHeaders()
    .set('Accept', 'application/json')
    .set('Cache-Control', ' no-cache')
    .set('Content-Type', 'application/json')
    .set('Accept-Language', this.lang);

  constructor(protected http: HttpClient,
              protected address: string,
              protected spinner?: NgxSpinnerService,
              protected translate?: TranslatePipe,
              protected notification?: NotificationService,
              private router?: Router
  ) {
  }

  get myUserId(): string {
    return localStorage.getItem('userId');
  }

  setHeadersWithToken(): HttpHeaders {
    const token = localStorage.getItem(this.TOKEN_KEY);
    let headersWithToken: HttpHeaders;
    headersWithToken = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Cache-Control', ' no-cache')
      .set('Content-Type', 'application/json')
      .set('Accept-Language', this.lang)
      .set('Authorization', 'Bearer ' + token);
    return headersWithToken;
  }

  ServerGet(): Observable<any> {
    this.spinner.show();
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.get(this.address, options)
               .pipe(
                 map(res => res),
                 catchError((err) => {
                   return this.ServerError(err);
                 }),
                 finalize(() => {
                   this.spinner.hide();
                 })
               );
  }

  ServerGetWithText(text): Observable<any> {
    this.spinner.show();
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.get(this.address + text, options)
               .pipe(
                 map(res => res),
                 catchError((err) => {
                   return this.ServerError(err);
                 }),
                 finalize(() => {
                   this.spinner.hide();
                 })
               );
  }

  ServerGetById(address: string): Observable<any> {
    this.spinner.show();
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.get(address, options)
               .pipe(
                 map(res => res),
                 catchError((err) => {
                   return this.ServerError(err);
                 }),
                 finalize(() => {
                   this.spinner.hide();
                 })
               );
  }

  ServerGetByIdWithoutLogin(address: string): Observable<any> {
    this.spinner.show();
    const headers = this.headerWithoutToken;
    const options = { headers };
    return this.http.get(address, options)
               .pipe(
                 map(res => res),
                 catchError((err) => {
                   return this.ServerError(err);
                 }),
                 finalize(() => {
                   this.spinner.hide();
                 })
               );
  }

  ServerPost(data: any): Observable<any> {
    this.spinner.show();
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.post(this.address, data, options)
               .pipe(
                 map(res => res),
                 catchError((err) => {
                   return this.ServerError(err);
                 }),
                 finalize(() => {
                   this.spinner.hide();
                 }));
  }

  ServerPostWithText(text: string, data: any): Observable<any> {
    this.spinner.show();
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.post(this.address + text, data, options)
               .pipe(
                 map(res => res),
                 catchError((err) => {
                   return this.ServerError(err);
                 }),
                 finalize(() => {
                   this.spinner.hide();
                 }));
  }

  ServerPut(address: string, data: any): Observable<any> {
    this.spinner.show();
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.put(address, data, options)
               .pipe(
                 map(res => res),
                 catchError((err) => {
                   return this.ServerError(err);
                 }),
                 finalize(() => {
                   this.spinner.hide();
                 }));
  }

  ServerDelete(address: string): Observable<any> {
    this.spinner.show();
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.delete(address, options)
               .pipe(
                 map(res => res),
                 catchError((err) => {
                   return this.ServerError(err);
                 }),
                 finalize(() => {
                   this.spinner.hide();
                 }));
  }

  ServerGetWithoutLogin(): Observable<any> {
    this.spinner.show();
    const headers = this.headerWithoutToken;
    const options = { headers };
    return this.http.get(this.address, options)
               .pipe(
                 map(res => res),
                 catchError((err) => {
                   return this.ServerError(err);
                 }),
                 finalize(() => {
                   this.spinner.hide();
                 }));
  }

  ServerGetWithoutLoginWithText(text): Observable<any> {
    this.spinner.show();
    const headers = this.headerWithoutToken;
    const options = { headers };
    return this.http.get(this.address + text, options)
               .pipe(
                 map(res => res),
                 catchError((err) => {
                   return this.ServerError(err);
                 }),
                 finalize(() => {
                   this.spinner.hide();
                 }));
  }

  ServerPostWithoutLogin(address: string, data: any): Observable<any> {
    this.spinner.show();
    const headers = this.headerWithoutToken;
    const options = { headers };
    return this.http.post(address, data, options)
               .pipe(
                 map(res => res),
                 catchError((err) => {
                   return this.ServerError(err);
                 }),
                 finalize(() => {
                   this.spinner.hide();
                 }));
  }

  ServerPostWithFormData(address: string, data: any): Observable<any> {
    this.spinner.show();
    const token = localStorage.getItem(this.TOKEN_KEY);
    let headers: HttpHeaders;
    headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Cache-Control', ' no-cache')
      .set('Accept-Language', this.lang)
      .set('Authorization', 'Bearer ' + token);

    return this.http.post(address, data, { headers })
               .pipe(
                 map(res => res),
                 catchError((err) => {
                   return this.ServerError(err);
                 }),
                 finalize(() => {
                   this.spinner.hide();
                 }));
  }

  ServerPutWithFormData(address: string, data: any): Observable<any> {
    this.spinner.show();
    const token = localStorage.getItem(this.TOKEN_KEY);
    let headers: HttpHeaders;
    headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Cache-Control', ' no-cache')
      .set('Accept-Language', this.lang)
      .set('Authorization', 'Bearer ' + token);

    return this.http.put(address, data, { headers })
               .pipe(
                 map(res => res),
                 catchError((err) => {
                   return this.ServerError(err);
                 }),
                 finalize(() => {
                   this.spinner.hide();
                 }));
  }

  ServerSignUp(address: string, data: any): Observable<any> {
    this.spinner.show();
    const token = localStorage.getItem(this.TOKEN_KEY);
    let headers: HttpHeaders;
    headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Cache-Control', ' no-cache');

    return this.http.post(address, data, { headers })
               .pipe(
                 map(res => res),
                 catchError((err) => {
                   return this.ServerError(err);
                 }),
                 finalize(() => {
                   this.spinner.hide();
                 }));
  }

  ClearSession(): void {
    this.spinner.show();
    const tempLang = localStorage.getItem('lang');
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem('lang', tempLang);
    this.router.navigate(['/'])
        .then(r => {
          this.spinner.hide();
        });
  }

  getSessionLanguage(): string {
    if (!localStorage.getItem('lang')) {
      return JSON.parse(window.atob((this.getToken()).split('.')[1])).preferred_language;
    }
    return localStorage.getItem('lang');
  }

  setLanguage(lang): void {
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', 'en');
      window.location.reload();
    } else {
      if (lang.trim() !== '') {
        localStorage.setItem('lang', lang);
        window.location.reload();
      } else if (this.getToken()) {
        const sessionLanguage = this.getSessionLanguage();
        localStorage.setItem('lang', sessionLanguage);
      }
    }
  }

  getToken(): string {
    return localStorage.getItem(this.TOKEN_KEY);
  }

  ServerLogout(): void {
    this.ClearSession();
  }

  ServerError(err: any): any {
    const error = err.error;
    let throwErrorCode = 'GENERAL_ERROR';
    if (err.status === 404) {
      return throwError(err);
    }

    if (err.status === 401) {

      // if(err.message.includes('Invalid refresh token')){
      //   this.ServerLogout();
      // }
      // else{
      //   this.refreshToken().subscribe(x=>{

      //   }, error=>{

      //     this.ServerLogout();

      //   });
      // }
      // this.ServerLogout();
    } else {
      if (error.code && ServerErrorList[error.code]) {
        throwErrorCode = this.checkSubErrors(error);
        if (throwErrorCode) {
          error.message = throwErrorCode;
          return throwError(err.error);
        }
        this.notification.error(this.translate.transform(ServerErrorList[error.code], []));
        throwErrorCode = this.translate.transform(ServerErrorList[error.code], []);
        error.message = throwErrorCode;
        return throwError(err.error);
      }

      return throwError(err);
    }

  }

  refreshToken(): Observable<any> {
    let body = new URLSearchParams();
    body.set('refresh_token', localStorage.getItem('refresh_token'));
    body.set('grant_type', 'refresh_token');
    const token = localStorage.getItem(this.TOKEN_KEY);
    let headers: HttpHeaders;
    headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Cache-Control', ' no-cache')
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Accept-Language', this.lang)
      .set('Authorization', 'Bearer ' + token);

    return this.http.post(server.uaaUrl + '/oauth/token', body.toString(), { headers })
               .pipe(map((user) => {

               }), catchError((err) => {

                 this.ServerLogout();
                 return this.ServerError(err);

               }));
  }

  checkSubErrors(error) {
    let message: string;
    if (error.subErrors) {
      const subCodes = error.subErrors.map(item => item.code);
      const subCodesCleanDublicate = Array.from(new Set(subCodes));
      subCodesCleanDublicate.forEach((item: any) => {
        if (ServerErrorList[item]) {
          if (!message) {
            message = this.translate.transform(ServerErrorList[item], []);
          } else {
            message += ', ' + this.translate.transform(ServerErrorList[item], []);
          }
        }
      });
      return message;
    } else {
      return null;
    }

  }

}

