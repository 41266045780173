<div class=" pb-5">
  <header id="home" class="hero-area-2">
    <div class="overlay"></div>
    <nav class="navbar navbar-expand-lg bg-inverse scrolling-navbar menu-bg-login">
      <div class="container-fluid px-lg-5 pt-4"><img src="../../../assets/images/logo.png" width="160" />
        <!-- <a [routerLink]="isLoggedIn ?'/dashboard':'/'" class="navbar-brand pl-3 pl-lg-1"> <img src="../../../assets/images/small-logo.png" alt="" style="margin-top: -2px;"> <span class="logoName ml-2">{{'logoName' | translate}}</span> -->
        <!-- <span class="pl-2 logo-text">TalentBase</span> -->
        <!-- </a> -->
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation"  *ngIf="!isLoggedIn">
          <i class="lni-menu"></i>
        </button>
        <div class="collapse navbar-collapse bg-white bg-lg-0" id="navbarCollapse" *ngIf="!isLoggedIn">
          <ul class="navbar-nav mx-auto pr-lg-6  pr-xl-7  pl-4 pl-lg-0">
            <li class="nav-item">
              <a class="nav-link page-scroll" href="#home">HOME</a>
            </li>
            <li class="nav-item">
              <a class="nav-link page-scroll" href="#app-features">ABOUT US</a>
            </li>
            <li class="nav-item">
              <a class="nav-link page-scroll" href="#explore">HOW IT WORKS?</a>
            </li>
            <li class="nav-item">
              <a class="nav-link page-scroll" routerLink='/pricing'>PRICING</a>
            </li>
            <li class="nav-item">
              <a class="nav-link page-scroll" href="#faq">FAQ</a>
            </li>
            <li class="nav-item">
              <a class="nav-link page-scroll" href="#contact">CONTACTS</a>
            </li>
          </ul>
          <ul class="navbar-nav w-sm-25 justify-content-end  pl-4 pl-lg-0" style="display: -webkit-inline-box;" *ngIf="!isLoggedIn">
            <li class="nav-item">
              <a style="color: #381DDB;" class="nav-link" routerLink='/login'>SIGN IN</a>
            </li>
            <li class="nav-item sign-up-position">
              <a style="color: #381DDB;" class="nav-link sign-up-design p-2" routerLink='/sign-up' href="#">SIGN UP</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</div>

