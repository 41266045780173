<div
  class="modal fade"
  id="application-note-delete-modal"
  tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="icon-info-box">
        <div class="icon-box">
          <img alt="delete icon"
               class="delete-icon"
               src="assets/images/talentbase/icons/delete.png">
        </div>
        <div class="info-text-box">
          {{'theNoteWillBeRemovedAreYouSure' | translate}}
        </div>
        <div class="close-button-box">
          <img (click)="closeModal()"
               alt="cross"
               src="assets/images/talentbase/icons/cross.png">
        </div>
      </div>
      <div class="delete-action-box">
        <button (click)="closeModal()"
                class="btn btn-cancel">{{'discard' | translate}}</button>
        <button (click)="deleteNote()"
                class="btn btn-delete">{{'delete' | translate}}</button>
      </div>


    </div>
  </div>
</div>
