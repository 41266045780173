<div class="container-fluid px-md-5 mt-4">
  <div class="row px-5">
    <div class="col-12">
      <span class="home-text">{{ 'home' | translate }}</span>
      <span class="user-text"> <i class="fas fa-chevron-right ml-3 mr-2"></i> {{ 'users' | translate }} </span>
    </div>
    <br>
    <div class="header col-12 mt-3">
      {{ 'users' | translate }}
    </div>
    <div class="col-12 pt-3 pr-1 pl-0 filter-div">
      <div class="row mb-3"
           style="display: flex;
                                    flex-direction: row;
                                    flex-wrap: wrap;
                                    align-content: flex-start;
                                    justify-content: flex-start;
                                    align-items: baseline;">
        <div class=" ml-4"
             style="display: flex;
                                  flex-direction: row;
                                  flex-wrap: nowrap;
                                  justify-content: flex-start;
                                  align-items: baseline;">
          <i class="fa fa-search"></i>
          <input [(ngModel)]="filterText"
                 [ngClass]=" filterText.length > 0 ? 'filter-tocuhed' : '' "
                 name="skills"
                 class="form-control filter"
                 id="skill"
                 type="text"
                 placeholder="{{'searchForName' | translate}}"
                 (keyup.enter)="onFilter()">

              <br>
             <label *ngIf="filterText != ''" class="clear-search mt-2" for="" (click)="clearSearch()"><i class="fas fa-times mr-1"
              style="color: #707683;" ></i> {{'clearSearch' | translate}}  </label>

        </div>
        <div style="display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
justify-content: flex-end;
    align-items: center;
    margin-left: auto;">
          <span class="sort-by-text">{{ 'sortBy' | translate }}:</span>
          <tlb-dropdown [items]="sortOptions"
                        [dropDownMenuWidth]="'16rem'"
                        [style]="'background: #FFFFFF; border: 1px solid #C8D4DF; box-sizing: border-box; width: 163px; height: 53px; margin: 0px 0px 0px .5rem;'"
                        [textStyle]="'font-style: normal; font-weight: normal; font-size: 14px; color: #252B42; margin-top: 5px;'"
                        [dropdownName]="sortLabel != '' ? sortLabel : 'selectSort'"
                        (searchData)="selectSort($event)"
                        [isFilter]="false">
          </tlb-dropdown>
          <button type="button"
                  (click)="userModalOpen()"
                  class="float-right add-new-user-button ml-2"><i
            class="fas fa-plus fa-sm mr-2"></i><span class="font-weight">{{ 'newUser' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row overflow-auto"
           *ngFor="let user of users | paginate:{ itemsPerPage: requestPayload.limit, currentPage: currentPage,totalItems:count}">
        <div class="card">
          <div class="profile-image-container">
            <div [style.background-color]="user.imageColor"
                 class="preimage"><span style="color: white;">{{user.imageText}}</span></div>
          </div>
          <div class="profile-container">
            <div class="profile-name-container">
              {{user.fullName}}
            </div>
            <div class="profile-email-container">
              {{user.email}}
            </div>
          </div>
          <div class="create-date-container">
            <i class="far fa-clock"></i>
            {{user.createdAt | date}}
          </div>
          <div class="job-numbers-container"
               *ngIf="isEmployer == 1">
            <div class="job-number-text-container">
              {{ 'numberOfJobs'| translate }}
            </div>
            <div class="job-count-container">
              {{user.jobCount}} {{'jobs'|translate}}
            </div>
          </div>

          <div class="status-container">
            <div class="status-text-container">
              {{ 'status' |
              translate }}
            </div>
            <div class="status-value-container">
              <p class="text-muted mb-0"
                 [ngClass]="user.status == 'ACTIVE' ? 'active-box' : user.status == 'DELETED' ? 'passive-box' : 'pending-box'">
                         <span class="text-center"
                               [ngClass]="user.status == 'ACTIVE' ? 'active-text' : user.status == 'DELETED' ? 'passive-text' : 'pending-text' ">
                           {{user.status | translate }}</span>
              </p>
            </div>

          </div>
          <div class="delete-container">
            <div class="delete-button-container">
<!--              <button class="btn dropdown-toggle col-12"
                      type="button" id="dropdownMenuButton-{{user.id}}" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

              </button>-->

            </div>
            <div class="delete-popup-container">
              <div *ngIf="user.erasable" class="dropdown">
                <button class="btn dropdown-toggle col-12" type="button" id="dropdownMenuButton-{{user.id}}" data-offset="0" data-toggle="dropdown" aria-haspopup="false" aria-expanded="true">
                   <span style="color: hsl(204, 7%, 42%); max-width: 95%;"
                         class="text-truncate d-inline-block text"><i class="fas fa-ellipsis-v"></i></span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton-{{user.id}}">
                  <button (click)="deleteModal(user)"
                          class="delete-button">{{ 'delete' | translate }}</button>
                </div>
              </div>


            </div>
          </div>
        </div>

      </div>

      <div *ngIf="false"
           class="mt-5 pt-5">
        <div
          *ngFor="let user of users | paginate:{ itemsPerPage: requestPayload.limit, currentPage: currentPage,totalItems:count}"
          class="row mt-3">
          <div class="col-12 pr-1 pl-0">
            <div class="card"
                 style="box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.08), 0px 4px 4px rgba(51, 51, 51, 0.04); border: none;">
              <div class="card-body">

                <div *ngIf="user.erasable" class="dropdown drp"
                     style="float: right;">
                  <button aria-expanded="false"
                          aria-haspopup="true"
                          class="btn dropdown-toggle col-12"
                          data-toggle="dropdown"
                          type="button">
                  <span style="color: hsl(204, 7%, 42%); max-width: 95%;"
                        class="text-truncate d-inline-block text"><i class="fas fa-ellipsis-v"></i></span>
                  </button>
                  <div class="dropdown-menu col-12 pb-0"
                       id="dropdown-menu">
                    <div class="col-12 drp-menu scrollable-menu p-0 ">
                      <button (click)="deleteModal(user)"
                              class="delete-button">{{ 'delete' | translate }}</button>
                    </div>
                  </div>
                </div>
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-12">
                      <div class="row"
                           style="display: flex;
                                            flex-direction: row;
                                            flex-wrap: nowrap;
                                            align-content: flex-start;
                                            justify-content: space-between;
                                            align-items: baseline;
                                           ">
                        <div>
                          <div class="row">
                            <div class="mx-4">
                              <div [style.background-color]="user.imageColor"
                                   id="preimage"><span style="color: white;">{{user.imageText}}</span></div>
                            </div>
                            <div class="">
                            <span id="intials"
                                  class="name-text">{{user.fullName}}</span> <br>
                              <span class="mail-text">{{user.email}}</span>
                            </div>
                          </div>
                        </div>
                        <div class="">

                   <span class="date-text ml-2"><i
                     class="far fa-clock"></i> {{user.createdAt | date}}</span>

                        </div>
                        <div class="">
                          <p *ngIf="isEmployer == 1"
                             class="mb-1 number-header">
                            {{ 'numberOfJobs'
                            | translate }}
                          </p>
                          <p *ngIf="isEmployer == 1"
                             class="number-job-text mb-0">
                            {{user.jobCount}} {{'jobs'|translate}}
                          </p>
                        </div>
                        <div class="">
                          <p class="mb-0 number-header">
                            {{ 'status' |
                            translate }}
                          </p>
                          <p class="text-muted mb-0"
                             [ngClass]="user.status == 'ACTIVE' ? 'active-box' : user.status == 'DELETED' ? 'passive-box' : 'pending-box'">
                         <span class="text-center"
                               [ngClass]="user.status == 'ACTIVE' ? 'active-text' : user.status == 'DELETED' ? 'passive-text' : 'pending-text' ">{{user.status
                           |
                           translate }}</span>
                          </p>
                        </div>
                      </div>

                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <tlb-empty-page *ngIf="!users || users.length == 0" [warningLabel]="'Search no result for '+filterText" [subText]="false" ></tlb-empty-page> -->
        </div>
      </div>

      <div class=" container-fluid row mt-3 pagination">
        <pagination-controls (pageChange)="currentPage = $event;getUsers(currentPage)"
                             [autoHide]="count <= requestPayload.limit"
                             nextLabel="{{'next' | translate}}"
                             previousLabel="{{'previous' | translate}}"
                             responsive="true"></pagination-controls>
      </div>
      <tlb-empty-page *ngIf="!users || users.length == 0" [warningLabel]="filterText == ''  ? 'noUserYet' : filterText" [filterText]="filterText"></tlb-empty-page>
    </div>
    <!-- <tlb-empty-page-dashboard *ngIf="!users || users.length == 0" [warningLabel]="'No jobs yet.'"></tlb-empty-page-dashboard> -->

  </div>


  <app-user-modal [modalId]="'exampleModalLongFromUser'"
                  [isEmployer]="isEmployer == 1"
                  (postedUser)="getUsers(1)"></app-user-modal>
  <app-delete-user-popup [employer]="selectedUser"
                         (updateUsersEmitter)="getUsers(1)"></app-delete-user-popup>
