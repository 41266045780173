import { PhoneNumberModel } from './../parameter/phone-number.model';
export class UserGeneralInfosModel {

  email: string;
  firstName: string;
  lastName: string;
  type?: string;
  createdAt: Date |null;

  constructor() {
    this.email = "";
    this.firstName = "";
    this.lastName = "";
    this.type = "";
    this.createdAt = null;
  }
}
