import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../../auth';
import { server } from '../../server';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmployerMeService extends ApiService {

  constructor(public http: HttpClient, public spinner: NgxSpinnerService, public translate: TranslatePipe, public notification: NotificationService) {
    super(http, server.employerme, spinner, translate, notification)
  }

  getContactPerson(){
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.get(server.employerme + "/contact-persons",  options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      );
  }

  putContactPerson(model){
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.put(server.employerme + "/contact-persons", model,  options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      );
  }


  getCompanyInfos(){
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.get(server.employerme + "/company-infos",  options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      );
  }

  putCompanyInfos(model){
    this.spinner.show();
    const token = localStorage.getItem(this.TOKEN_KEY);
    let headers: HttpHeaders;
    headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Cache-Control', ' no-cache')
      .set('Accept-Language', this.lang)
      .set('Authorization', 'Bearer ' + token);

    return this.http.put(server.employerme + "/company-infos", model, { headers })
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        }));
  }

  getAddress(){
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.get(server.employerme + "/addresses",  options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      );
  }

  putAddress(model){
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.put(server.employerme + "/addresses", model,  options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      );
  }

  getSocialLinks(){
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.get(server.employerme + "/social-links",  options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      );
  }

  putSocialLinks(model){
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.put(server.employerme + "/social-links", model,  options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      );
  }
}
