import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-employer-detail-modal',
  templateUrl: './admin-employer-detail-modal.component.html',
  styleUrls: ['./admin-employer-detail-modal.component.scss']
})
export class AdminEmployerDetailModalComponent implements OnInit {

  @Input() employer: any;
  selectedEmployer: any;

  constructor() { }

  ngOnInit(): void {
  }


  openMessagesPopup() {
    this.selectedEmployer = this.employer;

    // @ts-ignore
    $('#adminEmployerDetailModal').modal('hide');
    // @ts-ignore
    $('#messagesForm').modal('show');
  }

  openDeactivePopup() {
    // @ts-ignore
    $('#adminEmployerDetailModal').modal('hide');
    // @ts-ignore
    $('#deactivatePopup').modal('show');
  }

}
