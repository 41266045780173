export class LegalInfoModel {
    companyAddress: string;
    companyName: string;
    emailAddress: string;


    constructor() {
        this.companyAddress = '';
        this.companyName = '';
        this.emailAddress = '';

    }
}
