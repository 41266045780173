import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-recruiter-detail-popup',
  templateUrl: './admin-recruiter-detail-popup.component.html',
  styleUrls: ['./admin-recruiter-detail-popup.component.scss']
})
export class AdminRecruiterDetailPopupComponent implements OnInit {

  @Input() recruiter: any;
  reviews: any[] = [];
  selectedRecruiter: any;
  @Input() modalId: string = "adminRecruiterDetailModal";

  constructor() { }

  ngOnInit(): void {
  }


  openReviewModal(reviews) {
    this.reviews = reviews;

    // @ts-ignore
    $('#review-popup').modal('show');
  }
  openMessagesPopup() {
    this.selectedRecruiter = this.recruiter;

    // @ts-ignore
    $('#' + this.modalId).modal('hide');
    // @ts-ignore
    $('#messagesForm').modal('show');
  }

  openDeactivePopup() {
    // @ts-ignore
    $('#' + this.modalId).modal('hide');
    // @ts-ignore
    $('#deactivatePopup').modal('show');
  }

}
