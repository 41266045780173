import {GeneralIdNameModel} from '../general';
import {PhoneNumberModel} from '../parameter';
import {UserPostModel} from './user-post.model';

export class UserModel extends UserPostModel {

  createdAt: string;
  createdBy: GeneralIdNameModel;
  updatedAt: string;
  updatedBy: GeneralIdNameModel;
  status: string;
  recruiterId: string;
  employerId: string;
  phoneNumber: PhoneNumberModel;
  emailVerified: boolean;
  fullName: string;
  comments: string;
  // jobs: string;
  functionalroles: string;
  id: string;
  jobCount:number;
  imageColor: string;
  imageText: string;
  erasable: boolean;

  constructor() {
    super();
    this.createdAt = '';
    this.updatedAt = '';
    this.comments = '';
    this.status = '';
    this.fullName = '';
    this.recruiterId = '';
    this.employerId = '';
    this.functionalroles = '';
    this.createdBy = new GeneralIdNameModel();
    this.updatedBy = new GeneralIdNameModel();
    this.id = '';
    this.jobCount = 0;
    this.imageColor = "";
    this.imageText = "";
    this.erasable = true;
  }
}
