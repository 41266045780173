import { Component, EventEmitter, Input, OnInit, Output }         from '@angular/core';
import { FormControl, FormGroup, Validators }                     from '@angular/forms';
import { FormType }                                               from 'src/app/shared/enums';
import { TlbFormModel }                                           from 'src/app/shared/models';
import { ApplicationService, NotificationService, TranslatePipe } from 'src/app/shared/services';

@Component({
             selector: 'app-add-edit-application-note',
             templateUrl: './add-edit-application-note.component.html',
             styleUrls: ['./add-edit-application-note.component.scss']
           })
export class AddEditApplicationNoteComponent implements OnInit {

  formModel: TlbFormModel[] = [];
  formGroup: FormGroup;
  @Input() selectedNote: any = null;
  @Input() changed: boolean = false;
  @Input() applicationId: string = null;
  model: any = null;
  defaultModel: any = null;
  @Output() updateOrSaveClick = new EventEmitter();

  constructor(private notification: NotificationService, private translate: TranslatePipe,
              private applicationService: ApplicationService) {
  }

  get form() {
    return this.formGroup.controls;
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (this.selectedNote && this.selectedNote != null) {

      this.model = {
        id: this.selectedNote.id,
        application: { id: this.selectedNote.application.id },
        title: this.selectedNote.title,
        note: this.selectedNote.note
      };

      this.defaultModel = Object.assign({}, this.model);
      this.createForm();
    } else {
      this.model = {
        application: { id: this.applicationId },
        title: '',
        note: ''
      };

      this.createForm();
    }
  }

  createForm() {

    let s = this.selectedNote ? this.selectedNote : null;

    this.formModel = [{
      class: 'col-12', components: [
        { label: 'title', control: 'title', required: true, type: FormType.text },
        { label: 'note', control: 'note', required: true, type: FormType.textArea }
      ]
    }];

    this.formGroup = new FormGroup({
                                     title: new FormControl(s ? s.title : '', Validators.required),
                                     note: new FormControl(s ? s.note : '', Validators.required)
                                   });
  }

  updateOrSave() {

    this.model.title = this.form.title.value;
    this.model.note = this.form.note.value;

    if (this.selectedNote && this.selectedNote != null) {

      this.applicationService.putNote(this.selectedNote.id, this.model)
          .subscribe(x => {
            this.updateOrSaveClick.emit(true);
            this.notification.success(this.translate.transform('updateSuccessful'));

            // @ts-ignore
            $('#addEditApplicationNote')
              .modal('hide');
          });
    } else {
      this.applicationService.postNote(this.model)
          .subscribe(x => {
            this.updateOrSaveClick.emit(true);
            this.notification.success(this.translate.transform('createSuccessful'));
            // @ts-ignore
            $('#addEditApplicationNote')
              .modal('hide');
          });
    }
  }

}
