<div class="container-fluid px-xxl-5 py-5" style="max-width:1400px">
  <div class="row  px-xxl-5">
    <div class="col-12 px-2 px-sm-5">
      <div class="row">
        <div class="col-12 text-center">
          <div class="section-header text-center">
            <h2 style="color: black " class="section-title">Simple, transparent pricing</h2>
            <p class="pb-2" style="color:#848199; font-size: 21px;">No contracts. No surprise fees.
            </p>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-12 text-center" *ngIf="!hideSwitch">
          <div class="switch6 m-auto">
            <label class="switch6-light" onclick="">
              <input [(ngModel)]="selectedPrice" type="checkbox" (ngModelChange)="changeTable($event)">
              <span>
              <span [ngClass]="!selectedPrice ? 'white-text' : 'gray-text'">{{ 'employer' | translate | uppercase }}</span>
              <span [ngClass]="selectedPrice ? 'white-text' : 'gray-text'">{{ 'recruiter' | translate | uppercase }}</span>
            </span>
              <a class="swtich-button"></a>
            </label>
          </div>
        </div>
      </div>
      <div class="row shadow px-4 bg-white" style="border-radius: 25px;">
        <div class="col-12 col-md-6 py-5"
        *ngFor="let package of packages;let i=index;let last=last;"
        [ngClass]="count == 1 ? 'col-xl-12' : count == 2 ? 'col-xl-6' : count == 3 ? 'col-xl-4' : count == 4 ? 'col-xl-3' : 'col-xl-3'">
          <div class="row d-flex" style="min-height: 100%;">
            <div class="col-12">
              <div class="row">
                <div class="col-12">
                  <h2 class="h1 font-weight-bold mb-4">{{package.price}}€<span
                    class="text-small font-weight-normal ml-2">/ month</span></h2>
                </div>
                <div class="col-12">
                  <h1 class="h6 pricing-title mb-2">{{package.name}}</h1>
                </div>
                <div class="col-12">
                  <p class="pricing-subtitle">{{package.description}}</p>
                </div>
                <div class="col-12" [innerHTML]="package.subDescription">
                </div>
                <div class="col-12">
                  <ul class="list-unstyled my-5 text-small text-left">
                    <li class="mb-3" *ngFor="let businessFunction of package.businessFunctions">
                      <i class="fa fa-check mr-2 tick-color"></i> {{businessFunction.name}}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-12 align-self-end">
              <a class="btn btn-block shadow get-started-button"  (click)="choosePacket(package)">Choose Plan</a>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>


</div>
