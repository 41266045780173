<div class="row">
  <div class="col-12 pr-1 pl-0">
    <div class="card border-0"
         style="background-color: #F5F6F8;">
      <div class="card-body">
        <div class="card-text row">

          <div class="col-12 col-xxl-3 p-0 header">
            {{ 'trackCandidates' | translate }}
          </div>
          <div class="col-12 col-xxl-9">

            <div style="display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              align-content: flex-start;
              justify-content: flex-end;
              align-items: center;">
              <div class="mt-3 position-relative ml-md-auto" style="border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;">
                <input (keyup.backspace)="filterText==''&&isPress?emitFilterData():''"
                       (keyup.enter)="filterText!=''?emitFilterData():''"
                       [(ngModel)]="filterText"
                       class="form-control  d-block align-self-center search-input w-100 border-0"
                       style="height: 45px; background-color: #F5F6F8;  border: 1px solid #CED4DA !important;"
                       type="text">
                <button (click)="emitFilterData()"
                        class="search-button d-block align-self-center"
                        type="submit"><i class="fa fa-search search-icon"
                                         style="font-size: 19px;"></i>
                </button>


              </div>

              <label *ngIf="filterText != ''" class="clear-search mt-3 ml-3" for="" (click)="clearSearch()"><i class="fas fa-times mr-1"
                style="color: #707683;" ></i> {{'clearSearch' | translate}}  </label>
              <div class="mt-3 mx-3">
                <button (click)="navigateAddCandidateComponent()"
                        class="btn btn-primary btn-style add-new-candaite-button align-self-center">
                  <svg fill="none"
                       height="14"
                       style="color: #3652E3;margin-right: 0.4rem"
                       viewBox="0 0 14 14"
                       width="14"
                       xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 7H1H7Z"
                          fill="#3652E3" />
                    <path d="M7 1V7M7 7V13M7 7H13M7 7H1"
                          stroke="#3652E3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2" />
                  </svg>
                  New Candidate
                </button>
              </div>
              <!--
              <div class="d-inline-block mx-3 position-relative">
                <span class="ml-3 mt-2 cursor-pointer d-block align-self-center">
                  <img
                    src="./../../../../assets/images/talentbase/filter-button.png"> {{ 'filters' | translate }}</span>
              </div>
              !-->

              <div class="mt-3 mr-md-5 mx-3">
                <tlb-dropdown (searchData)="selectSort($event)"
                              [dropdownName]="sortLabel != '' ? sortLabel : 'selectSort'"
                              [isFilter]="false"
                              [items]="sortOptions">
                </tlb-dropdown>
              </div>

            </div>
          </div>


          <!--          <div class="col-7 text-end p-0">
                      <div class="row">
                        <div class="col-9 my-2 my-lg-0">
                          <button class="search-button" (click)="emitFilterData()" type="submit"><i style="font-size: 19px;"
                                                                                                    class="fa fa-search search-icon"></i>
                          </button>
                          <input [(ngModel)]="filterText" (keyup.backspace)="filterText==''&&isPress?emitFilterData():''"
                                 (keyup.enter)="filterText!=''?emitFilterData():''" type="text"
                                 class="form-control d-inline float-right search-input w-100 border-0"
                                 style="height: 45px; background-color: #F5F6F8;">
                        </div>
                        <div class="col-3 my-2 my-lg-0">

                          <button class="btn btn-primary btn-style" (click)="navigateAddCandidateComponent()">
                            <svg style="color: #3652E3" width="14" height="14" viewBox="0 0 14 14" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M7 7H1H7Z" fill="#3652E3"/>
                              <path d="M7 1V7M7 7V13M7 7H13M7 7H1" stroke="#3652E3" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round"/>
                            </svg>
                            New Candidate
                          </button>

                        </div>
                      </div>
                    </div>-->


          <!--          <div class="col-2  text-end p-0">
                      <div class="row">
                        <div class="d-inline-block mx-3">
                          <span class="ml-3 mt-2 cursor-pointer">
                            <img
                              src="./../../../../assets/images/talentbase/filter-button.png"> {{ 'filters' | translate }}</span>
                        </div>
                        <div class="d-inline-block mx-3">
                          <tlb-dropdown [items]="sortOptions" [style]="'height: 45px;'" [textStyle]="'margin-top: 5px;'"
                                        [dropdownName]="sortLabel != '' ? sortLabel : 'selectSort'"
                                        (searchData)="selectSort($event)" [isFilter]="false">
                          </tlb-dropdown>
                        </div>
                      </div>
                    </div>-->


        </div>

      </div>
    </div>
  </div>
</div>

