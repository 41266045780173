<div class="dropdown drp ">
  <button
  (click)="clicked()"
  [disabled]="disabled"
          [style]="style"
          aria-expanded="false"
          aria-haspopup="true"
          class="btn dropdown-toggle position-relative"
          data-toggle="dropdown"
          style="border: 1px solid #ced4da; text-align: left; width: 100%;padding-right: 2rem;"
          type="button">
    <span class="d-block">
      <span [style]="textStyle"
            class="text-truncate text"
            style="color: hsl(204, 7%, 42%);line-height: initial;">{{dropdownName | translate}}</span>
      <span class=" d-inline-block"
            style="position: absolute;
                   right: 5px;
                   top: 56%;
                   transform: translate(0,-50% );">
        <i class="fas fa-chevron-down"
           style="float: right;"></i>
      </span>
    </span>

  </button>
  <div [style]="'min-width:' + dropDownMenuWidth"
       class="dropdown-menu col-12 pb-0"
       id="dropdown-menu">
    <label *ngIf="isFilter" class="col-12">
      <input
        (keyup)="search()"
        [(ngModel)]="searchText.data"
        autocomplete="off"
        autofocus
        class="form-control border-radius-0"
        placeholder="{{'Search' | translate}}">
    </label>
    <div class="col-12 drp-menu scrollable-menu p-0 ">
      <div *ngFor="let item of filteredItems">
        <label (click)="selectData($event, item)"
               *ngIf="!item.hide"
               [style]="item.disabled ? 'pointer-events:none; color: gray;' : 'none'"
               class="dropdown-item m-0 cursor-pointer scrollable-menu">
          <span class="d-block">{{item.label | translate}}</span>
        </label>
      </div>

    </div>
  </div>
</div>
