<div class="modal fade  bd-example-modal-xl" id="candidateDetailModal" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel" aria-hidden="true" style="background-color: #000000b3;">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content p-2">

      <div class="modal-body">
        <div class="container-fluid">
          <div class="row mt-3">
            <div class="col-1">
              <img src="assets/images/avatar_empty.png" style="width: 80px; height: 80px;">
            </div>
            <div class="col-8">
              <div class="row mt-2">
                <div class="col-12">
                  <b>
                    <h5>
                      {{application?.candidate?.fullName}}
                    </h5>
                  </b>

                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <b>
                    <h6>
                      {{application?.candidate?.resume.title}}
                    </h6>
                  </b>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="row" *ngIf="job && job.status=='ACTIVE'">
                <div class="col-12">
                  <button class="form-control green-button" (click)="openUpdateCandidateStatus(application)">
                    {{'updateCandidateStatus'|translate}}
                  </button>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12">
                  <button class="form-control purple-button" (click)="goToLink(application?.candidate?.resume?.url)">
                    {{'viewCandidateResume'|translate}}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <span class="gray">
                {{'appliedOn'|translate}} {{application?.date}}
              </span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-3">
              <button class="icon-background" type="button">
                <i class="fas fa-phone-alt"></i>
              </button>
              <span class="pl-4 texts gray">
                {{application?.candidate.phoneNumber.countryCallingCode}}
                {{application?.candidate.phoneNumber.phoneNumber}}
              </span>
            </div>
            <div class="col-3">
              <button class="icon-background" type="button">
                <i class="fas fa-envelope"></i>
              </button>
              <span class="pl-4 pr-xl-3  texts gray">{{application?.candidate.email}}</span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <span class="gray">
                <b>{{'applicationStatus'|translate}}:</b> {{application?.status|translate}}
              </span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12 recruiter-box">
              <div class="container-fluid">
                <div class="row mt-2">
                  <div class="col-12">
                    <span class="purple">
                      <b>{{'recruiterInfo'|translate}}</b>
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12  col-xl-1">
                    <div class="row h-100 ml-2">
                      <div class="row justify-content-center align-self-center mx-auto">
                        <img src="assets/images/avatar_empty.png" alt="" class="img-fluid">
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-xl-3">
                    <div class="row h-100 ml-2">
                      <div class="row justify-content-center align-self-center m-auto m-xl-0">
                        <b>{{recruiter?.user?.fullName}}</b>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 mt-3 mt-xl-0 col-xl-3 ml-xl-auto mr-xl-5 mt-xl-4">
                    <div class="row">
                      <div class="col-12 ">
                        <div class="row justify-content-center align-self-center user-rating-div m-auto">
                          <div class="col-4 p-2 text-center rating-score">
                            {{recruiter?.star}}
                          </div>
                          <div class="col-8  p-2 rating-star">
                            <app-star-rating [ratingScore]="recruiter?.star"></app-star-rating>
                          </div>
                          <div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="row mt-3">
                          <div class="col-12 text-center">
                            <h6>
                              <a (click)="openReviewModal(recruiter.reviews)" class="review-link">{{'read'|translate}}
                                {{recruiter?.reviewCount}}
                                {{'reviews'|translate}}</a>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-12 col-lg-3 col-sm-4">
                    <i class="fas fa-calendar icon purple mr-2"></i>
                    <span class="gray">{{ 'recruitmentExperience' | translate }}</span>
                  </div>
                  <div class="col-12 col-sm-8">
                    {{recruiter?.individualPracticeTime | translate}}
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-12 col-lg-3 col-sm-4">
                    <i class="fas fa-handshake icon purple mr-2"></i>
                    <span class="gray">{{ 'jobRecruisitionTypes' | translate }}</span>
                  </div>
                  <div class="col-12 col-sm-8">
                    {{recruiter?.requisitionTypesText}}
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-12 col-lg-3 col-sm-4">
                    <i class="fas fa-sticky-note icon purple mr-2"></i>
                    <span class="gray">{{ 'functionalRoles' | translate }}</span>
                  </div>
                  <div class="col-12 col-sm-8">
                    {{recruiter?.functionalRolesText}}
                  </div>
                </div>

                <div class="row my-2">
                  <div class="col-12 col-lg-3 col-sm-4">
                    <i class="fas fa-building icon purple mr-2"></i>
                    <span class="gray">{{ 'industries' | translate }}</span>
                  </div>
                  <div class="col-12 col-sm-8">
                    {{recruiter?.industriesText}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <span class="purple">
                <b>{{'coverLetter'|translate}}:</b>
              </span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <button class="form-control purple-button" (click)="goToLink(application?.candidate?.coverLetter?.url)"
                *ngIf="application?.candidate?.coverLetter?.url">
                {{'viewCoverLetter'|translate}}
              </button>
              <p> {{application?.candidate?.coverLetter?.content|translate}}</p>
            </div>
          </div>
          <div *ngIf="job?.questionnaire && job?.questionnaire.questions && job?.questionnaire.questions.length > 0">
            <div class="row mt-2">
              <div class="col-12">
                <b class="purple">{{'screeningQuestions'|translate}}</b>
              </div>
            </div>
            <div *ngFor="let ques of application?.answers; let i = index" class="row mt-1">
              <div class="col-12">
                <b>{{i + 1}}. {{ques.question.name}}</b><br>
                <span class="ml-1">
               <span class="ml-3">  {{ques.content}}</span></span>
              </div>
            </div>
          </div>


        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-control gray-button w-25" data-dismiss="modal">

          {{'close'|translate}}
        </button>
      </div>
    </div>
  </div>
</div>
