import {FormType} from 'src/app/shared/enums';
import {DropdownOptionsModel, EmployerAddressModel, EmployerCompanyInfoModel, EmployerContactPersonModel, EmployerSocialLinkModel, GeneralIdModel, PhoneNumberModel, TlbFormModel, UserGeneralInfosModel} from 'src/app/shared/models';
import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CompanySize} from '../../../../shared/enums/employer';
import { UserMeService, TranslatePipe, IndustryService, NotificationService, EmployerMeService, CountryService, CityService } from 'src/app/shared/services';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-complete-register',
  templateUrl: './complete-register.component.html',
  styleUrls: ['./complete-register.component.scss']
})
export class CompleteRegisterComponent implements OnInit {

  step: number = 1;
  generalInfos: UserGeneralInfosModel;

  companySizeOptions: DropdownOptionsModel[] = [];
  industryOptions: any[] = [];
  countryOptions: DropdownOptionsModel[] = [];
  cityOptions: DropdownOptionsModel[] = [];

  contactPersons: EmployerContactPersonModel;
  companyInfo: EmployerCompanyInfoModel;
  address: EmployerAddressModel;
  socialLinks: EmployerSocialLinkModel;

  formGeneralInfo: TlbFormModel[] = [];
  formGroupGeneralInfo: FormGroup;
  formContactPerson: TlbFormModel[] = [];
  formGroupContactPerson: FormGroup;
  formModelCompanyInfo: TlbFormModel[] = [];
  formGroupCompanyInfo: FormGroup;
  formAddress: TlbFormModel[] = [];
  formGroupAddress: FormGroup;
  formSocialLink: TlbFormModel[] = [];
  formGroupSocialLink: FormGroup;
  pageType: string = "";
  updatedPhoneNumber: boolean = false;

  constructor(private usersMeService: UserMeService, private employerMeService: EmployerMeService, private translate: TranslatePipe,
    private countryService: CountryService, private cityService: CityService, private industryService: IndustryService,
    private notification: NotificationService, private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit(): void {

    this.pageType =this.route.snapshot.paramMap.get("packageId");
    this.step = this.pageType == "null" ? 1 : 2;
    this.createGeneralInfoForm();
    this.createContactPersonForm();
    this.createCompanyInfoForm();
    this.createAddressForm();
    this.createSocialLinksForm();
  }


  createGeneralInfoForm(){
    this.usersMeService.getGeneralInfo().subscribe(x => {
      this.generalInfos = x as UserGeneralInfosModel;

      // this.generalInfos.phoneNumber == null ? this.generalInfos.phoneNumber = new PhoneNumberModel() : "";
      let s = this.generalInfos ? this.generalInfos : null;
      this.generalInfos.createdAt = null;

        this.formGeneralInfo = [{
          class: 'col-md-6 col-12', components: [
            { label: 'contactPersonFirstName', control: 'firstName', labelIcon:'fas fa-check mr-1' , type: FormType.text, required: true, style: "border: none; border-bottom: 1px solid gray; border-radius: 0;" },
            { label: 'contactPersonEmail', control: 'email', labelIcon:'fas fa-check mr-1' , required: true, type: FormType.email, style: "border: none; border-bottom: 1px solid gray; border-radius: 0;" },

          ]
        },
        {
          class: 'col-md-6 col-12', components: [
            { label: 'contactPersonLastName', labelIcon:'fas fa-check mr-1' ,control: 'lastName', required: true, type: FormType.text, style: "border: none; border-bottom: 1px solid gray; border-radius: 0;" },
            // {
            //   label: 'contactNumbers', labelIcon:'fas fa-check mr-1' , control: 'countryCode', control2: 'phoneNumber', required: true, type: FormType.phoneNumber,
            //   style: "border: none; border-bottom: 1px solid gray; border-radius: 0;"
            // },
          ]
        }];

        this.formGroupGeneralInfo = new FormGroup({
          firstName: new FormControl(s ? s.firstName : '', Validators.required),
          email: new FormControl(s ? s.email : '', Validators.required),
          lastName: new FormControl(s ? s.lastName : '', Validators.required),
          // countryCode: new FormControl(s ? s.phoneNumber.countryCallingCode : null, Validators.required),
          // phoneNumber: new FormControl(s ? s.phoneNumber.phoneNumber : null, Validators.required),
        });
    });
  }

  createContactPersonForm(){
    this.employerMeService.getContactPerson().subscribe(x => {
      this.contactPersons = x as EmployerContactPersonModel;
      this.contactPersons.phoneNumber == null ? this.contactPersons.phoneNumber = new PhoneNumberModel() : "";

      let s = this.contactPersons ? this.contactPersons : null;

      this.formContactPerson = [{
        class: 'col-md-6 col-12', components: [
          { label: 'firstName', labelIcon:'fas fa-check mr-1' , control: 'firstName', type: FormType.text, required: true, style: "border: none; border-bottom: 1px solid gray; border-radius: 0;" },
          {
            label: 'phoneNumber', control: 'countryCode', control2: 'phoneNumber', required: true, type: FormType.phoneNumber,
            style: "border: none; border-bottom: 1px solid gray; border-radius: 0;"
          },
        ]
      },
      {
        class: 'col-md-6 col-12', components: [
          { label: 'lastName', labelIcon:'fas fa-check mr-1' , control: 'lastName', required: true, type: FormType.text, style: "border: none; border-bottom: 1px solid gray; border-radius: 0;" },
          { label: 'email', labelIcon:'fas fa-check mr-1' ,control: 'email', required: true, type: FormType.email, style: "border: none; border-bottom: 1px solid gray; border-radius: 0;" },

        ]
      }];

      this.formGroupContactPerson = new FormGroup({
        firstName: new FormControl(s ? s.firstName : '', Validators.required),
        email: new FormControl(s ? s.email : '', Validators.required),
        lastName: new FormControl(s ? s.lastName : '', Validators.required),
        countryCode: new FormControl(s ? s.phoneNumber.countryCallingCode : null, Validators.required),
        phoneNumber: new FormControl(s ? s.phoneNumber.phoneNumber : null, Validators.required),
      });
    });
  }

  createCompanyInfoForm(){
    this.employerMeService.getCompanyInfos().subscribe(x => {
      this.companyInfo = x as EmployerCompanyInfoModel;

      let s = this.companyInfo ? this.companyInfo : null;

      this.companySizeOptions = Object.keys(CompanySize).map(c => ({ value: c, label: this.translate.transform(c) }));
      this.industryService.ServerGetWithoutLogin().subscribe(j => {
        let industries = j.content;
        this.industryOptions = industries.map(c => ({ item_id: c.id, item_text: c.defaultLocalization.name }));
        let industryArray = [];
        this.companyInfo.industries?.forEach(i => {
          industryArray.push({ item_id: i.id, item_text: i.name });
        });

        this.formModelCompanyInfo = [{
          class: 'col-md-6 col-12', components: [
            { label: 'companyName', control: 'companyName', required: true, type: FormType.text, style: "border: none; border-bottom: 1px solid gray; border-radius: 0;" },
            { label: 'website', control: 'website', required: true, type: FormType.text, style: "border: none; border-bottom: 1px solid gray; border-radius: 0;" },
            { label: 'companyLogo', control: 'companyLogo', name: s && s.companyLogo && s.companyLogo.url ? s.companyLogo.url.substring(0, 25) + "..." : "",
            imageUrl: this.companyInfo.companyLogo?.url, required: false, type: FormType.media, index: 0, style: "border: none; border-bottom: 1px solid gray; border-radius: 0;" },
          ]
        },
        {
          class: 'col-md-6 col-12', components: [
            { label: 'companySize', control: 'companySize', options: this.companySizeOptions, required: true, type: FormType.dropDown, style: "border: none; border-bottom: 1px solid gray; border-radius: 0;" },
            { label: 'industries', control: 'industries',
            msSettings: {
              singleSelection: false,
              idField: 'item_id',
              textField: 'item_text',
              selectAllText: 'Select All',
              unSelectAllText: 'UnSelect All',
              itemsShowLimit: 'All',
              allowSearchFilter: false,
              limitSelection: 5,
              enableCheckAll: false
            }, required: true, options: this.industryOptions, type: FormType.multiSelect, style: "border: none; border-bottom: 1px solid gray; border-radius: 0;" },
         //   { label: 'number of Employers',  required: true, type: FormType.text, style: "border: none; border-bottom: 1px solid gray; border-radius: 0;" },

          ]
        },
          {
            class: ' col-12', components: [
              { label: 'about', control: 'about', required: true, type: FormType.textArea, style: "border: none; border-bottom: 1px solid gray; border-radius: 0;" }
            ]
          }
        ];

        this.formGroupCompanyInfo = new FormGroup({
          companyName: new FormControl(s ? s.companyName : '', Validators.required),
          website: new FormControl(s ? s.website : '', Validators.required),
          companyLogo: new FormControl(s ? s.companyLogo : null),
          companySize: new FormControl(s ? s.companySize : '', Validators.required),
          industries: new FormControl(industryArray, Validators.required),
          about: new FormControl(s ? s.about : '', Validators.required)
        });
      });
    });
  }

  createAddressForm(){
    this.employerMeService.getAddress().subscribe(x => {
      this.address = x as EmployerAddressModel;

      let s = this.address ? this.address : null;

        this.countryService.ServerGetWithoutLogin().subscribe(f => {
          let countries = f.content;
          this.countryOptions = countries.map(c => ({ value: c.id, label: c.defaultLocalization.name }));

          if(this.address.country){
            this.cityService.getCountryCities(this.address.country.id).subscribe(c=>{

              this.cityOptions = c.map(c => ({ value: c.id, label: c.defaultLocalization.name }));

              this.formAddress = [{
                class: 'col-md-6 col-12', components: [
                  { label: 'addressLine1', control: 'line1', labelIcon:'fas fa-check mr-1' , required: true, type: FormType.text, style: "border: none; border-bottom: 1px solid gray; border-radius: 0;" },
                  { label: 'country', control: 'country', options: this.countryOptions,labelIcon:'fas fa-check mr-1' , onChange: this.countryChange.bind(this), required: true, type: FormType.dropDown, style: "border: none; border-bottom: 1px solid gray; border-radius: 0;" },
                  { label: 'zipCode', labelIcon:'fas fa-check mr-1' , control: 'zipCode', required: true, type: FormType.text, style: "border: none; border-bottom: 1px solid gray; border-radius: 0;" },
                ]
              },
              {
                class: 'col-md-6 col-12', components: [
                  { label: 'addressLine2', labelIcon:'fas fa-check mr-1' , control: 'line2', required: false, type: FormType.text, style: "border: none; border-bottom: 1px solid gray; border-radius: 0;" },
                  { label: 'city', control: 'city', labelIcon:'fas fa-check mr-1' , options: this.cityOptions, required: true, type: FormType.dropDown, style: "border: none; border-bottom: 1px solid gray; border-radius: 0;" },
                ]
              }];

              this.formGroupAddress = new FormGroup({
                line1: new FormControl(s ? s.line1 : "", Validators.required),
                country: new FormControl(s ? s.country?.id : null, Validators.required),
                zipCode: new FormControl(s ? s.zipCode : "", Validators.required),
                line2: new FormControl(s ? s.line2 : ""),
                city: new FormControl(s ? s.city?.id : null, Validators.required)
              });
            });
          }

          else{
            this.address.country = new GeneralIdModel();
            this.address.city = new GeneralIdModel();
            this.cityOptions = [];

            this.formAddress = [{
              class: 'col-md-6 col-12', components: [
                { label: 'addressLine1', control: 'line1', required: true, type: FormType.text, style: "border: none; border-bottom: 1px solid gray; border-radius: 0;" },
                { label: 'country', control: 'country', options: this.countryOptions, onChange:this.countryChange.bind(this), required: true, type: FormType.dropDown, style: "border: none; border-bottom: 1px solid gray; border-radius: 0;" },
                { label: 'zipCode', control: 'zipCode', required: true, type: FormType.text, style: "border: none; border-bottom: 1px solid gray; border-radius: 0;" },
              ]
            },
            {
              class: 'col-md-6 col-12', components: [
                { label: 'addressLine2', control: 'line2', required: false, type: FormType.text, style: "border: none; border-bottom: 1px solid gray; border-radius: 0;" },
                { label: 'city', control: 'city', options: this.cityOptions, required: true, type: FormType.dropDown, style: "border: none; border-bottom: 1px solid gray; border-radius: 0;" },
              ]
            }];

            this.formGroupAddress = new FormGroup({
              line1: new FormControl(s ? s.line1 : "", Validators.required),
              country: new FormControl(s ? s.country?.id : null, Validators.required),
              zipCode: new FormControl(s ? s.zipCode : "", Validators.required),
              line2: new FormControl(s ? s.line2 : ""),
              city: new FormControl(s ? s.city?.id : null, Validators.required)
            });
          }

      });
    });
  }

  countryChange(data){

    this.cityService.getCountryCities(data).subscribe(c=>{
      this.cityOptions = c.map(c => ({ value: c.id, label: c.defaultLocalization.name }));
      this.formAddress[1].components[1].options = this.cityOptions;
      this.formAddr.city.setValue(null);
    });

  }

  createSocialLinksForm(){
    this.employerMeService.getSocialLinks().subscribe(x => {
      this.socialLinks = x as EmployerSocialLinkModel;

      let s = this.socialLinks ? this.socialLinks : null;

          this.formSocialLink = [{
            class: 'col-md-6 col-12',  components: [
              {  label: 'linkedinProfileLink', control: 'linkedinAccount', labelIcon: "fab fa-linkedin mr-1", required: false, type: FormType.text, style: "border: none; border-bottom: 1px solid gray; border-radius: 0;" },
              { label: 'facebookProfileLink', control: 'facebookAccount', labelIcon: "fab fa-facebook-f mr-1", required: false, type: FormType.text, style: "border: none; border-bottom: 1px solid gray; border-radius: 0;" },
              { label: 'atsAdditional', control: 'atsAdditional', required: false, type: FormType.text, style: "border: none; border-bottom: 1px solid gray; border-radius: 0;" }
            ]
          },
          {
            class: 'col-md-6 col-12', components: [
              { label: 'instagramProfileLink', control: 'instagramAccount', labelIcon: "fab fa-instagram mr-1", required: false, type: FormType.text, style: "border: none; border-bottom: 1px solid gray; border-radius: 0;" },
              { label: 'twitterProfileLink', control: 'twitterAccount', labelIcon: "fab fa-twitter mr-1", required: false, type: FormType.text, style: "border: none; border-bottom: 1px solid gray; border-radius: 0;" }
            ]
          }];

          this.formGroupSocialLink = new FormGroup({
            linkedinAccount: new FormControl(s ? s.linkedinAccount : ""),
            facebookAccount: new FormControl(s ? s.facebookAccount : ""),
            atsAdditional: new FormControl(s ? s.atsAdditional : ""),
            instagramAccount: new FormControl(s ? s.instagramAccount : ""),
            twitterAccount: new FormControl(s ? s.twitterAccount : "")
          });
        });
  }

  get formGI() { return this.formGroupGeneralInfo.controls }
  get formCP() { return this.formGroupContactPerson.controls }
  get formComI() { return this.formGroupCompanyInfo.controls }
  get formAddr() { return this.formGroupAddress.controls }
  get formSL() { return this.formGroupSocialLink.controls }

  goBack() {
    this.step--;
  }

  goNext() {

    switch (this.step) {
      case 1:
        if(this.getDirtyValues(this.formGroupGeneralInfo).length > 0) this.saveGeneralInfo();
        break;
      case 2:
        if(this.getDirtyValues(this.formGroupContactPerson).length > 0) this.saveContactPerson();
        break;
      case 3:
        if(this.getDirtyValues(this.formGroupCompanyInfo).length > 0) this.saveCompanyInfo();

        break;
      case 4:
        if(this.getDirtyValues(this.formGroupAddress).length > 0) this.saveAddress();

        break;
    }

    this.step++;
  }

  getDirtyValues(form: any) {

    let dirtyValues = [];

    Object.keys(form.controls)
       .forEach(key => {
          let currentControl = form.controls[key];

          if (currentControl.dirty) {
             if (currentControl.controls)
             dirtyValues.push(this.getDirtyValues(currentControl));
             else
                dirtyValues.push(currentControl.value);
          }
       });

    return dirtyValues;
 }


  saveGeneralInfo() {
    this.generalInfos.email = this.formGI.email.value;
    this.generalInfos.firstName = this.formGI.firstName.value;
    this.generalInfos.lastName = this.formGI.lastName.value;
      // this.generalInfos.phoneNumber.countryCallingCode = this.formGI.countryCode.value;
      // this.generalInfos.phoneNumber.phoneNumber = this.formGI.phoneNumber.value;

    this.usersMeService.putGeneralInfo(this.generalInfos).subscribe(x => {
        this.createGeneralInfoForm();
        this.notification.success(this.translate.transform('generalInfoHasBeenSuccessfullyUpdated'));
    });
  }

  saveContactPerson() {
    this.contactPersons.email = this.formCP.email.value;
    this.contactPersons.firstName = this.formCP.firstName.value;
    this.contactPersons.lastName = this.formCP.lastName.value;
     if(!this.formCP.phoneNumber.value || this.formCP.phoneNumber.value == "") this.contactPersons.phoneNumber = null;
     else{
       this.contactPersons.phoneNumber.countryCallingCode = this.formCP.countryCode.value;
       this.contactPersons.phoneNumber.phoneNumber = this.formCP.phoneNumber.value;
     }

    this.employerMeService.putContactPerson(this.contactPersons).subscribe(x => {
      this.updatedPhoneNumber = true;
        this.createContactPersonForm();

        this.notification.success(this.translate.transform('contactPersonHasBeenSuccessfullyUpdated'));
    });
  }


  saveAddress() {

    this.address.line1 = this.formAddr.line1.value;
    this.address.line2 = this.formAddr.line2.value;
    this.address.country.id = this.formAddr.country.value;
    this.address.city.id = this.formAddr.city.value;
    this.address.zipCode = this.formAddr.zipCode.value;

    this.employerMeService.putAddress(this.address).subscribe(x => {
      this.createAddressForm();
      this.notification.success(this.translate.transform('addressHasBeenSuccessfullyUpdated'));
    });
  }


  saveCompanyInfo() {

    let formData: any = new FormData();

    this.companyInfo.companyName = this.formComI.companyName.value;
    this.companyInfo.website = this.formComI.website.value;
    this.companyInfo.companySize = this.formComI.companySize.value;
    this.companyInfo.about = this.formComI.about.value;
    this.companyInfo.companyLogo = null;
    if(this.formComI.industries.value[0].item_id){
      this.companyInfo.industries = this.formComI.industries.value.map(x => ({ id: x.item_id }));
    }
    else this.companyInfo.industries = this.formComI.industries.value.map(x => ({ id: x }));

    let companyInfo = new Blob([JSON.stringify(this.companyInfo)], { type: 'application/json' });
    formData.append('companyInfo', companyInfo);


    if(this.formComI.companyLogo.value && !this.formComI.companyLogo.value.url) {
      formData.append('companyLogo', this.formComI.companyLogo.value);
    }

    this.employerMeService.putCompanyInfos(formData).subscribe(x => {
      this.createCompanyInfoForm();
      this.notification.success(this.translate.transform('companyInfoHasBeenSuccessfullyUpdated'));
    });

  }


  saveSocialLink() {

    this.socialLinks.linkedinAccount = this.formSL.linkedinAccount.value;
    this.socialLinks.facebookAccount = this.formSL.facebookAccount.value;
    this.socialLinks.atsAdditional = this.formSL.atsAdditional.value;
    this.socialLinks.instagramAccount = this.formSL.instagramAccount.value;
    this.socialLinks.twitterAccount = this.formSL.twitterAccount.value;

    this.employerMeService.putSocialLinks(this.socialLinks).subscribe(x => {

        this.createSocialLinksForm();
        this.notification.success(this.translate.transform('socialLinksHasBeenSuccessfullyUpdated'));
      });

  }


  finish() {
    if(this.getDirtyValues(this.formGroupSocialLink).length > 0) this.saveSocialLink();

    this.router.navigate(['/dashboard']);
  }

  backToPay(){
    this.router.navigate(['/dashboard/checkout-form/'+ this.pageType]);
  }
}
