export class EmployerSocialLinkModel {
  linkedinAccount: string;
  facebookAccount: string;
  twitterAccount: string;
  instagramAccount: string;
  atsAdditional: string;

  constructor() {
    this.linkedinAccount = '';
    this.facebookAccount = '';
    this.twitterAccount = '';
    this.instagramAccount = '';
    this.atsAdditional = '';
  }
}
