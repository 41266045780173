import { JobModel } from './../../models/job/job.model';
import { TranslatePipe } from './../../pipes/translate/translate.pipe';
import { RecruiterUserModel } from './../../models/recruiter/recruiter-user.model';
import { RecruiterService } from './../../services/recruiter/recruiter.service';
import { ApplicationModel } from './../../models/candidate/application.model';
import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { ReviewService } from '../../services';

@Component({
  selector: 'app-candidate-detail-popup',
  templateUrl: './candidate-detail-popup.component.html',
  styleUrls: ['./candidate-detail-popup.component.scss']
})
export class CandidateDetailPopupComponent implements OnInit, OnChanges {

  @Input() application: ApplicationModel;
  @Input() job: JobModel;
  recruiter: RecruiterUserModel = new RecruiterUserModel();
  recruiters: RecruiterUserModel[] = [];
  @Output() openUpdateCandidateStatusEmitter = new EventEmitter();
  @Output() openReviewsEmitter = new EventEmitter();

  constructor(private recruiterService: RecruiterService, private translate: TranslatePipe, private reviewService: ReviewService) { }
  ngOnChanges(): void {
    this.getRecruiterDetails();
  }

  ngOnInit(): void {

  }
  goToLink(url: string) {
    window.open(url, "_blank");
  }
  openUpdateCandidateStatus(application: ApplicationModel) {
    this.openUpdateCandidateStatusEmitter.emit(application);
  }
  openReviewModal(reviews) {
    this.openReviewsEmitter.emit(reviews);
    // @ts-ignore
    $('#review-popup').modal('show');
  }

  getRecruiterDetails() {
    if (this.application == undefined) {
      return;
    }
    this.recruiterService.ServerGet().subscribe(x => {
      this.recruiters = x.content;
      this.recruiter = this.recruiters.filter(x => x.id == this.application?.recruiterId)[0];

      this.reviewService.ServerGetWithText('/?recruiterId=' + this.recruiter?.id).subscribe(x => {
        this.recruiter.reviews = x.content;
        this.recruiter.reviews.forEach(x => {
          x.date = new Date(x.createdAt).toLocaleDateString();
        })
        const sum = x.content.reduce((a, b) => a + b.rating, 0);
        const avg = (sum / x.content.length) || 0;
        this.recruiter.reviewCount = x.totalElements;
        this.recruiter.star = avg;
        this.recruiter.functionalRolesText = this.recruiter.functionalRoles.map(m => ' ' + m.name).toString();
        this.recruiter.industriesText = this.recruiter.industries.map(m => ' ' + m.name).toString();
        this.recruiter.requisitionTypesText = this.recruiter.requisitionTypes.map(m => ' ' + this.translate.transform(m + 'REC')).toString();

      });
    });

  }

}
