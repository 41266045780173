import { Injectable } from '@angular/core';
import {AuthService} from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor( private authService: AuthService) { }
  private get functionalRoles(): string[] {
    return JSON.parse(localStorage.getItem('functionalRolesMine'));
  }

  private get permissions(): string[] {
    return JSON.parse(localStorage.getItem('permissions'));
  }

  permissionsOnly(perm: string[]): boolean {
    for (let i = 0; i <= perm.length; i++) {
      if (this.permissions.includes(perm[i])) {
        return true;
      }
    }
    return false;//moolieden sonra false olacak
  }
  functionalRolesOnly(perm: string[]): boolean {
    /*var role = JSON.parse(window.atob((this.authService.getToken()).split('.')[1])).role;
    if (role=='NEW_SUBSCRIBER')
      return false;*/

    for (let i = 0; i <= perm.length; i++) {
      if (this.functionalRoles.includes(perm[i])) {
        return true;
      }
    }
    return false;//moolieden sonra false olacak
  }
}
