import { Component, OnInit } from '@angular/core';

@Component({
             selector: 'app-browse-jobs',
             templateUrl: './browse-jobs.component.html',
             styleUrls: ['./browse-jobs.component.scss']
           })
export class BrowseJobsComponent implements OnInit {

  activeTab = 0;

  constructor() {
  }

  ngOnInit() {
  }

}
