<div class="container-fluid px-5 pb-5">

  <div class="header mt-3">{{'packages' | translate}}</div>
  <div class="row mt-4 text-center">
    <!--    <div class="col-6 switch2"
             [ngClass]="selectedTab===AdminPacketTabs.Packets?' switch-active':''"
             (click)="selectTab(AdminPacketTabs.Packets)">-->
    <div class="col-6 switch2"
    >
      {{AdminPacketTabs.Packages | translate}}
    </div>
    <!--    <div class="col-6 switch2"
             [ngClass]="selectedTab===AdminPacketTabs.BusinessFunctions?' switch-active':''"
             (click)="selectTab(AdminPacketTabs.BusinessFunctions)">-->
    <div class="col-6 switch2"
    >
      {{AdminPacketTabs.BusinessFunctions | translate}}
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-6 border-right">
      <div class="row">
        <div class="col-12">
          <div class="switch6 mt-3">
            <label class="switch6-light">
              <span style="cursor: pointer;">
                <span [ngClass]="selectedUserType === UserTypeEnum.RECRUITER ? 'white-text swtich-button' : 'gray-text'"
                      (click)="selectCategory(UserTypeEnum.RECRUITER)">{{ UserTypeEnum.RECRUITER | uppercase }}</span>
                <span [ngClass]="selectedUserType === UserTypeEnum.EMPLOYER ? 'white-text swtich-button' : 'gray-text'"
                      (click)="selectCategory(UserTypeEnum.EMPLOYER)">{{UserTypeEnum.EMPLOYER  | uppercase }}</span>
              </span>
            </label>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <app-admin-packet-edit #adminPacketEditComponent [userType]="selectedUserType"></app-admin-packet-edit>
        </div>
      </div>
    </div>
    <div class="col-6 mt-4">
      <app-admin-packets-bussines-functions (refreshData)="adminPacketEditComponent.getAllBusinessRoles()" ></app-admin-packets-bussines-functions>
    </div>
  </div>


</div>


