<div class="pl-4 pt-4">

  <h3 class="title">{{'applications'|translate}}</h3>
  <p class="sub-title">{{'baseOnYourPreferences' | translate }}
  </p>
  <div class="col-12 overflow-auto hide-scroll my-3 p-0 tabs">
        <span (click)="changeActiveTab(0)"
              [ngClass]="getTabClass(0)"
              class="tab-span">{{'allApplications'|translate|titlecase}}</span>

    <span (click)="changeActiveTab(1)"
          [ngClass]="getTabClass(1)"
          class="tab-span">{{'savedApplications'|translate|titlecase}}</span>
  </div>

  <div class="search-filter-sort-box justify-content-end d-flex">

    <div class="filter-sort-box">
      <div class="filter-box">
        <app-application-all-filters (clearFilter)="clearFilter($event)"
                                     (resultFilter)="getFilteredApplications($event)"></app-application-all-filters>
      </div>
      <div class="sort-box">
        <select (ngModelChange)="sortChanged($event)"
                [(ngModel)]="sort"
                class="sort-select"
                id="sort"
                name="sort">
          <option [ngValue]="'createdAt,desc'"
                  disabled
                  selected>{{'sort' | translate}}</option>
          <option [ngValue]="'createdAt,desc'">{{'newest' | translate}}</option>
          <option [ngValue]="'createdAt,asc'">{{'oldest' | translate}}</option>
        </select>
      </div>
    </div>
  </div>

  <tlb-empty-page *ngIf="!applicationss || applicationss.length == 0"
[warningLabel]="searchText == ''  ? 'noApplicationYet' : searchText" [filterText]="searchText"></tlb-empty-page>

  <div *ngIf="applications | async as apps"
       class="table-box">
    <table class="table" *ngIf="applicationss && applicationss.length > 0"  >
      <thead>
      <tr>
        <th></th>
        <th class="text-left">{{'candidateName' | translate}}</th>
        <th>{{'jobTitle' | translate}}</th>
        <th>{{'recruiter' | translate}}</th>
        <th>{{'appliedOn' | translate}}</th>
        <th>{{'status' | translate}}</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let application of apps | paginate:{ itemsPerPage: requestPayload.limit, currentPage: currentPage,totalItems:count}">
        <td class="profile-image-cell"  [routerLink]="'/dashboard/applications/'+ application.id">
          <div [style.background-color]="application.candidate.imageColor"
               class="preimage"><span style="color: white;">{{application.candidate.imageText}}</span></div>
        </td>
        <td class="text-left"  [routerLink]="'/dashboard/applications/'+ application.id">
          <div class="candidate-text">{{application.candidate.fullName}}</div>
          <div class="e-mail-text">{{application.candidate.email}}</div>
        </td>
        <td *ngIf="application.$job | async as job"  [routerLink]="'/dashboard/applications/'+ application.id">
          <div class="job-title-text">{{job.title}}</div>
        </td>
        <td *ngIf="application.$recruiter| async as recruiter">
          <div [routerLink]="'/recruiter-details/'+recruiter.id +'/1'"
               class="recruiter-text">{{recruiter?.user?.fullName}}</div>
        </td>
        <td>
          <div class="applied-on-text"  [routerLink]="'/dashboard/applications/'+ application.id">
            {{application.createdAt | date: 'dd/MM/YYYY'}}
          </div>
        </td>
        <td>
          <div [className]="getStatusClass(application.status) + ' status-text'"  [routerLink]="'/dashboard/applications/'+ application.id">
            {{application.status | translate}}
          </div>
        </td>
        <td><i class="fa-bookmark cursor-pointer"  [ngClass]="application.save?'fas':'far'" (click)="!application.save?saveApplication(application):unSaveApplication(application)"></i></td>
      </tr>
      </tbody>

    </table>
    <div *ngIf="apps && apps.length > 0"
         class=" container-fluid row mt-3 pagination">
      <pagination-controls (pageChange)="currentPage = $event;getApplications()"
                           [autoHide]="count <= requestPayload.limit"
                           nextLabel="{{'next' | translate}}"
                           previousLabel="{{'previous' | translate}}"
                           responsive="true"></pagination-controls>
    </div>
  </div>

</div>

