<div class="container-fluid mt-4">

  <div class="header">
    {{ 'activityForThisWeek' | translate }}
  </div>

  <div class="row mt-4">
    <div class="col-12">
      <div class="row">
        <div class="col-12 col-lg-3 col-xl-3 my-2 my-xl-0">
          <div class="card total-rec-card">
            <div class="card-body">
              <div class="col-12">
                <div class="row">
                  <div class="card-titles col-10 mt-2">{{'totalRecruiterCount' | translate}}</div>
                  <div class="col-2"><img src="../../../assets/images/total-rec.png" alt="" width="18" height="18"
                      class="img-fluid m-10"></div>
                </div>

                <div class="card-counts">{{recruitersCount}}</div>

                <div class="arrow-icon">
                  <i class="fas fa-arrow-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-3 col-xl-3 my-2 my-xl-0">
          <div class="card total-emp-card">
            <div class="card-body">
              <div class="col-12">
                <div class="row">
                  <div class="card-titles col-10 mt-2">{{'totalEmployerCount' | translate}}</div>
                  <div class="col-2"><img src="../../../assets/images/total-rec.png" alt="" width="18" height="18"
                      class="img-fluid m-10"></div>
                </div>

                <div class="card-counts">{{employerCount}}</div>

                <div class="arrow-icon">
                  <i class="fas fa-arrow-right"></i>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="col-12 col-lg-3 col-xl-3 my-2 my-xl-0">
          <div class="card total-job-card">
            <div class="card-body">
              <div class="col-12">
                <div class="row">
                  <div class="card-titles col-10 mt-2">{{'numberOfTotalJobs' | translate}}</div>
                  <div class="col-2"><img src="../../../assets/images/total-job.png" alt="" width="18" height="18"
                      class="img-fluid m-10"></div>
                </div>

                <div class="card-counts">{{jobsCount}}</div>

                <div class="arrow-icon">
                  <i class="fas fa-arrow-right"></i>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="col-12 col-lg-3 col-xl-3 my-2 my-xl-0">
          <div class="card job-closed-card">
            <div class="card-body">

              <div class="col-12">
                <div class="row">
                  <div class="card-titles col-10 mt-2">{{'jobsClosed' | translate}}</div>
                  <div class="col-2"><img src="../../../assets/images/job-closed.png" alt="" width="18" height="18"
                      class="img-fluid m-10"></div>
                </div>

                <div class="card-counts">{{closedJobCount}}</div>

                <div class="arrow-icon">
                  <i class="fas fa-arrow-right"></i>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>


</div>
