import { DropdownOptionsModel } from './../../../shared/models';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CandidateService } from 'src/app/shared/services';
import { ApplicationStatus } from 'src/app/shared/enums/application/application-status.enum';
import { EmployerService } from 'src/app/shared/services/uaa/employer/employer.service';

@Component({
  selector: 'app-track-applications-filter',
  templateUrl: './track-applications-filter.component.html',
  styleUrls: ['./track-applications-filter.component.scss']
})
export class TrackApplicationsFilterComponent implements OnInit {

  applicationStatusDropDown: DropdownOptionsModel[] = [];
  statusLabel: string = "status";
  candidatesDropDown: DropdownOptionsModel[] = [];
  candidatesLabel: string = "candidate";
  employersDropDown: DropdownOptionsModel[] = [];
  employersLabel: string = "employer";
  sortOptions: DropdownOptionsModel[] = [];
  sortLabel: string = "";
  filterData: any = {};
  filterText: string = "";
  filterState: boolean;

  selectedStatus: any[] = [];
  selectedCandidates: any[] = [];
  selectedEmployers: any[] = [];
  isFilterOpen = true;

  @Output() resultFilter = new EventEmitter();
  @Output() clearFilter = new EventEmitter();

  @Output() resultSort = new EventEmitter();
  @Output() resultSearch = new EventEmitter();

  constructor(private candidateService: CandidateService, private employerService: EmployerService) { }

  ngOnInit(): void {
    this.getOptions();
    //@ts-ignore
    $(document).on("click", function (event) {
      //@ts-ignore
      var $trigger = $("#filer-dropdown");
      //@ts-ignore
      if ($trigger !== event.target && !$trigger.has(event.target).length && $("#filter-dropdown-menu").css('display') == 'block') {
        //@ts-ignore
        $("#filterButton").click();
      }
    });
  }

  closeFilterPopup(e) {
    console.log(e);

    this.filterState = false;
  }

  getOptions() {
    this.filterData.candidateId = "";
    this.filterData.employerId = "";
    this.filterData.status = "";

    this.statusLabel = "";
    this.employersLabel = "";
    this.candidatesLabel = "";

    this.selectedCandidates = this.selectedEmployers = this.selectedStatus = [];

    this.sortOptions = [
      { label: 'noSorting', value: 'noSort' },
      { label: 'sortByDateCreatedAZ', value: 'ecratedAt,ASC' },
      { label: 'sortByDateCreatedZA', value: 'createdAt,DESC' }
    ];

    this.applicationStatusDropDown = Object.keys(ApplicationStatus).map(c => ({ value: c, label: c }));

    this.candidateService.ServerGet()
      .subscribe((res: any) => {
        this.candidatesDropDown = res.content.map(c => ({ value: c, label: c.fullName }));
      });

    this.employerService.ServerGet()
      .subscribe((res: any) => {
        this.employersDropDown = res.content.map(c => ({ value: c, label: c.createdBy.name }));

      });

  }

  selectStatus(datas) {
    this.filterData.status = '';
    for (const data of datas) {
      if (this.filterData.status === '') {
        this.filterData.status = data;
      } else {
        this.filterData.status = this.filterData.status + ',' + data;
      }
    }
    this.statusLabel = datas.length > 0 ? datas.length + " selected" : "status";
    this.selectedStatus = [...datas];
  }

  selectCandidate(datas) {
    this.filterData.candidateId = '';
    for (const data of datas) {
      if (this.filterData.candidateId === '') {
        this.filterData.candidateId = data.id;
      } else {
        this.filterData.candidateId = this.filterData.candidateId + ',' + data.id;
      }
    }

    this.candidatesLabel = datas.length > 0 ? datas.length + " selected" : "candidate";
    this.selectedCandidates = [...datas];
  }

  selectEmployer(datas) {
    this.filterData.employerId = '';
    for (const data of datas) {
      if (this.filterData.employerId === '') {
        this.filterData.employerId = data.id;
      } else {
        this.filterData.employerId = this.filterData.employerId + ',' + data.id;
      }
    }

    this.employersLabel = datas.length > 0 ? datas.length + " selected" : "employer";
    this.selectedEmployers = [...datas];

  }

  emitData() {
    this.filterState = false;
    this.resultFilter.emit(this.filterData);

  }

  clearFilterEmit() {
    this.filterData.status = '';
    this.filterData.candidateId = '';
    this.filterData.employerId = '';

    this.getOptions();
    this.clearFilter.emit(this.filterData);
  }

  selectSort(data) {
    this.sortLabel = data.label;
    this.resultSort.emit(data.value);
  }

  search() {
    this.resultSearch.emit(this.filterText);
  }

  clearSearch(){
    this.filterText = "";
    this.resultSearch.emit(this.filterText);
  }

  keyup(data) {
    data == "" ? this.resultSearch.emit("") : "";
  }

  clickedSort(){
    this.filterState = false;
  }

}
