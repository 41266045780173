import { GeneralIdNameModel } from '../general';
import { AuditedModel }       from '../general/audited.model';
import { RecruiterUserModel } from '../recruiter/recruiter-user.model';
import { Observable }         from 'rxjs';
import { JobModel }           from '../job';

export class ApplicationModel extends AuditedModel {
  id: string;
  candidate: Candidate;
  recruiterId: string;
  $recruiter: Observable<RecruiterUserModel>;
  $job: Observable<JobModel>;
  recruiter: RecruiterUserModel;
  job: JobModel;

  employer: GeneralIdNameModel;
  status: ApplicationStatusEnum;
  selectableApplicationStatuses: ApplicationStatusEnum[] = [];
  save: boolean;
  answers: any[];

  constructor() {
    super();
    this.id = '';
    this.candidate = new Candidate();
    this.recruiterId = '';
    this.employer = new GeneralIdNameModel();
    this.status = ApplicationStatusEnum.SUBMITTED;
    this.selectableApplicationStatuses = [];
    this.save = false;
    this.answers = [];
  }
}

export class Candidate extends AuditedModel {
  id: string;
  status: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: PhoneNumber;
  resume: Resume;
  coverLetter: CoverLetter;
  skillTags: SkillTag[];
  fullName: string;
  imageText?: string;
  imageColor?: string;

  constructor() {
    super();
    this.id = '';
    this.status = status;
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.phoneNumber = new PhoneNumber();
    this.resume = new Resume();
    this.coverLetter = new CoverLetter();
    this.skillTags = [];
    this.fullName = '';
  }
}

export class CoverLetter {
  content: string;
  url: string;

  constructor() {
    this.content = '';
    this.url = '';
  }
}

export class PhoneNumber {
  countryCallingCode: string;
  phoneNumber: string;

  constructor() {
    this.countryCallingCode = '';
    this.phoneNumber = '';
  }
}

export class Resume {
  title: string;
  url: string;

  constructor() {
    this.title = '';
    this.url = '';
  }
}

export class SkillTag extends GeneralIdNameModel {
  skillTagId: string;

  constructor() {
    super();
    this.name = '';
  }
}

export enum ApplicationStatusEnum {
  SUBMITTED = 'SUBMITTED',
  APPROVED = 'APPROVED',
  REJECTED_BY_CANDIDATE = 'REJECTED_BY_CANDIDATE',
  CLIENT_REVIEW = 'CLIENT_REVIEW',
  MANAGER_REVIEW = 'MANAGER_REVIEW',
  INTERVIEW_STAGE = 'INTERVIEW_STAGE',
  OFFER_STAGE = 'OFFER_STAGE',
  HIRED = 'HIRED',
  REJECTED_BY_EMPLOYER = 'REJECTED_BY_EMPLOYER',
  RESIGNED = 'RESIGNED',
  DISMISSED = 'DISMISSED',
  CANCELLED = 'CANCELLED'
}
