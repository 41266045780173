<div class="pl-xl-5 pl-1 pt-5">
    <span style="font-weight: 700; color: #323332;">{{ 'home' | translate }}</span> <span class="my-jobs-text"> <i class="fas fa-chevron-right ml-3 mr-2"></i> {{'jobProfile'|translate}}</span>
  <h4 class="pt-4">{{'jobProfile'|translate}}</h4>

  <div class="row">
    <div class="col-xl-9 col-12 pt-4 pb-4">
      <p class="tab-style"><span style="cursor: pointer;">{{'predefinedPublicJobProfiles'|translate}}</span></p>
      <div>
        <div class="card">
          <div class="card-body">
            <h4>{{'jobProfile'|translate}}</h4>
            <hr>
            <tlb-form *ngIf="formCreateJobProfile && formCreateJobProfile.length > 0"
              [forms]="formCreateJobProfile" [formGroup]="formGroupCreateJob"></tlb-form>
          </div>
        </div>
      </div>

      <div class="pt-4">
        <div class="card">
          <div class="card-body">
            <h4>{{'jobProfileSkill'|translate}}</h4>
            <hr>
            <tlb-form *ngIf="formCreateJobProfileSkill && formCreateJobProfileSkill.length > 0"
              [forms]="formCreateJobProfileSkill" [formGroup]="formGroupCreateJob"></tlb-form>
          </div>
        </div>
      </div>

      <div class="pt-4">
        <div class="card">
          <div class="card-body">
            <h4>{{'jobProfileType'|translate}}</h4>
            <hr>
            <div>
              <div class="form-check pb-3 pt-3">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                <label class="form-check-label" for="flexRadioDefault1">
                  {{'public'|translate}}
                </label>
              </div>
              <div class="form-check pb-3">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                <label class="form-check-label" for="flexRadioDefault1">
                  {{'employerSpecific'|translate}}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-3 col-12 pt-5">
      <app-employer-dashboard-activity></app-employer-dashboard-activity>
      <app-employer-dashboard-best-recruiters></app-employer-dashboard-best-recruiters>
    </div>

  </div>


</div>
