import { JobInvitationRecruiterModel } from ".";

export class JobInvitationModel {

  id: string;
  recruiters: JobInvitationRecruiterModel[];

constructor() {
  this.id = "";
  this.recruiters = [];
}
}
