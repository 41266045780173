export class JobCountsModel {

    totalJobs: number;
    totalJobsSinceLastMonth: number;
    closedJobs: number;
    closedJobsSinceLastMonth: number;

  constructor() {

    this.totalJobs = 0;
    this.totalJobsSinceLastMonth = 0;
    this.closedJobs = 0;
    this.closedJobsSinceLastMonth = 0;
  }
}
