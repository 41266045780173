import { Component, OnInit } from '@angular/core';
import { UserEmailPreferencesModel } from 'src/app/shared/models';
import { NotificationService, server, TranslatePipe, UserMeService } from 'src/app/shared/services';

@Component({
  selector: 'app-email-preferences',
  templateUrl: './email-preferences.component.html',
  styleUrls: ['./email-preferences.component.scss']
})
export class EmailPreferencesComponent implements OnInit {

  emailPreferences: UserEmailPreferencesModel;
  tmpEmailPreferences: UserEmailPreferencesModel;
  isEdited: boolean = false;

  constructor(private notification: NotificationService, private translate: TranslatePipe, private userMeService: UserMeService) { }

  ngOnInit() {
    this.userMeService.ServerGetWithText("/email-preferences").subscribe(x => {
      this.emailPreferences = x;
      this.tmpEmailPreferences = { ...this.emailPreferences }
    });
  }

  update() {
    this.userMeService.ServerPut(server.usersMe + "/email-preferences", this.emailPreferences).subscribe(x => {
      this.notification.success(this.translate.transform('yourEmailPreferencesHasBeenSuccessfullyUpdated'));
      this.emailPreferences = x;
    });
  }

}
