<div class="container-fluid px-xl-4 px-md-2 mt-2">
  <div class="row">
    <h6 class="credits ml-3">Activity</h6>

  </div>
  <div style="
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              align-content: flex-start;
              justify-content: flex-start;
              align-items: center;">

    <div class="">
      <p class="talentbase-credits">Base on your preferences</p>

    </div>
    <div class="d-flex ml-auto" style="flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: center;
    align-items: center;">
      <div class="ml-auto mt-2">
        <span class="sort-by-text">{{ 'sortBy' | translate }}:</span>
      </div>
      <div class="pr-3 mt-1">
        <tlb-dropdown (searchData)="selectSort($event)"
                      [dropDownMenuWidth]="'2rem'"
                      [dropdownName]="sortLabel != '' ? sortLabel : 'selectSort'"
                      [isFilter]="false"
                      [items]="sortOptions"
                      [style]="'background: none; border: none; box-sizing: border-box; width: 120px; height: 53px; margin: 0px 15px 0 0;'"
                      [textStyle]="'font-style: normal; font-weight: normal; font-size: 14px; color: #252B42; margin-top: 2px;'">
        </tlb-dropdown>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <svg class="mt-2"
           fill="none"
           height="2"
           style="width: 100%;"
           viewBox="0 0 1167 2"
           width="1167"
           xmlns="http://www.w3.org/2000/svg">
        <path d="M0 1H1167"
              stroke="#111111"
              stroke-opacity="0.08" />
      </svg>
    </div>
  </div>
  <div class="row">
    <div class="col-12">

      <div class="row">
        <div class="mt-3">


          <div class="dropdown drp col-12  my-2 my-lg-0"
               id="filer-dropdown">
            <button (click)="filterState = !filterState"
                    class="btn dropdown-menus form-control mb-1 d-inline"
                    id="filterButton"
                    type="button">
              <span class="text-center">{{'filters' | translate}}</span>
            </button>
            <div [style.display]="filterState ? 'block':'none'"
                 aria-labelledby="dropdownMenuButton"
                 class="dropdown-menu col-12 pb-0"
                 id="filter-dropdown-menu">

              <div class="dropdown-item color-dropdown-item"
                   id="type">

                <tlb-multiselect-dropdown (selectResult)="selectType($event)"
                                          [defaultItemKey]="''"
                                          [dropDownMenuWidth]="'15rem'"
                                          [dropdownName]="typeLabel != '' ? typeLabel : 'type'"
                                          [existItems]="selectedTypes"
                                          [isFilter]="false"
                                          [items]="typeOptions"
                                          [style]="'text-align: left; border: 1px solid #DCDCDC; box-sizing: border-box; box-shadow: 0px 2px 4px rgb(0 0 0 / 10%); border-radius: 7px; height: 40px;'"
                                          [textStyle]="'font-family: Roboto; font-style: normal; font-weight: 500; font-size: 15px; line-height: 16px; letter-spacing: 0.1px; color: #252B42 !important;'">
                </tlb-multiselect-dropdown>
              </div>

              <div class="row mr-2"
                   style="justify-content: end;">

                <div class="mt-2 mb-2 mr-1">
                  <button (click)="clearFilterEmit()"
                          class="btn clear-filter-btn"
                          type="button">{{'discard' |
                    translate}}</button>
                </div>
                <div class="mt-2">
                  <button (click)="emitData()"
                          class="btn apply-filter-btn"
                          type="button">{{'apply' |
                    translate}}</button>
                </div>


              </div>

            </div>
          </div>

        </div>

        <div class="mt-3 selected-filters-box">
          <i *ngIf="selectedTypes && selectedTypes.length > 0"
             class="fas fa-filter"
             style="color: #707683;"></i>
          <span *ngFor="let item of selectedTypes"
                class="filters-texts"
                style="margin-left: 11px">{{item | translate}},</span>
        </div>

        <div class="mt-3 ml-auto mr-2 d-flex justify-content-end"
            >
          <svg (click)="getActivities(1)"
               fill="none"
               height="19"
               style="cursor: pointer;"
               viewBox="0 0 18 19"
               width="18"
               xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1 1.99999V6.99999H1.582M1.582 6.99999C2.24585 5.35812 3.43568 3.9829 4.96503 3.08985C6.49438 2.1968 8.2768 1.8364 10.033 2.06513C11.7891 2.29386 13.4198 3.09878 14.6694 4.35377C15.919 5.60875 16.7168 7.24285 16.938 8.99999M1.582 6.99999H6M17 18V13H16.419M16.419 13C15.7542 14.6409 14.564 16.015 13.0348 16.9073C11.5056 17.7995 9.72374 18.1595 7.9681 17.9309C6.21246 17.7022 4.5822 16.8979 3.33253 15.6437C2.08287 14.3896 1.28435 12.7564 1.062 11M16.419 13H12"
              stroke="#3F3F46"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2" />
          </svg>
        </div>
      </div>


    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="mt-3">
        <div style="width:100%;height: 100%;background: #FFFFFF;
          border-radius: 4px; display: flex;
          flex-direction: column;
          align-items: flex-start;">

          <div class="container-fluid">

            <div *ngFor="let activity of activities"

                 style="margin-left: 5px;
                        margin-top: 10px;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        align-content: flex-start;
                        justify-content: flex-start;
                        align-items: center;
                        border-bottom: 1px solid #e6e7e9;
                        padding: 1rem 0">

              <div style="display: flex">
                <span class="second-css mt-2 ">
                {{activity.postDate}}d
              </span>

                <svg class="mt-2 ml-1"
                     fill="none"
                     height="14"
                     viewBox="0 0 14 14"
                     width="14"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7 4.33333V7L9 9M13 7C13 7.78793 12.8448 8.56815 12.5433 9.2961C12.2417 10.0241 11.7998 10.6855 11.2426 11.2426C10.6855 11.7998 10.0241 12.2417 9.2961 12.5433C8.56815 12.8448 7.78793 13 7 13C6.21207 13 5.43185 12.8448 4.7039 12.5433C3.97595 12.2417 3.31451 11.7998 2.75736 11.2426C2.20021 10.6855 1.75825 10.0241 1.45672 9.2961C1.15519 8.56815 1 7.78793 1 7C1 5.4087 1.63214 3.88258 2.75736 2.75736C3.88258 1.63214 5.4087 1 7 1C8.5913 1 10.1174 1.63214 11.2426 2.75736C12.3679 3.88258 13 5.4087 13 7Z"
                    stroke="#707683"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>

                <div style="margin-left: 16px ">
                  <div [style.background-color]="activity.imageColor"
                       id="preimage"><span
                    style="color: white;">{{activity.imageText}}</span></div>
                </div>
              </div>


              <div>
                <span style="margin-left: 10px"> <span style="color: #0086FF; cursor: pointer;" (click)="selectedActivity(activity)"> {{activity.type | translate}},
                </span>
                  {{activity.content}}
              </span>
              </div>

            </div>
            <tlb-empty-page *ngIf="!activities || activities.length == 0"
                            [small]="false"
                            [warningLabel]="'noNotificationYet' |translate"></tlb-empty-page>


          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<app-dashboard-view-salary-modal [selectedJobs]="selectedContent"></app-dashboard-view-salary-modal>
<app-view-salary-modal [selectedJobs]="selectedContent"></app-view-salary-modal>
<app-submit-candidate-modal [dropName]="''" [job]="selectedContent"></app-submit-candidate-modal>


<app-employer-job-detail-popup
(submitCandidateModalEmitter)="submitCandidate(selectedContent)" (viewSalaryEmitter)="OpenModalViewSalary(selectedContent)"
  [job]="selectedContent" [modalId]="'jobDetailFromNotification'"></app-employer-job-detail-popup>
