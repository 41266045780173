import {GeneralIdNameModel} from '../general';
import {JobPostModel} from './job-post.model';
import {JobCompensationModel} from './job-compensation.model';
import {ApplicationModel} from '../candidate';
import {JobQuestionnaireModel} from './job-questionnaire.model';

export class JobModel extends JobPostModel {


  employer: GeneralIdNameModel;
  compensation: JobCompensationModel;
  id: string;
  status: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;

  pendingReview: number;
  underConsideration: number;
  totalApplications: number;

 // openPosition: number;
  clientReview: number;
  managerReview: number;
  interviewStage: number;
  offerStage: number;
  rejected: number;
  approved: number;
  rejectedByEmployer: number;
  hired: number;


  favorite: boolean;
  applications: ApplicationModel[];
  allApplications: ApplicationModel[];
  postDate: number;
  logoUrl: string;
  aboutCompany: string;


  opened: boolean;
  questionnaire: JobQuestionnaireModel;
  openPosition: number;
  numberofApplicant: number;
  currencySymbol: string;

  constructor() {
    super();
    this.employer = new GeneralIdNameModel();
    this.id = '';
    this.status = '';
    this.createdAt = new Date();
    this.createdBy = '';
    this.updatedAt = new Date();
    this.updatedBy = '';
    this.postDate = 0;
    this.favorite = false;
    this.pendingReview = 0;
    this.underConsideration = 0;
    this.totalApplications = 0;
    this.applications = [];

   // this.openPosition = 0;
    this.clientReview = 0;
    this.managerReview = 0;
    this.interviewStage = 0;
    this.offerStage = 0;
    this.rejected = 0;
    this.approved = 0;
    this.logoUrl = '';
    this.aboutCompany = '';
    this.opened = false;
    this.rejectedByEmployer = 0;
    // this.date = "";
    this.hired = 0;
    this.currencySymbol = "";
  }

}
