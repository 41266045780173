import { PhoneNumberModel } from "../parameter";

export class JobAuthorizedPersonModel {

 firstName: string;
 lastName: string;
 email: string;
 phoneNumber: PhoneNumberModel;

constructor() {
  this.firstName = "";
  this.lastName = "";
  this.email = "";
  this.phoneNumber = new PhoneNumberModel();
}
}
