import { MarketPlaceService } from './../../shared/services/uaa/market-place/market-place-service';
import { ItemsModel } from './Items-model';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NotificationService, TranslatePipe } from '../../shared/services';
import { MarketsModel } from './markets-model';

@Component({
  selector: 'app-market-purchase-modal',
  templateUrl: './market-purchase-modal.component.html',
  styleUrls: ['./market-purchase-modal.component.scss']
})
export class MarketPurchaseModalComponent implements OnInit {

  market: MarketsModel[];
  yesNoTitle: string;
  yesNoText: string;
  selectedGroupId: string;
  selectedItem: ItemsModel;
  @Output() updateCredits = new EventEmitter();

  constructor(private notification: NotificationService, private translate: TranslatePipe, private marketPlaceService: MarketPlaceService) {
  }

  ngOnInit(): void {

    this.getMarketPlacePurchasing();
  }


  getMarketPlacePurchasing() {
    this.marketPlaceService.ServerGet().subscribe(res => {
      this.market = res.content;
    });
  }
  showPurchase(marketGroupId: string, item: ItemsModel) {
    this.selectedGroupId = marketGroupId;
    this.selectedItem = item;
    this.yesNoTitle = this.translate.transform('info');
    this.yesNoText = this.translate.transform('youAreAboutToPurchase') + ' \'' + this.translate.transform(item.action) + '\'. ' + this.translate.transform('areYouSure');
    // @ts-ignore
    $('#generalYesNoPopup').modal('show');
  }
  purchase(flag: boolean) {
    if (flag) {
      this.marketPlaceService.Purchase(this.selectedGroupId, this.selectedItem.id).subscribe(x => {
        this.notification.success(this.translate.transform('yourPurchaseWasSuccessful'));
        this.updateCredits.emit();
        // @ts-ignore
        $('#modalMarketPurchase').modal('hide');
      });
    }
  }


}
