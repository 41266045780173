<div>
  <header id="home" class="hero-area-2">
    <div class="overlay"></div>
    <nav class="navbar navbar-expand-md bg-inverse scrolling-navbar">
      <div class="container" style="max-width: 850px !important;">
        <a routerLink="/" class="navbar-brand"><img src="../../../assets/images/white-logo.png" width="150px" height="20px" alt="talentbase">
        <!-- <span class="pl-2 logo-text">TalentBase</span> -->
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <i class="lni-menu"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <ul class="navbar-nav mr-auto w-100">
            <li class="nav-item">
              <a class="nav-link page-scroll" href="#home">HOME</a>
            </li>
            <li class="nav-item">
              <a class="nav-link page-scroll" href="#app-features">ABOUT US</a>
            </li>
            <li class="nav-item">
              <a class="nav-link page-scroll" href="#explore">HOW IT WORKS?</a>
            </li>
            <li class="nav-item">
              <a class="nav-link page-scroll" routerLink='/pricing'>PRICING</a>
            </li>
            <li class="nav-item">
              <a class="nav-link page-scroll" href="#faq">FAQ</a>
            </li>
            <li class="nav-item">
              <a class="nav-link page-scroll" href="#contact">CONTACTS</a>
            </li>
          </ul>
          <ul class="navbar-nav w-sm-25 justify-content-end" style="display: -webkit-inline-box;">
            <li class="nav-item">
              <a class="nav-link" routerLink='/login'>SIGN IN</a>
            </li>
            <li class="nav-item">
              <a class="nav-link sign-up-design p-2" routerLink='/sign-up' href="#">SIGN UP</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!-- <div class="container">
      <div class="row space-100">
        <div class="col-lg-6 col-md-12 col-xs-12">
          <div class="contents">
            <h2 style="color: #FFA3A3;">The best way to connects
              the recruiters and the employers
            </h2>
            <p>By the help of the platform, employers will be able to choose
              the best recruiters for their vacancies
            </p>
            <div class="pt-5">
              <a class="pr-2" style="color: #FFA3A3;" href="#">Read More</a>
              <div class="dot">
                <img class="arrow-icon" src="./../../../assets/images/talentbase/up-icon.png">
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-1 col-md-12 col-xs-12"></div>
        <div class="col-lg-5 col-md-12 col-xs-12 card-pos pt-3 pt-md-3 pb-5">
          <div class="card">
            <div class="card-body">
          <div class="contact-block wow fadeInUp" data-wow-delay="0.2s">
              <div class="section-header pt-4 get-started px-3">
                <h5 class="card-title">Get started now</h5>
              </div>
              <form id="contactForm">
                <div class="row px-3">
                      <div class="col-md-9">
                        <div class="form-group">
                          <input type="text" class="form-control" id="name" name="name" placeholder="Name" required data-error="Please enter your name">
                          <div class="help-block with-errors"></div>
                        </div>
                      </div>
                      <div class="col-md-9">
                        <div class="form-group">
                          <input type="text" placeholder="Email" id="email" class="form-control" required="" data-error="Please enter your email">
                          <div class="help-block with-errors"></div>
                        </div>
                      </div>
                      <div class="col-md-9">
                        <div class="form-group">
                          <input type="text" placeholder="Phone Number" id="Phone Number" class="form-control" required="" data-error="Please enter your phone number">
                          <div class="help-block with-errors"></div>
                        </div>
                      </div>
                      <div class="col-md-9">
                        <div class="form-group">
                          <input type="text" placeholder="Company Name" id="Company Name" class="form-control" required="" data-error="Please enter your email company name">
                          <div class="help-block with-errors"></div>
                        </div>
                      </div>
                      <div class="col-md-9 pt-2">
                        <a href="#" class="btn btn-submit sign-up-text">Sign up</a>
                      </div>
                      <div class="col-md-3">
                        <img class="image-computer" src="./../../../assets/images/talentbase/contact-computer.png">
                      </div>
                      <div class="col-md-9 pt-3 pb-3">
                        <p><span class="pr-2" style="color: #2B3A4B;">Support:</span><span style="color: #381DDB;">help@talentbase.com</span></p>
                      </div>
                  </div>

              </form>
            </div>

        </div>
      </div>
    </div>
  </div>
</div> -->
  </header>
</div>
<!-- <nav class="navbar navbar-expand-lg navbar-dark px-5 shadow main-header">
  <a (click)="router.navigate(['/dashboard'])" class="navbar-brand" href="/"><img src="./../../../../assets/images/talentbase/talent-logo.png" width="30px" height="30px"
    alt="talentbase">
    <span class="pl-2 logo-text">TalentBase</span>
  </a>
  <button *ngIf="HeaderMode=='main'" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler"
          data-target="#navbarSupportedContent" data-toggle="collapse" type="button">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div *ngIf="HeaderMode!='candidate'" class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav m-auto">
      <li class="nav-item active">
        <a class="nav-link" href="#"><b>{{'whatIsIt' | translate}}</b></a>
      </li>
      <li class="nav-item active">
        <a class="nav-link" href="#"><b>{{'howItWork' | translate}}</b></a>
      </li>
      <li class="nav-item active">
        <a class="nav-link" href="#"><b>{{'successStories' | translate}}</b></a>
      </li>
      <li class="nav-item active">
        <a class="nav-link" href="#"><b>{{'faq' | translate}}</b></a>
      </li>
      <li class="nav-item active">
        <a class="nav-link" href="#"><b>{{'contactUs' | translate}}</b></a>
      </li>
    </ul>
    <form class="form-inline my-2 my-lg-0">
      <button routerLink='/login' class="btn login" type="button"><b>{{'login' | translate}}</b></button>
      <button routerLink='/' class="btn sign-up ml-2" type="button"><b>{{'signUp' | translate}}</b></button>
    </form>
  </div>
</nav> -->
