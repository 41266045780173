export class RecruiterCompanyInfoModel {

  type: string;
  companyName: string;
  positionAtCompany: string
  website: string;
  linkedinAccount: any;
  recruiterCount: string;
  firmPracticeTime: string;
  jobMarkets: any[];

  constructor() {
  this.type = "";
  this.companyName = "";
  this.positionAtCompany = "";
  this.website = "";
  this.linkedinAccount = "";
  this.recruiterCount = "";
  this.firmPracticeTime = "";
  this.jobMarkets = [];
  }
  }
