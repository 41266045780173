<!-- Modal -->
<div #myModal class="modal fade" id="exampleModalLongs">
  <div class="modal-dialog ">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title resul modal-header-title-job" id="exampleModalLongTitle">{{'postAJob' | translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body container" style="max-width: 600px;">
        <div class="form-group">
          <label for="title" class="text-muted font-weight-light">{{'jobTitle' | translate}}<span class="text-danger">*</span></label>
          <input [(ngModel)]="job.title" class="form-control" id="title" placeholder="{{'jobTitle' | translate}}">
        </div>
        <label class="my-1 mr-2 text-muted font-weight-light" for="positionCount">{{'totalNumberOfPosition' |
          translate}}<span class="text-danger">*</span></label>
        <input [(ngModel)]="job.positionCount" class="form-control" id="positionCount"
          placeholder="{{'totalNumberOfPosition' | translate}}" min="0" step="1" type="number">

        <label class="my-1 mr-2 text-muted font-weight-light" for="classification">{{'jobClassification' |
          translate}}<span class="text-danger">*</span></label>
        <tlb-dropdown [items]="classificationOptions"
          [dropdownName]="job.classification.id != '' ? classificationLabel : 'pleaseSelect'"
          (searchData)="optionChangeWithId($event, 'classification')">
        </tlb-dropdown>

        <label class="my-1 mr-2 text-muted font-weight-light" for="function">{{'jobFunction' | translate}}<span class="text-danger">*</span></label>
        <tlb-dropdown [items]="functionOptions"
          [dropdownName]="job.function.id != '' ? functionLabel : 'pleaseSelect'"
          (searchData)="optionChangeWithId($event, 'function')">
        </tlb-dropdown>
        <label class="my-1 mr-2 text-muted font-weight-light" for="industry">{{'industry' | translate}}<span class="text-danger">*</span></label>
        <tlb-dropdown [items]="industryOptions"
          [dropdownName]="job.industry.id != '' ? industryLabel : 'pleaseSelect'"
          (searchData)="optionChangeWithId($event, 'industry')">
        </tlb-dropdown>
        <label class="my-1 mr-2 text-muted font-weight-light" for="experience">{{'experienceLevel' | translate}}<span class="text-danger">*</span></label>
        <tlb-dropdown [items]="experienceLevelOptions" [isFilter]="false"
          [dropdownName]="job.experienceLevel != '' ? job.experienceLevel : 'pleaseSelect'"
          (searchData)="optionChangeWithString($event, 'experienceLevel')">
        </tlb-dropdown>

        <label class="my-1 mr-2 text-muted font-weight-light" for="education">{{'educationLevel' | translate}}<span class="text-danger">*</span></label>
        <tlb-dropdown [items]="educationLevelOptions" [isFilter]="false"
          [dropdownName]="job.educationLevel != '' ? job.educationLevel : 'pleaseSelect'"
          (searchData)="optionChangeWithString($event, 'educationLevel')">
        </tlb-dropdown>
        <label class="my-1 mr-2 text-muted font-weight-light" for="type">{{'jobType' | translate}}<span class="text-danger">*</span></label>
        <tlb-dropdown [items]="jobTypeOptions" [dropdownName]="job.type != '' ? job.type : 'pleaseSelect'"
          (searchData)="optionChangeWithString($event, 'type')" [isFilter]="false">
        </tlb-dropdown>

        <label class="my-1 mr-2 text-muted font-weight-light" for="compensation">{{'compensation' | translate}}</label>
        <div class="row">
          <div class="col">
            <label class="my-1 mr-2 text-muted font-weight-light">{{'from' | translate}}<span class="text-danger">*</span></label>
            <tlb-dropdown [items]="minAmountOptions"
              [dropdownName]="job.compensation.minAmount != '' ? job.compensation.minAmount : 'from'"
              (searchData)="optionChangeWithString($event,'compensation', 'minAmount')">
            </tlb-dropdown>
            <!-- <input [(ngModel)]="job.compensation.minAmount" class="form-control" id="compensation1"
              placeholder="{{'from' | translate}}"> -->
          </div>
          <div class="col ">
            <label class="my-1 mr-2 text-muted font-weight-light">{{'to' | translate}}<span class="text-danger">*</span></label>
            <tlb-dropdown [items]="maxAmountOptions"
              [dropdownName]="job.compensation.maxAmount != '' ? job.compensation.maxAmount : 'to'"
              (searchData)="optionChangeWithString($event,'compensation' ,'maxAmount')">
            </tlb-dropdown>
            <!-- <input [(ngModel)]="job.compensation.maxAmount" class="form-control" id="compensation2"
              placeholder="{{'to' | translate}}"> -->
          </div>
        </div>
        <br>

        <div class="form-group">
          <input [(ngModel)]="job.compensation.additional" class="form-control" id="compensation3"
            placeholder="{{'additionalCompensationInformation' | translate}}">
        </div>

        <label class="my-1 mr-2 text-muted font-weight-light" for="experienceReq">{{'experienceRequired' |
          translate}}<span class="text-danger">*</span></label>

        <div class="row">
          <div class="col">
            <input [(ngModel)]="job.experienceRequired.year" class="form-control" id="experienceReq"
              placeholder="{{'year' | translate}}" min="0" step="1" type="number">
          </div>
          <div class="col">
            <input [(ngModel)]="job.experienceRequired.month" class="form-control" id="experienceReq"
              placeholder="{{'month' | translate}}" min="0" step="1" type="number">
          </div>
        </div>
        <label class="my-1 mr-2 text-muted font-weight-light" for="relocation">{{'relocationAssistance' |
          translate}}<span class="text-danger">*</span></label>
        <tlb-dropdown [items]="relocationAssistanceOptions"
          [dropdownName]="job.relocationAssistance.type != '' ? job.relocationAssistance.type : 'pleaseSelect'"
          (searchData)="optionChangeWithString($event, 'relocationAssistance', 'type')" [isFilter]="false">
        </tlb-dropdown>
        <br>

        <div class="form-group">
          <input [(ngModel)]="job.relocationAssistance.additional" class="form-control" id="relocation"
            placeholder="{{'additionalRelocationAssistanceInformation' | translate}}">
        </div>

        <label class="my-1 mr-2 text-muted font-weight-light" for="visa">{{'visaRequirement' | translate}}<span class="text-danger">*</span></label>
        <tlb-multiselect-dropdown (selectResult)="selectVisas($event)" [isFilter]="false" [items]="visas"
          [dropdownName]="visaLabel != '' ? visaLabel : 'pleaseSelect'" [existItems]="job.visaRequirement.visas">
        </tlb-multiselect-dropdown>
        <br>

        <div class="form-group">
          <input [(ngModel)]="job.visaRequirement.additional" class="form-control" id="visa"
            placeholder="{{'additionalVisaInformation' | translate}}">
        </div>

        <div class="form-group">
          <label class="text-muted font-weight-light" for="description">{{'jobInformation' | translate}}<span class="text-danger">*</span></label>
          <textarea [(ngModel)]="job.description" class="form-control" id="description" rows="3">
       </textarea>
        </div>
        <div class="form-group">
          <label class="text-muted font-weight-light" for="skill">{{'skills' | translate}}<span class="text-danger">*</span></label>
          <input [(ngModel)]="skillsString" class="form-control" id="skill"
            placeholder="{{'addCommaToEnterMultipleSkills' | translate}}" (keyup.enter)="onEnter()">
        </div>
        <div class="form-group">
          <label class="text-muted font-weight-light" for="zip">{{'zipCode' | translate}}<span class="text-danger">*</span></label>
          <input [(ngModel)]="job.location.zipCode" type="number" class="form-control" id="zip"
            placeholder="{{'zipCode' | translate}}">
        </div>
        <div class="form-group">
          <input [(ngModel)]="job.location.additional" class="form-control" id="zip"
            placeholder="{{'additionalLocationJobInformation' | translate}}">
        </div>

        <label class="my-1 mr-2 text-muted font-weight-light" for="country">{{'country' | translate}}<span class="text-danger">*</span></label>
        <tlb-dropdown [items]="countryOptions"
          [dropdownName]="job.location.country.id != '' ? countryLabel : 'pleaseSelect'"
          (searchData)="optionChangeWithId($event, 'country')">
        </tlb-dropdown>

        <label class="my-1 mr-2 text-muted font-weight-light" for="city">{{'city' | translate}}<span class="text-danger">*</span></label>
        <tlb-dropdown [items]="cityOptions"
          [dropdownName]="job.location.city.id != '' ? cityLabel : 'pleaseSelect'"
          (searchData)="optionChangeWithId($event, 'city')">
        </tlb-dropdown>

        <div class="form-group">
          <label class="my-1 mr-2 text-muted font-weight-light" for="jobId">{{'yoursOrganizationsJobOrderNumber' |
            translate}}</label>
          <input [(ngModel)]="job.atsJobId" class="form-control" id="jobId"
            placeholder="{{'theUniqueNumberAssignedByYourApplicantTrackingSystem' | translate}}">
        </div>
        <label class="my-1 mr-2 text-muted font-weight-light" for="inlineFormCustomSelectPref">{{'selectTeamMember' |
          translate}}</label>
          <tlb-multiselect-dropdown (selectResult)="selectTeamMembers($event)" [isFilter]="false" [items]="teamMembers"
          [dropdownName]="teamMembersLabel != '' ? teamMembersLabel : 'pleaseSelect'" [existItems]="job.users">
        </tlb-multiselect-dropdown>
        <!-- <select class="custom-select my-1 mr-sm-2" id="inlineFormCrererererustomSelectPrefs">
          <option selected>Choose...</option>
          <option value="1">One</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
        </select> -->
        <label class="my-1 mr-2 text-muted font-weight-light" for="mail">{{'emailNotification' | translate}}</label>
        <tlb-dropdown [items]="emailNotificationOptions"
          [dropdownName]="job.differentNotificationEmail != '' ? 'yes' : 'no'"
          (searchData)="emailNotificationChange($event)" [isFilter]="false">
        </tlb-dropdown>
        <br>
        <div *ngIf="mailShow" class="form-group">
          <input [(ngModel)]="job.differentNotificationEmail" type="email" class="form-control" id="mail"
            placeholder="{{'differentNotificationEmail' | translate}}">
        </div>
        <!-- <p><label class="my-1 mr-2 text-muted font-weight-light" for="inlineFormCustomSelectPref">
            {{'areYouAuthorizedToApproveThePaymentOfReferralRewardAndAssoicatedFeeUponSuccessfulHire' | translate}}
          </label></p>
        <div class="row">
          <div class="col">
            <input type="radio" id="hwhyr-3" name="howLongHaveYouRecruited" value="3" class="btn-check"
              autocomplete="off" (click)="feeStatusChange(true)">
            <label class="form-check-label" for="hwhyr-3" class="btn btn-secondary">{{'yes' | translate}}</label>
          </div>
          <div class="col">
            <input type="radio" id="hwhyr-4" name="howLongHaveYouRecruited" value="4" class="btn-check"
              autocomplete="off" (click)="feeStatusChange(false)">
            <label class="form-check-label" for="hwhyr-4" class="btn btn-secondary">{{'no' | translate}}</label>
          </div>
        </div> -->

        <!-- <div *ngIf="feeShow"> -->
          <label class="my-1 mr-2 text-muted font-weight-light" for="fee">{{'fee' | translate}} {{job.fee.type == 'FIXED' ? '(in Euros)' : ''}}<span class="text-danger">*</span></label>
          <div class="row">
            <div class="col">
              <tlb-dropdown [items]="feeTypeOptions"
                [dropdownName]="job.fee.type != '' ? job.fee.type : 'pleaseSelect'"
                (searchData)="optionChangeWithString($event, 'fee', 'type')" [isFilter]="false">
              </tlb-dropdown>
            </div>
            <div class="col">
              <input [(ngModel)]="job.fee.amount" class="form-control" id="fee" placeholder="{{'amount' | translate}}"
                min="0" step=".01" type="number">
            </div>
          </div>
        <!-- </div> -->


        <hr class="my-4">

        <div class="form-group">
          <label class="text-muted font-weight-light" for="exampleInputEmail1">{{'preScreenQuestion' |
            translate}}</label>
            <div *ngFor="let question of questionsArray">
              <button class="search-button mt-2" (click)="deleteQuestion(question.index)" type="submit"><i class="fas fa-times search-icon"></i></button>
              <input  [(ngModel)]="questions[question.index]" class="form-control d-inline float-right search-input mt-2"
                id="{{question.id}}" placeholder="{{'enterQuestion' | translate}}" style="width: 92%">
            </div>

        </div>

        <button id="button-son" type="button" class="btn btn-outline btn-md mt-2 " style="border: 1px solid #ABA4F3;" (click)="addQuestion()">
          <span id="yaz" class="btn-label"><i style="padding: 10px" class="fas fa-plus"></i></span>{{'addNewQuestion' |
          translate}}</button>
        <br><br>

      </div>
      <div class="modal-footer container" style="max-width: 550px;">
        <a class="font-weight-bold anchor-preview" (click)="openPreview()">{{'preview' | translate}}  </a>
        <button type="button" class="btn btnSaveDraftJob" [disabled]="buttonDisabled()" (click)="postJob()">{{'saveAsDraft' | translate}}</button>
        <button type="button" class="btn btnSavePostJob" [disabled]="buttonDisabled()" (click)="postJob('active')">{{'saveAndPublish' | translate}}</button>
      </div>
    </div>
  </div>


</div>

<app-full-job-description-modal *ngIf="previewJob" [job]="previewJob" [employer]="true"></app-full-job-description-modal>
