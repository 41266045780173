export enum MaxAmount {
  FOURTY = 'FOURTY',
  FIFTY = 'FIFTY',
  SIXTY = 'SIXTY',
  SEVENTY = 'SEVENTY',
  EIGHTY = 'EIGHTY',
  NINETY = 'NINETY',
  ONE_HUNDRED = 'ONE_HUNDRED',
  ONE_HUNDRED_AND_TEN = 'ONE_HUNDRED_AND_TEN',
  ONE_HUNDRED_AND_TWENTY = 'ONE_HUNDRED_AND_TWENTY',
  ONE_HUNDRED_AND_THIRTY = 'ONE_HUNDRED_AND_THIRTY',
  ONE_HUNDRED_AND_FORTY = 'ONE_HUNDRED_AND_FORTY',
  ONE_HUNDRED_AND_FIFTY = 'ONE_HUNDRED_AND_FIFTY',
  ONE_HUNDRED_AND_SIXTY = 'ONE_HUNDRED_AND_SIXTY',
  ONE_HUNDRED_AND_SEVENTY = 'ONE_HUNDRED_AND_SEVENTY',
  ONE_HUNDRED_AND_EIGHTY = 'ONE_HUNDRED_AND_EIGHTY',
  ONE_HUNDRED_AND_NINETY = 'ONE_HUNDRED_AND_NINETY',
  TWO_HUNDRED = 'TWO_HUNDRED',
  TWO_HUNDRED_AND_TEN = 'TWO_HUNDRED_AND_TEN',
  TWO_HUNDRED_AND_TWENTY_FIVE = 'TWO_HUNDRED_AND_TWENTY_FIVE',
  TWO_HUNDRED_AND_FIFTY_PLUS  = 'TWO_HUNDRED_AND_FIFTY_PLUS'
}
