export enum ApplicationStatus{
  SUBMITTED="SUBMITTED",
  APPROVED="APPROVED",
  REJECTED_BY_CANDIDATE="REJECTED_BY_CANDIDATE",
  CLIENT_REVIEW="CLIENT_REVIEW",
  MANAGER_REVIEW="MANAGER_REVIEW",
  INTERVIEW_STAGE="INTERVIEW_STAGE",
  OFFER_STAGE="OFFER_STAGE",
  HIRED="HIRED",
  REJECTED_BY_EMPLOYER="REJECTED_BY_EMPLOYER",
  CANCELLED="CANCELLED"
}
