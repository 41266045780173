export class JobQuestionModel {

  content: string;
  answerType: string;
  mandatory: boolean;
  screenOrder: number;
  id?: string;

  constructor() {
    this.content = "";
    this.answerType = "";
    this.mandatory = true;
    this.screenOrder = 0;
  }
}
