import { Injectable }                              from '@angular/core';
import { ApplicationStatusEnum, ApplicationModel } from '../../../shared/models/applications/application.model';
import { ApplicationService }                      from '../../../shared/services';
import { ApplicationDetailService }                from './application-detail.service';

declare var $: any;

@Injectable({
              providedIn: 'root'
            })
export class ApplicationDetailStatusService {

  isHired = false;

  constructor(
    private applicationService: ApplicationService,
    private applicationDetailService: ApplicationDetailService
  ) {
  }

  getStatusClass(status: ApplicationStatusEnum): string {
    let className = '';
    switch (status) {
      case ApplicationStatusEnum.HIRED:
        className = 'status-success';
        break;
      case ApplicationStatusEnum.APPROVED:
      case ApplicationStatusEnum.SUBMITTED:
      case ApplicationStatusEnum.CLIENT_REVIEW:
      case ApplicationStatusEnum.INTERVIEW_STAGE:
      case ApplicationStatusEnum.MANAGER_REVIEW:
      case ApplicationStatusEnum.OFFER_STAGE:
        className = 'status-light';
        break;

      case ApplicationStatusEnum.REJECTED_BY_CANDIDATE:
      case ApplicationStatusEnum.REJECTED_BY_EMPLOYER:
      case ApplicationStatusEnum.DISMISSED:
      case ApplicationStatusEnum.RESIGNED:
      case ApplicationStatusEnum.CANCELLED:
        className = 'status-danger';
        break;

      default:
        break;
    }
    return className;
  }

  setSelectableApplicationStatuses(application: ApplicationModel): void {
    application.selectableApplicationStatuses = [];
    let statuses = [];
    switch (application.status) {

      case ApplicationStatusEnum.SUBMITTED: // Recruiter submitted candidate to the job
      case ApplicationStatusEnum.REJECTED_BY_EMPLOYER: // Employer rejected the application
      case ApplicationStatusEnum.REJECTED_BY_CANDIDATE: // Candidate rejected the application
      case ApplicationStatusEnum.CANCELLED: // Recruiter cancelled the application
      case ApplicationStatusEnum.RESIGNED: // Candidate resigned from the job
      case ApplicationStatusEnum.DISMISSED: // Candidate dismissed from the job
        break;
      case ApplicationStatusEnum.HIRED: // Employer hired the candidate
        statuses = [ApplicationStatusEnum.RESIGNED,
          ApplicationStatusEnum.DISMISSED
        ];
        application.selectableApplicationStatuses.push(...statuses);
        break;
      default:
        statuses = [ApplicationStatusEnum.CLIENT_REVIEW,
          ApplicationStatusEnum.MANAGER_REVIEW,
          ApplicationStatusEnum.INTERVIEW_STAGE,
          ApplicationStatusEnum.OFFER_STAGE,
          ApplicationStatusEnum.HIRED,
          ApplicationStatusEnum.REJECTED_BY_EMPLOYER

        ];
        application.selectableApplicationStatuses.push(...statuses);
        break;
    }
  }

  changeApplicationStatus(status: ApplicationStatusEnum, getApplication: () => void): void {

    this.isHired = false;

    switch (status) {

      case ApplicationStatusEnum.CANCELLED:
        this.applicationService.CancelApplication(this.applicationDetailService.applicationId)
            .subscribe(x => {
              getApplication();
            });
        break;
      case ApplicationStatusEnum.HIRED:
        $('#agreed-salary-amount-modal')
          .modal('toggle');
        break;
      case ApplicationStatusEnum.REJECTED_BY_EMPLOYER:
        this.applicationService.rejectApplication(this.applicationDetailService.applicationId)
            .subscribe(x => {
              getApplication();
            });
        break;
      case ApplicationStatusEnum.RESIGNED:
        this.applicationService.resignedApplication(this.applicationDetailService.applicationId)
            .subscribe(x => {
              getApplication();
            });
        break;
      case ApplicationStatusEnum.DISMISSED:
        this.applicationService.dismissedApplication(this.applicationDetailService.applicationId)
            .subscribe(x => {
              getApplication();
            });
        break;

      default:
        this.applicationService.ProgressApplication(this.applicationDetailService.applicationId, status)
            .subscribe(x => {
              getApplication();
            });
        break;

    }

  }
}
