<!-- <app-main-header [HeaderMode]="'login'"></app-main-header> -->
<div>
  <div class="col-12 col-lg-6 m-auto px-1 px-md-5 px-xl-0">
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-7">
        <div class="card shadow pt-5 d-md-block d-lg-block d-xl-block">
          <div *ngIf="success" class="card-body" style="text-align: center;">
            <img src="assets/images/mail-verified.png" alt="">
            <p class="verified-text mt-4">{{ 'verified' | translate }}</p>
            <div style="text-align: -webkit-center;">
              <p class="content">{{'successEmailVerify' | translate}}</p>
            </div>

            <div class="col-12 mb-2 mt-5 noselect"><button routerLink='/login' class="btn btn-sing-in"
              type="submit">{{'proceedToLogin'|translate}}</button></div>

          </div>

          <div *ngIf="error" class="card-body" style="text-align: center;">

            <div class="alert alert-danger mt-2">
              <div>{{'errorEmailVerify' | translate}}</div>
            </div>

          </div>

        </div>
      </div>


    </div>


</div>

</div>
