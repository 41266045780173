<div   class="modal fade  bd-example-modal-xl" id="modalMarketPurchase" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl" role="document">
    <div  class="modal-content">
      <div class="modal-header modal-xl">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div  class="modal-body">
        <h5 class="text-center"><b>{{'marketPlace' | translate}}</b></h5>
        <div class="container">

          <div *ngFor="let marketitem of market" class="card mt-5 cardColor">
            <h6 class="font-weight-bold ml-4 mt-3">{{marketitem.name | translate}}</h6>
            <div class="card-body columnColor">
                <div class="row">
                  <div class="col-9">
                    <div class="card mt-2">
                      <div class="card-body cardBodyColors ">
                        <div class="row ">
                          <div class="col-9">
                            <b>{{'title' | translate}}</b>
                          </div>
                          <div class="col-3">
                            <b>{{'talentbaseCredit' | translate}} </b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              <div class="row mt-3" *ngFor="let item of marketitem.items">
                <div class="col-9">
              <div class="card">
                <div class="card-body colonSame">
                  <div class="row ">
                    <div class="col-9">
                       {{item.action | translate}}
                    </div>
                    <div class="col-3">
                     {{ item.amount | translate}} {{'credits' | translate}}
                    </div>
                  </div>
                </div>
              </div>
                </div>
                <div class="col-3">
                  <button class="btn btnPrch btn-block h-100" *ngIf="marketitem.name=='Candidate Quota'" (click)="showPurchase(marketitem.id,item)">{{'purchaseButton' | translate}}</button>
                  <button class="btn btn-will-be-ready-soon btn-block h-100" *ngIf="marketitem.name!='Candidate Quota'" [disabled]="true">{{'willBeReadySoon' | translate}}</button>

                </div>
              </div>

              <!-- <div class="row">
                <div class="col-10">
                  <div class="card mt-2">
                    <div class="card-body colonSame">
                      <div class="row ">
                        <div class="col-9">
                          +5 {{market[0].items[0].action}} {{'candidateThisMonth' | translate}}
                        </div>
                        <div class="col-3">
                         40 {{ 'credits' | translate}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-2 mt-3">
                  <button class="btn  btnPrch btn-block">{{'purchaseButton' | translate}}</button>
                </div>
              </div> -->
            </div>
          </div>




        </div>
      </div>
    </div>
  </div>
</div>
<app-general-yes-no-popup [Title]="yesNoTitle" [Text]="yesNoText" (selection)="purchase($event)"></app-general-yes-no-popup>

