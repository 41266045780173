import { RequestPayload } from './../../../shared/classes/request-payload';
import { Component, OnInit } from '@angular/core';
import { ApplicationService, CandidateService, JobService, NotificationService, server, TranslatePipe } from 'src/app/shared/services';
import { TrackModel } from './track-model';
import { ApplicationModel, CandidateModel } from 'src/app/shared/models';
import { CreateAvatar } from 'src/app/shared/classes';

@Component({
  selector: 'app-track-candidates',
  templateUrl: './track-candidates.component.html',
  styleUrls: ['./track-candidates.component.scss']
})
export class TrackCandidatesComponent implements OnInit {

  trackCandidate: TrackModel[];
  requestPayload: RequestPayload;
  currentPage: number;
  count: number;
  editCandidateModel: CandidateModel;
  applications: ApplicationModel[] = [];
  deletedCandidate: any;
  filterText: string = "&filter=name~~";
  sortText: string = "";
  createAvatar: CreateAvatar;
  selectedCandidate: CandidateModel;
  filterTextInput: string = "";

  constructor(private candidateService: CandidateService, private applicationService: ApplicationService,
    private notification: NotificationService, private translate: TranslatePipe, private jobService: JobService) {
    this.requestPayload = new RequestPayload();
    this.editCandidateModel = new CandidateModel();
    this.requestPayload.limit = 5;
    this.createAvatar = new CreateAvatar();
  }

  ngOnInit(): void {
    this.getCandidates(1);
  }


  getCandidates(pageNo) {
    this.candidateService.ServerGetWithText(this.requestPayload.payloadURL(pageNo) + this.sortText + this.filterText)
      .subscribe((res: any) => {
        this.trackCandidate = res.content;
        this.trackCandidate.forEach(f => {
          f.imageText = this.createAvatar.create(f.firstName + " " + f.lastName);
          f.imageColor = this.createAvatar.color();
        });
        this.count = res.totalElements;
      });
  }

  openEditCandidate(candidate) {
    this.editCandidateModel = candidate;
    // @ts-ignore
    $('#editCandidateModalFromCandidates').modal('show');


  }

  openApplications(candidate) {
    this.selectedCandidate = candidate;
    this.applicationService.ServerGet()
      .subscribe((res: any) => {
        this.applications = res.content.filter(f => f.candidate.id == candidate.id);
        this.applications.forEach(x=>{

          this.jobService.ServerGetWithText("/" + x.job.id).subscribe(j=>{
            x.location = j.location;
            x.jobType = j.type;
          });
        });
        this.applications.forEach(f => {
          f.date = new Date(f.createdAt).toLocaleDateString();
        });

        // @ts-ignore
        $('#applicantsModalLong').modal('show');

      });
  }

  openDeleteCandidate(candidate) {
    this.editCandidateModel = candidate;

    // @ts-ignore
    $('#generalDeleteModal').modal('show');
  }



  deleteCandidate(e) {

    if (e) {
      this.applicationService.ServerDelete(server.candidate + '/' + this.editCandidateModel.id)
        .subscribe((res: any) => {
              // @ts-ignore
    $('#editCandidateModalFromCandidates').modal('hide');
          this.notification.success(this.translate.transform('theCandidateSuccessfullyDeleted'));
          this.getCandidates(1);
        });
    }
  }

  getSortedCandidate(e) {
    this.sortText = e == "noSort" ? "" : "&sort=" + e;
    this.getCandidates(1);
  }

  getFilteredCandidate(e) {
    this.filterTextInput = e;
    this.filterText = '&filter=name~~' + e;
    this.getCandidates(1);

  }

}
