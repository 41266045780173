export class JobInvitationRecruiterModel {

  recruiterId: string;
  recruiterEmail: string;

constructor() {
  this.recruiterId = "";
  this.recruiterEmail = "";
}
}
