import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EducationLevelType, ExperienceLevelType, FeeType, JobType, MaxAmount, MinAmount, RelocationAssistanceType } from 'src/app/shared/enums';
import { CountryModel, DropdownOptionsModel, GeneralIdModel, JobAuthorizedPersonModel, JobClassificationModel, JobFeeModel, JobFunctionModel, JobQuestionModel, JobQuestionnaireModel, PhoneNumberModel, UserModel, VisaModel, JobModel } from 'src/app/shared/models';
import { EmployerAccountModel } from 'src/app/shared/models/employer/employer-account.model';
import { CityService, CountryService, EmployerMeService, IndustryService, JobClassificationService, JobFunctionService, JobService, NotificationService, server, TranslatePipe, UserService, VisaService } from 'src/app/shared/services';

@Component({
  selector: 'app-edit-job',
  templateUrl: './edit-job.component.html',
  styleUrls: ['./edit-job.component.scss']
})
export class EditJobComponent implements OnInit {



  @Input() job: JobModel;
  @Output() postedJob: EventEmitter<any> = new EventEmitter();

  classifications: JobClassificationModel[] = [];
  classificationOptions: DropdownOptionsModel[] = [];
  classificationLabel: string = "";

  functions: JobFunctionModel[] = [];
  functionOptions: DropdownOptionsModel[] = [];
  functionLabel: string = "";

  industries: JobFunctionModel[] = [];
  industryOptions: DropdownOptionsModel[] = [];
  industryLabel: string = "";

  visas: VisaModel[] = [];
  visaLabel: string = "";

  countries: CountryModel[] = [];
  countryOptions: DropdownOptionsModel[] = [];
  countryLabel: string = "";

  cities: CountryModel[] = [];
  cityOptions: DropdownOptionsModel[] = [];
  cityLabel: string = "";

  teamMembers: UserModel[] = [];
  teamMembersLabel: string = "";

  experienceLevelOptions: DropdownOptionsModel[] = [];
  educationLevelOptions: DropdownOptionsModel[] = [];
  jobTypeOptions: DropdownOptionsModel[] = [];
  relocationAssistanceOptions: DropdownOptionsModel[] = [];
  feeTypeOptions: DropdownOptionsModel[] = [];
  emailNotificationOptions: DropdownOptionsModel[] = [];
  minAmountOptions: DropdownOptionsModel[] = [];
  maxAmountOptions: DropdownOptionsModel[] = [];

  skillsString: string = "";
  feeShow: boolean = false;
  mailShow: boolean = false;
  questionsArray: any[] = [];
  questions: any[] = [];
  employer: EmployerAccountModel;
  previewJob: any;

  constructor(private classificationService: JobClassificationService, private functionService: JobFunctionService, private industryService: IndustryService,
    private visaService: VisaService, private jobService: JobService, private countryService: CountryService, private cityService: CityService, private userService: UserService,
    private element: ElementRef, private notification: NotificationService, private translate: TranslatePipe, private employerMeService: EmployerMeService) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {

    if (document.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }
    this.element.nativeElement.focus();

  }

  ngOnChanges() {
    this.jobService.ServerGetWithText("/" + this.job.id).subscribe(x => {
      this.job = x;

      this.questionsArray = [];
      this.questions = [];
      this.getJobDetails();

    });
  }

  getJobDetails() {
    if (this.job.id != '') {

      this.job.fee.type == "PERCENT" ? this.job.fee.amount = this.job.fee.percent : "";
      this.classificationService.ServerGetWithoutLogin().subscribe(x => {
        this.classifications = x.content;
        this.classificationOptions = this.classifications.map(c => ({ value: c.id, label: c.defaultLocalization.name }));
        this.classificationLabel = this.classificationOptions.filter(x => x.value == this.job.classification.id)[0]?.label;
      });
      this.functionService.ServerGetWithoutLogin().subscribe(x => {
        this.functions = x.content;
        this.functionOptions = this.functions.map(c => ({ value: c.id, label: c.defaultLocalization.name }));
        this.functionLabel = this.functionOptions.filter(x => x.value == this.job.function.id)[0]?.label;
      });
      this.industryService.ServerGetWithoutLogin().subscribe(x => {
        this.industries = x.content;
        this.industryOptions = this.industries.map(c => ({ value: c.id, label: c.defaultLocalization.name }));
        this.industryLabel = this.industryOptions.filter(x => x.value == this.job.industry.id)[0]?.label;
      });
      this.visaService.ServerGetWithoutLogin().subscribe(x => {
        this.visas = x.content;
        this.visaLabel = this.visas.filter(x => x.id == this.job.visaRequirement.visas[0].id)[0]?.defaultLocalization.name;
      });
      this.job.skills.forEach(x => {
        this.skillsString = this.skillsString == '' ? x : this.skillsString + ',' + x;
      });
      this.countryService.ServerGetWithoutLogin().subscribe(x => {
        this.countries = x.content;
        this.countryOptions = this.countries.map(c => ({ value: c.id, label: c.defaultLocalization.name }));
        this.countryLabel = this.countryOptions.filter(x => x.value == this.job.location.country.id)[0]?.label;
      });
      this.cityService.getCountryCities(this.job.location.country.id).subscribe(x => {
        this.cityOptions = x.map(city => ({ value: city.id, label: city.defaultLocalization.name }));
        this.cityLabel = this.cityOptions.filter(x => x.value == this.job.location.city.id)[0]?.label;
      });

      this.feeShow = true;
      this.experienceLevelOptions = Object.keys(ExperienceLevelType).map(c => ({ value: c, label: c }));

      this.educationLevelOptions = Object.keys(EducationLevelType).map(c => ({ value: c, label: c }));

      this.jobTypeOptions = Object.keys(JobType).map(c => ({ value: c, label: c }));

      this.relocationAssistanceOptions = Object.keys(RelocationAssistanceType).map(c => ({ value: c, label: c }));

      this.feeTypeOptions = Object.keys(FeeType).map(c => ({ value: c, label: c }));

      this.minAmountOptions = Object.keys(MinAmount).map(c => ({ value: c, label: c }));

      this.maxAmountOptions = Object.keys(MaxAmount).map(c => ({ value: c, label: c }));


      this.userService.ServerGet().subscribe(x => {
        this.teamMembers = x.content.filter(f => f.status == "ACTIVE");
        this.teamMembersLabel = this.teamMembers.filter(x => x.id == this.job.users[0]?.id)[0]?.fullName;
      });

      if (this.job.questionnaire && this.job.questionnaire.questions && this.job.questionnaire.questions.length > 0) {
        let array = this.job.questionnaire.questions.sort((n1, n2) => n1.screenOrder > n2.screenOrder ? 1 : -1);
        array.forEach(q => {
          if (this.questionsArray && this.questionsArray.length < 10) {
            let array = [...this.questionsArray];
            this.questionsArray = [];
            array.push({ id: 'question' + array.length, index: array.length, ques: q.content });
            for (let i = 0; i < array.length; i++) {
              this.questionsArray.push({ id: 'question' + i, index: i, ques: this.job.questionnaire.questions[i]?.content });
            }
          }
        });
        this.questionsArray.forEach(x => {
          this.questions.push(x.ques);
        });

      }

      else {
        this.questionsArray = [];
        this.questions = [];

      }

    }

  }

  buttonDisabled(): boolean {
    if (this.job.title != "" && this.job.positionCount != null && this.job.positionCount != 0 && this.job.classification && this.job.classification.id != "" &&
      this.job.function && this.job.function.id != "" && this.job.industry && this.job.industry.id != "" && this.job.experienceLevel != "" &&
      this.job.educationLevel != "" && this.job.type != "" && this.job.compensation && this.job.compensation.minAmount != "" && this.job.compensation.maxAmount != "" &&
      this.job.experienceRequired && this.job.experienceRequired.month != null && this.job.experienceRequired.year != null && this.job.relocationAssistance &&
      this.job.relocationAssistance.type != "" && this.job.visaRequirement && this.job.visaRequirement.visas.length > 0 && this.job.description != "" &&
      this.skillsString != "" + this.job.location && this.job.location.zipCode != "" && this.job.location.country && this.job.location.country.id != "" &&
      this.job.location.city && this.job.location.city.id != "" && this.job.fee && (this.job.fee.amount > 0 || this.job.fee.percent > 0) && this.job.fee.type) {
      return false;
    }
    else {
      return true;
    }
  }

  updateJob(status?) {
    this.job.compensation.currency = <GeneralIdModel>{
      id: "f27b54bc-a61f-4f63-9db5-9c111665710e"
    };

    this.job.location.state = "bla bla";
    this.job.location.anyLocation = true;
    this.job.location.remote = true;
    this.job.fee.currency = <GeneralIdModel>{
      id: "f27b54bc-a61f-4f63-9db5-9c111665710e"
    };

    this.job.fee.currency = <GeneralIdModel>{
      id: "f27b54bc-a61f-4f63-9db5-9c111665710e"
    };


    let phoneNumber = <PhoneNumberModel>{
      countryCallingCode: "+90",
      phoneNumber: "12345678"
    };

    this.job.authorizedPerson = <JobAuthorizedPersonModel>{
      firstName: "Melanie",
      lastName: "Musterfrau",
      email: "melanie.musterfrau@flynx.com",
      phoneNumber: phoneNumber
    };

    this.job.fee.type == "PERCENT" ? this.job.fee.percent = this.job.fee.amount.toString() :
      this.job.fee.amount = this.job.fee.amount.toString();

    this.job.skills = this.skillsString.split(",");

    let postQuestions: JobQuestionModel[] = [];

    let qs = this.questions.filter(x => x);

    qs.forEach(q => {
      let index = qs.indexOf(q);
      postQuestions.push({ content: q, answerType: "TEXT_FIELD", mandatory: true, screenOrder: index })
    });

    if (postQuestions && postQuestions.length > 0) {
      this.job.questionnaire = new JobQuestionnaireModel();
      this.job.questionnaire.questions = postQuestions;
    }
    else {
      this.job.questionnaire = null;
    }

    this.jobService.ServerPut(server.jobs + '/' + this.job.id, this.job).subscribe(x => {
      this.notification.success(this.translate.transform('jobSuccessfullyUpdated'));
      if (status == "active") {
        this.jobService.ServerPut(server.jobs + '/' + x.id + '/status', { status: 'ACTIVE' }).subscribe(x => {
          this.postedJob.emit();
        });
      }
      else {

        this.postedJob.emit();
      }
      // @ts-ignore
      $('#editJobModal').modal('hide');

    });
  }

  optionChangeWithId(data, type) {
    switch (type) {
      case 'classification':
        this.job.classification = new GeneralIdModel();
        this.classificationLabel = data.label;
        this.job.classification.id = data.value;
        break;
      case 'function':
        this.job.function = new GeneralIdModel();
        this.functionLabel = data.label;
        this.job.function.id = data.value;
        break;
      case 'industry':
        this.job.industry = new GeneralIdModel();
        this.industryLabel = data.label;
        this.job.industry.id = data.value;
        break;
      case 'country':
        this.job.location.country = new GeneralIdModel();
        this.countryLabel = data.label;
        this.job.location.country.id = data.value;
        this.cityService.getCountryCities(data.value).subscribe(x => {
          this.cityOptions = x.map(city => ({ value: city.id, label: city.defaultLocalization.name }));
        });
        break;
      case 'city':
        this.job.location.city = new GeneralIdModel();
        this.cityLabel = data.label;
        this.job.location.city.id = data.value;
        break;
    }
  }

  selectVisas(e) {

    this.job.visaRequirement.visas = [];
    this.visaLabel = "";
    e.forEach(x => {
      this.visaLabel = this.visaLabel + x.defaultLocalization.name + (e[e.length - 1] == x ? "." : ",");
      this.job.visaRequirement.visas.push({ id: x.id });
    });
  }

  selectTeamMembers(e) {

    this.teamMembersLabel = "";
    e.forEach(x => {
      this.teamMembersLabel = this.teamMembersLabel + x.fullName + (e[e.length - 1] == x ? "." : ",");
      this.job.users.push({ id: x.id });
    });
  }

  optionChangeWithString(data, name, name2?) {
    name2 ? this.job[name][name2] = data.value : this.job[name] = data.value;

  }

  onEnter() {
    this.skillsString = this.skillsString + ",";
  }

  feeStatusChange(value: boolean) {
    this.feeShow = value;
    !value ? this.job.fee = new JobFeeModel() : "";
  }

  emailNotificationChange(data) {
    this.mailShow = data.value;
    !data.value ? this.job.differentNotificationEmail = "" : "";
  }

  addQuestion() {
    if (this.questionsArray && this.questionsArray.length < 10) {
      let array = [...this.questionsArray];
      this.questionsArray = [];
      array.push({ id: 'question' + array.length, index: array.length });
      for (let i = 0; i < array.length; i++) {
        this.questionsArray.push({ id: 'question' + i, index: i });
      }
    }
  }


  deleteQuestion(index) {
    if (this.questionsArray.length > 1) {
      let array = [...this.questionsArray];
      this.questionsArray = [];
      array.splice(index, 1);
      this.questions.splice(index, 1);
      for (let i = 0; i < array.length; i++) {
        this.questionsArray.push({ id: 'question' + i, index: i });
      }
    }

  }

  openPreview() {

    this.employerMeService.ServerGet().subscribe(employerDetails => {
      this.previewJob = this.job;
      this.previewJob.location.city.name = this.cityLabel;
      this.previewJob.location.country.name = this.countryLabel;
      this.previewJob.skills = this.skillsString.split(",");
      this.previewJob.function.name = this.functionLabel;
      this.previewJob.industry.name = this.industryLabel;
      this.previewJob.aboutCompany = employerDetails.about;

      let postQuestions: JobQuestionModel[] = [];

      let qs = this.questions.filter(x => x);

      qs.forEach(q => {
        let index = qs.indexOf(q);
        postQuestions.push({ content: q, answerType: "TEXT_FIELD", mandatory: true, screenOrder: index })
      });

      if (postQuestions && postQuestions.length > 0) {
        this.previewJob.questionnaire = new JobQuestionnaireModel();
        this.previewJob.questionnaire.questions = postQuestions;
      }
      else {
        this.previewJob.questionnaire = null;
      }
      // @ts-ignore
      $('#fullJobDescriptionModal').modal('show');
    });
  }

}
