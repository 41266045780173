import { Injectable }          from '@angular/core';
import { ApiService }          from '../../auth';
import { HttpClient }          from '@angular/common/http';
import { NgxSpinnerService }   from 'ngx-spinner';
import { TranslatePipe }       from '../../../pipes/translate/translate.pipe';
import { NotificationService } from '../../notification';
import { server }              from '../../server';

@Injectable({
              providedIn: 'root'
            })
export class BusinessRoleService extends ApiService {

  constructor(public http: HttpClient, public spinner: NgxSpinnerService, public translate: TranslatePipe,
              public notification: NotificationService) {
    super(http, server.packageBusinessRoles, spinner, translate, notification);
  }
}
