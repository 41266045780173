<div [id]="modalId" aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade bd-example-modal-xl "
  role="dialog" style="background-color: #000000b3;" tabindex="-1">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div *ngIf="this.job && this.job.id" class="container-fluid">
          <div class="row">
            <div class="card w-100">
              <div class="card-body">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-1 col-lg-1-5 p-0 pl-lg-2 d-flex  mb-3">
                      <img
                        [src]="job?.logoUrl && job?.logoUrl != '' ? job?.logoUrl : './assets/images/default-logo.png'"
                        alt="" class="img-fluid w-100  align-self-center">
                    </div>
                    <div class="col-5 d-flex">
                      <span class="d-inline-block align-self-center"><span
                          class="emp-name">{{job?.employer?.name}}</span></span>
                      <span class="d-inline-block align-self-center ml-3 city-name">
                        {{job?.location?.city?.name}}, {{job?.location?.country?.name}}
                      </span>
                      <!-- <span class="d-inline-block align-self-center ml-3 primary-blue">
                        {{'new'  | translate | titlecase}}
                      </span> -->
                    </div>
                    <div class="col-3 ml-auto text-center">
                      <div class="row">
                        <div class="col-12">
                          <span *ngIf="job?.fee?.type=='FIXED'" class="badge-base-salary">{{job?.fee?.amount}} {{job?.currencySymbol}}</span>
                          <span *ngIf="job?.fee?.type=='PERCENT'" class="badge-base-salary">{{job?.fee?.percent}}
                            % {{'ofBaseSalary' | translate | titlecase}}</span>
                        </div>
                        <div class="col-12 mt-1">
                          <a (click)="openviewSalary()"
                            class="cursor-pointer text-decoration-underline color-dark">{{'viewSalary' |translate
                            |titlecase}}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <span class="f-title">{{job?.title}}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-8">
                      <div class="row">
                        <div class="col-12">
                          <span class="f-content color-gray-500">
                            {{job?.description}}
                          </span>
                        </div>

                      </div>
                    </div>
                    <div class="col-3 ml-auto text-center guarantee-period-div">
                      <span class="f-content color-green">
                        <i class="far fa-calendar-minus mr-2"></i> 90 {{'daysGuaranteePeriod' | translate | lowercase}}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="card w-100">
              <div class="card-body">
                <div class="container-fluid">
                  <div class="row icons">

                    <div class="col-12">
                      <i class="fa fa-briefcase mr-2"></i>
                      <span class="all-texts"> {{'experienceLevel'| translate |titlecase}}
                        : <span style="font-weight: 700;">{{job?.experienceLevel | translate}}</span></span>
                    </div>
                    <div class="col-12">
                      <i class="fa fa-chart-bar mr-2"></i>
                      <span class="all-texts"> {{'industry'| translate |titlecase}}
                        : <span style="font-weight: 700;"> {{job?.industry?.name }}</span></span>

                    </div>
                    <div class="col-12">
                      <i class="fas fa-map-marker-alt mr-2"></i>
                      <span class="all-texts"> {{'relocationAssistance'| translate |titlecase}}
                        : <span style="font-weight: 700;">{{job?.relocationAssistance?.type | translate }}</span></span>

                    </div>
                    <div class="col-12">
                      <i class="fas fa-graduation-cap mr-2"></i>
                      <span class="all-texts"> {{'educationLevel'| translate |titlecase}}
                        : <span style="font-weight: 700;">{{job?.educationLevel | translate }}</span></span>

                    </div>
                    <div class="col-12">
                      <i class="fa fa-chart-pie mr-2"></i>
                      <span class="all-texts"> {{'compensations'| translate |titlecase}}
                        :<span style="font-weight: 700;">{{'from' | translate}} {{job?.compensation?.minAmount |
                          translate }} {{job?.currencySymbol}} {{'to' | translate}} {{job?.compensation?.maxAmount | translate }} {{job?.currencySymbol}}</span> </span>

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="card w-100">
              <div class="card-body">
                <div class="container-fluid">
                  <div class="row text-center">
                    <div class="col-3">
                      <div class="row">
                        <div class="col-12 step-title">{{'applyBefore' | translate | uppercase}}</div>
                        <div class="col-12 step-value mt-3">{{job?.updatedAt | date}}</div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="row">
                        <div class="col-12 step-title">{{'jobNature' |translate | uppercase}}</div>
                        <div class="col-12 step-value mt-3">
                          <span *ngFor="let skill of job?.skills" class="badge badge-yellow mr-2">
                            {{skill}}
                          </span>

                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="row">
                        <div class="col-12 step-title">{{'salaryRange' |translate | uppercase}}</div>
                        <div class="col-12 step-value mt-3">
                          {{job?.compensation?.minAmount | translate }} {{job?.currencySymbol}}
                          - {{job?.compensation?.maxAmount | translate }} {{job?.currencySymbol}}
                          /yearly
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="row">
                        <div class="col-12 step-title">{{'jobLocation' |translate | uppercase}}</div>
                        <div class="col-12 step-value mt-3">
                          {{job?.location?.city?.name}}, {{job?.location?.country?.name}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4 px-3 text-center  ">
            <div class="col-4 mt-3 mt-xl-0 col-lg-2 col-xl-1-5 ">
              <div class="row py-3 card m-0 h-100">
                <div class="col-12">
                  <div class="row h-100 d-flex">
                    <div class="col-12">
                      <span [ngClass]="job.numberofApplicant > 0 ? 'span-step-count2' : 'span-step-count'">
                        <span>{{job?.numberofApplicant}}</span>
                      </span>
                    </div>
                    <div (click)="popoversDisplay()" class="col-12 align-self-center mt-2 css-after-one"
                      data-placement="top" data-toggle="tooltip" id="tooltip" title="Number Of applicant">
                      {{'numberOfApplicantShort' |translate}}
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-4 mt-3 mt-xl-0 col-lg-2 col-xl-1-5 ">
              <div class="row py-3 card m-0 h-100">
                <div class="col-12">
                  <div class="row h-100 d-flex">
                    <div class="col-12">
                      <span [ngClass]="job.pendingReview > 0 ? 'span-step-count2' : 'span-step-count'">
                        <span>{{job?.pendingReview}}</span>
                      </span>
                    </div>
                    <div (click)="popoversDisplay()" class="col-12 align-self-center mt-2 css-after-two"
                      data-placement="top" data-toggle="tooltip" title="Pending Review">
                      {{'pendingReviewShort' |translate}}
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-4 mt-3 mt-xl-0 col-lg-2 col-xl-1-5">
              <div class="row py-3 card m-0 h-100">
                <div class="col-12">
                  <div class="row h-100 d-flex">
                    <div class="col-12">
                      <span [ngClass]="job.interviewStage > 0 ? 'span-step-count2' : 'span-step-count'">
                        <span>{{job?.interviewStage}}</span>
                      </span>
                    </div>
                    <div (click)="popoversDisplay()" class="col-12 align-self-center mt-2 css-after-three"
                      data-placement="top" data-toggle="tooltip" title="Interview Stage">
                      {{'interviewStageShort' |translate}}
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-4 mt-3 mt-xl-0 col-lg-2 col-xl-1-5">
              <div class="row py-3 card m-0 h-100">
                <div class="col-12">
                  <div class="row h-100 d-flex">
                    <div class="col-12">
                      <span [ngClass]="job.managerReview > 0 ? 'span-step-count2' : 'span-step-count'">
                        <span>{{job?.managerReview}}</span>
                      </span>
                    </div>
                    <div (click)="popoversDisplay()" class="col-12 align-self-center mt-2 css-after-four"
                      data-placement="top" data-toggle="tooltip" title="Manage Review">
                      {{'managerReviewShort' |translate}}
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-4 mt-3 mt-xl-0 col-lg-2 col-xl-1-5">
              <div class="row py-3 card m-0 h-100">
                <div class="col-12">
                  <div class="row h-100 d-flex">
                    <div class="col-12">
                      <span [ngClass]="job.clientReview > 0 ? 'span-step-count2' : 'span-step-count'">
                        <span>{{job?.clientReview}}</span>
                      </span>
                    </div>
                    <div (click)="popoversDisplay()" class="col-12 align-self-center mt-2 css-after-five"
                      data-placement="top" data-toggle="tooltip" title="Hr Review">
                      {{'hrReviewShort' |translate}}
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-4 mt-3 mt-xl-0 col-lg-2 col-xl-1-5">
              <div class="row py-3 card m-0 h-100">
                <div class="col-12">
                  <div class="row h-100 d-flex">
                    <div class="col-12">
                      <span [ngClass]="job.rejectedByEmployer > 0 ? 'span-step-count2' : 'span-step-count'">
                        <span>{{job?.rejectedByEmployer}}</span>
                      </span>
                    </div>
                    <div (click)="popoversDisplay()" class="col-12 align-self-center mt-2 css-after-six"
                      data-placement="top" data-toggle="tooltip" title="Rejected By Employer">
                      {{'rejectedByEmployerShort' |translate}}
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-4 mt-3 mt-xl-0 col-lg-2 col-xl-1-5">
              <div class="row py-3 card m-0 h-100">
                <div class="col-12">
                  <div class="row h-100 d-flex">
                    <div class="col-12">
                      <span [ngClass]="job.offerStage > 0 ? 'span-step-count2' : 'span-step-count'">
                        <span>{{job?.offerStage}}</span>
                      </span>
                    </div>
                    <div (click)="popoversDisplay()" class="col-12 align-self-center mt-2 css-after-seven"
                      data-placement="top" data-toggle="tooltip" title="Offer Stage">
                      {{'offerStageShort' |translate}}
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-4 mt-3 mt-xl-0 col-lg-2 col-xl-1-5">
              <div class="row py-3 card m-0 h-100">
                <div class="col-12">
                  <div class="row h-100 d-flex">
                    <div class="col-12">
                      <span [ngClass]="job.approved > 0 ? 'span-step-count2' : 'span-step-count'">
                        <span>{{job?.approved}}</span>
                      </span>
                    </div>
                    <div (click)="popoversDisplay()" class="col-12 align-self-center mt-2 css-after-eight"
                      data-placement="top" data-toggle="tooltip" title="Approved">
                      {{'approvedShort' |translate}}
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-4 mt-3 mt-xl-0 col-lg-2 col-xl-1-5">
              <div class="row py-3 card m-0 h-100">
                <div class="col-12">
                  <div class="row h-100 d-flex">
                    <div class="col-12">
                      <span [ngClass]="job.hired > 0 ? 'span-step-count2' : 'span-step-count'">
                        <span>{{job?.hired}}</span>
                      </span>
                    </div>
                    <div (click)="popoversDisplay()" class="col-12 align-self-center mt-2 css-after-nine"
                      data-placement="top" data-toggle="tooltip" title="Hired">
                      {{'hiredShort' |translate}}
                    </div>
                  </div>
                </div>

              </div>
            </div>


          </div>
          <div class="row mt-4">
            <div class="card w-100">
              <div class="card-body">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-12">
                      <span class="f-title-2">{{'jobDescription' | translate | titlecase}}</span>
                    </div>
                    <div class="col-12 mt-3">
                      <p class="desc-text">
                        {{job?.description}}
                      </p>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="job?.questionnaire && job?.questionnaire?.questions && job?.questionnaire?.questions?.length > 0"
            class="row mt-4">
            <div class="card w-100">
              <div class="card-body">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-12">
                      <span class="f-title-2">{{'preScreenQuestions' | translate | titlecase}}</span>
                    </div>
                    <div class="col-12 mt-3">
                      <p *ngFor="let ques of job?.questionnaire?.questions">
                        <!--{{job.questionnaire.questions.indexOf(ques) + 1}}-->- {{ques.content}}
                      </p>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="card w-100">
              <div class="card-body">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-12">
                      <span class="f-title-2">{{'actions'| translate | titlecase}}</span>
                    </div>
                    <div *ngIf="role=='EMPLOYER_MANAGER' || role === 'EMPLOYER_USER'" class="col-6 col-lg-3">
                      <button (click)="openApplicantsModal()" class="btn btn-brand w-100 h-100"><i
                          class="fas fa-users mr-2"></i>{{'viewAllApplications' | translate | titlecase}}</button>
                    </div>
                    <div *ngIf="(role=='RECRUITER_MANAGER' || role ==='RECRUITER_USER') && !job.favorite"
                      class="col-6 col-lg-3">
                      <button (click)="addToWorkList()" class="btn btn-brand w-100 h-100"><i
                          class="fa fa-bookmark mr-2"></i>{{'addToWorkList' | translate | titlecase}}</button>
                    </div>
                    <div *ngIf="(role=='RECRUITER_MANAGER' || role ==='RECRUITER_USER') && job.favorite"
                      class="col-6 col-lg-3">
                      <button (click)="removeFromWorkList()" class="btn btn-brand w-100 h-100"><i
                          class="fa fa-bookmark mr-2"></i>{{'removeFromWorkList' | translate | titlecase}}</button>
                    </div>

                    <div *ngIf="role==('RECRUITER_MANAGER' || role ==='RECRUITER_USER') && job.favorite"
                      class="col-6 col-lg-3">
                      <button (click)="openSubmitCandidateModal()" class="btn btn-brand w-100 h-100"><i
                          class="fa fa-bookmark mr-2"></i>{{'submitCandidate' | translate | titlecase}}</button>
                    </div>

                    <div *ngIf="(role=='EMPLOYER_MANAGER' || role === 'EMPLOYER_USER')"
                      class="col-6 mt-3  mt-lg-0 col-lg-2">
                      <button (click)="deleteJobModal(job)" class="btn btn-white w-100 h-100"><i
                          class="fa fa-trash mr-2"></i>{{'deleteJob' | translate | titlecase}}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>


<!-- <app-delete-popup></app-delete-popup> -->
