import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {
    FirmPracticeTime,
    FormType,
    PaymentType,
    PermanentCount,
    PositionAtCompany,
    PracticeTime,
    RecruiterCount,
    RecruiterType,
    ReferenceType,
    RequisitionType
} from 'src/app/shared/enums';
import {
    DropdownOptionsModel,
    EmployerAddressModel,
    PhoneNumberModel,
    RecruiterAboutModel,
    RecruiterCompanyInfoModel,
    RecruiterContactInfoModel,
    RecruiterExperienceLevelModel,
    RecruiterSpeacializationModel,
    TlbFormModel,
    UserGeneralInfosModel
} from 'src/app/shared/models';
import {
    CityService,
    CountryService,
    FunctionalRoleService,
    IndustryService,
    NotificationService,
    RecruiterMeService,
    RecruiterService,
    TranslatePipe,
    UserMeService
} from 'src/app/shared/services';
import {JobMarketService} from 'src/app/shared/services/parameter/job-market.service';
import {EmployersModels} from '../complete-register-popup/employers-model';

@Component({
    selector: 'app-complate-recruiter-register',
    templateUrl: './complate-recruiter-register.component.html',
    styleUrls: ['./complate-recruiter-register.component.scss']
})

export class ComplateRecruiterRegisterComponent implements OnInit {

    step: number = 1;
    generalInfos: UserGeneralInfosModel;
    type: string;
    typeOptions: DropdownOptionsModel[] = [];
    referenceTypeOptions: DropdownOptionsModel[] = [];
    practiceTimeOptions: DropdownOptionsModel[] = [];
    requisitionTypeOptions: any[] = [];
    plecementCountOptions: DropdownOptionsModel[] = [];
    positionOptions: DropdownOptionsModel[] = [];
    recruiterCountOptions: DropdownOptionsModel[] = [];
    firmPracticeTimeOptions: DropdownOptionsModel[] = [];
    functionalRolesOptions: any[] = [];
    industryOptions: any[] = [];
    jobMarketsOptions: any[] = [];
    paymentTypeOptions: DropdownOptionsModel[] = [];

    contactInfo: RecruiterContactInfoModel;
    about: RecruiterAboutModel;
    companyInfo: RecruiterCompanyInfoModel;
    paymentType: string;
    iban: string;
    specialization: RecruiterSpeacializationModel;

    formGeneralInfo: TlbFormModel[] = [];
    formGroupGeneralInfo: FormGroup;
    formContactInfo: TlbFormModel[] = [];
    formGroupContactInfo: FormGroup;
    formAbout: TlbFormModel[] = [];
    formGroupAbout: FormGroup;
    formModelCompanyInfo: TlbFormModel[] = [];
    formGroupCompanyInfo: FormGroup;
    formSpecialization: TlbFormModel[] = [];
    formGroupSpecialization: FormGroup;
    formPaymentType: TlbFormModel[] = [];
    formGroupPaymentType: FormGroup;
    models: EmployersModels;
    countryOptions: DropdownOptionsModel[] = [];
    cityOptions: DropdownOptionsModel[] = [];

    constructor(private usersMeService: UserMeService, private recruiterMeService: RecruiterMeService,
                private translate: TranslatePipe,
                private jobMarketService: JobMarketService, private functionalRoleService: FunctionalRoleService,
                private industryService: IndustryService,
                private recruitersService: RecruiterService, private notification: NotificationService,
                private countryService: CountryService, private cityService: CityService,
                private router: Router) {
    }

    public get permanentCount(): typeof PermanentCount {
        return PermanentCount;
    }

    get formGI() {
        return this.formGroupGeneralInfo.controls;
    }

    get formAb() {
        return this.formGroupAbout.controls;
    }

    get formCI() {
        return this.formGroupContactInfo.controls;
    }

    get formComI() {
        return this.formGroupCompanyInfo.controls;
    }

    get formPay() {
        return this.formGroupPaymentType.controls;
    }

    get formSpe() {
        return this.formGroupSpecialization.controls;
    }

    ngOnInit(): void {
        this.createGeneralInfoForm();
        this.createAboutForm();
        this.createContactInfoForm();
        this.createCompanyInfoForm();
        this.createSpeacializationForm();
        this.createPaymentForm();
    }

    placementCountsChange(data, placementCounts, level) {
        this.about.placementCounts[placementCounts] == null ? this.about.placementCounts[placementCounts] = new RecruiterExperienceLevelModel() : '';
        this.about.placementCounts[placementCounts][level] = data.value;
    }

    createGeneralInfoForm() {
        this.usersMeService.getGeneralInfo()
            .subscribe(x => {
                this.generalInfos = x as UserGeneralInfosModel;
                let s = this.generalInfos ? this.generalInfos : null;
                this.generalInfos.createdAt = null;
                this.recruiterMeService.getType()
                    .subscribe(t => {
                        this.recruiterMeService.ServerGet()
                            .subscribe(x => {
                                let profilePhotoUrl = x.profilePhotoUrl;
                                let typ = t as any;
                                this.generalInfos.type = typ.type;
                                this.type = typ.type;
                                this.typeOptions = Object.keys(RecruiterType)
                                    .map(c => ({value: c, label: this.translate.transform(c)}));

                                this.formGeneralInfo = [
                                    {
                                        class: 'col-12 col-md-6', components: [
                                            {
                                                label: 'profilePhoto',
                                                control: 'profilePhoto',
                                                name: profilePhotoUrl,
                                                imageUrl: profilePhotoUrl,
                                                required: false,
                                                type: FormType.media,
                                                index: 0,
                                                style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                                            }
                                        ]
                                    },
                                    {
                                        class: 'col-md-6 col-12', components: [
                                            {
                                                label: 'firstName',
                                                labelIcon: 'fas fa-check mr-1',
                                                control: 'firstName',
                                                type: FormType.text,
                                                required: true,
                                                style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                                            },
                                            {
                                                label: 'lastName',
                                                labelIcon: 'fas fa-check mr-1',
                                                control: 'lastName',
                                                required: true,
                                                type: FormType.text,
                                                style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                                            }

                                        ]
                                    },
                                    {
                                        class: 'col-md-6 col-12', components: [
                                            {
                                                label: 'email',
                                                labelIcon: 'fas fa-check mr-1',
                                                control: 'email',
                                                required: true,
                                                type: FormType.email,
                                                style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                                            }
                                        ]
                                    },
                                    {
                                        class: 'col-md-6 col-12', components: [
                                            {
                                                label: 'recruitertype',
                                                labelIcon: 'fas fa-check mr-1',
                                                control: 'type',
                                                options: this.typeOptions,
                                                required: false,
                                                type: FormType.dropDown,
                                                style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                                            }
                                        ]
                                    }];

                                this.formGroupGeneralInfo = new FormGroup({
                                    firstName: new FormControl(s ? s.firstName : '',
                                        Validators.required),
                                    email: new FormControl(s ? s.email : '',
                                        Validators.required),
                                    lastName: new FormControl(s ? s.lastName : '',
                                        Validators.required),
                                    type: new FormControl(this.type),
                                    profilePhoto: new FormControl(profilePhotoUrl)
                                });
                            });

                    });

            });
    }

    createContactInfoForm() {
        this.recruiterMeService.getContactInfo()
            .subscribe(x => {
                this.contactInfo = x as RecruiterContactInfoModel;
                this.contactInfo.primaryContactNumber == null ? this.contactInfo.primaryContactNumber = new PhoneNumberModel() : '';
                this.contactInfo.secondaryContactNumber == null ? this.contactInfo.secondaryContactNumber = new PhoneNumberModel() : '';

                let s = this.contactInfo ? this.contactInfo : null;
                this.countryService.ServerGetWithoutLogin().subscribe(f => {
                    let countries = f.content;
                    this.countryOptions = countries.map(c => ({value: c.id, label: c.defaultLocalization.name}));
                    if (this.contactInfo.address.country?.id) {
                        this.cityService.getCountryCities(this.contactInfo.address.country.id).subscribe(c => {

                            this.cityOptions = c.map(c => ({value: c.id, label: c.defaultLocalization.name}));
                            this.formContactInfo = [
                                {
                                    class: 'col-12', components: [
                                        {
                                            label: 'primaryContactNumber',
                                            control: 'priCountryCode',
                                            control2: 'priPhoneNumber',
                                            required: true,
                                            type: FormType.phoneNumber,
                                            style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                                        },
                                        {
                                            label: 'secondaryContactNumber',
                                            control: 'secCountryCode',
                                            control2: 'secPhoneNumber',
                                            required: false,
                                            type: FormType.phoneNumber,
                                            style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                                        }
                                    ],
                                },
                                {
                                    class: 'col-6', components: [
                                        {
                                            label: 'taxNumber',
                                            control: 'taxNumber',
                                            required: true,
                                            type: FormType.numericText,
                                            style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                                        }
                                    ],
                                },
                                {
                                    class: 'col-6', components: [
                                        {
                                            label: 'taxOffice',
                                            control: 'taxOffice',
                                            required: true,
                                            type: FormType.text,
                                            style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                                        }
                                    ],
                                },
                                {
                                    class: 'col-md-6 col-12', components: [
                                        {
                                            label: 'addressLine1',
                                            control: 'line1',
                                            labelIcon: 'fas fa-check mr-1',
                                            required: true,
                                            type: FormType.text,
                                            style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                                        },
                                        {
                                            label: 'country',
                                            control: 'country',
                                            options: this.countryOptions,
                                            labelIcon: 'fas fa-check mr-1',
                                            onChange: this.countryChange.bind(this),
                                            required: true,
                                            type: FormType.dropDown,
                                            style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                                        },
                                        {
                                            label: 'zipCode',
                                            labelIcon: 'fas fa-check mr-1',
                                            control: 'zipCode',
                                            required: true,
                                            type: FormType.text,
                                            style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                                        },
                                    ]
                                },
                                {
                                    class: 'col-md-6 col-12', components: [
                                        {
                                            label: 'addressLine2',
                                            labelIcon: 'fas fa-check mr-1',
                                            control: 'line2',
                                            required: false,
                                            type: FormType.text,
                                            style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                                        },
                                        {
                                            label: 'city',
                                            control: 'city',
                                            labelIcon: 'fas fa-check mr-1',
                                            options: this.cityOptions,
                                            required: true,
                                            type: FormType.dropDown,
                                            style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                                        },
                                    ]
                                }];

                            this.formGroupContactInfo = new FormGroup({
                                priCountryCode: new FormControl(
                                    s ? s.primaryContactNumber.countryCallingCode : '',
                                    Validators.required),
                                priPhoneNumber: new FormControl(
                                    s ? s.primaryContactNumber.phoneNumber : '',
                                    Validators.required),
                                secCountryCode: new FormControl(
                                    s ? s.secondaryContactNumber.countryCallingCode : ''),
                                secPhoneNumber: new FormControl(
                                    s ? s.secondaryContactNumber.phoneNumber : ''),
                                taxNumber: new FormControl(
                                    s ? s.taxNumber : null, Validators.required),
                                taxOffice: new FormControl(
                                    s ? s.taxOffice : '', Validators.required),
                                line1: new FormControl(s ? s.address.line1 : '', Validators.required),
                                country: new FormControl(s ? s.address.country?.id : null, Validators.required),
                                zipCode: new FormControl(s ? s.address.zipCode : '', Validators.required),
                                line2: new FormControl(s ? s.address.line2 : ''),
                                city: new FormControl(s ? s.address.city?.id : null, Validators.required)
                            });
                        });
                    } else {
                        this.formContactInfo = [
                            {
                                class: 'col-12', components: [
                                    {
                                        label: 'primaryContactNumber',
                                        control: 'priCountryCode',
                                        control2: 'priPhoneNumber',
                                        required: true,
                                        type: FormType.phoneNumber,
                                        style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                                    },
                                    {
                                        label: 'secondaryContactNumber',
                                        control: 'secCountryCode',
                                        control2: 'secPhoneNumber',
                                        required: false,
                                        type: FormType.phoneNumber,
                                        style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                                    }
                                ],
                            },
                            {
                                class: 'col-6', components: [
                                    {
                                        label: 'taxNumber',
                                        control: 'taxNumber',
                                        required: true,
                                        type: FormType.numericText,
                                        style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                                    }
                                ],
                            },
                            {
                                class: 'col-6', components: [
                                    {
                                        label: 'taxOffice',
                                        control: 'taxOffice',
                                        required: true,
                                        type: FormType.text,
                                        style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                                    }
                                ],
                            },
                            {
                                class: 'col-md-6 col-12', components: [
                                    {
                                        label: 'addressLine1',
                                        control: 'line1',
                                        labelIcon: 'fas fa-check mr-1',
                                        required: true,
                                        type: FormType.text,
                                        style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                                    },
                                    {
                                        label: 'country',
                                        control: 'country',
                                        options: this.countryOptions,
                                        labelIcon: 'fas fa-check mr-1',
                                        onChange: this.countryChange.bind(this),
                                        required: true,
                                        type: FormType.dropDown,
                                        style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                                    },
                                    {
                                        label: 'zipCode',
                                        labelIcon: 'fas fa-check mr-1',
                                        control: 'zipCode',
                                        required: true,
                                        type: FormType.text,
                                        style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                                    },
                                ]
                            },
                            {
                                class: 'col-md-6 col-12', components: [
                                    {
                                        label: 'addressLine2',
                                        labelIcon: 'fas fa-check mr-1',
                                        control: 'line2',
                                        required: false,
                                        type: FormType.text,
                                        style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                                    },
                                    {
                                        label: 'city',
                                        control: 'city',
                                        labelIcon: 'fas fa-check mr-1',
                                        options: this.cityOptions,
                                        required: true,
                                        type: FormType.dropDown,
                                        style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                                    },
                                ]
                            }];

                        this.formGroupContactInfo = new FormGroup({
                            priCountryCode: new FormControl(
                                s ? s.primaryContactNumber.countryCallingCode : '',
                                Validators.required),
                            priPhoneNumber: new FormControl(
                                s ? s.primaryContactNumber.phoneNumber : '',
                                Validators.required),
                            secCountryCode: new FormControl(
                                s ? s.secondaryContactNumber.countryCallingCode : ''),
                            secPhoneNumber: new FormControl(
                                s ? s.secondaryContactNumber.phoneNumber : ''),
                            taxNumber: new FormControl(
                                s ? s.taxNumber : null, Validators.required),
                            taxOffice: new FormControl(
                                s ? s.taxOffice : '', Validators.required),
                            line1: new FormControl(s ? s.address.line1 : '', Validators.required),
                            country: new FormControl(s ? s.address.country?.id : null, Validators.required),
                            zipCode: new FormControl(s ? s.address.zipCode : '', Validators.required),
                            line2: new FormControl(s ? s.address.line2 : ''),
                            city: new FormControl(s ? s.address.city?.id : null, Validators.required)
                        });
                    }


                });

            });
    }

    createAboutForm() {
        this.recruiterMeService.getAbout()
            .subscribe(x => {
                this.about = x as RecruiterAboutModel;
                this.about.type = this.type;
                let s = this.about ? this.about : null;
                this.referenceTypeOptions = Object.keys(ReferenceType)
                    .map(c => ({value: c, label: this.translate.transform(c)}));
                this.practiceTimeOptions = Object.keys(PracticeTime)
                    .map(c => ({value: c, label: this.translate.transform(c)}));
                this.requisitionTypeOptions = Object.keys(RequisitionType)
                    .map(
                        c => ({item_id: c, item_text: this.translate.transform(c + 'REC')}));
                this.plecementCountOptions = Object.keys(PermanentCount)
                    .map(c => ({value: c, label: PermanentCount[c]}));

                this.formAbout = [{
                    class: 'col-md-6 col-12', components: [
                        {
                            label: 'talentbaseReferenceType',
                            control: 'referenceType',
                            options: this.referenceTypeOptions,
                            required: true,
                            type: FormType.dropDown,
                            style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                        }
                    ]
                },
                    {
                        class: 'col-md-6 col-12', components: [
                            {
                                label: 'individualPracticeTime',
                                control: 'practiceTime',
                                options: this.practiceTimeOptions,
                                required: true,
                                type: FormType.dropDown,
                                style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                            }
                        ]
                    },
                    {
                        class: 'col-md-12 col-12', components: [
                            {
                                label: 'requisitionTypes',
                                control: 'requisitionTypes',
                                options: this.requisitionTypeOptions,
                                required: true,
                                type: FormType.multiSelect,
                                style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                            }
                        ]
                    },
                    {
                        class: 'col-md-12 col-12', components: [
                            {
                                label: 'about', control: 'aboutMe', required: true, type: FormType.textAreaWithLimit, limit: 2600,
                                style: 'background: #F9FAFB; border: 1px solid rgba(43, 58, 75, 0.5); border-radius: 8px;'
                            }
                        ]
                    }];

                this.formGroupAbout = new FormGroup({
                    referenceType: new FormControl(s ? s.talentbaseReferenceType : '',
                        Validators.required),
                    practiceTime: new FormControl(s ? s.individualPracticeTime : '',
                        Validators.required),
                    requisitionTypes: new FormControl(s ? s.requisitionTypes : '',
                        Validators.required),
                    aboutMe: new FormControl(s ? s.aboutMe : '', Validators.required)
                });
            });

    }

    createCompanyInfoForm() {
        this.recruiterMeService.getCompanyInfo()
            .subscribe(x => {
                this.companyInfo = x as RecruiterCompanyInfoModel;
                this.companyInfo.type = this.type;
                let s = this.companyInfo ? this.companyInfo : null;
                this.positionOptions = Object.keys(PositionAtCompany)
                    .map(c => ({value: c, label: this.translate.transform(c)}));
                this.recruiterCountOptions = Object.keys(RecruiterCount)
                    .map(c => ({value: c, label: this.translate.transform(c)}));
                this.firmPracticeTimeOptions = Object.keys(FirmPracticeTime)
                    .map(c => ({value: c, label: this.translate.transform(c)}));
                this.jobMarketService.ServerGetWithoutLogin()
                    .subscribe(j => {
                        let jobMarkets = j.content;
                        this.jobMarketsOptions = jobMarkets.map(
                            c => ({item_id: c.id, item_text: c.defaultLocalization.name}));
                        let jobarray = [];
                        this.companyInfo.jobMarkets?.forEach(i => {

                            jobarray.push({item_id: i.id, item_text: i.name});
                        });

                        this.formModelCompanyInfo = [{
                            class: 'col-md-6 col-12', components: [
                                {
                                    label: 'companyName',
                                    control: 'companyName',
                                    labelIcon: 'fas fa-check mr-1',
                                    required: false,
                                    type: FormType.text,
                                    style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                                },
                                {
                                    label: 'website',
                                    control: 'website',
                                    required: false,
                                    type: FormType.text,
                                    style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                                },
                                {
                                    label: 'recruiterCount',
                                    control: 'recruiterCount',
                                    options: this.recruiterCountOptions,
                                    required: false,
                                    type: FormType.dropDown,
                                    style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                                },
                                {
                                    label: 'firmPracticeTime',
                                    control: 'firmPracticeTime',
                                    options: this.firmPracticeTimeOptions,
                                    required: false,
                                    type: FormType.dropDown,
                                    style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                                }
                            ]
                        },
                            {
                                class: 'col-md-6 col-12', components: [
                                    {
                                        label: 'positionAtCompany',
                                        labelIcon: 'fas fa-check mr-1',
                                        control: 'positionAtCompany',
                                        options: this.positionOptions,
                                        required: false,
                                        type: FormType.dropDown,
                                        style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                                    },
                                    {
                                        label: 'linkedinAccount',
                                        control: 'linkedinAccount',
                                        required: false,
                                        type: FormType.text,
                                        style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                                    },
                                    {
                                        label: 'jobMarkets',
                                        control: 'jobMarkets',
                                        options: this.jobMarketsOptions,
                                        required: false,
                                        type: FormType.multiSelect,
                                        style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                                    }
                                ]
                            }];

                        this.formGroupCompanyInfo = new FormGroup({
                            companyName: new FormControl(s ? s.companyName : ''),
                            website: new FormControl(s ? s.website : ''),
                            recruiterCount: new FormControl(s ? s.recruiterCount : ''),
                            firmPracticeTime: new FormControl(
                                s ? s.firmPracticeTime : ''),
                            positionAtCompany: new FormControl(
                                s ? s.positionAtCompany : ''),
                            linkedinAccount: new FormControl(
                                s ? s.linkedinAccount : ''),
                            jobMarkets: new FormControl(jobarray)
                        });
                    });
            });
    }

    createSpeacializationForm() {
        this.recruiterMeService.getSpecialization()
            .subscribe(x => {
                this.specialization = x as RecruiterSpeacializationModel;
                let industryArray = [];
                this.specialization.industries?.forEach(i => {
                    industryArray.push({item_id: i.id, item_text: i.name});
                });

                let roleArray = [];
                this.specialization.functionalRoles?.forEach(i => {
                    roleArray.push({item_id: i.id, item_text: i.name});
                });

                let s = this.specialization ? this.specialization : null;

                this.industryService.ServerGetWithoutLogin()
                    .subscribe(i => {
                        let industry = i.content;
                        this.industryOptions = industry.map(c => ({item_id: c.id, item_text: c.defaultLocalization.name}));

                        this.functionalRoleService.ServerGetWithoutLogin()
                            .subscribe(f => {
                                let roles = f.content;
                                this.functionalRolesOptions = roles.map(
                                    c => ({item_id: c.id, item_text: c.defaultLocalization.name}));

                                this.formSpecialization = [{
                                    class: 'col-md-6 col-12', components: [
                                        {
                                            label: 'industries',
                                            control: 'industries',
                                            msSettings: {
                                                singleSelection: false,
                                                idField: 'item_id',
                                                textField: 'item_text',
                                                selectAllText: 'Select All',
                                                unSelectAllText: 'UnSelect All',
                                                itemsShowLimit: 'All',
                                                allowSearchFilter: false,
                                                limitSelection: 5,
                                                enableCheckAll: false
                                            },
                                            options: this.industryOptions,
                                            required: true,
                                            type: FormType.multiSelect,
                                            style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                                        }
                                    ]
                                },
                                    {
                                        class: 'col-md-6 col-12', components: [
                                            {
                                                label: 'functionalRoles',
                                                control: 'functionalRoles',
                                                msSettings: {
                                                    singleSelection: false,
                                                    idField: 'item_id',
                                                    textField: 'item_text',
                                                    selectAllText: 'Select All',
                                                    unSelectAllText: 'UnSelect All',
                                                    itemsShowLimit: 'All',
                                                    allowSearchFilter: false,
                                                    limitSelection: 5,
                                                    enableCheckAll: false
                                                },
                                                options: this.functionalRolesOptions,
                                                required: true,
                                                type: FormType.multiSelect,
                                                style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                                            }
                                        ]
                                    }];

                                this.formGroupSpecialization = new FormGroup({
                                    industries: new FormControl(industryArray,
                                        Validators.required),
                                    functionalRoles: new FormControl(roleArray,
                                        Validators.required)
                                });
                            });
                    });
            });


    }

    createPaymentForm(){
        this.recruiterMeService.getPaymentType()
            .subscribe(x => {
                let pay = x as any;
                this.paymentType = pay.type;
                this.iban = pay.iban;
                this.paymentTypeOptions = Object.keys(PaymentType)
                    .map(c => ({value: c, label: this.translate.transform(c)}));

                this.formPaymentType = [{
                    class: 'col-md-12 col-12', components: [
                        {
                            label: 'paymentType',
                            control: 'paymentType',
                            options: this.paymentTypeOptions,
                            required: true,
                            type: FormType.dropDown,
                            style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                        },
                        {
                            label: 'iban',
                            control: 'iban',
                            required: true,
                            type: FormType.iban,
                            style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                        }
                    ]
                }];

                this.formGroupPaymentType = new FormGroup({
                    paymentType: new FormControl(this.paymentType,
                        Validators.required),
                    iban: new FormControl(this.iban,
                        Validators.required)
                });

            });
    }

    goBack() {
        this.step--;
    }

    goNext() {
        switch (this.step) {
            case 1:

                if (this.getDirtyValues(
                    this.formGroupGeneralInfo).length > 0 || (this.formGI.profilePhoto.value && this.formGI.profilePhoto.value.name)) {
                    this.saveGeneralInfo();
                }
                break;
            case 2:
                if (this.getDirtyValues(this.formGroupContactInfo).length > 0) {
                    this.saveContactInfo();
                }
                break;
            case 3:
                if (this.getDirtyValues(this.formGroupAbout).length > 0) {
                    this.saveAbout();
                }
                break;
            case 4:
                if (this.getDirtyValues(this.formGroupCompanyInfo).length > 0) {
                    this.saveCompanyInfo();
                }
                break;
            case 5:
                if (this.getDirtyValues(this.formGroupPaymentType).length > 0) {
                    this.savePayment();
                }
                break;
        }

        this.step++;
    }

    getDirtyValues(form: any) {
        let dirtyValues = [];

        Object.keys(form.controls)
            .forEach(key => {
                let currentControl = form.controls[key];

                if (currentControl.dirty) {
                    if (currentControl.controls) {
                        dirtyValues.push(this.getDirtyValues(currentControl));
                    } else {
                        dirtyValues.push(currentControl.value);
                    }
                }
            });

        return dirtyValues;
    }

    saveGeneralInfo() {
        if (this.formGI.profilePhoto.value && this.formGI.profilePhoto.value.name) {
            if (this.formGI.profilePhoto.value != null) {
                let formData: any = new FormData();
                const file = this.formGI.profilePhoto.value;
                formData.append('profilePhoto', file);

                this.recruiterMeService.putProfilePhoto(formData)
                    .subscribe(x => {
                        this.createGeneralInfoForm();
                    });
            }
        }

        this.generalInfos.email = this.formGI.email.value;
        this.generalInfos.firstName = this.formGI.firstName.value;
        this.generalInfos.lastName = this.formGI.lastName.value;

        this.usersMeService.putGeneralInfo(this.generalInfos)
            .subscribe(x => {
                this.recruiterMeService.putType({type: this.formGI.type.value})
                    .subscribe(x => {
                        this.createGeneralInfoForm();
                        this.notification.success(this.translate.transform('generalInfoHasBeenSuccessfullyUpdated'));
                    });
            });
    }

    saveContactInfo() {
        this.contactInfo.primaryContactNumber.countryCallingCode = this.formCI.priCountryCode.value;
        this.contactInfo.primaryContactNumber.phoneNumber = this.formCI.priPhoneNumber.value;
        this.contactInfo.address = new EmployerAddressModel();

        this.contactInfo.address.line1 = this.formCI.line1.value;
        this.contactInfo.address.line2 = this.formCI.line2.value;
        this.contactInfo.address.country = {id: this.formCI.country.value};
        this.contactInfo.address.city = {id: this.formCI.city.value};
        this.contactInfo.address.zipCode = this.formCI.zipCode.value;

        /*        this.contactInfo.address = this.formCI.address.value;*/
        this.contactInfo.taxNumber = this.formCI.taxNumber.value;
        this.contactInfo.taxOffice = this.formCI.taxOffice.value;
        if (!this.formCI.secPhoneNumber.value || this.formCI.secPhoneNumber.value == '') {
            this.contactInfo.secondaryContactNumber = null;
        } else {
            this.contactInfo.secondaryContactNumber.countryCallingCode = this.formCI.secCountryCode.value;
            this.contactInfo.secondaryContactNumber.phoneNumber = this.formCI.secPhoneNumber.value;
        }

        this.recruiterMeService.putContactInfo(this.contactInfo)
            .subscribe(x => {
                this.createContactInfoForm();
                this.notification.success(this.translate.transform('contactInfoHasBeenSuccessfullyUpdated'));
            });
    }

    saveAbout() {
        this.about.talentbaseReferenceType = this.formAb.referenceType.value;
        this.about.individualPracticeTime = this.formAb.practiceTime.value;
        this.about.aboutMe = this.formAb.aboutMe.value;
        if (this.formAb.requisitionTypes.value[0].item_id) {
            this.about.requisitionTypes = [];
            this.formAb.requisitionTypes.value?.forEach(i => {
                this.about.requisitionTypes.push(i.item_id);
            });
        } else {
            this.about.requisitionTypes = this.formAb.requisitionTypes.value;
        }

        this.about.type = this.generalInfos.type;
        let placementCounts: any = <any> {};
        Object.keys(this.about.placementCounts)
            .forEach(x => {
                if (this.about.placementCounts[x] && (this.about.placementCounts[x].permanent != '' || this.about.placementCounts[x].temporary != '')) {
                    placementCounts[x] = <any> {};
                }

                Object.keys(this.about.placementCounts[x])
                    .forEach(y => {
                        if (this.about.placementCounts[x] && this.about.placementCounts[x][y] && this.about.placementCounts[x][y] != '') {
                            placementCounts[x][y] = this.about.placementCounts[x][y];
                        }
                    });
            });

        this.about.placementCounts = placementCounts;

        this.recruiterMeService.putAbout(this.about)
            .subscribe(x => {
                this.createAboutForm();
                this.notification.success(this.translate.transform('aboutInfoHasBeenSuccessfullyUpdated'));
            });
    }

    saveCompanyInfo() {
        this.companyInfo.type = this.generalInfos.type;
        this.companyInfo.companyName = this.formComI.companyName.value;
        this.companyInfo.website = this.formComI.website.value;
        this.companyInfo.recruiterCount = this.formComI.recruiterCount.value;
        this.companyInfo.firmPracticeTime = this.formComI.firmPracticeTime.value;
        this.companyInfo.positionAtCompany = this.formComI.positionAtCompany.value;
        this.companyInfo.linkedinAccount = this.formComI.linkedinAccount.value;
        if (this.formComI.jobMarkets.value[0].item_id) {
            this.companyInfo.jobMarkets = this.formComI.jobMarkets.value.map(x => ({id: x.item_id}));
        } else {
            this.companyInfo.jobMarkets = this.formComI.jobMarkets.value.map(x => ({id: x}));
        }

        this.recruiterMeService.putCompanyInfo(this.companyInfo)
            .subscribe(x => {
                this.createCompanyInfoForm();
                this.notification.success(this.translate.transform('companyInfoHasBeenSuccessfullyUpdated'));
            });

    }

    saveSpeacialization() {
        if (this.formSpe.industries.value[0].item_id) {
            this.specialization.industries = this.formSpe.industries.value.map(x => ({id: x.item_id}));
        } else {
            this.specialization.industries = this.formSpe.industries.value.map(x => ({id: x}));
        }

        if (this.formSpe.functionalRoles.value[0].item_id) {
            this.specialization.functionalRoles = this.formSpe.functionalRoles.value.map(x => ({id: x.item_id}));
        } else {
            this.specialization.functionalRoles = this.formSpe.functionalRoles.value.map(x => ({id: x}));
        }

        this.recruiterMeService.putSpecialization(this.specialization)
            .subscribe(x => {
                this.notification.success(this.translate.transform('specializationHasBeenSuccessfullyUpdated'));
            });
    }

    savePayment() {
        this.recruiterMeService.putPaymentType({type: this.formPay.paymentType.value, iban: this.formPay.iban.value})
            .subscribe(x => {
                this.createSpeacializationForm();
                this.notification.success(this.translate.transform('paymentHasBeenSuccessfullyUpdated'));
            });
    }

    finish() {
        if (this.getDirtyValues(this.formGroupSpecialization).length > 0 || this.getDirtyValues(
            this.formGroupPaymentType).length > 0) {
            this.saveSpeacialization();
        }

        this.router.navigate(['/dashboard']);
    }

    countryChange(data) {

        this.cityService.getCountryCities(data).subscribe(c => {
            this.cityOptions = c.map(c => ({value: c.id, label: c.defaultLocalization.name}));
            this.formContactInfo[4].components[1].options = this.cityOptions;
            this.formGroupContactInfo.controls.city.setValue(null);
        });

    }
}
