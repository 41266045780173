export class ResumeModel {

  title: string;
  url: string;

  constructor() {
    this.title = '';
    this.url = null;
  }
}
