<div class="box">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h5><b>{{ 'tools' | translate }}</b></h5>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 ">
        <div  (click)="exactalent()"  class="box-part text-center cardOutline cardChildOutline purple-white ">
          <img class="card-img-top" src="/assets/images/company-images/exactalentImage.png" alt="Card image cap">
          <i class="fa fa-instagram fa-3x" aria-hidden="true"></i>

          <div class="text">
            <span>{{ 'exactalents' | translate }}</span>
            <p class="text-muted ">{{ 'exactalentsCompany' | translate }}</p>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <div  (click)="recruiting()"  class="box-part text-center cardOutline cardChildOutline">
          <img class="card-img-top" src="/assets/images/company-images/recruting.png" alt="Card image cap">
          <i class="fa fa-twitter fa-3x" aria-hidden="true"></i>

          <div class="text">
            <span>{{ 'recruiting' | translate }}</span>
            <p class="text-muted ">{{ 'recruitingCompany' | translate }}</p>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">

        <div   (click)="oracle()"  class="box-part text-center cardOutline cardChildOutline">
          <img class="card-img-top" src="/assets/images/company-images/oracle.png" alt="Card image cap">


          <i class="fa fa-facebook fa-3x" aria-hidden="true"></i>

          <div class="text">
            <span>{{ 'oracle' | translate }}</span>
            <p class="text-muted ">{{ 'oracleCompany' | translate }}</p>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">

        <div  (click)="smartRecruiters()"  class="box-part text-center cardOutline cardChildOutline">
          <img class="card-img-top" src="/assets/images/company-images/smartRecruting.png" alt="Card image cap">


          <i class="fa fa-pinterest-p fa-3x" aria-hidden="true"></i>


          <div class="text">
            <span>{{ 'smartRecruiters' | translate }}</span>
            <p class="text-muted ">{{ 'smartRecruitersCompany' | translate }}</p>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">

        <div  (click)="codility()"  class="box-part text-center cardOutline cardChildOutline">
          <img class="card-img-top" src="/assets/images/company-images/codility.png" alt="Card image cap">
          <i class="fa fa-google-plus fa-3x" aria-hidden="true"></i>

          <div class="text">
            <span>{{ 'codility' | translate }}</span>
            <p class="text-muted ">{{ 'codilityCompany' | translate }}</p>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <div  (click)="hackerRank()"  class="box-part text-center cardOutline cardChildOutline">
          <img class="card-img-top" src="/assets/images/company-images/hackerRank.png" alt="Card image cap">
          <i class="fa fa-github fa-3x" aria-hidden="true"></i>

          <div class="text">
            <span>{{ 'hackerRank' | translate }}</span>
            <p class="text-muted ">{{ 'hackerRankCompany' | translate }}</p>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <div  (click)="imocha()"  class="box-part text-center cardOutline cardChildOutline">
          <img class="card-img-top" src="/assets/images/company-images/imocha.png" alt="Card image cap">
          <i class="fa fa-pinterest-p fa-3x" aria-hidden="true"></i>

          <div class="text">
            <span>{{ 'imocha' | translate }}</span>
            <p class="text-muted ">{{ 'imochaCompany' | translate }}</p>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <div  (click)="merger()"  class="box-part text-center cardOutline cardChildOutline">
          <img class="card-img-top" src="/assets/images/company-images/mercer.png" alt="Card image cap">
          <i class="fa fa-google-plus fa-3x" aria-hidden="true"></i>

          <div class="text">
            <span>{{ 'merger' | translate }}</span>
            <p class="text-muted ">{{ 'mergerCompany' | translate }}</p>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">

        <div  (click)="criteria()"  class="box-part text-center cardOutline cardChildOutline">
          <img class="card-img-top" src="/assets/images/company-images/criteria.png" alt="Card image cap">
          <i class="fa fa-github fa-3x" aria-hidden="true"></i>

          <div class="text">
            <span>{{ 'criteria' | translate }}</span>
            <p class="text-muted ">{{ 'criteriaCompany' | translate }}</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
