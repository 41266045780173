<div class="container-fluid mt-4">
  <div class="d-flex">
    <div class="col-12">

      <app-jobs-filter (resultFilter)="getFilteredJobs($event)" (clearFilter)="getClearFilterJobs($event)"
        (resultSearch)="search($event)"></app-jobs-filter>
      <tlb-empty-page-dashboard *ngIf="!jobs || jobs.length == 0" [warningLabel]="'No jobs yet.'">
      </tlb-empty-page-dashboard>
      <app-recruiter-jobs *ngIf="jobs && jobs.length > 0" [jobs]="jobs" [count]="count" [limit]="3"
        (getJobListAgain)="ngOnInit()" (updateJobs)="getJobs($event)" (sorted)="sorted($event)"
        (updatedStatus)="getJobs(1)">
      </app-recruiter-jobs>

    </div>

  </div>

  <app-dashboard-news-modal *ngIf="this.new" [new]="this.new"></app-dashboard-news-modal>
