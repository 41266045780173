export class JobGeneralInfosModel {

    id: string;
    title: string;
    positionCount: number;
    classification: any;
    function: any;
    industry: any;
    type: string;
    anonymous: boolean;

    constructor() {
        this.id = '';
        this.title = '';
        this.positionCount = null;
        this.classification = null;
        this.function = null;
        this.industry = null;
        this.type = null;
        this.anonymous = false;
    }
}
