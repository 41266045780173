<div class="container-fluid px-sm-5 my-4">
  <div class="col-12 col-sm-10 mx-auto">

    <app-recruiter-filters (resultFilter)="getFilteredRec($event)" (clearFilter)="clearFilter($event)" (resultSearch)="search($event)"></app-recruiter-filters>
  <tlb-empty-page *ngIf="!recruiters || recruiters.length == 0" [warningLabel]="'No recruiters yet.'"></tlb-empty-page>
  <div *ngIf="recruiters && recruiters.length > 0">
    <div  *ngFor="let recruiter of recruiters | paginate:{ itemsPerPage: requestPayload.limit, currentPage: currentPage,totalItems:count}">
    <div class="row mt-3" >
      <div class="col-12 pr-1 pl-0">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12  col-xl-1">
                <div class="row h-100 ml-2">
                  <div class="row justify-content-center align-self-center mx-auto">
                    <img src="assets/images/avatar_empty.png" alt="" class="img-fluid">
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl-3">
                <div class="row h-100 ml-2">
                  <div class="row justify-content-center align-self-center m-auto m-xl-0">
                    <b>{{recruiter.user?.fullName}}</b>
                  </div>
                </div>
              </div>

              <div class="col-md-12 mt-3 mt-xl-0 col-xl-3 ml-xl-auto mr-xl-5 mt-xl-4">
                <div class="row">
                  <div class="col-12 ">
                    <div class="row justify-content-center align-self-center user-rating-div m-auto">
                      <div class="col-4 p-2 text-center rating-score">
                       {{recruiter.star}}
                      </div>
                      <div class="col-8  p-2 rating-star">
                        <app-star-rating [ratingScore]="recruiter.star"></app-star-rating>
                      </div>
                      <div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row mt-3">
                      <div class="col-12 text-center">
                        <h6>
                          <a (click)="openReviewModal(recruiter.reviews)" class="review-link">{{'read'|translate}} {{recruiter.reviewCount}}
                            {{'reviews'|translate}}</a>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>


              </div>

            </div>
            <div class="row mt-4">
              <div class="col-12 col-sm-4">
                <i class="fas fa-calendar icon purple mr-2"></i>
                <span class="gray">{{ 'recruitmentExperience' | translate }}</span>
              </div>
              <div class="col-12 col-sm-8">
                {{recruiter.individualPracticeTime | translate}}
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-12 col-sm-4">
                <i class="fas fa-handshake icon purple mr-2"></i>
                <span class="gray">{{ 'jobRecruisitionTypes' | translate }}</span>
              </div>
              <div class="col-12 col-sm-8">
                {{recruiter.requisitionTypesText}}
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-12 col-sm-4">
                <i class="fas fa-sticky-note icon purple mr-2"></i>
                <span class="gray">{{ 'functionalRoles' | translate }}</span>
              </div>
              <div class="col-12 col-sm-8">
               {{recruiter.functionalRolesText}}
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-12 col-sm-4">
                <i class="fas fa-building icon purple mr-2"></i>
                <span class="gray">{{ 'industries' | translate }}</span>
              </div>
              <div class="col-12 col-sm-8">
                {{recruiter.industriesText}}
              </div>
            </div>

          </div>
        </div>
      </div>

      </div>
    </div>
  </div>

  </div>

</div>

<div  class=" container-fluid row mt-3 pagination">
  <pagination-controls (pageChange)="currentPage = $event;getRecruiters(currentPage)"
                       [autoHide]="count <= requestPayload.limit"
                       nextLabel="{{'next' | translate}}"
                       previousLabel="{{'previous' | translate}}"
                       responsive="true"></pagination-controls>
</div>
<app-employer-recruiter-review-popup [reviews]="reviews">

</app-employer-recruiter-review-popup>
