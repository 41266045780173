import { GeneralIdNameModel }         from '../general';
import { BusinessFunctionStatusEnum } from './business-function-status.enum';

export class BusinessFunctionModel {
  createdAt: Date;
  createdBy: GeneralIdNameModel;
  updatedAt!: Date;
  updatedBy!: GeneralIdNameModel;
  id: string;
  name: string;
  status: BusinessFunctionStatusEnum;
  functionalRoles: string[];

  constructor() {
    this.createdAt = new Date();
    this.createdBy = new GeneralIdNameModel();
    this.updatedAt =  new Date();
    this.updatedBy =  new GeneralIdNameModel();
    this.id = '';
    this.name = '';
    this.status = BusinessFunctionStatusEnum.PASSIVE;
    this.functionalRoles = [];
  }
}
