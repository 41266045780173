
<link href="https://fonts.googleapis.com/css?family=Poppins" rel="stylesheet" />
<div >
  <div class="col-12 col-xl-9 m-auto px-1 px-md-5 px-xl-0">
  <div class="row">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-md-7 mb-5" >
      <div class="">
        <div class="pt-5 d-none d-md-block d-lg-block d-xl-block">
          <h2>Find a job & grow your career!</h2>
          <p class="desc-text pt-3">
            Lorem ipsum dolor sit amet, sea putant suscipit invidunt cu.
          </p>
        </div>
        <div class="pt-5 d-none d-md-block d-lg-block d-xl-block">
          <img src="./../../../../assets/images/talentbase/left-bg.svg" />
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-7 mb-5" >
      <div class="card border-0 custom-card px-0 px-md-4">
        <span class="createText ml-2">{{ "login" | translate }}</span>
        <span class="mt-2 mb-3 ml-2 bottomCreateText">
          {{ "topJobBoardsForProfessionals" | translate }}
        </span>
        <tlb-form
          *ngIf="formModel && formModel.length > 0 && formGroup"
          [forms]="formModel"
          [formGroup]="formGroup"
        ></tlb-form>
        <div class="ml-3 mb-3" style="margin-top: -3rem;">

          <div *ngIf="errorMessage != ''" class="error-message mb-2 ml-2 ">
            {{ errorMessage }}
          </div>
          <div class="ml-2">
            <span (click)="forgotPassword()" class="forgot-password">{{
              "forgotPassword" | translate
            }}</span>
          </div>
        </div>

        <button
          (click)="login()"
          class="btn createBtn ml-sm-3"
          id="next"
          type="button"
        >
          {{ "login" | translate }}
        </button>
        <div class="col-12 col-sm-6 mt-4 mt-sm-0 d-sm-none">
          <span class="already-have-account"
            >{{ "dontYouHaveAnAccount" | translate }}
            <span (click)="goRegister()" class="sign-in">{{
              "createAccount" | translate
            }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

