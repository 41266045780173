export enum MinAmount {
  THIRTY = 'THIRTY',
  FOURTY_ONE = 'FOURTY_ONE',
  FIFTY_ONE = 'FIFTY_ONE',
  SIXTY_ONE = 'SIXTY_ONE',
  SEVENTY_ONE = 'SEVENTY_ONE',
  EIGHTY_ONE = 'EIGHTY_ONE',
  NINETY_ONE = 'NINETY_ONE',
  ONE_HUNDRED_ONE = 'ONE_HUNDRED_ONE',
  ONE_HUNDRED_AND_ELEVEN = 'ONE_HUNDRED_AND_ELEVEN',
  ONE_HUNDRED_AND_TWENTY_ONE = 'ONE_HUNDRED_AND_TWENTY_ONE',
  ONE_HUNDRED_AND_THIRTY_ONE = 'ONE_HUNDRED_AND_THIRTY_ONE',
  ONE_HUNDRED_AND_FORTY_ONE = 'ONE_HUNDRED_AND_FORTY_ONE',
  ONE_HUNDRED_AND_FIFTY_ONE = 'ONE_HUNDRED_AND_FIFTY_ONE',
  ONE_HUNDRED_AND_SIXTY_ONE = 'ONE_HUNDRED_AND_SIXTY_ONE',
  ONE_HUNDRED_AND_SEVENTY_ONE = 'ONE_HUNDRED_AND_SEVENTY_ONE',
  ONE_HUNDRED_AND_EIGHTY_ONE = 'ONE_HUNDRED_AND_EIGHTY_ONE',
  ONE_HUNDRED_AND_NINETY_ONE = 'ONE_HUNDRED_AND_NINETY_ONE',
  TWO_HUNDRED_ONE = 'TWO_HUNDRED_ONE',
  TWO_HUNDRED_AND_ELEVEN = 'TWO_HUNDRED_AND_ELEVEN',
  TWO_HUNDRED_AND_TWENTY_SIX = 'TWO_HUNDRED_AND_TWENTY_SIX',
  TWO_HUNDRED_AND_FIFTY_PLUS = 'TWO_HUNDRED_AND_FIFTY_PLUS'
}
