import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormType } from 'src/app/shared/enums';
import { TlbFormModel } from 'src/app/shared/models';

@Component({
  selector: 'app-create-job-profile',
  templateUrl: './create-job-profile.component.html',
  styleUrls: ['./create-job-profile.component.scss']
})
export class CreateJobProfileComponent implements OnInit {

  formCreateJobProfile: TlbFormModel[] = [];
  formCreateJobProfileSkill: TlbFormModel[] = [];
  formGroupCreateJob: FormGroup;

  constructor() { }

  ngOnInit() {
    this.createJobProfileForm();
  }

  createJobProfileForm(){
    let s;
    this.formCreateJobProfile = [{
      class: 'col-md-6 col-12', components: [
        {
          label: 'segmentId', control: 'title', type: FormType.text, required: true,
          style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
        },
        {
          label: 'employerId',  control: 'title', required: true, type: FormType.text,
          style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
        },
        {
          label: 'countryId', control: 'title', required: true, type: FormType.text,
          style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
        },
        {
          label: 'cityId',  control: 'title', required: true, type: FormType.text,
          style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
        },
        {
          label: 'locationAdditional',  control: 'title', required: true, type: FormType.dropDown,
          style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
        },
        {
          label: 'educationLevel', control: 'title',  required: true, type: FormType.dropDown,
          style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
        },
        {
          label: 'experienceRequiredMonth',  control: 'title', required: true, type: FormType.dropDown,
          style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
        },
        {
          label: 'profileType',  control: 'title', required: true, type: FormType.text,
          style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
        },
        {
          label: 'experienceLevel',   control: 'title',required: true, type: FormType.text,
          style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
        },
        {
          label: 'updatedDay',  control: 'title', required: true, type: FormType.text,
          style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
        },
      ]
    },
    {
      class: 'col-md-6 col-12', components: [
        {
          label: 'jobTitle',  control: 'title', required: true, type: FormType.text,
          style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
        },
        {
          label: 'industryId',  control: 'title', required: true, type: FormType.text,
          style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',

        },
        {
          label: 'functionn',  control: 'title', required: true, type: FormType.text,
          style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
        },
        {
          label: 'zipCode',  control: 'title', required: true, type: FormType.text,
          style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
        },
        {
          label: 'anyLocationFlag', control: 'title', required: true, type: FormType.text,
          style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
        },
        {
          label: 'classification', control: 'title', required: true, type: FormType.text,
          style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
        },
        {
          label: 'createdAt', control: 'title',  required: true, type: FormType.dropDown,
          style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
        },
        {
          label: 'remoteFlag', control: 'title',  required: true, type: FormType.text,
          style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
        },
        {
          label: 'experienceRequiredYear',  control: 'title', required: true, type: FormType.text,
          style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
        },
      ]
    }];

    this.formCreateJobProfileSkill = [{

      class: 'col-md-6 col-12', components: [
        {
          label: 'jobProfileId', control: 'title', type: FormType.text, required: true,
          style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
        },
        {
          label: 'updatedDate', required: true,  control: 'title', type: FormType.dropDown,
          style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
        },
      ]
    },
    {
      class: 'col-md-6 col-12', components: [
        {
          label: 'skill',  control: 'title', required: true, type: FormType.dropDown,
          style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
        },
        {
          label: 'createdDate',  control: 'title', required: true, type: FormType.dropDown,
          style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
        },
      ]
    }];


    this.formGroupCreateJob = new FormGroup({
      title: new FormControl(s ? s.title : '', Validators.required),
    });

  }

}
