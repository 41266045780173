export class JobCompensationModel {

 currency: any;
 minAmount: string;
 maxAmount: string;
 additional: string;

constructor() {
  this.currency = null;
  this.minAmount = null;
  this.maxAmount = null;
  this.additional = '';
}
}
