export const LANG_NL_NAME = 'nl';

export const LANG_NL_TRANS = {

  appName: 'App Name',
  welcome: 'Welcome',
  welcomeToFlynx: 'Welcome to Flynx',
  pleaseLogin:'Please Login',
  emailVerification:'E-Mail Verification',
  successEmailVerify: 'Congratulations! Your e-mail has been successfully verified.',
  errorEmailVerify: 'Sorry but there was a problem verifying your e-mail. Please try again.',
  user: 'User',
  login: 'Login',
  signIn: 'Sign In',
  signUp: 'Sign Up',
  logOut: 'Logout',
  forgotPassword:'Forgot Password',
  loginErrorInfo: 'Username or password is incorrect. Please check your credentials',
  loginErrorGeneralInfo: 'A general problem occured. Please try again later',
  updateSuccessful: 'Update is successful',
  createSuccessful: 'Successfully created',
  deleteSuccessful: 'Successfully deleted',
  passwordRequestSuccessful: 'Password Request is sent successfully',
  saveSuccessful: 'Successfully saved',
  previous: 'Previous',
  next: 'Next',
  saveAndFinish: 'Save and Finish',

  whatIsIt: 'What Is It',
  howItWork: 'How It Work',
  successStories: 'Success Stories',
  faq: 'FAQ',
  contactUs: 'Contact Us',
  recruiters: 'Recruiters',
  employers: 'Employers',
  hr: 'HR',
  createEmployerAccount: 'Create Employer Account',
  createRecruiterAccount: 'Create Recruiter Account',
  firstName: 'First Name',
  lastName: 'Last Name',
  phoneNumber: 'Phone Number',
  email: 'Email',
  password: 'Password',
  companyName: 'Company Name',

  generalInfo: 'General Info',
  basicDetails: 'Basic Details',
  companyInfo: 'Company Info',
  locationInfo: 'Location Info',
  companySocialLinks: 'Company Social Links',

  myDashboard: 'My Dashboard',
  trackCandidates: 'Track Candidates',

  contactNumberCode:'Contact Number Code',
  contactPersonFirstName: 'Contact Person First Name',
  contactPersonLastName: 'Contact Person Last Name',
  contactPersonEmailAddress: 'Contact Person Email Address',
  employerContactPhoneNumber: 'Employer Contact Number',
  size: 'Size',
  companySizeOfEmployees: 'Company Size of Employees',
  websiteURL: 'Website URL',

  turkey: 'Turkey',

  industry: 'Industry',
  selectIndustry: 'Select Industry',
  companyLogo: 'Company Logo',
  uploadCompanyLogo: 'Upload Company Logo',
  companyImage: 'Company Image',
  uploadCompanyImage: 'Upload Company Image',
  aboutCompany: 'About Company',

  addressLine1: 'Address Line 1',
  addressLine2: 'Address Line 2',
  country: 'Country',
  city: 'City',
  zipCode: 'Zip Code',

  linkedinProfileLink: 'LinkedIn Profile Link',
  facebookProfileLink: 'Facebook Profile Link',
  twitterProfileLink: 'Twitter Profile Link',
  googleplusProfileLink: 'Google+  Profile Link',
  instagramProfileLink: 'Instagram Profile Link',
  doYouHaveATS: 'Do you currently have an applicant tracking system (ATS)?',
  yes: 'Yes',
  no: 'No',

  basicInfo: 'Basic Info',
  aboutYou: 'About You',
  companyExperience:'Company Experience',
  paymentPreferences: 'Payment Preferences',
  yourSpecialization: 'Your Specialization',
  industrySpecialization: 'Industry Specialization',
  locationInformation:'Location Information',

  oneOfFiveQuestionInThisStep: '1 of 4 question in this step',
  twoOfFiveQuestionInThisStep: '2 of 4 question in this step',
  threeOfFiveQuestionInThisStep: '3 of 4 question in this step',
  fourOfFiveQuestionInThisStep: '4 of 4 question in this step',
  fiveOfFiveQuestionInThisStep: '4 of 4 question in this step',
  oneOfOneQuestionInThisStep: '1 of 1 question in this step',

  oneOfSixQuestionInThisStep: '1 of 6 question in this step',
  twoOfSixQuestionInThisStep: '2 of 6 question in this step',
  threeOfSixQuestionInThisStep: '3 of 6 question in this step',
  fourOfSixQuestionInThisStep: '4 of 6 question in this step',
  fiveOfSixQuestionInThisStep: '5 of 6 question in this step',
  sixOfSixQuestionInThisStep: '6 of 6 question in this step',

  oneOfthreeQuestionInThisStep: '1 of 3 question in this step',
  twoOfthreeQuestionInThisStep: '2 of 3 question in this step',
  threeOfthreeQuestionInThisStep: '3 of 3 question in this step',

  whoAreYou: 'Who Are You ?',
  individualRecruiter: 'Individual Recruiter',
  recruitmentFirm: 'Recruitment Firm',
  others: 'Others',
  recruimentFirmText: 'Please Enter Company Name',
  othersText: 'Please explain your role briefly.',

  howLongHaveYouRecruitedForTheMarket: 'How long have you recruited for the market?',

  WhatTypeOfJobRequisitionsDoYouRecruitFor: 'What type of job requisitions do you recruit for?',
  permanentDirectHire: 'Permanent / Direct Hire',
  temporaryContractHire:'Temporary / Contrac Hire',
  ContingentLaborContractRole: 'Contingent labor/Contract role',
  TemporaryToDirectHire: 'Temporary to direct hire',
  recruitmentProcessOutsourcing:'Recruiment Process Outsourcing',
  other: 'Other',

  whatIsYourPrimaryAndSecondaryContactNumber: 'What is your primary and secondary contact number?',
  primaryContactNumber: 'Primary Contact Number',
  secondaryContactNumber: 'Secondary Contact Number',

  whatBringsYouToFlynx: 'What brings you to Flynx?',
  iGrowMyRecruitingBusiness: 'I grow my recruiting business',
  iAmInBetweenJobsAndLookingForAnOpportunityToFillJobVacanciesAndEarnPlacementFees: 'I am in between jobs and looking for an opportunity to fill job vacancies and earn placement fees.',
  justBrowsing: 'Just browsing.',
  iHaveAFullTimeJobButIAmLookingToSupplementMyIncome: 'I have a full-time job but I am looking to supplement my income.',

  howDoYouWishToReceiveThePaymentAfterMakingASuccessfulPlacement: 'How do you wish to receive the payment after making a successful placement?',
  electronicPayment: 'Electronic payment',
  mailACheck: 'Mail a check',
  notSureAtTheMoment: 'Not sure at the moment',

  selectUpToFiveFunctionalRolesThatRepresentYourRecruitingExpertise: 'Select up to 5 functional roles that represent your recruiting expertise.',
  accountingFinance: 'Accounting & Finance',
  administrative: 'Administrative',
  customerService: 'Customer Service',
  education: 'Education',
  salesBusinessDevelopment: 'Sales & Business Development',
  marketingPRAdvertising: 'Marketing, PR & Advertising',
  management: 'Management',
  humanResources: 'Human Resources',
  legal: 'Legal',
  engineering: 'Engineering',
  informationTechnology: 'Information Technology',
  productionManufacturing: 'Production & Manufacturing',
  healthcare: 'Healthcare',
  researchDevelopment: 'Research Development',
  nonITConsultant: 'Non IT Consultant',
  technicianMechanic: 'Technician & Mechanic',




  selectUpToFiveIndustriesThatRepresentYourRecruitingExpertise: 'Select up to 5 industries that represent your recruiting expertise.',

  agricultureForestryFishingMining: 'Agriculture, Forestry, Fishing & Mining',

  automotiveAviation: 'Automotive & Aviation',
  staffingRecruitingHumanResources: 'Staffing, Recruiting & Human Resources',
  bankingFinancialServicesInsurance: 'Banking, Financial Services & Insurance',
  softwareTechnology: 'Software & Technology',
  consultingProfessionalServices: 'Consulting & Professional Services',
  manufacturingDiscreteProcess: 'Manufacturing (Discrete & Process)',
  construction: 'Construction',
  travelTransportationLogistics: 'Travel, Transportation & Logistics',
  consumerGoodsRetailWholesale: 'Consumer Goods, Retail & Wholesale',
  telecoMediaEntertainment: 'Telecom, Media & Entertainment',
  energyOilGasUtilities: 'Energy, Oil, Gas & Utilities',

  governmentNonprofitEducation: 'Government, Non-profit & Education',
  lifeSciencesBiotechMedicalDevicesPharmaceuticals: 'Life Sciences (Bio-tech, Medical Devices & Pharmaceuticals)',

  congratulations: 'CONGRATULATIONS',
  youHavesuccessfullycompletedyourprofile: 'You have successfully completed your profile.',
  youHaveEarnedLynxPoints: 'You have earned {0} points.',
  yourDashboard: 'Your Dashboard',

  whatIsYourRoleAt: 'What is your role at',

  ownerpresidentceo: 'Owner / President / CEO',
  foundingPartner: 'Founding Partner',
  vpOrDirectorOfRecruitingOrStaffing: 'VP or Director of recruiting or staffing',
  managerOfRecruitingOrStaffing: 'Manager of recruiting or staffing',
  executiveRecruiter: 'Executive Recruiter',
  seniorRecruiter: 'Senior Recruiter',
  recruiter: 'Recruiter',

  numberOfRecruitersWorkingFor:'Number of recruiters working for',
  iAmASoloPractitioner:'I am a solo practitioner',
  iDontKnow:'I don\'t know',
  whatIs:'What is',
  sWebsiteAddress:'\'s website address?',
  website:'Website Address',
  sLinkedinCompanyPage:'\'s LinkedIn Company Page?',
  linkedinAccount:'LinkedIn Company Page',

  howLongHas:'How long has',
  beenInStaffingAndRecruitingbusiness:'been in staffing & recruiting business?',

  itIsANewlyFoundedCompany:'It is a newly founded company',
  years:'years',

  whatTypeOfRecruitingAndStuffingServicesAreOfferedBy:'What type of recruiting & stuffing services services are offered by',

  iAmSoloPractitioner:'I am solo practitioner',
  whichjobmarketsdoyourecruitfor:'Which job markets do you recruit for ?',

  selectAll:'Select All',

  howMantPlacementsYouHaveMadeInLast12Months:'How many placements you have made in last 12 months?',


  experienceLevel:'Expreience Level',
  entryLevel:'Entry Level',
  associate:'Associate',
  midsenior:'Mid-Senior',
  director:'Director',
  executive:'Executive',
  selectNumberOfPlacements:'Select number of placements',

  choosePhoto: 'Choose Photo',
  atsAdditional: 'ATS Additional',

  editProfile:'Edit Profile',
  address:'Address',
  websitee:'Website',
  aboutYourCompany:'About Your Company',
  update:'Update',
  cancel:'Cancel',

};
