import {FormType} from 'src/app/shared/enums';
import {Router} from '@angular/router';
import {GeneralIdModel, SignUpModel, TlbFormModel} from 'src/app/shared/models';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {IndustryService, NotificationService, server, TranslatePipe} from 'src/app/shared/services';
import {FormControl, FormGroup, Validators} from '@angular/forms';

declare var $: any;

@Component({
    selector: 'app-sing-up',
    templateUrl: './sing-up.component.html',
    styleUrls: ['./sing-up.component.scss']
})
export class SingUpComponent implements OnInit {

    @Output() openCreateEmployerModal = new EventEmitter();
    @Output() openCreateRecruiterModal = new EventEmitter();
    formModel: TlbFormModel[] = [];
    formGroup: FormGroup;
    model: SignUpModel = new SignUpModel();
    showRegister: boolean = false;
    type: string = '';
    industryOptions: any[] = [];
    postIndustries: GeneralIdModel[] = [];
    showSuccessSignUp: boolean = false;

    constructor(private router: Router, private industryService: IndustryService,
                private notification: NotificationService, private translate: TranslatePipe) {
    }

    get form() {
        return this.formGroup.controls;
    }

    ngOnInit(): void {
        this.scrolling();
    }

    scrolling() {
        $('.fixed-top')
            .addClass('menu-bg');
        $('.navbar ')
            .removeClass('fixed-top');

        $('.sign-in-color')
            .addClass('scroll-text-color');

        $('.sign-up-color')
            .addClass('scroll-text-color');

        $('.sign-up-design')
            .addClass('sign-up-design2');
    }

    openCreateEmployerAccount(): void {
        this.openCreateEmployerModal.emit();
    }

    openCreateRecruiterAccount(): void {
        this.openCreateRecruiterModal.emit();
    }

    createForm() {

        this.industryService.ServerGetWithoutLogin()
            .subscribe(x => {
                let industries = x.content;
                let industryOptions = industries.map(c => ({item_id: c.id, item_text: c.defaultLocalization.name}));
                console.log(industryOptions);
                if (this.type == 'RECRUITER') {
                    this.formModel = [{
                        class: 'col-12', components: [
                            {
                                label: 'firstName',
                                control: 'firstName',
                                required: true,
                                type: FormType.text,
                                style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                            },
                            {
                                label: 'lastName',
                                control: 'lastName',
                                required: true,
                                type: FormType.text,
                                style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                            },
                            {
                                label: 'companyName',
                                control: 'companyName',
                                required: false,
                                type: FormType.text,
                                style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                            },
                            {
                                label: 'email',
                                control: 'email',
                                required: true,
                                type: FormType.email,
                                style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                            },
                            {
                                label: 'industry',
                                control: 'industry',
                                required: true,
                                msSettings: {
                                    singleSelection: false,
                                    idField: 'item_id',
                                    textField: 'item_text',
                                    selectAllText: 'Select All',
                                    unSelectAllText: 'UnSelect All',
                                    itemsShowLimit: 3,
                                    allowSearchFilter: false,
                                    limitSelection: 5,
                                    enableCheckAll: false
                                },
                                options: industryOptions,
                                type: FormType.multiSelect,
                                style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                            },
                            {
                                label: 'phoneNumber',
                                control: 'countryCode',
                                control2: 'phoneNumber',
                                required: true,
                                type: FormType.phoneNumber,
                                style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                            },
                            {
                                label: 'password',
                                control: 'password',
                                required: true,
                                type: FormType.password,
                                showPatternDetailsOnTheBottom: true,
                                patterns: [
                                    {pattern: '.*[a-z].*', errorText: 'mustALowerLetter'},
                                    {pattern: '.*[A-Z].*', errorText: 'mustAUpperLetter'},
                                    {pattern: '.*[0-9].*', errorText: 'mustADigit'},
                                    {pattern: '.*[@$!%*.?&:].*', errorText: 'mustASpecialLetter'},
                                    {pattern: '^.{6,}$', errorText: 'mustAtLeastSixLetter'},
                                ],
                                style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                            }
                        ]
                    }];
                    this.formGroup = new FormGroup({
                        firstName: new FormControl('', Validators.required),
                        lastName: new FormControl('', Validators.required),
                        companyName: new FormControl(''),
                        email: new FormControl(''),
                        industry: new FormControl(null, Validators.required),
                        countryCode: new FormControl(null, Validators.required),
                        phoneNumber: new FormControl(null, Validators.required),
                        password: new FormControl('',
                            [
                                Validators.required,
                                Validators.pattern('.*[a-z].*'),
                                Validators.pattern('.*[A-Z].*'),
                                Validators.pattern('.*[0-9].*'),
                                Validators.pattern(
                                    '.*[@$!%*.?&:].*'),
                                Validators.pattern('^.{6,}$')
                            ])
                    });
                } else {
                    this.formModel = [{
                        class: 'col-12', components: [
                            {
                                label: 'firstName',
                                control: 'firstName',
                                required: true,
                                type: FormType.text,
                                style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                            },
                            {
                                label: 'lastName',
                                control: 'lastName',
                                required: true,
                                type: FormType.text,
                                style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                            },
                            {
                                label: 'companyName',
                                control: 'companyName',
                                required: false,
                                type: FormType.text,
                                style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                            },
                            {
                                label: 'email',
                                control: 'email',
                                required: true,
                                type: FormType.email,
                                style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                            },
                            {
                                label: 'industry',
                                control: 'industry',
                                required: true,
                                msSettings: {
                                    singleSelection: false,
                                    idField: 'item_id',
                                    textField: 'item_text',
                                    selectAllText: 'Select All',
                                    unSelectAllText: 'UnSelect All',
                                    itemsShowLimit: 3,
                                    allowSearchFilter: false,
                                    limitSelection: 5,
                                    enableCheckAll: false
                                },
                                options: industryOptions,
                                type: FormType.multiSelect,
                                style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                            },
                            {
                                label: 'password',
                                control: 'password',
                                required: true,
                                type: FormType.password,
                                showPatternDetailsOnTheBottom: true,
                                patterns: [
                                    {pattern: '.*[a-z].*', errorText: 'mustALowerLetter'},
                                    {pattern: '.*[A-Z].*', errorText: 'mustAUpperLetter'},
                                    {pattern: '.*[0-9].*', errorText: 'mustADigit'},
                                    {pattern: '.*[@$!%*.?&:].*', errorText: 'mustASpecialLetter'},
                                    {pattern: '^.{6,}$', errorText: 'mustAtLeastSixLetter'},
                                ],
                                style: 'border: none; border-bottom: 1px solid gray; border-radius: 0;'
                            }
                        ]
                    }];
                    this.formGroup = new FormGroup({
                        firstName: new FormControl('', Validators.required),
                        lastName: new FormControl('', Validators.required),
                        companyName: new FormControl(''),
                        email: new FormControl(''),
                        industry: new FormControl(null, Validators.required),
                        password: new FormControl('', [
                            Validators.required,
                            Validators.pattern('.*[a-z].*'),
                            Validators.pattern('.*[A-Z].*'),
                            Validators.pattern('.*[0-9].*'),
                            Validators.pattern(
                                '.*[@$!%*.?&:].*'),
                            Validators.pattern('^.{6,}$')
                        ])
                    });
                }

            });
    }

    signUp() {
        this.model.accountType = this.type;
        this.type == 'RECRUITER' ? this.model.recruiter = true : this.model.employer = true;
        this.model.firstName = this.form.firstName.value;
        this.model.lastName = this.form.lastName.value;
        this.model.companyName = this.form.companyName.value;
        this.model.email = this.form.email.value;
        this.model.password = this.form.password.value;
        this.model.industries = this.form.industry.value.map(x => ({id: x.item_id}));

        if (this.type == 'RECRUITER') {
            if (!this.form.phoneNumber.value || this.form.phoneNumber.value == '') {
                this.model.phoneNumber = null;
            } else {
                this.model.phoneNumber.countryCallingCode = this.form.countryCode.value;
                this.model.phoneNumber.phoneNumber = this.form.phoneNumber.value;
            }
        } else {
            this.model.phoneNumber = null;
        }

        this.industryService.ServerPostWithoutLogin(server.signUp, this.model)
            .subscribe(x => {

                this.showSuccessSignUp = true;

            }, error => {
                this.notification.error(this.translate.transform(error.message));

            });

    }

    changeType(type: string) {
        this.type = type;
        this.showRegister = true;
        this.createForm();
    }

    goLogin() {
        this.router.navigateByUrl('login');
    }

    test() {
        console.log(this.formGroup);
    }
}
