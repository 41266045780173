import { CurrencyService } from './../../services/parameter/currency.service';
import { TranslatePipe } from './../../pipes/translate/translate.pipe';
import { ApplicationCountService, JobService, server } from 'src/app/shared/services';
import { JobModel } from 'src/app/shared/models';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-employer-job-detail-popup',
  templateUrl: './employer-job-detail-popup.component.html',
  styleUrls: ['./employer-job-detail-popup.component.scss']
})
export class EmployerJobDetailPopupComponent implements OnInit, OnChanges {

  @Input() job: JobModel;
  @Input() modalId: string = 'default';
  @Output() submitCandidateModalEmitter = new EventEmitter();
  selectedJob: JobModel;
  worklist: boolean;
  @Output() showCancelJobPopupEmitter = new EventEmitter();
  @Output() showDeleteJobPopup = new EventEmitter();
  @Output() editJobModalShowEmitter = new EventEmitter();
  @Output() openApplicantsModalEmitter = new EventEmitter();
  @Output() viewSalaryEmitter = new EventEmitter();
  @Output() getJobListAgain = new EventEmitter();
  role: string;
  currencySym: string = '';

  constructor(private jobService: JobService, private applicationCountService: ApplicationCountService,
    private translate: TranslatePipe,
    private currencyService: CurrencyService) {
    this.role = localStorage.getItem('role');
  }

  ngOnChanges(changes: SimpleChanges): void {


    if (this.job) {

      this.applicationCountService.ServerGetWithText('/?jobId=' + this.job.id)
        .subscribe(a => {
          this.job.offerStage = a.offerStage;
          this.job.clientReview = a.clientReview;
          this.job.interviewStage = a.interviewStage;
          this.job.managerReview = a.managerReview; // hr olan
          this.job.pendingReview = a.pendingReview;
          this.job.rejected = a.rejectedByEmployer;
          this.job.approved = a.approved;
          this.job.hired = a.hired;
          this.job.rejectedByEmployer = a.rejectedByEmployer;
          this.job.numberofApplicant = this.job.clientReview + this.job.managerReview + this.job.interviewStage + this.job.offerStage;

          switch (this.job.compensation.currency.id) {
            case '9e6567f2-c0b4-430a-9a7d-3ebc60563120':
              this.job.currencySymbol = "₺";
              break;
            case '8ea8bbb9-fbe8-45b1-baa1-fbe51e41cd71':
              this.job.currencySymbol = "$";
              break;
            case '61f03d25-f291-490c-97c5-33e5d92b5caf':
              this.job.currencySymbol = "€";
              break;
            case 'cababa4d-3e77-4c7c-9c18-c65be8dea197':
              this.job.currencySymbol = "£";
              break;
          }
        });
    }
  }

  ngOnInit(): void {
    this.worklist = false;
    // @ts-ignore
    $(document)
      .ready(function () {
        // @ts-ignore
        $('body')
          .tooltip({ selector: '[data-toggle=tooltip]' });
      });
  }

  changeWorkListStatus(toAdd: boolean) {
    switch (toAdd) {
      case true:
        this.jobService.addToWorkList(this.job.id)
          .subscribe(x => {
            this.worklist = true;
          });
        break;
      case false:
        this.jobService.removeFromWorkList(this.job.id)
          .subscribe(x => {
            this.worklist = false;
          });
        break;
      default:
        break;
    }
  }

  showCancelJobPopup() {
    this.showCancelJobPopupEmitter.emit();
  }

  editJobModalShow() {
    this.editJobModalShowEmitter.emit();
  }

  openApplicantsModal() {
    this.openApplicantsModalEmitter.emit(this.job);
    // @ts-ignore
    $('#' + this.modalId)
      .modal('hide');

  }

  deleteJobModal(job) {
    this.showDeleteJobPopup.emit();

    this.selectedJob = job;
    // @ts-ignore
    $('#generalDeleteModal')
      .modal('show');
  }

  deleteJob(flag): void {

    if (flag) {
      this.jobService.ServerDelete(server.jobs + '/' + this.selectedJob.id)
        .subscribe(data => {
          console.log(data);

          // @ts-ignore
          $('#generalDeleteModal')
            .modal('hide');

          // @ts-ignore
          $('#' + this.modalId)
            .modal('hide');

          this.getJobListAgain.emit();
        });

    }

  }

  addToWorkList(): void {
    this.jobService.addToWorkList(this.job.id)
      .subscribe(x => {
        this.getJobListAgain.emit();
        this.job.favorite = true;
      });
  }

  removeFromWorkList(): void {
    this.jobService.removeFromWorkList(this.job.id)
      .subscribe(x => {
        this.getJobListAgain.emit();
        this.job.favorite = false;
      });
  }

  openviewSalary() {
    this.viewSalaryEmitter.emit();
    // @ts-ignore
    $('#' + this.modalId)
      .modal('hide');
  }

  openSubmitCandidateModal() {
    this.submitCandidateModalEmitter.emit();
    // @ts-ignore
    $('#' + this.modalId)
      .modal('hide');
  }

  popoversDisplay() {
    // @ts-ignore
    $('.popover')
      .popover()
      .click(function () {
        setTimeout(function () {
          // @ts-ignore
          $('#tooltip')
            .tooltip('hide');
        }, 2000);
      });

  }
}
