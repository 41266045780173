import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RequestPayload } from 'src/app/shared/classes';
import { JobType } from 'src/app/shared/enums';
import { DropdownOptionsModel, JobModel } from 'src/app/shared/models';
import { JobService, TranslatePipe } from 'src/app/shared/services';

@Component({
  selector: 'app-recruiter-jobs',
  templateUrl: './recruiter-jobs.component.html',
  styleUrls: ['./recruiter-jobs.component.scss']
})
export class RecruiterJobsComponent implements OnInit {

  @Input() jobs: JobModel[] = [];
  @Input() count: number;
  @Input() limit: number;
  @Input() descriptionShow: boolean = false;
  @Output() updateJobs = new EventEmitter();
  @Output() updateCurrentPage = new EventEmitter();
  @Output() sorted = new EventEmitter();
  @Output() updatedStatus = new EventEmitter();
  @Output() getJobListAgain = new EventEmitter();
  @Output() updateGridOrList = new EventEmitter();
  selectedJob: JobModel = new JobModel();
  @Input() currentPage: number;
  @Input() gridArray: boolean = false;
  requestPayload: RequestPayload;
  sortText: string = '&sort=createdAt,DESC';
  filterText: string = '';
  postFilter: string = '';
  sortLabel: string = this.translate.transform('newestFirst');
  relatedJobs: any[] = [];
  @Input() fromRelated: boolean = false;
  sortOptions: DropdownOptionsModel[] = [
    { label: this.translate.transform('newestFirst'), value: '&sort=createdAt,DESC' },
    { label: this.translate.transform('oldestFirst'), value: '&sort=createdAt,ASC' }
  ];
  gridJobs: any[] = [];

  constructor(private translate: TranslatePipe, private jobService: JobService) {
    this.requestPayload = new RequestPayload();

  }

  ngOnInit(): void {
    this.requestPayload.limit = this.limit;
  }

  pagination(pageNo) {
    this.updateJobs.emit(pageNo);
    this.updateCurrentPage.emit(this.currentPage);
  }

  ngOnChanges() {
    const numsPerGroup = Math.ceil(this.jobs.length / 2);
    const num = Math.ceil(this.jobs.length / numsPerGroup);

    const result = new Array(numsPerGroup)
      .fill('')
      .map((_, i) => this.jobs.slice(i * num, (i + 1) * num));
    this.gridJobs = result;

  }

  selectSort(data) {
    this.sortLabel = data.label;
    this.sortText = data.value;
    this.sorted.emit(this.sortText);
    // this.getUsers(1);
  }

  public get jobType(): typeof JobType {
    return JobType;
  }
  openfullJobDescriptionModal(job: JobModel) {

    this.selectedJob = job;
    // @ts-ignore
    $('#jobDetailFromRecruiter').modal('show');
    /*    // @ts-ignore
        $('#fullJobDescriptionModal').modal('show');*/
  }

  updateStatus() {
    this.updatedStatus.emit();

  }

  getJobListAgainEmit() {
    this.updateCurrentPage.emit(this.currentPage);
    this.getJobListAgain.emit();
  }

  OpenModalViewSalary(job: JobModel) {
    this.selectedJob = job;
    // @ts-ignore
    $('#viewSalaryModalFromRec').modal('show');
    // @ts-ignore
    $('#fullJobDescriptionModal').modal('hide');
  }

  submitCandidate(job: JobModel) {
    this.selectedJob = job;

    // @ts-ignore
    $('#submitCandidateModal').modal('show');
  }

  candidateReviewScreen() {
    // @ts-ignore
    $('#candidateReviewModal').modal('show');

  }

  gridOrList(type) {
    if (type == 'list') {
      this.gridArray = false;

    }
    else {
      this.gridArray = true;

    }

    this.updateGridOrList.emit(this.gridArray);
  }

  openRelatedJobsModal(job) {

    this.jobService.ServerGetWithText('/' + job.id + '/related-jobs').subscribe(x => {
      this.relatedJobs = x;
      // @ts-ignore
      $('#related-jobs-modal').modal('show');
      console.log(x);

    });


  }


}
