import { GeneralIdNameModel } from '../general';

export class RecruitersWithRecommendationModel {
  recruiterPagination: PaginationDtoAllRecruiterInfoModel;
  recruiters: AllRecruiterInfoModel[]; // Recommendations

  constructor() {
    this.recruiterPagination = new PaginationDtoAllRecruiterInfoModel();
    this.recruiters = [];
  }
}

export class PaginationDtoAllRecruiterInfoModel {
  content: AllRecruiterInfoModel[];
  pageIndex: number;
  pageSize: number;
  numberOfElements: number;
  totalPages: number;
  totalElements: number;
  first: boolean;
  last: boolean;
  empty: boolean;

  constructor() {
    this.content = [];
    this.pageIndex = 0;
    this.pageSize = 0;
    this.numberOfElements = 0;
    this.totalPages = 0;
    this.totalElements = 0;
    this.first = false;
    this.last = false;
    this.empty = false;
  }
}

export class AllRecruiterInfoModel {
  createdAt: Date;
  createdBy: GeneralIdNameModel;
  updatedAt: Date;
  updatedBy: GeneralIdNameModel;
  id: string;
  type: string;
  user: User;
  primaryContactNumber: PhoneNumberModel;
  secondaryContactNumber: PhoneNumberModel;
  talentbaseReferenceType: string;
  individualPracticeTime: string;
  paymentReferenceType: string;
  companyName: string;
  positionAtCompany: string;
  website: string;
  linkedinAccount: string;
  recruiterCount: string;
  firmPracticeTime: string;
  customType: string;
  profilePhotoUrl: string;
  jobMarkets: GeneralIdNameModel[];
  requisitionTypes: string[];
  functionalRoles: GeneralIdNameModel[];
  industries: GeneralIdNameModel[];
  placementCounts: any;
  recommended: boolean;
  totalPlacements: number;
  rating: number;
  favorite: boolean;
  applicationCounts: any;
  skillTags: SkillTag[];
  imageColor: string;
  imageText: string;
  isChecked: boolean;

  constructor() {
    this.createdAt = new Date();
    this.createdBy = new GeneralIdNameModel();
    this.updatedAt = new Date();
    this.updatedBy = new GeneralIdNameModel();
    this.id = '';
    this.type = '';
    this.user = new User();
    this.primaryContactNumber = new PhoneNumberModel();
    this.secondaryContactNumber = new PhoneNumberModel();
    this.talentbaseReferenceType = '';
    this.individualPracticeTime = '';
    this.paymentReferenceType = '';
    this.companyName = '';
    this.positionAtCompany = '';
    this.website = '';
    this.linkedinAccount = '';
    this.recruiterCount = '';
    this.firmPracticeTime = '';
    this.customType = '';
    this.profilePhotoUrl = '';
    this.jobMarkets = [];
    this.requisitionTypes = [];
    this.functionalRoles = [];
    this.industries = [];
    this.placementCounts = {};
    this.recommended = false;
    this.totalPlacements = 0;
    this.rating = 0;
    this.favorite = false;
    this.applicationCounts = { };
    this.skillTags = [];
    this.imageColor = '';
    this.imageText = '';
    this.isChecked = false;
  }
}


export class PhoneNumberModel {
  countryCallingCode: string;
  phoneNumber: string;

  constructor() {
    this.countryCallingCode = '';
    this.phoneNumber = '';
  }
}

export class SkillTag {
  createdAt: Date;
  createdBy: GeneralIdNameModel;
  updatedAt: Date;
  updatedBy: GeneralIdNameModel;
  id: string;
  name: string;
  weight: number;
  status: string;

  constructor() {
    this.createdAt = new Date();
    this.createdBy = new GeneralIdNameModel();
    this.updatedAt = new Date();
    this.updatedBy = new GeneralIdNameModel();
    this.id = '';
    this.name = '';
    this.weight = 0;
    this.status = '';
  }
}

export class User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: PhoneNumberModel;
  preferredLanguage: string;
  fullName: string;

  constructor() {
    this.id = '';
    this.email = '';
    this.firstName = '';
    this.lastName = '';
    this.phoneNumber = new PhoneNumberModel();
    this.preferredLanguage = '';
    this.fullName = '';
  }
}
