export class EmployerCountsModel {

  totalEmployers: number;
  totalEmployersSinceLastMonth: number;

  constructor() {
  this.totalEmployers = 0;
  this.totalEmployersSinceLastMonth = 0;
  }
  }
