import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-news-modal',
  templateUrl: './dashboard-news-modal.component.html',
  styleUrls: ['./dashboard-news-modal.component.scss']
})
export class DashboardNewsModalComponent implements OnInit {

  @Input() new: any;

  constructor() { }

  ngOnInit(): void {


  }

  ngOnChanges(){
  }

  closeNewsModal(){
    // @ts-ignore
    $('#modalNews').modal('hide');
  }

}
