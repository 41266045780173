import { Component, OnInit } from '@angular/core';
import { CreateAvatar, RequestPayload } from 'src/app/shared/classes';
import { ApplicationModel } from 'src/app/shared/models';
import { ApplicationService, JobService, server } from 'src/app/shared/services';

@Component({
  selector: 'app-track-applications',
  templateUrl: './track-applications.component.html',
  styleUrls: ['./track-applications.component.scss']
})

export class TrackApplicationsComponent implements OnInit {

  applications: ApplicationModel[] = [];
  allApplications: ApplicationModel[] = [];
  selectedCandidate: ApplicationModel;
  job: any;
  selectedApplicationId: string = "";
  filterData: any = {};
  sortText: string = "&sort=createdAt,DESC";
  requestPayload: RequestPayload;
  currentPage: number;
  count: number;
  storedItems: any[] = [];
  searchText: string = "";
  createAvatar: CreateAvatar;
  selectedApplications: any[] = [];

  constructor(private applicationService: ApplicationService, private jobService: JobService) {
    this.filterData.candidateId = "";
    this.filterData.employerId = "";
    this.filterData.status = "";
    this.requestPayload = new RequestPayload();
    this.requestPayload.limit = 5;
    this.createAvatar = new CreateAvatar();
  }

  ngOnInit(): void {
    this.getApplications(1);
  }

  getApplications(pageNo) {
    this.applicationService.ServerGetWithText(this.requestPayload.payloadURLApp(pageNo) + this.requestFilter() + this.sortText)
      .subscribe((res: any) => {
        this.applications = res.content;
        this.storedItems = this.applications;
        this.searchText != "" ? this.search(this.searchText) : "";
        this.count = res.totalElements;
        this.applications.forEach(f => {
          f.date = new Date(f.createdAt).toLocaleDateString();
          f.imageText = this.createAvatar.create(f.candidate.fullName);
          f.imageColor = this.createAvatar.color();
        });
      });
  }

  requestFilter() {
    return '&filter=status**' + this.filterData.status +
      '&filter=candidate.id**' + this.filterData.candidateId +
      '&filter=employerId**' + this.filterData.employerId
  }

  openSubmitApplicationModal(app) {
    this.jobService.ServerGetWithText("/" + app.job.id).subscribe(j=>{
      app.location = j.location;
      app.jobType = j.type;


    this.selectedApplications = [];
    this.selectedApplications.push(app);

        // @ts-ignore
        $('#applicantsModalLong').modal('show');
    });


    // this.jobService.ServerGetById(server.jobs + '/' + app.job.id).subscribe(x => {
    //   this.job = x;
    //   let time = Math.abs(new Date().getTime() - new Date(x.createdAt).getTime());
    //   this.job.postDate = Math.ceil(time / (1000 * 3600 * 24));
    //   this.selectedCandidate = app.candidate.fullName;
    //   this.selectedApplicationId = app.id;

    //   // @ts-ignore
    //   $('#submitCandidateModal').modal('show');
    // });
  }

  openCancelApplicationModal(app) {
    this.selectedApplicationId = app.id;

    // @ts-ignore
    $('#cancelApplicationModal').modal('show');
  }

  getSortedApps(e) {
    this.sortText = e == "noSort" ? "" : "&sort=" + e;
    this.getApplications(1);

  }

  getFilteredApps(e) {
    this.filterData = e;
    this.getApplications(1);

  }
  clearFilter(e) {
    this.filterData = e;
    this.getApplications(1);
  }

  search(data) {
    this.searchText = data;
    if (data == "") this.getApplications(1);

    // tslint:disable-next-line:max-line-length
    this.applications = ((this.storedItems).filter((item: ApplicationModel) =>
      (((item.candidate.fullName).toLowerCase().trim()).indexOf(data.toLowerCase().trim()) > -1) ||
      (((item.job.name).toLowerCase().trim()).indexOf(data.toLowerCase().trim()) > -1) ||
      (((item.employer.name).toLowerCase().trim()).indexOf(data.toLowerCase().trim()) > -1)
    ));
  }

}
