import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormType } from 'src/app/shared/enums';
import { TlbFormModel } from 'src/app/shared/models';
import { TranslatePipe, NotificationService, server, UserService, RecruiterMeService } from 'src/app/shared/services';

@Component({
  selector: 'app-recruiter-edit-basic-info',
  templateUrl: './recruiter-edit-basic-info.component.html',
  styleUrls: ['./recruiter-edit-basic-info.component.scss']
})
export class RecruiterEditBasicInfoComponent implements OnInit {

  formModel: TlbFormModel[] = [];
  formGroup: FormGroup;
  recruiterUser: any = {};
  defaultUser: any = {};

  constructor(private translate: TranslatePipe, private notification: NotificationService,
    private userService: UserService, private recruiterMeService: RecruiterMeService) { }

  ngOnInit(): void {
    this.getInfo();
  }

  getInfo() {
    this.recruiterMeService.ServerGet().subscribe(res => {
      this.recruiterUser = res.user;
      this.defaultUser = Object.assign({}, this.recruiterUser);
      this.defaultUser.phoneNumber = Object.assign({}, this.recruiterUser.phoneNumber);
      this.createForm();
    });
  }

    // disableControl(): boolean {
  //   return JSON.stringify(this.recruiterModel) == JSON.stringify(this.defaultModel) && !this.isEdited;
  // }

  createForm() {

    let s = this.recruiterUser ? this.recruiterUser : null;

    this.formModel = [
      {
        class: 'col-xl-6 col-md-6 col-12', components: [
          { label: 'firstName', control: 'firstName', required: true, type: FormType.text },
          {
            label: 'phoneNumber', control: 'countryCode', control2: 'phoneNumber', type: FormType.phoneNumber, required: true
          }
        ]
      },
      {
        class: 'col-xl-6 col-md-6 col-12', components: [
          { label: 'lastName', control: 'lastName', required: true, type: FormType.text },
          { label: 'email', control: 'email', required: true, type: FormType.email },
        ]
      }
    ];

    this.formGroup = new FormGroup({
      firstName: new FormControl(s ? s.firstName : '', Validators.required),
      countryCode: new FormControl(s ? s.phoneNumber.countryCallingCode : null, Validators.required),
      phoneNumber: new FormControl(s ? s.phoneNumber.phoneNumber : null, Validators.required),
      lastName: new FormControl(s ? s.lastName : '', Validators.required),
      email: new FormControl(s ? s.email : '', Validators.required),
    });
  }

  get form() { return this.formGroup.controls }

  updateInfo() {
    this.recruiterUser.role = "RECRUITER";
    this.recruiterUser.firstName = this.form.firstName.value;
    this.recruiterUser.countryCode = this.form.countryCode.value;
    this.recruiterUser.phoneNumber = this.form.phoneNumber.value;
    this.recruiterUser.lastName = this.form.lastName.value;
    this.recruiterUser.email = this.form.email.value;

    this.userService.ServerPut(server.user + "/" + this.recruiterUser.id, this.recruiterUser).subscribe(res => {
      this.notification.success(this.translate.transform('yourProfileHasBeenSuccessfullyUpdated'));
    });
  }

  openChangePassword() {
    // @ts-ignore
    $('#changePasswordModal').modal('show');
  }

}
