<div class="container-fluid">
  <!-- <div class="d-flex"> -->
    <div class="col-xl-11 col-md-10 col-10 m-auto">
      <div class="row">
        <div class="col-xl-9 col-12">
          <app-jobs-filter [showAllFilter]="false" (resultFilter)="getFilteredJobs($event)"
            (clearFilter)="getClearFilterJobs($event)" (resultSearch)="search($event)"></app-jobs-filter>
          <tlb-empty-page *ngIf="!jobs || jobs.length == 0" [warningLabel]="searchText == ''  ?
      'noJobYet' : searchText" [filterText]="searchText"></tlb-empty-page>

          <app-recruiter-jobs *ngIf="jobs && jobs.length > 0" [jobs]="jobs" [count]="count" [limit]="3" [gridArray]="gridArray" [currentPage]="currentPage"
            (getJobListAgain)="ngOnInit()" (updateJobs)="getJobs($event)" (updateCurrentPage)="updateCurrentPage($event)" (sorted)="sorted($event)"
            (updatedStatus)="getJobs(1)" [descriptionShow]="true" (updateGridOrList)="updateGridOrList($event)"></app-recruiter-jobs>
        </div>
        <div class="col-xl-3 col-12">
          <app-search-jobs-filter (resultFilter)="getFilteredJobs($event)" (clearFilter)="getClearFilterJobs($event)"
            (resultSearch)="search($event)"></app-search-jobs-filter>
        </div>
      </div>
    </div>

  <!-- </div> -->

</div>
