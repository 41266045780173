import { JobQuestionModel } from "./job-question-model";

export class JobQuestionnaireModel {

  questions: JobQuestionModel[];

  constructor() {
    this.questions = [];
  }
}
