
<div
  *ngFor="let job of jobs | paginate:{ itemsPerPage: requestPayload.limit, currentPage: currentPage,totalItems:count}"
  class="row mt-3">
  <div class="col-12 pr-1 pl-0">
    <div class="card">
      <div class="card-body">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <b>{{job.title}}</b>

            </div>


          </div>
          <div class="row">
            <div class="col-12 left-box">
              <div class="row pb-4">
                <div class="col-12 col-sm-4 col-xl-2 my-2 my-xl-0 gray pr-0">{{job.employer.name}}</div>
              </div>
              <div class="row pb-1 ">
                <div class="col-12 col-sm-6 col-xl-3 my-2 my-xl-0 gray pr-0"><i
                    class="fas fa-calendar icon mr-3"></i>{{'dateOfEntry' | translate}}</div>
                <div class="col-12 col-sm-6 col-xl-3 my-2 my-xl-0">{{job.postDate}} {{ 'daysAgo' | translate }}
                </div>
                <div class="col-12 col-sm-6 col-xl-3 my-2 my-xl-0 gray pr-0"><i
                    class="fas fa-user-friends icon mr-3"></i>{{'totalNumberOfPositions' | translate}}</div>
                <div class="col-12 col-sm-6 col-xl-3 my-2 my-xl-0">{{job.positionCount}}</div>
              </div>
              <div class="row pb-1 ">
                <div class="col-12 col-sm-6 col-xl-3 my-2 my-xl-0 gray pr-0"><i
                    class="fas fa-map-marker icon mr-3"></i>{{'adminJobLocation' | translate}}</div>
                <div class="col-12 col-sm-6 col-xl-3 my-2 my-xl-0">{{job.location.city.name}},
                  {{job.location.country.name}}</div>
                <div class="col-12 col-sm-6 col-xl-3 my-2 my-xl-0 gray pr-0"><i
                    class="fas fa-suitcase icon mr-3"></i>{{'jobClassification' | translate}}</div>
                <div class="col-12 col-sm-6 col-xl-3 my-2 my-xl-0">{{job.classification.name}}</div>
              </div>
              <div class="row pb-1 ">
                <div class="col-12 col-sm-6 col-xl-3 my-2 my-xl-0 gray pr-0"><i
                    class="fas fa-handshake icon mr-3"></i>{{'jobType' | translate}}</div>
                <div class="col-12 col-sm-6 col-xl-3 my-2 my-xl-0">{{(job.type) | translate }}</div>
                <div class="col-12 col-sm-6 col-xl-3 my-2 my-xl-0 gray pr-0"><i
                    class="fas fa-suitcase icon mr-3"></i>{{'jobStatus' | translate}}</div>
                <div class="col-12 col-sm-6 col-xl-3 my-2 my-xl-0">{{job.status | translate}}</div>
              </div>
            </div>
            <div class="col-12 ml-xl-auto pt-3 pb-3 pb-xl-2 right-box">
              <div class="row">
                <div class="col-1 col-xl-2 icon-col">
                  <img src="assets/images/dashboard/pending-review.png" alt="" class="img-fluid">
                </div>
                <div class="col pl-0">
                  {{'pendingReview'|translate}}
                </div>
                <div class="col-1 p-0 mr-2 purple" (click)="openApplicantsModal(job)"><b>{{job.pendingReview}}</b></div>
              </div>
              <div class="row mt-2">
                <div class="col-1 col-xl-2 icon-col">
                  <img src="assets/images/dashboard/under-consideration.png" alt="" class="img-fluid">
                </div>
                <div class="col pl-0">
                  {{'underConsideration'|translate}}
                </div>
                <div class="col-1 p-0 mr-2 purple"><b>{{job.underConsideration}}</b></div>
              </div>
              <div class="row mt-2">
                <div class="col-1 col-xl-2  icon-col">
                  <img src="assets/images/dashboard/total-applications.png" alt="" class="img-fluid">
                </div>
                <div class="col pl-0">
                  {{'TotalApplications'|translate}}
                </div>
                <div class="col-1 p-0 mr-2 purple"><b>{{job.totalApplications}}</b></div>
              </div>
            </div>
          </div>


          <div class="row mt-3">
            <div class="col-12 right-box ml-auto p-0 border-0">
              <button class="form-control w-100 green-button" *ngIf="job.status=='ACTIVE'" (click)="openApplicantsModal(job, 'all')"  >
                {{'viewAllApplications'|translate}}
              </button>
              <button class="form-control w-100 gray-button" *ngIf="job.status=='DRAFT'||job.status=='PASSIVE'" (click)="editJobModalShow(job)">
                {{'editJob'|translate}}
              </button>
            </div>
            <div class="col-12 right-box ml-4 p-0 border-0 ">
              <button class="form-control w-100 purple-button" (click)="openJobDetail(job)">
                {{'viewJobDetail'|translate}}
              </button>
            </div>
            <div class="col-12 right-box ml-4 p-0 border-0 " *ngIf="job.status=='DRAFT'||job.status=='PASSIVE'">
              <button class="form-control w-100 green-button" (click)="activeJob(job)">
                {{'publishJob'|translate}}
              </button>
            </div>


          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="row mt-3 pagination">
  <pagination-controls (pageChange)="currentPage = $event;pagination(currentPage)"
    [autoHide]="count <= requestPayload.limit" nextLabel="{{'next' | translate}}"
    previousLabel="{{'previous' | translate}}" responsive="true"></pagination-controls>
</div>


<app-employer-job-detail-popup [job]="selectedJob" [modalId]="'jobDetailFromEmployer'"
(showCancelJobPopupEmitter)="showCancelJobPopup()"
(editJobModalShowEmitter)="editJobModalShow()"
(openApplicantsModalEmitter)="openApplicantsModal($event, 'all')"></app-employer-job-detail-popup>

<app-general-yes-no-popup (selection)="cancelJob($event)" [Text]="'cancelJobAreYouSureText'"></app-general-yes-no-popup>
<app-applicants-modal [applications]="applications" [job]="selectedJob"
(openUpdateCandidateStatusEmitter)="openUpdateCandidateStatus($event)"
(openCandidateDetailPopupEmitter)="openCandidateDetailPopup($event)"
></app-applicants-modal>
<app-edit-job *ngIf="selectedJob && selectedJob.id" [job]="selectedJob"></app-edit-job>
<app-candidate-update-status-popup [application]="selectedApplication" (updatedCandidateStatus)="updateStatus()">
</app-candidate-update-status-popup>
<app-candidate-detail-popup [application]="selectedApplication" [job]="selectedJob"
(openUpdateCandidateStatusEmitter)="openUpdateCandidateStatus($event)"
(openReviewsEmitter)="openReviewModal($event)"
></app-candidate-detail-popup>
<app-employer-recruiter-review-popup [reviews]="reviews"></app-employer-recruiter-review-popup>
