<!-- Modal -->
<div class="modal fade" id="recruiterDetailModal">
  <div class="modal-dialog" style="max-width: 550px;">
    <div class="modal-content">


      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="float-right mr-3 mt-3 fas fa-times" style="color: black;"></i>
      </button>

      <div *ngIf="!cameFromReviews" class="content-div">
        <div class="row">
          <div>
            <div [style.background-color]="recruiter?.imageColor" id="preimage"><span
                style="color: white;">{{recruiter?.imageText}}</span></div>
          </div>
          <div class="ml-4">
            <div class="name-text">{{recruiter?.user?.fullName}}</div>
            <div class="type-text"><span *ngIf="recruiter && recruiter.type"> {{recruiter.type | translate}} </span>
              <span *ngIf="!recruiter || !recruiter.type">{{ 'notSpecified' | translate}}</span>
            </div>
          </div>
          <div class="col-12">
            <div class="row pb-3 pt-3 pl-3">
              <span style="color: #F1B856;" [ngClass]="recruiter?.rating > 0 ? recruiter?.rating < 1 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"></span>
              <span style="color: #F1B856;" [ngClass]="recruiter?.rating > 1 ? recruiter?.rating < 2 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"></span>
              <span style="color: #F1B856;" [ngClass]="recruiter?.rating > 2 ? recruiter?.rating < 3 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"></span>
              <span style="color: #F1B856;" [ngClass]="recruiter?.rating > 3 ? recruiter?.rating < 4 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"></span>
              <span style="color: #F1B856;" [ngClass]="recruiter?.rating > 4 ? recruiter?.rating < 5 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"></span>
            </div>
          </div>

          <div *ngIf="recruiter && recruiter.recommended" class="col-12">
            <img src="../../../assets/images/recomended.png"> <span class="recomended-text ml-1">{{'recomendedByTalentbase' | translate}}</span>
            <span class="recomended-text" style="font-weight: 400;"> {{ 'employersGaveThem' | translate }} {{ recruiter?.rating | number : '1.2-2' }} {{ 'starReviews' | translate }} </span>
          </div>
        </div>

        <div class="row mt-4">

          <div class="card" style="width: 25%;">
            <div class="bottom-numbers">
              {{recruiter?.applicationCounts?.hired}}
            </div>
            <div class="bottom-texts">
              {{ 'hired' | translate }}
            </div>
          </div>

          <div class="card" style="width: 25%;">
            <div class="bottom-numbers">
              {{recruiter?.applicationCounts?.interviewStage}}
            </div>
            <div class="bottom-texts">
              {{ 'interviewed' | translate }}
            </div>
          </div>

          <div class="card" style="width: 25%;">
            <div class="bottom-numbers" style="color: #FE2E56;">
              {{recruiter?.applicationCounts?.rejectedByEmployer}}
            </div>
            <div class="bottom-texts" style="color: #FE2E56;">
              {{ 'rejected' | translate }}
            </div>
          </div>

          <div class="card" style="width: 25%;">
            <div class="bottom-numbers">
              {{recruiter?.totalPlacements}}
            </div>
            <div class="bottom-texts">
              {{ 'placements' | translate }}
            </div>
          </div>

        </div>

      </div>

      <div class="mt-2 mb-4" style="border: 10px solid rgba(18, 17, 39, 0.12);"></div>


      <div style="padding: 2px 15px 20px 15px;">
        <div class="row mt-4">
          <div class="col-12 pb-2 titles">
            <b>
              {{'recruitingInfo'|translate}}
            </b>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12 col-sm-4">
            <img src="./../../../../../assets/images/talentbase/icons/briefcase.png">
            <span class="desc">{{ 'recruitmentExperience' | translate }}</span>
          </div>
          <div class="col-12 col-sm-8 comm-text">
            <span *ngIf="recruiter && recruiter.individualPracticeTime ;else naContent">{{recruiter?.individualPracticeTime | translate}}</span> <ng-template #naContent>{{'na' | translate}}</ng-template>

          </div>
        </div>

        <div class="row mt-2">
          <div class="col-12 col-sm-4">
            <img src="./../../../../../assets/images/talentbase/icons/chart-bar.png">
            <span class="desc">{{ 'jobRecruisitionTypes' | translate }}</span>
          </div>
          <div class="col-12 col-sm-8 comm-text">
            <span *ngIf="recruiter && recruiter.requisitionTypesText;else narContent">{{recruiter?.requisitionTypesText}}</span> <ng-template #narContent>{{'na' | translate}}</ng-template>

          </div>
        </div>

        <div class="row mt-2">
          <div class="col-12 col-sm-4">
            <img src="./../../../../../assets/images/talentbase/icons/locations.png">
            <span class="desc">{{ 'functionalRoles' | translate }}</span>
          </div>
          <div class="col-12 col-sm-8 comm-text">
            <span *ngIf="recruiter && recruiter.functionalRolesText;else nafContent">{{recruiter?.functionalRolesText}}</span> <ng-template #nafContent>{{'na' | translate}}</ng-template>

          </div>
        </div>

        <div class="row mt-2">
          <div class="col-12 col-sm-4">
            <img src="./../../../../../assets/images/talentbase/icons/chart-pie.png">
            <span class="desc">{{ 'industries' | translate }}</span>
          </div>
          <div class="col-12 col-sm-8 comm-text">
            <span *ngIf="recruiter && recruiter.industriesText;else naiContent">{{recruiter?.industriesText}}</span> <ng-template #naiContent>{{'na' | translate}}</ng-template>

          </div>
        </div>
      </div>

      <div *ngIf="reviews && reviews.length > 0 && !cameFromReviews" class="mt-2 mb-4" style="border: 10px solid rgba(18, 17, 39, 0.12);"></div>

      <div *ngIf="reviews && reviews.length > 0" style="padding: 2px 15px 20px 15px;">

        <span class="comments-header mb-2">{{'comments' | translate}}</span>

        <div *ngFor="let review of reviews" class="mt-2"
          style=" border-top: 1px solid rgba(18, 17, 39, 0.12); padding: 2px 15px 20px 15px;">

          <div class="row mt-2">
            <div>
              <div [style.background-color]="review?.imageColor" id="preimage"><span
                  style="color: white;">{{review?.imageText}}</span></div>
            </div>
            <div class="ml-4">
              <span class="comm-name">{{review?.fullName}}</span> <span class="comm-date ml-2">{{review?.createdAt |
                date}} {{review.createdAt | date:'shortTime'}}</span>
              <div class="col-12">
                <div class="row pb-2 pt-1 pl-1">
                  <span [ngClass]="review.rating > 0 ? 'fa fa-star' : 'far fa-star'"></span>
                  <span [ngClass]="review.rating > 1 ? 'fa fa-star' : 'far fa-star'"></span>
                  <span [ngClass]="review.rating > 2 ? 'fa fa-star' : 'far fa-star'"></span>
                  <span [ngClass]="review.rating > 3 ? 'fa fa-star' : 'far fa-star'"></span>
                  <span [ngClass]="review.rating > 4 ? 'fa fa-star' : 'far fa-star'"></span>

                </div>
              </div>

              <div class="col-12 row">
                <span class="comm-text ml-2">
                  {{review?.comment}}
                </span>

              </div>
            </div>

          </div>

        </div>

        <div *ngIf="count > 3" class="view-text mr-3" >
          <div class="row">
            <button [disabled]="page == 1" class="arrow-buttons mr-3" (click)="getReviews(page - 1)">
              <i class="fas fa-chevron-left"></i>
            </button>
            <button [disabled]="page * 3 >= count" class="arrow-buttons" (click)="getReviews(page + 1)">
              <i class="fas fa-chevron-right"></i>
            </button>

          </div>
        </div>

      </div>

    </div>
  </div>
</div>
