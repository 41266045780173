import { Component, Input, OnInit } from '@angular/core';
import { RequestPayload } from 'src/app/shared/classes';
import { JobType } from 'src/app/shared/enums';
import { JobFilterDataModel, JobModel, NewsModel } from 'src/app/shared/models';
import { ApplicationService, JobService } from 'src/app/shared/services';

@Component({
  selector: 'app-employer-dashboard',
  templateUrl: './employer-dashboard.component.html',
  styleUrls: ['./employer-dashboard.component.scss']
})

export class EmployerDashboardComponent implements OnInit {

  @Input() news: NewsModel[] = [];
  jobs: JobModel[] = [];
  count: number;
  requestPayload: RequestPayload;
  filterData: JobFilterDataModel;
  recentNews: any[] = [];
  new: NewsModel = new NewsModel;
  storedItems: any[] = [];
  searchText: string = "";
  allJobs: JobModel[] = [];
  statusText: string = "";

  constructor(private jobService: JobService, private applicationService: ApplicationService) {
    this.requestPayload = new RequestPayload();
    this.filterData = new JobFilterDataModel();
    this.requestPayload.limit = 3;
  }

  ngOnInit(): void {

    this.getJobs(1);

  }



  getJobs(pageNo) {
    this.jobService.ServerGetWithText(this.requestPayload.payloadURL(pageNo) + this.requestFilter() + '&filter=status::' + this.statusText).subscribe(x => {
      this.jobs = x.jobPagination.content as JobModel[];

      this.count = x.jobPagination.totalElements;
      this.storedItems = this.jobs;
      this.searchText != "" ? this.search(this.searchText) : "";
      this.applicationService.ServerGet().subscribe(x => {
        let applications = x.content;
        this.jobs.forEach(x => {

          let time = Math.abs(new Date().getTime() - new Date(x.createdAt).getTime());
          x.postDate = Math.ceil(time / (1000 * 3600 * 24));
          x.applications = applications.filter(f => f.job.id == x.id && f.status != "HIRED" && f.status != "CANCELLED" && f.status != "REJECTED_BY_CANDIDATE"
            && f.status != "REJECTED_BY_EMPLOYER");
          x.applications?.forEach(a => { a.date = new Date(a.createdAt).toLocaleDateString() });
          x.pendingReview = x.applications.some(s => (s.job.id == x.id) && (s.status != "CANCELLED") && (s.status != "REJECTED_BY_CANDIDATE") &&
            (s.status != "REJECTED_BY_EMPLOYER") && (s.status != "HIRED")) ? x.applications.filter(s => (s.job.id == x.id) && (s.status != "CANCELLED")
              && (s.status != "REJECTED_BY_CANDIDATE") && (s.status != "REJECTED_BY_EMPLOYER") && (s.status != "HIRED")).length : 0;
          x.underConsideration = x.applications.filter(a => a.status == "CLIENT_REVIEW" || a.status == "MANAGER_REVIEW").length;
          let apps = applications.filter(f => f.job.id == x.id);
          x.totalApplications = apps.length;
          x.allApplications = apps;
          x.allApplications?.forEach(a => { a.date = new Date(a.createdAt).toLocaleDateString() });

        });

        this.allJobs = this.jobs;

      });

    });

  }

  setStatusFilter(event){
    this.statusText = event;
    this.getJobs(1);
  }

  requestFilter() {
    return '&filter=countryId**' + this.filterData.country +
      '&filter=cityId**' + this.filterData.city +
      '&filter=freeText~~' + this.filterData.jobTitle +
      '&filter=type**' + this.filterData.jobType +
      '&filter=functionId**' + this.filterData.jobFunction +
      '&filter=industryId**' + this.filterData.industry
  }

  getFilteredJobs(e) {
    this.filterData = e;
    this.getJobs(1);

  }

  public get jobType(): typeof JobType {
    return JobType;
  }

  getClearFilterJobs(e) {
    this.filterData = e;
    this.getJobs(1);

  }

  selectRadioButtons(data){
    switch (data) {
      case 0:
        this.jobs = this.allJobs;
        break;
      case 1:
        this.jobs = this.allJobs.filter(x=> x.status == "ACTIVE");
        break;
      case 2:
        this.jobs = this.allJobs.filter(x=> x.pendingReview > 0);
        break;
    }

  }

  openModalNew(e) {
    this.new = e;

    // @ts-ignore
    $('#modalNews').modal('show');
  }

  search(data) {

    this.searchText = data;

    if (data == "") this.getJobs(1);
    // tslint:disable-next-line:max-line-length
    this.jobs = ((this.storedItems).filter((item: JobModel) =>
      (((item.employer.name).toLowerCase().trim()).indexOf(data.toLowerCase().trim()) > -1) ||
      (((item.title).toLowerCase().trim()).indexOf(data.toLowerCase().trim()) > -1)
    ));


  }
}
