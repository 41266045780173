import { JobCompensationModel } from ".";

export class JobCompensationsAndFeesModel {

  id: string;
  compensation: JobCompensationModel;
  fee: any;

constructor() {
  this.id = "";
  this.compensation = new JobCompensationModel();
  this.fee = {};
}
}
