import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class PopupService {

  Subject = new Subject<any>();
  OpenPopups: PopupModel[] = [];
  Popups: PopupModel[] = [];
  ClosedPopups: PopupModel[] = [];

  constructor() {
  }

  public registerPopup(popup: PopupModel): void {
    this.Popups.push(popup);
    this.ClosedPopups.push(popup);
    $('#' + popup.id).on('hidden.bs.modal', e => {
      this.closePopup(popup.id);
    });
  }


  public openPopup(popupId: string): void {
    let existingPopup = null;
    this.Popups.forEach(popup => {
      if (popup.id === popupId) {
        existingPopup = popup;
        existingPopup.currentState = PopupState.Open;
      }
    });
    if (existingPopup == null){
      return;
    }
    this.OpenPopups.push(existingPopup);
    $('#' + popupId).modal('show');
    this.deletePopupFromArray(this.ClosedPopups, popupId);
  }

  public closePopup(popupId: string): void {
    let existingPopup = null;
    this.Popups.forEach(popup => {
      if (popup.id === popupId) {
        existingPopup = popup;
        existingPopup.currentState = PopupState.Closed;
      }
    });
    if (existingPopup == null){
      return;
    }
    this.ClosedPopups.push(existingPopup);
    $('#' + popupId).modal('hide');
    this.deletePopupFromArray(this.OpenPopups, popupId);
  }

  public getSubjectAsObservable(): Observable<any> {
    return this.Subject.asObservable();
  }

  public nextToSubject(data: any): void {
    this.Subject.next(data);
  }

  private deletePopupFromArray(array: PopupModel[], popupId: string): void {
    // tslint:disable-next-line:only-arrow-functions typedef
    array = array.filter(function(obj) {
      return obj.id !== popupId;
    });
  }

}

export class PopupModel {
  id: string;
  currentState: PopupState;

  constructor(id: string) {
    this.id = id;
    this.currentState = PopupState.Closed;
  }
}

export enum PopupState {
  Open,
  Closed
}
