<div *ngIf="filterData" class=" mt-3 box">
  <div class="col-12 pr-3 pl-3 mt-2 mb-2">

      <span class="filters-text">{{'filters' | translate}}</span>

      <section class="mt-4">
        <div class="form-check mb-2" >
          <input type="radio" (change)="selectRB('')" class="form-check-input filled-in" name="one" id="filledInCheckbox" value="0" >
          <label class="form-check-label cb-text" for="filledInCheckbox">{{ 'allJobs' | translate }}</label>
        </div>

        <div class="form-check mb-2" >
          <input type="radio" (change)="selectRB('ACTIVE')" class="form-check-input filled-in" name="one" id="filledInCheckboxOne" value="1" checked>
          <label class="form-check-label cb-text" for="filledInCheckboxOne">{{ 'activeJobs' | translate }}</label>
        </div>

        <div class="form-check mb-2" >
          <input type="radio" (change)="selectRB('DRAFT')" class="form-check-input filled-in" name="one" id="filledInCheckboxTwo" value="2">
          <label class="form-check-label cb-text" for="filledInCheckboxTwo">{{ 'draftJobs' | translate }}</label>
        </div>

        <div class="form-check mb-4" >
          <input type="radio" (change)="selectRB('CLOSED')" class="form-check-input filled-in" name="one" id="filledInCheckboxThree" value="3">
          <label class="form-check-label cb-text" for="filledInCheckboxThree">{{ 'closedJobs' | translate }}</label>
        </div>
      </section>

    <div>
      <div class="filter-names mb-1 mt-3">
        {{ 'country' | translate }}
      </div>

        <tlb-dropdown [items]="countryOptions" [dropdownName]="countryLabel != '' ? countryLabel : 'allCountries'"
        (searchData)="selectCountry($event)"
        [style]="'background-color: white; border: 1px solid #EDECE7; height: 40px; border-radius: 4px;'">
      </tlb-dropdown>


    </div>

    <div class="mt-3">
      <div class="filter-names mb-1">
        {{ 'city' | translate }}
      </div>
      <tlb-multiselect-dropdown [alertText]="alertText" (clicked)="clickedCity()" (selectResult)="selectCity($event)" [isFilter]="false" [items]="cities"
        [dropdownName]="cityLabel != '' ? cityLabel : 'allCities'" [existItems]="selectedCities"
        [style]="'background-color: white; border: 1px solid #EDECE7; height: 40px; border-radius: 4px;'">
      </tlb-multiselect-dropdown>

    </div>

    <div class="mt-3">
      <div class="filter-names mb-1">
        {{ 'jobType' | translate }}
      </div>
      <tlb-multiselect-dropdown (selectResult)="selectType($event)" [isFilter]="false" [items]="jobTypeOptions"
        [dropdownName]="jobTypeLabel != '' ? jobTypeLabel : 'allTypes'" [defaultItemKey]="''"
        [existItems]="selectedJobTypes"
        [style]="'background-color: white; border: 1px solid #EDECE7; height: 40px; border-radius: 4px;'">
      </tlb-multiselect-dropdown>
    </div>

    <div class="mt-3">
      <div class="filter-names mb-1">
        {{ 'jobFunction' | translate }}
      </div>
      <tlb-multiselect-dropdown (selectResult)="selectFunction($event)" [isFilter]="false" [items]="jobFunctions"
        [dropdownName]="jobFunctionLabel != '' ? jobFunctionLabel : 'allFunctions'" [existItems]="selectedFunctions"
        [dropDownMenuWidth]="'17rem'"
        [style]="'background-color: white; border: 1px solid #EDECE7; height: 40px; border-radius: 4px;'">
      </tlb-multiselect-dropdown>
    </div>


    <div class="mt-3">
      <div class="filter-names mb-1">
        {{ 'industry' | translate }}
      </div>
      <tlb-multiselect-dropdown (selectResult)="selectIndustry($event)" [isFilter]="false" [items]="industries"
        [dropdownName]="industryLabel != '' ? industryLabel : 'allIndustries'" [existItems]="selectedIndustries"
        [dropDownMenuWidth]="'15rem'"
        [style]="'background-color: white; border: 1px solid #EDECE7; height: 40px; border-radius: 4px;'">
      </tlb-multiselect-dropdown>
    </div>

    <div class="mt-3 mr-3">
      <div class="row" style="display: flex;justify-content: end;">
        <button (click)="clearFilterEmit()" class="btn discard-btn mr-2">{{'discard' | translate}}</button>
        <button (click)="emitData()" class="btn apply-btn ">{{'apply' | translate}}</button>
       </div>
    </div>

  </div>
</div>
