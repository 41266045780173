<div class="modal fade"
     id="agreed-salary-amount-modal"
     role="dialog"
     tabindex="-1">
  <div class="modal-dialog"
       role="document">
    <div class="modal-content">
      <div
        class="card mt-2"
        style="border: none;">
        <div class="card-body">
          <div class="row"
               style="padding: 10px 18px;">
            <div class="col-12">

              <label class="form-label salary-label"
                     for="candidate">{{'agreedSalaryAmount' | translate}}</label>
              <input [(ngModel)]="salary"
                     class="form-control"
                     id="candidate"
                     min="0"
                     (keydown)="keyDownHandler($event)"
                     placeholder="{{'enterSalary' | translate}}"
                     style="background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px; width: 100%; margin-bottom: -1px;"
                     type="number">

              <button (click)="clickedHire()"
                      class="btn save-btn m-2"
                      style="float: right;"
                      type="button">{{
                'save' |
                  translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
