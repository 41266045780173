  <div class="container-fluid bg-gray main-container">
    <img src="assets/images/credit-card/vector.png" class="background-vector">
    <div class="row h-100">
      <div class="col-12 col-xl-10 text-center m-auto">
        <div class="container-fluid my-5 my-sm-0">
          <div class="row">
            <div class="col-12"><h1 class="font-weight-bold">Checkout</h1></div>
          </div>
          <div class="row mb-4">
            <div class="col-12 color-gray">
              <span>No contracts. No surprise fees.</span>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-10 col-xl-8 mx-auto">
              <div class="card">
                <div class="card-body">
                  <div class="container text-left">
                    <div class="row">
                      <div class="col-9 mx-auto mt-4">
                        <div class="row">
                          <div class="col-12">
                            <span class="color-gray-2">Payment method</span>
                          </div>
                          <div class="col-12 mt-md-2">
                            <img src="assets/images/credit-card/paypal.png" alt="Paypal Logo">
                            <img src="assets/images/credit-card/apple-pay.png" alt="Apple Pay Logo">
                            <img src="assets/images/credit-card/amazon.png" alt="Amazon Logo">
                            <img src="assets/images/credit-card/google-pay.png" alt="Google Pay Logo">
                          </div>
                          <div class="col-12 mt-md-3">
                            <input type="radio" name="payment-method" id="payment-method">
                            <label for="payment-method" class="mx-1 color-gray-2">Credit Card</label> <br class="d-block d-sm-none">
                            <img src="assets/images/credit-card/visa.png" alt="Visa Logo" class="mx-1">
                            <img src="assets/images/credit-card/masterCard.png" alt="Mastercard Logo" class="mx-1">
                            <img src="assets/images/credit-card/american-express.png" alt="American Express Logo" class="mx-1">
                          </div>
                          <div class="col-12 mt-md-3">
                            <label for="card-number" class="color-gray-2">Card number</label>

                          </div>
                          <div class="col-12 position-relative">
                            <input type="text" id="card-number" name="card-number" class="form-control" placeholder="1234 1234 1234 1234">
                            <div class="position-absolute right-0 d-none d-sm-block">
                              <img src="assets/images/credit-card/visa-bank.png" alt="Visa Logo" class="mx-1">
                              <img src="assets/images/credit-card/mastercard-bank.png" alt="Mastercard Logo" class="mx-1">
                              <img src="assets/images/credit-card/american-express.png" alt="American Express Logo" class="mx-1">
                              <img src="assets/images/credit-card/discover-bank.png" alt="Discover Logo" class="mx-1">
                            </div>
                          </div>
                          <div class="col-12 col-md-6 mt-3">
                            <label for="expiry" class="color-gray-2">Expiry</label>
                            <input type="text" id="expiry" class="form-control" placeholder="MM / YY">
                          </div>
                          <div class="col-12 col-md-6 mt-3">
                            <label for="cvc" class="color-gray-2">CVC</label>
                            <input type="text" id="cvc" class="form-control" placeholder="CVC">
                          </div>
                          <div class="col-12 col-md-6 mt-3">
                            <label for="country" class="color-gray-2">Country</label>
                            <select name="country" id="country"  class="form-control">
                              <option value="United States">United States</option>
                            </select>
                          </div>
                          <div class="col-12 col-md-6 mt-3">
                            <label for="postal-code" class="color-gray-2">Postal Code</label>
                            <input type="text" id="postal-code" class="form-control" placeholder="90210">
                          </div>
                        </div>
                        <div class="row my-5">
                          <div class="col-12 text-right">
                            <button class="btn btn-back mr-md-3 w-100 w-md-auto" id="back">Back</button>
                            <button class="btn btn-confirm-payment w-100 w-md-auto mt-3 mt-md-0" id="confirm-payment">Confirm Payment 600 $</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
