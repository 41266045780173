import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CreateAvatar, RequestPayload } from 'src/app/shared/classes';
import { JobModel, NotificationsModel } from 'src/app/shared/models';
import { ActivitiesService, JobService, TranslatePipe } from 'src/app/shared/services';

@Component({
  selector: 'app-employer-dashboard-activity',
  templateUrl: './employer-dashboard-activity.component.html',
  styleUrls: ['./employer-dashboard-activity.component.scss']
})

export class EmployerDashboardActivityComponent implements OnInit {

  activities: any[] = [
  ];
  prevDisabled: boolean = true;
  nextDisabled: boolean = false;
  totalCount: number = 0;
  requestPayload: RequestPayload;
  page: number = 1;
  createAvatar: CreateAvatar;
  selectedContent: any = null;

  constructor(private activityService: ActivitiesService, private translate: TranslatePipe, private router: Router,
    private jobService: JobService) {
    this.requestPayload = new RequestPayload();
    this.requestPayload.limit = 4;
    this.createAvatar = new CreateAvatar();
   }

  ngOnInit() {
      //     this.activities.forEach(f => {
      //   f.imageText = this.createAvatar.create(this.translate.transform(f.type));
      //   f.imageColor = this.createAvatar.color();
      // });
    this.getActivities(1);
  }

  activityNavigate() {
    this.router.navigate(['/activity']);
  }

  getActivities(pageNo) {
    this.page = pageNo;
    this.activityService.ServerGetWithText(this.requestPayload.payloadURL(pageNo)).subscribe(x => {
      this.activities = x.content;
      this.activities.forEach(f => {
        f.imageText = this.createAvatar.create(this.translate.transform(f.type));
        f.imageColor = this.createAvatar.color();
      });

      this.totalCount = x.totalElements;
    });
  }


  selectedActivity(activity) {

    switch (activity.domainType) {
      case 'JOB':
        this.jobService.ServerGetWithText("/" + activity.contentId).subscribe(j=>{

        this.selectedContent = j;
        // @ts-ignore
        $('#jobDetailFromNotifications').modal('show');
        });

        break;
      case 'APPLICATION':
        this.router.navigate(["/dashboard/applications/" + activity.contentId]);
        break;
      case 'EARNING':
        this.router.navigate(["/dashboard/payments/"]);
        break;
        case 'EMPLOYER ':

          break;
    }

  }

  OpenModalViewSalary(job: JobModel) {
    this.selectedContent = job;
    // @ts-ignore
    $('#viewSalaryModal').modal('show');
    // @ts-ignore
    $('#jobDetailFromNotification').modal('hide');
  }

  submitCandidate(job: JobModel) {
    this.selectedContent = job;

    // @ts-ignore
    $('#submitCandidateModal').modal('show');
  }

}
