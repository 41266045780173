import { Component, OnInit } from '@angular/core';
import { CandidateService, EmployerService, GlobalSearchService, JobService, RecruiterService, UserService } from '../../services';
import { SearchModel } from './search.model';
import { GlobalSearchTypes } from '../../enums';

const Icons = {
  USER: "fas fa-users",
  RECRUITER: "fas fa-user-tie",
  EMPLOYER: "fas fa-user-tie",
  CANDIDATE: "far fa-id-badge",
  JOB: "fas fa-briefcase",
  APPLICATION: "fas fa-eye"
};

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})

export class GlobalSearchComponent implements OnInit {

  searchDatas: SearchModel[];
  searchModel: SearchModel;
  isSearchClick: boolean;
  isLessThanMinimum: boolean;
  searchKey: string;
  categories: any[] = [];
  selectedCategories: any[] = [];
  results: SearchModel[] = [];
  job: any = null;
  candidate: any = null;
  user: any = null;
  employer: any = null;
  recruiter: any = null;
  jobSentModal;

  constructor(private globalSearchService: GlobalSearchService, private candidateService: CandidateService, private jobService: JobService,
    private employerService: EmployerService, private recruiterService: RecruiterService, private userService: UserService) {
    this.searchModel = new SearchModel();
  }

  ngOnInit(): void {
    // @ts-ignore
    $('#searchModal').on('hidden.bs.modal', () => {
      // @ts-ignore
      $('#search-input').val("");
      // @ts-ignore
      this.searchDatas = [];
      this.isSearchClick = false;
    });
  }
  search() {
    this.searchDatas = [];
    this.isSearchClick = true;
    this.isLessThanMinimum = false;
    if (this.searchModel.value.length < 3) {
      this.isLessThanMinimum = true;
      //this.closeSearchModal();
      return;
    }

    if (this.searchModel.value === '') {
      return false;
    }

    this.searchKey = this.searchModel.value;

    this.globalSearchService.ServerGetWithText('?q=' + this.searchKey + '')
      .subscribe((res: any) => {
        this.searchDatas = res.content;
        this.results = res.content;
        this.pushCategories();
      });
  }

  pushCategories() {
    this.categories = [];
    this.selectedCategories = [];
    this.searchDatas.forEach(x => {
      if (!this.categories.some(y => y.name == x.type)) {
        this.categories.push({ name: x.type, icon: Icons[x.type] });
      }
    });
  }

  selectCategory(cat) {
    if (this.selectedCategories.length == 0) {
      this.selectedCategories.push(cat);
    }

    else if (this.selectedCategories.some(x => x == cat)) {

      const index: number = this.selectedCategories.indexOf(cat);
      if (index !== -1) {
        this.selectedCategories.splice(index, 1);
      }
    }
    else {
      this.selectedCategories.push(cat);
    }

    this.filterResult();
  }

  filterResult() {
    this.results = [];
    if (this.selectedCategories.length == 0) {
      this.results = this.searchDatas;
    }

    else {
      this.selectedCategories.forEach(x => {
        this.searchDatas.filter(f => f.type == x.name).forEach(res => {
          this.results.push(res);
        });
      });
    }

  }

  closeSearchModal() {
    // @ts-ignore
    $('#searchModal').modal('hide');
  }

  openDetailModal(data) {
    this.closeSearchModal();
    switch (data.type) {
      case GlobalSearchTypes.JOB:
       this.jobService.ServerGetWithText('/' + data.id).subscribe(a => {
        this.job = a;
        // @ts-ignore
        $('#jobDetailFromGlobalSearch').modal('show');
      });

        break;
      case GlobalSearchTypes.CANDIDATE:
        this.candidateService.ServerGetWithText('/' + data.id).subscribe(a => {
          this.candidate = a;
          // @ts-ignore
          $('#editCandidateModalFromGlobalSearch').modal('show');
        });

        break;
      case GlobalSearchTypes.EMPLOYER:
        this.employerService.ServerGetWithText('/' + data.id).subscribe(a => {
          this.employer = a;
          // @ts-ignore
          $('#adminEmployerModal').modal('show');
        });

        break;
      case GlobalSearchTypes.APPLICATION:


        break;
      case GlobalSearchTypes.USER:
        this.userService.ServerGetWithText('/' + data.id).subscribe(a => {
          this.user = a;
          // @ts-ignore
          $('#userModalFromGeneralSearch').modal('show');
        });

        break;
      case GlobalSearchTypes.RECRUITER:
        this.recruiterService.ServerGetWithText('/' + data.id).subscribe(a => {
          this.recruiter = a;
          // @ts-ignore
          $('#adminRecruiterDetailModalFromGlobalSearch').modal('show');
        });

        break;
      default:
        break;
      }


  }
}
