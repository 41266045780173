import { Component, EventEmitter, Input, OnInit, Output }          from '@angular/core';
import { FormControl, FormGroup, Validators }                      from '@angular/forms';
import { NgxSpinnerService }                                       from 'ngx-spinner';
import { FormType, RecruiterUserRole, UserRole }                   from 'src/app/shared/enums';
import { DropdownOptionsModel, TlbFormModel, UserPostModel }       from 'src/app/shared/models';
import { NotificationService, server, TranslatePipe, UserService } from 'src/app/shared/services';

@Component({
             selector: 'app-user-modal',
             templateUrl: './user-modal.component.html',
             styleUrls: ['./user-modal.component.scss']
           })
export class UserModalComponent implements OnInit {

  @Output() postedUser: EventEmitter<any> = new EventEmitter();
  formModel: TlbFormModel[] = [];
  formGroup: FormGroup;
  roleOptions: DropdownOptionsModel[] = [];
  user: UserPostModel;
  @Input() modalId: string = 'userModal';
  @Input() isEmployer: boolean = true;
  @Input() selectedUser: any = null;

  constructor(private spinner: NgxSpinnerService, private notification: NotificationService,
              private translate: TranslatePipe, private userService: UserService) {
  }

  get form() {
    return this.formGroup.controls;
  }

  ngOnInit(): void {

    this.user = new UserPostModel();
    this.createForm();
  }

  ngOnChanges() {
    this.user = new UserPostModel();
    this.createForm();
  }

  createForm() {

    let s = this.selectedUser ? this.selectedUser : null;
    this.roleOptions = this.isEmployer ? Object.keys(UserRole)
                                               .map(c => ({ value: c, label: this.translate.transform(c) })) :
      Object.keys(RecruiterUserRole)
            .map(c => ({ value: c, label: this.translate.transform(c) }));

    this.formModel = [{
      class: 'col-12', components: [
        {
          label: 'firstName', control: 'firstName', required: true, type: FormType.text,
          placeHolderStyle: 'color: #323C47; font-style: normal; font-weight: 500; font-size: 12px; font-family: Helvetica Neue; line-height: 150%; ',
          style: 'border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;'
        },
        {
          label: 'lastName', control: 'lastName', required: true, type: FormType.text,
          placeHolderStyle: 'color: #323C47; font-style: normal; font-weight: 500; font-size: 12px; font-family: Helvetica Neue;line-height: 150%;',
          style: 'border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;'
        },
        {
          label: 'email', control: 'email', required: true, type: FormType.email,
          placeHolderStyle: 'color: #323C47; font-style: normal; font-weight: 500; font-size: 12px; font-family: Helvetica Neue;line-height: 150%;',
          style: 'border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;'
        },
        {
          label: 'role', control: 'role', options: this.roleOptions,
          placeHolderStyle: 'color: #323C47; font-style: normal; font-weight: 500; font-size: 12px; font-family: Helvetica Neue;line-height: 150%;',
          style: 'border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
          required: true, type: FormType.dropDown
        }
      ]
    }];

    this.formGroup = new FormGroup({
                                     firstName: new FormControl(s ? s.firstName : '', Validators.required),
                                     role: new FormControl(s ? s.role : '', Validators.required),
                                     lastName: new FormControl(s ? s.lastName : '', Validators.required),
                                     email: new FormControl(s ? s.email : '', Validators.required)
                                   });
  }

  postUser() {
    this.user.preferredLanguage = 'en';
    this.user.firstName = this.form.firstName.value;
    this.user.lastName = this.form.lastName.value;
    this.user.email = this.form.email.value;
    this.user.role = this.form.role.value;

    if (this.selectedUser) {
      this.user.id = this.selectedUser.id;
      this.userService.ServerPut(server.user + '/' + this.selectedUser.id, this.user)
          .subscribe(x => {
            this.notification.success(this.translate.transform('updateSuccessful'));
            this.user = new UserPostModel();
            this.createForm();
            // @ts-ignore
            $('#' + this.modalId)
              .modal('hide');
          });
    } else {
      this.userService.ServerPost(this.user)
          .subscribe(x => {
                       this.user = new UserPostModel();
                       this.createForm();
                       this.notification.success(this.translate.transform('newUserSuccessfullyAdded'));
                       this.postedUser.emit();

                       // @ts-ignore
                       $('#' + this.modalId)
                         .modal('hide');

                     }, err => {
                       if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                         if (err.message == 'RESOURCE_ALREADY_EXISTS') {
                           this.notification.error(
                             this.translate.transform('theEmailYouHaveProvidedIsAlreadyRegistered'));
                         } else {
                           this.notification.error(err.message);
                         }

                         this.spinner.hide();
                       }
                     }, () => {
                       this.spinner.hide();
                     }
          );
    }

  }

}
