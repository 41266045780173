import { GeneralIdNameModel } from "../general";
import { PhoneNumberModel } from "../parameter";
import { CandidatePostModel } from "./candidate-post.model";

export class CandidateModel extends CandidatePostModel{
  // id: string;
  createdAt: string;
  createdBy: GeneralIdNameModel;
  updatedAt: string;
  updatedBy: GeneralIdNameModel;
  fullName: string;
  imageColor: string;
  imageText: string;
}
