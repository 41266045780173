<div>
  <span class="ml-5 mt-2"><img src="./../../../../assets/images/talentbase/filter-button.png">
    <span style="font-weight: 600; font-size: 16px;"> {{ 'filters' | translate }}</span></span>

  <div class="col-12 pb-0 pt-4">

      <label class="label-style mt-1 pl-4">{{ 'country' | translate }}</label>
      <div class="dropdown-item color-dropdown-item" id="country">
        <tlb-dropdown [items]="countryOptions" [dropdownName]="countryLabel != '' ? countryLabel : 'country'"
          (searchData)="selectCountry($event)"
          [style]="'text-align: left; border: 1px solid #DCDCDC; box-sizing: border-box; box-shadow: 0px 2px 4px rgb(0 0 0 / 10%); border-radius: 7px; height: 40px;'"
          [textStyle]="'font-family: Roboto; font-style: normal; font-weight: 500; font-size: 15px; line-height: 16px; letter-spacing: 0.1px; color: #252B42 !important;'">
        </tlb-dropdown>
      </div>

      <label class="label-style mt-3 pl-4">{{ 'city' | translate }}</label>
      <div class="dropdown-item color-dropdown-item" id="city">
        <tlb-multiselect-dropdown [alertText]="alertText" (clicked)="clickedCity()" (selectResult)="selectCity($event)" [isFilter]="false" [items]="cities"
          [dropdownName]="cityLabel != '' ? cityLabel : 'city'" [existItems]="selectedCities"
          [style]="'text-align: left; border: 1px solid #DCDCDC; box-sizing: border-box; box-shadow: 0px 2px 4px rgb(0 0 0 / 10%); border-radius: 7px; height: 40px;'"
          [textStyle]="'font-family: Roboto; font-style: normal; font-weight: 500; font-size: 15px; line-height: 16px; letter-spacing: 0.1px; color: #252B42 !important;'">
        </tlb-multiselect-dropdown>
      </div>

      <label class="label-style mt-3 pl-4">{{ 'jobType' | translate }}</label>
      <div class="dropdown-item color-dropdown-item" id="type">
        <tlb-multiselect-dropdown (selectResult)="selectType($event)" [isFilter]="false"
          [items]="jobTypeOptions" [dropdownName]="jobTypeLabel != '' ? jobTypeLabel : 'jobType'"
          [defaultItemKey]="''" [existItems]="selectedJobTypes"
          [style]="'text-align: left; border: 1px solid #DCDCDC; box-sizing: border-box; box-shadow: 0px 2px 4px rgb(0 0 0 / 10%); border-radius: 7px; height: 40px;'"
          [textStyle]="'font-family: Roboto; font-style: normal; font-weight: 500; font-size: 15px; line-height: 16px; letter-spacing: 0.1px; color: #252B42 !important;'">
        </tlb-multiselect-dropdown>
      </div>

      <label class="label-style mt-3 pl-4">{{ 'jobFunction' | translate }}</label>
      <div class="dropdown-item color-dropdown-item" id="type">
        <tlb-multiselect-dropdown (selectResult)="selectFunction($event)" [isFilter]="false"
          [items]="jobFunctions" [dropdownName]="jobFunctionLabel != '' ? jobFunctionLabel : 'jobFunction'"
          [existItems]="selectedFunctions" [dropDownMenuWidth]="'17rem'"
          [style]="'text-align: left; border: 1px solid #DCDCDC; box-sizing: border-box; box-shadow: 0px 2px 4px rgb(0 0 0 / 10%); border-radius: 7px; height: 40px;'"
          [textStyle]="'font-family: Roboto; font-style: normal; font-weight: 500; font-size: 15px; line-height: 16px; letter-spacing: 0.1px; color: #252B42 !important;'">
        </tlb-multiselect-dropdown>
      </div>

      <label class="label-style mt-3 pl-4">{{ 'industry' | translate }}</label>
      <div class="dropdown-item color-dropdown-item" id="type">
        <tlb-multiselect-dropdown (selectResult)="selectIndustry($event)" [isFilter]="false"
          [items]="industries" [dropdownName]="industryLabel != '' ? industryLabel : 'industry'"
          [existItems]="selectedIndustries" [dropDownMenuWidth]="'15rem'"
          [style]="'text-align: left; border: 1px solid #DCDCDC; box-sizing: border-box; box-shadow: 0px 2px 4px rgb(0 0 0 / 10%); border-radius: 7px; height: 40px;'"
          [textStyle]="'font-family: Roboto; font-style: normal; font-weight: 500; font-size: 15px; line-height: 16px; letter-spacing: 0.1px; color: #252B42 !important;'">
        </tlb-multiselect-dropdown>
      </div>


      <div class="row mr-4 mt-3" style="justify-content: end;">

        <div class="mt-2 mb-2 mr-1">
          <button (click)="clearFilterEmit()" class="btn clear-filter-btn" type="button">{{'discard' |
            translate}}</button>
        </div>
        <div class="mt-2">
          <button (click)="emitData()" class="btn apply-filter-btn" type="button">{{'apply' |
            translate}}</button>
        </div>
      </div>


    </div>
</div>
