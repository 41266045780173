<!-- Modal -->
<div class="modal fade" id="review-popup" style="background-color: #000000b3;">
  <div class="modal-dialog ">
    <div class="modal-content">
      <div class="modal-header">
        <div class="container">
          <div class="col-12 p-0">
            <h5>
              {{'userreviews'|translate}}
            </h5>
          </div>
        </div>

      </div>
      <div class="modal-body py-0">
        <div *ngIf="reviews && reviews.length > 0" class="container">
          <div class="row my-3 border-bottom" *ngFor="let review of reviews">
            <div class="col-12 pb-3">
              <div class="row">
                <div class="col-4">
                  <b>{{review.fullName}}</b>
                </div>
                <div class="col-3 col-xl-2 ml-sm-auto mr-5 rating-col">
                  <app-star-rating [ratingScore]="review.rating"></app-star-rating>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <span class="profile-type">
                    {{review.role | translate}}
                  </span>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12">
                  <p class="m-0">
                    {{review.comment}}
                  </p>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12">
                  <span class="review-date">
                    {{'on'|translate}} {{review.date}}
                  </span>
                </div>
              </div>
            </div>
          </div>



        </div>
        <tlb-empty-page *ngIf="!reviews || reviews.length == 0" [warningLabel]="'No reviews yet.'"></tlb-empty-page>
      </div>
      <div class="modal-footer py-4">
<div class="container">
  <div class="row">
    <div class="col-12 col-sm-6 col-lg-3  ml-auto">
      <button class="form-control close-button" data-dismiss="modal">
        {{'close'|translate}}
      </button>
    </div>
  </div>
</div>
      </div>
    </div>
  </div>
</div>
