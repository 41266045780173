import { Component, Input, OnInit } from '@angular/core';
import {Employer} from '../admin-all-employers/employer';

@Component({
  selector: 'app-admin-employer-modal',
  templateUrl: './admin-employer-modal.component.html',
  styleUrls: ['./admin-employer-modal.component.scss']
})
export class AdminEmployerModalComponent implements OnInit {

  selectedEmployer: any = null;
  @Input() employer: Employer;

  constructor() {
  }

  ngOnInit() {

  }

  openMessagesPopup() {
    this.selectedEmployer = this.employer;

    // @ts-ignore
    $('#adminEmployerModal').modal('hide');
       // @ts-ignore
       $('#messagesForm').modal('show');
     }

     openDeactivePopup() {
      // @ts-ignore
      $('#adminEmployerModal').modal('hide');
         // @ts-ignore
         $('#deactivatePopup').modal('show');
       }


}
