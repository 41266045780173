<div class="container-fluid">
  <div class="card" style="border: 1px solid #C8D4DF;">
    <div class="card-body">
      <div class="header mb-2">{{'activity' | translate}}</div>
      <tlb-empty-page *ngIf="!activities || activities.length == 0" [small]="true" [warningLabel]="'noActivityYet'">
      </tlb-empty-page>
      <div *ngIf="activities && activities.length > 0">
        <div *ngFor="let activity of activities" class="py-3 overflow-auto"
          style="border-top:1px solid rgba(180, 182, 197, 0.38);">
          <div class="activity-row" (click)="selectedActivity(activity)">

            <div>
              <div class="activity-row">
                <div>
                  <div [style.background-color]="activity.imageColor" id="preimage"><span
                      style="color: white;">{{activity.imageText}}</span></div>
                </div>
                <div class="ml-2">
                  <div class="name-text">{{activity.content}}</div>
                  <div class="type-text">{{activity.type | translate}} </div>
                </div>
              </div>
            </div>

            <div class="ml-auto star-text mt-2">
              {{activity.createdAt | date:'shortTime'}}

            </div>
          </div>
        </div>
        <hr>

        <div class="row">
          <div class="btn-view-all-row  col-6 ">

            <button (click)="activityNavigate()" class="btn view-all-btn" type="button">{{'viewAll' |
              translate}}</button>

          </div>

          <div *ngIf="totalCount > 4" class="view-text col-6 ">


            <div class="row" style="    float: right;">
              <button (click)="getActivities(page - 1)" [disabled]="page == 1" class="arrow-buttons mr-3">
                <i class="fas fa-chevron-left"></i>
              </button>
              <button (click)="getActivities(page + 1)" [disabled]="page * 4 >= totalCount" class="arrow-buttons mr-2">
                <i class="fas fa-chevron-right"></i>
              </button>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>


<app-dashboard-view-salary-modal [selectedJobs]="selectedContent"></app-dashboard-view-salary-modal>
<app-view-salary-modal [selectedJobs]="selectedContent"></app-view-salary-modal>
<app-submit-candidate-modal [dropName]="''" [job]="selectedContent"></app-submit-candidate-modal>


<app-employer-job-detail-popup
(submitCandidateModalEmitter)="submitCandidate(selectedContent)" (viewSalaryEmitter)="OpenModalViewSalary(selectedContent)"
  [job]="selectedContent" [modalId]="'jobDetailFromNotifications'"></app-employer-job-detail-popup>
