<!-- Modal -->
<div class="modal fade bd-example-modal-xl" id="addEditApplicationNote" >
  <div class="modal-dialog modal-md modal-dialog-centered">
    <div class="modal-content">

        <tlb-form *ngIf="formModel && formModel.length > 0 && formGroup" [forms]="formModel" [formGroup]="formGroup"
        ></tlb-form>

      <div class="modal-footer">
        <button type="button" class="btn btnSave" (click)="updateOrSave()" [disabled]="formGroup && formGroup.invalid" >{{'save' | translate}}</button>
        <button type="button" class="btn btnCancel" data-dismiss="modal" >{{'cancel' | translate}}</button>

      </div>
    </div>
  </div>
</div>

