<!-- Modal -->
<div class="modal fade" [id]="modalId" >
  <div class="modal-dialog">
    <div class="modal-content">


        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <i class="float-right mr-3 mt-3 fas fa-times" style="color: black;"></i>
        </button>

      <div class="content-div">
        <div class="header" id="exampleModalLongTitle">{{'createUser' | translate}}</div>
        <tlb-form *ngIf="formModel && formModel.length > 0 && formGroup" [forms]="formModel" [formGroup]="formGroup"></tlb-form>
          <div class="ml-auto">
            <button type="button" class="btn btnSavePost float-right mr-3" (click)="postUser()" >{{'create' | translate}}</button>
          </div>

      </div>

    </div>
  </div>
</div>

