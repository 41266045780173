import { Component, EventEmitter, Input, OnInit, Output }                                                  from '@angular/core';
import { JobProfileModel }                                                                                 from 'src/app/shared/models';
import { EmployerMeService, JobSegmentTagService, JobsProfileService, NotificationService, TranslatePipe } from 'src/app/shared/services';

@Component({
             selector: 'app-saved-job-profiles',
             templateUrl: './saved-job-profiles.component.html',
             styleUrls: ['./saved-job-profiles.component.scss']
           })
export class SavedJobProfilesComponent implements OnInit {

  jobs: JobProfileModel[] = [];
  @Input() indFilter = '';
  @Input() fromInd: boolean = false;
  employerId: string = '';
  @Input() type = 'EMPLOYER_SPECIFIC';

  @Output() useJobProfile = new EventEmitter();

  constructor(private jobProfileService: JobsProfileService, private employerMeService: EmployerMeService,
              private jobSegmentTagService: JobSegmentTagService, private notification: NotificationService,
              private translate: TranslatePipe) {
  }

  ngOnInit() {
    this.getEmployerId();
  }

  ngOnChanges() {
    this.getEmployerId();
  }

  getEmployerId() {
    this.employerMeService.ServerGet()
        .subscribe(x => {
          this.employerId = x.id;
          this.getJobs();
        });
  }

  getJobs() {
    this.jobProfileService.ServerGetWithText(this.requestFilter())
        .subscribe(x => {

          this.jobs = this.fromInd ? x.content.filter(
            f => !f.employer) as JobProfileModel[] : x.content as JobProfileModel[];
          console.log(x);
          this.jobSegmentTagService.ServerGet()
              .subscribe(s => {
                this.jobs.forEach(f => {
                  f.tags = s.content?.filter(t => t.jobSegment.id == f.jobSegment?.id);
                });
              });
        });
  }

  requestFilter() {
    return '/?filter=type::' + this.type + '&filter=industryId**' + this.indFilter
      + '&filter=employerId::' + this.employerId;
  }

  useProfile(job) {
    this.jobProfileService.createJobFromJobProfile(job.id)
        .subscribe(x => {
          let job = x as any;
          this.notification.success(this.translate.transform('theJobSuccessfullyCreated'));
          this.useJobProfile.emit(job.id);
        }, error => {
          this.notification.error(this.translate.transform(error.error.code));
        });
  }

}
