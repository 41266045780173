export class RecruiterCountsModel {

  totalRecruiters: number;
  totalRecruitersSinceLastMonth: number;

  constructor() {
  this.totalRecruiters = 0;
  this.totalRecruitersSinceLastMonth = 0;
  }
  }
