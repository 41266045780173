import { GeneralIdModel } from "../parameter";
import { EmployerAddressModel } from "./employer-address.model";
import { EmployerContactPersonModel } from "./employer-contact-person.model";

export class EmployerSignUpModel {


  companyName: string;
  contactPerson: EmployerContactPersonModel;

  companySize: string; //companySizeOfEmployees
  website: string; //websiteURL

  industry: GeneralIdModel;  //industry
  about: string;  //aboutCompany
  address: EmployerAddressModel;

  linkedinAccount: string; //linkedinProfileLink
  facebookAccount: string; //facebookProfileLink
  twitterAccount: string;  //twitterProfileLink
  instagramAccount: string;  //googleplusProfileLink
  atsAdditional: string; //haveATS

  constructor() {
    this.companyName = '';
    this.contactPerson = new EmployerContactPersonModel();
    this.companySize = '';
    this.website = '';
    this.industry = new GeneralIdModel();
    this.about = '';
    this.address = new EmployerAddressModel();
    this.linkedinAccount = '';
    this.facebookAccount = '';
    this.twitterAccount = '';
    this.instagramAccount = '';
    this.atsAdditional = '';
  }
}
