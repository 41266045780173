export class JobFilterDataModel {

  jobTitle: string;
  country: string;
  city: string;
  jobType: string;
  jobFunction: string;
  industry: string;
  referralReward: string;
  status: string;
  freeText: string;

 constructor() {
   this.jobTitle = "";
   this.country = "";
   this.city = "";
   this.jobType = "";
   this.jobFunction = "";
   this.industry = "";
   this.referralReward = "";
   this.status = "";
   this.freeText = "";
 }
 }
