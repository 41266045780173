import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-dashboard-view-salary-modal',
  templateUrl: './dashboard-view-salary-modal.component.html',
  styleUrls: ['./dashboard-view-salary-modal.component.scss']
})
export class DashboardViewSalaryModalComponent implements OnInit {
  @Input() selectedJobs;
@Input() modalId: string = "viewSalaryModals"
  constructor() {
  }

  ngOnInit(): void {
  }

}
