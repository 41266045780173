<div class="container-fluid">
  <div class="card mt-4">
    <div class="card-body">
      <div class="header mb-2">{{'candidates' | translate}}</div>
      <tlb-empty-page *ngIf="!candidates || candidates.length == 0" [warningLabel]="'noCandidateYet'" [small]="true">
      </tlb-empty-page>
      <div *ngIf="candidates && candidates.length > 0">
        <div *ngFor="let candidate of candidates" style="border-top:1px solid rgba(180, 182, 197, 0.38);">
          <div class="py-2 candidate-row overflow-auto">

            <div class="candidate-row">
              <div [style.background-color]="candidate.imageColor" id="preimage"><span
                  style="color: white;">{{candidate.imageText}}</span></div>
              <div class="ml-2">
                <div class="name-text">{{candidate.fullName}}</div>
                <div class="type-text">{{candidate.email}} </div>
              </div>
            </div>

            <div class="ml-auto star-text">
              {{candidate.createdAt | date:'shortTime'}}
            </div>
          </div>
        </div>

        <hr>



        <!-- <div class="row">
          <div class="btn-view-all-row ml-3">

            <button (click)="candidateNavigate()" class="btn view-all-btn" type="button">{{'viewAll' |
              translate}}</button>

          </div>

          <div *ngIf="totalCount > 4" class="view-text m-auto">


            <div class="row">
              <button (click)="getCandidates(page - 1)" [disabled]="page == 1" class="arrow-buttons mr-3">
                <i class="fas fa-chevron-left"></i>
              </button>
              <button (click)="getCandidates(page + 1)" [disabled]="page * 4 >= totalCount" class="arrow-buttons">
                <i class="fas fa-chevron-right"></i>
              </button>

            </div>
          </div>
        </div> -->

        <div class="row">
          <div class="btn-view-all-row  col-6 ">

            <button (click)="candidateNavigate()" class="btn view-all-btn" type="button">{{'viewAll' |
              translate}}</button>

          </div>

          <div *ngIf="totalCount > 4" class="view-text col-6 ">


            <div class="row" style="    float: right;">
              <button (click)="getCandidates(page - 1)" [disabled]="page == 1" class="arrow-buttons mr-3">
                <i class="fas fa-chevron-left"></i>
              </button>
              <button (click)="getCandidates(page + 1)" [disabled]="page * 4 >= totalCount" class="arrow-buttons mr-2">
                <i class="fas fa-chevron-right"></i>
              </button>

            </div>
          </div>
        </div>

        <hr>


        <div style="float: right;">
          <button (click)="newCandidate()"
          class="btn btn-outline-dark explore-div"
          type="button"><i class="fas fa-plus"></i> {{ 'newCandidate' | translate }}</button>
        </div>


      </div>


    </div>

  </div>

</div>
