import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../auth';
import { server } from '../server';
import { catchError, finalize, map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class JobsProfileService extends ApiService {

  constructor(public http: HttpClient, public spinner: NgxSpinnerService, public translate: TranslatePipe, public notification: NotificationService) {
    super(http, server.jobProfiles, spinner, translate, notification)
  }

  createJobFromJobProfile(jobId){
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.post(server.jobs + server.jobProfileForCreateJob + '/' + jobId,null, options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        }));
  }
}
