export class RecruiterExperienceLevelModel {

  permanent: string;
  temporary: string;

  constructor() {
  this.permanent = "";
  this.temporary = "";
  }
  }
