import { PhoneNumberModel } from "../parameter";
import { RecruiterPlacementCountsModel } from "./recruiter-placement-counts.model";

export class RecruiterSignUpModel {
  type:string; // INDIVIDUAL, RECRUITMENT_FIRM, OTHER

  primaryContactNumber:PhoneNumberModel;
  secondaryContactNumber:PhoneNumberModel;
  talentbaseReferenceType: string;     // whatBringsYouToFlybx:number;
  individualPracticeTime: string;   // howLongHaveYouRecruited:number;
  requisitionTypes: string[];    // whatTypeOfRecruitingAndStuffingServicesAreOfferedBy:number;      // typeOfJobRequisitions:number;
  paymentReferenceType: string;  // paymentPreferences:number;
  functionalRoles: any[];     // specialization:boolean[];
  industries: any[];     // industrySpecialization:boolean[];

  //for other
  customType: string;


  //for recruitment firm
  companyName: string; //recruimentFirmText
  positionAtCompany: string;    // roleAtCompany:number;
  website: string;       // websiteAddress:string;
  linkedinAccount: string;      // linkedinCompanyPage:string;
  recruiterCount: string;    // numberOfRecruiters:number;
  firmPracticeTime: string;    // howLongHasCompanyBeenInStaffingAndRecruitingBussiness:number;
  jobMarkets: any[];

  placementCounts: any;

constructor() {
  this.primaryContactNumber = new PhoneNumberModel();
  this.secondaryContactNumber = new PhoneNumberModel();
  this.talentbaseReferenceType = '';
  this.individualPracticeTime = '';
  this.requisitionTypes = [];
  this.paymentReferenceType = '';
  this.functionalRoles = [];
  this.industries = [];

  this.customType = '';

  this.companyName = '';
  this.positionAtCompany = '';
  this.website = '';
  this.linkedinAccount = '';
  this.recruiterCount = '';
  this.firmPracticeTime = '';
  this.jobMarkets = [];

  this.placementCounts = null;
}
}
