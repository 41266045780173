import { Directive ,  ElementRef, HostListener } from '@angular/core';
import { TitleCasePipe } from "@angular/common";


@Directive({
  selector: '[appTransformCapitalize]'
})
export class TransformCapitalizeDirective {


  constructor(private elementRef: ElementRef, public titleCases: TitleCasePipe) { }
  @HostListener('keyup') titleCaseOnKeyup(){
    const value = this.elementRef.nativeElement.value;
    const titleCaseStr = this.titleCases.transform(value);
    this.elementRef.nativeElement.value = titleCaseStr;
  }


}
