<!-- Modal -->
<div class="modal fade" id="adminEmployerDetailModal">
  <div class="modal-dialog modal-dialog-centered " >
    <div class="modal-content" >
      <!-- <div class="modal-header">
      </div> -->
      <div class="modal-body py-0" style="background-color: #F5F6F8;">
        <div class="container" >
          <div class="row" >
            <div class="col-12 pr-1 pl-0">
              <div class="card border-0">
                <div  *ngIf="employer && employer.id != ''" class="card-body" style="background-color: #F5F6F8;">
                  <div class="card mb-3" >
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12 col-xl-1 px-0 ml-5">
                          <div class="row h-100">
                            <div class="row">
                              <div class="col-12">
                                <div id="preimage">
                                  <span style="color: white;">{{employer.createdBy?.name.charAt(0)}}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-xl-4 p-0">
                          <div class="row h-100">

                            <div class="pl-4">
                              <p class="user-fullname pt-3">
                                {{employer?.createdBy?.name}}

                                <span class="pl-2">
                                  <button class="btn btn-control deactive-button" *ngIf="employer.active" (click)="openDeactivePopup()">
                                    <b>{{'deactive'|translate}}</b>
                                  </button>
                                  <button class="btn btn-control active-button" *ngIf="!employer.active">
                                    <b>{{'active'|translate}}</b>
                                  </button>
                                </span>
                              </p>


                              <div class="pt-3">
                                <p>
                                  <span class="desc ml-0"> {{'registeredOn'|translate}}</span>
                                  <span> {{employer.createdAt | date }}</span>
                                </p>

                                <div class="pt-2 pb-3">
                                  <button class="btn btn-control message-button w-100" (click)="openMessagesPopup()">
                                    <b>{{'message'|translate}}</b>
                                  </button>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>


                  <div class="card mb-3">
                    <div class="card-body">


                      <div class="row mt-4">
                        <div class="col-12 pb-2 titles">
                          <b>
                            {{'contactPerson'|translate}}
                          </b>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12 col-lg-9">
                          <div class="row mt-2">
                            <div class="col-12 col-sm-4">
                              <img src="./../../../../../assets/images/talentbase/icons/primay-phone.png">
                              <span class="desc">{{ 'primanyPhone' | translate }}</span>
                            </div>
                            <div class="col-12 col-sm-8" *ngIf="employer?.contactPerson">
                              {{employer?.contactPerson?.phoneNumber?.countryCallingCode}} {{employer?.contactPerson?.phoneNumber?.phoneNumber}}
                            </div>
                            <div class="col-12 col-sm-8" *ngIf="!employer?.contactPerson">
                              {{'na'|translate}}
                            </div>
                          </div>
                        </div>
                        <!-- <div class="col-12 col-lg-3">
                          <button class="btn btn-control red-button w-100" *ngIf="recruiter.active" (click)="openDeactivePopup()">
                            <b>{{'deactive'|translate}}</b>
                          </button>
                          <button class="btn btn-control green-button w-100" *ngIf="!recruiter.active">
                            <b>{{'active'|translate}}</b>
                          </button>
                        </div> -->
                      </div>

                      <div class="row">
                        <div class="col-12 col-lg-9">
                          <div class="row mt-2">
                            <div class="col-12 col-sm-4">
                              <img src="./../../../../../assets/images/talentbase/icons/mail.png">
                              <span class="desc">{{ 'email' | translate }}</span>
                            </div>
                            <div class="col-12 col-sm-8" *ngIf="employer?.contactPerson">
                              {{employer.contactPerson?.email}}
                            </div>
                            <div class="col-12 col-sm-8" *ngIf="!employer?.contactPerson">
                              {{'na'|translate}}
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-12 col-sm-4">
                              <img src="./../../../../../assets/images/talentbase/icons/office-building.png">
                              <span class="desc">{{ 'company' | translate }}</span>
                            </div>
                            <div class="col-12 col-sm-8" *ngIf="employer.companyName">
                              {{employer.companyName}}
                            </div>
                            <div class="col-12 col-sm-8" *ngIf="!employer.companyName">
                              {{ 'na' | translate }}
                            </div>
                          </div>
                        </div>
                        <!-- <div class="col-12 col-lg-3">
                          <button class="btn btn-control purple-button w-100" (click)="openMessagesPopup()">
                          <b>{{'message'|translate}}</b>
                          </button>
                        </div> -->
                      </div>
                    </div>
                  </div>


                  <div class="card">
                    <div class="card-body">
                      <div class="row mt-4">
                        <div class="col-12 pb-2 titles">
                          <b>
                            {{'companyInfo'|translate}}
                          </b>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-12 col-sm-3">
                          <img src="./../../../../../assets/images/talentbase/icons/briefcase.png">
                          <span class="desc">{{ 'companySize' | translate }}</span>
                        </div>
                        <div class="col-12 col-sm-9" *ngIf="employer?.companySize">
                          {{employer.companySize | translate}}
                        </div>
                        <div class="col-12 col-sm-9" *ngIf="!employer?.companySize">
                          {{'na' | translate}}
                        </div>
                      </div>

                      <div class="row mt-2">
                        <div class="col-12 col-sm-3">
                          <img src="assets/images/talentbase/icons/web.png">
                          <span class="desc">{{ 'website' | translate }}</span>
                        </div>
                        <div class="col-12 col-sm-9" *ngIf="employer?.website">
                          {{employer.website}}
                        </div>
                        <div class="col-12 col-sm-9" *ngIf="!employer?.website">
                          {{'na' | translate}}
                        </div>
                      </div>

                      <div class="row mt-2">
                        <div class="col-12 col-sm-3">
                          <img src="assets/images/talentbase/icons/chart-pie.png">
                          <span class="desc">{{ 'industries' | translate }}</span>
                        </div>
                        <div class="col-12 col-sm-9">
                          <span *ngFor="let industry of employer.industries; let last = last;">{{industry.name}}<span *ngIf="!last">, </span></span>
                        </div>
                      </div>

                      <div class="row mt-2">
                        <div class="col-12 col-sm-3">
                          <img src="assets/images/talentbase/icons/office-building.png">
                          <span class="desc">{{ 'about' | translate }}</span>
                        </div>
                        <div class="col-12 col-sm-9" *ngIf="employer?.about">
                          {{employer.about}}
                        </div>
                        <div class="col-12 col-sm-9" *ngIf="!employer?.about">
                          {{'na' | translate}}
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>

          </div>

        </div>

      </div>

      <div class="col-12">

        <div class="col-10">
        </div>
        <div class="col-2 float-right pb-5 mr-5 mr-md-5 mr-lg-0 mr-xl-0">
          <button type="button" class="btn btnClose shadow-sm" data-dismiss="modal">{{ 'close' | translate }}</button>
        </div>

  </div>
    </div>
  </div>
</div>

<app-messages *ngIf="selectedEmployer && selectedEmployer.id" [selectedUser]="selectedEmployer" [employer]="true"></app-messages>

<app-admin-deactivate-popup></app-admin-deactivate-popup>
