import { Component, OnInit }                                              from '@angular/core';
import { ActivatedRoute }                                                 from '@angular/router';
import { CreateAvatar, RequestPayload }                                   from 'src/app/shared/classes';
import { EarningsModel }                                                  from 'src/app/shared/models';
import { RecruiterUserModel }                                             from 'src/app/shared/models/recruiter/recruiter-user.model';
import { EarningService, RecruiterService, ReviewService, TranslatePipe } from 'src/app/shared/services';

@Component({
             selector: 'app-recruiter-details',
             templateUrl: './recruiter-details.component.html',
             styleUrls: ['./recruiter-details.component.scss']
           })
export class RecruiterDetailsComponent implements OnInit {

  recruiterId: string = null;
  recruiter: RecruiterUserModel = null;
  reviews: any[];
  createAvatar: CreateAvatar;
  requestPayload: RequestPayload;
  requestPayloadComments: RequestPayload;
  currentPage: number;
  count: number;
  page: number = 1;
  countExp: number;
  isAbout: boolean = true;

  isRecommended: boolean = false;
  experiences: EarningsModel[] = [];
  activeReviewCardId: string = null;

  constructor(private route: ActivatedRoute, private recruiterService: RecruiterService,
              private reviewService: ReviewService, private translate: TranslatePipe,
              private earningService: EarningService) {
    this.requestPayload = new RequestPayload();
    this.requestPayload.limit = 3;
    this.requestPayloadComments = new RequestPayload();
    this.requestPayloadComments.limit = 10;
    this.createAvatar = new CreateAvatar();
  }

  ngOnInit(): void {
    this.recruiterId = this.route.snapshot.paramMap.get('recruiterId');
    this.isRecommended = this.route.snapshot.paramMap.get('isRecommended') == '1';

    this.recruiterService.ServerGetWithText('/' + this.recruiterId)
        .subscribe(x => {

          this.recruiter = x;
          this.recruiter.imageText = this.createAvatar.create(this.recruiter.user.fullName);
          this.recruiter.imageColor = this.createAvatar.color();
          this.recruiter.functionalRolesText = this.recruiter.functionalRoles.map(m => ' ' + m.name)
                                                   .toString();
          this.recruiter.industriesText = this.recruiter.industries.map(m => ' ' + m.name)
                                              .toString();
          this.recruiter.requisitionTypesText = this.recruiter.requisitionTypes.map(
            m => ' ' + this.translate.transform(m + 'REC'))
                                                    .toString();
          this.getReviews(1);
          this.getEarnings(1);
        });
  }

  getReviews(pageNo) {
    this.page = pageNo;
    this.reviewService.ServerGetWithText(
      this.requestPayloadComments.payloadURLWithOutDelete(pageNo) + '&recruiterId=' + this.recruiter.id)
        .subscribe(x => {
          this.reviews = x.content;
          this.count = x.totalElements;
          this.reviews.forEach(f => {
            f.imageText = this.createAvatar.create(f.fullName);
            f.imageColor = this.createAvatar.color();
          });

        });
  }

  getEarnings(pageNo) {
    this.page = pageNo;
    this.earningService.ServerGetWithText(
      this.requestPayload.payloadURLWithOutDelete(pageNo) + '&filter=recruiter.id::' + this.recruiter.id)
        .subscribe(x => {
          this.experiences = x.content;
          this.countExp = x.totalElements;
          this.experiences.forEach(f => {
            f.imageText = this.createAvatar.create(f.job?.name);
            f.imageColor = this.createAvatar.color();
          });

        });
  }

}
