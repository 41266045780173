<div class="px-xl-5 pb-5">
  <div class="container-fluid mt-4">
    <span class="home-text">{{ 'candidates' | translate }}</span> <span class="user-text">
      <i class="fas fa-chevron-right ml-3 mr-2"></i> {{ 'addNewCandidate' | translate }} </span>
    <div class="header mt-3">
      {{ 'addNewCandidate' | translate }}
    </div>
    <div>

    </div>
  </div>
  <hr class="text-line">

  <div class="container-fluid">
    <div class="row">
      <div class="col d-flex justify-content-end">
        <button class="btn btn-outline-primary m-3" (click)="backToPage()">Discard</button>

        <button class="btn btn-primary m-3" [disabled]="disableControl()" (click)="postCandidate()">Save
          Candidate</button>

      </div>

    </div>
  </div>
  <hr class="hr-line">

  <div class="container-fluid">
    <div class="row">

      <div class="col-12 col-md-2 col-lg-3 font-weight-bold" style="margin-top: 6px">
        {{'candidateInfo' | translate}}

        <div class="text-muted font-weight-normal" style="margin: 18px 0px;
">
          {{'textThree' | translate}}
        </div>
      </div>
      <div class="col-12 col-md-10 col-lg-9">
        <div class="row pt-1">
          <div class="col-12 col-md-6">
            <div class="row">
              <div class="col-12">
                <label class="label" for="first-name">{{ 'firstName' | translate }}*</label>
              </div>
              <div class="col-12">
                <input [(ngModel)]="candidate.firstName" type="text" class=" form-control text"
                  placeholder="First Name">
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label class="label" for="email">{{ 'email' | translate }}*</label>
              </div>
              <div class="col-12">
                <input [(ngModel)]="candidate.email" type="text" (keydown)="keyDownHandlerEmail($event)"
                  class=" form-control text" placeholder="Enter e-mail">

              </div>
            </div>

          </div>
          <div class="col-12 col-md-6">
            <div class="row">
              <div class="col-12">
                <label class="label" for="last-name">{{ 'lastName' | translate }}*</label>
              </div>
              <div class="col-12">
                <input [(ngModel)]="candidate.lastName" type="text" class=" form-control text" placeholder="Last Name">
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label class="label" for="phone-number">{{ 'phoneNumber' | translate }}*</label>
              </div>
              <div class="col-12">
                <div class="row">
                  <div id="tooltips" class="col-6 pl-3" data-toggle="tooltip" data-placement="top" title="{{tooltip}}"
                    [attr.data-original-title]="tooltip">
                    <select class="form-control text" (change)="selectNumberCode($event.target.value)"
                      id="exampleFormControlSelect1" [(ngModel)]="candidate.phoneNumber.countryCallingCode">
                      <option *ngFor="let opt of phoneNumberCodesOptions" [ngValue]="opt.value">{{opt.label}}</option>
                    </select>

                  </div>
                  <div class="col-6 pl-1">
                    <input type="text" class=" form-control text" (keydown)="phoneNumberChars($event)" maxlength="15"
                      placeholder="Enter Your Number" [(ngModel)]="candidate.phoneNumber.phoneNumber">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 mt-2">
            <label class="label" for="skill-tags">{{ 'skillTags' | translate }}</label>
          </div>

          <div class="col-12">
            <div class="dropdown drp ">
              <input [(ngModel)]="skillTagText" aria-expanded="false" aria-haspopup="true"
                class="form-group dropdown-toggles position-relative" data-target="dropdown" type="text" #searchInput
                (keyup)="getSkills(searchInput.value)"
                style="background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px; width: 100%; margin-bottom: -1px;" />
              <div class="dropdown-menu col-12 pb-0" id="dropdown-menu" [ngStyle]="{'display':  skillTagText == '' ? 'none' : ''}">
                <div class="col-12 drp-menu scrollable-menu p-0 ">
                    <div *ngFor="let item of skillsOptions">
                      <label (click)="selectSkill($event, item)"
                        class="dropdown-item m-0 cursor-pointer scrollable-menu">
                        <span class="ml-2">{{item.label | translate}}</span>
                      </label>

                    </div>

                  <div *ngIf="skillTagText != '' && skillsOptions.length == 0">
                    <label (click)="addSkill(skillTagText)" class="dropdown-item m-0 cursor-pointer scrollable-menu">
                      <span class="ml-2"><i class="fas fa-plus"></i> {{'addNewSkill' | translate}}</span>
                    </label>
                  </div>

                </div>
              </div>
            </div>
          </div>


          <div *ngIf="skillTags && skillTags.length > 0" class="form-group" style="padding: 0px 15px 15px 15px;">
            <label for="exampleInputEmail1" class="form-label"
              style="font-family: Roboto; font-style: normal; font-weight: bold; font-size: 14px; line-height: 150%; text-transform: capitalize; color: #2B3A4B;">
              {{ 'addedSkills' | translate }}
            </label>

            <br>
            <span class="mail-badge ml-2" *ngFor="let tag of skillTags">
              <span class="badge-text"> {{tag.name}} <i class="fas fa-times ml-1" style="color: #707683;"
                  (click)="deleteTag(tag)"></i> </span>
            </span>

          </div>
        </div>
      </div>
    </div>

  </div>
  <hr class="hr-line">

  <div class="container-fluid">

    <div class="row">

      <div class="col-12 col-md-2 col-lg-3 font-weight-bold">
        {{'uploadResume' | translate}}

        <div class="text-muted font-weight-normal" style="margin: 18px 0px;
">
          {{'textFour' | translate}}

        </div>
      </div>
      <div class="col-12 col-md-10 col-lg-9">

        <div class="row ">
          <div class="col-12">
            <label class="label" for="cover-letter-textarea">
              {{'uploadResume' | translate}}
            </label>
          </div>
        </div>



        <div class="row">
          <div class="col-12 position-relative">
            <div class="w-100 cursor-pointer" (dblclick)="openResume()">
              <input type="text" class="form-control" [(ngModel)]="candidate.resume.title">
            </div>

            <label for="resume" class="file-label cursor-pointer">
              Browse
            </label>
            <input type="file" class="d-none" id="resume" (change)="chooseResume($event)">
          </div>
        </div>







      </div>
    </div>
  </div>
  <br>
  <hr class="hr-line">

  <div class="container-fluid">

    <div class="row">

      <div class="col-12 col-md-2 col-lg-3 font-weight-bold" style="margin-top: 4px">
        {{'coverLetter' | translate}}


        <div class="text-muted font-weight-normal" style="margin: 18px 0px;
">
          {{'textFive' | translate}}
        </div>
      </div>

      <div class="col-12 col-md-10 col-lg-9">


        <div class="row">
          <div class="col-12">
            <label for="cover-letter" class="input-label-2">{{'coverLetter' | translate | titlecase }}</label>
            <label for="cover-letter-file" class="btn btn-upload-cover-letter">{{'uploadCoverLetter' | translate |
              titlecase}}</label>
            <input type="file" class="d-none" id="cover-letter-file" (change)="chooseCoverLetter($event)">
          </div>
          <div class="col-12" *ngIf="candidate.coverLetter.url!=null">
            <a class="cursor-pointer color-brand" (click)="openCoverLetter()">{{'openCoverLetter' | translate
              |titlecase}}</a>
          </div>
          <div class="col-12">
            <textarea name="cover-letter" id="cover-letter" rows="5" class="form-control"
              [(ngModel)]="candidate.coverLetter.content"></textarea>
          </div>
        </div>


      </div>
    </div>
  </div>
</div>
