import {Component, OnInit} from '@angular/core';
import {
  ApiService,
  FaqCategoryService,
  FaqItemsService, JobService,
  NewsLetterService,
  NotificationService,
  server,
  TranslatePipe,
  TranslateService,
  UserService
} from 'src/app/shared/services';
import {LandingModel} from './landing-model';

declare var $: any;

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})

export class LandingPageComponent implements OnInit {

  programs: any[] = [
    {
      id: 0,
      title: 'How does Talentbase work for employers?',
      content: 'Your jobs go live on the platform: An account manager, who is assigned to you, will work with you to upload a job description as well as notes (called Job Insights) for each of your jobs to help the recruiting community understand who you are looking to hire. The search for your perfect candidate begins: The moment your jobs go live on the Platform, the right set of independent recruiters and recruiting firms on the Talentbase platform will begin submitting candidates to Talentbase. Information about candidates who are approved by your account manager will be sent to you. You can review incoming candidates straight from the Talentbase platform, or in your inbox.'
    },
    {
      id: 1,
      title: 'We are working with approved vendor(s) for our hiring needs and use an internal recruiting team. Why use Talentbase?',
      content: 'By using Talentbase, you instantly extend your reach to find better candidates in less time. Traditional talent acquisition methods cannot give you the reach of a large community of recruiters. You can also manage your talent acquisition processes efficiently by using Talentbase as a centralized platform for all of your approved vendors.'
    },
    {
      id: 2,
      title: 'Who refers candidates on Talentbase? Why do they participate?',
      content: 'Currently, Talentbase works with individual recruiters and recruiting firms. By partnering with Talentbase, members of Talentbase\'s recruiting community gain access to jobs at some of the best companies in Europe. Moreover, working with Talentbase, larger recruiting firms can ensure recruiter productivity. Their recruiters will never run out of jobs that need to be filled.'
    },
    {
      id: 3,
      title: 'Will recruiters or applicants have access to my contact information?',
      content: 'Talentbase values your privacy. Your contact information will not be accessible from the platform to recruiters or candidates.'
    },
    {
      id: 4,
      title: 'What if a candidate, who is sent to me by Talentbase, is or was in my company’s pipeline of applicants?',
      content: 'If a candidate was sent to you by Talentbase and has been in your database for 90 days or less, you can reject their application; it would be considered a duplicate.'
    },
    {
      id: 5,
      title: 'What happens if two recruiters submit the same candidate?',
      content: 'Our system prevents two or more recruiters from submitting the same candidate to the same job. Should this happen, the recruiter, who submits a candidate first, is given credit. Employers will never be required to pay two placement fees for one hire. Please review the Terms of Use to learn more.'
    },
    {
      id: 6,
      title: 'Can I work offline with recruiters whom I have met through Talentbase?',
      content: 'No. Please review our Terms and Conditions here.'
    }
  ];

  faqCategory: boolean = false;
  categories: any[] = [];
  questions: any[] = [];
  selectedCat: boolean = false;
  selectedPrice: boolean = false;
  message: any = {};
  landingModel: LandingModel = null;
  email: string = "";

  constructor(private faqItemService: FaqItemsService, private faqCategoryService: FaqCategoryService,
              private notificationService: NotificationService, private translateService: TranslateService,
              private userService: UserService, private newsLetterService: NewsLetterService, private translate: TranslatePipe) {
  }

  ngOnInit() {
    this.message.name = '';
    this.message.email = '';
    this.message.text = '';
    this.scrolling();
    this.getFaqCategories();
    this.totalColumnCount();
  }

  getFaqCategories() {
    this.faqCategoryService.ServerGetWithoutLogin().subscribe(x => {
      this.categories = x.content;
      let id = this.categories[0].id;
      this.getFaqs(id);

    });
  }

  selectCategory(value) {
    let id = value ? this.categories[0].id : this.categories[1].id;
    this.getFaqs(id);
  }

  getFaqs(catId) {
    this.faqItemService.ServerGetWithoutLoginWithText('/?categoryId=' + catId).subscribe(x => {
      this.questions = x.content.filter(a => a.status != 'DELETED');
    });
  }

  scrolling() {


    $('.navbar ').addClass('fixed-top');
    $('.fixed-top').removeClass('menu-bg');
  }

  setIcon(id) {
    this.programs.forEach(x => {
      x.id == id ? this.programs.filter(x => x.id == id)[0].show = !this.programs.filter(x => x.id == id)[0].show : x.show = false;
    });

  }

  sendMessage() {
    this.notificationService.success(this.translateService.instant('sentMessageSuccessfully'));
    this.message.name = '';
    this.message.email = '';
    this.message.text = '';

    // @ts-ignore
    $('#success-bagde').css('opacity', '1');
    setTimeout(() => {
      $('#success-bagde').css('opacity', '0');
    }, 2500);

  }

  totalColumnCount() {
    this.userService.getLanding().subscribe(x => {
      this.landingModel = x;
    });
  }

  sendMail(){
    let model = {
      email: this.email
    };

    this.newsLetterService.ServerPostWithoutLogin(server.newsLetter, model).subscribe(x=>{
      this.notificationService.success(this.translate.transform('yourEmailHasBeenSuccessfullySaved'));
      this.email = "";
    },
    error =>{
      this.notificationService.error(this.translate.transform(error.message));
    });
  }

}
