<div class="container">
  <div class="row pt-5 border-bottom">
    <div class="col-12">
      <h2 class="gray">{{notification.title}}</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mt-4">
      <div class="position-absolute end-0 notification-date gray">{{notification.day}} {{'daysAgo' |translate}}</div>
      <img src="assets/images/avatar_empty.png" alt="" class="img-fluid avatar-size">
      <div class="row">
        <div class="col-12 mx-auto">
          <p class="mt-3">
            {{notification.content}}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
