import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'general-delete-modal',
  templateUrl: './general-delete-modal.component.html',
  styleUrls: ['./general-delete-modal.component.scss']
})
export class GeneralDeleteModalComponent implements OnInit {

  @Input() Title: '';
  @Input() Text: '';
  @Output() selection = new EventEmitter();
  @Input() ImageSrc: './../../../../assets/images/talentbase/delete-icon.png';

  constructor() {  }

  ngOnInit(): void {
  }

  selectYesOrNo(flag){
    this.selection.emit(flag);
  }

}
