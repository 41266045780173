<!-- <app-main-header [HeaderMode]="'login'"></app-main-header> -->
<link href="https://fonts.googleapis.com/css?family=Poppins" rel="stylesheet" />
<div class="col-12 col-lg-9 m-auto">
  <div class="row">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" style="margin-top: 10rem;">
      <div class="">
        <div class="pt-5 d-none d-md-block d-lg-block d-xl-block">
          <h2>Find a job & grow your career!</h2>
          <p class="desc-text pt-3">
            Lorem ipsum dolor sit amet, sea putant suscipit invidunt cu.
          </p>
        </div>
        <div class="pt-5 d-none d-md-block d-lg-block d-xl-block">
          <img src="./../../../../assets/images/talentbase/left-bg.svg" />
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" style="margin-top: 10rem;">
      <div class="card border-0 custom-card">

        <span class="createText ml-2">{{ "forgotPassword" | translate }}?</span>
        <span class="mt-2 mb-5 ml-2 bottomCreateText">
          {{ "weWillSendMessageToEmail" | translate }}
        </span>
        <tlb-form
          *ngIf="formModel && formModel.length > 0 && formGroup"
          [forms]="formModel"
          [formGroup]="formGroup"
        ></tlb-form>

        <button
          class="btn createBtn ml-3"
          id="next"
          type="button"
          (click)="resetPassword()"
        >
          {{ "continue" | translate }}
        </button>

        <div style="margin-top: 11rem"></div>
      </div>
    </div>
  </div>
  <div class="mt-5"></div>
</div>
