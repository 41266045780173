import { Component, OnInit, Input } from '@angular/core';
import { SideMenuModel }            from '../side-menu-model';

@Component({
             selector: 'app-sidebar-menu',
             templateUrl: './sidebar-menu.component.html',
             styleUrls: ['./sidebar-menu.component.scss']
           })
export class SidebarMenuComponent implements OnInit {

  @Input() menu: SideMenuModel[] = [];

  constructor() {
  }

  ngOnInit(): void {
  }

}
