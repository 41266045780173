import { RecruiterExperienceLevelModel } from "./recruiter-experience-level.model";

export class RecruiterPlacementCountsModel {

  ENTRY_LEVEL: RecruiterExperienceLevelModel;
  ASSOCIATE: RecruiterExperienceLevelModel;
  MID_SENIOR: RecruiterExperienceLevelModel;
  DIRECTOR: RecruiterExperienceLevelModel;
  EXECUTIVE: RecruiterExperienceLevelModel;

constructor() {
  this.ENTRY_LEVEL = new RecruiterExperienceLevelModel();
  this.ASSOCIATE = new RecruiterExperienceLevelModel();
  this.MID_SENIOR = new RecruiterExperienceLevelModel();
  this.DIRECTOR = new RecruiterExperienceLevelModel();
  this.EXECUTIVE = new RecruiterExperienceLevelModel();
}
}
