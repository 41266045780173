import { JobQuestionnaireModel } from ".";

export class JobPreQuestionsModel {

  id: string;
  questionnaire: JobQuestionnaireModel;

constructor() {
  this.id = "";
  this.questionnaire = new JobQuestionnaireModel();
}
}
