<div class="container d-flex justify-content-center">
  <div class="card mt-5 p-3">
    <div class="media"> <img src="https://imgur.com/3UmlFEf.png" class="mr-3">
      <div class="media-body">
        <h6 class="mt-1 mb-0 h6-style">Almost Done! Complete your profile!
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>

        </h6>
       <p class="text">Now start collaboration with your partners, but first create a project.
      </p>
        <button class="btn btn-next border" style="color: #323C47; border-radius: 13px" >Complete Your Profile
           <span style="color: #323C47;">→</span> </button>

      </div>
    </div>
  </div>
</div>
