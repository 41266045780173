import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
             selector: 'app-application-detail-agreed-salary-amount-modal',
             templateUrl: './application-detail-agreed-salary-amount-modal.component.html',
             styleUrls: ['./application-detail-agreed-salary-amount-modal.component.scss']
           })
export class ApplicationDetailAgreedSalaryAmountModalComponent implements OnInit {
  @Input() salary: any = '';
  @Output() hire = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  clickedHire(){
    this.hire.emit(this.salary);
  }

  keyDownHandler(event) {
if((this.salary == '' || this.salary == null || this.salary == undefined ) && event.key == "0") event.preventDefault();
    if (event.key === "-" || (event.keyCode <= 90 && event.keyCode >= 65) || event.key == "*"
    || event.key == "." || event.key == ",")
        event.preventDefault();
}

}
