import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild }                                   from '@angular/core';
import { CandidateModel, DropdownOptionsModel }                                                                    from 'src/app/shared/models';
import { CountryService, NotificationService, TranslatePipe, server, CandidateService, RecruiterSkillTagsService } from 'src/app/shared/services';

@Component({
             selector: 'app-edit-candidate',
             templateUrl: './edit-candidate.component.html',
             styleUrls: ['./edit-candidate.component.scss']
           })
export class EditCandidateComponent implements OnInit {

  @Input() candidateModel: CandidateModel;

  @Input() modalId: 'editCandidateModal';
  phoneNumberCodesOptions: DropdownOptionsModel[] = [];
  resume: any;
  coverLetter: any;
  resumeLabel: string = '';
  coverLetterLabel: string = '';
  coverLetterChoosed: boolean = false;
  resumeChoosed: boolean = false;
  isEdited: boolean = false;
  defaultModel: CandidateModel = null;
  tooltip: string = this.translate.transform('pleaseSelect');
  skillTags: any[] = [];
  skillsOptions: any[] = [];
  skillTagText: string = '';
  allTags: any[] = [];

  @Output() updateCandidate: EventEmitter<any> = new EventEmitter();
  @ViewChild('coverLetter', { static: false }) coverLetterFile: ElementRef;
  @ViewChild('resume', { static: false }) resumeFile: ElementRef;

  constructor(private countryService: CountryService, private candidateService: CandidateService,
              private element: ElementRef,
              private notification: NotificationService, private translate: TranslatePipe,
              private recruiterSkillTagsService: RecruiterSkillTagsService) {
  }

  ngOnInit(): void {
    // @ts-ignore
    $('#' + this.moodalId)
      .modal({ focus: false });

    this.countryService.ServerGetWithoutLogin()
        .subscribe(x => {
          this.phoneNumberCodesOptions = [];
          x.content.forEach(x => {
            this.phoneNumberCodesOptions.push(
              { label: x.callingCode + ' (' + x.defaultLocalization.name + ')', value: x.callingCode });
          });
        });

  }

  ngAfterViewInit() {
    if (document.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }
    this.element.nativeElement.focus();
  }

  ngOnChanges() {
    if (this.candidateModel && this.candidateModel.id && this.candidateModel.id != '') {
      this.defaultModel = Object.assign({}, this.candidateModel);
      this.defaultModel.coverLetter = Object.assign({}, this.candidateModel.coverLetter);
      this.defaultModel.resume = Object.assign({}, this.candidateModel.resume);
      this.defaultModel.phoneNumber = Object.assign({}, this.candidateModel.phoneNumber);
      this.isEdited = false;
      this.coverLetterLabel = this.candidateModel.coverLetter && this.candidateModel.coverLetter.url ? this.candidateModel.coverLetter.url.substr(
        0, 85) : '';
      this.resumeLabel = this.candidateModel.resume?.url?.substr(0, 85);
      this.tooltip = this.phoneNumberCodesOptions.filter(
        x => x.value == this.candidateModel.phoneNumber.countryCallingCode)[0].label;

      this.coverLetterChoosed = false;
      this.resumeChoosed = false;
      this.skillTags = [];

      this.recruiterSkillTagsService.ServerGet()
          .subscribe(x => {
            this.allTags = x.content;

            this.candidateModel.skillTags.forEach(f => {
              this.skillTags.push(this.allTags.filter(fi => fi.id == f.skillTagId)[0]);
            });
          });
    }

  }

  disableControl(): boolean {
    return ((JSON.stringify(this.candidateModel) == JSON.stringify(this.defaultModel)) &&
      (JSON.stringify(this.candidateModel.coverLetter) == JSON.stringify(this.defaultModel.coverLetter)) &&
      (JSON.stringify(this.candidateModel.resume) == JSON.stringify(this.defaultModel.resume)) &&
      (JSON.stringify(this.candidateModel.phoneNumber) == JSON.stringify(
        this.defaultModel.phoneNumber)) && !this.isEdited ) || !this.candidateModel.email.includes('@');
  }

  numbersOnly() {
    this.candidateModel.phoneNumber.phoneNumber = this.candidateModel.phoneNumber.phoneNumber.replace(/[^0-9.-]/g, '');
  }

  chooseCoverLetter(event: any) {
    const file = event.target.files[0];
    const url = window.URL.createObjectURL(file);
    this.candidateModel.coverLetter.url = url;
    this.coverLetter = file;
    this.coverLetterLabel = file.name;
    this.coverLetterChoosed = true;
    this.isEdited = true;
  }

  chooseResume(event: any) {
    const file = event.target.files[0];
    const url = window.URL.createObjectURL(file);
    this.candidateModel.resume.title = file.name;
    this.candidateModel.resume.url = url;

    this.resume = file;
    this.resumeLabel = file.name;
    this.resumeChoosed = true;
    this.isEdited = true;
  }

  postCandidate() {

    let array = [];
    if (this.skillTags && this.skillTags.length > 0) {
      this.skillTags.forEach(x => {
        if (this.candidateModel && this.candidateModel.skillTags && this.candidateModel.skillTags.length > 0 && this.candidateModel.skillTags.some(
          s => s.skillTagId == x.id)) {
          array.push({
                       skillTagId: x.id,
                       name: x.name,
                       id: this.candidateModel.skillTags.filter(f => f.skillTagId == x.id)[0].id
                     });
        } else {
          array.push({ skillTagId: x.id, name: x.name });
        }

      });
    }

    let resume: any = {
      title: this.candidateModel.resume.title
    };

    let coverLetter: any = {
      content: this.candidateModel.coverLetter.content
    };

    let candidate: any = {
      id: this.candidateModel.id,
      firstName: this.candidateModel.firstName,
      lastName: this.candidateModel.lastName,
      email: this.candidateModel.email,
      phoneNumber: this.candidateModel.phoneNumber,
      resume: resume,
      coverLetter: coverLetter,
      skillTags: array
    };

    let formData: any = new FormData();

    let updateCandidate = new Blob([JSON.stringify(candidate)], { type: 'application/json' });
    formData.append('candidate', updateCandidate);

    this.resumeChoosed ? formData.append('resume', this.resume) : '';
    this.coverLetterChoosed ? formData.append('coverLetter', this.coverLetter) : '';

    this.candidateService.ServerPutWithFormData(server.candidate + '/' + candidate.id, formData)
        .subscribe(res => {
          // @ts-ignore
          $('#' + this.modalId)
            .modal('hide');

          this.notification.success(this.translate.transform('theCandidateHasBeenSuccessfullyUpdated'));
          //this.updateCandidate.emit();
        });

  }

  openDeleteCandidate() {
     // @ts-ignore
     $('#generalDeleteModal').modal('show');
  }

  deleteCandidate(e) {
    if (e) {
      this.candidateService.ServerDelete(server.candidate + '/' + this.candidateModel.id)
          .subscribe((res: any) => {
            this.notification.success(this.translate.transform('theCandidateSuccessfullyDeleted'));
            // @ts-ignore
            $('#' + this.modalId)
              .modal('hide');

            this.updateCandidate.emit();
          });
    }
  }

  openResume() {
    if (this.candidateModel.resume.url !== '') {
      window.open(this.candidateModel.resume.url);
    }

  }

  openCoverLetter() {
    if (this.candidateModel.coverLetter.url !== '') {
      window.open(this.candidateModel.coverLetter.url);
    }
  }

  selectNumberCode(data) {
    let array = data.split(':');
    this.tooltip = this.phoneNumberCodesOptions[array[0]].label;
    //@ts-ignore
    $('#tooltips')
      .tooltip('hide');

  }

  getSkills(data) {

    this.skillsOptions = [];
    if (data.length > 2) {

      this.recruiterSkillTagsService.ServerGetWithText('?/filter=status!:DELETED&filter=name~~' + data)
          .subscribe(x => {

            this.skillsOptions = x.content.map(c => ({ value: c, label: c.name }));
          });
      // @ts-ignore
      $('.dropdown-toggles')
        .dropdown('show');
    } else {
      this.skillsOptions = [];
      // @ts-ignore
      $('.dropdown-toggles')
        .dropdown('hide');
    }

  }

  selectSkill(event, data) {
    if (!this.skillTags.some(x => x.id == data.value.id)) {
      this.skillTags.push(data.value);
      this.skillTagText = '';
      this.getSkills('');
    } else {
      this.skillTagText = '';
      this.getSkills('');
    }

  }

  deleteTag(tag) {
    const index: number = this.skillTags.indexOf(tag);
    if (index !== -1) {
      this.skillTags.splice(index, 1);
    }
  }

  addSkill(data) {
    let model = {
      name: data
    };

    this.recruiterSkillTagsService.ServerPost(model)
        .subscribe(x => {
          this.skillTags.push(x);
          this.skillTagText = '';
          this.getSkills('');
        });

  }

  keyDownHandlerEmail(event){
    if (event.keyCode === 32)
        event.preventDefault();

}

phoneNumberChars(event) {
  if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) || event.keyCode == 8) {}
  else event.preventDefault();
}

}
