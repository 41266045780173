<ul class="sidebar-menu">
  <ng-container *ngFor="let menuItem of menu">
    <li *ngIf="menuItem.routerLink"
        [ngClass]="menuItem.subMenu?'has-sub-menu':''"
        [routerLinkActiveOptions]="{exact: menuItem.routerLinkActiveOptions !==undefined ? menuItem.routerLinkActiveOptions:true}"
        [routerLinkActive]="(menuItem.routerLinkActive || 'active')"
        [routerLink]="menuItem.routerLink"
        class="sidebar-menu-item">
      <div class="sidebar-menu-item-content">
        <img *ngIf="menuItem.iconSrc"
             [src]="menuItem.iconSrc"
             style="width: 16px;">
        <i *ngIf="menuItem.iconClass"
           [class]="menuItem.iconClass"></i>
        <span class="d-inline pl-3">{{menuItem.translateTextKey|translate}}</span>
        <i *ngIf="menuItem.subMenu"
           class="fas fa-caret-down float-right pl-2"></i>
      </div>
      <ul *ngIf="menuItem.subMenu"
          class="sidebar-menu-sub-menu hidden">
        <li *ngFor="let subMenuItem of menuItem.subMenu"
            [routerLinkActive]="(subMenuItem.routerLinkActive || 'active')"
            [routerLink]="subMenuItem.routerLink"
            class="sidebar-menu-sub-menu-item">
          <span>{{subMenuItem.translateTextKey|translate}}</span></li>
      </ul>
    </li>
    <li *ngIf="!menuItem.routerLink"
        [ngClass]="menuItem.subMenu?'has-sub-menu':''"
        [routerLinkActiveOptions]="{exact: true}"
        [routerLinkActive]="(menuItem.routerLinkActive || 'active')"
        class="sidebar-menu-item">
      <div class="sidebar-menu-item-content">
        <img *ngIf="menuItem.iconSrc"
             [src]="menuItem.iconSrc"
             style="width: 16px;">
        <i *ngIf="menuItem.iconClass"
           [class]="menuItem.iconClass"></i>
        <span class="d-inline pl-3">{{menuItem.translateTextKey|translate}}</span>
        <i *ngIf="menuItem.subMenu"
           class="fas fa-caret-down float-right pl-2"></i>
      </div>
      <ul *ngIf="menuItem.subMenu"
          class="sidebar-menu-sub-menu hidden">
        <li *ngFor="let subMenuItem of menuItem.subMenu"
            [routerLinkActive]="(subMenuItem.routerLinkActive || 'active')"
            [routerLink]="subMenuItem.routerLink"
            class="sidebar-menu-sub-menu-item">
          <span>{{subMenuItem.translateTextKey|translate}}</span></li>
      </ul>
    </li>
  </ng-container>
</ul>
