import { Component, OnInit,Input } from '@angular/core';
import {QuarterLineCartModel} from '../../models/dashboard/quarter-line-cart.model'

@Component({
  selector: 'app-dashboard-quarter-line-cart',
  templateUrl: './dashboard-quarter-line-cart.component.html',
  styleUrls: ['./dashboard-quarter-line-cart.component.scss']
})
export class DashboardQuarterLineCartComponent implements OnInit {

  @Input()  quarterModels:QuarterLineCartModel[];

  constructor() { }

  ngOnInit(): void {
  }

}
