import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {NgxSpinnerService} from 'ngx-spinner';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {finalize} from 'rxjs/operators';
import {LegalInfoModel} from './legal-info.model';

@Injectable({
  providedIn: 'root'
})
export class LegalInfoService {

  TOKEN_KEY = 'access_token';
  lang = localStorage.getItem('lang');

  constructor(
      public http: HttpClient,
      protected spinner?: NgxSpinnerService,
  ) {

  }

  setHeadersWithToken(): HttpHeaders {
    const token = localStorage.getItem(this.TOKEN_KEY);
    let headersWithToken: HttpHeaders;
    headersWithToken = new HttpHeaders()
        .set('Accept', 'application/json')
        .set('Cache-Control', ' no-cache')
        .set('Content-Type', 'application/json')
        .set('Accept-Language', this.lang)
        .set('Authorization', 'Bearer ' + token);
    return headersWithToken;
  }

  getRecruiterLegalInfo(): Observable<LegalInfoModel> {
    this.spinner.show();
    const headers = this.setHeadersWithToken();
    const options = {headers};
    const url = `${environment.baseUrl}/recruiter/v1/recruiters/me/legal-infos`;

    return this.http.get<LegalInfoModel>(url, options).pipe(
        finalize(() => {
          this.spinner.hide();
        })
    );
  }

  putRecruiterLegalInfo(data: LegalInfoModel): Observable<LegalInfoModel> {
    this.spinner.show();
    const headers = this.setHeadersWithToken();
    const options = {headers};
    const url = `${environment.baseUrl}/recruiter/v1/recruiters/me/legal-infos`;

    return this.http.put<LegalInfoModel>(url, data, options).pipe(
        finalize(() => {
          this.spinner.hide();
        })
    );;
  }

  getEmployerLegalInfo(): Observable<LegalInfoModel> {
    this.spinner.show();
    const headers = this.setHeadersWithToken();
    const options = {headers};
    const url = `${environment.baseUrl}/employer/v1/employers/me/legal-infos`;

    return this.http.get<LegalInfoModel>(url, options).pipe(
        finalize(() => {
          this.spinner.hide();
        })
    );;
  }

  putEmployerLegalInfo(data: LegalInfoModel): Observable<LegalInfoModel> {
    this.spinner.show();
    const headers = this.setHeadersWithToken();
    const options = {headers};
    const url = `${environment.baseUrl}/employer/v1/employers/me/legal-infos`;

    return this.http.put<LegalInfoModel>(url, data, options).pipe(
        finalize(() => {
          this.spinner.hide();
        })
    );
  }

}
