import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import {  NotificationService } from 'src/app/shared/services/notification/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { map, catchError, finalize } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../auth';
import { server } from '../server';

@Injectable({
  providedIn: 'root'
})

export class JobService extends ApiService {

  loading: boolean = false;

  constructor(public http: HttpClient,public spinner:NgxSpinnerService,public translate:TranslatePipe, public notification:NotificationService) {
    super(http, server.jobs,spinner,translate,notification)
  }

  addToWorkList(jobId: string) {
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.post(server.jobs + '/' + jobId + '/favorite', {}, options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        }));

  }

  cloneJob(jobId: string) {
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.post(server.jobs + '/' + jobId + '/clones', {}, options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        }));

  }

  removeFromWorkList(jobId: string) {
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.post(server.jobs + '/' + jobId + '/unfavorite', {}, options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        }));

  }

  getGeneralInfo(jobId: string){

    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.get(server.jobs + "/" + jobId + server.jobGeneralInfos,  options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();

        })
      );
  }

  getExperiences(jobId: string){
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.get(server.jobs + "/" + jobId + server.jobExperiences,  options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      );
  }

  getLocations(jobId: string){
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.get(server.jobs + "/" + jobId + server.jobLocations,  options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      );
  }

  getCompensationsAndFees(jobId: string){
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.get(server.jobs + "/" + jobId + server.jobCompensationsAndFees,  options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      );
  }

  getPreQuestions(jobId: string){
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.get(server.jobs + "/" + jobId + server.jobPreQuestions,  options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      );
  }

  getNotificationsAndCorporates(jobId: string){
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.get(server.jobs + "/" + jobId + server.jobNotificationsAndCorporates,  options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      );
  }

  getTeamMembers(jobId: string){
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.get(server.jobs + "/" + jobId + server.jobTeamMembers,  options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      );
  }

  getInvitation(jobId: string){
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.get(server.jobs + "/" + jobId + server.jobInvitation,  options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      );
  }

  getRestriction(jobId: string){
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.get(server.jobs + "/" + jobId + server.jobRestriction,  options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      );
  }

  postGeneralInfos(model: any){
    this.loading = true;
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.post(server.jobs + server.jobGeneralInfos, model, options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
          this.loading = false;
        }));
  }

  putGeneralInfos(jobId: string, model: any){
    this.loading = true;
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.put(server.jobs + '/' + jobId + server.jobGeneralInfos, model, options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
          this.loading = false;
        }));
  }

  putExperience(jobId: string, model: any){
    this.loading = true;
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.put(server.jobs + '/' + jobId + server.jobExperiences, model, options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
          this.loading = false;
        }));
  }

  putLocations(jobId: string, model: any){
    this.loading = true;
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.put(server.jobs + '/' + jobId + server.jobLocations, model, options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
          this.loading = false;
        }));
  }

  putCompensationsAndFees(jobId: string, model: any){
    this.loading = true;
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.put(server.jobs + '/' + jobId + server.jobCompensationsAndFees, model, options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
          this.loading = false;
        }));
  }

  putPreQuestions(jobId: string, model: any){
    this.loading = true;
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.put(server.jobs + '/' + jobId + server.jobPreQuestions, model, options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
          this.loading = false;
        }));
  }

  putNotificationsAndCorporates(jobId: string, model: any){
    this.loading = true;
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.put(server.jobs + '/' + jobId + server.jobNotificationsAndCorporates, model, options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
          this.loading = false;
        }));
  }

  putTeamMembers(jobId: string, model: any){
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.put(server.jobs + '/' + jobId + server.jobTeamMembers, model, options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        }));
  }

  putInvitation(jobId: string, model: any){
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.put(server.jobs + '/' + jobId + server.jobInvitation, model, options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        }));
  }

  putRestriction(jobId: string, model: any){
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.put(server.jobs + '/' + jobId + server.jobRestriction, model, options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        }));
  }
}
