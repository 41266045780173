import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'tlb-empty-page-dashboard',
  templateUrl: './tlb-empty-page-dashboard.component.html',
  styleUrls: ['./tlb-empty-page-dashboard.component.scss']
})
export class TlbEmptyPageDashboardComponent implements OnInit {
  @Input() warningLabel: string = "";
  constructor() { }

  ngOnInit() {
  }

}
