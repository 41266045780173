import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RequestPayload } from 'src/app/shared/classes';
import { JobType } from 'src/app/shared/enums';
import { ApplicationModel, JobModel, GeneralIdModel, JobCompensationModel, JobExperienceRequiredModel, JobRelocationAssistanceModel, JobVisaRequirementModel, JobLocationModel, JobFeeModel, JobQuestionnaireModel } from 'src/app/shared/models';
import { server, JobService } from 'src/app/shared/services';

@Component({
  selector: 'app-employer-jobs',
  templateUrl: './employer-jobs.component.html',
  styleUrls: ['./employer-jobs.component.scss']
})
export class EmployerJobsComponent implements OnInit {
  reviews: any[] = [];
  @Input() jobs: JobModel[] = [];
  @Input() count: number;
  @Input() limit: number;
  @Output() updateJobs = new EventEmitter();
  currentPage: number;
  requestPayload: RequestPayload;
  applications: ApplicationModel[] = [];
  selectedJob: JobModel = new JobModel();
  selectedApplication: ApplicationModel;

  @Output() updatedStatus = new EventEmitter();


  constructor(private jobService: JobService) {
    this.requestPayload = new RequestPayload();
    this.selectedJob.classification = new GeneralIdModel();
    this.selectedJob.function = new GeneralIdModel();
    this.selectedJob.industry = new GeneralIdModel();
    this.selectedJob.compensation = new JobCompensationModel();
    this.selectedJob.compensation.currency = new GeneralIdModel();
    this.selectedJob.experienceRequired = new JobExperienceRequiredModel();
    this.selectedJob.relocationAssistance = new JobRelocationAssistanceModel();
    this.selectedJob.visaRequirement = new JobVisaRequirementModel();
    this.selectedJob.location = new JobLocationModel();
    this.selectedJob.location.country = new GeneralIdModel();
    this.selectedJob.location.city = new GeneralIdModel();
    this.selectedJob.fee = new JobFeeModel();
    this.selectedJob.questionnaire = new JobQuestionnaireModel();
  }

  ngOnInit(): void {
    this.requestPayload.limit = this.limit;

  }

  pagination(pageNo) {
    this.updateJobs.emit(pageNo);
  }

  public get jobType(): typeof JobType {
    return JobType;
  }

  openApplicantsModal(event, all?) {

    this.applications = all == "all" ? event.allApplications : event.applications;
    event ? this.selectedJob = event : '',

    this.applications.forEach(x=> {
      x.location = this.selectedJob.location;
      x.jobType = this.selectedJob.type;
    });
      //@ts-ignore
      //$('#employerJobDetailModal').modal('hide');
      // @ts-ignore
      $('#applicantsModalLong').modal('show');
  }

  openJobDetail(job: JobModel) {
    this.selectedJob = job;

    // @ts-ignore
    $('#jobDetailFromEmployer').modal('show');

  }
  cancelJob(flag: boolean) {
    if (flag == true) {
      this.jobService.ServerPut(server.jobs + '/' + this.selectedJob.id + '/status', { status: 'PASSIVE' }).subscribe(x => {
        this.pagination(1);
        //@ts-ignore
        $('#jobDetailFromEmployer').modal('hide');
      });
    } else {
      //@ts-ignore
      $('#jobDetailFromEmployer').modal('show');
    }
  }
  editJobModalShow(job?) {
    job ? this.selectedJob = job : '',

      // @ts-ignore
      $('#editJobModal').modal('toggle');
    //@ts-ignore
    //$('#employerJobDetailModal').modal('hide');
  }
  showCancelJobPopup() {
    //@ts-ignore
    //$('#employerJobDetailModal').modal('hide');
    // @ts-ignore
    $('#generalYesNoPopup').modal('show');

  }
  openReviewModal(reviews) {
    this.reviews = reviews;
    // @ts-ignore
    $('#review-popup').modal('show');
  }
  openUpdateCandidateStatus(application: ApplicationModel) {
    this.selectedApplication = application;
    // @ts-ignore
    $('#candidateUpdateStatusModal').modal('show');
    // @ts-ignore
    $('#candidateUpdateStatusModal').css('z-index', 3000);;
  }
  openCandidateDetailPopup(application: ApplicationModel) {
    this.selectedApplication = application;
    // @ts-ignore
    $('#candidateDetailModal').modal('show');
  }

  updateStatus() {
    // @ts-ignore
    //$('#applicantsModalLong').modal('hide');

    this.updatedStatus.emit();



  }
  activeJob(job?) {
    this.jobService.ServerPut(server.jobs + '/' + job.id + '/status', { status: 'ACTIVE' }).subscribe(x => {
      this.updatedStatus.emit();
    });

  }

}
