<div class="row h-100 d-flex">
  <div class="col-12 align-self-center">
    <div *ngIf="!invalidNumber" id="iyzipay-checkout-form" class="responsive h-100"></div>

      <div class="success-modal" *ngIf="invalidNumber">
        <div class="success-modal-overlay" (click)="goLogin()"></div>
        <div class="container h-100">
          <div class="row h-100">
            <div class="col-xl-6 m-auto">
              <div class="card shadow">
                <div class="card-body" style="text-align: center">
                  <img src="assets/images/invalid-number.png" alt=""/>
                  <p class="thank-you-text mt-3">{{ "invalidPhoneNumber" | translate }}</p>
                  <div style="display: flex; justify-content: center">
                    <p class="mail-sent-text">
                      {{
                      "pleaseEnterAValidPhoneNumberToPay"
                        | translate
                      }}
                    </p>
                  </div>

                  <div *ngIf="role != 'EMPLOYER_MANAGER' && role != 'EMPLOYER_USER'" class="row" style="width: 80%; margin-left: 10%;">
                    <div id="tooltips" class="col-4 pl-3" data-toggle="tooltip" data-placement="top" title="{{tooltip}}"
                      [attr.data-original-title]="tooltip">
                      <select class="form-control text" (change)="selectNumberCode($event.target.value)"
                        id="exampleFormControlSelect1" [(ngModel)]="phoneNumber.countryCallingCode">
                        <option *ngFor="let opt of phoneNumberCodesOptions" [ngValue]="opt.value">{{opt.label}}</option>
                      </select>
                    </div>
                    <div class="col-8 pl-1">
                      <input type="text" class=" form-control text" (keydown)="phoneNumberChars($event)" maxlength="15"
                        placeholder="Enter Your Number" [(ngModel)]="phoneNumber.phoneNumber">
                    </div>
                  </div>

                  <button *ngIf="role != 'EMPLOYER_MANAGER' && role != 'EMPLOYER_USER'" class="btn btn-primary mt-5" (click)="updatePhoneNumber()" [disabled]="this.phoneNumber.phoneNumber.length < 7 || this.phoneNumber.countryCallingCode == ''">
                    {{ "update" | translate }}
                  </button>

                  <button *ngIf="role == 'EMPLOYER_MANAGER' || role == 'EMPLOYER_USER'" class="btn btn-primary mt-5" (click)="goProfileUpdate()">
                    {{ "update" | translate }}
                  </button>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

  </div>
</div>


