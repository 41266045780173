import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PackageFunctionalRoleService }            from '../../../../shared/services/subscription/functional-role/package-functional-role.service';
import { BusinessRoleService }                     from '../../../../shared/services/subscription/business-role/business-role.service';
import { BusinessFunctionModel }                   from '../../../../shared/models/subscription/business-function-model';
import { BusinessFunctionStatusEnum }              from '../../../../shared/models/subscription/business-function-status.enum';
import { NotificationService, server, TranslatePipe }                                  from '../../../../shared/services';

@Component({
             selector: 'app-admin-packets-bussines-functions',
             templateUrl: './admin-packets-bussines-functions.component.html',
             styleUrls: ['./admin-packets-bussines-functions.component.scss']
           })
export class AdminPacketsBussinesFunctionsComponent implements OnInit {

  @Output() refreshData: EventEmitter<any> = new EventEmitter<any>();

  allFunctionalRoles: string[] = [];
  allFunctionalRolesList: string[] = [];
  selectedFunctionalRole = '';
  newBusinessFunction: BusinessFunctionModel = new BusinessFunctionModel();
  businessFunctions: BusinessFunctionModel[] = [];
  selectedBusinessFunction: BusinessFunctionModel = new BusinessFunctionModel();

  constructor(private functionalRoleService: PackageFunctionalRoleService,
              private businessRoleService: BusinessRoleService,
              private notification: NotificationService, private translate: TranslatePipe) {
  }

  get BusinessFunctionStatusEnum(): typeof BusinessFunctionStatusEnum {
    return BusinessFunctionStatusEnum;
  }

  ngOnInit(): void {
    this.getAllBusinessFunctions();
    this.getAllFunctionalRoles();

  }

  selectFunctionalRole(): void {

    const functionalRole = this.selectedFunctionalRole;
    const index = this.allFunctionalRoles.indexOf(functionalRole);
    if (index < 0) {
      return;
    }
    this.allFunctionalRoles.splice(index, 1);
    this.selectedBusinessFunction.functionalRoles.push(functionalRole);
    this.selectedFunctionalRole = '';
  }

  unSelectFunctionalRole(functionalRole: string, index: number): void {
    this.selectedBusinessFunction.functionalRoles.splice(index, 1);
    this.allFunctionalRoles.push(functionalRole);
  }

  changeBusinessFunction(): void {
    this.allFunctionalRoles = [...this.allFunctionalRolesList];
    this.selectedBusinessFunction.functionalRoles.forEach(functionalRole => {
      const index = this.allFunctionalRoles.indexOf(functionalRole);
      this.allFunctionalRoles.splice(index, 1);
    });
    this.selectedFunctionalRole = this.allFunctionalRoles[0];

  }

  save(): void {
    if (this.selectedBusinessFunction.id === '') {
      this.businessRoleService.ServerPost(this.selectedBusinessFunction)
          .subscribe(x => {

            this.businessRoleService.ServerPut(`${server.packageBusinessRoles}/${x.id}/status`, {
              status: this.selectedBusinessFunction.status
            })
                .subscribe(() => {
                  this.notification.success(this.translate.transform('newBusinessFunctionsSuccessfullyAdded'));
                  this.refreshData.emit();
                  this.getAllBusinessFunctions();
                });
          });
    } else {

      this.businessRoleService.ServerPut(`${server.packageBusinessRoles}/${this.selectedBusinessFunction.id}`,
                                         this.selectedBusinessFunction)
          .subscribe(x => {
            this.notification.success(this.translate.transform('newBusinessFunctionsSuccessfullyUpdated'));
            this.getAllBusinessFunctions();
            this.refreshData.emit();
          });
      this.businessRoleService.ServerPut(`${server.packageBusinessRoles}/${this.selectedBusinessFunction.id}/status`, {
        status: this.selectedBusinessFunction.status
      })
          .subscribe(x => {
            this.getAllBusinessFunctions();
            this.refreshData.emit();
          });
    }
  }

  openDeletePacket() {
    // @ts-ignore
    $('#generalDeleteModal').modal('show');
 }

  delete(e): void {
    if (this.selectedBusinessFunction.id !== '' && e) {
      this.businessRoleService.ServerDelete(`${server.packageBusinessRoles}/${this.selectedBusinessFunction.id}`)
          .subscribe(x => {
            this.refreshData.emit();
            this.getAllBusinessFunctions();
          });
    }
  }

  private getAllBusinessFunctions(): void {
    this.businessRoleService.ServerGetWithText(`?filter=status!:${BusinessFunctionStatusEnum.DELETED}`)
        .subscribe(x => {
          this.businessFunctions = x.content;
          this.selectedBusinessFunction = this.businessFunctions[0];
        });
  }

  private getAllFunctionalRoles(): void {
    this.functionalRoleService.ServerGet()
        .subscribe(x => {
          this.allFunctionalRoles = x;
          this.allFunctionalRolesList = x;
        });
  }
}
