import { GeneralIdModel } from "../parameter";

export class JobFeeModel {

  type: string;
  amount: number;
  currency: any;

  constructor() {

    this.type = "";
    this.amount = null;
    this.currency = null;
  }
}
