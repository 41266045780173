import { Component, OnInit } from '@angular/core';

@Component({
             selector: 'app-payment-popup',
             templateUrl: './payment-popup.component.html',
             styleUrls: ['./payment-popup.component.scss']
           })
export class PaymentPopupComponent implements OnInit {
  creditCard = {
    number: '',
    expirationDate: '',
    securityCode: ''
  };
  paymentMethod = {
    paypal: {
      bgColor: 'white'
    },
    creditCard: {
      bgColor: 'blue'
    }
  };

  constructor() {
  }

  ngOnInit(): void {
    // @ts-ignore
    $('#paymentModal')
      .modal('toggle');
  }

  onCardNumberKeyUp(event: KeyboardEvent): void {
    const allowedChars = '0123456789';
    let str = '';
    for (let i = 0; i < this.creditCard.number.length; i++) {
      if (i === 4 || i === 9 || i === 14) {
        str += ' ';
      }
      if (allowedChars.includes(this.creditCard.number.charAt(i))) {
        str += this.creditCard.number.charAt(i);
      }
    }
    this.creditCard.number = str;
  }

  onCardExpirationDateKeyUp(event: KeyboardEvent): void {
    const allowedChars = '0123456789';
    let str = '';
    for (let i = 0; i < this.creditCard.expirationDate.length; i++) {
      if (i === 2) {
        str += '/';
      }
      if (allowedChars.includes(this.creditCard.expirationDate.charAt(i))) {
        str += this.creditCard.expirationDate.charAt(i);
      }
    }
    this.creditCard.expirationDate = str;
  }

  onCardSecurityCodeKeyUp($event: KeyboardEvent): void {
    const allowedChars = '0123456789';
    let str = '';
    for (let i = 0; i < this.creditCard.securityCode.length; i++) {
      if (allowedChars.includes(this.creditCard.securityCode.charAt(i))) {
        str += this.creditCard.securityCode.charAt(i);
      }
    }
    this.creditCard.securityCode = str;
  }

  changePaymentMethod(paymentMethod: string): void {
    this.paymentMethod.paypal.bgColor = this.paymentMethod.paypal.bgColor === 'white' ? 'blue' : 'white';
    this.paymentMethod.creditCard.bgColor = this.paymentMethod.creditCard.bgColor === 'white' ? 'blue' : 'white';
  }
}
