import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-finish-earning-payment',
  templateUrl: './finish-earning-payment.component.html',
  styleUrls: ['./finish-earning-payment.component.scss']
})
export class FinishEarningPaymentComponent implements OnInit {


  constructor() {
  }

  ngOnInit(): void {

  }

}
