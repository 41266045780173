<div class="container-fluid px-xl-5 px-0 my-4">
  <div class="col-10 mx-auto">
    <div class="row">
      <div class="col-12 pr-1 pl-0">
        <h5 class="company-info pb-1 pt-2">{{ 'editBasicInfoHeader' | translate }}</h5>
        <div class="card">
          <div class="card-body shadow-sm">
            <div class="container-fluid">
              <div class="col">
                <div class="form-group">
                  <div class="row">
                    <div class="col-6">
                      <p class="font-weight-bold">{{'basicsDetails' | translate}}</p>
                    </div>
                    <div class="col-6">
                      <span type="button" (click)="openChangePassword()" class="font-weight-bold passColor float-right "> {{'changePassword' | translate}}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <tlb-form *ngIf="formModel && formModel.length > 0 && formGroup" [forms]="formModel" [formGroup]="formGroup"></tlb-form>

                <div class="row mt-5">
                  <a href="#" class="font-weight-bold btnCancel  "> {{'cancels' | translate}} </a>
                  <button type="button" class="btn btnUpdateProfile " [disabled]="formGroup && formGroup.invalid" (click)="updateInfo()" >{{'updateProfile' | translate}}</button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-change-password></app-change-password>
  </div>
</div>
