<div class="container-fluid px-sm-5 mt-4">
  <div class="header">{{ header | translate }}</div>
  <div class="row">
    <div class="col-xl-3 col-lg-4 col-md-12 col-12">
      <div style="cursor: pointer;" class="mt-3" [ngClass]="activeTab==0 ? 'active-select-div' : 'select-div'"
        (click)="changeTab(0, 'savedJobProfiles')">
        <div class="ml-4 row">
          <div class="col-8" style="margin-top: 13px;">
            {{ 'savedJobProfiles' | translate }}
          </div>
          <div class="col-4 mt-1">
            <div class="box"><label style="margin-top: 6px;">{{jobCount}}</label></div>
          </div>
        </div>

      </div>
      <div style="cursor: pointer;" class="mt-3" [ngClass]="activeTab==1 ? 'active-select-div' : 'select-div'"
        (click)="changeTab(1, 'industryJobProfiles')">
        <div class="ml-4 row">
          <div class="col-8" style="margin-top: 13px;">
            {{ 'industryJobProfiles' | translate }}
          </div>
          <div class="col-4 mt-1">
            <div class="box"><label style="margin-top: 6px;">{{industryCount}}</label></div>
          </div>
        </div>
      </div>

      <div style="cursor: pointer; text-align: center;" class="mt-3" [ngClass]="activeTab==2 ? 'active-select-div' : 'select-div'"
        (click)="changeTab(2, 'createNewJobProfile')">
        <span><i class="fas fa-plus"></i> <label class="ml-1 job-profile-text">{{ 'createNewJobProfile' | translate }}</label></span>
      </div>

      <div *ngIf="activeTab==2" class="mt-5 warning-box"
        (click)="changeTab(2, 'createNewJobProfile')">
        <p class="warning-header mt-4 ml-3"><i class="fas fa-exclamation-triangle"></i> {{'warning' | translate}}!</p>
        <span><label class="ml-3">{{ 'jobAddWarningText' | translate }}</label> <br> <label class="ml-3">{{'jobAddWarningTextCont' | translate}}</label></span>
     </div>

    </div>

    <div class="col-xl-9 col-lg-8 col-md-12 col-12">
      <app-saved-job-profiles *ngIf="activeTab == 0" [fromInd]="false" (useJobProfile)="useProfile($event)"></app-saved-job-profiles>
      <app-industry-job-profiles *ngIf="activeTab == 1" (useJobProfile)="useProfile($event)"></app-industry-job-profiles>
      <app-create-new-job-profile *ngIf="activeTab == 2" (publishedJob)="getCounts()" [jobId]="jobId"></app-create-new-job-profile>
    </div>
  </div>

</div>

<!-- <div class="container-fluid px-sm-5 mt-4 row">
  <div class="col-9">
    <div class="card">
      <div class="card-body pb-0" style="margin-top: -12px;">
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li class="nav-item tab-name cursor" style="border-right: 2px solid #EBEEF2;" (click)="activeTab = 0">
            <span class="nav-link active" id="pills-saved-jobs-tab" data-toggle="pill" href="#pills-saved-jobs" role="tab"
              aria-controls="pills-saved-jobs" aria-selected="true"> <i class="fas fa-bookmark"></i> {{ 'savedJobProfiles' |
              translate }}</span>
          </li>
          <li class="nav-item tab-name cursor" style="border-right: 2px solid #EBEEF2;" (click)="activeTab = 1">
            <span class="nav-link" id="pills-industry-jobs-tab" data-toggle="pill" href="#pills-industry-jobs" role="tab"
              aria-controls="pills-industry-jobs" aria-selected="false"><i class="fas fa-table"></i> {{
              'industryJobProfiles' | translate }}</span>
          </li>
          <li class="nav-item tab-name cursor" (click)="activeTab = 2">
            <span class="nav-link" id="pills-create-job-tab" data-toggle="pill" href="#pills-create-job" role="tab"
              aria-controls="pills-create-job" aria-selected="false"><i class="fas fa-briefcase"></i> {{
              'createNewJobProfile' | translate }}</span>
          </li>
        </ul>

      </div>
    </div>

    <div class="card mt-2" [ngStyle]="{'background-color': activeTab == 0 ? 'rgba(43, 58, 75, 0.1)' : activeTab == 1 ? 'rgba(0, 0, 0, 0.02)' : 'white'}">
      <div class="card-body">
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="pills-saved-jobs" role="tabpanel" aria-labelledby="pills-saved-jobs-tab">
            <app-saved-job-profiles></app-saved-job-profiles>
          </div>
          <div class="tab-pane fade" id="pills-industry-jobs" role="tabpanel" aria-labelledby="pills-industry-jobs-tab">
            <app-industry-job-profiles></app-industry-job-profiles>
          </div>
          <div class="tab-pane fade" id="pills-create-job" role="tabpanel" aria-labelledby="pills-create-job-tab">
            <app-create-new-job-profile></app-create-new-job-profile>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="col-3">

  </div>
</div> -->
