

export enum NotificationTypes{
  JOB_CREATED= 'JOB_CREATED',
  JOB_UPDATED= 'JOB_UPDATED',
  JOB_STATUS_UPDATED= 'JOB_STATUS_UPDATED',
  APPLICATION_SUBMITTED= 'APPLICATION_SUBMITTED',
  APPLICATION_UPDATED= 'APPLICATION_UPDATED',
  APPLICATION_HIRED= 'APPLICATION_HIRED',
}
