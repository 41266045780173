<!-- <div class="container d-flex justify-content-center">
  <div class="card mt-5 p-3">
    <div class="media"> <img src="https://imgur.com/3UmlFEf.png" class="mr-3">
      <div class="media-body">
        <h6 class="mt-1 mb-0 h6-style">The candidate will be removed from your list. Are you sure?
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </h6>

        <div class="row">
          <button class="btn btn-next border" style="color: #323C47; border-radius: 13px">Discard</button>
        <button class="btn btn-next border" style="color: #323C47; border-radius: 13px">Delete</button>
        </div>

      </div>
    </div>
  </div>
</div> -->

<div class="modal fade" id="deletePopup" style="background-color: #000000b3;">
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header modal-md">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-2">
            <img src="./../../../assets/images/talentbase/delete-icon.png">
          </div>
          <div class="col-10">
            <div class="title-design">
              <p style="font-weight:700; color: #54595E;">It will be removed from your list. Are you sure?</p>
              <div class="row pt-2 pb-3 d-flex justify-content-start">
                <div class="mr-2">
                  <button type="button" class="btn discard-button" (click)="discard()" data-dismiss="modal">{{ 'discard'
                    | translate }}</button>
                </div>
                <div>
                  <button type="button" class="btn delete-button" (click)="delete()" data-dismiss="modal">{{ 'delete' |
                    translate }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
