import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { CreateAvatar } from 'src/app/shared/classes';
import { NotificationTypes } from 'src/app/shared/enums';
import { DropdownOptionsModel, JobModel } from 'src/app/shared/models';
import { ActivitiesService, JobService, TranslatePipe } from 'src/app/shared/services';

@Component({
  selector: 'app-dashboard-activity',
  templateUrl: './dashboard-activity.component.html',
  styleUrls: ['./dashboard-activity.component.scss']
})
export class DashboardActivityComponent implements OnInit {

  activities: any[] = [

  ];

  createAvatar: CreateAvatar;
  filterState: boolean;
  typeOptions: DropdownOptionsModel[] = [];
  typeLabel: string = '';
  selectedTypes: any[] = [];
  selectedTypesString: string = '';
  sortOptions: DropdownOptionsModel[] = [
    { label: this.translate.transform('newestFirst'), value: '&sort=createdAt,DESC' },
    { label: this.translate.transform('oldestFirst'), value: '&sort=createdAt,ASC' }
  ];
  sortText: string = '&sort=createdAt,DESC';
  sortLabel: string = this.translate.transform('newestFirst');
  selectedContent: any = null;

  constructor(private translate: TranslatePipe, private activityService: ActivitiesService,
    private jobService: JobService, private router: Router) {
    this.createAvatar = new CreateAvatar();
  }

  ngOnInit(): void {
    this.getActivities(1);
    this.getFilterOptions();
  }

  getActivities(pageNo) {

    this.activityService.ServerGetWithText(this.requestFilter() + this.sortText)
      .subscribe(x => {
        this.activities = x.content;
        this.activities.forEach(f => {
          f.imageText = this.createAvatar.create(this.translate.transform(f.type));
          f.imageColor = this.createAvatar.color();
          let time = Math.abs(new Date().getTime() - new Date(f.createdAt).getTime());
          f.postDate = Math.ceil(time / (1000 * 3600 * 24));
        });

      });
  }

  selectSort(data) {
    this.sortLabel = data.label;
    this.sortText = data.value;
    this.getActivities(1);
  }

  requestFilter() {
    return '?filter=type**' + this.selectedTypesString;
  }

  getFilterOptions() {
    this.selectedTypesString = '';

    this.typeLabel = '';

    this.typeOptions = Object.keys(NotificationTypes)
      .map(c => ({ value: c, label: c }));
  }

  selectType(datas) {
    this.selectedTypesString = '';
    for (const data of datas) {
      if (this.selectedTypesString === '') {
        this.selectedTypesString = data;
      } else {
        this.selectedTypesString = this.selectedTypesString + ',' + data;
      }
    }
    this.typeLabel = datas.length > 0 ? datas.length + ' selected' : 'type';
    this.selectedTypes = [...datas];
  }

  emitData() {

    this.filterState = false;
    this.getActivities(1);

  }

  clearFilterEmit() {
    this.selectedTypesString = '';
    this.getActivities(1);
    this.getFilterOptions();

  }

  selectedActivity(activity) {

    switch (activity.domainType) {
      case 'JOB':
        this.jobService.ServerGetWithText("/" + activity.contentId).subscribe(j=>{

        this.selectedContent = j;
        // @ts-ignore
        $('#jobDetailFromNotification').modal('show');
        });

        break;
      case 'APPLICATION':
        this.router.navigate(["/dashboard/applications/" + activity.contentId]);
        break;
      case 'EARNING':
        this.router.navigate(["/dashboard/payments/"]);
        break;
        case 'EMPLOYER ':

          break;
    }

  }

  OpenModalViewSalary(job: JobModel) {
    this.selectedContent = job;
    // @ts-ignore
    $('#viewSalaryModal').modal('show');
    // @ts-ignore
    $('#jobDetailFromNotification').modal('hide');
  }

  submitCandidate(job: JobModel) {
    this.selectedContent = job;

    // @ts-ignore
    $('#submitCandidateModal').modal('show');
  }

}
