import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {PracticeTime, RequisitionType} from 'src/app/shared/enums';
import {DropdownOptionsModel} from 'src/app/shared/models';
import {FunctionalRoleService, IndustryService} from 'src/app/shared/services';
import {RecruitersFilterServiceService} from '../recruiters-filter-service.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-recruiter-all-filters',
    templateUrl: './recruiter-all-filters.component.html',
    styleUrls: ['./recruiter-all-filters.component.scss']
})
export class RecruiterAllFiltersComponent implements OnInit, OnDestroy {

    recruitmentExperienceOptions: DropdownOptionsModel[] = [];
    recruitmentExperienceLabel: string = 'recruitmentExperience';
    requisitionTypeOptions: DropdownOptionsModel[] = [];
    requisitionTypeLabel: string = 'requisitionType';
    functionalRoleOptions: DropdownOptionsModel[] = [];
    functionalRoleLabel: string = 'functionalRole';
    industryOptions: DropdownOptionsModel[] = [];
    industryLabel: string = 'industry';
    filterData: any = {};
    filterText: string = '';
    filterState: boolean;

    selectedRecruitmentExperiences: any[] = [];
    selectedRequisitionType: any[] = [];
    selectedFunctionalRoles: any[] = [];
    selectedIndustries: any[] = [];

    @Output() resultFilter = new EventEmitter();
    @Output() clearFilter = new EventEmitter();

    @Output() resultSearch = new EventEmitter();

    clearFilterAndSortSubjectSubscription!: Subscription;

    constructor(private functionalRoleService: FunctionalRoleService, private industryService: IndustryService,
                private recruitersFilterServiceService: RecruitersFilterServiceService
    ) {
    }

    ngOnDestroy(): void {
        this.clearFilterAndSortSubjectSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.clearFilterAndSortSubjectSubscription = this.recruitersFilterServiceService.clearFilterAndSortSubject.subscribe(() => {
            this.getOptions();
        });
        this.getOptions();
        //@ts-ignore
        $(document).on('click', function(event) {
            //@ts-ignore
            var $trigger = $('#filer-dropdown');
            //@ts-ignore
            if ($trigger !== event.target && !$trigger.has(event.target).length && $('#filter-dropdown-menu').css('display') == 'block') {
                //@ts-ignore
                $('#filterButton').click();
            }
        });
    }

    getOptions() {
        this.filterData.individualPracticeTime = '';
        this.filterData.requisitionType = '';
        this.filterData.functionalRoleId = '';
        this.filterData.industryId = '';

        this.industryLabel = '';
        this.functionalRoleLabel = '';
        this.requisitionTypeLabel = '';
        this.recruitmentExperienceLabel = '';

        this.selectedFunctionalRoles = this.selectedIndustries = this.selectedRecruitmentExperiences = this.selectedRequisitionType = [];

        this.recruitmentExperienceOptions = Object.keys(PracticeTime).map(c => ({value: c, label: c}));

        this.requisitionTypeOptions = Object.keys(RequisitionType).map(c => ({value: c, label: c + 'REC'}));

        this.functionalRoleService.ServerGetWithoutLogin().subscribe(x => {
            this.functionalRoleOptions = x.content.map(c => ({value: c.id, label: c.defaultLocalization.name}));
        });

        this.industryService.ServerGetWithoutLogin().subscribe(x => {
            this.industryOptions = x.content.map(c => ({value: c.id, label: c.defaultLocalization.name}));
        });
    }

    selectExperience(datas) {
        this.filterData.individualPracticeTime = '';
        for (const data of datas) {
            if (this.filterData.individualPracticeTime === '') {
                this.filterData.individualPracticeTime = data;
            } else {
                this.filterData.individualPracticeTime = this.filterData.individualPracticeTime + ',' + data;
            }
        }
        this.recruitmentExperienceLabel = datas.length > 0 ? datas.length + ' selected' : 'recruitmentExperience';
        this.selectedRecruitmentExperiences = [...datas];
    }

    selectRequisition(datas) {
        this.filterData.requisitionType = '';
        for (const data of datas) {
            if (this.filterData.requisitionType === '') {
                this.filterData.requisitionType = data;
            } else {
                this.filterData.requisitionType = this.filterData.requisitionType + ',' + data;
            }
        }
        this.requisitionTypeLabel = datas.length > 0 ? datas.length + ' selected' : 'requisitionType';
        this.selectedRequisitionType = [...datas];
    }

    selectFunctionalRole(datas) {
        console.log(datas);

        this.filterData.functionalRoleId = '';
        for (const data of datas) {
            if (this.filterData.functionalRoleId === '') {
                this.filterData.functionalRoleId = data;
            } else {
                this.filterData.functionalRoleId = this.filterData.functionalRoleId + ',' + data;
            }
        }

        this.functionalRoleLabel = datas.length > 0 ? datas.length + ' selected' : 'functionalRole';
        this.selectedFunctionalRoles = [...datas];
    }

    selectIndustry(datas) {
        this.filterData.industryId = '';
        for (const data of datas) {
            if (this.filterData.industryId === '') {
                this.filterData.industryId = data;
            } else {
                this.filterData.industryId = this.filterData.industryId + ',' + data;
            }
        }

        this.industryLabel = datas.length > 0 ? datas.length + ' selected' : 'industry';
        this.selectedIndustries = [...datas];
    }

    emitData() {
        // this.filterData.jobTitle = this.filterType == "title" ? this.filterText : "";
        this.filterState = false;
        this.resultFilter.emit(this.filterData);

    }

    clearFilterEmit() {
        this.filterData.individualPracticeTime = '';
        this.filterData.requisitionType = '';
        this.filterData.functionalRoleId = '';
        this.filterData.industryId = '';

        this.getOptions();
        this.clearFilter.emit(this.filterData);
    }

    search() {
        this.resultSearch.emit(this.filterText);
    }

    keyup(data) {
        if (data == '') {
            this.resultSearch.emit(this.filterText);
        }
    }
}
