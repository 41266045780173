<div class="container-fluid px-sm-3 mt-4">
      <div class="row">
        <div class="col-sm-12 col-md-3 col-xs-3 col-lg-3 col-xl-3  ">
          <app-employer-dashboard-filter (setStatusFilter)="setStatusFilter($event)" (resultFilter)="getFilteredJobs($event)" (clearFilter)="getClearFilterJobs($event)" (resultSearch)="search($event)"></app-employer-dashboard-filter>

        </div>
        <div class="col-sm-12 col-md-9 col-xs-9  pl-sm-2   ">
          <tlb-empty-page-dashboard *ngIf="!jobs || jobs.length == 0" [warningLabel]="'No jobs yet.'"></tlb-empty-page-dashboard>
          <app-employer-dashboard-jobs *ngIf="jobs && jobs.length > 0" [jobs]="jobs" [count]="count" [limit]="3"
            (updateJobs)="getJobs($event)" (updatedStatus)="getJobs(1)"></app-employer-dashboard-jobs>
        </div>
      </div>

  </div>
<app-dashboard-news-modal *ngIf="this.new" [new]="this.new"></app-dashboard-news-modal>
