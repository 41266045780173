<div
  aria-hidden="true"
  aria-labelledby="exampleModalLabel"
  class="modal fade"
  id="complete-register-popup"
  role="dialog"
  style="background-color: #ffffff00"
  tabindex="-1"
>
  <div class="modal-dialog ml-auto  mr-2 mb-2"
       role="document"
       style="margin-top:60px !important; max-width: 464px !important;">
    <div class="modal-content p-2">
      <!-- <div class="modal-header modal-xl">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div> -->
      <div class="modal-body p-0 pr-2">
        <div class="container py-3">
          <div class="row">

            <div class="col-12 col-sm-3">
              <div
                class="c100 font-size-120 bg-blue position-absolute back-c100"
              >
                <div class="slice">
                  <div class="bar border-white"></div>
                  <div class="fill"></div>
                </div>
              </div>
              <div [class]="'p'+profileEnrichmentScore"
                   class="c100 font-size-120 bg-transparent front-c100">
                <span class="h-100">
                 <div class="container-fluid h-100">
                  <div class="row h-50">
                    <div class="col-12 mt-auto"
                         style="    margin-left: -1.5em;    line-height: 1em;"><span class="text-white"><b>{{profileEnrichmentScore}}
                      %</b></span></div>
                  </div>
                  <div class="row h-50">
                    <div class="col-12"
                         style="    margin-left: -1.5em;    line-height: 1em;">
                     <span class="text-white"
                           style="font-size: 14px;">{{'completed' |translate}}</span>
                    </div>
                  </div>
                </div>
                </span>

                <!--                <span class="text-white">25%
                                </span>
                                <span class="text-white" style="font-size: 5px;">completed</span>-->
                <div class="slice">
                  <div class="bar border-white"></div>
                  <div class="fill"></div>
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-9">
              <div class="row">
                <div class="col-12 text-white">
                  <h4 class="complete-profile-title">
                    {{ "completeYourProfile" | translate }}
                  </h4>
                </div>
                <div class="col-12 text-white">
                  <p class="complete-profile-p">
                    {{ "completeYourProfilePopupText" | translate }}
                  </p>
                </div>
              </div>
            </div>

          </div>
          <div class="row">
            <div class="col-12 "
                 style="display: flex; justify-content: center;border-radius: 36px;
    ">
              <button *ngIf="role == 'EMPLOYER_MANAGER' || role == 'EMPLOYER_USER'"
                      class="btn bg-white text-blue"
                      data-dismiss="modal"
                      routerLink="/dashboard/complete-employer-register/null">
                {{'complete' | translate}}
              </button>
              <button *ngIf="role == 'RECRUITER_MANAGER'|| role ==='RECRUITER_USER'"
                      class="btn bg-white text-blue"
                      data-dismiss="modal"
                      routerLink="/dashboard/complete-recruiter-register">
                {{ "complete" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-control gray-button w-25" data-dismiss="modal">

          {{'close'|translate}}
        </button>
      </div> -->
    </div>
  </div>
</div>
