<button (click)="closeMenu()"
        class="btn float-right d-xl-none">
  <img alt=""
       src="assets/images/icons/x.png">
</button>


<!-- recruiter -->
<app-sidebar-menu *ngIf="(role==='RECRUITER_MANAGER')||(role==='RECRUITER_USER')"
                  [menu]="menu.recruiter"></app-sidebar-menu>

<!-- employer -->
<app-sidebar-menu *ngIf="(role==='EMPLOYER_MANAGER')||(role==='EMPLOYER_USER')"
                  [menu]="menu.employer"></app-sidebar-menu>

<!-- admin -->
<app-sidebar-menu *ngIf="role==='ADMIN'"
                  [menu]="menu.admin"></app-sidebar-menu>


<!-- recruiter -->
<!--<ul class="list-group menu-container mt-xl-1 mt-md-1 mt-4" *ngIf="role=='RECRUITER_MANAGER11'">
  <li class="list-group-item py-2 pl-3 text-left ul-li" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/dashboard']" routerLinkActive="active" >
    <img src="./../../../../assets/images/talentbase/sidemenü-icon/dashboard-icon.png" style="">
    <span class="d-inline pl-4">{{'dashboard'|translate}}</span>
  </li>

  <li class="list-group-item has-sub py-2 pl-3" [routerLinkActiveOptions]="{exact: true}"  routerLinkActive="menu-active"  style="text-align: left;">
<div>
  <img src="./../../../../assets/images/talentbase/sidemenü-icon/candidates-icon.png">
  <span class="d-inline pl-3">{{'candidates'|translate}}</span> <i class="fas fa-caret-down float-right pr-4"></i>
</div>
    <ul class="list-group sub-menu hidden" [routerLinkActiveOptions]="{exact: true}"  routerLinkActive="shown">
      <li class="list-group-item list-group-item2 py-2 pl-3"  [routerLink]="['/candidate/track-candidate']" routerLinkActive="list-group-item2-active"><span class="ml-3">{{'trackCandidates'|translate}}</span></li>
      <li class="list-group-item list-group-item2 py-2 pl-3"  [routerLink]="['/candidate/track-application']" routerLinkActive="list-group-item2-active"><span class="ml-3">{{'trackApplications'|translate}}</span></li>
    </ul>
  </li>
  <li class="list-group-item has-sub  py-2 pl-3 text-left"   [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/dashboard/browse-jobs']"   routerLinkActive="active" >
    <img src="./../../../../assets/images/talentbase/sidemenü-icon/browse&#45;&#45;jobs-icon.png">
        <span class="d-inline pl-3">{{'jobs'|translate}}</span>
    <ul class="list-group sub-menu hidden" [routerLinkActiveOptions]="{exact: true}"  routerLinkActive="shown">
    </ul>
  </li>
  <li class="list-group-item py-2 pl-3 text-left" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/dashboard/talentbase-credits']" routerLinkActive="active">
    <img src="./../../../../assets/images/talentbase/sidemenü-icon/credits-icon.png">
        <span class="d-inline pl-3">{{'talentbaseCredits'|translate}}</span>
  </li>
  <li class="list-group-item py-2 pl-3 text-left" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/dashboard/earning']" routerLinkActive="active" >
    <img src="./../../../../assets/images/talentbase/sidemenü-icon/earnings-icon.png">
        <span class="d-inline pl-3">{{'earnings'|translate}}</span>
  </li>

  <li class="list-group-item py-2 pl-3 text-left" [routerLink]="['/support']" routerLinkActive="active">
        <img src="./../../../../assets/images/talentbase/sidemenü-icon/support-icon.png">
        <span class="d-inline pl-3">{{'support'|translate}}</span>
  </li>

  <li class="list-group-item  py-2 pl-3 "  [routerLinkActiveOptions]="{exact: true}"
      [routerLink]="['/dashboard/users/0']" routerLinkActive="active">
    <img src="./../../../../assets/images/talentbase/sidemenü-icon/user-icon.png">
    <span class="d-inline pl-3" style="margin-left: -2px">{{'users'|translate}}</span>
  </li>

</ul>-->
<!-- employer -->
<!--<ul class="list-group menu-container mt-1" *ngIf="role=='ADMIN'">
  <li class="list-group-item py-2 pl-3 text-left admin-li" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/dashboard']" routerLinkActive="active" >
    <i class="fas fa-home pr-3"></i>
    <span class="d-inline">{{'dashboard'|translate}}</span>
  </li>
  <li class="list-group-item  py-2 pl-3 admin-li" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/all-jobs']" routerLinkActive="active"  style="text-align: left;">
    <i class="fas fa-suitcase pr-3"></i>
    <span class="d-inline">{{'allJobs'|translate}}</span>
  </li>
  <li class="list-group-item   py-2 pl-3 text-left admin-li" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/all-recruiters']" routerLinkActive="active">
  <i class="fas fa-users pr-3"></i>
    <span class="d-inline">{{'allRecruiters'|translate}}</span>
  </li>
  <li class="list-group-item  py-2 pl-3 text-left admin-li"  [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/all-employers']" routerLinkActive="active">
    <i class="fas fa-user-friends  pr-3"></i>
    <span class="d-inline">{{'allEmployers'|translate}}</span>
  </li>
  <li class="list-group-item  py-2 pl-3 text-left admin-li"  [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/admin-faq']" routerLinkActive="active">
    <i class="fas fa-question-circle pr-3"></i>
    <span class="d-inline">{{'faqMenu'|translate}}</span>
  </li>
  <li class="list-group-item  py-2 pl-3 text-left admin-li"  [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/admin-job-profiles']" routerLinkActive="active">
    <i class="far fa-list-alt pr-3"></i>
    <span class="d-inline">{{'jobProfiles'|translate}}</span>
  </li>
  <li class="list-group-item  py-2 pl-3 text-left admin-li"  [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/admin-reviews']" routerLinkActive="active">
    <i class="far fa-comments pr-3"></i>
    <span class="d-inline">{{'recruiterReviews'|translate}}</span>
  </li>
  <li class="list-group-item  py-2 pl-3 text-left admin-li"  [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/admin-packages']" routerLinkActive="active">
    <i class="fas fa-money-bill pr-3"></i>
    <span class="d-inline">{{'packages'|translate}}</span>
  </li>
</ul>-->
