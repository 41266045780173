import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-complete-register-popup',
  templateUrl: './complete-register-popup.component.html',
  styleUrls: ['./complete-register-popup.component.scss']
})
export class CompleteRegisterPopupComponent implements OnInit {

  role: string = localStorage.getItem("role");
  @Input()profileEnrichmentScore:number;
  constructor() { }

  ngOnInit(): void {


  }

}
