import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {DropdownOptionsModel} from '../../../shared/models';
import {ApplicationStatusEnum} from '../../../shared/models/applications/application.model';
import {ApplicationService, CandidateService, JobService, RecruiterService} from '../../../shared/services';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-application-all-filters',
    templateUrl: './application-all-filters.component.html',
    styleUrls: ['./application-all-filters.component.scss']
})
export class ApplicationAllFiltersComponent implements OnInit, OnDestroy {

    statusOptions: DropdownOptionsModel[] = [];
    selectedStatuses: any[] = [];
    statusLabel = 'status';

    candidateOptions: DropdownOptionsModel[] = [];
    candidateLabel = 'candidate';
    candidateSearchText: any = {data: ''};

    recruiterOptions: DropdownOptionsModel[] = [];
    recruiterLabel = 'recruiter';
    recruiterSearchText: any = {data: ''};

    /*  employerOptions: DropdownOptionsModel[] = [];
      employerLabel = 'employer';
      employerSearchText: any = { data: '' };*/

    jobOptions: DropdownOptionsModel[] = [];
    jobLabel = 'job';
    jobSearchText: any = {data: ''};

    filterState: boolean;
    filterData: any = {};

    @Output() resultFilter = new EventEmitter();
    @Output() clearFilter = new EventEmitter();

    clearFilterAndSortSubjectSubscription !: Subscription;

    constructor(
        private candidateService: CandidateService,
        private recruiterService: RecruiterService,
        private applicationService: ApplicationService,
        /*    private employerService: EmployerService,*/
        private jobService: JobService
    ) {
    }

    ngOnInit(): void {
        this.clearFilterAndSortSubjectSubscription = this.applicationService.clearFilterAndSortSubject.subscribe(() => {
            this.clearFilterEmit();
            console.log('aa');
        });

        this.getOptions();
        // @ts-ignore
        $(document)
            .on('click', (event) => {
                // @ts-ignore
                const $trigger = $('#filer-dropdown');
                // @ts-ignore
                if ($trigger !== event.target && !$trigger.has(event.target).length && $('#filter-dropdown-menu')
                    .css('display') === 'block') {
                    // @ts-ignore
                    this.filterState = false;
                }
            });
    }

    getOptions(): void {
        this.filterData.status = '';

        this.statusLabel = '';
        this.selectedStatuses = [];
        this.statusOptions = Object.keys(ApplicationStatusEnum)
            .map(c => ({value: c, label: c}));

        this.getCandidates('?page=0&size=5');
        this.getRecruiters('?page=0&size=5');
        /*    this.getEmployers();*/
        this.getJobs('?page=0&size=5');
    }

    selectStatus(datas): void {
        let status = '';
        this.filterData.status = {};
        for (const data of datas) {
            if (status === '') {
                status = data;
            } else {
                status = status + ',' + data;
            }
        }

        this.filterData.status.data = status;
        this.filterData.status.name = 'status';
        this.filterData.status.type = 'any';

        this.statusLabel = datas.length > 0 ? datas.length + ' selected' : 'status';
        this.selectedStatuses = [...datas];
    }

    clearFilterEmit(): void {

        this.filterState = false;
        this.filterData.status = {};
        this.statusLabel = 'status';

        this.filterData.candidate = {};
        this.candidateLabel = 'candidate';
        this.candidateSearchText = {data: ''};

        this.filterData.recruiter = {};
        this.recruiterLabel = 'recruiter';
        this.recruiterSearchText = {data: ''};

        /*    this.filterData.employer = {};
            this.employerLabel = 'candidate';
            this.employerSearchText = { data: '' };*/

        this.filterData.job = {};
        this.jobLabel = 'job';
        this.jobSearchText = {data: ''};
        this.getOptions();
        this.clearFilter.emit(this.filterData);

    }

    emitData(): void {
        this.filterState = false;
        this.resultFilter.emit(this.filterData);

    }

    getCandidates(params: string = ''): void {
        this.candidateService.ServerGetWithText(params)
            .subscribe(x => {
                this.candidateOptions = x.content.map(c => ({value: c.id, label: c.fullName}));
            });
    }

    selectCandidate(data): void {
        this.filterData.candidate = {};
        this.filterData.candidate.data = data.value;
        this.filterData.candidate.name = 'candidate.id';
        this.filterData.candidate.type = 'equals';
        this.candidateLabel = data.label;
    }

    onSearchCandidate(data: string): void {
        if (data.length > 2) {
            this.getCandidates(`?filter=name~~${data}&page=0&size=10`);
        } else if (data.length === 0) {
            this.getCandidates();
        }
    }

    getRecruiters(params: string = ''): void {
        this.recruiterService.ServerGetWithText(params)
            .subscribe(x => {
                this.recruiterOptions = x.recruiterPagination.content.map(c => ({value: c.id, label: c.user.fullName}));
            });
    }

    selectRecruiter(data): void {
        this.filterData.recruiter = {};
        this.filterData.recruiter.data = data.value;
        this.filterData.recruiter.name = 'recruiterId';
        this.filterData.recruiter.type = 'equals';
        this.recruiterLabel = data.label;
    }

    onSearchRecruiter(data: string): void {
        if (data.length > 2) {
            this.getRecruiters(`?filter=name~~${data}&page=0&size=5`);
        } else if (data.length === 0) {
            this.getRecruiters();
        }
    }

    getJobs(params: string = ''): void {
        this.jobService.ServerGetWithText(params)
            .subscribe(x => {
                this.jobOptions = x.jobPagination.content.map(c => ({value: c.id, label: c.title}));
            });
    }

    selectJob(data): void {
        this.filterData.job = {};
        this.filterData.job.data = data.value;
        this.filterData.job.name = 'jobId';
        this.filterData.job.type = 'equals';
        this.jobLabel = data.label;
    }

    onSearchJob(data: string): void {
        if (data.length > 2) {
            this.getJobs(`?filter=title~~${data}&page=0&size=5`);
        } else if (data.length === 0) {
            this.getJobs();
        }
    }

    ngOnDestroy(): void {
        this.clearFilterAndSortSubjectSubscription.unsubscribe();
    }

}
