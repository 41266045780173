import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'tlb-multiselect-dropdown',
  templateUrl: './tlb-multiselect-dropdown.component.html',
  styleUrls: ['./tlb-multiselect-dropdown.component.scss']
})

export class TlbMultiselectDropdownComponent implements OnInit {

  @Input() dropdownName = 'Select';
  @Input() alertText = '';
  @Input() isFilter = true;
  @Input() defaultItemKey = 'id';
  @Input() dropDownMenuWidth = '10rem';
  @Input() items: any[] = [];
  @Input() existItems: any[] = [];
  existItemArray: any[] = [];
  @Input() textStyle:string = '';
  @Input() style:string = '';
  @Output() selectResult = new EventEmitter();
  @Output() clicked = new EventEmitter();

  selectedItems = [];
  selectedItemsView = [];
  storedItems: any[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  clickDropDown(){
    this.clicked.emit();
  }

  ngOnChanges(): void {
    this.storedItems = [...this.items];

    if (this.existItems) {
        if(this.defaultItemKey != ''){
            this.existItemArray = [];
            this.selectedItems = [];
            for (const existItem of this.existItems) {
                this.existItemArray.push(existItem[this.defaultItemKey]);
            }
            for (const storedItem of this.storedItems) {
                if (this.existItemArray.indexOf(storedItem.id) > -1) {
                    this.selectedItems.push(storedItem);
                }
                if (this.selectedItems.length >= this.existItemArray.length) {
                    break;
                }
            }
        }
        else{
            this.existItemArray = [];
            this.selectedItems = [];
            for (const existItem of this.existItems) {
                this.existItemArray.push(existItem);
            }
            for (const storedItem of this.storedItems) {
                if (this.existItemArray.some(x=> x == storedItem.value)) {
                    this.selectedItems.push(storedItem.value);
                }
                if (this.selectedItems.length >= this.existItemArray.length) {
                    break;
                }
            }

        }

    }
}

stopClose(event): void {
    event.stopPropagation();
}

selectData(event, item: any): void {

    if(this.defaultItemKey != ''){
        if (event.target.checked) {
            const index = this.selectedItems.indexOf(item);
            if (index <= -1) {
                this.selectedItems.push(item);
            }
        } else {
            const index = this.selectedItems.indexOf(item);
            if (index > -1) {
                this.selectedItems.splice(index, 1);
            }
        }
    }

    else{
    if (event.target.checked) {
        const index = this.selectedItems.indexOf(item.value);
        if (index <= -1) {
            this.selectedItems.push(item.value);
        }
    } else {
        const index = this.selectedItems.indexOf(item.value);
        if (index > -1) {
            this.selectedItems.splice(index, 1);
        }
    }

}

this.selectedItemsView = [...this.selectedItems];
this.selectResult.emit(this.selectedItemsView);
}


  search(data) {
    this.items = ((this.storedItems).filter((item: any) => ((item.label).toLowerCase().trim()).indexOf(data.toLowerCase().trim()) > -1));
}

}
