import { TranslatePipe } from './../../pipes/translate/translate.pipe';
import { NotificationService } from './../../services/notification/notification.service';
import { DropdownOptionsModel } from '../../models/tlb-form/dropdown-options.model';
import { ApplicationStatus } from './../../enums/application/application-status.enum';
import { ApplicationModel } from './../../models/candidate/application.model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApplicationService } from '../../services';

@Component({
  selector: 'app-candidate-update-status-popup',
  templateUrl: './candidate-update-status-popup.component.html',
  styleUrls: ['./candidate-update-status-popup.component.scss']
})
export class CandidateUpdateStatusPopupComponent implements OnInit {
  @Input() application: ApplicationModel;
  applicationStatus: DropdownOptionsModel[] = [];
  tempStatus: string;
  statusLabel: string;
  agreedSalaryAmount: number;

  @Output() updatedCandidateStatus = new EventEmitter();

  constructor(private applicationService: ApplicationService, private notification: NotificationService, private translate: TranslatePipe) { }

  ngOnInit(): void {
    this.applicationStatus = Object.keys(ApplicationStatus).map(c => ({ value: c, label: c }));

  }

  ngOnChanges() {
    this.tempStatus = this.application ? this.application.status : "";

    if (this.application && this.application.status == ApplicationStatus.APPROVED) {
      this.applicationStatus = [
        { value: ApplicationStatus.CLIENT_REVIEW, label: ApplicationStatus.CLIENT_REVIEW },
        { value: ApplicationStatus.MANAGER_REVIEW, label: ApplicationStatus.MANAGER_REVIEW },
        { value: ApplicationStatus.INTERVIEW_STAGE, label: ApplicationStatus.INTERVIEW_STAGE },
        { value: ApplicationStatus.OFFER_STAGE, label: ApplicationStatus.OFFER_STAGE },
        { value: ApplicationStatus.REJECTED_BY_EMPLOYER, label: ApplicationStatus.REJECTED_BY_EMPLOYER },
        { value: ApplicationStatus.CANCELLED, label: ApplicationStatus.CANCELLED }
      ];
    }

    else if (this.application && (this.application.status == ApplicationStatus.CLIENT_REVIEW || this.application.status == ApplicationStatus.MANAGER_REVIEW ||
      this.application.status == ApplicationStatus.INTERVIEW_STAGE || this.application.status == ApplicationStatus.OFFER_STAGE)) {
      this.applicationStatus = [
        { value: ApplicationStatus.CLIENT_REVIEW, label: ApplicationStatus.CLIENT_REVIEW, hide: this.application.status == ApplicationStatus.CLIENT_REVIEW },
        { value: ApplicationStatus.MANAGER_REVIEW, label: ApplicationStatus.MANAGER_REVIEW, hide: this.application.status == ApplicationStatus.MANAGER_REVIEW },
        { value: ApplicationStatus.INTERVIEW_STAGE, label: ApplicationStatus.INTERVIEW_STAGE, hide: this.application.status == ApplicationStatus.INTERVIEW_STAGE },
        { value: ApplicationStatus.OFFER_STAGE, label: ApplicationStatus.OFFER_STAGE, hide: this.application.status == ApplicationStatus.OFFER_STAGE },
        { value: ApplicationStatus.REJECTED_BY_EMPLOYER, label: ApplicationStatus.REJECTED_BY_EMPLOYER },
        { value: ApplicationStatus.HIRED, label: ApplicationStatus.HIRED },
        { value: ApplicationStatus.CANCELLED, label: ApplicationStatus.CANCELLED }
      ];
    }
  }

  changeApplicationStatus(data) {
    this.tempStatus = data.value;
    this.statusLabel = data.label;
  }
  sendStatusToServer() {
    if (this.tempStatus == "HIRED") {
      let model: any = {
        agreedSalaryAmount: this.agreedSalaryAmount.toString()
      }
      this.applicationService.ServerPostWithText('/' + this.application.id + '/hire', model).subscribe(x => {
        this.application.status = this.tempStatus;
        this.notification.success(this.translate.transform('theApplicationWasUpdatedSuccessfully'));
        this.updatedCandidateStatus.emit(x);

        // @ts-ignore
        $('#candidateUpdateStatusModal').modal('hide');
      });
    }
    else if (this.tempStatus == "REJECTED_BY_EMPLOYER") {
      this.applicationService.ServerPostWithText('/' + this.application.id + '/reject', null).subscribe(x => {
        this.notification.success(this.translate.transform('theApplicationWasUpdatedSuccessfully'));
        this.application.status = this.tempStatus;
        this.updatedCandidateStatus.emit(x);

        // @ts-ignore
        $('#candidateUpdateStatusModal').modal('hide');
      });
    }
    else {
      this.applicationService.ServerPostWithText('/' + this.application.id + '/progress', { status: this.tempStatus }).subscribe(x => {
        this.notification.success(this.translate.transform('theApplicationWasUpdatedSuccessfully'));
        this.application.status = this.tempStatus;
        this.updatedCandidateStatus.emit(x);

        // @ts-ignore
        $('#candidateUpdateStatusModal').modal('hide');
      });
    }

  }

}
