import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BillingInfoModel} from './billing-info.model';
import {environment} from '../../../../environments/environment';
import {finalize} from 'rxjs/operators';
import {NgxSpinnerService} from 'ngx-spinner';

@Injectable({
    providedIn: 'root'
})
export class BillingInfoService {

    TOKEN_KEY = 'access_token';
    lang = localStorage.getItem('lang');

    constructor(
        public http: HttpClient,
        protected spinner?: NgxSpinnerService,
    ) {

    }

    setHeadersWithToken(): HttpHeaders {
        const token = localStorage.getItem(this.TOKEN_KEY);
        let headersWithToken: HttpHeaders;
        headersWithToken = new HttpHeaders()
            .set('Accept', 'application/json')
            .set('Cache-Control', ' no-cache')
            .set('Content-Type', 'application/json')
            .set('Accept-Language', this.lang)
            .set('Authorization', 'Bearer ' + token);
        return headersWithToken;
    }

    getRecruiterBillingInfo(): Observable<BillingInfoModel> {
        this.spinner.show();
        const headers = this.setHeadersWithToken();
        const options = {headers};
        const url = `${environment.baseUrl}/recruiter/v1/recruiters/me/billing-addresses`;

        return this.http.get<BillingInfoModel>(url, options).pipe(
            finalize(() => {
                this.spinner.hide();
            })
        );
    }

    putRecruiterBillingInfo(data: BillingInfoModel): Observable<BillingInfoModel> {
        this.spinner.show();
        const headers = this.setHeadersWithToken();
        const options = {headers};
        const url = `${environment.baseUrl}/recruiter/v1/recruiters/me/billing-addresses`;

        return this.http.put<BillingInfoModel>(url, data, options).pipe(
            finalize(() => {
                this.spinner.hide();
            })
        );;
    }

    getEmployerBillingInfo(): Observable<BillingInfoModel> {
        this.spinner.show();
        const headers = this.setHeadersWithToken();
        const options = {headers};
        const url = `${environment.baseUrl}/employer/v1/employers/me/billing-addresses`;

        return this.http.get<BillingInfoModel>(url, options).pipe(
            finalize(() => {
                this.spinner.hide();
            })
        );;
    }

    putEmployerBillingInfo(data: BillingInfoModel): Observable<BillingInfoModel> {
        this.spinner.show();
        const headers = this.setHeadersWithToken();
        const options = {headers};
        const url = `${environment.baseUrl}/employer/v1/employers/me/billing-addresses`;

        return this.http.put<BillingInfoModel>(url, data, options).pipe(
            finalize(() => {
                this.spinner.hide();
            })
        )
    }
}
