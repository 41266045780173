import { PhoneNumberModel } from "../parameter";

export class InitialUserModel{
  firstName: string;
  lastName: string;
  phoneNumber: PhoneNumberModel;
  email: string;
  password: string;
  preferredLanguage: string;

  constructor() {
    this.firstName = "";
    this.lastName = "";
    this.phoneNumber = new PhoneNumberModel();
    this.email = "";
    this.password = "";
    this.preferredLanguage = "";

  }
  }
