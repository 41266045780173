import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormType } from 'src/app/shared/enums';
import { TlbFormModel } from 'src/app/shared/models';
import { FaqItemsService, NotificationService, server, TranslatePipe } from 'src/app/shared/services';

@Component({
  selector: 'app-admin-add-edit-modal',
  templateUrl: './admin-add-edit-modal.component.html',
  styleUrls: ['./admin-add-edit-modal.component.scss']
})
export class AdminAddEditModalComponent implements OnInit {

  @Input() selectedItem: any = null;
  @Input() itemList: any[] = [];
  @Input() category: any = {};
  @Output() updateOrSaveClick = new EventEmitter();
  model: any = {};
  formModel: TlbFormModel[] = [];
  formGroup: FormGroup;
  defaultModel: any = {};

  constructor(private faqItemsService: FaqItemsService, private notification: NotificationService, private translate: TranslatePipe) { }

  ngOnInit(): void {
  }

  ngOnChanges() {

    if (this.selectedItem && this.selectedItem != null) {

      let category = {
        id: this.selectedItem.category.id
      }
      let order = this.itemList.indexOf(this.selectedItem);
      this.model = {
        id: this.selectedItem.id,
        category: category,
        question: this.selectedItem.question,
        answer: this.selectedItem.answer,
        screenOrder: order
      };

      this.defaultModel = Object.assign({}, this.model);
      this.createForm();
    }

    else {
      let category = {
        id: this.category.id
      }
      let order = this.itemList.length;
      this.model = {
        category: category,
        question: "",
        answer: "",
        screenOrder: order
      };

      this.createForm();
    }
  }


    disableControl(): boolean {
      if(this.selectedItem && this.selectedItem != null ) return JSON.stringify(this.model) == JSON.stringify(this.defaultModel);
      else return this.model.question == '' || this.model.answer == ''
  }

  createForm() {

    let s = this.selectedItem ? this.selectedItem : null;

    this.formModel = [{
      class: 'col-12', components: [
        { label: 'question', control: 'question', required: true, type: FormType.text },
        { label: 'answer', control: 'answer', required: true, type: FormType.textArea }
      ]
    }];

    this.formGroup = new FormGroup({
      question: new FormControl(s ? s.question : '', Validators.required),
      answer: new FormControl(s ? s.answer : '', Validators.required)
    });
  }

  get form() { return this.formGroup.controls }

  updateOrSave() {

    this.model.question = this.form.question.value;
    this.model.answer = this.form.answer.value;

    if (this.selectedItem && this.selectedItem != null) {

      this.faqItemsService.ServerPut(server.faqItems + "/" + this.selectedItem.id, this.model)
        .subscribe(x => {
          this.updateOrSaveClick.emit();
          this.notification.success(this.translate.transform('updateSuccessful'));

          // @ts-ignore
          $('#editFaqItemModal').modal('hide');
        });
    }

    else {

      this.faqItemsService.ServerPost(this.model)
        .subscribe(x => {
          this.updateOrSaveClick.emit();
          this.notification.success(this.translate.transform('createSuccessful'));

          // @ts-ignore
          $('#editFaqItemModal').modal('hide');
        });
    }
  }

}
