export class CreditsModel {

  id: string;
  action: string;
  amount: number;
  balance: number;
  createdAt: string;
  // Don't exist in the database
  date: string;

  constructor() {
    this.id = '';
    this.action = '';
    this.amount = 0;
    this.balance = 0;
    this.createdAt = '';
    this.date = '';
  }
}
