import { Component, OnInit } from '@angular/core';
import { EmployerCountsModel, JobCountsModel, RecruiterCountsModel } from 'src/app/shared/models';
import { EmployerCountService, JobsCountService, RecruiterCountService } from 'src/app/shared/services';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {

  jobsCount: number = 0;
  employerCount: number = 0;
  closedJobCount: number = 0;
  recruitersCount: number = 0;

  constructor(private jobCountService: JobsCountService, private employerCountService: EmployerCountService, private recruiterCountService: RecruiterCountService) { }

  ngOnInit(): void {
    this.getCounts();
  }

  getCounts() {
    this.jobCountService.ServerGet().subscribe(x => {
      let jobCounts: JobCountsModel = x;
      this.jobsCount = jobCounts.totalJobs;
      this.closedJobCount = jobCounts.closedJobs;
    });

    this.recruiterCountService.ServerGet().subscribe(x => {
      let recruiterCount: RecruiterCountsModel = x;
      this.recruitersCount = recruiterCount.totalRecruiters;
    });

    this.employerCountService.ServerGet().subscribe(x => {
      let employerCounts: EmployerCountsModel = x;
      this.employerCount = employerCounts.totalEmployers;
    });
  }
}




