<div *ngIf="!fromRelated" class="row">
  <div *ngIf="descriptionShow" class="showing-text mt-4">
    {{ 'showing' | translate }} {{ jobs.length }} {{ 'resultOfJobs' | translate }}
  </div>

  <div class="ml-auto m-2 pt-3" style="    display: flex;flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: center;
">
    <div *ngIf="descriptionShow" class="mr-4 mt-1">
      <i (click)="gridOrList('grid')" [style.color]="gridArray ? '#3652E3' : '#999FA9'" class="fas fa-th-large"></i>
      <i (click)="gridOrList('list')" [style.color]="!gridArray ? '#3652E3' : '#999FA9'"
        class="fas fa-list-ul ml-3"></i>
    </div>
    <span class="sort-by-text">{{ 'sortBy' | translate }}:</span>

    <div>

      <tlb-dropdown (searchData)="selectSort($event)" [dropDownMenuWidth]="'16rem'"
        [dropdownName]="sortLabel != '' ? sortLabel : 'selectSort'" [isFilter]="false" [items]="sortOptions"
        [style]="'background-color:#F5F6F8; border: none; box-sizing: border-box; width: 117px; height: 35px'"
        [textStyle]="'font-style: normal; font-weight: normal; font-size: 14px; color: #252B42; margin-top: 3px;'">
      </tlb-dropdown>
    </div>
  </div>
</div>
<!-- list -->
<div *ngIf="!gridArray">
  <div
    *ngFor="let job of jobs | paginate:{ itemsPerPage: requestPayload.limit, currentPage: currentPage,totalItems:count}"
    class="row mt-3">
    <div class="col-12 pr-1 pl-0">
      <div class="card" style="border: none;">
        <div class="card-body">
          <div class="container-fluid mt-4 mb-4">

            <div class="row">
              <div class="col-12 col-xl-8 col-xxl-9">
                <div style="    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: baseline;">
                  <div class="mr-2">
                    <img [src]="job.logoUrl && job.logoUrll != '' ? job.logoUrl : './assets/images/default-logo.png'"
                      alt="" width="45">
                  </div>
                  <div>
                    <div class="row mt-2 emp-name-text">

                      <div class="col-12"> {{job.employer.name}}<img *ngIf="job.favorite && descriptionShow"
                          class="ml-4" src="./assets/images/my-worklist.png"></div>

                    </div>
                  </div>
                </div>

                <div class="row mt-2 ml-2">

                </div>

                <div class="row mt-1 desc-text">
                  <div class="col-12">

                    <span class="title-text">{{job.title}}</span> <br>

                    <div *ngIf="job.recommended" class="mt-2" style="display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-content: flex-start;
                    justify-content: flex-start;
                    align-items: flex-start;">
                      <img src="../../../assets/images/recomended.png">
                      <span class="recomended-text ml-1">{{'recomendedByTalentbase' | translate}}</span>
                    </div>

                    <span *ngIf="!descriptionShow">{{job.description ? job.description.substring(0, 200) : ''}}
                      {{job.description && job.description.length > 200 ? '...' : ''}} </span><br>

                    <div class="mt-2 row">
                      <div class="col-6">
                        <button (click)="openfullJobDescriptionModal(job)" class="btn view-application-btn">
                          {{'fullJobDescription' | translate }}
                        </button>
                      </div>


                      <div class="col-6">
                        <button (click)="openRelatedJobsModal(job)" class="btn gray-button">
                          {{'wiewRelatedJobs' | translate }}
                        </button>
                      </div>

                    </div>
                  </div>
                  <div class="col-12">


                  </div>
                </div>
              </div>

              <div class="col-12 col-xl-4 col-xxl-3 text-center left-border2">
                <div class="row mt-2">
                  <div *ngIf="job.fee?.type == 'PERCENT'" class="col-12">
                    <div class="base-salary-button">
                      <span>{{job.fee?.percent}}
                        %</span><span> {{ 'ofBaseSalary' | translate }}</span>
                    </div>

                  </div>
                  <div *ngIf="job.fee?.type == 'FIXED'" class="col-12">
                    <div class="base-salary-button">
                      <span>{{job.fee?.amount}}
                        €</span><span> {{ 'fixedAmount' | translate }}</span>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12 p-0 view-salary-button" style="display: flex; justify-content: center;">
                    <a (click)="OpenModalViewSalary(job)">
                      <span>{{ 'viewSalary' | translate }}</span>
                    </a>
                  </div>
                </div>
                <div class="mt-4" style="display: flex; justify-content: center;">
                  <div class="guarantee-text">
                    <i class="far fa-calendar-minus"></i>
                    <span class="ml-2">
                      90 {{'days' | translate }} {{ 'guaranteePeriod' | translate }}
                    </span>

                  </div>
                </div>
              </div>

            </div>

            <div *ngIf="!descriptionShow && !fromRelated" class="row">
              <div class="container-fluid m-0 p-0">
                <div class="mt-5 card-container">

                  <div class="position-relative card-shadow job-status-card" style="">
                    <div class=" h-100" style="padding: 16px 4px;">
                      <span [ngClass]="job.rejected > 0 ? 'status-number1' : 'status-number2'">{{job.rejected}}</span>
                      <span class="d-inline-block status-text">{{ 'rejected' | translate }}</span>

                    </div>
                  </div>
                  <div class="position-relative card-shadow job-status-card">
                    <div class="h-100">
                      <span
                        [ngClass]="job.openPosition > 0 ? 'status-number1' : 'status-number2'">{{job.openPosition}}</span>
                      <span class="d-inline-block status-text ">{{ 'openPosition' | translate }}</span>

                    </div>
                  </div>
                  <div class="position-relative card-shadow job-status-card">
                    <div class=" h-100">
                      <span
                        [ngClass]="job.managerReview > 0 ? 'status-number1' : 'status-number2'">{{job.managerReview}}</span>
                      <span class="d-inline-block status-text ">{{ 'hiringManagerReview' | translate }}</span>

                    </div>
                  </div>
                  <div class="position-relative card-shadow job-status-card">
                    <div class=" h-100">
                      <span
                        [ngClass]="job.offerStage > 0 ? 'status-number1' : 'status-number2'">{{job.offerStage}}</span>
                      <span class="d-inline-block status-text ">{{ 'offerStage' | translate }}</span>

                    </div>
                  </div>
                  <div class="position-relative card-shadow job-status-card">
                    <div class="h-100">
                      <span
                        [ngClass]="job.clientReview > 0 ? 'status-number1' : 'status-number2'">{{job.clientReview}}</span>
                      <span class="d-inline-block status-text ">{{ 'underClientReview' | translate }}</span>

                    </div>
                  </div>
                  <div class="position-relative card-shadow job-status-card">
                    <div class=" h-100">
                      <span
                        [ngClass]="job.interviewStage > 0 ? 'status-number1' : 'status-number2'">{{job.interviewStage}}</span>
                      <span class="d-inline-block status-text"> {{ 'interviewStage' | translate }}</span>

                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="row mt-2" *ngIf="descriptionShow">

              <div class="col-12">{{job.description ? job.description.substring(0, 200) : ''}} {{job.description &&
                job.description.length > 200 ? '...' : ''}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- grid -->

<div *ngIf="gridArray">
  <div
    *ngFor="let grids of gridJobs  | paginate:{ itemsPerPage: requestPayload.limit, currentPage: currentPage,totalItems:count}"
    class="row mt-3">
    <div *ngFor="let job of grids" class="col-6">
      <div class="card" style="border: none; height: 100%;">
        <div class="card-body">
          <div class="col-12"><img
              [src]="job.logoUrl && job.logoUrll != '' ? job.logoUrl : './assets/images/default-logo.png'" alt=""
              width="55">
            <img *ngIf="job.favorite && descriptionShow" class="mt-2" src="./assets/images/my-worklist.png"
              style="float: right;">
          </div>

          <div class="col-12">{{job.title}} </div>
          <div class="col-12">
            <div class="emp-name-text">{{job.employer.name}}
            </div>
          </div>

          <div *ngIf="job.recommended" class="mt-2" style="display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-content: flex-start;
          justify-content: flex-start;
          align-items: flex-start;">
            <img src="../../../assets/images/recomended.png">
            <span class="recomended-text ml-1">{{'recomendedByTalentbase' | translate}}</span>
          </div>

          <div class="row mt-3 ml-1">
            <div class="col-12">{{job.description ? job.description.substring(0, 20) : ''}} {{job.description &&
              job.description.length > 20 ? '...' : ''}}</div>
          </div>

          <div class="row ml-3 mt-3">
            <div>
              <span class="badge">
                <span class="badge-text">{{ job.type | translate}}s</span> </span>
            </div>

            <div class="ml-3">
              <span class="badge">
                <span class="badge-text">{{ job.experienceLevel | translate}}s</span> </span>
            </div>

          </div>

          <div class="col-12 mt-3 text-center ">
            <div class="row mt-2">
              <div *ngIf="job.fee?.type == 'PERCENT'" class="col-12">
                <div class="base-salary-button">
                  <span>{{job.fee?.percent}}%</span><span> {{ 'ofBaseSalary' | translate }}</span>
                </div>

              </div>
              <div *ngIf="job.fee?.type == 'FIXED'" class="col-12">
                <div class="base-salary-button">
                  <span>{{job.fee?.amount}} €</span><span> {{ 'fixedAmount' | translate }}</span>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12 p-0 view-salary-button" style="display: flex; justify-content: center;">
                <a (click)="OpenModalViewSalary(job)">
                  <span>{{ 'viewSalary' | translate }}</span>
                </a>
              </div>
            </div>
            <div class="mt-4">
              <div class="guarantee-text">
                <i class="far fa-calendar-minus mr-2"></i>
                <span>
                  90 {{'days' | translate }} {{ 'guaranteePeriod' | translate }}
                </span>

              </div>
            </div>

            <div *ngIf="!fromRelated" class="mt-4 row">
              <div class="col-6">
                <button (click)="openfullJobDescriptionModal(job)" class="btn view-application-btn">
                  {{'fullJobDescription' | translate }}
                </button>
              </div>


              <div class="col-6">
                <button (click)="openRelatedJobsModal(job)" class="btn gray-button">
                  {{'wiewRelatedJobs' | translate }}
                </button>
              </div>

            </div>
          </div>


        </div>
      </div>

    </div>
  </div>
</div>


<div *ngIf="!fromRelated" class="row mt-3 pagination mr-3 mr-sm-5">
  <pagination-controls (pageChange)="currentPage = $event;pagination(currentPage)"
    [autoHide]="count <= requestPayload.limit" nextLabel="{{'next' | translate}}"
    previousLabel="{{'previous' | translate}}" responsive="true"></pagination-controls>
</div>

<app-dashboard-view-salary-modal [modalId]="'viewSalaryModalsFromRec'"  [selectedJobs]="selectedJob"></app-dashboard-view-salary-modal>
<app-view-salary-modal [modalId]="'viewSalaryModalFromRec'" [detailModalId]="'viewSalaryModalsFromRec'" [selectedJobs]="selectedJob"></app-view-salary-modal>
<app-submit-candidate-modal [dropName]="''" [job]="selectedJob"></app-submit-candidate-modal>
<!--<app-full-job-description-modal></app-full-job-description-modal>-->
<app-employer-job-detail-popup (submitCandidateModalEmitter)="submitCandidate(selectedJob)"
  (viewSalaryEmitter)="OpenModalViewSalary(selectedJob)" [job]="selectedJob" [modalId]="'jobDetailFromRecruiter'">
</app-employer-job-detail-popup>


<app-related-jobs-modal [jobs]="relatedJobs"></app-related-jobs-modal>
