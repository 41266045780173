<div class="container-fluid d-block position-relative pb-2">
  <div class="row mt-2">
    <div class="col-6 feed-top-title text-left cursor-pointer">
      {{'notifications' | translate | titlecase}}
    </div>
    <div (click)="markAllAsRead()"
         class="col-6 feed-top-title text-right cursor-pointer">
      {{'markAllAsRead' | translate | firstLetterCapital}}
    </div>
  </div>

  <div class="row"
       style="    max-height: 70vh;
    overflow: auto;
">
    <div class="col-12">
      <div *ngFor="let activity of activities"
           class="row mt-3 text-left border-bottom">
        <div class="col-12 position-relative" (click)="selectedActivity(activity)">
      <span *ngIf="!activity.read"
            class="feed-unread-span"></span>
          <div class="row pt-1">
            <div class="px-2 w-20">
              <div [style.background-color]="activity.imageColor"
                   id="preimage"><span style="color: white;">{{activity.imageText}}</span></div>
            </div>
            <div class="pr-2 w-80 feed-text">
              {{activity.type | translate}}: {{activity.content}}
            </div>
          </div>
          <div class="row mt-1 mb-3">
            <div class="w-80 ml-auto text-left feed-top-title fw-500">
              {{activity.createdAt | date}}
            </div>

          </div>
        </div>
      </div>
      <tlb-empty-page *ngIf="!activities || activities.length == 0"
                      [containerStyle]="''"
                      [small]="true"
                      [warningLabel]="'noNotificationYet' |translate"></tlb-empty-page>
    </div>
  </div>
  <div *ngIf="activities && activities.length > 0"
       class="all-notifications"
       routerLink="/activity">{{'allNotifications' | translate | titlecase}}</div>



</div>


