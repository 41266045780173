import {GeneralIdNameModel} from '../../models';

export class BillingInfoModel {
    line1: string;
    line2: string;
    zipCode: string;
    country: GeneralIdNameModel;
    city: GeneralIdNameModel;

    constructor() {
        this.line1 = '';
        this.line2 = '';
        this.zipCode = '';
        this.country = new GeneralIdNameModel();
        this.city = new GeneralIdNameModel();
    }
}
