import {CompleteRegisterComponent} from './components/landing-page/sing-up/complete-register/complete-register.component';
import {SingUpComponent} from './components/landing-page/sing-up/sing-up.component';
import {LandingLayoutComponent} from './shared/layouts/landing-layout/landing-layout.component';
import {SupportComponent} from './components/support/support.component';
import {RecruiterGuard} from './shared/services/auth/guards/recruiter.guard';
import {JobsAppliedComponent} from './components/job/jobs-applied/jobs-applied.component';
import {SearchJobsComponent} from './components/job/search-jobs/search-jobs.component';
import {TrackApplicationsComponent} from './components/candidate/track-applications/track-applications.component';
import {TrackCandidatesComponent} from './components/candidate/track-candidates/track-candidates.component';
import {AddNewCandidateComponent} from './components/candidate/add-new-candidate/add-new-candidate.component';
import {CandidateLayoutComponent} from './shared/layouts/candidate-layout/candidate-layout.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginLayoutComponent} from './shared/layouts/login-layout/login-layout.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {DashboardLayoutComponent} from './shared/layouts/dashboard-layout/dashboard-layout.component';
import {LoginComponent} from './components/general-user-screens/login/login.component';
import {AuthGuard} from './shared/services/auth/guards/auth.guard';
import {EmployerGuard} from './shared/services/auth/guards/employer.guard';
import {LoginGuard} from './shared/services/auth/guards/login.guard';
import {MailVerifyComponent} from './components/general-user-screens/mail-verify/mail-verify.component';
import {MyJobsComponent} from './components/job/my-jobs/my-jobs.component';
import {UsersComponent} from './components/users/users.component';
import {SupportPageComponent} from './components/support-page/support-page.component';
import {EmailPreferencesComponent} from './components/recruiter-menu-settings/email-preferences/email-preferences.component';
import {EarningPageComponent} from './components/earning-page/earning-page.component';
import {ResetPasswordLoginComponent} from './components/general-user-screens/reset-password-login/reset-password-login.component';
import {AdminGuard} from './shared/services/auth/guards/admin.guard';
import {AdminAllRecruitersComponent} from './components/admin-screens/admin-all-recruiters/admin-all-recruiters.component';
import {AdminAllEmployersComponent} from './components/admin-screens/admin-all-employers/admin-all-employers.component';
import {RecruiterEditProfileComponent} from './components/recruiter-menu-settings/recruiter-edit-profile/recruiter-edit-profile.component';
import {
  RecruiterEditBasicInfoComponent
} from './components/recruiter-menu-settings/recruiter-edit-basic-info/recruiter-edit-basic-info.component';
import {ResetPasswordComponent} from './components/general-user-screens/reset-password/reset-password.component';
import {ApprovalEmailPageComponent} from './components/candidate-email-screens/approval-email-page/approval-email-page.component';
import {
  CandidateReviewScreenComponent
} from './components/candidate-email-screens/candidate-review-screen/candidate-review-screen.component';
import {SuccessfullySavedComponent} from './components/successfully-saved/successfully-saved.component';
import {AdminFaqComponent} from './components/admin-screens/admin-faq/admin-faq.component';
import {LandingPageComponent} from './components/landing-page/landing-page.component';
import {PostAJobComponent} from './components/job/post-a-job/post-a-job.component';
import {PricingComponent} from './components/landing-page/pricing/pricing.component';
import {MarketplaceComponent} from './components/marketplace/marketplace.component';
import {
  ComplateRecruiterRegisterComponent
} from './components/landing-page/sing-up/complete-register/complate-recruiter-register/complate-recruiter-register.component';
import {AdminJobProfilesComponent} from './components/admin-screens/admin-job-profiles/admin-job-profiles.component';
import {LeaderboardComponent} from './components/leaderboard/leaderboard.component';
import {CreateJobProfileComponent} from './components/job/create-job-profile/create-job-profile.component';
import {BillingComponent} from './components/billing/billing.component';
import {CreditCardComponent} from './components/landing-page/credit-card/credit-card.component';
import {DashboardActivityComponent} from './components/dashboard-activity/dashboard-activity.component';
import {BrowseJobsComponent} from './components/browse-jobs/browse-jobs.component';
import {EditProfileComponent} from './components/edit-profile/edit-profile.component';
import {TalentbaseCreditsComponent} from './components/talentbase-credits/talentbase-credits.component';
import {AdminReviewsComponent} from './components/admin-screens/admin-reviews/admin-reviews.component';
import {CheckoutComponent} from './components/dashboard/checkout/checkout.component';
import {FinishPaymentComponent} from './components/finish-payment/finish-payment.component';
import {RecruiterDetailsComponent} from './components/marketplace/recruiter-details/recruiter-details.component';
import {AdminPacketsComponent} from './components/admin-screens/admin-packets/admin-packets.component';
import {PaymentsComponent} from './components/payments/payments.component';
import {PaymentPopupComponent} from './components/payments/payment-popup/payment-popup.component';
import {BaseLayoutComponent} from './shared/layouts/base-layout/base-layout.component';
import {ApplicationsComponent} from './components/applications/applications.component';
import {ApplicationDetailComponent} from './components/applications/application-detail/application-detail.component';
import {AdminAllJobsComponent} from './components/admin-screens/admin-all-jobs/admin-all-jobs.component';

const routes: Routes = [
  {
    path: '', component: LandingLayoutComponent,
    children: [
      {
        path: '', component: LandingPageComponent
      }
    ]
  },
  {
    path: '', component: LoginLayoutComponent,
    children: [
      {
        path: 'credit-card',
        component: CreditCardComponent
      },
      {
        path: 'pricing',
        component: PricingComponent
      },
      {
        path: 'billing',
        component: BillingComponent
      }
    ]
  },
  {
    path: '', component: LoginLayoutComponent, canActivate: [LoginGuard],
    children: [
      {
        path: 'login', component: LoginComponent
      },
      {
        path: 'sign-up',
        component: SingUpComponent
      },
      {
        path: 'verify-email',
        component: MailVerifyComponent
      },
      {
        path: 'password-reset',
        component: ResetPasswordComponent
      },
      {
        path: 'successfully-saved',
        component: SuccessfullySavedComponent
      },
      {
        path: 'password-reset-login',
        component: ResetPasswordLoginComponent
      }

    ]
  },
  {
    path: '', component: DashboardLayoutComponent, canActivate: [AuthGuard],
    children: [
      {
        path: 'candidate', component: CandidateLayoutComponent,
        children: [
          {
            path: 'add-new', component: AddNewCandidateComponent, canActivate: [RecruiterGuard]
          },
          {
            path: 'track-candidate', component: TrackCandidatesComponent, canActivate: [RecruiterGuard]
          },
          {
            path: 'track-application', component: TrackApplicationsComponent, canActivate: [RecruiterGuard]
          }
        ]
      },
      {
        path: 'support',
        component: SupportComponent
      },
      {
        path: 'recruiters',
        component: MarketplaceComponent, canActivate: [EmployerGuard]
      },
      {
        path: 'recruiter-details/:recruiterId/:isRecommended',
        component: RecruiterDetailsComponent, canActivate: [EmployerGuard]
      },
      {
        path: 'all-jobs',
        component: AdminAllJobsComponent, canActivate: [AdminGuard]
      },
      {
        path: 'all-recruiters',
        component: AdminAllRecruitersComponent, canActivate: [AdminGuard]
      },
      {
        path: 'all-employers',
        component: AdminAllEmployersComponent, canActivate: [AdminGuard]
      },
      {
        path: 'admin-faq',
        component: AdminFaqComponent, canActivate: [AdminGuard]
      },
      {
        path: 'admin-reviews',
        component: AdminReviewsComponent, canActivate: [AdminGuard]
      },
      {
        path: 'admin-packages',
        component: AdminPacketsComponent, canActivate: [AdminGuard]
      },
      {
        path: 'admin-job-profiles',
        component: AdminJobProfilesComponent, canActivate: [AdminGuard]
      },
      {
        path: 'activity',
        component: DashboardActivityComponent
      },
      {
        path: 'jobs', component: BaseLayoutComponent,
        children: [
          {
            path: 'search-jobs', component: SearchJobsComponent, canActivate: [RecruiterGuard]
          },
          {
            path: 'jobs-applied', component: JobsAppliedComponent, canActivate: [RecruiterGuard]
          }
        ]
      }
    ]
  },
  {
    path: 'dashboard', component: DashboardLayoutComponent, canActivate: [AuthGuard],
    children: [
      {
        path: '', component: DashboardComponent
      },
      {
        path: 'applications', component: ApplicationsComponent
      },
      {
        path: 'applications/:id', component: ApplicationDetailComponent
      },
      {
        path: 'pricing',
        component: PricingComponent
      },
      {
        path: 'checkout-form/:packetId',
        component: CheckoutComponent
      },
      {
        path: 'checkout-form',
        component: CheckoutComponent
      },
      {
        path: 'complete-employer-register/:packageId',
        component: CompleteRegisterComponent
      },
      {
        path: 'complete-recruiter-register',
        component: ComplateRecruiterRegisterComponent
      },
      {
        path: 'my-jobs', component: MyJobsComponent
      },
      {
        path: 'leaderboard', component: LeaderboardComponent
      },
      {
        path: 'create-job-profile', component: CreateJobProfileComponent
      },
      {
        path: 'edit-profile', component: EditProfileComponent
      },
      {
        path: 'recruiter-edit-profile', component: RecruiterEditProfileComponent
      },
      {
        path: 'email-preferences', component: EmailPreferencesComponent
      },
      {
        path: 'edit-basic-info', component: RecruiterEditBasicInfoComponent
      },
      {
        path: 'users/:isEmployer', component: UsersComponent
      },
      {
        path: 'tools', component: SupportPageComponent
      },
      {
        path: 'marketplace', component: MarketplaceComponent
      },
      {
        path: 'talentbase-credits', component: TalentbaseCreditsComponent
      },
      {
        path: 'browse-jobs', component: BrowseJobsComponent
      },
      {
        path: 'earning', component: EarningPageComponent
      },
      {
        path: 'candidate', component: CandidateLayoutComponent, children: [
          {
            path: 'add-new', component: AddNewCandidateComponent
          }
        ]
      },
      {
        path: 'applications/:id/review', component: CandidateReviewScreenComponent, canActivate: [EmployerGuard]
      },
      {
        path: 'postAJob/:id', component: PostAJobComponent, canActivate: [EmployerGuard]
      },
      {
        path: 'payments',
        component: PaymentsComponent
      },
      {
        path: 'test',
        component: PaymentPopupComponent
      },
      {
        path: '**', redirectTo: '/dashboard'
      }
    ]
  },
  {
    path: 'applications/:id/decision', component: ApprovalEmailPageComponent
  },
  {
    path: 'applications/:id/review', component: CandidateReviewScreenComponent
  },
  {
    path: 'finish-payment',
    component: FinishPaymentComponent
  },
  {
    path: 'finish-earning-payment',
    redirectTo: '/dashboard/payments'
  },
  {
    path: '**', redirectTo: '/'
  }
];

@NgModule({
            imports: [RouterModule.forRoot(routes)],
            exports: [RouterModule]
          })
export class AppRoutingModule {
}
