<!-- Modal -->
<div class="modal fade bd-example-modal-xl" id="editFaqItemModal" >
  <div class="modal-dialog modal-xl modal-dialog-centered">
    <div class="modal-content">
      <h5 style="margin-left: 7rem;" class="mt-5"> {{'category' | translate}}: <span style="color: #978EF2;">{{this.category.name | translate}}</span></h5>

        <tlb-form *ngIf="formModel && formModel.length > 0 && formGroup" [forms]="formModel" [formGroup]="formGroup"
        ></tlb-form>



      <div class="modal-footer">
        <button type="button" class="btn btnSave" (click)="updateOrSave()" [disabled]="formGroup && formGroup.invalid" >{{'save' | translate}}</button>
        <button type="button" class="btn btnCancel" data-dismiss="modal" >{{'cancel' | translate}}</button>

      </div>
    </div>
  </div>
</div>

