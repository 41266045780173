import { GeneralIdNameModel } from './general-id-name.model';

export class AuditedModel {
  createdAt: Date;
  createdBy: GeneralIdNameModel;
  updatedAt: Date;
  updatedBy: GeneralIdNameModel;

  constructor() {
    this.createdAt = new Date();
    this.createdBy = new GeneralIdNameModel();
    this.updatedAt = new Date();
    this.updatedBy = new GeneralIdNameModel();
  }
}
