import {TranslatePipe} from '../../pipes/translate/translate.pipe';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'tlb-dropdown',
  templateUrl: './tlb-dropdown.component.html',
  styleUrls: ['./tlb-dropdown.component.scss']
})

export class TlbDropdownComponent implements OnInit {

  @Input() dropdownName = 'Select';
  @Input() isPhoneNumber: boolean = false;
  @Input() isFilter: boolean = true;
  @Input() selectedModel: any;
  @Input() selectedModelName: string;
  @Input() dropDownMenuWidth = '14rem';
  @Input() selectedModelName2: string = '';
  @Input() selectedModelName3: string = '';
  @Input() items: any[] = [];
  @Input() disabled: boolean = false;
  @Input() style: string = '';
  @Input() textStyle: string = '';
  @Input() searchType: string = 'local';
  @Input() searchText: any = { data: '' };
  @Output() searchData = new EventEmitter();
  @Output() onSearch = new EventEmitter();
  @Output() clickedButton = new EventEmitter();

  filteredItems: any[] = [];

  constructor(private translate: TranslatePipe) {

  }

  ngOnInit(): void {

    if (this.dropdownName != 'Select') {
      // this.dropdownName = this.translate.transform(this.dropdownName);
    }
    this.filteredItems = this.items;
  }

  ngOnChanges() {
    this.filteredItems = this.items;
  }

  selectData(event, item): void {
    if (this.selectedModel && this.isPhoneNumber) {

      if (this.selectedModelName2 && this.selectedModelName2 != '') {

        if (this.selectedModelName3 && this.selectedModelName3 != '') {
          this.selectedModel[this.selectedModelName][this.selectedModelName2][this.selectedModelName3] = item.value;
        } else {
          this.selectedModel[this.selectedModelName][this.selectedModelName2] = item.value;
        }
      } else if (this.selectedModelName3 && this.selectedModelName3 != '') {
        this.selectedModel[this.selectedModelName][this.selectedModelName3] = item.value;
      } else {
        this.selectedModel[this.selectedModelName] = item.value;
      }

    }

    this.dropdownName = item.label;
    this.searchData.emit(item);
  }

  search() {
    if (this.searchType === 'local') {
      if (this.searchText.data != '') {
        this.filteredItems = ((this.filteredItems).filter((item: any) => ((item.label).toLowerCase()
          .trim()).indexOf(
            this.searchText.data.toLowerCase()
              .trim()) > -1));
      } else {
        this.filteredItems = this.items;
      }
    }
    if (this.searchType === 'api') {
      this.onSearch.emit(this.searchText.data);
    }
  }

  clicked() {
    this.clickedButton.emit();
  }

}
