<hr>
<div [style]="containerStyle"
     class="container-fluid">
  <div class="row h-100"
       style="margin-top: 10px;">
    <div class="col-12 pr-1 pl-0 pb-4">
      <div class="card warning-background h-100">
        <div [ngClass]="small ? 'text-and-icon-small' : 'text-and-icon'"
             class="card-body">

          <div class="card-text">
            <img src="./assets/images/talentbase/empty-content1.svg" /> <br> <br>
            <span *ngIf="filterText == ''" class="text-center"> {{warningLabel | translate}}</span>
            <span *ngIf="filterText != ''" class="text-center"> {{filterTextWithTranslate | translate}}</span>
            <p *ngIf="subText"
               class="empty-desc">{{ 'emptyPageDescription' | translate }}</p>
          </div>


          <!-- <div style="position: relative; top: -15px" >
            <button (click)="activityNavigate()" class="btn apply-filter-btn" type="button">{{'viewAll' |
              translate}}</button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
