import { Component, Input, OnInit } from '@angular/core';
import { DropdownOptionsModel, GeneralIdModel, UserModel, ConversationModel } from 'src/app/shared/models';
import { ConversationService, UserService } from 'src/app/shared/services';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {

  @Input() selectedUser: any = null;
  @Input() employer: boolean = false;
  users: UserModel[] = [];
  userOptions: DropdownOptionsModel[] = [];
  selectedId: number;
  conversations: ConversationModel[] = [];
  defaultUsers: any[] = [];
  message: string;
  messages: any[] = [];
  myId: string = "";
  test: boolean = false;

  constructor(private authService: AuthService, private conversationService: ConversationService, private userService: UserService) {
    this.selectedId = 0;
  }

  ngOnInit(): void {
    const parsedToken = JSON.parse(decodeURIComponent(escape(window.atob((this.authService.getToken()).split('.')[1]))));
    this.myId = parsedToken.sub;
    this.getUsers();

  }

  // ngOnChanges(){
  //   this.test = true;


  // }

  // ngDoCheck(){
  //   if(this.test && this.selectedUser && this.selectedUser != null && this.users && this.users.length > 0 && this.conversations && this.conversations.length > 0){
  //     if(this.employer){
  //       let user = this.users.filter(x=> x.employerId == this.selectedUser.id)[0];
  //       let model = {value: user.id, label: user.fullName};
  //       this.test = false;
  //       this.selectUser(model);

  //      }
  //      else{

  //        let model = {value: this.selectedUser.user.id, label: this.selectedUser.user.fullName};
  //        this.test = false;
  //        this.selectUser(model);

  //      }
  //   }
  // }

  getUsers() {
    this.userService.ServerGet().subscribe(x => {
      this.users = x.content;

      this.getConversations();
      this.userOptions = this.users.filter(u => u.id != this.myId).map(c => ({ value: c.id, label: c.fullName }));
    });
  }

  getConversations() {

    this.defaultUsers = [];
    this.conversationService.ServerGet().subscribe(x => {
      this.conversations = x.content;
      if (this.conversations && this.conversations.length > 0) {
        this.conversations.forEach(c => {
          let name = this.users.some(u => u.id == c.participants[0].id) ? this.users.filter(u => u.id == c.participants[0].id)[0].fullName : "";
          let date = c.lastMessage ? new Date(c.lastMessage.updatedAt).toLocaleDateString() : new Date(c.updatedAt).toLocaleDateString();
          this.defaultUsers.push({ name: name, id: c.id, lastMsg: c.lastMessage ? c.lastMessage.content : "", date: date });
        });
      }
    });
  }

  selectUser(user) {

    if (this.conversations.some(x => x.participants[0].id == user.value)) {
      // if(this.selectedUser && this.selectedUser != null) this.getConversations();
      // setTimeout(() => {
        this.changeContent(this.conversations.filter(x => x.participants[0].id == user.value)[0].id);
      // });

    }
    else {
      let participants: any[] = []
      let id: GeneralIdModel = new GeneralIdModel();
      id.id = user.value;
      participants.push(id);
      let model = {
        participants: participants
      }

      this.conversationService.ServerPost(model).subscribe(x => {
        this.getConversations();
        setTimeout(() => {
          this.changeContent(x.id);
        });
      });
    }
  }

  sendMessage() {
    if (this.message != "" && this.message != undefined) {
      let model = {
        content: this.message
      }
      this.conversationService.ServerPostWithText('/' + this.selectedId + '/messages', model).subscribe(x => {
        this.message = "";
        this.changeContent(this.selectedId);
      });
    }

  }

  changeContent(id: any) {

    this.conversationService.ServerGetWithText('/' + id + '/messages').subscribe(x => {
      this.selectedId = id;
      this.messages = [];
      if (x.content && x.content.length > 0) {
        x.content.forEach(msg => {
          this.messages.push({
            date: new Date(msg.createdAt).toLocaleDateString(), time: new Date(msg.createdAt).toLocaleTimeString(),
            content: msg.content, inc: msg.createdBy.id != this.myId
          })
        });
        this.messages = this.messages.reverse();
      }

    });
  }
}
