<div *ngIf="filterData"
     class="row">
  <div class="col-12 pr-1 pl-0">
    <div class="card border-0"
         style="background-color: #F5F6F8;">
      <div class="card-body"
           style="padding: 0rem 1.25rem;">
        <div class="card-text row">

          <div class="dropdown drp"
               id="filer-dropdown">
            <button (click)="filterState = !filterState"
                    class="btn dropdown-menus form-control mb-1 d-inline"
                    id="filterButton"
                    type="button">
              <span class="text-center">{{'showAllFilter' | translate}}</span>
            </button>
            <div [style.display]="filterState ? 'block':'none'"
                 aria-labelledby="dropdownMenuButton"
                 class="dropdown-menu col-12 pb-0"
                 id="filter-dropdown-menu"
                 style="min-width: 18rem;">


              <div class="dropdown-item color-dropdown-item"
                   id="city">

                <tlb-multiselect-dropdown (selectResult)="selectExperience($event)"
                                          [defaultItemKey]="''"
                                          [dropDownMenuWidth]="'15rem'"
                                          [dropdownName]="recruitmentExperienceLabel != '' ? recruitmentExperienceLabel : 'recruitmentExperience'"
                                          [existItems]="selectedRecruitmentExperiences"
                                          [isFilter]="false"
                                          [items]="recruitmentExperienceOptions"
                                          [style]="'text-align: left; border: 1px solid #DCDCDC; box-sizing: border-box; box-shadow: 0px 2px 4px rgb(0 0 0 / 10%); border-radius: 7px; height: 40px;'"
                                          [textStyle]="'font-family: Roboto; font-style: normal; font-weight: 500; font-size: 15px; line-height: 16px; letter-spacing: 0.1px; color: #252B42 !important;'">
                </tlb-multiselect-dropdown>
              </div>

              <div class="dropdown-item color-dropdown-item"
                   id="city">

                <tlb-multiselect-dropdown (selectResult)="selectRequisition($event)"
                                          [defaultItemKey]="''"
                                          [dropdownName]="requisitionTypeLabel != '' ? requisitionTypeLabel : 'requisitionType'"
                                          [existItems]="selectedRequisitionType"
                                          [isFilter]="false"
                                          [items]="requisitionTypeOptions"
                                          [style]="'text-align: left; border: 1px solid #DCDCDC; box-sizing: border-box; box-shadow: 0px 2px 4px rgb(0 0 0 / 10%); border-radius: 7px; height: 40px;'"
                                          [textStyle]="'font-family: Roboto; font-style: normal; font-weight: 500; font-size: 15px; line-height: 16px; letter-spacing: 0.1px; color: #252B42 !important;'">
                </tlb-multiselect-dropdown>
              </div>

              <div class="dropdown-item color-dropdown-item"
                   id="type">

                <tlb-multiselect-dropdown (selectResult)="selectFunctionalRole($event)"
                                          [defaultItemKey]="''"
                                          [dropdownName]="functionalRoleLabel != '' ? functionalRoleLabel : 'functionalRole'"
                                          [existItems]="selectedFunctionalRoles"
                                          [isFilter]="false"
                                          [items]="functionalRoleOptions"
                                          [style]="'text-align: left; border: 1px solid #DCDCDC; box-sizing: border-box; box-shadow: 0px 2px 4px rgb(0 0 0 / 10%); border-radius: 7px; height: 40px;'"
                                          [textStyle]="'font-family: Roboto; font-style: normal; font-weight: 500; font-size: 15px; line-height: 16px; letter-spacing: 0.1px; color: #252B42 !important;'">
                </tlb-multiselect-dropdown>
              </div>

              <div class="dropdown-item color-dropdown-item"
                   id="type">

                <tlb-multiselect-dropdown (selectResult)="selectIndustry($event)"
                                          [defaultItemKey]="''"
                                          [dropdownName]="industryLabel != '' ? industryLabel : 'industry'"
                                          [existItems]="selectedIndustries"
                                          [isFilter]="false"
                                          [items]="industryOptions"
                                          [style]="'text-align: left; border: 1px solid #DCDCDC; box-sizing: border-box; box-shadow: 0px 2px 4px rgb(0 0 0 / 10%); border-radius: 7px; height: 40px;'"
                                          [textStyle]="'font-family: Roboto; font-style: normal; font-weight: 500; font-size: 15px; line-height: 16px; letter-spacing: 0.1px; color: #252B42 !important;'">
                </tlb-multiselect-dropdown>
              </div>

              <div class="row mr-4"
                   style="justify-content: end;">

                <div class="mt-2 mb-2 mr-1">
                  <button (click)="clearFilterEmit()"
                          class="btn clear-filter-btn"
                          type="button">{{'discard' |
                    translate}}</button>
                </div>
                <div class="mt-2">
                  <button (click)="emitData()"
                          class="btn apply-filter-btn"
                          type="button">{{'apply' |
                    translate}}</button>
                </div>


              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
