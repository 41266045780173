<!-- Modal -->
<div class="modal fade" id="generalYesNoPopup" style="z-index: 1000000000;">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header modal-md">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-2">
            <img src="./../../../assets/images/talentbase/delete-icon.png">
          </div>
          <div class="col-10">
            <div class="title-design">
              <p style="font-weight:700; color: #54595E;">{{Text|translate}}</p>
              <div class="row pt-2 pb-3 d-flex justify-content-start">
                <div class="mr-2">
                  <button (click)="selectYesOrNo(false)" type="button" class="btn discard-button" data-dismiss="modal">{{ 'discard' | translate }}</button>
                </div>
                <div>
                  <button (click)="selectYesOrNo(true)" type="button" class="btn delete-button" data-dismiss="modal">{{ 'delete' | translate }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
