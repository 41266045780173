import {Injectable} from '@angular/core';
import {ApiService} from '../../auth';
import {HttpClient} from '@angular/common/http';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslatePipe} from '../../../pipes/translate/translate.pipe';
import {NotificationService} from '../../notification';
import {server} from '../../server';

@Injectable({
  providedIn: 'root'
})
export class PackageService extends ApiService {

  constructor(public http: HttpClient, public spinner: NgxSpinnerService, public translate: TranslatePipe, public notification: NotificationService) {
    super(http, server.packages, spinner, translate, notification);
  }


  changePackage(packageId) {
    return this.ServerPut(server.subscriptionsMinePackage, {packageId});
  }

  getMine() {
    return this.ServerGetById(server.subscriptionsMine);
  }

  getCheckoutForm(packetId) {
    if (packetId == undefined) {
      return this.ServerGetById(server.subscriptionsMineIyzicoCheckoutForm);
    } else {
      return this.ServerGetById(server.subscriptionsMineIyzicoCheckoutForm + '?packageId=' + packetId);
    }
  }

  getPaymentStatus() {
    return this.ServerGetById(server.subscriptionsPaymentStatus);
  }

  getFunctionalRolesMine() {
    return this.ServerGetById(server.functionalRolesMine);
  }

}
