export enum RecruiterCount{
  SOLO = 'SOLO',
  TWO = 'TWO',
  THREE = 'THREE',
  FOUR = 'FOUR',
  FIVE_TO_TEN = 'FIVE_TO_TEN',
  ELEVEN_TO_TWENTY_FIVE = 'ELEVEN_TO_TWENTY_FIVE',
  TWENTY_SIX_TO_HUNDRED = 'TWENTY_SIX_TO_HUNDRED',
  HUNDRED_PLUS = 'HUNDRED_PLUS',
  UNKNOWN = 'UNKNOWN'
}
