import { Component, OnInit } from '@angular/core';
import { RequestPayload } from 'src/app/shared/classes';
import { GeneralIdModel, JobCompensationModel, JobExperienceRequiredModel, JobFeeModel, JobLocationModel, JobModel, JobPostModel, JobRelocationAssistanceModel, JobVisaRequirementModel, JobFilterDataModel, JobQuestionnaireModel } from 'src/app/shared/models';
import { ApplicationCountService, ApplicationService, EmployerMeService, EmployerService, JobService, NewsLetterService, NotificationService, TranslatePipe } from 'src/app/shared/services';

@Component({
  selector: 'app-my-jobs',
  templateUrl: './my-jobs.component.html',
  styleUrls: ['./my-jobs.component.scss']
})
export class MyJobsComponent implements OnInit {

  job: JobPostModel = null;
  jobs: JobModel[] = [];
  count: number;
  requestPayload: RequestPayload;
  filterData: JobFilterDataModel;
  storedItems: any[] = [];
  searchText: string = "";
  sortText: string= "&sort=createdAt,DESC";
  filterText: string = "";
  filterType: string = "title";
  statusText: string = "";
  email: string = "";

  constructor(private applicationCountService: ApplicationCountService, private jobService: JobService, private newsLetterService: NewsLetterService,
    private applicationService: ApplicationService, private employerMeService: EmployerMeService, private employerService: EmployerService,
    public notification: NotificationService, public translate: TranslatePipe) {
    this.requestPayload = new RequestPayload();
    this.filterData = new JobFilterDataModel();
    this.requestPayload.limit = 5;
  }

  ngOnInit() {
    this.getJobs(1);
  }

  getJobs(pageNo) {
    this.jobService.ServerGetWithText(this.requestPayload.payloadURL(pageNo) + this.sortText + this.requestFilter() + '&filter=status::' + this.statusText).subscribe(x => {
      this.jobs = x.jobPagination.content as JobModel[];
      this.storedItems = this.jobs;
      this.searchText != "" ? this.search(this.searchText) : "";
      this.count = x.jobPagination.totalElements;
      let about;
      this.employerMeService.ServerGet().subscribe(employerDetails => {
        about = employerDetails.about;
        this.applicationService.ServerGet().subscribe(x => {
          let applications = x.content;
          this.jobs.forEach(x => {
            this.employerService.ServerGetWithText("/" + x.employer.id + "/company-logo")
              .subscribe((res: any) => {
                x.logoUrl = res.url;

              });

            x.aboutCompany = about;
            let time = Math.abs(new Date().getTime() - new Date(x.createdAt).getTime());
            x.postDate = Math.ceil(time / (1000 * 3600 * 24));
            let apps = applications.filter(f => f.job.id == x.id);
            x.applications = applications.filter(f => f.job.id == x.id && f.status != "HIRED" && f.status != "CANCELLED" && f.status != "REJECTED_BY_CANDIDATE"
              && f.status != "REJECTED_BY_EMPLOYER");
            x.applications?.forEach(a => { a.date = new Date(a.createdAt).toLocaleDateString() });
            x.pendingReview = x.applications.some(s => (s.job.id == x.id) && (s.status != "CANCELLED") && (s.status != "REJECTED_BY_CANDIDATE") &&
              (s.status != "REJECTED_BY_EMPLOYER") && (s.status != "HIRED")) ? x.applications.filter(s => (s.job.id == x.id) && (s.status != "CANCELLED")
                && (s.status != "REJECTED_BY_CANDIDATE") && (s.status != "REJECTED_BY_EMPLOYER") && (s.status != "HIRED")).length : 0;
            x.underConsideration = x.applications.filter(a => a.status == "CLIENT_REVIEW" || a.status == "MANAGER_REVIEW").length;
            x.totalApplications = apps.length;
            x.allApplications = apps;
            x.allApplications?.forEach(a => { a.date = new Date(a.createdAt).toLocaleDateString() });
            this.applicationCountService.ServerGetWithText("/?jobId=" + x.id).subscribe(a => {
              x.managerReview = a.managerReview;
              x.offerStage = a.offerStage;
              x.rejected = a.rejectedByEmployer;
              x.clientReview = a.clientReview;
              x.interviewStage = a.interviewStage;
              x.openPosition = x.clientReview + x.managerReview + x.interviewStage + x.offerStage
            });
          });
        });
      });
    });

  }

  sorted(sort){
    this.sortText = sort;
    this.getJobs(1);
  }

  setFilterText(event){
    this.filterText = event;
    this.getJobs(1);
  }

  setStatusFilter(event){
    this.statusText = event;
    this.getJobs(1);
  }

  requestFilter() {
    return '&filter=countryId**' + this.filterData.country +
      '&filter=cityId**' + this.filterData.city +
      '&filter=freeText~~' + this.filterText +
      '&filter=type**' + this.filterData.jobType +
      '&filter=functionId**' + this.filterData.jobFunction +
      '&filter=industryId**' + this.filterData.industry
  }

  myJobOpenModal() {
    this.job = new JobPostModel();
    this.job.classification = new GeneralIdModel();
    this.job.function = new GeneralIdModel();
    this.job.industry = new GeneralIdModel();
    this.job.compensation = new JobCompensationModel();
    this.job.compensation.currency = new GeneralIdModel();
    this.job.experienceRequired = new JobExperienceRequiredModel();
    this.job.relocationAssistance = new JobRelocationAssistanceModel();
    this.job.visaRequirement = new JobVisaRequirementModel();
    this.job.location = new JobLocationModel();
    this.job.location.country = new GeneralIdModel();
    this.job.location.city = new GeneralIdModel();
    this.job.fee = new JobFeeModel();
    this.job.questionnaire = new JobQuestionnaireModel();

    // @ts-ignore
    $('#exampleModalLongs').modal('show');
  }

  getFilteredJobs(e) {
    this.filterData = e;
    this.getJobs(1);

  }

  getClearFilterJobs(e) {
    this.filterData = e;
    this.getJobs(1);
  }

  search(data) {

    this.searchText = data;
    // tslint:disable-next-line:max-line-length
    this.jobs = ((this.storedItems).filter((item: JobModel) =>
      (((item.employer.name).toLowerCase().trim()).indexOf(data.toLowerCase().trim()) > -1) ||
      (((item.title).toLowerCase().trim()).indexOf(data.toLowerCase().trim()) > -1)
    ));
  }

  sendMail(){
    let model = {
      email: this.email
    };

    this.newsLetterService.ServerPost(model).subscribe(x=>{
      this.notification.success(this.translate.transform('yourEmailHasBeenSuccessfullySaved'));
      this.email = "";
    },
    error =>{
      this.notification.error(this.translate.transform(error.message));
    });
  }

}
