import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { NoteModel }                                      from '../../../../shared/models/applications/note.model';

declare var $: any;

@Component({
             selector: 'app-application-detail-note-delete-modal',
             templateUrl: './application-detail-note-delete-modal.component.html',
             styleUrls: ['./application-detail-note-delete-modal.component.scss']
           })
export class ApplicationDetailNoteDeleteModalComponent implements OnInit {

  @Output() delete: EventEmitter<NoteModel> = new EventEmitter();
  @Input() selectedNote: NoteModel = new NoteModel();

  constructor() {
  }

  ngOnInit(): void {
  }

  closeModal(): void {
    $('#application-note-delete-modal')
      .modal('hide');
  }

  deleteNote(): void {
    this.delete.emit(this.selectedNote);
    $('#application-note-delete-modal')
      .modal('hide');

  }
}
