import { map, catchError, finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../../auth';
import { server } from '../../server';


@Injectable({
    providedIn: 'root'
})

export class MarketPlaceService extends ApiService{

  constructor(public http: HttpClient,public spinner:NgxSpinnerService,public translate:TranslatePipe, public notification:NotificationService) {
      super(http, server.marketPlace,spinner,translate,notification)
  }

  Purchase(groupId:string,marketPlaceItemId:number):Observable<any>{
      this.spinner.show();
      const headers = this.setHeadersWithToken();
      const options = { headers };
      return this.http.post(server.marketplaceGroups+'/'+groupId+'/marketplace-items/'+marketPlaceItemId+'/purchase',null, options)
          .pipe(
              map(res => res),
              catchError((err) => {
                  return this.ServerError(err);
              }),
              finalize(() => {
                this.spinner.hide();
              })
              );
  }

}
