<div class="container-fluid">
  <div class="row">
    <div class="col-12 px-4 pt-3">
      <div class="d-flex flex-wrap flex-row align-content-center justify-content-space-between align-items-center ">
        <div>
          <h2 class="page-title">Payments</h2>
          <h6 class="page-description">Base on your preferences</h6>
        </div>
        <div>
          <span>{{'sortBy' | translate}}:</span>
          <select (ngModelChange)="sortByChange()"
                  [(ngModel)]="sortBy"
                  class="ml-2 sort-by"
                  id="sortBy"
                  id="sortBy"
                  name="sortBy">
            <option [ngValue]="'createdAt,desc'">{{'newest' | translate}}</option>
            <option [ngValue]="'createdAt,asc'">{{'oldest' | translate}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-12 p-0">
      <hr>
    </div>
    <div class="col-12 px-4 d-flex flex-wrap flex-row align-content-center justify-content-space-between align-items-center">
      <div class="d-flex flex-wrap flex-row align-content-center justify-content-flex-start align-items-center">
        <div class="dropdown drp d-inline-block my-2 my-lg-0"
             id="filer-dropdown">
          <button (click)="filterState = !filterState"
                  class="btn dropdown-menus form-control d-inline"
                  id="filterButton"
                  style="height: 48px"
                  type="button">
            <!-- <i class="fas fa-filter mt-1"></i> -->
            <span class="text-truncate d-inline-block;"
            >{{'filters' | translate}}<i class="fa fa-chevron-down ml-3"></i></span>
          </button>
          <div [style.display]="filterState ? 'block':'none'"
               aria-labelledby="dropdownMenuButton"
               class="dropdown-menu col-12 pb-0"
               id="filter-dropdown-menu"
               style="min-width: 14rem;">

            <div class="dropdown-item color-dropdown-item filters-header"
                 id="header">
              {{'filters' | translate}}
            </div>
            <!-- <p class="filters-header">{{'filters' | translate}}</p> -->

            <div class="dropdown-item color-dropdown-item"
                 id="country">
              <tlb-dropdown (searchData)="selectPaymentStatus($event)"
                            [dropdownName]="paymentStatusLabel != '' ? paymentStatusLabel : 'paymentStatus'"
                            [items]="paymentStatusOptions"
                            [style]="'text-align: left;  width: 100%; border: 1px solid #DCDCDC; box-sizing: border-box; box-shadow: 0px 2px 4px rgb(0 0 0 / 10%); border-radius: 7px; height: 40px;'"
                            [textStyle]="'font-family: Roboto; font-style: normal; font-weight: 500; font-size: 15px; line-height: 16px; letter-spacing: 0.1px; color: #252B42 !important;'">
              </tlb-dropdown>
            </div>


            <div class="row mr-4"
                 style="justify-content: end;">

              <div class="mt-2 mb-2 mr-1">
                <button (click)="clearFilter()"
                        class="btn clear-filter-btn"
                        type="button">{{'discard' |
                  translate}}</button>
              </div>
              <div class="mt-2">
                <button class="btn apply-filter-btn"
                        type="button" (click)="filterState = false;getEarnings()">{{'apply' |
                  translate}}</button>
              </div>
            </div>


          </div>
        </div>
        <input (ngModelChange)="searchChangeEvent()"
               [(ngModel)]="searchText"
               [placeholder]="'search' | translate"
               class="search-box ml-0 ml-sm-3"
               id="search-box"
               name="search-box"
            >

               <label *ngIf="searchText != ''" class="clear-search mt-2 ml-2" for="" (click)="clearSearch()"><i class="fas fa-times mr-1"
                style="color: #707683;" ></i> {{'clearSearch' | translate}}  </label>

<!--        <span class="person-icon  ml-0 ml-sm-3">
        <img src="assets/images/icons/users.png"
             alt="users">
          {{'person' | translate}}
      </span>-->
      </div>
      <div>
        <img (click)="getEarnings()"
             alt="refresh"
             class="cursor-pointer"
             src="assets/images/icons/refresh.png">
      </div>

    </div>
    <div *ngIf="earnings && earnings.length > 0" class="col-12 mt-3 px-4 overflow-auto">
      <div class="table-white">
        <div class="table-header">
          <h6 class="table-header-col-1">{{'name' | translate | uppercase}}</h6>
          <h6 class="table-header-col-2">{{'placementDate'| translate| uppercase}}</h6>
          <h6 class="table-header-col-3">{{'inGuaranteeDate'| translate| uppercase}}</h6>
          <h6 class="table-header-col-4">{{'amount'| translate| uppercase}}</h6>
          <h6 class="table-header-col-5">{{'status'| translate| uppercase}}</h6>
          <h6 class="table-header-col-6">{{'action'| translate| uppercase}}</h6>
        </div>
        <hr>
        <div *ngFor="let earning of earnings"
             class="table-item my-2">
          <div class="table-item-col-1">
            <div [style.background-color]="earning.imageColor"
                 class="preimage mx-2">
              <span style="color: white;font-size: 0.6rem;    line-height: 24px;">{{earning.imageText}}</span></div>
            {{earning.candidate.name}}
          </div>
          <div class="table-item-col-2">
            {{earning.placedAt | date}}
          </div>
          <div class="table-item-col-3">
            {{earning.inGuaranteeDate | date}}
          </div>
          <div class="table-item-col-4">
            {{earning.fee | currency:('currency_'+earning.currency?.name | translate)}}
          </div>
          <div class="table-item-col-5">
            <span [style.background-color]="earning.paymentStatus==='GTEE_PERIOD'?'#EF865D':earning.paymentStatus==='PAID'?'#4BDE97':'#FE2E56'"
                  class="status-circle"></span>
            {{earning.paymentStatus | translate}}
          </div>
          <div class="table-item-col-6">
            <button (click)="pay(earning)"
                    [disabled]="earning.paymentStatus === ('PAID' || 'CANCELLED')"
                    [ngClass]="earning.paymentStatus === 'PAID' ?'btn-paid':earning.paymentStatus === 'CANCELLED'?'btn-cancelled':''"
                    class="btn btn-pay">{{((earning.paymentStatus === 'PAID') ? 'paid' : (earning.paymentStatus === 'CANCELLED' ? 'CANCELLED' : 'payNow')) | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<tlb-empty-page *ngIf="!earnings || earnings.length == 0" [warningLabel]="searchText == ''  ? 'noPaymentYet' : searchText" [filterText]="searchText"></tlb-empty-page>
