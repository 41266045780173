import {PhoneNumberModel} from '../parameter';
import {CoverLetterModel} from './cover-letter.model';
import {ResumeModel} from './resume.model';

export class CandidatePostModel {

  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: PhoneNumberModel;
  resume: ResumeModel;
  coverLetter: any;
  skillTags: any[];

  constructor() {
    this.id = '';
    this.email = '';
    this.firstName = '';
    this.lastName = '';
    this.phoneNumber = new PhoneNumberModel();
    this.resume = new ResumeModel();
    this.coverLetter = {};
    this.skillTags = [];
  }
}
