import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ApplicationModel, ApplicationStatusEnum} from '../../../shared/models/applications/application.model';
import {NoteModel} from '../../../shared/models/applications/note.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ApplicationDetailStatusService} from './application-detail-status.service';
import {ApplicationDetailPrintPageService} from './application-detail-print-page/application-detail-print-page.service';
import {ApplicationDetailService} from './application-detail.service';
import { NotificationService, TranslatePipe } from 'src/app/shared/services';

declare var $: any;

@Component({
             selector: 'app-application-detail',
             templateUrl: './application-detail.component.html',
             styleUrls: ['./application-detail.component.scss']
           })
export class ApplicationDetailComponent implements OnInit {

  application!: ApplicationModel;

  noteForm!: FormGroup;

  applicationStatusOptions = [];

  selectedNote: NoteModel = new NoteModel();

  constructor(private route: ActivatedRoute,
              private fb: FormBuilder,
              public applicationDetailStatusService: ApplicationDetailStatusService,
              public applicationDetailPrintPageService: ApplicationDetailPrintPageService,
              public applicationDetailService: ApplicationDetailService,
              private notification: NotificationService, private translate: TranslatePipe) {

    this.applicationStatusOptions = Object.keys(ApplicationStatusEnum);
  }

  ngOnInit(): void {
    this.noteForm = new FormGroup({
                                    title: new FormControl('', Validators.required),
                                    note: new FormControl('', Validators.required)
                                  });

    this.getApplication();
  }

  showAddNoteBox(): void {
    this.applicationDetailService.showAddNoteBox();
  }

  cancelAddNote(): void {
    this.applicationDetailService.cancelAddNote();
  }

  addNote(): void {
    this.applicationDetailService.addNote();
  }

  getApplication(): void {
    this.route.params.subscribe(params => {
      this.applicationDetailService.applicationId = params.id;
      this.applicationDetailService.getApplication()
          .subscribe(application => {
            this.application = application;
            this.application.$job.subscribe(job => {
              this.application.job = job;
            });
            this.application.$recruiter.subscribe(recruiter => {
              this.application.recruiter = recruiter;
            });
            this.applicationDetailStatusService.setSelectableApplicationStatuses(application);
          });
    });
  }

  openUrl(url) {
    window.open(url);
  }



  openNoteDeleteModal(note: NoteModel): void {
    this.applicationDetailService.addNoteState = false;
    this.selectedNote = note;
    $('#application-note-delete-modal')
      .modal('show');
  }

  hireApp(salary){
    this.applicationDetailService.salary = salary;
    this.applicationDetailService.hireApp();

    this.notification.success(this.translate.transform('successfullyHired'));

  }

  print(): void {
    this.applicationDetailPrintPageService.print(this.application);

  }

  changeApplicationStatus(status: ApplicationStatusEnum): void {
    this.applicationDetailStatusService.changeApplicationStatus(status, () => {
      this.getApplication();
    });
  }

    saveApplication(application: ApplicationModel): void {
        this.applicationDetailService.saveApplication(application.id).subscribe(() => {
            this.getApplication();
        });

    }

    unSaveApplication(application: ApplicationModel): void {
        this.applicationDetailService.unSaveApplication(application.id).subscribe(() => {
            this.getApplication();
        });
    }
}
