import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent implements OnInit {

  public sidebarIsOpen = true;
  public sidebarOpen = true;
  role: string = "";

  constructor() {
    this.role = localStorage.getItem("role");
  }

  public _toggleSidebar(): void {
    this.sidebarIsOpen = !this.sidebarIsOpen;
    localStorage.setItem('sidebarIsOpen', String(this.sidebarIsOpen));
  }

  ngOnInit(): void {
    this.setSidebarState();
        // @ts-ignore
        $('body').css('background-color', '#F5F6F8');
  }
  openNav() {
    document.getElementById("mySidenav").style.width = "250px";
  }
  setSidebarState(): void {
    const isOpened = localStorage.getItem('sidebarIsOpen');
    const isTrueSet = (isOpened === 'true');
    if (isOpened && [true, false].includes(isTrueSet)) {
      this.sidebarIsOpen = isTrueSet;
    } else {
      this.sidebarIsOpen = true;
    }
  }

}
