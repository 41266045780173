export class JobExperienceRequiredModel {

 year: number;
 month: number;

constructor() {
  this.year = null;
  this.month = null;
}
}
