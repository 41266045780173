import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FeeType, JobType } from 'src/app/shared/enums';
import { CityModel, CountryModel, DropdownOptionsModel, IndustryModel, JobFilterDataModel, JobFunctionModel } from 'src/app/shared/models';
import { CityService, CountryService, FunctionalRoleService, IndustryService, TranslatePipe } from 'src/app/shared/services';

@Component({
  selector: 'app-my-jobs-filter',
  templateUrl: './my-jobs-filter.component.html',
  styleUrls: ['./my-jobs-filter.component.scss']
})
export class MyJobsFilterComponent implements OnInit {

  countries: CountryModel[] = [];
  countryOptions: DropdownOptionsModel[] = [];
  cities: CityModel[] = [];
  countryLabel: string = "";
  cityLabel: string = "";
  filterData: JobFilterDataModel;
  filterState: boolean;
  keyWordsFilterOptions: DropdownOptionsModel[] = [];
  keyWordsLabel: string = "";
  filterType: string = "title";
  filterText: string = "";
  jobTypeOptions: DropdownOptionsModel[] = [];
  jobTypeLabel: string = "";
  jobFunctions: JobFunctionModel[] = [];
  jobFunctionLabel: string = "";
  industries: IndustryModel[] = [];
  industryLabel: string = "";
  selectedCities: any[] = [];
  selectedJobTypes: any[] = [];
  selectedIndustries: IndustryModel[] = [];
  selectedFunctions: JobFunctionModel[] = [];
  feeTypeOptions: DropdownOptionsModel[] = [];
  feeTypeLabel: string = "";
  selectedFeeTypes: any[] = [];
  alertText: string = "";

  @Output() resultFilter = new EventEmitter();
  @Output() clearFilter = new EventEmitter();

  @Output() resultSearch = new EventEmitter();
  @Output() setStatusFilter = new EventEmitter();
  constructor(private countryService: CountryService, private cityService: CityService, private translateService: TranslatePipe,
    private functionalRoleService: FunctionalRoleService, private industryService: IndustryService) {
    this.filterData = new JobFilterDataModel();
  }

  ngOnInit(): void {
    this.getOptions();

    //@ts-ignore
    $(document).on("click", function (event) {
      //@ts-ignore
      var $trigger = $("#filer-dropdown");
      //@ts-ignore
      if ($trigger !== event.target && !$trigger.has(event.target).length && $("#filter-dropdown-menu").css('display') == 'block') {
        //@ts-ignore
        $("#filterButton").click();
      }
    });

    this.selectRB('ACTIVE');

  }

  closeFilterPopup(e) {
    this.filterState = false;
  }

  clickedCity() {
    this.alertText = this.cities.length == 0 ? this.translateService.transform('pleaseSelectCountryFirst') : this.alertText = "";
  }

  getOptions() {
    this.cityLabel = "";
    this.countryLabel = "";
    this.feeTypeLabel = "";
    this.jobTypeLabel = "";
    this.industryLabel = "";
    this.keyWordsLabel = "";
    this.jobFunctionLabel = "";

    this.selectedCities = this.selectedFeeTypes = this.selectedFunctions = this.selectedIndustries = this.selectedJobTypes = [];

    this.keyWordsFilterOptions = [
      { label: 'jobTitleCompanies', value: 'title' },
      { label: 'keyWords', value: 'key' }
    ]

    this.countryService.ServerGetWithoutLogin().subscribe(x => {
      this.countries = x.content;
      this.countryOptions = this.countries.map(c => ({ value: c.id, label: c.defaultLocalization.name }));
    });

    this.functionalRoleService.ServerGetWithoutLogin().subscribe(x => {
      this.jobFunctions = x.content;
    });

    this.industryService.ServerGetWithoutLogin().subscribe(x => {
      this.industries = x.content;
    });

    this.jobTypeOptions = Object.keys(JobType).map(c => ({ value: c, label: c }));

    this.feeTypeOptions = Object.keys(FeeType).map(c => ({ value: c, label: c }));
  }

  selectCountry(data) {
    this.filterData.country = data.value;
    this.countryLabel = data.label;
    this.cityService.getCountryCities(data.value).subscribe(x => {
      this.cities = x;
    });
  }

  selectCity(datas) {
    this.filterData.city = '';
    for (const data of datas) {
      if (this.filterData.city === '') {
        this.filterData.city = data.id;
      } else {
        this.filterData.city = this.filterData.city + ',' + data.id;
      }
    }
    this.cityLabel = datas.length > 0 ? datas.length + " selected" : "city";
    this.selectedCities = [...datas];
  }

  selectType(datas) {
    this.filterData.jobType = '';
    for (const data of datas) {
      if (this.filterData.jobType === '') {
        this.filterData.jobType = data;
      } else {
        this.filterData.jobType = this.filterData.jobType + ',' + data;
      }
    }
    this.jobTypeLabel = datas.length > 0 ? datas.length + " selected" : "jobType";
    this.selectedJobTypes = [...datas];
  }

  selectFunction(datas) {
    this.filterData.jobFunction = '';
    for (const data of datas) {
      if (this.filterData.jobFunction === '') {
        this.filterData.jobFunction = data.id;
      } else {
        this.filterData.jobFunction = this.filterData.jobFunction + ',' + data.id;
      }
    }
    this.jobFunctionLabel = datas.length > 0 ? datas.length + " selected" : "jobFunction";
    this.selectedFunctions = [...datas];
  }

  selectIndustry(datas) {
    this.filterData.industry = '';
    for (const data of datas) {
      if (this.filterData.industry === '') {
        this.filterData.industry = data.id;
      } else {
        this.filterData.industry = this.filterData.industry + ',' + data.id;
      }
    }

    this.industryLabel = datas.length > 0 ? datas.length + " selected" : "industry";
    this.selectedIndustries = [...datas];
  }

  selectFeeType(datas) {
    this.filterData.referralReward = '';
    for (const data of datas) {
      if (this.filterData.referralReward === '') {
        this.filterData.referralReward = data;
      } else {
        this.filterData.referralReward = this.filterData.referralReward + ',' + data;
      }
    }
    this.feeTypeLabel = datas.length > 0 ? datas.length + " selected" : "referralReward";
    this.selectedFeeTypes = [...datas];
  }

  selectKeyWords(data) {
    this.filterText = "";
    this.keyWordsLabel = data.label;
    this.filterType = data.value;
  }

  emitData() {
    // this.filterData.jobTitle = this.filterType == "title" ? this.filterText : "";
    this.filterState = false;
    this.resultFilter.emit(this.filterData);

  }

  clearFilterEmit() {
    this.filterData.country = '';
    this.filterData.jobFunction = '';
    this.filterData.jobTitle = '';
    this.filterData.jobType = '';
    this.filterData.city = '';
    this.filterData.industry = '';
    this.keyWordsLabel = "";
    this.cities = [];
    this.getOptions();
    this.clearFilter.emit(this.filterData);
  }

  search() {
    this.resultSearch.emit(this.filterText);
  }


  keyup(data) {
    data == "" ? this.resultSearch.emit("") : "";

  }

  selectRB(type) {
    this.setStatusFilter.emit(type);
  }

}
