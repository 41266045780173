import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService }    from '../../services';

@Pipe({
        name: 'translateFromLocalizations'
      })
export class TranslateFromLocalizationsPipe implements PipeTransform {

  constructor(private _translate: TranslateService) {
  }

  transform(value: any, args?: any): unknown {
    let lang: string = localStorage.getItem('lang') || this._translate.currentLang || '';

    if (this._translate.currentLang == null || localStorage.getItem('lang') == null) {
      localStorage.setItem('lang', 'en');
      this._translate.setCurrentLang('en');
      lang = 'en';
      window.location.reload();
    }
    if (!value) {
      return;
    }
    return value[lang].name;

  }

}
