<div id="related-jobs-modal" aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade bd-example-modal-xl "
  role="dialog" style="background-color: #000000b3;" tabindex="-1">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-body">

        <div >
          <div
            *ngFor="let job of jobs"
            class="row mt-3">
            <div class="col-12 pr-1 pl-0">
              <div class="card" style="border: none;">
                <div class="card-body">
                  <div class="container-fluid mt-4 mb-4">

                    <div class="row">
                      <div class="col-12 col-xl-8 col-xxl-9">
                        <div style="    display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-content: flex-start;
            justify-content: flex-start;
            align-items: baseline;">
                          <div class="mr-2">
                            <img [src]="job.logoUrl && job.logoUrll != '' ? job.logoUrl : './assets/images/default-logo.png'"
                              alt="" width="45">
                          </div>
                          <div>
                            <div class="row mt-2 emp-name-text">

                              <div class="col-12"> {{job.employer.name}}<img *ngIf="job.favorite && descriptionShow"
                                  class="ml-4" src="./assets/images/my-worklist.png"></div>

                            </div>
                          </div>
                        </div>

                        <div class="row mt-2 ml-2">

                        </div>

                        <div class="row mt-1 desc-text">
                          <div class="col-12">

                            <span class="title-text">{{job.title}}</span> <br>

                            <div *ngIf="job.recommended" class="mt-2" style="display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            align-content: flex-start;
                            justify-content: flex-start;
                            align-items: flex-start;">
                              <img src="../../../assets/images/recomended.png">
                              <span class="recomended-text ml-1">{{'recomendedByTalentbase' | translate}}</span>
                            </div>

                            <span *ngIf="!descriptionShow">{{job.description ? job.description.substring(0, 200) : ''}}
                              {{job.description && job.description.length > 200 ? '...' : ''}} </span><br>

                            <div class="mt-2 row">
                              <div class="col-12">
                                <button (click)="openfullJobDescriptionModal(job)" class="btn view-application-btn">
                                  {{'fullJobDescription' | translate }}
                                </button>
                              </div>


                            </div>
                          </div>

                        </div>
                      </div>

                      <div class="col-12 col-xl-4 col-xxl-3 text-center left-border2">
                        <div class="row mt-2">
                          <div *ngIf="job.fee?.type == 'PERCENT'" class="col-12">
                            <div class="base-salary-button">
                              <span>{{job.fee?.percent}}
                                %</span><span> {{ 'ofBaseSalary' | translate }}</span>
                            </div>

                          </div>
                          <div *ngIf="job.fee?.type == 'FIXED'" class="col-12">
                            <div class="base-salary-button">
                              <span>{{job.fee?.amount}}
                                €</span><span> {{ 'fixedAmount' | translate }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-12 p-0 view-salary-button" style="display: flex; justify-content: center;">
                            <a (click)="OpenModalViewSalary(job)">
                              <span>{{ 'viewSalary' | translate }}</span>
                            </a>
                          </div>
                        </div>
                        <div class="mt-4" style="display: flex; justify-content: center;">
                          <div class="guarantee-text">
                            <i class="far fa-calendar-minus"></i>
                            <span class="ml-2">
                              90 {{'days' | translate }} {{ 'guaranteePeriod' | translate }}
                            </span>

                          </div>
                        </div>
                      </div>

                    </div>

                    <div class="row mt-2" *ngIf="descriptionShow">

                      <div class="col-12">{{job.description ? job.description.substring(0, 200) : ''}} {{job.description &&
                        job.description.length > 200 ? '...' : ''}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <app-dashboard-view-salary-modal [modalId]="'viewSalaryModalsFromRec'" [selectedJobs]="selectedJob"></app-dashboard-view-salary-modal>
        <app-view-salary-modal [modalId]="'viewSalaryModalFromRel'" [detailModalId]="'viewSalaryModalsFromRec'" [selectedJobs]="selectedJob"></app-view-salary-modal>
        <app-submit-candidate-modal [dropName]="''" [job]="selectedJob"></app-submit-candidate-modal>
        <!--<app-full-job-description-modal></app-full-job-description-modal>-->
        <app-employer-job-detail-popup (submitCandidateModalEmitter)="submitCandidate(selectedJob)"
          (viewSalaryEmitter)="OpenModalViewSalary(selectedJob)" [job]="selectedJob" [modalId]="'jobDetailFromRelated'">
        </app-employer-job-detail-popup>



      </div>
    </div>
  </div>
</div>
