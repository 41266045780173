export class RecruiterReviewModel{

  applicationId: string;
  candidate: any;
  comment: string;
  companyName: string;
  createdAt: Date;
  createdBy: any;
  earnedScore: number;
  fullName: string;
  id: string;
  rating: number;
  recruiterId: string;
  role: string;
  status: string;
  updatedAt: Date;
  updatedBy: any;
  imageText: string;
  imageColor: string;

  constructor() {
    this.applicationId = null;
    this.candidate = null;
    this.comment = null;
    this.companyName = null;
    this.createdAt = null;
    this.createdBy = null;
    this.earnedScore = null;
    this.fullName = null;
    this.id = null;
    this.rating = null;
    this.recruiterId = null;
    this.role = null;
    this.status = null;
    this.updatedAt = null;
    this.updatedBy = null;
    this.imageColor = null;
    this.imageText = null;
  }

}
