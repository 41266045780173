import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DropdownOptionsModel } from 'src/app/shared/models';
import {Router} from '@angular/router';

@Component({
  selector: 'app-track-candidates-filter',
  templateUrl: './track-candidates-filter.component.html',
  styleUrls: ['./track-candidates-filter.component.scss']
})
export class TrackCandidatesFilterComponent implements OnInit {

  sortOptions: DropdownOptionsModel[] = [];
  sortLabel: string = "";
  filterText: string = "";
  isPress:boolean=false;

  @Output() resultFilter = new EventEmitter();
  @Output() resultSort = new EventEmitter();


  constructor(private  router: Router) { }

  ngOnInit(): void {
    this.sortOptions = [
      { label: 'noSorting', value: 'noSort' },
      { label: 'sortByNameAZ', value: 'firstName,ASC' },
      { label: 'sortByNameZA', value: 'firstName,DESC' },
      { label: 'sortByDateCreatedAZ', value: 'createdAt,ASC' },
      { label: 'sortByDateCreatedZA', value: 'createdAt,DESC' }
    ]
  }

  selectSort(data) {
    this.sortLabel = data.label;
    this.resultSort.emit(data.value);
  }

  emitFilterData() {
    this.isPress=false;
    this.resultFilter.emit(this.filterText);
    this.isPress=true;
  }


  clearSearch(){
    this.filterText = "";
    this.resultFilter.emit(this.filterText);
  }

  navigateAddCandidateComponent() {
    this.router.navigate(['/candidate/add-new']);


  }
}
