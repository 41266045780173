<div class="modal fade bd-example-modal-xl" id="submitCandidateModal" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel" aria-hidden="true" style="background-color: #000000b3;">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content p-2" style="background-color: #F5F6F8">
      <div class="modal-body">
        <div class="modal-body p-0" style="background-color: #F5F6F8">
          <div class="container pl-0 pr-0 pt-4">
            <div class="row ">
              <div class="col-12 mb-2">
                <div class="card card-one h-100">
                  <div class="card-body">
                    <h6>
                      {{ 'details' | translate }}
                    </h6>

                    <div class="container">

                      <div class="row">
                        <div class="col-12 my-3  ">
                          <label for="candidate" class="form-label">Candidate</label>
                          <tlb-dropdown [items]="candidateOptions" [searchType]="'api'" (onSearch)="getFilteredCandidates($event)"
                            [style]="'border: none; border-bottom: 1px solid rgba(43, 58, 75, 0.5); border-radius: 0;'"
                            [dropdownName]="selectedCandidate ? selectedCandidate.fullName : 'Please Select'"
                            (searchData)="selectCandidate($event)" >
                          </tlb-dropdown>

                        </div>

                        <div class="col-6 my-3">
                          <label for="candidate" class="form-label">Candidate E-mail</label>
                          <input [(ngModel)]="email" type="text" class="form-control" id="candidate" disabled>

                        </div>
                        <div class="col-6 my-3  ">
                          <label for="candidate" class="form-label">Candidate Phone
                          </label>
                          <div class="row">
                            <div class="col-3">
                              <select class="form-select form-control" aria-label="Default select example" disabled>
                                <option selected> {{phoneCode}} </option>
                              </select>

                            </div>
                            <div class="col-9">
                              <input [(ngModel)]="phoneNumber" type="text" class="form-control" id="candidate" disabled>

                            </div>

                          </div>

                        </div>
                      </div>


                    </div>


                  </div>
                </div>
              </div>
              <br><br>

            </div>
            <div *ngIf="answers && answers.length > 0" class="row">
              <div class="col-12 mb-2">
                <div class="card card-two h-100">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12">
                        <h6>
                          {{'preScreenQuestions' | translate}} * (Both of Required)
                        </h6>
                      </div>

                    </div>
                    <div *ngFor="let answer of answers" class="row mt-2">
                      <div class="col-12">
                        <div class="p-style">
                          {{answers.indexOf(answer) + 1}}. {{ answer.question.name }}

                        </div>

                        <input   value="answer" [(ngModel)]="answer.content" type="text" class="form-control" id="ans" >
                      </div>

                    </div>

                  </div>
                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-12 mb-2">
                <div class="card card-three h-100">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12">
                        <h6>
                          {{'coverLetter' | translate}}
                        </h6>

                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">

                        <div class="form-group">
                          <label for="exampleFormControlTextarea1"></label>
                          <textarea [(ngModel)]="coverLetter.content" class="form-control"
                            id="exampleFormControlTextarea1" rows="3" disabled></textarea>
                        </div>

                      </div>
                    </div>


                  </div>
                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-12 mb-2">
                <div class="card card-four h-100">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12">
                        <h6>
                          {{'attachment' | translate}}
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="col-12" *ngIf="coverLetter.url!=null">
                      <a class="cursor-pointer color-brand" (click)="openUrl(coverLetter.url)">{{'openCoverLetter' |
                        translate |titlecase}}</a>
                    </div>

                    <div class="col-12" *ngIf="resume.url!=null">
                      <a class="cursor-pointer color-brand" (click)="openUrl(resume.url)">{{'openResume' | translate
                        |titlecase}}</a>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex justify-content-end">
          <button class="btn btn-primary" style="background: #3652E3; border-radius: 4px;" (click)="confirm()"
                  [disabled]="dsbControl()">
            {{'confirm' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
