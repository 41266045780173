import { JobType } from 'src/app/shared/enums';
import { RequestPayload } from 'src/app/shared/classes';
import { JobModel, ApplicationModel } from 'src/app/shared/models';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-employer-dashboard-jobs',
  templateUrl: './employer-dashboard-jobs.component.html',
  styleUrls: ['./employer-dashboard-jobs.component.scss']
})
export class EmployerDashboardJobsComponent implements OnInit {
  @Input() jobs: JobModel[] = [];
  @Input() count: number;
  @Input() limit: number;
  @Output() updateJobs = new EventEmitter();

  @Output() updatedStatus = new EventEmitter();
  currentPage: number;
  requestPayload: RequestPayload;
  applications: ApplicationModel[] = [];
  selectedApplication: ApplicationModel;
  selectedJob: any;
  reviews: any[] = [];
  constructor() {
    this.requestPayload = new RequestPayload();
  }

  ngOnInit(): void {
    this.requestPayload.limit = this.limit;
  }
  pagination(pageNo) {
    this.updateJobs.emit(pageNo);
  }

  public get jobType(): typeof JobType {
    return JobType;
  }

  openApplicantsModal(app, job) {
    this.applications = app;
    this.selectedJob = job;
    this.applications.forEach(x=> {
      x.location = this.selectedJob.location;
      x.jobType = this.selectedJob.type;
    });


    // @ts-ignore
    $('#applicantsModalLong').modal('show');
  }

  openApprovalEmailModal() {
    // @ts-ignore
    $('#approvalModal').modal('show');
  }

  openReviewModal(reviews) {
    this.reviews = reviews;
    // @ts-ignore
    $('#review-popup').modal('show');
  }

  openUpdateCandidateStatus(application: ApplicationModel) {
    this.selectedApplication = application;
    // @ts-ignore
    $('#candidateUpdateStatusModal').modal('show');
    // @ts-ignore
    $('#candidateUpdateStatusModal').css('z-index', 3000);;
  }
  openCandidateDetailPopup(application: ApplicationModel) {
    this.selectedApplication = application;
    // @ts-ignore
    $('#candidateDetailModal').modal('show');
  }

  updateStatus() {
    // @ts-ignore
    $('#applicantsModalLong').modal('hide');
    this.updatedStatus.emit();
  }
}
