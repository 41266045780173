export enum FormType{
    text = 'text',
    toUpperCaseText= 'toUpperCaseText',
    dropDown = 'dropDown',
    radioButton = 'radioButton',
    limitedMultiSelectButton = 'limitedMultiSelectButton',
    phoneNumber = 'phoneNumber',
    textArea = 'textArea',
    textAreaWithLimit = 'textAreaWithLimit',
    image = 'image',
    booleanCheck = 'booleanCheck',
    horizontalRadioButton = 'horizontalRadioButton',
    email =  'email',
    password = 'password',
    numericText = 'numericText',
    customNumericText = 'customNumericText',
    phoneCodeDropDown = 'phoneCodeDropDown',
    multiSelectButton = "multiSelectButton",
    textStyle = 'textStyle',
    emailStyle = 'emailStyle',
    passwordStyle = 'passwordStyle',
    dropDownStyle = 'dropDownStyle',
    multiSelectStyle = 'multiSelectStyle',
    multiSelect = 'multiSelect',
    media = 'media',
    arrayText = "arrayText",
    doubleNumericText = "doubleNumericText",
    multiSelectWithBorder = "multiSelectWithBorder",
    switchButton = "switchButton",
    iban = "iban"
}
