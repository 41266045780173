<div class="modal fade" id="successPopup" style="background-color: #000000b3;">
    <div class="modal-dialog modal-md" role="document" style="">
        <div class="modal-content">
            <!--      <div class="modal-header modal-md">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>-->
            <div class="modal-body">
                <div>
                    <div class="card" style="border: none;">
                        <div class="card-body">
                            <div class="d-flex justify-content-center">
                                <img src="./../../../../assets/images/talentbase/success-page.svg">
                            </div>
                            <div class="text-center">
                                <h4 style="color: #323C47; font-weight:700;">
                                    {{ 'thankYou' | translate }}
                                </h4>
                                <p style="color: #707683;">{{ 'successText' | translate }}</p>
                            </div>
                            <div class="text-center">
                                <button class="close-popup-button" (click)="close()">{{'ok' | translate}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
