import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { CreateAvatar, RequestPayload } from 'src/app/shared/classes';
import { CandidateModel } from 'src/app/shared/models';
import { CandidateService } from 'src/app/shared/services';

@Component({
  selector: 'app-recruiter-dashboard-candidate',
  templateUrl: './recruiter-dashboard-candidate.component.html',
  styleUrls: ['./recruiter-dashboard-candidate.component.scss']
})
export class RecruiterDashboardCandidateComponent implements OnInit {

  candidates: CandidateModel[] = [];
  prevDisabled: boolean = true;
  nextDisabled: boolean = false;
  totalCount: number = 0;
  requestPayload: RequestPayload;
  page: number = 1;
  createAvatar: CreateAvatar;

  constructor(private candidateService: CandidateService, private router: Router) {
    this.requestPayload = new RequestPayload();
    this.requestPayload.limit = 4;
    this.createAvatar = new CreateAvatar();
   }

  ngOnInit() {
    this.getCandidates(1);
  }

  candidateNavigate() {
    this.router.navigate(['/candidate/track-candidate']);
  }

  newCandidate(){
    this.router.navigate(['/candidate/add-new']);
  }


  getCandidates(pageNo){
    this.page = pageNo;
    this.candidateService.ServerGetWithText(this.requestPayload.payloadURL(pageNo)).subscribe(x=>{
      this.candidates = x.content;
      this.candidates.forEach(f => {
        f.imageText = this.createAvatar.create(f.fullName);
        f.imageColor = this.createAvatar.color();
      });
      this.totalCount = x.totalElements;
    });
  }

}
