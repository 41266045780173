<div class="row">
  <div class="col-12">
    <div class="form-group border-bottom pb-4">
      <label for="packet-type">{{'packet' | translate}}:</label>
      <select class="form-control"
              [(ngModel)]="selectedPacket"
              (change)="changePacket()">
        <option [ngValue]="newPacket">{{'new' | translate}}</option>
        <option [ngValue]="packet"
                *ngFor="let packet of packets">
          {{packet.name}}
        </option>
      </select>
    </div>


    <div class="form-group">
      <label for="packet-name">{{'name' | translate}}:</label>
      <input type="text"
             class="form-control"
             [(ngModel)]="selectedPacket.name"
             id="packet-name">
    </div>
    <div class="form-group">
      <label for="packet-type">{{'type' | translate}}:</label>

      <select class="form-control"
              id="packet-type"
              [(ngModel)]="selectedPacket.type">
        <option [ngValue]="UserTypeEnum.RECRUITER">
          {{UserTypeEnum.RECRUITER|translate}}
        </option>
        <option [ngValue]="UserTypeEnum.EMPLOYER">
          {{UserTypeEnum.EMPLOYER|translate}}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label for="packet-status">{{'status' | translate}}:</label>
      <select class="form-control"
              id="packet-status"
              [(ngModel)]="selectedPacket.status">
        <option [ngValue]="PackageStatusEnum.ACTIVE">
          {{PackageStatusEnum.ACTIVE|translate}}
        </option>
        <option [ngValue]="PackageStatusEnum.DRAFT">
          {{PackageStatusEnum.DRAFT|translate}}
        </option>
        <option [ngValue]="PackageStatusEnum.PASSIVE">
          {{PackageStatusEnum.PASSIVE|translate}}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label for="packet-description">{{'description' | translate}}:</label>
      <input type="text"
             class="form-control"
             [(ngModel)]="selectedPacket.description"
             id="packet-description">
    </div>
    <div class="form-group">
      <label for="packet-subDescription">{{'subDescription' | translate}}:</label>
      <input type="text"
             class="form-control"
             [(ngModel)]="selectedPacket.subDescription"
             id="packet-subDescription">
    </div>
    <div class="form-group">
      <label for="packet-planId">{{'planId' | translate}}:</label>
      <input type="text"
             class="form-control"
             [(ngModel)]="selectedPacket.planId"
             id="packet-planId">
    </div>
    <div class="form-group">
      <label for="packet-planName">{{'planName' | translate}}:</label>
      <input type="text"
             class="form-control"
             [(ngModel)]="selectedPacket.planName"
             id="packet-planName">
    </div>
    <div class="form-group">
      <label for="packet-trialInDays">{{'trialInDays' | translate}}:</label>
      <input type="text"
             class="form-control"
             [(ngModel)]="selectedPacket.trialInDays"
             id="packet-trialInDays">
    </div>
    <div class="form-group">
      <label for="packet-price">{{'price' | translate}}:</label>
      <input type="text"
             class="form-control"
             [(ngModel)]="selectedPacket.price"
             id="packet-price">
    </div>
    <div class="form-group">
      <label for="packet-currency">{{'currency' | translate}}:</label>
      <select class="form-control"
              id="packet-currency"
              [(ngModel)]="selectedPacket.currency">
        <option [ngValue]="currency"
                *ngFor="let currency of currencies">
          {{currency.localizations | translateFromLocalizations }}
        </option>
      </select>

    </div>
    <div class="form-group">
      <label for="packet-cycle">{{'cycle' | translate}}:</label>
      <select class="form-control"
              id="packet-cycle"
              [(ngModel)]="selectedPacket.cycle">

        <option [ngValue]="PackageCycleEnum.WEEKLY">
          {{PackageCycleEnum.WEEKLY|translate}}
        </option>
        <option [ngValue]="PackageCycleEnum.MONTHLY">
          {{PackageCycleEnum.MONTHLY|translate}}
        </option>
        <option [ngValue]="PackageCycleEnum.YEARLY">
          {{PackageCycleEnum.YEARLY|translate}}
        </option>

      </select>
    </div>
    <div class="form-group">
      <label for="packet-screenOrder">{{'screenOrder' | translate}}:</label>
      <input type="number"
             class="form-control"
             [(ngModel)]="selectedPacket.screenOrder"
             id="packet-screenOrder">
    </div>
    <div class="form-group">
      <label for="packet-level">{{'level' | translate}}:</label>
      <input type="number"
             class="form-control"
             [(ngModel)]="selectedPacket.level"
             id="packet-level">
    </div>
    <div class="form-group">
      <label for="packet-commission">{{'commission' | translate}}:</label>
      <select name="commission-type"
              id="commission-type"
              class="form-control"
      [(ngModel)]="selectedPacket.commission.type">
        <option [ngValue]="CommissionTypeEnum.FIXED">{{CommissionTypeEnum.FIXED | translate}}</option>
        <option [ngValue]="CommissionTypeEnum.PERCENT">{{CommissionTypeEnum.PERCENT | translate}}</option>
      </select>
      <input type="text"
             class="form-control"
             [(ngModel)]="selectedPacket.commission.amount"
             id="packet-commission">
    </div>
    <div class="form-group">
      <label for="business-functions"
             class="d-block mt-3">{{'businessFunctions'| translate}}:</label>
      <select class="form-control  w-75 d-inline-block"
              id="business-functions"
              [(ngModel)]="selectedBusinessFunction">
        <option [ngValue]="businessFunction"
                *ngFor="let businessFunction of allBusinessFunctions">
          {{businessFunction.name|translate}}
        </option>
      </select>
      <button class="btn btn-primary w-25 d-inline-block"
              (click)="selectBusinessFunction()">{{'add' | translate}}
      </button>
      <div class="functionalRole"
           *ngFor="let businessFunction of selectedPacket.businessFunctions;let i = index;"
      >{{businessFunction.name|translate}}
        <span class="delete-functional-role cursor-pointer"
              (click)="unSelectBusinessFunction(businessFunction,i)">X</span></div>
    </div>
    <div class="form-group">
      <label for="business-functions"
             class="d-block mt-3">{{'usageRestrictions'| translate}}:</label>
      <select class="form-control  w-75 d-inline-block"
              id="usage-restrictions"
              [(ngModel)]="selectedFunctionalRole">
        <option [ngValue]="functionalRole"
                *ngFor="let functionalRole of allFunctionalRoles">
          {{functionalRole|translate}}
        </option>
      </select>
      <button class="btn btn-primary w-25 d-inline-block"
              (click)="selectUsageRestriction()">{{'add' | translate}}
      </button>
      <div class="functionalRole"
           *ngFor="let usageRestriction of selectedPacket.usageRestrictions;let i = index;"
      >{{usageRestriction.functionalRole|translate}}
        <input type="text" class="form-control w-auto" [(ngModel)]="usageRestriction.countLimit">
        <span class="delete-functional-role cursor-pointer"
              (click)="unSelectUsageRestriction(usageRestriction,i)">X</span></div>
    </div>
    <div class="form-check">
      <input type="checkbox"
             class="form-check-input"
             [(ngModel)]="selectedPacket.free"
             id="packet-free">
      <label for="packet-free"
             class="form-check-label">{{'free' | translate}}</label>
    </div>


    <button class="btn btn-primary mt-4 w-100" (click)="save()">{{'save' | translate}}</button>
    <button class="btn btn-danger mt-4 w-100" (click)="openDeletePacket()">{{'delete' | translate}}</button>
  </div>
</div>

<general-delete-modal [Title]="'deletePackage'" [Text]="'thePackageWillBeRemovedFromYourListAreYouSure'"
(selection)="delete($event)"></general-delete-modal>

