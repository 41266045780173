<div class="container-fluid  px-0 mt-4">
  <div class="row px-0">
    <div class="col-xl-11 col-md-10 col-10 m-auto">
      <app-jobs-filter (resultFilter)="getFilteredJobs($event)" (clearFilter)="getClearFilterJobs($event)" (resultSearch)="search($event)"></app-jobs-filter>
      <tlb-empty-page *ngIf="!jobs || jobs.length == 0"   [warningLabel]="searchText == ''  ?
      'noJobYet' : searchText" [filterText]="searchText"></tlb-empty-page>
      <app-recruiter-jobs *ngIf="jobs && jobs.length > 0" [jobs]="jobs" [count]="count" [limit]="5" (getJobListAgain)="ngOnInit()"
        (updateJobs)="getJobs($event)"  (sorted)="sorted($event)" (updatedStatus)="getJobs(1)"></app-recruiter-jobs>
    </div>
  </div>
</div>
