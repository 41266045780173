import {Component, OnInit} from '@angular/core';
import {DropdownOptionsModel, EarningsModel} from 'src/app/shared/models';
import {EarningService} from 'src/app/shared/services';
import {CreateAvatar} from '../../shared/classes';

declare var $: any;

@Component({
             selector: 'app-earning-page',
             templateUrl: './earning-page.component.html',
             styleUrls: ['./earning-page.component.scss']
           })
export class EarningPageComponent implements OnInit {

  sortBy: 'createdAt,asc' | 'createdAt,desc' = 'createdAt,asc';
  filterState: boolean;
  paymentStatusOptions: DropdownOptionsModel[] = [];
  searchText = '';
  earnings: EarningsModel[] = [];
  createAvatar: CreateAvatar;
  requestParams = {};
  paymentStatusLabel = '';

  constructor(private earningService: EarningService) {
    this.createAvatar = new CreateAvatar();
  }

  ngOnInit(): void {
    this.getFilterOptions();
    this.getEarnings();

    $(document)
      .on('click', (event) => {
        const $trigger = $('#filer-dropdown');
        if ($trigger !== event.target && !$trigger.has(event.target).length && $('#filter-dropdown-menu')
          .css('display') === 'block') {
          $('#filterButton')
            .click();
        }
      });
  }

  getFilterOptions(): void {
    this.paymentStatusLabel = '';
    this.paymentStatusOptions = [];
    this.paymentStatusOptions.push({ value: 'GTEE_PERIOD', label: 'GTEE_PERIOD' });
    this.paymentStatusOptions.push({ value: 'PROCESSING', label: 'PROCESSING' });
    this.paymentStatusOptions.push({ value: 'CANCELLED', label: 'CANCELLED' });
    this.paymentStatusOptions.push({ value: 'PAID', label: 'PAID' });

  }

  getEarnings(): void {
    let requestParamsText = '?';

    Object.keys(this.requestParams)
          .forEach(value => {
            if (this.requestParams[value] !== undefined) {
              requestParamsText += `&${value + this.requestParams[value]}`;
            }

          });
    this.earningService.ServerGetWithText(requestParamsText)
        .subscribe(x => {
          this.earnings = x.content;
          this.earnings.forEach(earnings => {
            earnings.inGuaranteeDate = new Date(earnings.inGuarantee);
            earnings.imageText = this.createAvatar.create(earnings.candidate.name);
            earnings.imageColor = this.createAvatar.color();
          });
        });
  }

  sortByChange(): void {
    this.requestParams['sort='] = this.sortBy;
    this.getEarnings();
    this.filterState = false;

  }

  clearFilter(): void {
    this.getFilterOptions();
    this.requestParams = {};
    this.getEarnings();
    this.filterState = false;
  }

  clearSearch(){
    this.searchText = "";
    this.requestParams['filter=freeText~~'] = this.searchText;
    this.getEarnings();
  }

  selectPaymentStatus(data): void {
    this.paymentStatusLabel = data.label;
    this.requestParams['filter=paymentStatus::'] = data.value;
    // this.filterState = false;
  }

  searchChangeEvent(data): void {
      this.requestParams['filter=freeText~~'] = this.searchText;
      this.getEarnings();

  }
}
