import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CreateAvatar, RequestPayload} from 'src/app/shared/classes';
import {DropdownOptionsModel, RecruiterReviewModel} from 'src/app/shared/models';
import {RecruiterUserModel} from 'src/app/shared/models/recruiter/recruiter-user.model';
import {RecruiterService, ReviewService, TranslatePipe} from 'src/app/shared/services';
import {AuthService} from 'src/app/shared/services/auth/auth.service';
import {RecruitersFilterServiceService} from './recruiters-filter-service.service';

@Component({
    selector: 'app-marketplace',
    templateUrl: './marketplace.component.html',
    styleUrls: ['./marketplace.component.scss']
})
export class MarketplaceComponent implements OnInit {

    recruiters: RecruiterUserModel[] = [];
    reviews: any[] = [];
    requestPayload: RequestPayload;
    currentPage = 1;
    count: number;
    sortText = '';
    filterText = '';
    postFilter = '';
    filterData: any = {};
    fromSearch: boolean = false;
    sortLabel1: string = this.translate.transform('ratings');
    sortLabel2: string = this.translate.transform('locations');
    sortOptions1: DropdownOptionsModel[] = [
        {label: this.translate.transform('high'), value: '&sort=rating,DESC'},
        {label: this.translate.transform('low'), value: '&sort=rating,ASC'}
    ];
    sortOptions2: DropdownOptionsModel[] = [
        {label: this.translate.transform('newestFirst'), value: '&sort=createdAt,DESC'},
        {label: this.translate.transform('oldestFirst'), value: '&sort=createdAt,ASC'}
    ];

    createAvatar: CreateAvatar;
    selectedRec: RecruiterUserModel;
    selectedReviews: RecruiterReviewModel[] = [];
    empName = '';
    cameFromReviews = false;
    otherRecruiters: RecruiterUserModel[] = [];
    filtered = false;
    favorited = false;
    activeTab = 0;

    constructor(private translate: TranslatePipe, private recruiterService: RecruiterService,
                private authService: AuthService,
                private reviewService: ReviewService, private router: Router,
                private recruitersFilterServiceService: RecruitersFilterServiceService
    ) {
        this.filterData.individualPracticeTime = '';
        this.filterData.requisitionType = '';
        this.filterData.functionalRoleId = '';
        this.filterData.industryId = '';
        this.requestPayload = new RequestPayload();
        this.requestPayload.limit = 3;
        this.createAvatar = new CreateAvatar();
    }

    ngOnInit() {
        const parsedToken = JSON.parse(
            decodeURIComponent(escape(window.atob((this.authService.getToken()).split('.')[1]))));
        this.empName = parsedToken.first_name;
        this.recruiters = [];
        this.getRecruiters(1);
    }

    selectSort1(data) {
        this.sortLabel1 = data.label;
        this.sortText = data.value;
        this.currentPage = 1;
        this.getRecruiters(1);

    }

    selectSort2(data) {
        this.sortLabel2 = data.label;
        this.sortText = data.value;
        this.getRecruiters(1);
    }

    getRecruiters(pageNo) {
       // this.filtered ? this.currentPage = 1 : null;
        this.recruiterService.ServerGetWithText(this.requestPayload.payloadURLApp(
            pageNo) + this.sortText + this.requestFilter() + (this.favorited ? '&filter=favorite::true' : ''))
            .subscribe((res: any) => {
                this.fromSearch ? this.currentPage = 1 : null;
                this.fromSearch = false;
                this.recruiters = res.recruiters;


                this.recruiters.forEach(f => {
                    f.imageText = this.createAvatar.create(f.user.fullName);
                    f.imageColor = this.createAvatar.color();
                });

                this.otherRecruiters = res.recruiterPagination.content;
                this.otherRecruiters.forEach(f => {
                    f.imageText = this.createAvatar.create(f.user.fullName);
                    f.imageColor = this.createAvatar.color();
                });

                this.count = res.recruiterPagination.totalElements;

            });
    }

    requestFilter() {


        return '&filter=individualPracticeTime**' + this.filterData.individualPracticeTime +
            '&filter=requisitionTypes.requisitionType**' + this.filterData.requisitionType +
            '&filter=functionalRoles.functionalRoleId**' + this.filterData.functionalRoleId +
            '&filter=industries.industryId**' + this.filterData.industryId +
            '&filter=name~~' + this.filterText;
    }


    getFilteredRec(e) {
        console.log(e);
        this.filtered = true;
        this.filterData = e;
        this.currentPage = 1;
        this.getRecruiters(1);

    }

    clearFilter(e) {
        this.filterData = e;
        this.getRecruiters(1);
    }

    search(data) {
        // this.filterText = data;
        // this.fromSearch = true;
        this.getRecruiters(1);

    }


    onFilter() {
        this.filtered = this.filterText != '';
        this.fromSearch = true;
        // this.postFilter = '&filter=name~~' + this.filterText;
        this.getRecruiters(1);
    }

    clearSearch() {
        this.filtered = false;
        this.fromSearch = true;
        this.filterText = '';
        this.getRecruiters(1);
    }

    openDetailModal(recruiter: RecruiterUserModel, reviews: boolean = false, reco: boolean = false) {
        let postReco = reco ? '1' : '0';
        this.router.navigate(['/recruiter-details/' + recruiter.id + '/' + postReco]);
    }


    changeActiveTab(tab: number): void {
        this.activeTab = tab;
        switch (this.activeTab) {
            case 0:
                this.favorited = false;
                break;
            case 1:
                this.favorited = true;
                break;
        }

        this.sortLabel1 = this.translate.transform('ratings');
        this.sortText = '';
        this.sortOptions1 = [
            {label: this.translate.transform('high'), value: '&sort=rating,DESC'},
            {label: this.translate.transform('low'), value: '&sort=rating,ASC'}
        ];
        this.recruitersFilterServiceService.clearFilterAndSortSubject.next();
        this.getRecruiters(1);
    }

    favoriteRecruiter(recruiter: RecruiterUserModel): void {
        recruiter.favorite = true;
        this.recruiterService.favoriteRecruiter(recruiter.id)
            .subscribe(x => {

            }, error => {
                recruiter.favorite = false;
            });
    }

    unFavoriteRecruiter(recruiter: RecruiterUserModel): void {
        recruiter.favorite = false;
        this.recruiterService.unFavoriteRecruiter(recruiter.id)
            .subscribe(x => {

            }, error => {
                recruiter.favorite = true;
            });
    }

    filterControl(event: any) {
        if (event.key === '*' || event.key === '!' || event.key === '.' || event.key === ',' || event.key === '~') {
            event.preventDefault();
        }
    }
}
