import { RecruiterReviewModel } from './../../../../shared/models/recruiter/recruiter-review.model';
import { Component, OnInit,Input } from '@angular/core';


@Component({
  selector: 'app-employer-recruiter-review-popup',
  templateUrl: './employer-recruiter-review-popup.component.html',
  styleUrls: ['./employer-recruiter-review-popup.component.scss']
})
export class EmployerRecruiterReviewPopupComponent implements OnInit {

  @Input() reviews:any[] = [];

  constructor() {
  }

  ngOnInit(): void {

  }

  ngOnChanges(){
  }



}
