<!-- Modal -->
<div class="modal fade" id="admin-job-profile-detail-popup">
  <div class="modal-dialog ">
    <div class="modal-content">
      <div class="modal-header">
        <span class="header-text">{{'jobProfile' | translate}}</span>

      </div>
      <div class="modal-body py-0">
        <div class="container">
          <div class="row">
            <div class="col-12 pr-1 pl-0">
              <div class="card border-0">
                <div class="card-body">
                  <!-- body -->
                  <div class="row">
                    <div class="col-6">

                      <div class="form-group">
                        <label class="form-label"> {{ 'jobTitle' | translate }}<span class="text-danger">*</span>
                        </label>
                        <input [(ngModel)]="selectedJobProfile.title"
                          [style]="selectedJobProfile.title == '' ? 'background: #FFFFFF;' : 'background: #FEFCE5;'"
                          type="text" class="form-control form-area" placeholder="{{ 'jobTitle' | translate }}"
                          [ngModelOptions]="{standalone: true}" />
                      </div>

                      <div class="form-group">
                        <label for="exampleInputEmail1" class="form-label"> {{ 'jobClassification' | translate }}<span
                            class="text-danger">*</span>
                        </label>
                        <tlb-dropdown [items]="classificationOptions"
                          [dropdownName]="selectedJobProfile.id ? classificationLabel : 'pleaseSelect'"
                          [style]="selectedJobProfile.classification ? 'background: #FEFCE5; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px;' :
                        'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px;'"
                          [textStyle]="'font-style: normal; font-weight: 500; font-size: 14px; color: #323332;'"
                          [isFilter]="false" (searchData)="optionChangeWithId($event, 'classification')">
                        </tlb-dropdown>
                      </div>

                      <div class="form-group">
                        <label for="exampleInputEmail1" class="form-label"> {{ 'experienceLevel' | translate }}<span
                            class="text-danger">*</span>
                        </label>
                        <tlb-dropdown [items]="experienceLevelOptions"
                          [dropdownName]="selectedJobProfile.id ? experienceLevel : 'pleaseSelect'"
                          [style]="selectedJobProfile.experienceLevel != '' ? 'background: #FEFCE5; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px;' :
                        'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px;'"
                          [textStyle]="'font-style: normal; font-weight: 500; font-size: 14px; color: #323332;'"
                          [isFilter]="false" (searchData)="optionChangeWithString($event, 'experience')">
                        </tlb-dropdown>
                      </div>

                      <div class="form-group">
                        <label for="exampleInputEmail1" class="form-label"> {{ 'country' | translate }}<span
                            class="text-danger">*</span>
                        </label>
                        <tlb-dropdown [items]="countryOptions"
                          [dropdownName]="selectedJobProfile.id ? countryLabel : 'pleaseSelect'"
                          [style]="selectedJobProfile.location.country ? 'background: #FEFCE5; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px;' :
                        'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px;'"
                          [textStyle]="'font-style: normal; font-weight: 500; font-size: 14px; color: #323332;'"
                          [isFilter]="false" (searchData)="optionChangeWithId($event, 'country')">
                        </tlb-dropdown>
                      </div>

                      <div class="form-group">
                        <label class="form-label"> {{ 'zipCode' | translate }}<span class="text-danger">*</span>
                        </label>
                        <input [(ngModel)]="selectedJobProfile.location.zipCode" type="text"
                        [style]="selectedJobProfile.location.zipCode == '' ? 'background: #FFFFFF;' : 'background: #FEFCE5;'"
                          class="form-control form-area" placeholder="{{ 'zipCode' | translate }}"
                          [ngModelOptions]="{standalone: true}" />
                      </div>

                      <div class="form-group">
                        <label for="exampleFormControlSelect1" class="form-label">{{ 'experienceRequired' |
                          translate }}<span class="text-danger">*</span>
                        </label>
                        <div class="row">
                          <div class="col-6">
                            <input [(ngModel)]="selectedJobProfile.experienceRequired.year" type="number"
                            [style]="selectedJobProfile.experienceRequired.year == null ? 'background: #FFFFFF;' : 'background: #FEFCE5;'"
                              class="form-control form-area"  placeholder="{{ 'year' | translate }}"
                              [ngModelOptions]="{standalone: true}" />
                          </div>
                          <div class="col-6">
                            <input [(ngModel)]="selectedJobProfile.experienceRequired.month" type="number"
                            [style]="selectedJobProfile.experienceRequired.month == null ? 'background: #FFFFFF;' : 'background: #FEFCE5;'"
                              class="form-control form-area"  placeholder="{{ 'month' | translate }}"
                              [ngModelOptions]="{standalone: true}" />
                          </div>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="exampleFormControlSelect1" class="form-label">{{ 'relocationAssistance' |
                          translate }}<span class="text-danger">*</span></label>
                        <tlb-dropdown [items]="relocationAssistanceOptions"
                        [dropdownName]="selectedJobProfile.id ? relocationLabel : 'pleaseSelect'"
                        [style]="selectedJobProfile.relocationAssistance.type ? 'background: #FEFCE5; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px;' :
                        'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px;'"
                          [textStyle]="'font-style: normal; font-weight: 500; font-size: 14px; color: #323332;'"
                          [isFilter]="false" (searchData)="optionChangeWithString($event, 'relocation')">
                        </tlb-dropdown>
                      </div>

                    </div>
                    <div class="col-6">

                      <div class="form-group">
                        <label for="exampleInputEmail1" class="form-label"> {{ 'industry' | translate }}<span
                            class="text-danger">*</span></label>
                        <tlb-dropdown [items]="industryOptions"
                          [dropdownName]="selectedJobProfile.id  ? industryLabel : 'pleaseSelect'"
                          [style]="selectedJobProfile.industry ? 'background: #FEFCE5; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px;' :
                        'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px;'"
                          [textStyle]="'font-style: normal; font-weight: 500; font-size: 14px; color: #323332;'"
                          [isFilter]="false" (searchData)="optionChangeWithId($event, 'industry')">
                        </tlb-dropdown>
                        <!-- <input type="text" class="form-control form-area"  placeholder="adınız" /> -->
                      </div>

                      <div class="form-group">
                        <label for="exampleInputEmail1" class="form-label"> {{ 'jobFunction' | translate }}<span
                            class="text-danger">*</span>
                        </label>
                        <tlb-dropdown [items]="functionOptions"
                          [dropdownName]="selectedJobProfile.id ? functionLabel : 'pleaseSelect'"
                          [style]="selectedJobProfile.function ? 'background: #FEFCE5; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px;' :
                        'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px;'"
                          [textStyle]="'font-style: normal; font-weight: 500; font-size: 14px; color: #323332;'"
                          [isFilter]="false" (searchData)="optionChangeWithId($event, 'function')">
                        </tlb-dropdown>
                      </div>

                      <div class="form-group">
                        <label for="exampleInputEmail1" class="form-label"> {{ 'educationLevel' | translate }}<span
                            class="text-danger">*</span>
                        </label>
                        <tlb-dropdown [items]="educationLevelOptions"
                          [dropdownName]="selectedJobProfile.id ? educationLabel : 'pleaseSelect'"
                          [style]="selectedJobProfile.educationLevel != '' ? 'background: #FEFCE5; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px;' :
                        'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px;'"
                          [textStyle]="'font-style: normal; font-weight: 500; font-size: 14px; color: #323332;'"
                          [isFilter]="false" (searchData)="optionChangeWithString($event, 'education')">
                        </tlb-dropdown>
                      </div>

                      <div class="form-group">
                        <label for="exampleInputEmail1" class="form-label"> {{ 'city' | translate }}<span
                            class="text-danger">*</span>
                        </label>
                        <tlb-dropdown [items]="cityOptions"
                          [dropdownName]="selectedJobProfile.id ? cityLabel : 'pleaseSelect'"
                          [style]="selectedJobProfile.location.city ? 'background: #FEFCE5; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px;' :
                        'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px;'"
                          [textStyle]="'font-style: normal; font-weight: 500; font-size: 14px; color: #323332;'"
                          [isFilter]="true" (searchData)="optionChangeWithId($event, 'city')">
                        </tlb-dropdown>
                      </div>

                      <div class="form-group">
                        <label for="exampleInputEmail1" class="form-label"> {{ 'jobSegment' | translate }}<span
                            class="text-danger">*</span></label>
                        <tlb-dropdown [items]="segmentOptions" [dropdownName]="selectedJobProfile.id ? segmentLabel : 'pleaseSelect'"
                          [style]="selectedJobProfile.jobSegment ? 'background: #FEFCE5; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px;' :
                        'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px;'"
                          [textStyle]="'font-style: normal; font-weight: 500; font-size: 14px; color: #323332;'"
                          [isFilter]="false" (searchData)="optionChangeWithId($event, 'segment')">
                        </tlb-dropdown>
                        <!-- <input type="text" class="form-control form-area"  placeholder="adınız" /> -->
                      </div>

                      <div class="form-group">
                        <label for="exampleFormControlSelect1" class="form-label-small">{{ 'skills' | translate }}<span
                            class="text-danger">*</span></label>
                        <input [(ngModel)]="skillsString" name="skills" class="form-control form-area" id="skill"
                        [style]="skillsString == '' ? 'background: #FFFFFF;' : 'background: #FEFCE5;'"
                          type="text" placeholder="{{'addCommaToEnterMultipleSkills' | translate}}"
                          (keyup.enter)="onEnter()">
                      </div>


                      <div class="form-group">
                        <label for="exampleFormControlSelect1" class="form-label-small">{{
                          'additionalRelocationAssistanceInformation' | translate }}</label>
                        <input [(ngModel)]="selectedJobProfile.relocationAssistance.additional" class="form-control form-area"
                        [style]="selectedJobProfile.relocationAssistance.additional == '' ? 'background: #FFFFFF;' : 'background: #FEFCE5;'"
                          id="relocation" placeholder="{{'additionalRelocationAssistanceInformation' | translate}}"
                          [ngModelOptions]="{standalone: true}">
                      </div>

                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <label for="exampleFormControlTextarea1" class="form-label">{{ 'jobDescription' | translate
                          }}<span class="text-danger">*</span>
                        </label>
                        <textarea [(ngModel)]="selectedJobProfile.description" class="form-control form-area"
                        [style]="selectedJobProfile.description == '' ? 'background: #FFFFFF;' : 'background: #FEFCE5;'"
                          id="exampleFormControlTextarea1" rows="3" style="height: 100%;"
                          [ngModelOptions]="{standalone: true}"></textarea>
                      </div>
                    </div>


                  </div>


                </div>
              </div>
            </div>

          </div>

        </div>

      </div>
      <div class="modal-footer py-4">
        <div class="container">
          <div class="row">
            <div class="col-12 col-sm-6 col-lg-3">
              <button class="form-control close-button" data-dismiss="modal">
                {{'close'|translate}}
              </button>
            </div>
            <div class="col-12 col-sm-6 col-lg-3  ml-auto">
              <button class="form-control save-button" data-dismiss="modal" (click)="save()">
                {{'save'|translate}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
