export const ServerErrorList = {

INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
FEIGN_SERVER_ERROR: 'FEIGN_SERVER_ERROR', // Servisler arası iletişimde sunucu bazlı hata
FEIGN_CLIENT_ERROR: 'FEIGN_CLIENT_ERROR', // Servisler arası isteklerde 4xx hatası
// UAA
INVALID_GRANT: 'INVALID_GRANT', // Kullanıcı adı veya parola hatalı
INVALID_BEARER_TOKEN: 'INVALID_BEARER_TOKEN' , // Geçersiz token
INVALID_JWT: 'INVALID_JWT', // Geçersiz JWT
ACCESS_DENIED: 'ACCESS_DENIED', // Kullanıcının bu kaynağa erişim yetkisi yok
USER_NOT_FOUND: 'USER_NOT_FOUND', // Kullanıcı bulunamadı
USER_HAS_NO_PASSWORD: 'USER_HAS_NO_PASSWORD', // Yeni oluşturulan kullanıcı henüz ilk parolasını tanımlamadan login olmaya çalışıyor
WRONG_PASSWORD: 'WRONG_PASSWORD', // Kullanıcı parola değiştirmede mevcut parolasını yanlış girmiş
// Resource
RESOURCE_NOT_FOUND: 'RESOURCE_NOT_FOUND', // Ulaşılmak istenen obje bulunamadı
RESOURCE_DELETED: 'RESOURCE_DELETED', // Ulaşılmak istenen obje DELETED statüsünde
RESOURCE_ALREADY_EXISTS: 'RESOURCE_ALREADY_EXISTS', // Kullanılan business id (email, mobile, vs.) ile halihazırda obje mevcut
// JPA
DATA_INTEGRITY_VIOLATION: 'DATA_INTEGRITY_VIOLATION', // Veritabanında UQ, FK, Constraint Violation hatası
// Request validation
INVALID_REQUEST_FORMAT: 'INVALID_REQUEST_FORMAT', // Request body'deki json geçersiz. Misal UUID olması gereken bir alan farklı formatta.
VALIDATION_ERROR: 'VALIDATION_ERROR', // Request body olarak gelen objede geçersiz alan(lar) var. Suberror listesine bakmak gerek.
// General
INVALID_VALUE: 'INVALID_VALUE', // Geçersiz değer

// code=VALIDATION_ERROR olduğunda subErrors[n].code değerleri:
INVALID_FIELD: 'INVALID_FIELD',
MUST_BE_NULL: 'MUST_BE_NULL',
MUST_NOT_BE_NULL: 'MUST_NOT_BE_NULL',
MUST_BE_TRUE: 'MUST_BE_TRUE',
MUST_BE_FALSE: 'MUST_BE_FALSE',
MIN_VALUE_EXCEEDED: 'MIN_VALUE_EXCEEDED',
MAX_VALUE_EXCEEDED: 'MAX_VALUE_EXCEEDED',
DECIMAL_MIN_VALUE_EXCEEDED: 'DECIMAL_MIN_VALUE_EXCEEDED',
DECIMAL_MAX_VALUE_EXCEEDED: 'DECIMAL_MAX_VALUE_EXCEEDED',
MUST_BE_NEGATIVE: 'MUST_BE_NEGATIVE',
MUST_BE_NEGATIVE_OR_ZERO: 'MUST_BE_NEGATIVE_OR_ZERO',
MUST_BE_POSITIVE: 'MUST_BE_POSITIVE',
MUST_BE_POSITIVE_OR_ZERO: 'MUST_BE_POSITIVE_OR_ZERO',
INVALID_SIZE: 'INVALID_SIZE',
INVALID_DIGITS: 'INVALID_DIGITS',
MUST_BE_IN_THE_PAST: 'MUST_BE_IN_THE_PAST',
MUST_BE_IN_THE_PAST_OR_PRESENT: 'MUST_BE_IN_THE_PAST_OR_PRESENT',
MUST_BE_IN_THE_FUTURE: 'MUST_BE_IN_THE_FUTURE',
MUST_BE_IN_THE_FUTURE_OR_PRESENT: 'MUST_BE_IN_THE_FUTURE_OR_PRESENT',
MUST_MATCH_THE_REGEX: 'MUST_MATCH_THE_REGEX',
MUST_NOT_BE_EMPTY: 'MUST_NOT_BE_EMPTY',
MUST_NOT_BE_BLANK: 'MUST_NOT_BE_BLANK',
INVALID_EMAIL: 'INVALID_EMAIL',
BALANCE_NOT_ENOUGH:'BALANCE_NOT_ENOUGH'

};
