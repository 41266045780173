import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit {

  @Input() Title: '';
  @Input() Text: '';
  @Output() selection = new EventEmitter();
  @Input() ImageSrc: './../../../assets/images/talentbase/delete-icon.png';

  constructor() { }

  ngOnInit() {
  }

  selectYesOrNo(flag){
    this.selection.emit(flag);
  }

}
