import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApplicationService, NotificationService, TranslatePipe } from 'src/app/shared/services';

@Component({
  selector: 'app-cancel-application-modal',
  templateUrl: './cancel-application-modal.component.html',
  styleUrls: ['./cancel-application-modal.component.scss']
})
export class CancelApplicationModalComponent implements OnInit {

  @Input() applicationId: string = "";
  @Output() cancelledApplication: EventEmitter<any> = new EventEmitter();

  constructor(private applicationService: ApplicationService, private notification: NotificationService, private translate: TranslatePipe) { }

  ngOnInit() {
  }


  deleteApplication() {
    this.applicationService.CancelApplication(this.applicationId).subscribe(x => {
      this.notification.success(this.translate.transform('theApplicationWasCanceledSuccessfully'));
      this.cancelledApplication.emit();

      // @ts-ignore
      $('#cancelApplicationModal').modal('hide');
      // @ts-ignore
      $('#submitCandidateModal').modal('hide');

    });

  }

}
