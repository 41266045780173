import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from '../auth';
import { NotificationService } from '../notification';
import { server } from '../server';
import { TranslatePipe } from '../translate';

@Injectable({
  providedIn: 'root'
})
export class SubmitApplicationService extends ApiService {

  constructor(public http: HttpClient, public spinner: NgxSpinnerService, public translate: TranslatePipe, public notification: NotificationService) {
    super(http, server.submitApplication, spinner, translate, notification)
  }
}
