<div *ngIf="filterData" class="row">
  <div class="col-12">
    <div class="row">

      <div class="col-12 my-2 my-lg-0 px-4 px-xl-3 search-left" [ngClass]="showAllFilter ? 'col-lg-5' : 'col-lg-8'">
        <button class="search-button" style="height: 45px;" #searchInput (keyup)="keyup(searchInput.value)"
          (click)="search()" type="submit"><i class="fa fa-search search-icon"></i></button>
        <input [(ngModel)]="filterText" type="text"  #searchInput (keyup)="keyup(searchInput.value)"
          placeholder="{{filterType == 'title' ? ('searchJob' | translate) : ('searchForKeyWords' | translate)}}"
          class="form-control d-inline float-right search-input search-width" style="height: 45px;  "> <br>
        <label *ngIf="filterText != ''" class="clear-search mt-2" for="" (click)="clearSearch()"><i
            class="fas fa-times mr-1" style="color: #707683;"></i> {{'clearSearch' | translate}} </label>
      </div>


      <div [ngClass]="{'ml-auto': !showAllFilter}" class=" col-12 col-lg-4 my-2 my-lg-0 px-xl-3">
        <tlb-dropdown [items]="keyWordsFilterOptions" (clickedButton)="clicked()"
          [dropdownName]="keyWordsLabel != '' ? keyWordsLabel : 'selectSearchType'" [textStyle]="'margin-top: 4px;'"
          (searchData)="selectKeyWords($event)" [isFilter]="false"
          [style]="'background-color: white; border: 1px solid #CFD0D7; height: 45px;'">
        </tlb-dropdown>
      </div>

      <div *ngIf="showAllFilter" class="dropdown drp col-12 col-lg-3" id="filer-dropdown">
        <button (click)="filterState = !filterState" class="btn dropdown-menus form-control mb-1 d-inline ml-lg-4"
          id="filterButton" type="button">
          <!-- <i class="fas fa-filter mt-1"></i> -->
          <span class="text-truncate d-inline-block;">{{'showAllFilter' | translate}}</span>
        </button>
        <div (clickOutside)="closeFilterPopup($event)" [style.display]="filterState ? 'block':'none'"
          aria-labelledby="dropdownMenuButton" id="filter-dropdown-menu" class="dropdown-menu col-12 pb-0"
          style="min-width: 14rem;">

          <div class="dropdown-item color-dropdown-item filters-header" id="header">
            {{'filters' | translate}}
          </div>
          <!-- <p class="filters-header">{{'filters' | translate}}</p> -->

          <div class="dropdown-item color-dropdown-item" id="country">
            <tlb-dropdown [items]="countryOptions" [dropdownName]="countryLabel != '' ? countryLabel : 'country'"
              (searchData)="selectCountry($event)"
              [style]="'text-align: left;  width: 100%; border: 1px solid #DCDCDC; box-sizing: border-box; box-shadow: 0px 2px 4px rgb(0 0 0 / 10%); border-radius: 7px; height: 40px;'"
              [textStyle]="'font-family: Roboto; font-style: normal; font-weight: 500; font-size: 15px; line-height: 16px; letter-spacing: 0.1px; color: #252B42 !important;'">
            </tlb-dropdown>
          </div>

          <div class="dropdown-item color-dropdown-item" id="city">

            <tlb-multiselect-dropdown [alertText]="alertText" (clicked)="clickedCity()"
              (selectResult)="selectCity($event)" [isFilter]="false" [items]="cities"
              [dropdownName]="cityLabel != '' ? cityLabel : 'city'" [existItems]="selectedCities"
              [style]="'text-align: left; border: 1px solid #DCDCDC; box-sizing: border-box; box-shadow: 0px 2px 4px rgb(0 0 0 / 10%); border-radius: 7px; height: 40px;'"
              [textStyle]="'font-family: Roboto; font-style: normal; font-weight: 500; font-size: 15px; line-height: 16px; letter-spacing: 0.1px; color: #252B42 !important;'">
            </tlb-multiselect-dropdown>
          </div>

          <div class="dropdown-item color-dropdown-item" id="type">

            <tlb-multiselect-dropdown (selectResult)="selectType($event)" [isFilter]="false" [items]="jobTypeOptions"
              [dropdownName]="jobTypeLabel != '' ? jobTypeLabel : 'jobType'" [defaultItemKey]="''"
              [existItems]="selectedJobTypes"
              [style]="'text-align: left; border: 1px solid #DCDCDC; box-sizing: border-box; box-shadow: 0px 2px 4px rgb(0 0 0 / 10%); border-radius: 7px; height: 40px;'"
              [textStyle]="'font-family: Roboto; font-style: normal; font-weight: 500; font-size: 15px; line-height: 16px; letter-spacing: 0.1px; color: #252B42 !important;'">
            </tlb-multiselect-dropdown>
          </div>

          <div class="dropdown-item color-dropdown-item" id="type">

            <tlb-multiselect-dropdown (selectResult)="selectFunction($event)" [isFilter]="false" [items]="jobFunctions"
              [dropdownName]="jobFunctionLabel != '' ? jobFunctionLabel : 'jobFunction'"
              [existItems]="selectedFunctions" [dropDownMenuWidth]="'17rem'"
              [style]="'text-align: left; border: 1px solid #DCDCDC; box-sizing: border-box; box-shadow: 0px 2px 4px rgb(0 0 0 / 10%); border-radius: 7px; height: 40px;'"
              [textStyle]="'font-family: Roboto; font-style: normal; font-weight: 500; font-size: 15px; line-height: 16px; letter-spacing: 0.1px; color: #252B42 !important;'">
            </tlb-multiselect-dropdown>
          </div>

          <div class="dropdown-item color-dropdown-item" id="type">

            <tlb-multiselect-dropdown (selectResult)="selectIndustry($event)" [isFilter]="false" [items]="industries"
              [dropdownName]="industryLabel != '' ? industryLabel : 'industry'" [existItems]="selectedIndustries"
              [dropDownMenuWidth]="'15rem'"
              [style]="'text-align: left; border: 1px solid #DCDCDC; box-sizing: border-box; box-shadow: 0px 2px 4px rgb(0 0 0 / 10%); border-radius: 7px; height: 40px;'"
              [textStyle]="'font-family: Roboto; font-style: normal; font-weight: 500; font-size: 15px; line-height: 16px; letter-spacing: 0.1px; color: #252B42 !important;'">
            </tlb-multiselect-dropdown>
          </div>

          <!-- <div class="dropdown-item color-dropdown-item" id="type">

                <tlb-multiselect-dropdown (selectResult)="selectFeeType($event)" [isFilter]="false" [items]="feeTypeOptions"
                [dropdownName]="feeTypeLabel != '' ? feeTypeLabel : 'referralReward'" [defaultItemKey]="''" [existItems]="selectedFeeTypes">
                </tlb-multiselect-dropdown>
              </div> -->


          <div class="row mr-4" style="justify-content: end;">

            <div class="mt-2 mb-2 mr-1">
              <button (click)="clearFilterEmit()" class="btn clear-filter-btn" type="button">{{'discard' |
                translate}}</button>
            </div>
            <div class="mt-2">
              <button (click)="emitData()" class="btn apply-filter-btn" type="button">{{'apply' |
                translate}}</button>
            </div>
          </div>


        </div>
      </div>




    </div>

  </div>
</div>
