import { RecruiterPlacementCountsModel } from './recruiter-placement-counts.model';

export class RecruiterAboutModel {

  type: string;
  requisitionTypes: string[];
  talentbaseReferenceType: string
  individualPracticeTime: string;
  placementCounts: RecruiterPlacementCountsModel;
  aboutMe: string;

  constructor() {
  this.type = "";
  this.requisitionTypes = [];
  this.talentbaseReferenceType = "";
  this.individualPracticeTime = "";
  this.placementCounts = new RecruiterPlacementCountsModel;
  this.aboutMe = "";
  }
  }
