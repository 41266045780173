import { Component, OnInit, OnDestroy } from '@angular/core';

declare var $:any;
@Component({
  selector: 'app-login-layout',
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.scss']
})
export class LoginLayoutComponent implements OnInit,OnDestroy {

  constructor() {
  }

  ngOnInit(): void {
    $('html').css('background','-webkit-radial-gradient(120% 80%, circle, #f9fafc 47%, #f3f5fd 22px)');
    $('body').css('background','#00000000');
  }

  ngOnDestroy(): void {
    $('html').css('background','');
    $('body').css('background','');
  }

}
