export const LANG_EN_NAME = 'en';

export const LANG_EN_TRANS = {
    logoName: 'Picktus',
    appName: 'App Name',
    welcome: 'Welcome',
    welcomeToTalentbase: 'Welcome to Picktus',
    pleaseLogin: 'Please Login',
    emailVerification: 'E-Mail Verification',
    resetPassword: 'Password Reset',
    successEmailVerify: 'Congratulations! Your e-mail has been successfully verified.',
    errorEmailVerify: 'Sorry but there was a problem verifying your e-mail. Please try again.',
    user: 'User',
    login: 'Login',
    signIn: 'Sign In',
    signUp: 'Sign Up',
    logOut: 'Logout',
    forgotPassword: 'Forgot Password',
    loginErrorInfo: 'Username or password is incorrect. Please check your credentials',
    loginErrorGeneralInfo: 'A general problem occured. Please try again later',
    updateSuccessful: 'Update is successful',
    createSuccessful: 'Successfully created',
    deleteSuccessful: 'Successfully deleted',
    passwordRequestSuccessful: 'Password Request is sent successfully',
    saveSuccessful: 'Successfully saved',
    previous: 'Previous',
    saveAndFinish: 'Save and Finish',

    whatIsIt: 'What Is It',
    howItWork: 'How It Work',
    successStories: 'Success Stories',
    faq: 'FAQ',
    contactUs: 'Contact Us',
    recruiters: 'Recruiters',
    employers: 'Employers',
    postedJobs: 'Posted Jobs',
    hr: 'HR',
    createEmployerAccount: 'Create Employer Account',
    createRecruiterAccount: 'Create Recruiter Account',
    firstName: 'First Name',
    lastName: 'Last Name',
    phoneNumber: 'Phone Number',
    phoneNumberCode: 'Number Code',
    email: 'Email',
    password: 'Password',
    companyName: 'Company Name',

    generalInfo: 'General Info',
    basicDetails: 'Basic Details',
    companyInfo: 'Company Info',
    locationInfo: 'Location Info',
    companySocialLinks: 'Company Social Links',

    myDashboard: 'My Dashboard',
    trackCandidates: 'Track Candidates',
    candidates: 'Candidates',
    jobs: 'Browse Jobs',
    myjobbs: 'My Jobs',
    support: 'Support',
    dashboard: 'Dashboard',

    allJobs: 'All Jobs',
    allRecruiters: 'All Recruiters',
    allEmployers: 'All Employers',
    Employer: 'EMPLOYER',
    Recruiter: 'RECRUITER',
    faqMenu: 'FAQ',
    totalRecruiterCount: 'Total Recruiter Count',
    totalEmployerCount: 'Total Employer Count',
    numberOfTotalJobs: 'Number of Total Jobs',
    jobsClosed: 'Jobs Closed',
    dateOfEntry: 'Date of Entry',
    totalNumberOfPositions: 'Total number of positions',
    adminJobLocation: 'Job Location',
    jobStatus: 'Job Status',

    contactNumberCode: 'Contact Number Code',
    contactPersonFirstName: 'Contact Person First Name',
    contactPersonLastName: 'Contact Person Last Name',
    contactPersonEmailAddress: 'Contact Person Email Address',
    employerContactPhoneNumber: 'Employer Contact Number',
    size: 'Size',
    companySizeOfEmployees: 'Company Size of Employees',
    websiteURL: 'Website URL',

    turkey: 'Turkey',

    industry: 'Industry',
    selectIndustry: 'Select Industry',
    companyLogo: 'Company Logo',
    uploadCompanyLogo: 'Upload Company Logo',
    companyImage: 'Company Image',
    uploadCompanyImage: 'Upload Company Image',
    aboutCompany: 'About Company',
    select: 'Select',

    addressLine1: 'Address Line 1',
    addressLine2: 'Address Line 2',
    country: 'Country',
    city: 'City',
    zipCode: 'Zip Code',

    linkedinProfileLink: 'Company LinkedIn Profile ',
    facebookProfileLink: 'Company Facebook Profile ',
    twitterProfileLink: ' Company Twitter Profile ',
    instagramProfileLink: 'Company Instagram Profile ',
    googleplusProfileLink: 'Google+  Profile Link',
    doYouHaveATS: 'Do you currently have an applicant tracking system (ATS)?',
    yes: 'Yes',
    no: 'No',

    basicInfo: 'Basic Info',
    aboutYou: 'About You',
    companyExperience: 'Company Experience',
    paymentPreferences: 'Payment Preferences',
    yourSpecialization: 'Your Specialization',
    industrySpecialization: 'Industry Specialization',
    locationInformation: 'Location Information',

    oneOfFiveQuestionInThisStep: '1 of 5 question in this step',
    twoOfFiveQuestionInThisStep: '2 of 5 question in this step',
    threeOfFiveQuestionInThisStep: '3 of 5 question in this step',
    fourOfFiveQuestionInThisStep: '4 of 5 question in this step',
    fiveOfFiveQuestionInThisStep: '4 of 5 question in this step',
    oneOfOneQuestionInThisStep: '1 of 1 question in this step',

    oneOfSixQuestionInThisStep: '1 of 6 question in this step',
    twoOfSixQuestionInThisStep: '2 of 6 question in this step',
    threeOfSixQuestionInThisStep: '3 of 6 question in this step',
    fourOfSixQuestionInThisStep: '4 of 6 question in this step',
    fiveOfSixQuestionInThisStep: '5 of 6 question in this step',
    sixOfSixQuestionInThisStep: '6 of 6 question in this step',

    oneOfthreeQuestionInThisStep: '1 of 3 question in this step',
    twoOfthreeQuestionInThisStep: '2 of 3 question in this step',
    threeOfthreeQuestionInThisStep: '3 of 3 question in this step',

    whoAreYou: 'Who Are You ?',
    individualRecruiter: 'Individual Recruiter',
    recruitmentFirm: 'Recruitment Firm',
    others: 'Others',
    recruimentFirmText: 'Please Enter Company Name',
    othersText: 'Please explain your role briefly.',
    pleaseSpecify: 'Please Specify',

    howLongHaveYouRecruitedForTheMarket: 'How long have you recruited for the market?',

    WhatTypeOfJobRequisitionsDoYouRecruitFor: 'What type of job requisitions do you recruit for?',
    permanentDirectHire: 'Permanent / Direct Hire',
    temporaryContractHire: 'Temporary / Contrac Hire',
    ContingentLaborContractRole: 'Contingent labor/Contract role',
    TemporaryToDirectHire: 'Temporary to direct hire',
    recruitmentProcessOutsourcing: 'Recruiment Process Outsourcing',
    other: 'Other',

    PERMANENTREC: 'Permanent / Direct Hire',
    TEMPORARYREC: 'Temporary / Contrac Hire',
    CONTRACTREC: 'Contingent labor/Contract role',
    OUTSOURCINGREC: 'Recruiment Process Outsourcing',
    OTHERREC: 'Other',

    whatIsYourPrimaryAndSecondaryContactNumber: 'What is your primary and secondary contact number?',
    primaryContactNumber: 'Primary Contact Number',
    secondaryContactNumber: 'Secondary Contact Number',

    whatBringsYouToTalentbase: 'What brings you to Picktus?',
    iGrowMyRecruitingBusiness: 'I grow my recruiting business',
    iAmInBetweenJobsAndLookingForAnOpportunityToFillJobVacanciesAndEarnPlacementFees: 'I am in between jobs and looking for an opportunity to fill job vacancies and earn placement fees.',
    justBrowsing: 'Just browsing.',
    iHaveAFullTimeJobButIAmLookingToSupplementMyIncome: 'I have a full-time job but I am looking to supplement my income.',

    howDoYouWishToReceiveThePaymentAfterMakingASuccessfulPlacement: 'How do you wish to receive the payment after making a successful placement?',

    selectUpToFiveFunctionalRolesThatRepresentYourRecruitingExpertise: 'Select up to 5 functional roles that represent your recruiting expertise.',
    accountingFinance: 'Accounting & Finance',
    administrative: 'Administrative',
    customerService: 'Customer Service',
    education: 'Education',
    salesBusinessDevelopment: 'Sales & Business Development',
    marketingPRAdvertising: 'Marketing, PR & Advertising',
    management: 'Management',
    humanResources: 'Human Resources',
    legal: 'Legal',
    engineering: 'Engineering',
    informationTechnology: 'Information Technology',
    productionManufacturing: 'Production & Manufacturing',
    healthcare: 'Healthcare',
    researchDevelopment: 'Research Development',
    nonITConsultant: 'Non IT Consultant',
    technicianMechanic: 'Technician & Mechanic',

    selectUpToFiveIndustriesThatRepresentYourRecruitingExpertise: 'Select up to 5 industries that represent your recruiting expertise.',

    agricultureForestryFishingMining: 'Agriculture, Forestry, Fishing & Mining',

    automotiveAviation: 'Automotive & Aviation',
    staffingRecruitingHumanResources: 'Staffing, Recruiting & Human Resources',
    bankingFinancialServicesInsurance: 'Banking, Financial Services & Insurance',
    softwareTechnology: 'Software & Technology',
    consultingProfessionalServices: 'Consulting & Professional Services',
    manufacturingDiscreteProcess: 'Manufacturing (Discrete & Process)',
    construction: 'Construction',
    travelTransportationLogistics: 'Travel, Transportation & Logistics',
    consumerGoodsRetailWholesale: 'Consumer Goods, Retail & Wholesale',
    telecoMediaEntertainment: 'Telecom, Media & Entertainment',
    energyOilGasUtilities: 'Energy, Oil, Gas & Utilities',

    governmentNonprofitEducation: 'Government, Non-profit & Education',
    lifeSciencesBiotechMedicalDevicesPharmaceuticals: 'Life Sciences (Bio-tech, Medical Devices & Pharmaceuticals)',

    congratulations: 'CONGRATULATIONS',
    youHavesuccessfullycompletedyourregister: 'You have successfully completed your register.',
    weHaveSentYouAnEmailPleaseFollowTheLinkToActivateYourAccount: 'We have sent you an email. Please follow the link to activate your account.',
    theEmailYouHaveProvidedIsAlreadyRegistered: 'The email you have provided is already registered.',
    youHaveEarnedLynxPoints: 'You have earned 50 points.',
    yourDashboard: 'Your Dashboard',

    whatIsYourRoleAt: 'What is your role at',

    recruiter: 'Recruiter',

    numberOfRecruitersWorkingFor: 'Number of recruiters working for',
    iAmASoloPractitioner: 'I am a solo practitioner',
    iDontKnow: 'I don\'t know',
    whatIs: 'What is',
    sWebsiteAddress: '\'s website address?',
    website: 'Website Address',
    sLinkedinCompanyPage: '\'s LinkedIn Company Page?',
    linkedinAccount: 'LinkedIn Company Page',

    howLongHas: 'How long has',
    beenInStaffingAndRecruitingbusiness: 'been in staffing & recruiting business?',

    itIsANewlyFoundedCompany: 'It is a newly founded company',
    years: 'years',

    whatTypeOfRecruitingAndStuffingServicesAreOfferedBy: 'What type of recruiting & stuffing services services are offered by',

    iAmSoloPractitioner: 'I am solo practitioner',
    whichjobmarketsdoyourecruitfor: 'Which job markets do you recruit for ?',

    selectAll: 'Select All',

    howMantPlacementsYouHaveMadeInLast12Months: 'How many placements you have made in last 12 months?',

    experienceLevel: 'Experience Level',
    entryLevel: 'Entry Level',
    associate: 'Associate',
    midsenior: 'Mid-Senior',
    director: 'Director',
    executive: 'Executive',
    selectNumberOfPlacements: 'Select number of placements',

    choosePhoto: 'Choose Photo',
    atsAdditional: 'ATS Additional',

    jobTitles: 'Job titles',
    location: 'Location',
    searchForJobTitlesCompaniesTags: 'Search for job titles / companies',
    searchForKeyWords: 'Search for key words',
    searchForJobs: 'Search for jobs',
    searchJob: 'Search Job',
    posted: 'Posted',
    daysAgo: 'days ago',
    skills: 'Skills',
    fullJobDescription: 'Full Job Description',
    openPosition: 'Open position',
    underClientReview: 'Under client review',
    clientReview: 'Client review',
    hiringManagerReview: 'HM review',
    managerReview: 'Manager review',
    interviewStage: 'Interview stage',
    offerStage: 'Offer stage',
    rejected: 'Rejected',
    ofBaseSalary: 'of base salary',
    fixedAmount: 'Fixed Amount',
    viewSalary: 'View Salary',
    daysGuaranteePeriod: 'days guarantee period',
    recentNews: 'Recent News',
    talentbaseCredits: 'Picktus Credits',
    earnings: 'Earnings',
    youSpent: 'You spent',
    ofYourPoints: 'of your points',
    candidatesLeftThisMonth: 'Candidates Left This Month',
    totalCandidates: 'Total Candidates',
    numberOFSubmissions: 'Number Of Submissions',
    qualifedSubmissions: 'Qualified Submissions',
    underReview: 'Under Review',
    totalJobs: 'Total Jobs',
    totalApplications: 'Total Applications',
    waitingForAction: 'Waiting For Action',
    totalUsers: 'Total Users',
    filter: 'Filter',
    sort: 'Sort',
    searchForMyCandidates: 'Search for my candidates',
    searchForrecruiters: 'Search for recruiters',
    searchForEmployers: 'Search for employers',
    viewApplications: 'View Applications',
    editCandidate: 'Edit Candidate',
    applicationStatus: 'Application Status',
    viewApplication: 'View Application',
    employer: 'Employer',
    appliedOn: 'Applied On',
    jobAppliedByYou: 'Job Applied By You',
    addNewCandidate: 'Add New Candidate',
    addNewUser: 'Add New User',
    status: 'Status',
    comments: 'Comments',
    numberOfJobs: 'Number of Jobs',
    resumeTitle: 'Resume Title',
    addResume: 'Add Resume',
    addCoverLetter: 'Add Cover Letter',
    saveCandidate: 'Save Candidate',
    uploadCoverLetter: 'Upload Cover Letter',
    writeCoverLetter: 'Write Cover Letter',
    jobDescription: 'Job Description',
    applyFilters: 'Apply Filters',
    apply: 'Apply',
    referralReward: 'Referral Reward',

    // Add New Job Modal
    postAJob: 'Post a Job',
    jobTitle: 'Job Title',
    totalNumberOfPosition: 'Total Number Of Position',
    jobClassification: 'Job Classification',
    jobFunction: 'Job Function',
    educationLevel: 'Education Level',
    jobType: 'Job Type',
    compensation: 'Compensation',
    from: 'From',
    to: 'To',
    additionalCompensationInformation: 'Additional Compensation Information',
    experienceRequired: 'Experience Required',
    year: 'Year',
    month: 'Month',
    relocationAssistance: 'Relocation Assistance',
    additionalRelocationAssistanceInformation: 'Additional Relocation Assistance Information',
    visaRequirement: 'Visa Requirement',
    additionalVisaInformation: 'Additional Visa Information',
    jobInformation: 'Job Information',
    addCommaToEnterMultipleSkills: 'Add comma to enter multiple skills..',
    pressEenterToEnterMultipleSkills: 'Press enter to enter multiple skills..',
    additionalLocationJobInformation: 'Additional Location Job Information',
    yoursOrganizationsJobOrderNumber: 'Your\'s Organizations Job Order Number',
    theUserSuccessfullyDeletedniqueNumberAssignedByYourApplicantTrackingSystem: 'The unique number assigned by your Applicant Tracking System',
    selectTeamMember: 'Select Team Member',
    emailNotification: 'Email Notification',
    differentNotificationEmail: 'Different Notification Email',
    areYouAuthorizedToApproveThePaymentOfReferralRewardAndAssoicatedFeeUponSuccessfulHire: 'Are you authorized to approve the payment of referral reward and assoicated fee upon successful hire?',
    fee: 'Fee',
    pleaseSelect: 'Please Select',
    amount: 'Amount',
    amountEuro: 'Amount (in Euros)',
    feeAmountEuro: 'Fee Amount (in Euros)',
    feeAmount: 'Fee Amount',
    preScreenQuestion: 'Pre-Screen Question',
    enterQuestion: 'Enter Question',
    addNewQuestion: 'Add New Question',
    saveAsDraft: 'Save As Draft',
    saveAsPost: 'Save As Post',
    saveAndPublish: 'Save and Publish',
    preview: 'Preview',

    // Job Types
    CONTRACT: 'Contract',
    DIRECT_HIRE: 'Direct Hire',
    PART_TIME: 'Part Time',
    SOURCE_SCREEN: 'Source Screen',
    SOURCING: 'Sourcing',

    // Experience Level Type
    INTERNSHIP: 'Internship',
    ENTRY_LEVEL: 'Entry Level',
    ASSOCIATE: 'Associate',
    MID_SENIOR: 'Mid Senior',
    DIRECTOR: 'Director',
    EXECUTIVE: 'Executive',
    NOT_APPLICABLE: 'Not Applicable',
    MID_LEVEL: 'Mid Level',
    JUNIOR_LEVEL: 'Junior Level',

    // Education Level Type
    HIGH_SCHOOL: 'High School',
    PARTIAL_COLLEGE: 'Partial College',
    VOCATIONAL: 'Vocational',
    BACHELOR: 'Bachelor',
    MASTER: 'Master',
    DOCTORATE: 'Doctorate',

    // Relocation Assistance Type
    YES: 'Yes',
    NO: 'No',
    LIMITED: 'Limited',

    // Placement Fee Type
    PERCENT: 'Percent',
    FIXED: 'Fixed',

    recruitmentExperience: 'Recruitment Experience',
    jobRecruisitionTypes: 'Job Recruisition Types',
    functionalRoles: 'Functional Roles',
    functionalRole: 'Functional Role',
    requisitionType: 'Requisition Type',
    industries: 'Industries',

    // contract:'Contract',
    // directHire:'Direct Hire',
    // partTime:'Part Time',
    // sourceScreen:'Source Screen',
    // sourcing:'Sourcing',

    notifications: 'Notifications',
    editProfile: 'Edit Profile',
    address: 'Address',
    websitee: 'Website',
    aboutYourCompany: 'About Your Company',
    update: 'Update',
    cancel: 'Cancel',

    // RESUL
    editProfiles: 'EDIT PROFILE',
    basicsDetails: 'Basic Details',
    changePassword: 'Change password',
    changePasswordHeader: 'Change Password',
    currentPassword: 'Current Password',
    newPassword: 'New Password',
    newPasswordAgain: 'New Password Again',
    reset: 'Reset',
    enterCurrentPassword: 'Enter Current Password',
    enterNewPassword: 'Enter New Password',
    retypeNewPassword: 'Retype New Password',
    companyNames: 'Company name',
    contactPersonEmailAdress: 'Contact person email address',
    contactPersonFirstNames: 'Contact Person First Name',
    contactPersonLastNames: 'Contact Person Last Name',
    sizes: 'Size',
    websiteUrl: 'Website URL',
    employerContactNumber: 'Employer contact number',
    Industry: 'Industry',
    addLogoImages: 'Add Logo Image',
    companyImages: 'Company Image',
    aboutsCompany: 'About Company',
    locationDetails: 'Location Details',
    addressLines1: 'Address Line 1',
    addressLines2: 'Address Line 2',
    zipCodes: 'Zip Code',
    cities: 'City',
    state: 'State',
    countries: 'Country',
    linkToSocialMedia: 'Links To Social Media',
    facebookProfileLinks: 'Facebook profile link',
    linkedinProfileLinks: 'Linkedin profile link',
    twitterProfileLinks: 'Twitter profile link',
    googleProfileLinks: 'Google profile link',
    doYouCurrentlyHaveAnApplicantTrackingSystem: ' Do you have currently have an applicant tracking system (ATS) ?',
    cancels: 'Cancel',
    updateProfile: 'Update Profile',
    contactNumbers: 'Contact Number',
    messages: 'Messages',
    typeMessage: 'Type a message',

    // Company Size
    SOLO: 'Solo',
    TWO: '2',
    THREE: '3',
    FOUR: '4',
    FIVE_TO_TEN: '5-10',
    ELEVEN_TO_TWENTY_FIVE: '11-25',
    TWENTY_SIX_TO_HUNDRED: '26-100',
    HUNDRED_PLUS: '100+',
    close: 'Close',
    UNKNOWN: 'I don\'t know',

    editBasicInfoHeader: 'EDIT BASIC INFO',

    // Max Amount
    FOURTY: '40.000',
    FIFTY: '50.000',
    SIXTY: '60.000',
    SEVENTY: '70.000',
    EIGHTY: '80.000',
    NINETY: '90.000',
    ONE_HUNDRED: '100.000',
    ONE_HUNDRED_AND_TEN: '110.000',
    ONE_HUNDRED_AND_TWENTY: '120.000',
    ONE_HUNDRED_AND_THIRTY: '130.000',
    ONE_HUNDRED_AND_FORTY: '140.000',
    ONE_HUNDRED_AND_FIFTY: '150.000',
    ONE_HUNDRED_AND_SIXTY: '160.000',
    ONE_HUNDRED_AND_SEVENTY: '170.000',
    ONE_HUNDRED_AND_EIGHTY: '180.000',
    ONE_HUNDRED_AND_NINETY: '190.000',
    TWO_HUNDRED: '200.000',
    TWO_HUNDRED_AND_TEN: '210.000',
    TWO_HUNDRED_AND_TWENTY_FIVE: '225.000',
    TWO_HUNDRED_AND_FIFTY_PLUS: '250.000',

    // Min Amount
    THIRTY: '30.000',
    FOURTY_ONE: '41.000',
    FIFTY_ONE: '51.000',
    SIXTY_ONE: '61.000',
    SEVENTY_ONE: '71.000',
    EIGHTY_ONE: '81.000',
    NINETY_ONE: '91.000',
    ONE_HUNDRED_ONE: '101.000',
    ONE_HUNDRED_AND_ELEVEN: '111.000',
    ONE_HUNDRED_AND_TWENTY_ONE: '121.000',
    ONE_HUNDRED_AND_THIRTY_ONE: '131.000',
    ONE_HUNDRED_AND_FORTY_ONE: '141.000',
    ONE_HUNDRED_AND_FIFTY_ONE: '151.000',
    ONE_HUNDRED_AND_SIXTY_ONE: '161.000',
    ONE_HUNDRED_AND_SEVENTY_ONE: '171.000',
    ONE_HUNDRED_AND_EIGHTY_ONE: '181.000',
    ONE_HUNDRED_AND_NINETY_ONE: '191.000',
    TWO_HUNDRED_ONE: '201.000',
    TWO_HUNDRED_AND_ELEVEN: '211.000',
    TWO_HUNDRED_AND_TWENTY_SIX: '226.000',

    // Toast Notifications
    enteredPasswordsDoNtMatch: 'Entered passwords do not match!',
    yourPasswordHasBeenChangedSuccessfully: 'Your password has been changed successfully.',
    yourPasswordHasBeenResetSuccessfully: 'Your password has been reset successfully.',
    newJobSuccessfullyAdded: 'New job successfully added.',
    newCandidateSuccessfullyAdded: 'New candidate successfully added.',
    newUserSuccessfullyAdded: 'New user successfully added.',
    pleaseEnterAValidMailAddress: 'Please enter a valid mail address!',
    weHaveSentYouAnEmailPleaseFollowTheLinkToResetYourPassword: 'We have sent you an email. Please follow the link to reset your password.',
    yourProfileHasBeenSuccessfullyUpdated: 'Your profile has been successfully updated.',
    yourEmailPreferencesHasBeenSuccessfullyUpdated: 'Your email preferences has been successfully updated.',
    theCandidateHasBeenSuccessfullyUpdated: 'The candidate has been successfully updated.',

    // status
    PENDING: 'Pending',
    ACTIVE: 'Active',
    PASSIVE: 'Passive',
    DELETED: 'Deleted',
    CLOSED: 'Closed',
    BY_JOB_PROFILE: 'By Job Profile',

    // user role
    ADMIN: 'Admin',
    RECRUITER: 'Recruiter',
    EMPLOYER_MANAGER: 'Employer Manager',
    EMPLOYER_USER: 'Employer User',
    RECRUITER_MANAGER: 'Recruiter Manager',
    RECRUITER_USER: 'Recruiter User',

    editBasicInfo: 'Edit Basic Info',
    emailPreferences: 'Email Preferences',
    save: 'Save',
    add: 'Add',
    pleaseSelectTypes: 'Please select the types of emails you would like to receive from Picktus',

    // add new user
    role: 'Role',

    // New Email Preferences
    emailWhenNewContract: '  Email me when a new full-time/direct hire job is posted on Picktus',
    emailWhenApplicationStatus: '  Email me when a application status is updated on Picktus',
    emailWhenNewFullTime: 'Email me when a new contract/contingent labor job is posted on Picktus',
    InviteToJobRelatedWebinarsIntake: 'Invite me to job related webinars and intake calls - with Clients or Picktus Account Managers',
    RecrutingTipsSuccessStories: '  Recruiting tips, success stories and case studies',

    // Recruiter Edit New Profile
    ElectronicPaymentAch: '  Electronic payment(ACH payment , wire transfer to your bank account',
    MailACheck: 'Mail a Check',
    NotSureAtTheMoment: 'Not sure at the moment',
    trackApplications: 'Track Applications',
    searchJobs: 'Search Jobs',
    jobsApplied: 'Jobs Applied',
    myJobs: 'My Jobs',
    users: 'Users',
    on: 'on',
    userreviews: 'User Reviews',
    read: 'Read',
    reviews: 'reviews',
    recent: 'Recent',

    // Payment Status
    paid: 'Paid',
    cancelled: 'Cancelled',
    processing: 'Processing',
    gteePeriod: 'GTEE Period',

    // Marketplace
    candidateThisMonth: 'Candidate This Month',
    bestStepsToAutomate: 'Best Steps To Automate Your Recruitment Pipeline',
    daysOfSourcing: '12 Days of Sourcing Wrap-up Webinar',
    acquisitionTechnology: 'Zen and The Art of Talent Acquisition Technology',
    discountExactalent: '10% Discount on Exactalent ATS',
    discountRecruiting: '20% Discount on Recruiting.com',
    discountCodility: '5% Discount on Codility',

    // Company Image
    tools: 'Tools',
    exactalents: 'exactalent',
    exactalentsCompany: 'Applicant Tracking System',
    recruiting: 'recruiting.com',
    recruitingCompany: 'Complete Recruiting Solution',
    oracle: 'Oracle',
    oracleCompany: 'Corporate Database Solution',
    smartRecruiters: 'Smart Recruiters',
    smartRecruitersCompany: 'Talent Acquisition Software',
    codility: 'codility',
    codilityCompany: 'Tech Recruitment',
    hackerRank: 'HackerRank',
    hackerRankCompany: 'Developer Recruitment',
    imocha: 'imocha',
    imochaCompany: 'All Powered Skill Assessment',
    merger: 'mettle',
    mergerCompany: 'Online Examination Solution',
    criteria: 'criteria',
    criteriaCompany: 'Talent Decision',

    // Marketing Place
    marketPlace: 'MARKETPLACE',
    purchaseButton: 'Purchase',
    talentbaseCredit: 'Picktus Credits Needed',
    title: 'Title',
    candidateSlot: 'Candidate Slot',
    webinars: 'Webinars',
    discounts: 'Discounts',
    action: 'Action',
    credits: 'Credits',
    date: 'Date',
    balance: 'Balance',
    currentTalentbaseCredits: 'Current Picktus Credits',
    currentTalentbaseScore: 'Current Picktus Score',
    spentCurrentTalentbaseCredits: 'You spent 70% of your points',
    spentCandidatesLeftThisMonth: 'You spent 70% of your quota',
    acquireMore: 'Acquire More',
    employerSupportQuestion1: 'How does Picktus work for employers?',
    employerSupportQuestion2: 'We are working with approved vendor(s) for our hiring needs and use an internal recruiting team. Why use Picktus?',
    employerSupportQuestion3: 'Who refers candidates on Picktus? Why do they participate?',
    employerSupportQuestion4: 'Will recruiters or applicants have access to my contact information?',
    employerSupportQuestion5: 'What if a candidate, who is sent to me by Picktus, is or was in my company’s pipeline of applicants?',
    employerSupportQuestion6: 'What happens if two recruiters submit the same candidate?',
    employerSupportQuestion7: 'Can I work offline with recruiters whom I have met through Picktus?',

    employerSupportAnswer1: 'Your jobs go live on the platform: An account manager, who is assigned to you, will work with you to upload a job description as well as notes (called Job Insights) for each of your jobs to help the recruiting community understand who you are looking to hire. The search for your perfect candidate begins: The moment your jobs go live on the Platform, the right set of independent recruiters and recruiting firms on the Picktus platform will begin submitting candidates to Picktus. Information about candidates who are approved by your account manager will be sent to you. You can review incoming candidates straight from the Picktus platform, or in your inbox.',
    employerSupportAnswer2: 'By using Picktus, you instantly extend your reach to find better candidates in less time. Traditional talent acquisition methods cannot give you the reach of a large community of recruiters. You can also manage your talent acquisition processes efficiently by using Picktus as a centralized platform for all of your approved vendors.',
    employerSupportAnswer3: 'Currently, Picktus works with individual recruiters and recruiting firms. By partnering with Picktus, members of Picktus\'s recruiting community gain access to jobs at some of the best companies in Europe. Moreover, working with Picktus, larger recruiting firms can ensure recruiter productivity. Their recruiters will never run out of jobs that need to be filled.',
    employerSupportAnswer4: 'Picktus values your privacy. Your contact information will not be accessible from the platform to recruiters or candidates.',
    employerSupportAnswer5: 'If a candidate was sent to you by Picktus and has been in your database for 90 days or less, you can reject their application; it would be considered a duplicate.',
    employerSupportAnswer6: 'Our system prevents two or more recruiters from submitting the same candidate to the same job. Should this happen, the recruiter, who submits a candidate first, is given credit. Employers will never be required to pay two placement fees for one hire. Please review the Terms of Use to learn more.',
    employerSupportAnswer7: 'No. Please review our Terms and Conditions here.',

    recruiterSupportQuestion1: 'How does Picktus work?',
    recruiterSupportQuestion2: 'How does a recruiter benefit from Picktus?',
    recruiterSupportQuestion3: 'When do I get paid for successful placement? How will I receive my payment?',
    recruiterSupportQuestion4: 'How does the employment guarantee work?',
    recruiterSupportQuestion5: 'What is the average placement fee?',
    recruiterSupportQuestion6: 'Can anyone else see my candidates when I submit them on Picktus?',
    recruiterSupportQuestion7: 'Why can’t I search for candidates on Picktus for my other jobs?',
    recruiterSupportQuestion8: 'What if the employer already has a candidate that I submit?',
    recruiterSupportQuestion9: 'What if a candidate is submitted by more than one recruiter? How are duplicate submissions resolved?',
    recruiterSupportQuestion10: 'What if the client (employer) hires my candidate for another role?',
    recruiterSupportQuestion11: 'Can we resubmit a candidate who got hired through Picktus?',
    recruiterSupportQuestion12: 'What type of job requisitions are available on Picktus?',
    recruiterSupportQuestion13: 'How will I know if other recruiters are working on a job?',

    recruiterSupportAnswer1: 'Picktus has job requisitions from hundreds of employers. Recruiters can fill these jobs and earn a placement fee for each successful hire. They only need to register for free, review jobs and then start submitting candidates. Our clients (employers) hire candidates submitted by recruiters. If a candidate you have submitted into the platform is hired, you will receive a placement fee.',
    recruiterSupportAnswer2: 'Picktus gives you access to hundreds of jobs from top employers across Europe. Instead of spending time cold calling, negotiating contracts and getting on an approved vendor list to work on Fortune 500 enterprise jobs and collecting fees, you can spend your time recruiting more candidates by working with Picktus.',
    recruiterSupportAnswer3: 'You will be paid 60 days following the date your candidate starts working for the client. In rare cases, it might take 90 days, but recruiters will be notified. You can choose to receive a check via mail, or we can directly deposit your placement fee electronically in an account of your choice following the guarantee period.',
    recruiterSupportAnswer4: 'We typically provide a 60-day replacement guarantee to clients (employers). The guarantee period may vary for certain employers. In rare cases, the guarantee period will be 90 days. If our client believes an employee found on Picktus is not the best fit during the guarantee period, and the candidate’s employment is terminated (voluntarily or due to cause), Picktus may replace that candidate at the client’s discretion, and we would then ask you to find a replacement. Payment is subject to the candidate completing the guarantee period. If a candidate leaves before the guarantee period is over and a replacement candidate has not been identified, neither you nor Picktus will receive the placement fee.',
    recruiterSupportAnswer5: 'The average placement fee for jobs on Picktus is 10,000 €. This fee can be significantly higher based on the seniority of a role. The fee is displayed to recruiters next to each job requisition.',
    recruiterSupportAnswer6: 'Your candidate profiles are not searchable. Other than the employer to whom you submit a profile, your candidates’ profiles are completely confidential. Privacy and security are of utmost importance to us, and we encourage all members to read Picktus\'s Privacy Policy.',
    recruiterSupportAnswer7: 'Picktus does not offer a resume search database service, even for our clients.',
    recruiterSupportAnswer8: 'Employers want to see candidates that they don’t already have in their database. Candidates who directly applied to a job posted by a client (employer) outside of Picktus\'s portal in the preceding 180 days “belong” to the employer and not to the recruiter. If the employer hires a candidate whom they already were in contact with (in the past 180 days), neither you nor Picktus will receive credit for this referral, and the employer doesn’t owe a placement fee.',
    recruiterSupportAnswer9: 'If you are the first recruiter to submit a candidate for a job, the candidate “belongs” to you for that particular client (employer) for a period of 180 days (or approximately 6 months). If the employer hires your candidate within that time frame, you earn the placement fee. Our system prevents a candidate from being submitted twice to the same employer. In the unlikely event that duplicates do occur, the candidate ownership credit will be given to the recruiter who first submitted the candidate based on a time stamp in our system. Picktus\'s Account Managers will handle such cases.',
    recruiterSupportAnswer10: 'Picktus\'s agreement with clients (employers) ensures that you will get paid if the client (employer) hires your candidate for another role.',
    recruiterSupportAnswer11: 'You cannot re-introduce a previously hired candidate for at least 6 months following a placement. This helps an employer avoid candidates who aren’t committed to staying at a new job, ensuring Picktus\'s ability to provide exceptional service to the employers.',
    recruiterSupportAnswer12: 'Picktus has job requisitions for roles across nearly all functions and industries, including information technology, management consulting, business development and sales, accounting and finance, management, healthcare and more.',
    recruiterSupportAnswer13: 'You will be able to see how many candidates are already submitted by other recruiters for a job. Moreover, Picktus software displays, in real-time, the number of candidates in each stage of the hiring process, e.g., interview, offer, etc.',

    totalPosition: 'Total Position',
    removeFromWorkList: 'Remove From Worklist',
    submitCandidate: 'Submit Candidate',
    companyOverView: 'Company Overview',
    basicQualifications: 'Basic Qualifications',
    jobInsights: 'Job Insights',
    accountManagerInformation: 'Account Manager Information',
    additionalComments: 'Additional Comments',
    screeningQuestions: 'Screening Questions',
    candidatePipeline: 'Candidate Pipeline',
    name: 'Name',
    phone: 'Phone',

    searchNoResult: 'No results found for',
    leastThreeCharacter: 'At least 3 characters',
    search: 'Search',
    applicants: 'Applicants',
    ownershipExpired: 'Ownership expired',
    pounds: 'Pounds',
    viewCandidateDetail: 'View Candidate Detail',
    updateCandidateStatus: 'Update Candidate Status',

    // Last
    JobCompany: 'General Dynamics Mission Systems',
    jobLocation: 'Location',
    jobProfession: 'Engineer',
    jobDate: ' Posted ',
    jobPosition: 'Position',

    rating: 'Rating',
    registeredOn: 'Registered on',
    reviewsTitle: 'Reviews',
    viewDetails: 'View Details',

    primanyPhone: 'Primary Phone',
    secondaryPhone: 'Secondary Phone',
    company: 'Company',
    recruitingInfo: 'Recruiting Info',
    deactive: 'Deactive',
    message: 'Message',
    active: 'Active',

    pendingReview: 'Pending Review',
    underConsideration: 'Under Review',
    TotalApplications: 'Total Applications',
    // yeni-bugun
    companySize: 'Company Size',
    deactivate: 'Deactivate',
    about: 'About',
    contactPerson: 'Contact Person',
    contactPersonName: 'Contact Person Name',
    instagramProfileLinks: 'Instagram profile link',

    // 5.04
    deactivateText: 'The account will not be able to access Picktus once it is deactivated. Are you sure?',

    NEW_TO_RECRUITING: 'I am new to recruiting',
    ONE_YEAR: '1 year',
    TWO_TO_THREE_YEARS: '2-3 years',
    FOUR_TO_FIVE_YEARS: '4-5 years',
    SIX_TO_TEN_YEARS: '6-10 years',
    TEN_PLUS_YEARS: '10+ years',
    NOT_A_RECRUITER: 'I am not a recruiter',

    addToWorkList: 'Add To WorkList',
    viewJobDetail: 'View Job Detail',
    editJob: 'Edit Job',
    viewAllApplications: 'View All Applications',
    cancelJobAreYouSureText: 'The job will no longer be applicable once it is cancelled. Are you sure?',
    cancelJob: 'Cancel Job',

    jobSuccessfullyUpdated: 'The job successfully updated.',

    // 8.04.2021
    submitApplication: 'Submit Application',
    screenQuestions: 'Screen Questions',
    chooseCandidate: 'Choose candidate',
    cancelApplication: 'Cancel Application',
    cancelApplicationText: 'Your application will be cancelled. Are you sure?',

    // Submit Application Questions
    // submitAppQuestion1: 'Please tell us about your electrical engineering management experience.',
    // submitAppQuestion2: 'What is your work authorization status?',
    // submitAppQuestion3: 'What are your compensation expectations?',
    // submitAppQuestion4: 'What is your current level pf security clearance? Plase verify if it is active or inactive.',
    // submitAppQuestion5: 'Please elaborate on your expertise and understanding in JAVA code development, C/C++ code development.',

    jobInfo: 'Job Info',
    typeYourAnswerHere: 'Type your answer here',
    yourApplicationHasBeenSubmittedSuccessfully: 'Your application has been submitted successfully.',
    theApplicationWasCanceledSuccessfully: 'The application was canceled successfully.',
    theApplicationWasUpdatedSuccessfully: 'The application was updated successfully.',
    deleteCandidate: 'Delete Candidate',

    theCandidateWillBeRemovedFromYourListAreYouSure: 'The candidate will be removed from your list. Are you sure?',
    theCandidateSuccessfullyDeleted: 'The candidate successfully successfully.',

    theFaqItemWillBeRemovedFromYourListAreYouSure: 'The FAQ item will be removed from your list. Are you sure?',

    deleteFaqItem: 'Delete FAQ Item',
    addNew: 'Add New',

    clearFilters: 'Clear Filters',
    recruiterInfo: 'Recruiter Info',
    recruiterInfoText: 'The recruiter specified above has succesfully completed the application process on behalf of you.',
    pleaseReviewRecruiter: 'Please review the recruiter',
    submit: 'Submit',
    viewCoverLetter: 'View Cover Letter',
    viewCandidateResume: 'View Candidate Resume',
    newCandidateStatus: 'New Candidate Status',

    // 9 april

    TheRecruiterSpecified: ' The Recruiter specified above wants to apply to the job above on behalf of you.',
    doYouConfirm: 'Do you approve',
    reject: 'Reject',
    approval: 'Approval',
    approve: 'Approve',
    recruInfo: 'Recruiter Info',

    SUBMITTED: 'Submitted',
    APPROVED: 'Approved',
    REJECTED_BY_CANDIDATE: 'Rejected by Candidate',
    CLIENT_REVIEW: 'Client Review',
    MANAGER_REVIEW: 'Manager Review',
    INTERVIEW_STAGE: 'Interview Stage',
    OFFER_STAGE: 'Offer Stage',
    HIRED: 'Hired',
    REJECTED_BY_EMPLOYER: 'Rejected by Employer',
    CANCELLED: 'Cancelled',
    SUBMITTEDShort: 'S',
    DELETEDShort: 'D',
    RESIGNEDShort: 'R',
    APPROVEDShort: 'A',
    REJECTED_BY_CANDIDATEShort: 'RbC',
    CLIENT_REVIEWShort: 'CR',
    MANAGER_REVIEWShort: 'MR',
    INTERVIEW_STAGEShort: 'IS',
    OFFER_STAGEShort: 'OS',
    HIREDShort: 'H',
    REJECTED_BY_EMPLOYERShort: 'RbE',
    CANCELLEDShort: 'C',
    DISMISSEDShort: 'D',

    DRAFT: 'Draft',

    sinceLastMonth: 'Since last month',

    SIGN_UP: 'Sign Up',
    PLUS_ONE_CANDIDATE: '+1 Candidate',
    PLUS_FIVE_CANDIDATES: '+5 Candidates',
    SUCCESSFUL_PLACEMENT: 'Successful placement',

    flynxCreditsTable: 'Picktus credits',

    GTEE_PERIOD: 'GTEE Period',
    PROCESSING: 'Processing',
    PAID: 'Paid',

    paymentStatus: 'Payment Status',
    inGuarantee: 'In Guarantee',
    placedAt: 'Placed At',
    candidateName: 'Candidate Name',
    employerName: 'Employer Name',

    agreedSalaryAmount: 'Agreed Salary Amount',

    yourReviewHasBeenSentSuccessfully: 'Your review has been sent successfully.',

    theApplicationRejected: 'The application rejected.',
    theApplicationApproved: 'The application approved.',

    WEBINAR_12_DAYS_OF_SOURCING_WRAP_UP: '12 Days of Sourcing Wrap-up Webinar',
    WEBINAR_ZEN_AND_ART_OF_TALENT_ACQUISITION_TECHNOLOGY: 'Zen and The Art of Talent Acquisition Technology',
    WEBINAR_BEST_STEPS_TO_AUTOMATE_RECRUITMENT_PIPELINE: 'Best Steps To Automate Your Recruitment Pipeline',
    DISCOUNT_5_PERCENT_CODILITY: '5% Discount on Codility',
    DISCOUNT_10_PERCENT_EXACTALENT: '10% Discount on Exactalent ATS',
    DISCOUNT_20_PERCENT_RECRUITING_COM: '20% Discount on Recruiting.com',

    ofYourQuota: ' of your quota',

    jobTitleCompanies: 'Job Title / Companies',
    keyWords: 'Keywords',

    sortByNameAZ: 'Sort by name (A-Z)',
    sortByNameZA: 'Sort by name (Z-A)',
    sortByDateCreatedAZ: 'Sort by date created (A-Z)',
    sortByDateCreatedZA: 'Sort by date created (Z-A)',
    sortByCandidateNameAZ: 'Sort by candidate name (A-Z)',
    sortByCandidateNameZA: 'Sort by candidate name (Z-A)',
    selectFilter: 'Select Filter',
    selectSort: 'Select Sort',
    searchForCandidateName: 'Search for candidate name',
    searchForRecruiterName: 'Search for recruiter name',
    searchForCompanyName: 'Search for company name',
    searchForCandidateJobEmployerName: 'Search for candidate/job/employer name',
    noSorting: 'No sorting',
    thankYou: 'Thank you',

    proceedToLogin: 'Proceed To Login',
    publishJob: 'Publish Job',
    willBeReadySoon: 'Will be Ready Soon',
    youAreAboutToPurchase: 'You are about to purchase',
    areYouSure: 'Are you sure?',
    yourPurchaseWasSuccessful: 'Your purchase was successful.',

    answer: 'Answer',
    question: 'Question',
    category: 'Category',

    oldPasswordAndNewPasswordCannotBeTheSame: 'Old password and new password cannot be the same',
    primaryAandSecondaryContactNumbersCannotBeTheSame: 'Primary and secondary contact numbers cannot be the same',

    BALANCE_NOT_ENOUGH: 'Your balance is not enough!',
    FIVE_STARS_REVIEW: '5 Star Review',
    RESOURCE_ALREADY_EXISTS: 'The email you have provided is already registered.',

    createAccount: 'Create Account',
    topJobBoardsForProfessionals: 'Top job boards for professionals',
    chooseAccountType: 'Choose account type',
    iAgreeWith: 'I agree with',
    termsOfUse: 'Terms of use',
    topJobBoardsFor: 'Top job boards for',
    professionals: 'professionals!',
    discoverYourNextCareer: 'Discover your next career move with over 15 000 opening',
    vacanciesCustomerSupport: ' vacancies, customer support, software, design anywhere in ',
    theWorldOrRemotely: 'the world or remotely.',
    discoverYourNextCareerEmp: 'Discover your next career move with over 15 000 opening vacancies, customer',
    vacanciesCustomerSupportEmp: 'support, sowtware, design anywhere in the world or remotely.',
    filters: 'Filters',
    allOffers: 'All Offers',
    mostRelevant: 'Most Relevant',
    recommended: 'Recommended',
    mostRecent: 'Most Recent',
    bestRecruiters: 'Best Recruiters',
    activity: 'Activity',
    OWN_BUSINESS: 'Own Business',
    SEEKING_OPPORTUNITIES: 'Seeking Opportunities',
    BROWSING: 'Browsing',
    SUPPLEMENTARY: 'Supplementary',
    OTHER: 'Other',
    viewAll: 'View All',
    profileImage: 'Profile Image',
    writeYourBio: 'Write Your Bio',
    savedJobProfiles: 'Saved Job Profiles',
    industryJobProfiles: 'Industry Job Profiles',
    createNewJobProfile: 'Create a New Job',
    saved: 'Saved',
    delete: 'Delete',
    ok: 'OK',
    numberOfEmployers: 'Number of Employers',
    exploreIt: 'Explore It!',
    results: 'Results',
    continue: 'Continue',
    joinUs: 'Join Us!',
    toBeginThisJourneyTellUs: 'To begin this journey, tell us what type of account you’d be opening.',
    recruiterDesc: 'Personal account to manage all you activities.',
    employerDesc: 'Own or belong to a company, this is for you.',
    alreadyHaveAnSccount: 'Already have an account?',
    dontYouHaveAnAccount: 'Dont you have an account?',
    weWillSendMessageToEmail: 'We’ll send a message to e-mail',

    marketplace: 'Marketplace',
    ratings: 'Ratings',

    locations: 'Locations',
    sortBy: 'Sort by',
    viewContactProfile: 'View Profile',
    hired: 'Hired',
    interviewed: 'Interviewed',

    completeYourProfile: 'Complete Your Profile',
    completeYourProfilePopupText: 'To successfully register your profile as an Expert and to you availavle in search results Profile needs to be at least 80% completed.',
    complete: 'Complete',

    skillsExperience: 'Skills & Experience',
    compensationFee: 'Compensation & Fee',
    financeController: 'Finance Controller',
    jobConfiguration: 'Job Configuration',
    whatAboutYourCurrentAndPastWorkExperienceMakeYouAGreatFitForOurRole: 'What about your current and past work experience make you a great fit for our role?',
    whatDoesYourPerfectManagerLookLikeHaveYouEverHadAManagerWhoWasCloseToThisIdealOrFitItExactly: 'What does your perfect manager look like? Have you ever had a manager who was close to this ideal or fit it exactly?',
    whatIsTheBiggestChallengeYouveFacedInYourCurrentOrPreviousRoleHowDidYouOvercomeThisChallengeWhatWasTheOutcome: 'What is the biggest challenge you’ve faced in your current or previous role? How did you overcome this challenge? What was the outcome?',
    whatAreYourGoalsForProfessionalDevelopmentHowWillGettingThisJobHelpYouAdvanceTowardYourProfessionalDevelopmentGoals: 'What are your goals for professional development? How will getting this job help you advance toward your professional development goals?',
    haveYouEverHadToManageMultipleDeadlinesSetForTheSameDayOrWeekHowDidYouDoIt: 'Have you ever had to manage multiple deadlines set for the same day or week? How did you do it?',
    customPrescreeningQuestion: 'Custom Prescreening Question',
    preSecreeningQuestion: 'Pre-Secreening Question',
    answerFormat: 'Answer Format',
    compensationMin: 'Compensation Min',
    compensationMax: 'Compensation Max',
    organizationJobOrderNumber: 'Organization Job Order Number',
    teamMemberInCharge: 'Team member in charge',
    pushNotification: 'Push Notification',
    notification: 'Notification',
    guaranteePeriod: 'Guarantee Period',
    days: 'days',
    applyBefore: 'Apply Before',
    jobNature: 'Job Nature',
    salaryRange: 'Salary Range',
    jobLocations: 'Job Location',
    actions: 'Actions',
    saveJob: 'Save Job',
    downloadVacancy: 'Download Vacancy',
    deleteJob: 'Delete Job',
    aVerificationMailHasBeenSentToYourEmailAccountPleaseCheckYourInboxToVerify: 'A verification mail has been sent to your email account. Please check your inbox to verify.',
    thankYouForApplying: 'Thank you for applying!',
    resendVerificationMail: 'Resend Verification Mail',
    troubleVerifying: 'Trouble Verifying?',
    skillsExperienceSuccessfullyUpdated: 'Skills & Experience successfully updated!',
    locationSuccessfullyUpdated: 'Location successfully updated!',
    noQuestionsAdded: 'No questions added!',
    questionsSuccessfullyUpdated: 'Questions successfully updated!',
    feeType: 'Fee Type',
    compensationsAndFeesSuccessfullyUpdated: 'Compensations & Fees successfully updated!',
    jobAccessibilitySuccessfullySaved: 'Job Accessibility successfully saved!',
    notificationSuccessfullyUpdated: 'Notification successfully updated!',
    publish: 'Publish',

    // Recruiter Type
    INDIVIDUAL: 'Individual',
    RECRUITMENT_FIRM: 'Recruitment Firm',
    OWNER_PRESIDENT_CEO: 'Owner / President / CEO',
    FOUNDING_PARTNER: 'Founding Partner',
    VP_DIRECTOR: 'VP or Director of recruiting or staffing',
    MANAGER: 'Manager of recruiting or staffing',
    EXECUTIVE_RECRUITER: 'Executive Recruiter',
    SENIOR_RECRUITER: 'Senior Recruiter',
    NEWLY_FOUNDED: 'It is a newly founded company',
    ONE_TO_TWO_YEARS: '1-2 years',
    THREE_TO_FIVE_YEARS: '3-5 years',
    recruitertype: 'Recruiter Type',
    talentbaseReferenceType: 'Picktus Reference Type',
    individualPracticeTime: 'Individual Practice Time',
    requisitionTypes: 'Requisition Types',
    recruiterCount: 'Recruiter Count',
    positionAtCompany: 'Position at Company',
    firmPracticeTime: 'Firm Practice Time',
    jobMarkets: 'Job Markets',
    paymentType: 'Payment Type',
    ELECTRONIC: 'Electronic payment',
    MAIL_CHECK: 'Mail a check',
    NOT_DECIDED: 'Not sure at the moment',
    generalInfoHasBeenSuccessfullyUpdated: 'General info has been successfully updated.',
    aboutInfoHasBeenSuccessfullyUpdated: 'About info has been successfully updated.',
    companyInfoHasBeenSuccessfullyUpdated: 'Company info has been successfully updated.',
    specializationHasBeenSuccessfullyUpdated: 'Specialization has been successfully updated.',
    paymentHasBeenSuccessfullyUpdated: 'Payment type has been successfully updated.',
    contactInfoHasBeenSuccessfullyUpdated: 'Contact info has been successfully updated.',
    contactPersonHasBeenSuccessfullyUpdated: 'Contact person has been successfully updated.',
    addressHasBeenSuccessfullyUpdated: 'Address has been successfully updated.',
    socialLinksHasBeenSuccessfullyUpdated: 'Social links has been successfully updated.',
    fillInTheDataForProfileItWillTakeACoupleOfMinutes: 'Fill in the data for profile. It will take a couple of minutes.',
    contactInfo: 'Contact Info',
    specialization: 'Specialization',
    placementCounts: 'Placement Counts',
    specializationInfo: 'Specialization Info',
    goNext: 'Go Next',
    finish: 'Finish',
    socialLinks: 'Social Links',
    addressInfo: 'Address Info',
    theJobProfileUpdated: 'The job profile successfully updated!',
    teamMembers: 'Team Members',
    teamMembersSuccessfullyUpdated: 'Team members successfully updated!',
    createdByAdmin: 'Created By Admin',
    jobProfiles: 'Job Profiles',
    newJobProfile: 'New Job Profile',
    jobProfile: 'Job Profile',
    jobSegment: 'Job Segment',
    newUser: 'New User',
    newestFirst: 'Newest First',
    oldestFirst: 'Oldest First',
    searchForName: 'Search For Name',
    createUser: 'Create User',
    create: 'Create',
    frequentlyAskedQuestions: 'Frequently Asked Questions',
    low: 'Lowest first',
    high: 'Highest first',
    leaderboard: 'Leaderboard',
    recruitersLeaderboard: 'Recruiters Leaderboard',
    weeklyRecruiterLeaderboard: 'Weekly recruiter leaderboard',
    placements: 'Placements',
    viewOffer: 'View Offer',
    rules: 'Rules',
    home: 'Home',
    newJobProfileCreated: 'New Job Profile Created',
    score: 'score',
    previousStep: 'Previous Step',
    warning: 'Warning',
    jobAddWarningText: 'It is not possible to add a job without adding the current Company Info and Location information to your profile from the profile completion screen!',
    jobAddWarningTextCont: 'If you have added valid company info and location information, ignore this warning.',
    verified: 'Verified!',
    instagramAccount: 'Instagram Account',
    facebookAccount: 'Facebook Account',
    twitterAccount: 'Twitter Account',
    completed: 'completed',
    JOB_CREATED: 'Job Created',
    JOB_UPDATED: 'Job Updated',
    JOB_STATUS_UPDATED: 'Job Status Updated',
    APPLICATION_SUBMITTED: 'Application Submitted',
    APPLICATION_UPDATED: 'Application Updated',
    APPLICATION_HIRED: 'Application Hired',
    noActivityYet: 'No activity yet!',
    noCandidateYet: 'No candidate yet!',
    noBestRecYet: 'No best recruiter yet!',
    searchForQuestionsHere: 'Search for questions here',
    importQuestions: 'Import Questions',
    sno: 'Sno',
    questions: 'Questions',
    options: 'Options',
    createQuestion: 'Create Question',

    // new fields

    Leadboard: 'LeadBoard has no data',
    emptyPageTitle: 'Nothing to See Here!',
    emptyPageDescription: 'Get started by adding a new activiy from the menu.',
    emptyPageDescription2: 'Start with your priorities first.',

    // 20.12.2021
    sentMessageSuccessfully: 'Sent Message Successfully!',

    viewSalaryDesc1: 'The compensation details are for the recruiting professionals only. Please do not share the details with the candidates.',
    viewSalaryDesc2: 'We recommend that you discuss the expected compensation with the candidate and include it in the summary or the cover letter with each application.',
    minAmount: 'Min Amount',
    maxAmount: 'Max Amount',
    setAFreeJob: 'Set a free job!',
    setAFreeJobSubText: 'Now start collaboration with your partners, but first create a job.  Our bulk pricing tool makes it easy to set up.',
    createAFirstJob: 'Create a First Job',
    learnMore: 'Learn More',
    jobTitleCompaniesFilter: 'Job Title/Companies',
    allCountries: 'All Countries',
    allCities: 'All Cities',
    allTypes: 'All Types',
    allFunctions: 'All Functions',
    allIndustries: 'All Industries',
    viewAllApplication: 'View All Application',
    segmentId: 'Segment ID',
    employerId: 'Employer ID',
    countryId: 'Country ID',
    locationAdditional: 'Location Additional',
    industryId: 'Industry ID',
    functionn: 'Function',
    experienceRequiredMonth: 'Experience Required Month',
    profileType: 'Profile Type',
    cityId: 'City ID',
    anyLocationFlag: 'Any Location Flag',
    classification: 'Classification',
    updatedDay: 'Updated Day',
    remoteFlag: 'Remote Flag',
    createdAt: 'Created At',
    experienceRequiredYear: 'Experience Required Year',
    jobProfileId: 'Job Profile ID',
    updatedDate: 'Updated Date',
    skill: 'Skill',
    createdDate: 'Created Date',
    jobProfileSkill: 'Job Profile Skill',
    jobProfileType: 'Job Profile Type',
    public: 'Public',
    employerSpecific: 'Employer Specific',
    predefinedPublicJobProfiles: 'Predefined public job profiles',

    // new fields
    details: 'DETAILS',
    candidate: 'Candidate',
    applicationName: 'Application Name',
    candidateEmail: 'Candidate E-mail',
    candidatePhone: 'Candidate Phone',
    preScreenQuestions: 'Pre Screen Questions',
    longText: 'Haftada minimum 5 saatini gönüllü olarak ayırmak istiyor musun?',
    discard: 'Discard',
    next: 'Next',
    coverLetter: 'Cover Letter',
    attachment: 'Attachment',
    confirm: 'Confirm',
    uploadResume: 'Upload Resume',
    textOne: 'This informaation will be display publicy so be carefully what you share',
    textTwo: 'Job titles must describe one position',
    textThree: 'Job titles must describe one position',
    textFour: 'Job titles must describe one position',
    textFive: 'Job titles must describe one position',
    jobSuccessfullyCreated: 'Job successfully created!',
    youCreatedJobSuccessfully: 'You created job successfully.',
    pleaseSelectATeamMemberInCharge: 'Please select a team member in charge!',
    jobAccessibility: 'Job Accessibility',
    PUBLIC: 'Public',
    RULE_BASED: 'Rule Based',
    INVITATION_ONLY: 'Recruiters to Invite',
    pleaseSelectJobAccessibility: 'Please select job accessibility.',
    recruiterEMail: 'Recruiter E-mail',
    theEmployersWillBeAbleToPublishTheJobPublicly: 'The employers will be able to publish the job publicly,',
    orToASpecificGroupOfRecruiters: 'or to a specific group of recruiters.',
    settings: 'Settings',
    enterEMailAddress: 'Enter e-mail address',
    invitedRecruiters: 'Invited Recruiters',
    paymentSuccess: 'Your payment has been successfully completed.',
    billing: 'Billing',
    orderDetails: 'Order Details',
    clear: 'Clear',
    selectSearchType: 'Select Search Type',
    imLookingFor: 'I’M LOOKING FOR',
    recentSearchs: 'RECENT SEARCHS',
    searchResult: 'SEARCH RESULT',
    SRCUSER: 'User',
    SRCEMPLOYER: 'Employer',
    SRCRECRUITER: 'Recruiter',
    SRCJOB: 'Job',
    SRCCANDIDATE: 'Candidate',
    SRCAPPLICATION: 'Application',
    candidateInfo: 'Candidate Info',
    fullName: 'Full Name',
    image: 'Image',
    all: 'All',
    yourPublicProfile: 'Your public profile ',
    successText: 'We’ve successfully saved your information.',
    browseJobs: 'Browse Jobs',

    toSuccessfullyRegisterYourProfileAsAnExpertAndToYouAvailableInSearchResults: 'To successfully register your profile as an expert and to you available in search results.',
    publicProfile: 'public profile',
    billings: 'Billings',
    helpCenter: 'Help Center',
    profilePicture: 'profile picture',
    mustBeAJpgGifOrPngFile: 'Must be a jpg., gif or png. file',
    gender: 'Gender',
    female: 'female',
    male: 'male',
    ratherNotSay: 'Rather Not Say',
    contactNumber: 'Contact Number',
    saveChanges: 'Save Changes',
    memberSince: 'Member Since',
    connectedAccounts: 'Connected Accounts',
    connectInstagramProfile: 'Connect Instagram Profile',
    companyTwitterProfile: 'Company Twitter Profile',
    companyFacebookProfile: 'Company Facebook Profile',
    companyLinkedinProfile: 'Company Linkedin Profile',
    confirmNewPassword: 'Confirm New Password',
    currentEmail: 'Current Email',
    newEmail: 'New Email',
    confirmNewEmail: 'Confirm New Email',
    talentbasePassword: 'Picktus Password',
    changeEmail: 'Change Email',
    expiresOn: 'Expires on',
    change: 'Change',
    paymentMethod: 'Payment Method',
    billingAddress: 'Billing Address',
    emailAddress: 'E-mail Address',
    companyAddress: 'Company Address',
    billingInfo: 'Billing Info',
    selectCountry: 'Select Country',
    billingHistory: 'Billing History',
    invoiceNumber: 'Invoice Number',
    download: 'Download',
    surname: 'Surname',
    countryCode: 'Country Code',
    showAllFilter: 'Show All Filter',
    activityForThisWeek: 'Activity for this week',
    createNewProfile: 'Create New Profile',
    allJobProfilesListing: 'All Job Profiles Listing',
    showing: 'Showing',
    resultOfJobProfiles: 'result of job profiles',
    basedOnYourPreferences: 'Based on your preferences',
    allEmployersListing: 'All Employers listing',
    resultOfEmployers: 'result of Employers',
    resultOfRecruiters: 'result of Recruiters',
    resultOf: 'result of',
    newest: 'Newest',
    oldest: 'Oldest',
    allJobsListing: 'All Jobs listing',
    resultOfJobs: 'result of Jobs',
    editYourProfileDetails: 'Edit Your Profile Details',
    WRONG_PASSWORD: 'Wrong Password',
    mustBeFilled: 'Must be filled',
    passwordsAreNotSame: 'Passwords are not same',
    passwordsCannotBeTheSame: 'Passwords can not be the same',
    seeMore: 'See More',
    rolesAndResponsibilities: 'Roles and responsibilities',
    hrReview: 'HR Review',
    compensations: 'Compensations',
    activeJobs: 'Active Jobs',
    emailNotFound: 'E-Mail Not Found!',
    minimumRatingToBeApplicable: 'Minimum Rating To Be Applicable',
    minimumPlacementToBeApplicable: 'Minimum Placement To Be Applicable',
    up: 'Up',
    jobTitlesMustDescribeOnePosition: 'Job titles must describe one position.',
    characters: 'characters',
    openCoverLetter: 'Open Cover Letter',
    markAllAsRead: 'Mark all as read',
    accept: 'Accept',
    filterBy: 'Filter By',
    allERecruiters: 'All Recruiters',
    allRecruitersListing: 'All Recruiters Listing',
    hello: 'Hello',
    welcomeBackToTheLeaderboard: 'Welcome back to the Leaderboard',
    createAJob: 'Create A Job',
    newJobSavedAsDraft: 'New job saved as draft!',
    publicRelations: 'Public Relations',
    marketing: 'Marketing',
    notSpecified: 'Not Specified',
    topRecruiterForEmployers: 'Top recruiter for employers. ',
    employersGaveThemStarReviews: 'Employers gave them 5 star reviews!',
    theRecruiterSpecifiedAboveWantsToApplyToTheJobAboveOnBehalfYouDoYouApprove: 'The recruiter specified above wants to apply to the job above on behalf you. Do you approve?',
    jobInvited: 'Job Invited',
    numberOfApplicant: 'Number Of Applicant',
    rejectedByEmployer: 'Rejected By Employer',
    draftJobs: 'Draft Jobs',
    closedJobs: 'Closed Jobs',
    openResume: 'Open Resume',

    INVALID_GRANT: 'Username or password is incorrect!',
    INVALID_BEARER_TOKEN: 'Invalid token!',
    INVALID_JWT: 'Invalid JWT!',
    INVALID_EMAIL: 'Invalid Email!',
    ACCESS_DENIED: 'No user authorization!',
    USER_NOT_FOUND: 'User not found!',
    USER_HAS_NO_PASSWORD: 'Newly created user trying to login without defining their first password yet!',
    EMAIL_NOT_VERIFIED: 'Email not verified!',

    numberOfApplicantShort: 'NoA',
    pendingReviewShort: 'PR',
    managerReviewShort: 'MR',
    interviewStageShort: 'IS',
    offerStageShort: 'OS',
    hrReviewShort: 'HRR',
    rejectedByEmployerShort: 'RbE',
    approvedShort: 'A',
    hiredShort: 'H',
    send: 'Send',
    howCanWeHelpYou: 'How can we help you?',
    yourMessageHasBeenSuccessfullyDelivered: 'Your message has been successfully delivered!',
    scores: 'Scores',
    ratingShort: 'Rtng',
    placementsShort: 'Plcmnt',
    scoreShort: 'Scr',
    deleteJobProfile: 'Delete Job Profile',

    theJobProfileWillBeRemovedFromYourListAreYouSure: 'The job profile will be removed from your list. Are you sure?',
    theJobWillBeRemovedFromYourListAreYouSure: 'The job will be removed from your list. Are you sure?',
    theJobProfileSuccessfullyDeleted: 'The job profile successfully successfully.',
    theJobSuccessfullyDeleted: "The job successfully deleted!",
    recomendedByTalentbase: 'Recomended by Picktus',
    employersGaveThem: 'Employers gave them',
    starReviews: 'star reviews!',
    recruiterReviews: 'Recruiter Reviews',
    comment: 'Comment',
    reviewStatusUpdatedSuccessfully: 'The review status updated successfully!',
    type: 'Type',
    wantKeepUpToDate: 'Want keep up-to-date?',
    getADailyEmailOfAllNewJobs: 'Get a daily email of all new jobs',
    enterYourEmail: 'Enter your email',
    yourEmailHasBeenSuccessfullySaved: 'Your email has been successfully saved!',
    currency: 'Currency',
    TurkishLiraSym: '₺',
    UnitedStatesDollarSym: '$',
    EuroSym: '€',
    earnedScore: 'Earned Score',
    fromReviews: 'From Reviews',
    fromPlacements: 'From Placements',
    yourLoginHasExpired: 'Your login has expired!',
    skillTags: 'Skill Tags',
    enterSkillTags: 'Enter Skill Tags',
    addNewSkill: 'Add New Skill',
    addedSkills: 'Added Skills',
    enterSalary: 'Enter Salary',
    nextStep: 'Next Step',
    createYourFirstJob: 'Create Your First Job',

    // 23.04.2022
    youHaveChosenSamePackage: 'You Have Chosen  Same Package',
    upgradeYourPackage: 'Please upgrade your package to use this feature',
    upgrade: 'Upgrade',

    // 10.06.2022
    shareJobAnonymously: 'Share job anonymously',

    // 12.06.2022
    favoriteRecruiters: 'Favorite Recruiters',
    theUserSuccessfullyDeleted: 'The user successfully deleted!',

    backToList: 'Back to List',
    detailsLowerCase: 'Details',
    profilePhoto: 'Profile Photo',
    experience: 'Experience',
    overallSatisfaction: 'Overall Satisfaction',
    expertisement: 'Expertisement',
    communication: 'Communication',
    followUp: 'Follow Up',
    informativity: 'Informativity',
    industryKnowledge: 'Industry Knowledge',
    averageRating: 'Average Rating',
    cloneJob: 'Clone Job',
    theJobSuccessfullyCloned: 'The job successfully cloned!',
    theJobSuccessfullyCreated: 'The job successfully created!',
    applicationNotes: 'Application Notes',
    addNote: 'Add Note',
    note: 'Note',

    // 26.07.2022
    packets: 'Packets',
    businessFunctions: 'Business Functions',
    allFunctionalRoles: 'All Functional Functions',
    selectedFunctionalRoles: 'Selected Functional Functions',
    PUBLIC_JOB_POSTING: 'Public Job Posting',
    RESTRICTED_JOB_POSTING: 'Restricted Job Posting',
    INVITATION_JOB_POSTING: 'Invitation Job Posting',
    APPLICATION_SUBMIT: 'Application Submit',
    new: 'New',
    null: 'Null',
    packet: 'Packet',
    description: 'Description',
    subDescription: 'Sub Description',
    planId: 'Plan Id',
    planName: 'Plan Name',
    trialInDays: 'Trial In Days',
    price: 'Price',
    cycle: 'Cycle',
    screenOrder: 'Screen Order',
    level: 'Level',
    commission: 'Commission',
    usageRestrictions: 'Usage Restrictions',
    free: 'Free',

    // 03.08.2022
    youShouldCompleteProfileBeforeJobPosting: 'You should complete profile before job posting',

    // 09.08.2022
    packages: 'Packages',
    myFavorites: 'My Favorites',
    recommendedRecruiters: 'Recommended Recruiters',

    // 10.08.2022
    highestFirst: 'Highest First',
    lowestFirst: 'Lowest First',
    none: 'None',
    recruiterListing: 'Recruiter Listing',

    // 19.08.2022
    payments: 'Payments',
    person: 'Person',
    payNow: 'Pay Now',

    // 23.08.2022
    placementDate: 'Placement Date',
    inGuaranteeDate: 'In Guarantee Date',
    paypal: 'PayPal',
    youWillBeRedirectedToThePaypalWebsiteAfterSubmittingYourOrder: 'You will be redirected to the PayPal website after submitting your order',
    payWithCreditCard: 'Pay with Credit Card',
    cardNumber: 'Card number',
    expirationDate: 'Expiration Date',
    cardSecurityCode: 'Card Security Code',
    grandTotal: 'Grand Total',
    pleaseCheckToAcknowledgeOur: 'Please check to acknowledge our',
    privacyTermsPolicy: 'Privacy & Terms Policy',
    pay: 'Pay',

    aboutMe: 'About Me',

    INTERNAL_SERVER_ERROR: 'Internal server error!',

    // Resource
    RESOURCE_NOT_FOUND: 'Resource not found!', // Ulaşılmak istenen obje bulunamadı
    RESOURCE_DELETED: 'Resource Deleted!', // Ulaşılmak istenen obje DELETED statüsünde

    // code=VALIDATION_ERROR olduğunda subErrors[n].code değerleri:
    INVALID_FIELD: 'INVALID_FIELD',
    MUST_BE_NULL: 'Please fill in the fields completely and in the correct format!',
    MUST_NOT_BE_NULL: 'Please fill in the fields completely and in the correct format!',
    MUST_BE_TRUE: 'Please fill in the fields completely and in the correct format!',
    MUST_BE_FALSE: 'Please fill in the fields completely and in the correct format!',
    MIN_VALUE_EXCEEDED: 'MIN_VALUE_EXCEEDED',
    MAX_VALUE_EXCEEDED: 'MAX_VALUE_EXCEEDED',
    DECIMAL_MIN_VALUE_EXCEEDED: 'DECIMAL_MIN_VALUE_EXCEEDED',
    DECIMAL_MAX_VALUE_EXCEEDED: 'DECIMAL_MAX_VALUE_EXCEEDED',
    MUST_BE_NEGATIVE: 'MUST_BE_NEGATIVE',
    MUST_BE_NEGATIVE_OR_ZERO: 'MUST_BE_NEGATIVE_OR_ZERO',
    MUST_BE_POSITIVE: 'MUST_BE_POSITIVE',
    MUST_BE_POSITIVE_OR_ZERO: 'MUST_BE_POSITIVE_OR_ZERO',
    INVALID_SIZE: 'Invalid size!',
    INVALID_DIGITS: 'Invalid digits!',
    MUST_BE_IN_THE_PAST: 'MUST_BE_IN_THE_PAST',
    MUST_BE_IN_THE_PAST_OR_PRESENT: 'MUST_BE_IN_THE_PAST_OR_PRESENT',
    MUST_BE_IN_THE_FUTURE: 'MUST_BE_IN_THE_FUTURE',
    MUST_BE_IN_THE_FUTURE_OR_PRESENT: 'MUST_BE_IN_THE_FUTURE_OR_PRESENT',
    MUST_MATCH_THE_REGEX: 'Please fill in the fields completely and in the correct format!',
    MUST_NOT_BE_EMPTY: 'Please fill in the fields completely and in the correct format!',
    MUST_NOT_BE_BLANK: 'Please fill in the fields completely and in the correct format!',

    // 05.09.2022
    allNotifications: 'All Notifications',
    noNotificationYet: 'No Notification Yet',
    FAVORITE_RECRUITER: 'Added You to Favorite',
    JOB_INVITATION: 'Invited You to Job',

    // 06.09.2022
    inMemberSince: 'In member since',
    recruiterSampleAboutText: 'No information available',
    noSavedNotesYet: 'No saved notes yet',
    youHasNotBeenInvitedAnyJobYet: 'You has not been invited any job yet',

    // 08.09.2022
    picktusCredits: 'Picktus Credits',
    recomendedByPicktus: 'Recomended by Picktus',
    recommendedForYouByPicktus: 'recommended for you by Picktus',
    thereAre: 'there are',
    hi: 'hi',
    jobsInvited: 'Jobs Invited',

    // 14.09.2022
    applications: 'Applications',
    baseOnYourPreferences: 'Base on your preferences',
    allApplications: 'All Applications',
    savedApplications: 'Saved Applications',
    noteTitle: 'Note title',
    writeNote: 'Write note',

    // 18.09.2022
    landingHeader: 'The best way to connects the recruiters and the employers',
    landingSubHeader: 'By the help of the platform, employers will be able to choose the best recruiters for their vacancies',
    whyPicktus: 'Why Picktus?',
    whyPicktusDescription: 'By the help of the platform, employers will be able to hoose the best recruiters for their vacancies, where recruiters will access the marketplace to find the correct assignments.',
    whyPicktusHeader1: 'Candidate Onboarding & Placement Management',
    whyPicktusDesc1: 'Access Picktus from your computer, phone, or tablet to ensure you never miss any activity.',
    whyPicktusHeader2: 'Thousands of Free and Premium Job Posting Channels',
    whyPicktusDesc2: 'Slate helps you see how many more days you need to work to reach your financial goal.',
    whyPicktusHeader3: 'Easy-to-use Solution',
    whyPicktusDesc3: 'Slate helps you see how many more days you need to work to reach your financial goal.',
    exploreOurRecruitmentSoftware: 'Explore Our Recruitment Software',
    exploreOurDesc: 'Picktus, was designed to simplify your hiring process and save you valuable time by helping you choose the right candidates, manage your talent pool, strengthen team collaboration.',
    easyToUseSolution: 'Easy-to-use Solution',
    easyToUseDesc: 'Slate helps you see how many more days you need to work to reach your financial goal.',
    getAQuote: 'Get a Quote!',
    features: 'Features',
    featuresDesc: 'By the help of the platform, employers will be able to hoose the best recruiters for their vacancies, where recruiters will access the marketplace to find the correct assignments.',
    downloadAppNow: 'Download App Now',
    ourPartners: 'Our Partners',
    ourPartnersDesc: 'Most recruiter platforms are designed for teams. Slate is designed for recruiters and employers.',
    contactUsDesc: ' Most recruiter platforms are designed for teams. Slate is designed for recruiters and employers.',
    footerDesc: 'Picktus is an advanced recruitment software enabling human resources departments and recruitment agencies to source and hire in the most effective way.',
    theJobWillCloneAreYouSure: 'The job will be cloned. Are you sure?',
    PACKAGE_DOES_NOT_CONTAIN_FUNCTIONALITY: 'Your package does not support this functionality!',
    pleaseSelectCountryFirst: 'Please select country first!',
    newCandidate: 'New Candidate',
    newPackageSuccessfullyAdded: 'New package successfully added!',
    newPackageSuccessfullyUpdated: 'The package successfully updated!',
    thePackageWillBeRemovedFromYourListAreYouSure: 'The package will be removed from your list. Are you sure?',
    newBusinessFunctionsSuccessfullyAdded: 'New business functions successfully added!',
    newBusinessFunctionsSuccessfullyUpdated: 'The business functions successfully updated!',

    theBusinessFunctionseWillBeRemovedFromYourListAreYouSure: 'The business functions will be removed from your list. Are you sure?',
    deletePackage: 'Delete Package',
    deleteBusinessFunctions: 'Delete Business Functions',
    noJobYet: 'No job yet!',
    noEmployerYet: 'No employer yet!',
    noRecruiterYet: 'No recruiter yet!',
    noFaqYet: 'No FAQ yet!',
    noJobProfileYet: 'No job profile yet!',
    searchNoResultFor: 'Search no result for ',
    noUserYet: 'No user yet!',

    // 19.09.2022
    recruiterName: 'Recruiter Name',
    theNoteWillBeRemovedAreYouSure: 'The note will be removed. Are you sure?',
    jobDetails: 'Job Details',

    // 21.09.2022
    noNotesYet: 'No notes yet.',
    noApplicationYet: 'No application yet!',
    clearSearch: 'Clear search',
    noPaymentYet: 'No payment yet!',
    noEarningsYet: 'No earnings yet!',
    noRecruitersYet: 'No recruiters yet!',

    APPLICATION_DISMISSED: 'Application Dismissed',
    APPLICATION_RESIGNED: 'Application Resigned',
    IN_GUARANTEE_PERIOD_FINISHED: 'In Guarantee Period Finished',
    IN_GUARANTEE_PERIOD_ONE_WEEK_LEFT: 'In Guarantee Period One Week Left',
    LAST_PAYMENT_FINISHED: 'Last Payment Finished',
    LAST_PAYMENT_ONE_DAYS_LEFT: 'Last Payment One Days Left',
    LAST_PAYMENT_TWO_DAYS_LEFT: 'Last Payment Two Days Left',
    LAST_PAYMENT_THREE_DAYS_LEFT: 'Last Payment One Three Left',
    LAST_PAYMENT_ONE_WEEK_LEFT: 'Last Payment One Week Left',


    // 27.09.2022
    mustALowerLetter: 'Must a Lower Letter',
    mustAUpperLetter: 'Must a Upper Letter',
    mustADigit: 'Must a Digit',
    mustASpecialLetter: 'Must Special Letter',
    mustAtLeastSixLetter: 'Must Least Six Letters',

    // 10.10.2022
    noCommentsYet: 'No Comments Yet',

    successfullyHired: 'Successfully Hired!',
    taxOffice: 'Tax Office',
    taxNumber: 'Tax Number',
    iban: 'IBAN',


    // 04.11.2022
    linkExpired: 'Link Expired!',
    passwordResetErrorText: 'Hi, there your link has expired, because you haven’t used it. Login link expires in every 24 hours and can only be used once.',
    youWillBeRedirectedToTheLoginPageIn: 'You will be directed to the login page in',
    seconds: 'seconds',
    wiewRelatedJobs: 'Wiew Related Jobs',
    contactPersonEmail: 'Contact Person Email',
    JOB_NOT_ACTIVE: "This job is not active!",
    CANDIDATE_ALREADY_SUBMITTED: "The candidate has already been submitted for this job!",
    invalidPhoneNumber: "Invalid Phone Number!",
    pleaseEnterAValidPhoneNumberToPay: "Please enter a valid phone number to pay!",
    choose:'Choose',


    'currency_null': 'USD',
    'currency_Turkish Lira': 'TRY',
    'currency_United States Dollar': 'USD',
    'currency_Sterling': 'GBP',
    'currency_Euro': 'EUR',

    backToPay: 'Back to Pay',

    emailFormatError: 'Please enter your e-mail in the correct format.',
    legalInfo: 'Legal Info',
    youHaveNotSelectACountry: 'You have not select a country',
    selectCity: 'Select City',
    legalAddress: 'Legal Address',



};



