import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../auth';
import { server } from '../server';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserMeService extends ApiService {

  constructor(public http: HttpClient, public spinner: NgxSpinnerService, public translate: TranslatePipe, public notification: NotificationService) {
    super(http, server.usersMe, spinner, translate, notification)
  }

  getGeneralInfo(){
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.get(server.usersMe + "/general-info",  options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      );
  }

  putGeneralInfo(model){
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.put(server.usersMe + "/general-info", model, options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        })
      );
  }
}
