import { Component, OnInit } from '@angular/core';
import { CreateAvatar, RequestPayload } from 'src/app/shared/classes';
import { RecruiterReviewModel } from 'src/app/shared/models';
import { NotificationService, ReviewService, server, TranslatePipe } from 'src/app/shared/services';

@Component({
  selector: 'app-admin-reviews',
  templateUrl: './admin-reviews.component.html',
  styleUrls: ['./admin-reviews.component.scss']
})
export class AdminReviewsComponent implements OnInit {

  reviews: RecruiterReviewModel[] = [];
  createAvatar: CreateAvatar;
  requestPayload: RequestPayload;
  currentPage: number;
  count: number;
  selectedReview: RecruiterReviewModel = null;

  constructor(private reviewService: ReviewService, private notification: NotificationService, private translate: TranslatePipe) {
    this.requestPayload = new RequestPayload();
    this.requestPayload.limit = 5;
    this.createAvatar = new CreateAvatar();
  }

  ngOnInit(): void {
    this.getReviews(1);
  }

  getReviews(page) {
    this.reviewService.ServerGetWithText(this.requestPayload.payloadURLWithOutDelete(page)).subscribe(x => {
      this.reviews = x.content;
      this.count = x.totalElements;
      this.reviews.forEach(f => {
        f.imageText = this.createAvatar.create(f.fullName);
        f.imageColor = this.createAvatar.color();
      });
    });
  }

  openModal(rev) {
    this.selectedReview = rev;

    // @ts-ignore
    $('#adminReviewModal').modal('show');
  }

  changeStatus(accept) {

    let model = {
      status: accept ? 'ACCEPTED' : 'REJECTED'
    };

    this.reviewService.ServerPut(server.reviews + '/' + this.selectedReview.id + '/status', model).subscribe(x => {
      // @ts-ignore
      $('#adminReviewModal').modal('hide');
      this.notification.success(this.translate.transform('reviewStatusUpdatedSuccessfully'));
      this.getReviews(1);
    });


  }



}
