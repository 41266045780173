<div style="background-color: #F5F6F8;">

        <div class="col-12 col-lg-10 m-auto pb-5" style="padding-top: 100px">

          <div class="card pt-5 pb-5" style="background-color: white;">
            <div class="card-body">
          <div class="row">
            <div class="col-md-1 col-12 text-center mb-4 mb-md-0">

            </div>
            <div class="col-md-10 col-12 text-md-left text-center ">
              <span style="width: 27px;
        height: 27px; text-align: center ;color: #FFFFFF;
        "   class="dot" [ngClass]="step > 1 ? 'green' : step == 1 ? 'blue' : 'white'">1</span> <span class="text-height pl-2 step-header-text">{{'generalInfo' | translate}}</span>
              <hr class="line mx-1" [ngClass]="step > 1 ? 'green-hr' : 'white-hr'" />
              <span style="width: 27px;
        height: 27px;  text-align: center;color: #FFFFFF;
        " class="dot" [ngClass]="step > 2 ? 'green' : step == 2 ? 'blue' : 'white'"> 2</span> <span class="text-height pl-2 step-header-text">{{'contactPerson' | translate}}</span>
              <hr  class="line mx-1" [ngClass]="step > 2 ? 'green-hr' : 'white-hr'" />
              <span style="width: 27px;
        height: 27px;  text-align: center;color: #FFFFFF;
        " class="dot" [ngClass]="step > 3 ? 'green' : step == 3 ? 'blue' : 'white'">3</span> <span class="text-height pl-2 step-header-text">{{'companyInfo' | translate}}</span>
              <hr class="line mx-1" [ngClass]="step > 3 ? 'green-hr' : 'white-hr'" />
              <span style="width: 27px;
        height: 27px;  text-align: center;color: #FFFFFF;
        "   class="dot" [ngClass]="step > 4 ? 'green' : step == 4 ? 'blue' : 'white'">4</span>  <span class="text-height pl-2 step-header-text"> {{'locationInfo' | translate}} </span>
              <hr class="line mx-1" [ngClass]="step > 4 ? 'green-hr' : 'white-hr'" />
              <span style="width: 27px;
        height: 27px;  text-align: center;color: #FFFFFF;
        "  class="dot" [ngClass]="step > 5 ? 'green' : step == 5 ? 'blue' : 'white'">5</span> <span class="text-height pl-2 step-header-text">{{'socialLinks' | translate}}</span>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-11 col-12 ml-md-auto text-center text-md-left">
              <h2>{{'completeYourProfile' | translate}}</h2>
              <p class="text-secondary">
                {{'fillInTheDataForProfileItWillTakeACoupleOfMinutes' | translate}}
              </p>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-1"></div>
            <div class="col-md-10 col-11 mx-auto mx-md-0 border rounded p-4" *ngIf="step==1">
              <h4>{{'generalInfo' | translate}}</h4>
              <tlb-form *ngIf="formGeneralInfo && formGeneralInfo.length > 0 && formGroupGeneralInfo" [forms]="formGeneralInfo" [formGroup]="formGroupGeneralInfo"></tlb-form>
            </div>
            <div class="col-md-10 col-11 mx-auto mx-md-0 border rounded p-4" *ngIf="step==2">
              <h4>{{'contactPerson' | translate}}</h4>
              <div>
              <div class="alert alert-light alert-dismissible fade show alert-size " role="alert">
                <div class="row">
                  <div class="col-md-2">
                    <img src="./assets/images/claps.png" alt="clap">
                  </div>
                  <div class="col-md-9">
                    <div class="row">
                      <div class="col-12"> Almost Done</div>
                    </div>
                    <div class="row">
                      <div class="col-12"> Profile needs to be at least 20% completed</div>

                    </div>
                  </div>
                </div>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              </div>



              <tlb-form *ngIf="formContactPerson && formContactPerson.length > 0 && formGroupContactPerson" [forms]="formContactPerson" [formGroup]="formGroupContactPerson"></tlb-form>
            </div>


            <div class="col-md-10 col-11 mx-auto mx-md-0 border rounded p-4" *ngIf="step==3">
              <h4>{{'companyInfo' | translate}}</h4>
              <tlb-form *ngIf="formModelCompanyInfo && formModelCompanyInfo.length > 0 && formGroupCompanyInfo" [forms]="formModelCompanyInfo"  [formGroup]="formGroupCompanyInfo"></tlb-form>
            </div>

            <div class="col-md-10 col-11 mx-auto mx-md-0 border rounded p-4" *ngIf="step==4">
              <h4>{{'addressInfo' | translate}}</h4>
              <tlb-form *ngIf="formAddress && formAddress.length > 0 && formGroupAddress" [forms]="formAddress" [formGroup]="formGroupAddress"></tlb-form>
            </div>


            <div class="col-md-10 col-11 mx-auto mx-md-0 border rounded p-4" *ngIf="step==5">
              <h4>{{ 'socialLinks' | translate}}</h4>
              <tlb-form *ngIf="formSocialLink && formSocialLink.length > 0 && formGroupSocialLink" [forms]="formSocialLink"  [formGroup]="formGroupSocialLink"></tlb-form>
            </div>

          </div>
          <div class="row mt-3">
            <div class="col-1"></div>
            <div class="col-md-10 col-11 mx-auto mx-md-0 px-0" style=" display: flex;
            justify-content: space-between;">
              <button *ngIf="pageType == 'null' && step>1" class="btn btn-back border mr-3" (click)="goBack()">← {{'previousStep' | translate}}</button>
              <button *ngIf="pageType == 'null' && step!=5" class="btn btn-next border" (click)="goNext()">{{'nextStep' | translate}} →</button>
              <button *ngIf="pageType != 'null'" class="btn btn-next border" (click)="saveContactPerson()">{{'update' | translate}}</button>
              <button *ngIf="pageType != 'null'" class="btn btn-primary" (click)="backToPay()" [disabled]="!updatedPhoneNumber">{{'backToPay' | translate}}</button>
              <button *ngIf="pageType == 'null' && step==5" class="btn btn-finish border" (click)="finish()">{{'finish' | translate}}</button>
            </div>
          </div>


      </div>
    </div>
  </div>

</div>


