import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecruitersFilterServiceService {

  clearFilterAndSortSubject: Subject<any> = new Subject<any>();

  constructor() { }
}
