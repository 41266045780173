export class GeneralResponseModel {
  content: any[];
  empty: boolean;
  first: boolean;
  last: boolean;
  numberOfElements: number;
  pageIndex: number;
  pageSize: number;
  totalElements: number;
  totalPages: number;

  constructor() {
    this.content = [];
    this.empty = false;
    this.first = false;
    this.last = false;
    this.numberOfElements = 0;
    this.pageIndex = 0;
    this.pageSize = 0;
    this.totalElements = 0;
    this.totalPages = 0;
  }
}

export class PaginationDtoApplicationModel<T> {
  content: T;
  empty: boolean;
  first: boolean;
  last: boolean;
  numberOfElements: number;
  pageIndex: number;
  pageSize: number;
  totalElements: number;
  totalPages: number;

  constructor() {
    this.content = null as T;
    this.empty = false;
    this.first = false;
    this.last = false;
    this.numberOfElements = 0;
    this.pageIndex = 0;
    this.pageSize = 0;
    this.totalElements = 0;
    this.totalPages = 0;
  }
}




