<!--<div class="row">
  <div class="col-6 text-center">
    {{'allFunctionalRoles'|translate}}
  </div>
  <div class="col-6 text-center">
    {{'selectedFunctionalRoles'|translate}}
  </div>
  <div class="col-6 functionalRole-list">
    <div class="cursor-pointer functionalRole"
         *ngFor="let functionalRole of allFunctionalRoles;let i = index;"
         (click)="selectFunctionalRole(functionalRole,i)">{{functionalRole|translate}}</div>
  </div>
  <div class="col-6 functionalRole-list-2">
    <div class="cursor-pointer functionalRole"
         *ngFor="let functionalRole of selectedFunctionalRoles;let i = index;"
         (click)="unSelectFunctionalRole(functionalRole,i)">{{functionalRole|translate}}</div>
  </div>
  <div class="col-12">

  </div>
</div>-->
<div class="row">
  <div class="col-12">
    <select class="form-control"
            [(ngModel)]="selectedBusinessFunction"
            (change)="changeBusinessFunction()">
      <option [ngValue]="newBusinessFunction">{{'new' | translate}}</option>
      <option [ngValue]="businessFunction"
              *ngFor="let businessFunction of businessFunctions">
        {{businessFunction.name}}
      </option>
    </select>
    <label for="business-function-name">{{'name' | translate}}:</label>
    <input type="text"
           class="form-control"
           [(ngModel)]="selectedBusinessFunction.name"
           id="business-function-name">

    <label for="business-function-name"
           class="d-block">{{'functionalRoles'| translate}}:</label>
    <select class="form-control  w-75 d-inline-block"
            [(ngModel)]="selectedFunctionalRole">
      <option [ngValue]="functionalRole"
              *ngFor="let functionalRole of allFunctionalRoles">
        {{functionalRole|translate}}
      </option>
    </select>
    <button class="btn btn-primary w-25 d-inline-block"
            (click)="selectFunctionalRole()">{{'add' | translate}}</button>
    <div class="functionalRole"
         *ngFor="let functionalRole of selectedBusinessFunction.functionalRoles;let i = index;"
    >{{functionalRole|translate}}
      <span class="delete-functional-role cursor-pointer"
            (click)="unSelectFunctionalRole(functionalRole,i)">X</span></div>
    <div class="form-group">
      <label for="business-function-status"
             class="d-block mt-3">{{'status'| translate}}:</label>
      <select class="form-control"
              id="business-function-status"
              [(ngModel)]="selectedBusinessFunction.status">
        <option [ngValue]="BusinessFunctionStatusEnum.ACTIVE">
          {{BusinessFunctionStatusEnum.ACTIVE | translate}}
        </option>
        <option [ngValue]="BusinessFunctionStatusEnum.PASSIVE">
          {{BusinessFunctionStatusEnum.PASSIVE | translate}}
        </option>
      </select>

    </div>
    <button class="btn btn-primary mt-4 w-100"
            (click)="save()">{{'save' | translate}}</button>
    <button class="btn btn-danger mt-4 w-100"
            (click)="openDeletePacket()">{{'delete' | translate}}</button>
  </div>


</div>


<general-delete-modal [Title]="'deleteBusinessFunctions'" [Text]="'theBusinessFunctionseWillBeRemovedFromYourListAreYouSure'"
(selection)="delete($event)"></general-delete-modal>

