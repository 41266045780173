import { GeneralIdNameModel } from "../general";

export class NotificationsModel {

  createdAt: string;
  createdBy: GeneralIdNameModel;
  id: string;
  status: string;
  type: string;
  content: string;
  contentId: string;
  updatedAt: string;
  updatedBy: GeneralIdNameModel;
  userId: string;
  employerId: string;
  recruiterId: string;
  read: boolean;

  day: string;
  title: string;
  imageText: string;
  imageColor: string;

  constructor() {
    this.createdAt = "";
    this.createdBy = new GeneralIdNameModel();
    this.id = "";
    this.status = "";
    this.content = "";
    this.updatedAt = "";
    this.updatedBy = new GeneralIdNameModel();
    this.day = "";
    this.title = "";
    this.contentId = null;
    this.type = "";
    this.userId = "";
    this.employerId = "";
    this.recruiterId = "";
    this.read = false;
    this.imageColor = "";
    this.imageText = "";

  }
}
