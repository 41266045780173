import { JobExperienceRequiredModel } from ".";

export class JobExperiencesModel {

  id: string;
  experienceLevel: string;
  educationLevel: string;
  experienceRequired: JobExperienceRequiredModel;
  description: string;
  skills: string[];

constructor() {
  this.id = "";
  this.experienceLevel = null;
  this.educationLevel = null;
  this.experienceRequired = new JobExperienceRequiredModel();
  this.description = "";
  this.skills = [];
}
}
