import { Injectable }          from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable()
export class EmployerGuard implements CanActivate {
  constructor(private router: Router) {
  }

  canActivate() {
    const role = localStorage.getItem('role');
    if (role === 'EMPLOYER_MANAGER' || role === 'EMPLOYER_USER') {
      return true;
    } else {
      this.router.navigate(['/dashboard']);
      return false;
    }
  }
}
