import {JobNotificationsAndCorporatesModel} from './../../../../shared/models/job/job-notifications-and-corporates.model';
import {JobCompensationsAndFeesModel} from './../../../../shared/models/job/job-compensations-and-fees.model';
import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  EducationLevelType,
  ExperienceLevelType,
  FeeType,
  FormType,
  JobAccessibility,
  JobType,
  MaxAmount,
  MinAmount,
  RelocationAssistanceType
} from 'src/app/shared/enums';
import {
  DropdownOptionsModel,
  GeneralIdModel,
  JobAuthorizedPersonModel,
  JobFeeModel,
  JobInvitationModel,
  JobQuestionModel,
  JobQuestionnaireModel,
  JobRestrictionModel,
  PhoneNumberModel,
  TlbFormModel
} from 'src/app/shared/models';
import {
  CityService,
  CountryService,
  CurrencyService,
  EmployerMeService,
  IndustryService,
  JobClassificationService,
  JobFunctionService,
  JobService,
  NotificationService,
  RecruiterService,
  server,
  TranslatePipe,
  UserService,
  VisaService
} from 'src/app/shared/services';
import {JobGeneralInfosModel} from 'src/app/shared/models/job/job-general-infos.model';
import {JobExperiencesModel} from 'src/app/shared/models/job/job-experiences.model';
import {JobLocationsModel} from 'src/app/shared/models/job/job-locations.model';
import {JobPreQuestionsModel} from 'src/app/shared/models/job/job-pre-questions.model';
import {JobTeamMembersModel} from 'src/app/shared/models/job/job-team-members.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {PermissionService} from '../../../../shared/services/permission/permission.service';
import {AllRecruiterInfoModel} from '../../../../shared/models/recruiter/recruiters-with-recommendation-model';

EmployerMeService;

declare var rSlider: any;
declare var $: any;

@Component({
  selector: 'app-create-new-job-profile',
  templateUrl: './create-new-job-profile.component.html',
  styleUrls: ['./create-new-job-profile.component.scss']
})

export class CreateNewJobProfileComponent implements OnInit {
  questionsArray: any[] = [];
  questions: any[] = [];

  id: string = null;
  step = 1;

  headers = [
    'generalInfo', 'skillsExperience', 'location', 'preScreenQuestions', 'compensationFee', 'notification'
  ];

  generalInfos: JobGeneralInfosModel = new JobGeneralInfosModel();
  experiences: JobExperiencesModel = new JobExperiencesModel();
  locations: JobLocationsModel = new JobLocationsModel();
  compensationsAndFees: JobCompensationsAndFeesModel = new JobCompensationsAndFeesModel();
  preQuestions: JobPreQuestionsModel = new JobPreQuestionsModel();
  notificationsAndCorporates: JobNotificationsAndCorporatesModel = new JobNotificationsAndCorporatesModel();
  jobTeamMembers: JobTeamMembersModel = new JobTeamMembersModel();

  doneGeneralInfo = false;
  doneExperiences = false;
  doneLocations = false;
  doneCompensationsAndFees = false;
  donePreQuestions = false;
  doneNotificationsAndCorporates = false;
  doneTeamMembers = false;

  formGeneralInfo: TlbFormModel[] = [];
  formGroupGeneralInfo: FormGroup;
  formExperience: TlbFormModel[] = [];
  formGroupExperience: FormGroup;
  formLocation: TlbFormModel[] = [];
  formGroupLocation: FormGroup;
  formCompensation: TlbFormModel[] = [];
  formGroupCompensation: FormGroup;
  formNotification: TlbFormModel[] = [];
  formGroupNotification: FormGroup;
  formTeamMember: TlbFormModel[] = [];
  formGroupTeamMember: FormGroup;
  jobAccessibilityOptions: DropdownOptionsModel[] = [];
  jobAccessibilityLabel = 'pleaseSelect';
  jobAccessibility = 'PUBLIC';
  recruiterMail = '';
  recruiterMailArray: any[] = [];
  ruleBasedOptions: DropdownOptionsModel[] = [
    { label: this.translate.transform('ratings'), value: 'rating' },
    { label: this.translate.transform('placements'), value: 'placement' }
  ];
  ruleLabel = 'ratings';
  rule = 'rating';
  placement = 0;
  star = 0;
  recText = '';
  @Output() publishedJob = new EventEmitter();

  @Input() jobId = null;

  recruitersOptions: any[] = [];

  jobAccessibilityConfirmButtonDisabled = false;
  selectedRecruitersForInvitation: AllRecruiterInfoModel[] = [];

  constructor(private classificationService: JobClassificationService, private functionService: JobFunctionService,
    private industryService: IndustryService, private visaService: VisaService, public jobService: JobService,
    private countryService: CountryService, private cityService: CityService,
    public notification: NotificationService, public translate: TranslatePipe,
    private userService: UserService, private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef, private employersMeService: EmployerMeService,
    private recruiterService: RecruiterService, private router: Router,
    private currencyService: CurrencyService,
    public permissionService: PermissionService) {
  }

  get formGI() {
    return this.formGroupGeneralInfo.controls;
  }

  get formEx() {
    return this.formGroupExperience.controls;
  }

  get formLoc() {
    return this.formGroupLocation.controls;
  }

  get formCom() {
    return this.formGroupCompensation.controls;
  }

  get formNot() {
    return this.formGroupNotification.controls;
  }

  get formTM() {
    return this.formGroupTeamMember.controls;
  }

  ngOnInit() {

    if (this.jobId && this.jobId != null) {
      this.id = this.jobId;
      this.notification.success(this.translate.transform('newJobSavedAsDraft'));
      this.questionsArray = [{ id: 'question0', index: 0, ques: '' }];
      this.questions = [];
      this.getValues();
    } else if (this.route.snapshot.paramMap.get('id') && this.route.snapshot.paramMap.get('id') != 'null') {
      this.jobId = this.route.snapshot.paramMap.get('id');
      this.id = this.jobId;
      this.jobService.getPreQuestions(this.jobId)
        .subscribe(x => {
          this.preQuestions = x as JobPreQuestionsModel;
          this.questionsArray = [{ id: 'question0', index: 0, ques: '' }];
          this.questions = [];
          if (this.preQuestions.questionnaire && this.preQuestions.questionnaire.questions && this.preQuestions.questionnaire.questions.length > 0) {
            this.questionsArray = [];
            let ind = 0;
            this.preQuestions.questionnaire.questions.forEach(f => {
              this.questionsArray.push({ id: 'question' + ind, index: ind });
              this.questions.push(f.content);
              ind++;
            });
          }

        });

      this.getValues();
    } else {
      this.questionsArray = [{ id: 'question0', index: 0, ques: '' }];
      this.questions = [];
      this.createGeneralInfoForm();
      this.createEcxperienceForm();
      this.createLocationForm();
      this.createCompensationForm();
      this.createNotificationForm();
      this.createTeamMemberForm();

    }

  }

  getValues() {
    this.jobService.getGeneralInfo(this.jobId)
      .subscribe(x => {
        this.generalInfos = x as any;
        this.createGeneralInfoForm();
      });

    this.jobService.getExperiences(this.jobId)
      .subscribe(x => {
        this.experiences = x as any;
        this.createEcxperienceForm();
      });

    this.jobService.getLocations(this.jobId)
      .subscribe(x => {
        this.locations = x as any;
        this.createLocationForm();
      });

    this.jobService.getCompensationsAndFees(this.jobId)
      .subscribe(x => {
        this.compensationsAndFees = x as any;
        this.createCompensationForm();
      });

    this.jobService.getNotificationsAndCorporates(this.jobId)
      .subscribe(x => {
        this.notificationsAndCorporates = x as any;
        this.createNotificationForm();

      });

    this.jobService.getTeamMembers(this.jobId)
      .subscribe(x => {
        this.jobTeamMembers = x as any;
        this.createTeamMemberForm();
      });

  }

  createGeneralInfoForm() {

    this.classificationService.ServerGetWithoutLogin()
      .subscribe(x => {
        const classifications = x.content;
        const classificationOptions = classifications.map(c => ({ value: c.id, label: c.defaultLocalization.name }));

        this.functionService.ServerGetWithoutLogin()
          .subscribe(f => {
            const functions = f.content;
            const functionOptions = functions.map(c => ({ value: c.id, label: c.defaultLocalization.name }));

            this.industryService.ServerGetWithoutLogin()
              .subscribe(i => {
                const industries = i.content;
                const industryOptions = industries.map(c => ({ value: c.id, label: c.defaultLocalization.name }));

                const jobTypeOptions = Object.keys(JobType)
                  .map(c => ({ value: c, label: this.translate.transform(c) }));

                const s = this.generalInfos ? this.generalInfos : null;

                if (this.id == null && this.jobId == null) {
                  this.generalInfos.classification = new GeneralIdModel();
                  this.generalInfos.function = new GeneralIdModel();
                  this.generalInfos.industry = new GeneralIdModel();
                }

                this.formGeneralInfo = [{
                  class: 'col-md-6 col-12', components: [
                    {
                      label: 'jobTitle', control: 'title', type: FormType.text, required: true,
                      style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
                      labelStyle: 'font-family: "Roboto"; font-style: normal; font-weight: 400; font-size: 16px; color: #23252A;'
                    },
                    {
                      label: 'jobClassification',
                      control: 'classification',
                      required: true,
                      type: FormType.dropDown,
                      options: classificationOptions,
                      style: 'background: #FFFFFF; border: 1px solid #BCB6B1; box-sizing: border-box; border-radius: 8px; height: 45px;',
                      labelStyle: 'font-family: "Roboto"; font-style: normal; font-weight: 400; font-size: 16px; color: #23252A;'
                    },
                    {
                      label: 'jobFunction',
                      control: 'function',
                      required: true,
                      type: FormType.dropDown,
                      options: functionOptions,
                      style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
                      labelStyle: 'font-family: "Roboto"; font-style: normal; font-weight: 400; font-size: 16px; color: #23252A;'
                    },
                    {
                      label: 'shareJobAnonymously',
                      control: 'anonymous',
                      required: false,
                      type: FormType.switchButton,
                      labelStyle: 'margin-left: 7px;'

                    }
                  ]
                },
                {
                  class: 'col-md-6 col-12', components: [
                    {
                      label: 'industry',
                      control: 'industry',
                      required: true,
                      type: FormType.dropDown,
                      options: industryOptions,
                      style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
                      labelStyle: 'font-family: "Roboto"; font-style: normal; font-weight: 400; font-size: 16px; color: #23252A;'
                    },
                    {
                      label: 'jobType',
                      control: 'type',
                      options: jobTypeOptions,
                      required: true,
                      type: FormType.dropDown,
                      style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
                      labelStyle: 'font-family: "Roboto"; font-style: normal; font-weight: 400; font-size: 16px; color: #23252A;'
                    },
                    {
                      label: 'totalNumberOfPositions',
                      control: 'positionCount',
                      required: true,
                      min: 1,
                      type: FormType.customNumericText,
                      style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
                      labelStyle: 'font-family: "Roboto"; font-style: normal; font-weight: 400; font-size: 16px; color: #23252A;'
                    }
                  ]
                }];

                this.employersMeService.ServerGet()
                  .subscribe(me => {

                    this.formGroupGeneralInfo = new FormGroup({
                      title: new FormControl(s ? s.title : '',
                        Validators.required),
                      classification: new FormControl(
                        s && s.classification ? s.classification.id : null,
                        Validators.required),
                      function: new FormControl(
                        s && s.function ? s.function.id : null,
                        Validators.required),
                      industry: new FormControl(
                        (s && s.industry && s.industry.id ? s.industry.id : (me.industries.length > 0 ? me.industries[0].id : null)),
                        Validators.required),
                      type: new FormControl(s ? s.type : null,
                        Validators.required),
                      positionCount: new FormControl(
                        s ? s.positionCount : '',
                        Validators.required),
                      anonymous: new FormControl(s ? s.anonymous : '',
                        Validators.required)
                    });

                  });

              });
          });

      });
  }

  createEcxperienceForm() {
    const experienceLevelOptions = Object.keys(ExperienceLevelType)
      .map(c => ({ value: c, label: this.translate.transform(c) }));

    const educationLevelOptions = Object.keys(EducationLevelType)
      .map(c => ({ value: c, label: this.translate.transform(c) }));

    const s = this.experiences ? this.experiences : null;

    const arrayText = s ? this.experiences.skills.join(',') : '';

    this.formExperience = [{
      class: 'col-md-6 col-12', components: [
        {
          label: 'experienceLevel',
          control: 'experienceLevel',
          type: FormType.dropDown,
          required: true,
          options: experienceLevelOptions,
          style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
          labelStyle: 'font-family: "Roboto"; font-style: normal; font-weight: 400; font-size: 16px; line-height: 150%; color: #23252A;'
        },
        {
          label: 'experienceRequired',
          control: 'year',
          control2: 'month',
          required: true,
          type: FormType.doubleNumericText,
          style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
          labelStyle: 'font-family: "Roboto"; font-style: normal; font-weight: 400; font-size: 16px; line-height: 150%; color: #23252A;'
        }
      ]
    },
    {
      class: 'col-md-6 col-12', components: [
        {
          label: 'educationLevel',
          control: 'educationLevel',
          required: true,
          type: FormType.dropDown,
          options: educationLevelOptions,
          style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
          labelStyle: 'font-family: "Roboto"; font-style: normal; font-weight: 400; font-size: 16px; line-height: 150%; color: #23252A;'
        },
        {
          label: 'skills',
          control: 'skills',
          placeHolder: 'addCommaToEnterMultipleSkills',
          required: true,
          type: FormType.arrayText,
          style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
          labelStyle: 'font-family: "Roboto"; font-style: normal; font-weight: 400; font-size: 16px; line-height: 150%; color: #23252A;'
        }
      ]
    },
    {
      class: 'col-md-12 col-12', components: [
        {
          label: 'jobDescription', control: 'jobDescription', required: true, type: FormType.textArea,
          style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 100%;',
          labelStyle: 'font-family: "Roboto"; font-style: normal; font-weight: 400; font-size: 16px; line-height: 150%; color: #23252A;'
        }
      ]
    }
    ];

    this.formGroupExperience = new FormGroup({
      experienceLevel: new FormControl(s ? s.experienceLevel : null,
        Validators.required),
      year: new FormControl(s ? s.experienceRequired.year : null,
        Validators.required),
      month: new FormControl(s ? s.experienceRequired.month : null,
        Validators.required),
      educationLevel: new FormControl(s ? s.educationLevel : null,
        Validators.required),
      skills: new FormControl(arrayText, Validators.required),
      jobDescription: new FormControl(s ? s.description : '',
        Validators.required)
    });
  }

  createLocationForm() {
    this.countryService.ServerGetWithoutLogin()
      .subscribe(x => {
        const countries = x.content;
        const countryOptions = countries.map(c => ({ value: c.id, label: c.defaultLocalization.name }));

        this.visaService.ServerGetWithoutLogin()
          .subscribe(x => {
            const visas = x.content;
            const visaOptions = visas.map(c => ({ item_id: c.id, item_text: c.defaultLocalization.name }));
            const relocationAssistanceOptions = Object.keys(RelocationAssistanceType)
              .map(c => ({
                value: c,
                label: this.translate.transform(c)
              }));
            const s = this.locations ? this.locations : null;
            const visaArray = [];
            this.locations.visaRequirement?.visas.forEach(i => {
              visaArray.push({ item_id: i.id, item_text: i.name });
            });

            if (this.locations && this.locations.location.country) {
              this.cityService.getCountryCities(this.locations.location.country.id)
                .subscribe(x => {
                  const cityOptions = x.map(city => ({ value: city.id, label: city.defaultLocalization.name }));

                  this.formLocation = [{
                    class: 'col-md-6 col-12', components: [
                      {
                        label: 'relocationAssistance',
                        control: 'relocationAssistance',
                        options: relocationAssistanceOptions,
                        type: FormType.dropDown,
                        required: true,
                        style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
                        labelStyle: 'font-family: "Roboto"; font-style: normal; font-weight: 400; font-size: 16px; line-height: 150%; color: #23252A;'
                      },
                      {
                        label: 'country',
                        control: 'country',
                        required: true,
                        options: countryOptions,
                        onChange: this.countryChange.bind(this),
                        type: FormType.dropDown,
                        style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
                        labelStyle: 'font-family: "Roboto"; font-style: normal; font-weight: 400; font-size: 16px; line-height: 150%; color: #23252A;'
                      },
                      {
                        label: 'additionalVisaInformation',
                        control: 'additionalVisaInformation',
                        type: FormType.text,
                        required: false,
                        style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
                        labelStyle: 'font-family: "Roboto"; font-style: normal; font-weight: 400; font-size: 16px; line-height: 150%; color: #23252A;'
                      },
                      {
                        label: 'additionalRelocationAssistanceInformation',
                        control: 'additionalRelocationAssistanceInformation',
                        type: FormType.text,
                        required: false,
                        style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
                        labelStyle: 'font-family: "Roboto"; font-style: normal; font-weight: 400; font-size: 16px; line-height: 150%; color: #23252A;'
                      }
                    ]
                  },
                  {
                    class: 'col-md-6 col-12', components: [
                      {
                        label: 'zipCode', control: 'zipCode', required: true, type: FormType.text,
                        style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
                        labelStyle: 'font-family: "Roboto"; font-style: normal; font-weight: 400; font-size: 16px; line-height: 150%; color: #23252A;'
                      },
                      {
                        label: 'city',
                        control: 'city',
                        options: cityOptions,
                        required: true,
                        type: FormType.dropDown,
                        style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
                        labelStyle: 'font-family: "Roboto"; font-style: normal; font-weight: 400; font-size: 16px; line-height: 150%; color: #23252A;'
                      },
                      {
                        label: 'visaRequirement',
                        control: 'visaRequirement',
                        options: visaOptions,
                        type: FormType.multiSelectWithBorder,
                        required: true,
                        labelStyle: 'font-family: "Roboto"; font-style: normal; font-weight: 400; font-size: 16px; line-height: 150%; color: #23252A;'
                      }
                    ]
                  }
                  ];

                  this.formGroupLocation = new FormGroup({
                    relocationAssistance: new FormControl(
                      s ? s.relocationAssistance.type : null,
                      Validators.required),
                    country: new FormControl(
                      s ? s.location.country.id : null,
                      Validators.required),
                    additionalVisaInformation: new FormControl(
                      s ? s.visaRequirement.additional : null),
                    additionalRelocationAssistanceInformation: new FormControl(
                      s ? s.relocationAssistance.additional : ''),
                    zipCode: new FormControl(s ? s.location.zipCode : '',
                      Validators.required),
                    city: new FormControl(s ? s.location.city.id : null,
                      Validators.required),
                    visaRequirement: new FormControl(visaArray,
                      Validators.required)
                  });

                });
            } else {
              this.locations.location.country = new GeneralIdModel();
              this.locations.location.city = new GeneralIdModel();

              const cityOptions = [];

              this.formLocation = [{
                class: 'col-md-6 col-12', components: [
                  {
                    label: 'relocationAssistance',
                    control: 'relocationAssistance',
                    options: relocationAssistanceOptions,
                    type: FormType.dropDown,
                    required: true,
                    style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
                    labelStyle: 'font-family: "Roboto"; font-style: normal; font-weight: 400; font-size: 16px; line-height: 150%; color: #23252A;'
                  },
                  {
                    label: 'country',
                    control: 'country',
                    required: true,
                    options: countryOptions,
                    onChange: this.countryChange.bind(this),
                    type: FormType.dropDown,
                    style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
                    labelStyle: 'font-family: "Roboto"; font-style: normal; font-weight: 400; font-size: 16px; line-height: 150%; color: #23252A;'
                  },
                  {
                    label: 'additionalVisaInformation',
                    control: 'additionalVisaInformation',
                    type: FormType.text,
                    required: false,
                    style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
                    labelStyle: 'font-family: "Roboto"; font-style: normal; font-weight: 400; font-size: 16px; line-height: 150%; color: #23252A;'
                  },
                  {
                    label: 'additionalRelocationAssistanceInformation',
                    control: 'additionalRelocationAssistanceInformation',
                    type: FormType.text,
                    required: false,
                    style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
                    labelStyle: 'font-family: "Roboto"; font-style: normal; font-weight: 400; font-size: 16px; line-height: 150%; color: #23252A;'
                  }
                ]
              },
              {
                class: 'col-md-6 col-12', components: [
                  {
                    label: 'zipCode', control: 'zipCode', required: true, type: FormType.text,
                    style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
                    labelStyle: 'font-family: "Roboto"; font-style: normal; font-weight: 400; font-size: 16px; line-height: 150%; color: #23252A;'
                  },
                  {
                    label: 'city', control: 'city', options: cityOptions, required: true, type: FormType.dropDown,
                    style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
                    labelStyle: 'font-family: "Roboto"; font-style: normal; font-weight: 400; font-size: 16px; line-height: 150%; color: #23252A;'
                  },
                  {
                    label: 'visaRequirement',
                    control: 'visaRequirement',
                    options: visaOptions,
                    type: FormType.multiSelectWithBorder,
                    required: true,
                    labelStyle: 'font-family: "Roboto"; font-style: normal; font-weight: 400; font-size: 16px; line-height: 150%; color: #23252A;'
                  }
                ]
              }
              ];

              this.formGroupLocation = new FormGroup({
                relocationAssistance: new FormControl(
                  s ? s.relocationAssistance.type : null,
                  Validators.required),
                country: new FormControl(s ? s.location.country.id : null,
                  Validators.required),
                additionalVisaInformation: new FormControl(
                  s ? s.visaRequirement.additional : null),
                additionalRelocationAssistanceInformation: new FormControl(
                  s ? s.relocationAssistance.additional : ''),
                zipCode: new FormControl(s ? s.location.zipCode : '',
                  Validators.required),
                city: new FormControl(s ? s.location.city.id : null,
                  Validators.required),
                visaRequirement: new FormControl(visaArray,
                  Validators.required)
              });
            }
          });
      });

  }

  countryChange(data) {
    this.cityService.getCountryCities(data)
      .subscribe(c => {

        const cityOptions = c.map(c => ({ value: c.id, label: c.defaultLocalization.name }));
        this.formLocation[1].components[1].options = cityOptions;
        this.formLoc.city.setValue(null);
      });
  }

  createCompensationForm() {
    const feeTypeOptions = Object.keys(FeeType)
      .map(c => ({ value: c, label: this.translate.transform(c) }));

    const minAmountOptions = Object.keys(MinAmount)
      .map(c => ({ value: c, label: this.translate.transform(c) }));

    const maxAmountOptions = Object.keys(MaxAmount)
      .map(c => ({ value: c, label: this.translate.transform(c) }));

    this.currencyService.ServerGet()
      .subscribe(x => {
        const currencies = x.content;
        const currencyOptions = currencies.map(c => ({ value: c.id, label: c.defaultLocalization.name }));

        const s = this.compensationsAndFees ? this.compensationsAndFees : null;

        this.formCompensation = [
          {
            class: 'col-md-6 col-12', components: [
              {
                label: 'compensationMin',
                control: 'compensationMin',
                options: minAmountOptions,
                type: FormType.dropDown,
                required: true,
                onChange: this.changeMaxAmountOptions.bind(this),
                style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
                labelStyle: 'font-family: "Roboto"; font-style: normal; font-weight: 400; font-size: 16px; line-height: 150%; color: #23252A;'
              },
              {
                label: 'additionalCompensationInformation',
                control: 'additionalCompensationInformation',
                required: false,
                type: FormType.text,
                style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
                labelStyle: 'font-family: "Roboto"; font-style: normal; font-weight: 400; font-size: 16px; line-height: 150%; color: #23252A;'
              },
              {
                label: s && s.fee && s.fee.type == 'FIXED' ? 'feeAmountEuro' : 'feeAmount',
                control: 'amount',
                type: FormType.numericText,
                step: 0.1,
                required: true,
                style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
                labelStyle: 'font-family: "Roboto"; font-style: normal; font-weight: 400; font-size: 16px; line-height: 150%; color: #23252A;'
              }
            ]
          },
          {
            class: 'col-md-6 col-12', components: [
              {
                label: 'compensationMax',
                control: 'compensationMax',
                options: maxAmountOptions,
                required: true,
                type: FormType.dropDown,
                style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
                labelStyle: 'font-family: "Roboto"; font-style: normal; font-weight: 400; font-size: 16px; line-height: 150%; color: #23252A;'
              },
              {
                label: 'feeType',
                control: 'feeType',
                options: feeTypeOptions,
                onChange: this.feeChange.bind(this),
                required: true,
                type: FormType.dropDown,
                style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
                labelStyle: 'font-family: "Roboto"; font-style: normal; font-weight: 400; font-size: 16px; line-height: 150%; color: #23252A;'
              },
              {
                label: 'currency',
                control: 'currency',
                options: currencyOptions,
                type: FormType.dropDown,
                required: true,
                style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
                labelStyle: 'font-family: "Roboto"; font-style: normal; font-weight: 400; font-size: 16px; line-height: 150%; color: #23252A;'
              }
            ]
          }
        ];

        this.formGroupCompensation = new FormGroup({
          compensationMin: new FormControl(
            s ? s.compensation.minAmount : null, Validators.required),
          additionalCompensationInformation: new FormControl(
            s ? s.compensation.additional : ''),
          amount: new FormControl(
            s && s.fee ? s.fee.type == 'FIXED' ? s.fee.amount : s.fee.percent : null,
            Validators.required),
          compensationMax: new FormControl(
            s ? s.compensation.maxAmount : '', Validators.required),
          feeType: new FormControl(s && s.fee ? s.fee.type : '',
            Validators.required),
          currency: new FormControl(
            s && s.compensation && s.compensation.currency ? s.compensation.currency.id : null,
            Validators.required)
        });
      });

  }

  createNotificationForm() {

    const s = this.notificationsAndCorporates ? this.notificationsAndCorporates : null;

    this.formNotification = [{
      class: 'col-md-6 col-12', components: [
        {
          label: 'organizationJobOrderNumber',
          control: 'organizationJobOrderNumber',
          type: FormType.text,
          required: false,
          style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
          labelStyle: 'font-family: "Roboto"; font-style: normal; font-weight: 400; font-size: 16px; line-height: 150%; color: #23252A;'
        },
        {
          label: 'emailNotification', control: 'emailNotification', required: false, type: FormType.switchButton,
          labelStyle: 'margin-left: 7px;'

        },
        {
          label: 'pushNotification', control: 'pushNotification', type: FormType.switchButton, required: false,
          labelStyle: 'margin-left: 7px;'
        }
      ]
    },
    {
      class: 'col-md-6 col-12', components: [
        {
          label: 'differentNotificationEmail',
          control: 'differentNotificationEmail',
          required: false,
          type: FormType.text,
          style: 'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;',
          labelStyle: 'font-family: "Roboto"; font-style: normal; font-weight: 400; font-size: 16px; line-height: 150%; color: #23252A;'
        }
      ]
    }
    ];

    this.formGroupNotification = new FormGroup({
      organizationJobOrderNumber: new FormControl(s ? s.atsJobId : ''),
      emailNotification: new FormControl(s ? s.emailNotification : false),
      pushNotification: new FormControl(s ? s.pushNotification : false),
      differentNotificationEmail: new FormControl(
        s ? s.differentNotificationEmail : '')
    });
  }

  createTeamMemberForm() {
    this.userService.ServerGet()
      .subscribe(x => {
        const teamMembers = x.content.filter(f => f.status == 'ACTIVE');
        const teamMemberOptions = teamMembers.map(c => ({ item_id: c.id, item_text: c.fullName }));

        const userArray = [];
        this.jobTeamMembers?.users?.forEach(i => {
          userArray.push({ item_id: i.id, item_text: i.name });
        });

        this.formTeamMember = [{
          class: 'col-md-12 col-12', components: [
            {
              label: 'teamMemberInCharge',
              control: 'teamMembers',
              options: teamMemberOptions,
              type: FormType.multiSelectWithBorder,
              required: false,
              labelStyle: 'font-family: Roboto; font-style: normal; font-weight: 400; font-size: 14px; line-height: 150%; text-transform: capitalize; color: #707683;'
            }
          ]
        }
        ];

        this.formGroupTeamMember = new FormGroup({
          teamMembers: new FormControl(userArray)
        });

      });

  }

  feeChange(data) {
    this.formCompensation[0].components[2].label = data == 'FIXED' ? 'feeAmountEuro' : 'feeAmount';
  }

  deleteQuestion(index) {
    if (this.questionsArray.length > 1) {
      const array = [...this.questionsArray];
      this.questionsArray = [];
      array.splice(index, 1);
      this.questions.splice(index, 1);
      for (let i = 0; i < array.length; i++) {
        this.questionsArray.push({ id: 'question' + i, index: i });
      }
    }

  }

  addQuestion() {
    if (this.questionsArray && this.questionsArray.length < 10) {
      const array = [...this.questionsArray];
      this.questionsArray = [];
      array.push({ id: 'question' + array.length, index: array.length });
      for (let i = 0; i < array.length; i++) {
        this.questionsArray.push({ id: 'question' + i, index: i });
      }
    }
  }

  saveGeneralInfos() {

    this.generalInfos.id = this.id;
    this.generalInfos.title = this.formGI.title.value;
    this.generalInfos.type = this.formGI.type.value;
    this.generalInfos.positionCount = this.formGI.positionCount.value;
    this.generalInfos.classification.id = this.formGI.classification.value;
    this.generalInfos.function.id = this.formGI.function.value;
    this.generalInfos.industry.id = this.formGI.industry.value;
    this.generalInfos.anonymous = this.formGI.anonymous.value;

    if (this.generalInfos.id == null) {
      this.jobService.postGeneralInfos(this.generalInfos)
        .subscribe(x => {
          this.generalInfos = x as JobGeneralInfosModel;
          this.id = this.generalInfos.id;
          this.createGeneralInfoForm();
          this.doneGeneralInfo = true;
          this.step++;
          this.notification.success(this.translate.transform('newJobSuccessfullyAdded'));
        }, error => {
          this.notification.error(error.error.message);
        });
    } else {
      this.jobService.putGeneralInfos(this.id, this.generalInfos)
        .subscribe(x => {
          this.generalInfos = x as JobGeneralInfosModel;
          this.id = this.generalInfos.id;
          this.createGeneralInfoForm();
          this.doneGeneralInfo = true;
          this.step++;
          this.notification.success(this.translate.transform('jobSuccessfullyUpdated'));
        });
    }
  }

  saveExperience() {

    this.experiences.id = this.id;
    this.experiences.description = this.formEx.jobDescription.value;
    this.experiences.educationLevel = this.formEx.educationLevel.value;
    this.experiences.experienceLevel = this.formEx.experienceLevel.value;
    this.experiences.experienceRequired.year = this.formEx.year.value;
    this.experiences.experienceRequired.month = this.formEx.month.value;
    this.experiences.skills = this.formEx.skills.value.split(',');

    this.jobService.putExperience(this.id, this.experiences)
      .subscribe(x => {
        this.doneExperiences = true;
        this.experiences = x as JobExperiencesModel;
        this.createEcxperienceForm();
        this.step++;
        // this.stepper.next();
        this.notification.success(this.translate.transform('skillsExperienceSuccessfullyUpdated'));
      });
  }

  saveLocations() {

    this.locations.id = this.id;
    this.locations.relocationAssistance.type = this.formLoc.relocationAssistance.value;
    this.locations.relocationAssistance.additional = this.formLoc.additionalRelocationAssistanceInformation.value;
    this.locations.location.country.id = this.formLoc.country.value;
    this.locations.location.city.id = this.formLoc.city.value;
    this.locations.location.zipCode = this.formLoc.zipCode.value;
    this.locations.visaRequirement.additional = this.formLoc.additionalVisaInformation.value;

    if (this.formLoc.visaRequirement.value[0].item_id) {
      this.locations.visaRequirement.visas = this.formLoc.visaRequirement.value.map(x => ({ id: x.item_id }));
    } else {
      this.locations.visaRequirement.visas = this.formLoc.visaRequirement.value.map(x => ({ id: x }));
    }

    this.jobService.putLocations(this.id, this.locations)
      .subscribe(x => {
        this.doneLocations = true;
        this.locations = x as JobLocationsModel;
        this.createLocationForm();
        this.step++;
        // this.stepper.next();
        this.notification.success(this.translate.transform('locationSuccessfullyUpdated'));
      });
  }

  saveQuestions() {

    const postQuestions: JobQuestionModel[] = [];

    const qs = this.questions.filter(x => x);

    qs.forEach(q => {
      const index = qs.indexOf(q);
      postQuestions.push({ content: q, answerType: 'TEXT_FIELD', mandatory: true, screenOrder: index });
    });

    if (postQuestions && postQuestions.length > 0) {
      this.preQuestions.questionnaire ? null : this.preQuestions.questionnaire = new JobQuestionnaireModel();
      this.preQuestions.questionnaire.questions = postQuestions;
    } else {
      this.preQuestions.questionnaire = null;
    }

    if (this.preQuestions.questionnaire == null) {
      this.donePreQuestions = true;
      this.step++;
      // this.stepper.next();
      this.notification.warning(this.translate.transform('noQuestionsAdded'));
    } else {
      this.preQuestions.id = this.id;
      this.jobService.putPreQuestions(this.id, this.preQuestions)
        .subscribe(x => {
          this.donePreQuestions = true;

          this.step++;
          // this.stepper.next();
          this.notification.success(this.translate.transform('questionsSuccessfullyUpdated'));
        });
    }
  }

  saveCompensationAndFees() {

    this.compensationsAndFees.id = this.id;
    this.compensationsAndFees.compensation.minAmount = this.formCom.compensationMin.value;
    this.compensationsAndFees.compensation.maxAmount = this.formCom.compensationMax.value;
    this.compensationsAndFees.compensation.additional = this.formCom.additionalCompensationInformation.value;
    if (this.compensationsAndFees.fee == null) {
      this.compensationsAndFees.fee = new JobFeeModel();
    }
    this.compensationsAndFees.fee.type = this.formCom.feeType.value;
    this.compensationsAndFees.fee.amount = this.formCom.amount.value;

    this.compensationsAndFees.fee.currency = ({
      id: this.formCom.currency.value
    } as GeneralIdModel);

    this.compensationsAndFees.compensation.currency = ({
      id: this.formCom.currency.value
    } as GeneralIdModel);

    if (this.compensationsAndFees.fee.type == 'PERCENT') {
      this.compensationsAndFees.fee.percent = this.compensationsAndFees.fee.amount.toString();
    }

    this.jobService.putCompensationsAndFees(this.id, this.compensationsAndFees)
      .subscribe(x => {
        this.doneCompensationsAndFees = true;
        this.compensationsAndFees = x as JobCompensationsAndFeesModel;
        this.createCompensationForm();
        this.step++;
        // this.stepper.next();
        this.notification.success(this.translate.transform('compensationsAndFeesSuccessfullyUpdated'));

      });

  }

  saveNotification() {

    this.notificationsAndCorporates.id = this.id;
    this.notificationsAndCorporates.atsJobId = this.formNot.organizationJobOrderNumber.value;
    this.notificationsAndCorporates.emailNotification = this.formNot.emailNotification.value;
    this.notificationsAndCorporates.pushNotification = this.formNot.pushNotification.value;
    this.notificationsAndCorporates.differentNotificationEmail = this.formNot.differentNotificationEmail.value;

    const phoneNumber = {
      countryCallingCode: '+90',
      phoneNumber: '12345678'
    } as PhoneNumberModel;

    this.notificationsAndCorporates.authorizedPerson = ({
      firstName: 'Melanie',
      lastName: 'Musterfrau',
      email: 'melanie.musterfrau@flynx.com',
      phoneNumber
    } as JobAuthorizedPersonModel);

    this.jobService.putNotificationsAndCorporates(this.id, this.notificationsAndCorporates)
      .subscribe(x => {
        this.doneNotificationsAndCorporates = true;
        this.notificationsAndCorporates = x as JobNotificationsAndCorporatesModel;
        this.createNotificationForm();

        this.notification.success(this.translate.transform('notificationSuccessfullyUpdated'));

        // @ts-ignore
        $('#teamMemberModal')
          .modal('show');

      });

  }

  saveTeamMembers(publish: boolean) {

    if (this.formTM.teamMembers.value[0].item_id) {
      this.jobTeamMembers.users = this.formTM.teamMembers.value.map(x => ({ id: x.item_id }));
    } else {
      this.jobTeamMembers.users = this.formTM.teamMembers.value.map(x => ({ id: x }));
    }

    this.jobTeamMembers.publish = publish;
    this.jobTeamMembers.id = this.id;

    this.jobService.putTeamMembers(this.id, this.jobTeamMembers)
      .subscribe(x => {
        this.doneTeamMembers = true;
        this.jobTeamMembers = x as JobTeamMembersModel;
        this.createTeamMemberForm();
        this.notification.success(this.translate.transform('teamMembersSuccessfullyUpdated'));

        if (publish) {
          this.jobService.ServerPut(server.jobs + '/' + this.id + '/status', { status: 'ACTIVE' })
            .subscribe(x => {
              this.jobTeamMembers = x as JobTeamMembersModel;
              this.createTeamMemberForm();
              this.notification.success(this.translate.transform('newJobProfileCreated'));
              this.publishedJob.emit();

              this.jobAccessibilityOptions = Object.keys(JobAccessibility)
                .map(c => ({
                  value: c,
                  label: this.translate.transform(c)
                }));

              // @ts-ignore
              $('#jobAccessibilityModal')
                .modal('show');
            });
        }
      });

  }

  changeJobAccessibility(data) {
    switch (data.value) {
      case 'PUBLIC':
        if (this.permissionService.functionalRolesOnly(['PUBLIC_JOB_POSTING'])) {
          this.jobAccessibilityLabel = data.label;
          this.jobAccessibility = data.value;
          this.jobAccessibilityConfirmButtonDisabled = false;
        } else {
          this.jobAccessibility = 'pricing';
          this.jobAccessibilityLabel = data.label;
          this.jobAccessibilityConfirmButtonDisabled = true;
        }
        break;
      case 'INVITATION_ONLY':
        if (this.permissionService.functionalRolesOnly(['INVITATION_JOB_POSTING'])) {
          this.jobAccessibilityLabel = data.label;
          this.jobAccessibility = data.value;
          this.jobAccessibilityConfirmButtonDisabled = false;
        } else {
          this.jobAccessibility = 'pricing';
          this.jobAccessibilityLabel = data.label;
          this.jobAccessibilityConfirmButtonDisabled = true;
        }
        break;
      case 'RULE_BASED':
        if (this.permissionService.functionalRolesOnly(['RESTRICTED_JOB_POSTING'])) {
          this.jobAccessibilityLabel = data.label;
          this.jobAccessibility = data.value;
          this.jobAccessibilityConfirmButtonDisabled = false;
        } else {
          this.jobAccessibility = 'pricing';
          this.jobAccessibilityLabel = data.label;
          this.jobAccessibilityConfirmButtonDisabled = true;
        }
        break;
    }

  }

  deleteMail(mail) {
    const index: number = this.recruiterMailArray.indexOf(mail);
    if (index !== -1) {
      this.recruiterMailArray.splice(index, 1);
    }
  }

  // addMail(){
  //   let model = {
  //     email: this.recruiterMail
  //   };

  //   this.userService.ServerPostWithText("/recruiter-user", model).subscribe(x=>{
  //   this.recruiterMailArray.push(x);
  //   this.recruiterMail = "";

  //   },
  //   error => {
  //     this.notification.error(this.translate.transform('emailNotFound'));
  //     this.recruiterMail = "";
  //   } );

  // }

  changeRule(data) {
    this.ruleLabel = data.label;
    this.rule = data.value;
  }

  confirm() {
    switch (this.jobAccessibility) {
      case 'PUBLIC':
        this.notification.success(this.translate.transform('jobAccessibilitySuccessfullySaved'));
        this.router.navigate(['/dashboard/my-jobs']);
        break;
      case 'INVITATION_ONLY':

        const recruiters = [];

        /*this.recruiterMailArray.forEach(x => {
          recruiters.push({ recruiterId: x.id, recruiterEmail: x.user.email });
        });*/
        this.selectedRecruitersForInvitation.forEach(recruiter => {
          recruiters.push({ recruiterId: recruiter.id, recruiterEmail: recruiter.user.email });
        });

        const model: JobInvitationModel = {
          id: this.id,
          recruiters
        };

        this.jobService.putInvitation(this.id, model)
          .subscribe(x => {
            this.notification.success(this.translate.transform('jobAccessibilitySuccessfullySaved'));
            this.router.navigate(['/dashboard/my-jobs']);
          });

        break;
      case 'RULE_BASED':

        const modelRes: JobRestrictionModel = {
          job: {
            id: this.id
          },
          leastPlacement: this.placement,
          leastRating: this.star
        };

        this.jobService.putRestriction(this.id, modelRes)
          .subscribe(x => {
            this.notification.success(this.translate.transform('jobAccessibilitySuccessfullySaved'));
            this.router.navigate(['/dashboard/my-jobs']);
          });

        break;
    }
  }

  next() {
    this.step++;
    // this.stepper.next();
  }

  pre() {
    this.step--;
    // this.stepper.previous();
  }

  getRecruiters(data) {
    this.recruitersOptions = [];
    if (data.length > 2) {

      this.recruiterService.ServerGetWithText('?/filter=status!:DELETED&filter=name~~' + data)
        .subscribe(x => {

          this.recruitersOptions = x.recruiterPagination.content.map(c => ({ value: c, label: c.user?.fullName }));
        });
      // @ts-ignore
      $('.dropdown-toggles')
        .dropdown('show');
    } else {
      this.recruitersOptions = [];
      // @ts-ignore
      $('.dropdown-toggles')
        .dropdown('hide');
    }

  }

  selectRecruiter(data, item) {
    this.recruiterMailArray.push(item.value);
    this.recText = '';
    this.getRecruiters('');
  }

  showRecruiterList(): void {
    $('#recruiterListModal')
      .modal('toggle');
  }

  selectOfInvitationDone(recruiterList: AllRecruiterInfoModel[]): void {
    this.selectedRecruitersForInvitation = recruiterList;
  }

  private changeMaxAmountOptions(data: any) {

    const maxAmountOptions = Object.keys(MaxAmount)
      .map(c => ({ value: c, label: this.translate.transform(c) }));
    const valueArray = data.split('_');
    let valueText = '';

    for (let i = 0; i < valueArray.length - 1; i++) {

      if (valueArray.length > 2 && i > 0) {
        valueText = valueText + '_';
      }

      valueText = valueText + valueArray[i];
    }
    valueText = valueText === 'TWO_HUNDRED_AND_TWENTY' ? 'TWO_HUNDRED_AND_TWENTY_FIVE' : valueText;
    valueText = valueText === 'TWO_HUNDRED_AND_FIFTY' ? 'TWO_HUNDRED_AND_TWENTY_FIVE' : valueText;

    let deleteId = -2;
    maxAmountOptions.forEach((x, index) => {
      if (valueText == x.value) {
        deleteId = index;
      }
    });

    maxAmountOptions.splice(0, deleteId + 1);
    this.formCompensation.forEach(row => {
      row.components.forEach(col => {
        if (col.control == 'compensationMax') {
          col.options = maxAmountOptions;
        }
      });
    });
    this.changeDetector.detectChanges();

  }

  removeFromInvitedRecruiters(recruiter: AllRecruiterInfoModel) {
    recruiter.isChecked = false;
    const index = this.selectedRecruitersForInvitation.indexOf(recruiter);
    this.selectedRecruitersForInvitation.splice(index, 1);
  }
}
