import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApplicationModel} from '../../../shared/models/applications/application.model';
import {map} from 'rxjs/operators';
import {ApplicationService, EmployerService, JobService, RecruiterService} from '../../../shared/services';
import {NoteModel, NotePostModel} from '../../../shared/models/applications/note.model';
import {RequestPayload} from '../../../shared/classes';

declare var $: any;

@Injectable({
    providedIn: 'root'
})
export class ApplicationDetailService {

    public addNoteState = false;
    public notePostModel: NotePostModel = new NotePostModel();
    public $notes!: Observable<NoteModel[]>;

    public applicationId!: string;

    public currentPage = 1;
    public count!: number;
    public requestPayload: RequestPayload;

    public salary: number;

    constructor(
        private applicationService: ApplicationService,
        private recruiterService: RecruiterService,
        private jobService: JobService,
        private employerService: EmployerService
    ) {
        this.requestPayload = new RequestPayload();
        this.requestPayload.limit = 25;
    }

    getApplication(): Observable<ApplicationModel> {
        return this.applicationService.ServerGetWithText('/' + this.applicationId)
            .pipe(
                map(application => {
                    this.notePostModel.application.id = this.applicationId;
                    this.getNotes();
                    application.$recruiter = this.recruiterService.ServerGetWithText(
                        '/' + application.recruiterId);
                    application.$job = this.jobService.ServerGetWithText(
                        '/' + application.job.id)
                        .pipe(map(job => {
                            this.employerService.ServerGetWithText(
                                '/' + application.employer.id + '/company-logo')
                                .subscribe((res: any) => {
                                    job.logoUrl = res.url;
                                });
                            return job;
                        }));
                    return application;
                })
            );
    }

    addNote(): void {
        if (this.notePostModel.id === '') {
            this.applicationService.postNote(this.notePostModel)
                .subscribe(() => {
                    this.getNotes();
                });
            this.addNoteState = false;
        } else {
            this.applicationService.putNote(this.notePostModel.id, this.notePostModel)
                .subscribe(() => {
                    this.getNotes();
                });
            this.addNoteState = false;
        }
        this.clearNotePostModel();
    }

    clearNotePostModel(): void {
        this.notePostModel = new NotePostModel();
    }

    cancelAddNote(): void {
        this.addNoteState = false;
        this.clearNotePostModel();
    }

    showAddNoteBox(): void {
        this.notePostModel = new NoteModel();
        this.addNoteState = !this.addNoteState;
        this.notePostModel.application.id = this.applicationId;
    }

    getNotes(): void {
        const filterText = this.requestPayload.payloadURLWithoutQuestionMark(this.currentPage);
        this.$notes = this.applicationService.getNotes(this.applicationId + filterText)
            .pipe(map(response => {
                this.count = response.totalElements;
                return response.content;
            }));
    }

    hireApp(): void {

        if (!this.salary) {
            return;
        }
        const model = {
            agreedSalaryAmount: this.salary
        };

        this.applicationService.HireApplication(this.applicationId, model)
            .subscribe(x => {
                $('#agreed-salary-amount-modal')
                    .modal('toggle');
                this.getApplication();

            });
    }

    deleteNote(note: NoteModel): void {
        this.applicationService.deleteNote(note.id)
            .subscribe(() => {
                if (this.notePostModel.id === note.id) {
                    this.notePostModel = new NoteModel();
                }
                this.getNotes();
            });
    }

    editNote(note: NoteModel): void {
        this.notePostModel = note;
        this.addNoteState = true;
    }

    saveApplication(id: string): Observable<any> {
        return this.applicationService.saveApplication(id);
    }

    unSaveApplication(id: string): Observable<any> {
        return this.applicationService.unSaveApplication(id);
    }
}
