<div *ngIf="filterData" class="row">
  <div class="col-12 pr-1 pl-0">
    <div class="card border-0" style="background-color: #F5F6F8;">
      <div class="card-body">
        <div class="card-text row d-flex d-lg-none text-center">
          <div class="col-12  header">
            {{ 'trackApplications' | translate }}
          </div>

          <div class="col-12 my-2 my-lg-0">
            <form class="example d-flex inline-block" style="margin:auto;">
              <input [(ngModel)]="filterText" #searchInput (keyup)="keyup(searchInput.value)"
              type="text" name="search2">
              <button (click)="search()"><i class="fa fa-search"></i></button>
            </form>
            <label *ngIf="filterText != ''" class="clear-search mt-2" for="" (click)="clearSearch()"><i class="fas fa-times mr-1"
              style="color: #707683;" ></i> {{'clearSearch' | translate}}  </label>
          </div>

            <div class="dropdown drp col-12  my-2 my-lg-0" id="filer-dropdown">
              <div class="mt-2 ml-xl-5 ml-md-5 ml-0">
                <span (click)="filterState = !filterState" class="ml-3 cursor-pointer">
                  <img src="./../../../../assets/images/talentbase/filter-button.png">  {{ 'filters' | translate }}</span>
              </div>


              <div *ngIf="filterState"
               class="dropdown-menu col-12 pb-0">


                <div class="dropdown-item color-dropdown-item" id="city">

                  <tlb-multiselect-dropdown (selectResult)="selectStatus($event)" [isFilter]="false" [items]="applicationStatusDropDown"
                    [dropdownName]="statusLabel != '' ? statusLabel : 'status'" [defaultItemKey]="''" [existItems]="selectedStatus" [dropDownMenuWidth]="'15rem'"
                    [style]="'text-align: left; border: 1px solid #DCDCDC; box-sizing: border-box; box-shadow: 0px 2px 4px rgb(0 0 0 / 10%); border-radius: 7px; height: 40px;'"
                    [textStyle]="'font-family: Roboto; font-style: normal; font-weight: 500; font-size: 15px; line-height: 16px; letter-spacing: 0.1px; color: #252B42 !important;'">
                  </tlb-multiselect-dropdown>
                </div>

                <div class="dropdown-item color-dropdown-item" id="type">

                  <tlb-multiselect-dropdown (selectResult)="selectCandidate($event)" [isFilter]="false" [items]="candidatesDropDown"
                  [dropdownName]="candidatesLabel != '' ? candidatesLabel : 'candidate'" [defaultItemKey]="''"  [existItems]="selectedCandidates"
                  [style]="'text-align: left; border: 1px solid #DCDCDC; box-sizing: border-box; box-shadow: 0px 2px 4px rgb(0 0 0 / 10%); border-radius: 7px; height: 40px;'"
                  [textStyle]="'font-family: Roboto; font-style: normal; font-weight: 500; font-size: 15px; line-height: 16px; letter-spacing: 0.1px; color: #252B42 !important;'">
                  </tlb-multiselect-dropdown>
                </div>

                <div class="dropdown-item color-dropdown-item" id="type">

                  <tlb-multiselect-dropdown (selectResult)="selectEmployer($event)" [isFilter]="false" [items]="employersDropDown"
                  [dropdownName]="employersLabel != '' ? employersLabel : 'employer'"  [defaultItemKey]="''" [existItems]="selectedEmployers"
                  [style]="'text-align: left; border: 1px solid #DCDCDC; box-sizing: border-box; box-shadow: 0px 2px 4px rgb(0 0 0 / 10%); border-radius: 7px; height: 40px;'"
                  [textStyle]="'font-family: Roboto; font-style: normal; font-weight: 500; font-size: 15px; line-height: 16px; letter-spacing: 0.1px; color: #252B42 !important;'">
                  </tlb-multiselect-dropdown>
                </div>

                <div class="row mr-2" style="justify-content: end;">

                  <div class="mt-2 mb-2 mr-1">
                    <button (click)="clearFilterEmit()" class="btn clear-filter-btn" type="button">{{'discard' |
                      translate}}</button>
                  </div>
                  <div class="mt-2">
                    <button (click)="emitData()" class="btn apply-filter-btn" type="button">{{'apply' |
                      translate}}</button>
                  </div>


                </div>

              </div>
            </div>


            <div class="col-12 my-2 my-lg-0" >
              <tlb-dropdown [items]="sortOptions" (clickedButton)="clickedSort()"
              [dropdownName]="sortLabel != '' ? sortLabel : 'selectSort'"
              (searchData)="selectSort($event)" [isFilter]="false">
            </tlb-dropdown>
            </div>

        </div>
        <div class="card-text row d-none d-lg-flex">
          <div class="d-inline-block mx-3 header">
            {{ 'trackApplications' | translate }}
          </div>

          <div class="d-inline-block mx-3 ml-auto">
            <form class="example d-flex inline-block" style="margin:auto;">
              <input [(ngModel)]="filterText" #searchInput (keyup)="keyup(searchInput.value)"
              type="text" name="search2" class="search-input">
              <button (click)="search()" style="border: 1px solid #CED4DA !important;" type="submit"><i class="fa fa-search fa-search-position"></i></button>
            </form>
          </div>

          <label *ngIf="filterText != ''" class="clear-search mt-3" for="" (click)="clearSearch()"><i class="fas fa-times mr-1"
            style="color: #707683;" ></i> {{'clearSearch' | translate}}  </label>
          <div class="d-inline-block mx-3" (clickOutside)="closeFilterPopup($event)">
            <div class="dropdown drp h-100 d-flex" id="filer-dropdown">
                <span (click)="filterState = !filterState" class="align-self-center cursor-pointer">
                  <img src="./../../../../assets/images/talentbase/filter-button.png">  {{ 'filters' | translate }}</span>



              <div  [style.display]="filterState ? 'block':'none'"
                   aria-labelledby="dropdownMenuButton"   id="filter-dropdown-menu" class="dropdown-menu col-12 pb-0">
<div class="drp-menu">
  <div class="dropdown-item color-dropdown-item" id="city">

    <tlb-multiselect-dropdown (selectResult)="selectStatus($event)" [isFilter]="false" [items]="applicationStatusDropDown"
                              [dropdownName]="statusLabel != '' ? statusLabel : 'status'" [defaultItemKey]="''" [existItems]="selectedStatus" [dropDownMenuWidth]="'15rem'"
                              [style]="'text-align: left; border: 1px solid #DCDCDC; box-sizing: border-box; box-shadow: 0px 2px 4px rgb(0 0 0 / 10%); border-radius: 7px; height: 40px;'"
                              [textStyle]="'font-family: Roboto; font-style: normal; font-weight: 500; font-size: 15px; line-height: 16px; letter-spacing: 0.1px; color: #252B42 !important;'">
    </tlb-multiselect-dropdown>
  </div>

  <div class="dropdown-item color-dropdown-item" id="type">

    <tlb-multiselect-dropdown (selectResult)="selectCandidate($event)" [isFilter]="false" [items]="candidatesDropDown"
                              [dropdownName]="candidatesLabel != '' ? candidatesLabel : 'candidate'" [defaultItemKey]="''"  [existItems]="selectedCandidates"
                              [style]="'text-align: left; border: 1px solid #DCDCDC; box-sizing: border-box; box-shadow: 0px 2px 4px rgb(0 0 0 / 10%); border-radius: 7px; height: 40px;'"
                              [textStyle]="'font-family: Roboto; font-style: normal; font-weight: 500; font-size: 15px; line-height: 16px; letter-spacing: 0.1px; color: #252B42 !important;'">
    </tlb-multiselect-dropdown>
  </div>

  <div class="dropdown-item color-dropdown-item" id="type">

    <tlb-multiselect-dropdown (selectResult)="selectEmployer($event)" [isFilter]="false" [items]="employersDropDown"
                              [dropdownName]="employersLabel != '' ? employersLabel : 'employer'"  [defaultItemKey]="''" [existItems]="selectedEmployers"
                              [style]="'text-align: left; border: 1px solid #DCDCDC; box-sizing: border-box; box-shadow: 0px 2px 4px rgb(0 0 0 / 10%); border-radius: 7px; height: 40px;'"
                              [textStyle]="'font-family: Roboto; font-style: normal; font-weight: 500; font-size: 15px; line-height: 16px; letter-spacing: 0.1px; color: #252B42 !important;'">
    </tlb-multiselect-dropdown>
  </div>
</div>



                <div class="row mr-2" style="justify-content: end;">

                  <div class="mt-2 mb-2 mr-1">
                    <button (click)="clearFilterEmit()" class="btn clear-filter-btn" type="button">{{'discard' |
                      translate}}</button>
                  </div>
                  <div class="mt-2">
                    <button (click)="emitData()" class="btn apply-filter-btn" type="button">{{'apply' |
                      translate}}</button>
                  </div>


                </div>

              </div>
            </div>
          </div>
          <div style="display: grid;align-content: end; position: relative; bottom:5px" class="mx-3 mr-md-5" >
            <tlb-dropdown [items]="sortOptions" (clickedButton)="clickedSort()"
                          [dropdownName]="sortLabel != '' ? sortLabel : 'selectSort'"
                          (searchData)="selectSort($event)" [isFilter]="false">
            </tlb-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
