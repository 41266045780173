export class TlbFormComponentModel {

    label: string;
    disable?: boolean;
    min?: number;
    type?: string;
    class?: string;
    name?: string;
    control?: string;
    control2?: string;
    index?: number;
    for?: string;
    list?: string;
    options?: any[];
    required?: boolean;
    style?: string;
    labelStyle?: string;
    value?: any;
    hide?: boolean;
    placeHolder?: string;
    imageUrl?: any;
    dropDownFilter?: boolean;
    maxLength?: number;
    step?: number;
    placeHolderStyle?: string;
    labelIcon?: string;
    msSettings?: any;
    limit?: number;
    showPatternDetailsOnTheBottom?: boolean;
    patterns?: { errorText: string, pattern: string }[];

    constructor() {
        this.label = '';
        this.disable = false;
        this.type = '';
        this.class = '';
        this.name = '';
        this.control = '';
        this.control2 = '';
        this.index = 0;
        this.for = '';
        ;
        this.list = '';
        this.options = [];
        this.required = false;
        this.style = '';
        this.labelStyle = '';
        this.value = null;
        this.hide = false;
        this.placeHolder = '';
        this.imageUrl = '';
        this.dropDownFilter = false;
        this.maxLength = 100;
        this.step = 1;
        this.placeHolderStyle = '';
        this.labelIcon = null;
        this.msSettings = null;
        this.limit = 0;
        this.showPatternDetailsOnTheBottom = false;
        this.patterns = [];
        this.min = null;
    }

    onChange?(): Event;
}
