<div class="dropdown drp">
  <button (click)="clickDropDown()" aria-expanded="false" aria-haspopup="true" class="btn dropdown-toggle col-12" data-toggle="dropdown" [style]="style"
  id="dropdownMenuButton"  type="button"  style="border: 1px solid #ced4da; text-align: left;">
  <span style="color: hsl(204, 7%, 42%); max-width: 95%;"  [style]="textStyle" class="text-truncate d-inline-block">{{dropdownName | translate}}</span>
  <i class="fas fa-chevron-down mt-1" style="float: right;"></i>
  </button>
  <div class="dropdown-menu col-12 pb-0" id="dropdown-menu" [style]="'min-width:' + dropDownMenuWidth">
    <label *ngIf="alertText != ''" class="col-12 alert-text">
      {{alertText}}
  </label>
      <label *ngIf="isFilter" class="col-12">
          <input #searchInput (keyup)="search(searchInput.value)"  autocomplete="off" autofocus
                 class="form-control border-radius-0" placeholder="{{'search' | translate}}">
      </label>
      <div (click)="stopClose($event)" class="col-12 drp-menu scrollable-menu p-0" aria-labelledby="dropdownMenuButton">
          <label *ngFor="let item of items" class="dropdown-item m-0 cursor-pointer">
              <input (click)="selectData($event,item)"
                     (select)="selectData($event,item)"
                     [checked]="defaultItemKey != '' ? existItemArray && existItemArray.indexOf(item.id) > -1 : existItemArray && existItemArray.indexOf(item.value) > -1"
                     type="checkbox">
              <span *ngIf="defaultItemKey != ''" class="ml-2">{{(item.defaultLocalization ? item.defaultLocalization.name : item.fullName) | translate}}</span>
              <span *ngIf="defaultItemKey == ''" class="ml-2">{{item.label | translate}}</span>
          </label>
      </div>
      <!-- <button (click)="emitData()"
              class="col-12 border-radius-0 btn btn-secondary mt-1">{{'apply' | translate}}</button> -->
  </div>
</div>
