import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ApiService} from '../auth';
import {server} from '../server';
import {TranslatePipe} from 'src/app/shared/pipes/translate/translate.pipe';
import {NotificationService} from 'src/app/shared/services/notification/notification.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Observable} from 'rxjs';
import {catchError, finalize, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class RecruiterService extends ApiService {

  constructor(public http: HttpClient, public spinner: NgxSpinnerService, public translate: TranslatePipe, public notification: NotificationService) {
    super(http, server.recruiter, spinner, translate, notification);
  }


  favoriteRecruiter(id: string): Observable<any> {
    this.spinner.show();
    const headers = this.setHeadersWithToken();
    const options = {headers};
    return this.http.post(server.recruiter + '/' + id + '/favorite', null, options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        }));
  }
  unFavoriteRecruiter(id: string): Observable<any> {
    this.spinner.show();
    const headers = this.setHeadersWithToken();
    const options = {headers};
    return this.http.post(server.recruiter + '/' + id + '/unfavorite', null, options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return this.ServerError(err);
        }),
        finalize(() => {
          this.spinner.hide();
        }));
  }


}
