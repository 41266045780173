import { JobType } from 'src/app/shared/enums';
import { ApplicationCountService, EmployerService, JobService } from 'src/app/shared/services';
import { JobFilterDataModel, JobModel } from 'src/app/shared/models';
import { Component, OnInit } from '@angular/core';
import { RequestPayload } from 'src/app/shared/classes';

@Component({
  selector: 'app-search-jobs',
  templateUrl: './search-jobs.component.html',
  styleUrls: ['./search-jobs.component.scss']
})
export class SearchJobsComponent implements OnInit {

  jobs: JobModel[] = [];
  allJobs: JobModel[] = [];
  count: number;
  requestPayload: RequestPayload;
  filterData: JobFilterDataModel;
  storedItems: any[] = [];
  searchText: string = "";
  sortText: string = "&sort=createdAt,DESC";
  filtered = false;
  currentPage = 1;
  sortedControl: boolean = false;
  gridArray: boolean = false;

  constructor(private jobService: JobService, private employerService: EmployerService, private applicationCountService: ApplicationCountService) {
    this.requestPayload = new RequestPayload();
    this.filterData = new JobFilterDataModel();
    this.requestPayload.limit = 3;
  }

  ngOnInit(): void {
    this.getJobs(1);
  }

  sorted(sort) {
    this.sortedControl = true;
    this.sortText = sort;
    this.getJobs(1);
  }

  getJobs(pageNo) {
    this.jobs = [];
    this.filtered = !this.filteredControl();
    this.jobService.ServerGetWithText(this.requestPayload.payloadURL(pageNo) + this.sortText + this.requestFilter() + '&filter=status::ACTIVE').subscribe(x => {

      if(this.filteredControl() && !this.sortedControl && pageNo == 1){
        x.jobs.forEach(element => {
          element.recommended = true;
          this.jobs.push(element);
        });

        x.jobPagination.content.forEach(element => {
          this.jobs.push(element);
        });
      }

      else this.jobs = x.jobPagination.content;

      // this.jobs = x.jobPagination.content;
      this.storedItems = this.jobs;
      this.searchText != "" ? this.search(this.searchText) : "";
      this.count = x.jobPagination.totalElements;
      this.jobs.forEach(x => {
        let time = Math.abs(new Date().getTime() - new Date(x.createdAt).getTime());
        x.postDate = Math.ceil(time / (1000 * 3600 * 24));
        this.employerService.ServerGetWithText("/" + x.employer.id + "/company-logo").subscribe(a => {
          x.logoUrl = a.url;

        });
        this.employerService.ServerGetWithText("/" + x.employer.id).subscribe(employerDetails => {
          x.aboutCompany = employerDetails.about;
        });
        this.applicationCountService.ServerGetWithText("/?jobId=" + x.id).subscribe(a => {
          x.managerReview = a.managerReview;
          x.offerStage = a.offerStage;
          x.pendingReview = a.pendingReview;
          x.rejected = a.rejectedByEmployer;
          x.clientReview = a.clientReview;
          x.interviewStage = a.interviewStage;

          x.openPosition = x.clientReview + x.managerReview + x.interviewStage + x.offerStage

        });
      });
    });

  }

  updateCurrentPage(data){
    this.currentPage = data;
  }

  filteredControl(){
    return this.filterData.country == "" && this.filterData.city == "" && this.filterData.jobTitle == "" && this.filterData.jobType == "" &&
    this.filterData.jobFunction == "" && this.filterData.industry == "" && this.filterData.freeText == ""
  }


  requestFilter() {
    return '&filter=countryId**' + this.filterData.country +
      '&filter=cityId**' + this.filterData.city +
      '&filter=title~~' + this.filterData.jobTitle +
      '&filter=freeText~~' + this.filterData.freeText +
      '&filter=type**' + this.filterData.jobType +
      '&filter=functionId**' + this.filterData.jobFunction +
      '&filter=industryId**' + this.filterData.industry
  }


  getFilteredJobs(e) {
    this.filtered = true;
    this.filterData = e;
    this.getJobs(1);

  }

  public get jobType(): typeof JobType {
    return JobType;
  }

  updateGridOrList(data){
    this.gridArray = data;
  }

  getClearFilterJobs(e) {
    this.filtered = false;
    this.filterData = e;
    this.getJobs(1);

  }

  search(data) {

    if (data == "") this.getJobs(1);

    this.searchText = data;
    // tslint:disable-next-line:max-line-length
    this.jobs = ((this.storedItems).filter((item: JobModel) =>
      (((item.employer.name).toLowerCase().trim()).indexOf(data.toLowerCase().trim()) > -1) ||
      (((item.title).toLowerCase().trim()).indexOf(data.toLowerCase().trim()) > -1)
    ));


  }


}
