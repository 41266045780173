<div class="px-xl-5 pb-5">
  <div class="container-fluid mt-4">
    <span class="home-text">{{ 'candidates' | translate }}</span> <span class="user-text">
      <i class="fas fa-chevron-right ml-3 mr-2"></i> {{ 'trackApplications' | translate }} </span>
    <div class="row">
      <div class="col-12">
        <app-track-applications-filter (resultSort)="getSortedApps($event)" (resultFilter)="getFilteredApps($event)"
          (clearFilter)="clearFilter($event)" (resultSearch)="search($event)"></app-track-applications-filter>
      </div>
    </div>


    <div class="row overflow-auto mt-1">
      <div class="col-12 pl-5 pl-md-4 pl-xl-4">
        <table class="table" style="background-color: #fff;min-width: 900px">
          <thead>
            <tr>
              <th scope="col"><span class="table-headers">{{ 'image' | translate }} </span> </th>
              <th scope="col"> <span class="table-headers">{{ 'fullName' | translate }} </span></th>
              <th scope="col"> <span class="table-headers">{{ 'title' | translate }} </span></th>
              <th scope="col"> <span class="table-headers">{{ 'phoneNumber' | translate }} </span></th>
              <th scope="col"> <span class="table-headers">{{ 'employer' | translate }} </span></th>
              <th scope="col"> <span class="table-headers">{{ 'appliedOn' | translate }} </span></th>
              <th scope="col"> <span class="table-headers">{{ 'status' | translate }} </span></th>
              <th scope="col"> <span class="table-headers">{{ 'action' | translate }} </span></th>
            </tr>
          </thead>
          <tbody
            *ngFor="let app of applications | paginate:{ itemsPerPage: requestPayload.limit, currentPage: currentPage,totalItems:count}">
            <tr>
              <td>
                <div [style.background-color]="app.imageColor" id="preimage"><span
                    style="color: white;">{{app.imageText}}</span></div>
              </td>
              <td> <span class="name-text"> {{app.candidate.fullName}}</span>

                <span class="email-style">{{app.candidate.email}}</span>
              </td>

              <td> <span class="title-text"> {{app.job.name}}</span>


              </td>

              <td class="phone-text"> {{app.candidate.phoneNumber.countryCallingCode}}
                {{app.candidate.phoneNumber.phoneNumber}}</td>
              <td><b>{{app.employer.name}}</b></td>
              <td>{{app.date}}</td>
              <td *ngIf="app.candidate.status=='ACTIVE'">
                <div class="row">
                  <div class="active-button" data-toggle="tooltip" data-placement="top"
                    title="{{app.candidate.status | translate}}">{{app.candidate.status[0]}}</div>
                  <div class="active-button ml-2" data-toggle="tooltip" data-placement="top"
                    title="{{app.status | translate}}">{{app.status + 'Short' | translate}}</div>
                </div>


              </td>
              <td *ngIf="app.candidate.status!='ACTIVE'">
                <div class="row">
                  <div class="deactive-button" data-toggle="tooltip" data-placement="top"
                    title="{{app.candidate.status | translate}}">{{app.candidate.status[0]}}</div>
                  <div class="deactive-button ml-2" data-toggle="tooltip" data-placement="top"
                    title="{{app.status | translate}}">{{app.status + 'Short' | translate}}</div>
                </div>



                <!-- <div class="deactive-button">{{app.candidate.status}}</div>  <br>
                <div class="deactive-button mt-1" data-toggle="tooltip"
                data-placement="top" title="{{app.status | translate}}">{{app.status + 'Short' | translate}}</div> -->
              </td>
              <td>
                <span class="mr-1">
                  <span class="border-0" (click)="openSubmitApplicationModal(app)">
                    <img class="cursor-pointer" src="./../../../../assets/images/talentbase/view-button.svg">
                  </span>
                </span>
                <span class="border-0" (click)="openCancelApplicationModal(app)" *ngIf="
                  app.status=='ACTIVE'||
                  app.status=='SUBMITTED'||
                  app.status=='APPROVED'||
                  app.status=='CLIENT_REVIEW'||
                  app.status=='MANAGER_REVIEW'||
                  app.status=='INTERVIEW_STAGE'||
                  app.status=='OFFER_STAGE'||
                  app.status=='OFFER_STAGE'||
                  app.status=='ACTIVE'
                  ">
                  <img class="cursor-pointer" src="./../../../../assets/images/talentbase/delete-button.svg">
                </span>
              </td>
            </tr>

          </tbody>

        </table>

      </div>

    </div>
    <tlb-empty-page *ngIf="!applications || applications.length == 0"
      [warningLabel]="searchText == ''  ? 'noApplicationYet' : searchText" [filterText]="searchText"></tlb-empty-page>
    <div>

    </div>
  </div>
</div>

<app-applicants-modal [applications]="selectedApplications" [fromTrackApplications]="true"></app-applicants-modal>
<app-cancel-application-modal [applicationId]="selectedApplicationId" (cancelledApplication)="getApplications(1)">
</app-cancel-application-modal>



<div class="row mt-3 pagination">
  <pagination-controls (pageChange)="currentPage = $event;getApplications(currentPage)"
    [autoHide]="count <= requestPayload.limit" nextLabel="{{'next' | translate}}"
    previousLabel="{{'previous' | translate}}" responsive="true"></pagination-controls>
</div>
