import { JobType } from 'src/app/shared/enums';
import { ApplicationCountService, EmployerService, JobService } from 'src/app/shared/services';
import { JobFilterDataModel, JobModel } from 'src/app/shared/models';
import { Component, OnInit } from '@angular/core';
import { RequestPayload } from 'src/app/shared/classes';

@Component({
  selector: 'app-jobs-applied',
  templateUrl: './jobs-applied.component.html',
  styleUrls: ['./jobs-applied.component.scss']
})
export class JobsAppliedComponent implements OnInit {

  jobs: JobModel[] = [];
  count: number;
  requestPayload: RequestPayload;
  filterData: JobFilterDataModel;
  storedItems: any[] = [];
  searchText: string = "";
  sortText: string= "&sort=createdAt,DESC";

  constructor(private jobService: JobService, private employerService: EmployerService, private applicationCountService: ApplicationCountService) {
    this.requestPayload = new RequestPayload();
    this.filterData = new JobFilterDataModel();
    this.requestPayload.limit = 5;
  }

  ngOnInit(): void {
    this.getJobs('1');
  }

  sorted(sort){
    this.sortText = sort;
    this.getJobs(1);
  }

  getJobs(pageNo) {
    this.jobService.ServerGetWithText(this.requestPayload.payloadURL(pageNo) + this.sortText + this.requestFilter()).subscribe(x => {
      this.jobs = x.jobPagination.content;
      this.count = x.jobPagination.totalElements;
      this.storedItems = this.jobs;
      this.searchText != "" ? this.search(this.searchText) : "";
      this.jobs.forEach(x => {
        let time = Math.abs(new Date().getTime() - new Date(x.createdAt).getTime());
        x.postDate = Math.ceil(time / (1000 * 3600 * 24));
        this.employerService.ServerGetWithText("/" + x.employer.id + "/company-logo").subscribe(a => {
          x.logoUrl = a.url;

        });
        this.employerService.ServerGetWithText("/" + x.employer.id).subscribe(employerDetails => {
          x.aboutCompany = employerDetails.about;
        });
        this.applicationCountService.ServerGetWithText("/?jobId=" + x.id).subscribe(a => {
          x.managerReview = a.managerReview;
          x.offerStage = a.offerStage;
          x.pendingReview = a.pendingReview;
          x.rejected = a.rejectedByEmployer;
          x.clientReview = a.clientReview;
          x.interviewStage = a.interviewStage;
          x.openPosition = x.clientReview + x.managerReview + x.interviewStage + x.offerStage

        });
      });
    });

  }

  requestFilter() {
    return '&filter=countryId**' + this.filterData.country +
      '&filter=cityId**' + this.filterData.city +
      '&filter=freeText~~' + this.filterData.jobTitle +
      '&filter=type**' + this.filterData.jobType +
      '&filter=functionId**' + this.filterData.jobFunction +
      '&filter=industryId**' + this.filterData.industry +
      '&filter=favorite::' + true;
  }

  getFilteredJobs(e) {
    this.filterData = e;
    this.getJobs(1);

  }
  public get jobType(): typeof JobType {
    return JobType;
  }

  getClearFilterJobs(e) {
    this.filterData = e;
    this.getJobs(1);

  }

  search(data) {

    this.searchText = data;

    if (data == "") this.getJobs(1);
    // tslint:disable-next-line:max-line-length
    this.jobs = ((this.storedItems).filter((item: JobModel) =>
      (((item.employer.name).toLowerCase().trim()).indexOf(data.toLowerCase().trim()) > -1) ||
      (((item.title).toLowerCase().trim()).indexOf(data.toLowerCase().trim()) > -1)
    ));


  }

}
