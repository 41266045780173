<div class="container-fluid px-xl-5 px-0 my-4">
  <div class="row px-3">
    <div class="col-12 m-auto">
      <div class="pb-2 pt-2">
        <h3 class="gray">{{'notifications'|translate}}</h3>
      </div>
      <div class="card">

        <div class="container-fluid">
          <div class="row border main-row">
            <div class="col-4 col-xl-3 border-right overflow-auto left-col">
              <div *ngFor="let not of notifications" class="row position-relative py-3 border-bottom cursor" (click)="changeContent(not)"
                [class]="selectedNotification && selectedNotification.id == not.id ? 'active-row' :''">
                <div class="position-absolute end-0 notification-date gray">{{not.day}} {{'daysAgo' |translate}}</div>
                <div class="col-12 pr-4">
                  <img src="assets/images/avatar_empty.png" alt="" class="img-fluid avatar-size">
                  <h6 class="d-inline ml-3">{{not.title}}</h6>
                  <p class="mt-2 mb-0">{{not.content}}</p>
                </div>
                <div class="position-absolute end-0 top-50"><i class="fas fa-chevron-right gray"></i></div>
              </div>

              <tlb-empty-page *ngIf="!notifications || notifications.length == 0" [warningLabel]="'No notifications yet.'"></tlb-empty-page>

            </div>

            <div class="col-8 col-xl-9">

              <app-notification-content *ngIf="selectedNotification && selectedNotification.id" [notification]="selectedNotification">
              </app-notification-content>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
