export class JobTeamMembersModel {

  id?:	string;
  users: any[];
  publish:	boolean;

  constructor() {
    this.users = [];
    this.publish = false;
  }
}
