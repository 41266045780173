<div class="px-xl-5 pb-5">
  <div class="container-fluid mt-4">
    <span class="home-text">{{ 'recruiterReviews' | translate }}</span>

      <div class="row pt-3 overflow-auto">
        <div class="col-12">
          <table class="table" style="background-color: #fff;">
            <thead>
              <tr>

                <th scope="col"></th>
                <th scope="col">{{ 'fullName' | translate }}
                </th>
                <th scope="col"> {{ 'rating' | translate }}</th>
                <th scope="col"> {{ 'comment' | translate }} </th>
                <th scope="col"> {{ 'action' | translate }} </th>
              </tr>
            </thead>
            <tbody
              *ngFor="let review of reviews | paginate:{ itemsPerPage: requestPayload.limit, currentPage: currentPage,totalItems:count}">
              <tr>

                <td>
                  <div [style.background-color]="review.imageColor" id="preimage"><span
                      style="color: white;">{{review.imageText}}</span></div>
                </td>
                <td>
                  {{review.fullName}}

                </td>
                <td style="color: #707683;">
                  <span style="color: #F1B856;"
                    [ngClass]="review?.rating > 0 ? review?.rating < 1 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"></span>
                  <span style="color: #F1B856;"
                    [ngClass]="review?.rating > 1 ? review?.rating < 2 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"></span>
                  <span style="color: #F1B856;"
                    [ngClass]="review?.rating > 2 ? review?.rating < 3 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"></span>
                  <span style="color: #F1B856;"
                    [ngClass]="review?.rating > 3 ? review?.rating < 4 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"></span>
                  <span style="color: #F1B856;"
                    [ngClass]="review?.rating > 4 ? review?.rating < 5 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"></span>
                </td>
                <td style="color: #707683;">
                  {{review.comment}}
                </td>
                <td>
                  <span class="mr-3" (click)="openModal(review)">
                    <img class="cursor-pointer" src="./../../../../assets/images/talentbase/edit-button.png">
                  </span>

                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
      <tlb-empty-page *ngIf="!reviews || reviews.length == 0" [warningLabel]="'No review yet'"></tlb-empty-page>

  </div>
</div>


<div class="row mt-3 pagination">
  <pagination-controls (pageChange)="currentPage = $event; getReviews(currentPage)"
    [autoHide]="count <= requestPayload.limit" nextLabel="{{'next' | translate}}"
    previousLabel="{{'previous' | translate}}" responsive="true"></pagination-controls>
</div>

<app-admin-review-modal [review]="selectedReview" (clickButton)="changeStatus($event)"></app-admin-review-modal>
