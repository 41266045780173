<div class="row">
  <div class="col-12 col-lg-12 col-md-12 col-xl-12">

  <div class="dropdown drp about-content my-lg-0" id="filer-dropdown">
    <button (click)="filterState = !filterState" class="btn dropdown-menus form-control mb-1 d-inline"
       id="filterButton" type="button">
      <span class="text-center">{{'showAllFilter' | translate}}</span>
    </button>
    <div [style.display]="filterState ? 'block':'none'"
      aria-labelledby="dropdownMenuButton" id="filter-dropdown-menu" class="dropdown-menu col-12 pb-0">


      <div class="col-12 col-lg-12 col-md-12 col-xl-12 pt-md-2 pt-lg-2 pt-xl-0 pb-2">

        <tlb-dropdown [items]="countryOptions" [dropdownName]="countryLabel != '' ? countryLabel : 'country'"
          (searchData)="selectCountry($event)"
          [style]="'text-align: left; border: 1px solid #DCDCDC; box-sizing: border-box; box-shadow: 0px 2px 4px rgb(0 0 0 / 10%); border-radius: 7px; height: 40px;'"
          [textStyle]="'font-family: Roboto; font-style: normal; font-weight: 500; font-size: 15px; line-height: 16px; letter-spacing: 0.1px; color: #252B42 !important;'">
        </tlb-dropdown>

        </div>
        <div class="col-12 col-lg-12 col-md-12 col-xl-12 pt-md-2 pt-lg-2 pt-xl-0 pb-2">
          <tlb-multiselect-dropdown (selectResult)="selectType($event)" [isFilter]="false"
          [items]="jobTypeOptions" [dropdownName]="jobTypeLabel != '' ? jobTypeLabel : 'jobType'"
          [defaultItemKey]="''" [existItems]="selectedJobTypes"
          [style]="'text-align: left; border: 1px solid #DCDCDC; box-sizing: border-box; box-shadow: 0px 2px 4px rgb(0 0 0 / 10%); border-radius: 7px; height: 40px;'"
          [textStyle]="'font-family: Roboto; font-style: normal; font-weight: 500; font-size: 15px; line-height: 16px; letter-spacing: 0.1px; color: #252B42 !important;'">
        </tlb-multiselect-dropdown>
        </div>

        <div class="col-12 col-lg-12 col-md-12 col-xl-12 pt-md-2 pt-lg-2 pt-xl-0 pb-2">
          <tlb-multiselect-dropdown (selectResult)="selectFunction($event)" [isFilter]="false"
          [items]="jobFunctions" [dropdownName]="jobFunctionLabel != '' ? jobFunctionLabel : 'jobFunction'"
          [existItems]="selectedFunctions" [dropDownMenuWidth]="'17rem'"
          [style]="'text-align: left; border: 1px solid #DCDCDC; box-sizing: border-box; box-shadow: 0px 2px 4px rgb(0 0 0 / 10%); border-radius: 7px; height: 40px;'"
          [textStyle]="'font-family: Roboto; font-style: normal; font-weight: 500; font-size: 15px; line-height: 16px; letter-spacing: 0.1px; color: #252B42 !important;'">
        </tlb-multiselect-dropdown>
        </div>

      <div class="row mr-4" style="justify-content: end;">

        <div class="mt-2 mb-2 mr-1">
          <button (click)="clearFilterEmit()" class="btn clear-filter-btn" type="button">{{'discard' |
            translate}}</button>
        </div>
        <div class="mt-2">
          <button (click)="emitData()" class="btn apply-filter-btn" type="button">{{'apply' |
            translate}}</button>
        </div>


      </div>

    </div>
  </div>


</div>
