import { Component, OnInit } from '@angular/core';
import { FaqCategoryService, FaqItemsService } from 'src/app/shared/services';
declare var $: any;
@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  faqCategory: boolean = false;
  categories: any[] = [];
  questions: any[] = [];
  selectedCat: boolean = false;

  constructor(private faqItemService: FaqItemsService, private faqCategoryService: FaqCategoryService) { }

  ngOnInit(): void {
    this.getFaqCategories();
  }

  getFaqCategories(){
    this.faqCategoryService.ServerGetWithoutLogin().subscribe(x=>{
      this.categories = x.content;
      let id = this.categories[0].id ;
      this.getFaqs(id);

    });
  }

  selectCategory(value){
    let id = value ? this.categories[0].id : this.categories[1].id;
       this.getFaqs(id);
   }

  getFaqs(catId){
    this.faqItemService.ServerGetWithoutLoginWithText("/?categoryId=" + catId).subscribe(x=>{
      this.questions = x.content.filter(a => a.status != "DELETED");
    });
  }

  scrolling() {


    $('.navbar ').addClass('fixed-top');
    $('.fixed-top').removeClass('menu-bg');
  }

  setIcon(id){
    this.questions.forEach(x=> {
      x.id == id ? this.questions.filter(x=> x.id == id)[0].show = !this.questions.filter(x=> x.id == id)[0].show : x.show = false;
    })

  }


}
