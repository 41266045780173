export class EmployerCompanyInfoModel {
  companyName: string;
  companySize: string;
  website: string;
  about: string;
  industries: any[];
  companyLogo: any;

  constructor() {
    this.companyName = '';
    this.companySize = '';
    this.website = '';
    this.about = '';
    this.industries = [];
    this.companyLogo = null;
  }
}
