<div class="container-fluid px-xl-4 px-md-2 px-1">
  <h6 class="credits ml-3">Credits</h6>
  <p class="talentbase-credits ml-3">{{'talentbaseCredits' | translate}}</p>


  <svg style="border: 1px solid #C8D4DF;width: 100%" width="1169" height="2" viewBox="0 0 1169 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 1H1169" stroke="#C8D4DF"/>
  </svg>


  <div class="row pt-4 px-xl-4 px-md-2 px-1">
        <div class="col-12 col-lg-12 col-xl-6 mb-3 mb-md-3 mb-sm-3 mb-xl-0 mb-lg-3">
          <div class="card shadow-sm" style=" border-left-color: #EBB157">
            <div class="card-body" style="background: #FBEEDA; border-radius: 4px;">
              <div class="card-text">
                <div class="row">

                  <div class="col-xl-6 col-md-6 col-12 pt-xl-0 pt-md-0 pt-3 pl-lg-2 pl-md-0 pl-xl-4">
                    <h6 class="header">{{ 'currentTalentbaseScore' | translate }}:</h6>



                  </div>
                  <div class="col-xl-6 col-md-6 col-12 col-6">
                    <div class="position-relative" style="max-width: 150px;float: right;">
                      <img src="assets/images/credit-left.png" class="m-auto d-block">
                      <p class="number-style">{{totalCredits}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-12 col-xl-6 col-6">
          <div class="card shadow-sm" style="border-left-color: #3652E3;background: #FFFFFF;">
            <div class="card-body">
              <div class="card-text">
                <div class="row">

                  <div class="col-xl-6 col-md-7 col-12 pt-xl-0 pt-md-0 pt-3 pl-5 pl-sm-5">
                    <h6 class="header">{{ 'candidatesLeftThisMonth' | translate }}:</h6>
                    <p class="quota-number-style">{{applicationQuotaRemaining}}</p>

                    <div>
                    </div>
                  </div>

                  <div class="col-xl-2 col-md-2 col-12">
                    <img src="./../../../assets/images/candidate-left.png">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="container-fluid p-0 m-0 ">
        <div class="mt-2">

          <div class="row mt-4 pl-5">
            <div class="col-12 overflow-auto hide-scroll">
              <span class="tab-span" [ngClass]="activeTab==0?'active':''"
                (click)="activeTab=0">{{'fromReviews'|translate|titlecase}}</span>
              <span class="tab-span" [ngClass]="activeTab==1?'active':''"
                (click)="activeTab=1">{{'fromPlacements'|translate|titlecase}}</span>
            </div>
          </div>
          <hr class="hr-style">

          <div class="row overflow-auto" *ngIf="activeTab==0">
            <div class="col-12">
              <div class="row px-1 px-xl-4 px-md-4 px-sm-4">
                <div class="col-12 pt-4">
                  <table class="table shadow-sm">
                    <thead>
                      <tr>
                        <th scope="col common-style " style="color: #323C47;" >{{ 'fullName' | translate }}
                        </th>

                        <th scope="col common-style" style="color: #323C47;">{{ 'rating' | translate }}</th>
                        <th scope="col common-style" style="color:#323C47"> {{ 'earnedScore' | translate }}</th>
                        <th scope="col common-style" style="color:#323C47">{{ 'date' | translate }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let rev of reviews">
                        <td>{{rev.fullName}}</td>
                        <td>
                          <span style="color: #F1B856;" [ngClass]="rev?.rating > 0 ? rev?.rating < 1 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"></span>
                          <span style="color: #F1B856;" [ngClass]="rev?.rating > 1 ? rev?.rating < 2 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"></span>
                          <span style="color: #F1B856;" [ngClass]="rev?.rating > 2 ? rev?.rating < 3 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"></span>
                          <span style="color: #F1B856;" [ngClass]="rev?.rating > 3 ? rev?.rating < 4 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"></span>
                          <span style="color: #F1B856;" [ngClass]="rev?.rating > 4 ? rev?.rating < 5 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"></span></td>
                        <td>{{rev.earnedScore}}</td>
                        <td>{{rev.createdAt | date}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                </div>
            </div>
          </div>

          <div class="row overflow-auto" *ngIf="activeTab==1">
            <div class="col-12">
              <div class="row px-1 px-xl-4 px-md-4 px-sm-4">
                <div class="col-12 pt-4">
                  <table class="table" *ngIf="earnings && earnings.length > 0" style="background-color: #fff;">
                    <thead>
                      <tr>
                        <th scope="col" style="color: #323C47;" >{{ 'jobTitle' | translate }}</th>
                        <th scope="col" style="color: #323C47;" >{{ 'candidateName' | translate }}</th>
                        <th scope="col" style="color: #323C47;" >{{ 'employerName' | translate }}</th>

                        <th scope="col" style="color: #323C47;" >{{ 'earnedScore' | translate }}</th>
                        <th scope="col" style="color: #323C47;" >{{ 'placedAt' | translate }}</th>

                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let ear of earnings">
                        <td class="">{{ear.job.name}}</td>
                        <td>{{ear.candidate.name}}</td>
                        <td class="">{{ear.employer.name}}</td>

                        <td class="">{{ear.earnedScore}}</td>
                        <td class="">{{ear.placedAt | date}}</td>


                      </tr>

                    </tbody>

                  </table>

                </div>

              </div>
              <tlb-empty-page *ngIf="!earnings || earnings.length == 0" [warningLabel]="'No credits yet.'"></tlb-empty-page>
            </div>
          </div>


        </div>
      </div>





    </div>

