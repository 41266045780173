<div *ngIf="role=='RECRUITER_MANAGER' || role ==='RECRUITER_USER' || role=='EMPLOYER_MANAGER' || role=== 'EMPLOYER_USER'"
     class="container-fluid"
     style="background-color:  #F5F7FA;">
  <div class="cover-image-emp row">
    <div class="top-centered ml-5 col-7">
      <br>
      <span class="cover-header-emp"><b>{{'topJobBoardsFor' | translate}}</b></span> <br>
      <span class="cover-header-emp"><b>{{'professionals' | translate}}</b></span> <br>
      <div class="row"
           style="line-height: 15px;">
        <div class="col-xl-8 col-md-12">
          <span class="cover-sub-header-emp">{{'discoverYourNextCareerEmp' | translate}}</span> <br>
          <span class="cover-sub-header-emp">{{'vacanciesCustomerSupportEmp' | translate}}</span>
        </div>
        <div class="col-xl-4 col-md-12 pt-xl-0 pt-md-3 pt-3 pb-xl-0 pb-md-3 pb-4">
          <button [routerLink]="['/support']"
                  class="btn btn-outline-dark explore-div"
                  type="button">{{ 'exploreIt' | translate }}</button>
        </div>
      </div>
    </div>

    <div class="col-4 d-none d-md-none d-xl-block"
         style="text-align: end;">
      <img src="assets/images/emp-dashboard.png"
           style="margin-top: -50px;">
    </div>

  </div>
</div>

<div class="container-fluid">
  <div class="row">

    <div class="col-xl-9 col-12 ">
      <app-dashboard-quarter-line-cart *ngIf="quarterModels && quarterModels.length > 0 && (role=='RECRUITER_MANAGER' || role ==='RECRUITER_USER' || role=='EMPLOYER_MANAGER' || role=== 'EMPLOYER_USER') "
                                       [quarterModels]="quarterModels"></app-dashboard-quarter-line-cart>
      <app-recruiter-dashboard *ngIf="role=='RECRUITER_MANAGER'|| role ==='RECRUITER_USER'"
                               [news]="news">
      </app-recruiter-dashboard>

      <app-employer-dashboard *ngIf="role=='EMPLOYER_MANAGER' || role=== 'EMPLOYER_USER'"
                              [news]="news">
      </app-employer-dashboard>
    </div>

    <div class="col-xl-3 col-12 ">
      <app-employer-dashboard-activity *ngIf="role=='EMPLOYER_MANAGER' || role=== 'EMPLOYER_USER' || role== 'RECRUITER_MANAGER' || role== 'RECRUITER_USER'"
                                       class="ml-2 mr-2"></app-employer-dashboard-activity>
      <app-employer-dashboard-best-recruiters *ngIf="role=='EMPLOYER_MANAGER' || role=== 'EMPLOYER_USER'"
                                              class="ml-2 mr-2"></app-employer-dashboard-best-recruiters>
      <app-recruiter-dashboard-candidate *ngIf="role=='RECRUITER_MANAGER'|| role ==='RECRUITER_USER'"></app-recruiter-dashboard-candidate>
    </div>

  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div *ngIf="role=='ADMIN'"
         class="col-xl-12 col-12">
      <app-admin-dashboard *ngIf="role=='ADMIN'"></app-admin-dashboard>
      <app-dashboard-quarter-line-cart *ngIf="quarterModels && quarterModels.length > 0"
                                       [quarterModels]="quarterModels"></app-dashboard-quarter-line-cart>

      <div class="container-fluid mt-4">
        <app-admin-job-profiles-list></app-admin-job-profiles-list>
      </div>

    </div>
  </div>
</div>


<app-complete-register-popup [profileEnrichmentScore]="profileEnrichmentScore"></app-complete-register-popup>
