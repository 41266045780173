import {Component} from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';
import {authPasswordFlowConfig} from './shared/config/oauth.config';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Talentbase';

  constructor(private oauthService: OAuthService) {
    localStorage.setItem('lang', 'en');
    this.oauthService.configure(authPasswordFlowConfig);
  }
}
