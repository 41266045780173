import { EmployerMeService } from './../../../../shared/services/uaa/employer/employer-me.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { JobFunctionModel } from 'src/app/shared/models';
import { IndustryService } from 'src/app/shared/services';

@Component({
  selector: 'app-industry-job-profiles',
  templateUrl: './industry-job-profiles.component.html',
  styleUrls: ['./industry-job-profiles.component.scss']
})
export class IndustryJobProfilesComponent implements OnInit {

  industries: JobFunctionModel[] = [];
  selectedIndustries: any[] = [];
  allIndustries: any[] = [];
  filteredItems: JobFunctionModel[] = [];
  filter: string = "";
  filterIndustry: string = "";
  filterArray: any[] = [];
  showJobs: boolean = false;
  @Output() useJobProfile = new EventEmitter();

  constructor(private industryService: IndustryService, private employerMeService: EmployerMeService) { }

  ngOnInit() {
    this.getIndustries();
  }

  getIndustries() {
    this.employerMeService.ServerGet().subscribe(x => {
      this.selectedIndustries = x.industries;
      this.industryService.ServerGet().subscribe(x => {
        this.industries = x.content as JobFunctionModel[];
        this.filteredItems = this.industries;
        this.selectedIndustries.forEach(f=> {
          this.filterArray.push(this.industries.filter(fi=> fi.id == f.id)[0]);

        });
        this.setGroup();

      });
    });


  }

  setGroup() {
    const numsPerGroup = Math.ceil(this.filteredItems.length / 4);
    const num = Math.ceil(this.filteredItems.length / numsPerGroup);
    const result = new Array(numsPerGroup)
      .fill('')
      .map((_, i) => this.filteredItems.slice(i * num, (i + 1) * num));
    this.allIndustries = result;

    this.setFilter();
  }

  search(data) {
    if (this.filter != "") {
      this.filteredItems = ((this.filteredItems).filter((item: any) => ((item.defaultLocalization.name).toLowerCase().trim()).indexOf(data.toLowerCase().trim()) > -1));
      this.setGroup();
    }

    else {
      this.filteredItems = this.industries;
      this.setGroup();
    }
  }

  checkChange(data) {
    if(this.filterArray == []){
      this.filterArray.push(data);
    }
    else{
      if(this.filterArray.some(x=> x == data)){
        const index: number = this.filterArray.indexOf(data);
        if (index !== -1) {
            this.filterArray.splice(index, 1);
        }
      }
      else {
        this.filterArray.push(data);
      }
    }
  }

  setFilter() {
    this.showJobs = false;
    this.filterIndustry = "";
    this.filterArray.forEach(x=>{
      if(this.filterIndustry == "") this.filterIndustry = x.id;
      else this.filterIndustry = this.filterIndustry + "," + x.id ;
    });
    this.showJobs = true;

  }

  useProfile(job){
    this.useJobProfile.emit(job);
  }

}
