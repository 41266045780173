import {Inject, Injectable} from '@angular/core';
import {TRANSLATIONS} from './translation';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  // tslint:disable-next-line:variable-name
  constructor(@Inject(TRANSLATIONS) private _translations: any) {
  }

  // tslint:disable-next-line:variable-name
  private _currentLang: string = localStorage.getItem('lang');

  public get currentLang(): string {
    return this._currentLang;
  }
  public setCurrentLang(lang:string){
    this._currentLang=lang;
  }

  public instant(key: string): string {
    // call translation
    return this.translate(key);
  }

  private translate(key: string): string {
    // private perform translation
    const translation = key;

    if (this._translations[this.currentLang] && this._translations[this.currentLang][key]) {
      return this._translations[this.currentLang][key];
    }

    return translation;
  }
}
