import { Component, OnInit } from '@angular/core';
import { DropdownOptionsModel, JobProfileModel } from 'src/app/shared/models';
import { JobSegmentTagService, JobsProfileService, TranslatePipe } from 'src/app/shared/services';

@Component({
  selector: 'app-admin-job-profiles',
  templateUrl: './admin-job-profiles.component.html',
  styleUrls: ['./admin-job-profiles.component.scss']
})
export class AdminJobProfilesComponent implements OnInit {

  jobs: JobProfileModel[] = [];
  selectedJob: JobProfileModel = new JobProfileModel();
  count: number = 0;
  sortLabel: string = this.translate.transform('newest');
  sortOptions: DropdownOptionsModel[] = [
    { label: this.translate.transform('newest'), value: '/?sort=createdAt,DESC' },
    { label: this.translate.transform('oldest'), value: '/?sort=createdAt,ASC' }
  ];
  sortText: string = "/?sort=createdAt,DESC";

  constructor(private jobProfileService: JobsProfileService, private jobSegmentTagService: JobSegmentTagService,
    private translate: TranslatePipe) { }

  ngOnInit(): void {
    this.getProfiles();
  }

  getProfiles(){
    this.jobProfileService.ServerGet().subscribe(x => {
      this.count = x.totalElements;
    });
  }

  selectSort(data) {
    this.sortLabel = data.label;
    this.sortText = data.value;
  }

  search(data) {

    if (data == "") this.getProfiles();
    // tslint:disable-next-line:max-line-length
    // this.recruiters = ((this.storedItems).filter((item: RecruiterUserModel) => ((item.user.fullName).toLowerCase().trim()).indexOf(data.toLowerCase().trim()) > -1));
  }
}


