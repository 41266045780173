<!-- Modal -->
<div class="modal fade" id="deactivatePopup" tabindex="-1" role="dialog" aria-labelledby="deactivatePopupModalLongTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title headerModal" id="deactivatePopupModalLongTitle">{{ 'deactivate' | translate }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body"> 
                <div class="row">
                  <div class="col-12">
                    <p class="font-weight-light alert alert-warning"><i class="fas fa-exclamation-triangle pr-1"></i>  {{ 'deactivateText' | translate }} </p>
                  </div>         
                </div>   
      </div>
      <div class="row">
        <div class="col-12 float-right">
          <div class="modal-footer">
            <button type="button" class="btn btnNo" data-dismiss="modal">{{ 'no' | translate }}</button>
            <button type="button" class="btn btnYes" >{{ 'yes' | translate }}</button>
          </div>
        </div>
      </div>
</div>  
</div>   
</div>