import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss']
})
export class StarRatingComponent implements OnInit {

  @Input() ratingScore:number;
  constructor() { }

  ngOnInit(): void {
  }

}
