import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CreateAvatar, RequestPayload } from 'src/app/shared/classes';
import { DropdownOptionsModel, UserModel } from 'src/app/shared/models';
import { EmployerMeService, RecruiterMeService, TranslatePipe, UserService } from 'src/app/shared/services';

declare var $: any;

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  users: UserModel[] = [];
  selectedUser: UserModel = new UserModel();
  requestPayload: RequestPayload;
  currentPage: number;
  count: number;
  sortText: string = '&sort=createdAt,DESC';
  filterText: string = '';
  postFilter: string = '';
  sortLabel: string = this.translate.transform('newestFirst');
  sortOptions: DropdownOptionsModel[] = [
    { label: this.translate.transform('newestFirst'), value: '&sort=createdAt,DESC' },
    { label: this.translate.transform('oldestFirst'), value: '&sort=createdAt,ASC' }
  ];

  isEmployer: number = 1;
  createAvatar: CreateAvatar;
  role: string = "";

  constructor(private userService: UserService, private translate: TranslatePipe, private route: ActivatedRoute,
    private employerMeService: EmployerMeService, private recruiterMeService: RecruiterMeService) {
    this.requestPayload = new RequestPayload();
    this.requestPayload.limit = 5;
    this.createAvatar = new CreateAvatar();
  }

  ngOnInit() {
    this.isEmployer = +this.route.snapshot.paramMap.get('isEmployer');
    this.role = localStorage.getItem('role');
    this.getUsers(1);
  }

  getUsers(pageNo) {
    this.userService.ServerGetWithText(
      this.requestPayload.payloadURLApp(pageNo) + this.sortText + this.postFilter + '&filter=status!:DELETED')
      .subscribe((res: any) => {
        this.users = res.content;
        this.count = res.totalElements;
        if (this.isEmployer) {
          this.employerMeService.ServerGet().subscribe(x => {
            this.users.forEach(f => {
              f.imageText = this.createAvatar.create(f.fullName);
              f.imageColor = this.createAvatar.color();
              f.erasable = f.id == x.createdBy.id || this.role != 'EMPLOYER_MANAGER' ? false : true;
            });
          });
        }
        else {
          this.recruiterMeService.ServerGet().subscribe(x => {
            this.users.forEach(f => {
              f.imageText = this.createAvatar.create(f.fullName);
              f.imageColor = this.createAvatar.color();
              f.erasable = f.id == x.createdBy.id || this.role != 'RECRUITER_MANAGER' ? false : true;
            });
          });
        }
      });
  }

  selectSort(data) {
    this.sortLabel = data.label;
    this.sortText = data.value;
    this.getUsers(1);
  }

  userModalOpen(tempData?) {

    // this.users = tempData;
    // @ts-ignore
    $('#exampleModalLongFromUser')
      .modal('show');
  }

  deleteModal(user: UserModel) {
    this.selectedUser = user;
    // @ts-ignore
    $('#exampleDeleteModal')
      .modal('show');
  }

  clearSearch() {
    this.filterText = "";
    this.onFilter();
  }

  onFilter() {
    this.postFilter = '&filter=name~~' + this.filterText;
    this.getUsers(1);
  }

}
