import { CommissionTypeEnum } from './commission-type.enum';

export class CommissionModel {
  type: CommissionTypeEnum;
  amount: number;

  constructor() {
    this.type = CommissionTypeEnum.FIXED;
    this.amount = 0;
  }
}
