import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CandidateModel, CoverLetterModel, DropdownOptionsModel, JobModel, ResumeModel } from 'src/app/shared/models';
import { NotificationService, TranslatePipe, server, CandidateService, SubmitApplicationService, ApplicationService } from 'src/app/shared/services';
import { EmployerService } from 'src/app/shared/services/uaa/employer/employer.service';

@Component({
  selector: 'app-submit-candidate-modal',
  templateUrl: './submit-candidate-modal.component.html',
  styleUrls: ['./submit-candidate-modal.component.scss']
})

export class SubmitCandidateModalComponent implements OnInit {

  candidates: CandidateModel[] = [];
  @Input() job: JobModel;
  @Input() applicationId: number = 0;
  @Input() dropName: string = "";

  @Output() cancelledApplication: EventEmitter<any> = new EventEmitter();

  candidateOptions: DropdownOptionsModel[] = [];
  answers: any[] = [];
  selectedCandidate: any;
  dropDownLabel: string = "chooseCandidate";
  email: string ="";
  phoneCode: string = "";
  phoneNumber: string = "";
  coverLetter:CoverLetterModel = new CoverLetterModel();
  resume: ResumeModel = new ResumeModel();
  questions: any[] = [];
  answer: any;
  selectedJob: any = null;
  filterText: string = "";


  constructor(private notification: NotificationService, private translate: TranslatePipe, private candidateService: CandidateService, private element: ElementRef,
    private submitApplicationService: SubmitApplicationService, private employerService: EmployerService, private applicationService: ApplicationService) { }

  ngOnInit() {

  }

  ngAfterViewInit() {
    if (document.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }
    this.element.nativeElement.focus();

  }

  ngOnChanges() {
    this.answers = [];

    if(this.job && this.job.questionnaire && this.job.questionnaire.questions.length > 0){
      this.job.questionnaire.questions.forEach(q => {
        this.answers.push({ question: { id: q.id, name: q.content }, answerType: "TEXT_FIELD", content: "" });
      });
    }

    this.selectedCandidate = null;
    this.getCandidates();
  }

  dsbControl():boolean {
    return this.answers.some(x=> x.content == "") || !this.selectedCandidate;
  }

  getCandidates() {
    this.candidateService.ServerGetWithText("/?filter=name~~" + this.filterText)
      .subscribe((res: any) => {
        this.candidates = res.content;
        this.candidateOptions = this.candidates.map(c => ({ value: c, label: c.fullName }));
      });
  }

  getFilteredCandidates(data){
    this.filterText = data;
    this.getCandidates();
  }

  selectCandidate(data) {
    this.selectedCandidate = data.value;
    this.email = this.selectedCandidate.email;
    this.phoneCode = this.selectedCandidate.phoneNumber.countryCallingCode;
    this.phoneNumber = this.selectedCandidate.phoneNumber.phoneNumber;
    this.coverLetter = this.selectedCandidate.coverLetter;
    this.resume = this.selectedCandidate.resume;
  }

  openCancelApplicationModal() {
    // @ts-ignore
    $('#cancelApplicationModal').modal('show');
  }

  cancelApplication() {
    this.cancelledApplication.emit();
  }

  openUrl(url) {
    window.open(url);
  }

  confirm(){
      let model: any = {
      candidateId: this.selectedCandidate.id,
      jobId: this.job.id,
      answers: this.answers
    }

    this.submitApplicationService.ServerPost(model).subscribe(x => {
      this.notification.success(this.translate.transform('yourApplicationHasBeenSubmittedSuccessfully'));

      // @ts-ignore
      $('#submitCandidateModal').modal('hide');
    },
    error=>{
          // @ts-ignore
      $('#submitCandidateModal').modal('hide');
        this.notification.error(this.translate.transform(error.error.code));


    }
    );


  }
}
