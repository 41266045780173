<!-- Header Section Start -->
<header class="hero-area-2 headerr" id="home">
  <div class="overlay"></div>
  <div class="container" style="max-width: 800px !important;">
    <div class="row space-100" style="justify-content: center;">
      <div class="col-lg-5 col-md-12 col-xs-12">
        <div class="contents">
          <div class="header-text">
            {{'landingHeader' | translate}}
          </div>
          <div class="header-sub-text mt-3">
            {{'landingSubHeader' | translate}}
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-md-12 col-xs-12 mt-xl-5 mt-lg-5">
        <img class="w-100 mt-xl-5 mt-lg-5" src="./../../../assets/images/talentbase/header-right.png" />
      </div>
    </div>
  </div>
</header>

<!-- Header Section End -->

<!-- features Section Start -->
<div class="section" id="app-features">
  <div class="container text-center" style="max-width: 800px !important;">
    <div class="section-header">
      <div class="wow fadeInDown why-picktus" data-wow-delay="0.2s">{{'whyPicktus' | translate}}</div>
      <p class="wow fadeIn why-picktus-desc" data-wow-delay="0.2s">
        {{'whyPicktusDescription' | translate}}
      </p>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-12 col-xs-12">
        <div class="content-right">
          <div class="box-item right">
            <div class="text-center pb-4">
              <img src="./../../../assets/images/talentbase/user.png" />
            </div>
            <div class="text">
              <div class="why-picktus-headers">{{'whyPicktusHeader1' | translate}}</div>
              <p class="why-picktus-descs">
                {{'whyPicktusDesc1' | translate}}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 col-xs-12">
        <div class="content-right">
          <div class="box-item right">
            <div class="text-center pb-4">
              <img src="./../../../assets/images/talentbase/degree.png" />
            </div>
            <div class="text">
              <div class="why-picktus-headers">{{'whyPicktusHeader2' | translate}}</div>
              <p class="why-picktus-descs">
                {{'whyPicktusDesc2' | translate}}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 col-xs-12">
        <div class="content-right">
          <div class="box-item right">
            <div class="text-center pb-4">
              <img src="./../../../assets/images/talentbase/lamp.png" />
            </div>
            <div class="text">
              <div class="why-picktus-headers">{{'whyPicktusHeader3' | translate}}</div>
              <p class="why-picktus-descs">
                {{'whyPicktusDesc3' | translate}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- features Section End -->

<!-- Start Pricing Table Section -->
<div class="section pricing-section" id="explore" style="background-color: #f5f7fa">
  <div class="container" style="max-width: 800px !important;">
    <div class="row">
      <div class="col-lg-6 col-md-12 col-xs-12">
        <div class="text">
          <h4>{{'exploreOurRecruitmentSoftware' | translate}}</h4>
          <p class="subtitle-texts">
            {{'exploreOurDesc' | translate}}
          </p>
        </div>
        <div class="pt-5 text-center text-lg-left">
          <a class="btn btn-border2 " href="#faq">{{'learnMore' | translate}}</a>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-xs-12 pt-5 pt-xl-0">
        <div class="show-box">
          <img alt="" class="w-100" src="./../../../assets/images/talentbase/talent-interface.png" />
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Pricing Table Section -->

<!-- Awesome Screens Section Start -->
<section class="screens-shot section" id="screenshots">
  <div class="container" style="max-width: 800px !important;">
    <div class="row">
      <div class="col-lg-6 col-md-12 col-xs-12 pt-5 pt-xl-0">
        <div class="show-box pb-5">
          <img alt="" class="w-100" src="./../../../assets/images/talentbase/easy-use.png" />
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-xs-12">
        <div class="text pb-3">
          <h4>{{'easyToUseSolution' | translate}}</h4>
          <p style="font-size: 16px; color: #2b3a4b">
            {{'easyToUseDesc' | translate}}
          </p>
        </div>
        <div class="row pt-5 pt-xl-1 pl-3 pb-5">
          <div class="get-quote pr-3 ml-auto ml-lg-0">
            <a class="btn btn-border2" href="/pricing">{{'getAQuote' | translate}}</a>
          </div>
          <div class="pt-xl-0 pt-md-0 mr-auto mr-lg-0">
            <a class="btn btn-border3 shadow-lg" href="#faq">{{'learnMore' | translate}}</a>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-xs-12 pt-5">
        <div class="text">
          <h4>{{'features' | translate}}</h4>
          <p class="subtitle-texts">
           {{'featuresDesc' | translate}}
          </p>
        </div>
        <div class="pt-5 text-center text-lg-left">
          <a class="btn btn-border2" href="#faq">{{'learnMore' | translate}}</a>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-xs-12 pt-5 pt-xl-0">
        <div class="show-box pt-5">
          <img alt="" class="w-100" src="./../../../assets/images/talentbase/laptop.png" />
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Awesome Screens Section End -->

<!-- Team section Start -->
<section class="section" id="team" style="background-color: #faf8f3">
  <div class="container" style="max-width: 800px !important;">
    <div class="row">
      <div class="col-lg-6 col-md-12 col-xs-12 pt-5 pt-xl-0">
        <div class="show-box">
          <img alt="" class="person-phone" src="./../../../assets/images/talentbase/person-phone.png" />
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-xs-12">
        <div class="text pb-3">
          <h4 class="pb-3 pt-3 pt-xl-0">{{'downloadAppNow' | translate}}</h4>
          <p style="font-size: 16px; color: #2b3a4b">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
            nunc ante velit vitae. Est tellus vitae, nullam lobortis enim.
            Faucibus amet etiam tincidunt rhoncus, ullamcorper velit.
            Ullamcorper risus tempor, ac nunc libero urna, feugiat.
          </p>
        </div>
        <div class="row pt-5 pt-xl-1 pl-3 pb-3">
          <div class="pr-3">
            <img height="60" width="180" src="./../../../assets/images/talentbase/google-play.png" />
          </div>
          <div class="pt-sm-0 pt-xl-0 pt-md-0 border3-pos">
            <img height="60px" width="180" src="./../../../assets/images/talentbase/apple.png" />
          </div>
        </div>

      </div>
    </div>
  </div>
</section>

<!-- Blog Section -->
<section class="section" id="blog" style="    background-size: cover;
    background: url(./../../../assets/images/talentbase/numbers-bg.png) center;">
  <!-- Container Starts -->
  <div class="container" style="max-width: 800px !important;">
    <div class="row section-one">
      <div class="col-xl-3 col-md-6 col-12 pb-xl-0 pb-md-4 pb-4 text-center text-xl-left">
        <div class="number-fs text-center text-xl-left">+{{landingModel?.candidateCounts}}</div>
        <div>{{'candidates' | translate }}</div>
      </div>
      <div class="col-xl-3 col-md-6 col-12 pb-xl-0 pb-md-4 pb-4 text-center text-xl-left">
        <div class="number-fs text-center text-xl-left">+{{landingModel?.recruiterUsers}}</div>
        <div>{{'recruiters' | translate}}</div>
      </div>
      <div class="col-xl-3 col-md-6 col-12 pb-xl-0 pb-md-0 pb-4 text-center text-xl-left">
        <div class="number-fs text-center text-xl-left">+{{landingModel?.employerUsers}}</div>
        <div>{{'employers' | translate}}</div>
      </div>
      <div class="col-xl-3 col-md-6 col-12 text-center text-xl-left">
        <div class="number-fs text-center text-xl-left-left">+{{landingModel?.jobCounts}}</div>
        <div>{{'postedJobs' | translate}}</div>
      </div>
    </div>
  </div>
</section>
<!-- download Section Start -->

<!-- Start Pricing Table Section -->
<app-pricing></app-pricing>


<!-- Start FAQ  -->

<div class="section pricing-section pt-0 pt-md-5 pt-xl-5" id="faq" style="background-color: #f5f7fa">
  <div class="container-fluid px-xl-5 px-md-5 px-0 py-5">
    <div class="px-xl-5 px-md-5 px-0">
      <div class="section-header">
        <div class="faq-header" style="color: black">
          {{ "frequentlyAskedQuestions" | translate }}
        </div>
      </div>

      <div class="col-12" style="display: flex; justify-content: center">
        <div *ngIf="categories && categories.length > 0" class="switch6 mt-3 mb-4">
          <label class="switch6-light" onclick="">
            <input (click)="selectCategory(selectedCat)" [(ngModel)]="selectedCat" type="checkbox" />
            <span>
              <span [ngClass]="!selectedCat ? 'white-text' : 'gray-text'">{{
                categories[0].name | uppercase
                }}</span>
              <span [ngClass]="selectedCat ? 'white-text' : 'gray-text'">{{
                categories[1].name | uppercase
                }}</span>
            </span>
            <a class="swtich-button"></a>
          </label>
        </div>
      </div>

      <div class="container-fluid">
        <div class="col-12">
          <div class="faq-box">
            <div class="accordion" id="accordionExample">
              <div *ngFor="let item of questions" class="card">
                <div [id]="'heading' + item.id" class="card-header">
                  <button (click)="setIcon(item.id)" [attr.aria-controls]="'collapse' + item.id"
                    [attr.data-target]="'#collapse' + item.id" aria-expanded="true" class="btn btnk"
                    data-toggle="collapse" type="button">
                    <div class="row">
                      <div class="col-xl-11 col-md-10 col-12">
                        {{ item.question }}
                      </div>
                      <div class="col-xl-1 col-md-2 col-12">
                        <i [ngClass]="item.show ? 'fas fa-times' : 'fa fa-plus'" style="float: right"></i>
                      </div>
                    </div>
                  </button>
                </div>
                <div [attr.aria-label]="'heading' + item.id" [id]="'collapse' + item.id" class="collapse"
                  data-parent="#accordionExample">
                  <div class="card-body ml-xl-5 ml-md-5 ml-0 content">
                    {{ item.answer }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- end FAQ -->

<!-- Team section Start -->
<section class="section" id="team" style="background-color: #ffffff">
  <div class="container" style="max-width: 800px !important;">
    <div class="section-header">
      <div class="col-lg-12 col-md-12 col-xs-12">
        <div class="section-header pt-5">
          <h4>{{'ourPartners' | translate}}</h4>
          <p style="font-size: 16px; color: #2b3a4b">
            {{'ourPartnersDesc' | translate}}
          </p>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 col-xl-2">
            <img class="w-100" src="./../../../assets/images/talentbase/google.png" />
          </div>
          <div class="col-12 col-md-6 col-xl-2">
            <img class="w-100" src="./../../../assets/images/talentbase/amazon.png" />
          </div>
          <div class="col-12 col-md-6 col-xl-2">
            <img class="w-100" src="./../../../assets/images/talentbase/microsoft.png" />
          </div>
          <div class="col-12 col-md-6 col-xl-2">
            <img class="w-100" src="./../../../assets/images/talentbase/uber.png" />
          </div>
          <div class="col-12 col-md-6 col-xl-2">
            <img class="w-100" src="./../../../assets/images/talentbase/dropbox.png" />
          </div>
          <div class="col-12 col-md-6 col-xl-2">
            <img class="w-100" src="./../../../assets/images/talentbase/uber.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>



<!-- Contact Section Start -->
<section class="contact-top" id="contact" style="background-color: #ffffff">
  <div class="contact-form">
    <div class="container" style="max-width: 800px !important;">
      <div class="col-12 pb-5">
        <h5>{{'contactUs' | translate}}</h5>
        <p style="font-size: 16px; color: #2b3a4b">
         {{'contactUsDesc' | translate}}
        </p>
      </div>
      <div class="row px-0 px-xl-5">
        <div class="col-lg-5">
          <div class="card pt-5 pb-5 shadow-lg" style="border-radius: 20px">
            <div class="card-body">
              <div class="col-md-12">
                <div class="text-center pb-4">
                  <h6 class="mt-2" style="font-size: 19px">{{'contactUs' | translate}}</h6>
                </div>
              </div>
              <div class="col-lg-12">
                <form action="/" class="mb-4 mb-lg-0">
                  <div class="form-row">
                    <div class="col-12 form-group input-radius">
                      <input [(ngModel)]="message.name" class="form-control input-radius input-bg" id="name" name="name"
                        placeholder="Your Name" type="text" />
                    </div>
                  </div>
                  <div class="form-group">
                    <input [(ngModel)]="message.email" class="form-control input-radius input-bg" id="email"
                      name="email" placeholder="Your Email" type="text" />
                  </div>
                  <div class="form-group pt-2">
                    <textarea [(ngModel)]="message.text" class="form-control input-bg" name="message"
                      placeholder="Type Message" rows="3"></textarea>
                  </div>
                  <div class="float-left pt-2">
                    <a (click)="sendMessage()" class="btn btn-border2 cursor-pointer">Send</a>
                    <span class="badge badge-success" id="success-bagde">{{
                      "sentMessageSuccessfully" | translate
                      }}</span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 pt-5 pt-lg-0 pt-xl-0">
          <div class="row">
            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <i class="fas fa-map-marker-alt icon-color social-fs"></i>
              <p class="pb-3" style="color: black; font-size: 14px">
                Levent - ISTANBUL
              </p>
            </div>
            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 pb-sm-3">
              <i class="fas fa-mobile-alt icon-color social-fs"></i>
              <p class="pb-3" style="color: black; font-size: 14px">
                (239) 555-0108
              </p>
            </div>
            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 pb-sm-3">
              <i class="fas fa-envelope-square icon-color social-fs"></i>
              <p class="pb-3" style="color: black; font-size: 14px">
                help@picktus.com
              </p>
            </div>
          </div>

          <div class="map pt-4">
            <iframe allowfullscreen="" height="385px" loading="lazy"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12029.663394914936!2d29.00724229220714!3d41.08175984294976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab66eb1aaff27%3A0x306025824b2de11e!2zTGV2ZW50LCAzNDMzMCBCZcWfaWt0YcWfL8Swc3RhbmJ1bA!5e0!3m2!1sen!2str!4v1639594705338!5m2!1sen!2str"
              style="border: 0" width="100%">
            </iframe>
          </div>
          <div class="pt-3">
            <div class="row">
              <div class="pr-2">
                <li class="list-unstyled" style="margin-top: -4px;">
                  <a class="twitter" href="#"><i class="lni-twitter-filled social-fs icon-color"
                      style="font-size: 35px;"></i></a>
                </li>
              </div>
              <div class="pr-3">
                <li class="list-unstyled">
                  <a class="facebook" href="#"><i class="lni-facebook-filled social-fs icon-color"></i></a>
                </li>
              </div>
              <div class="pr-3">
                <li class="list-unstyled">
                  <a class="linkedin" href="#"><i class="lni-linkedin-fill social-fs icon-color"></i></a>
                </li>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Clients Section -->
<div class="section" id="clients" style="background-color: #ffffff">
  <!-- Container Ends -->
  <div class="container" style="max-width: 800px !important;">
    <!-- Row and Scroller Wrapper Starts -->
    <div class="row">
      <div class="col-12">
        <div class="pb-3">
          <h5 class="text-center" style="font-size: 30px">
            Sign up to our newsletter
          </h5>
          <div class="col-12 d-flex justify-content-center pt-4">
            <div class="row">
              <div class="col-7 pl-0">
                <input [(ngModel)]="email" class="form-control" name="email" placeholder="Enter Your Email"
                  type="email" />
              </div>
              <div class="col-5 pl-0">
                <a (click)="sendMail()" class="btn btn-border2 rounded-0">Subscribe</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Client Section End -->

<footer>
  <!-- Footer Area Start -->
  <section class="footer-Content">
    <div class="container" style="max-width: 800px !important;">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-mb-12">

          <img src="./../../../assets/images/white-logo.png" width="150px" height="20px" />

          <!-- <img src="./../../../assets/images/logo.png" alt=""> -->
          <div class="textwidget mt-3">
            <p>
              {{'footerDesc' | translate}}
            </p>
          </div>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-6 col-xs-6 col-mb-12">
          <div class="widget">
            <h3 class="block-title">Pages</h3>
            <ul class="menu">
              <li><a href="#home">Home</a></li>
              <li><a href="#explore"> How it Works</a></li>
              <li><a href="/pricing">Pricing</a></li>

            </ul>
          </div>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-6 col-xs-6 col-mb-12">
          <div class="widget">
            <ul class="menu">
              <li><a data-content="İçerik Yok" data-toggle="popover" href="#faq">FAQ</a>
              </li>
              <li><a href="#app-features">About Us</a></li>
              <li><a href="#contact">Contact</a></li>
            </ul>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-mb-12">
          <div class="widget">
            <i class="fas fa-map-marker-alt" style="color: #fff"></i>
            <span class="text-white ml-2" style="font-size: 16px">Levent - Mecidiyekoy ISTANBUL </span><br /><br />
            <div class="widget">
              <ul class="menu">
                <i class="fas fa-mobile-alt" style="color: #fff"></i>
                <span class="ml-2 text-white"> (239) 555-0108 </span>
                <br /><br />
                <div class="row m-0">
                  <div class="pr-3">
                    <li class="list-unstyled" style="margin-top: -4px;">
                      <a class="twitter" href="#"><i class="lni-twitter-filled social-fs text-white"
                          style="font-size: 35px;"></i></a>
                    </li>
                  </div>
                  <div class="pr-3">
                    <li class="list-unstyled">
                      <a class="facebook" href="#"><i class="lni-facebook-filled social-fs text-white"></i></a>
                    </li>
                  </div>
                  <div class="pr-3">
                    <li class="list-unstyled">
                      <a class="linkedin" href="#"><i class="lni-linkedin-fill social-fs text-white"></i></a>
                    </li>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Copyright Start  -->
    <div class="copyright">
      <div class="container" style="max-width: 800px !important;">
        <div class="row">
          <div class="col-md-12">
            <div class="site-info float-left">
              <p>Copyright © Picktus - 2021</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Copyright End -->
  </section>
  <!-- Footer area End -->
</footer>

<a class="back-to-top" href="#">
  <i class="lni-chevron-up"></i>
</a>
