import { Injectable }              from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router }                  from '@angular/router';
import { Observable, throwError }  from 'rxjs';
import { catchError, map }         from 'rxjs/operators';
import { TranslatePipe }           from '../../pipes/translate/translate.pipe';
import { NgxSpinnerService }       from 'ngx-spinner';
import { NotificationService }     from '../notification/notification.service';
import { ServerErrorList }         from '../../lists/serverErrorList';
import { server }                  from '../server';

@Injectable({
              providedIn: 'root'
            })
export class AuthService {

  TOKEN_KEY = 'access_token';
  lang = localStorage.getItem('lang');
  headerWithoutToken = new HttpHeaders()
    .set('Accept', 'application/json')
    .set('Cache-Control', ' no-cache')
    .set('Content-Type', 'application/json')
    .set('Accept-Language', this.lang);

  constructor(private http: HttpClient,
              private spinner: NgxSpinnerService,
              private translate: TranslatePipe,
              private notification: NotificationService,
              private router: Router) {
  }

  get myUserId(): string {
    return localStorage.getItem('userId');
  }

  setHeadersWithToken(): HttpHeaders {
    const token = localStorage.getItem(this.TOKEN_KEY);
    let headersWithToken: HttpHeaders;
    headersWithToken = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Cache-Control', ' no-cache')
      .set('Content-Type', 'application/json')
      .set('Accept-Language', this.lang)
      .set('Authorization', 'Bearer ' + token);
    return headersWithToken;
  }

  ServerGet(address: string): Observable<any> {
    this.spinner.show();
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.get(address, options)
               .pipe(
                 map(res => res),
                 catchError((err) => {
                   return this.ServerError(err);
                 }));
  }

  ServerPost(address: string, data: any): Observable<any> {
    this.spinner.show();
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.post(address, data, options)
               .pipe(
                 map(res => res),
                 catchError((err) => {
                   return this.ServerError(err);
                 }));
  }

  ServerPut(address: string, data: any): Observable<any> {
    this.spinner.show();
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.put(address, data, options)
               .pipe(
                 map(res => res),
                 catchError((err) => {
                   return this.ServerError(err);
                 }));
  }

  ServerDelete(address: string): Observable<any> {
    this.spinner.show();
    const headers = this.setHeadersWithToken();
    const options = { headers };
    return this.http.delete(address, options)
               .pipe(
                 map(res => res),
                 catchError((err) => {
                   return this.ServerError(err);
                 }));
  }

  ServerGetWithoutLogin(address: string): Observable<any> {
    this.spinner.show();
    const headers = this.headerWithoutToken;
    const options = { headers };
    return this.http.get(address, options)
               .pipe(
                 map(res => res),
                 catchError((err) => {
                   return this.ServerError(err);
                 }));
  }

  ServerPostWithoutLogin(address: string, data: any): Observable<any> {
    this.spinner.show();
    const headers = this.headerWithoutToken;
    const options = { headers };
    return this.http.post(address, data, options)
               .pipe(
                 map(res => res),
                 catchError((err) => {
                   return this.ServerError(err);
                 }));
  }

  ServerPostWithFormData(address: string, data: any): Observable<any> {
    this.spinner.show();
    const token = localStorage.getItem(this.TOKEN_KEY);
    let headers: HttpHeaders;
    headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Cache-Control', ' no-cache')
      .set('Accept-Language', this.lang)
      .set('Authorization', 'Bearer ' + token);

    return this.http.post(address, data, { headers })
               .pipe(
                 map(res => res),
                 catchError((err) => {
                   return this.ServerError(err);
                 }));
  }

  ServerSignUp(address: string, data: any): Observable<any> {
    this.spinner.show();
    const token = localStorage.getItem(this.TOKEN_KEY);
    let headers: HttpHeaders;
    headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Cache-Control', ' no-cache');

    return this.http.post(address, data, { headers })
               .pipe(
                 map(res => res),
                 catchError((err) => {
                   return this.ServerError(err);
                 }));
  }

  ServerPutWithFormData(address: string, data: any): Observable<any> {
    this.spinner.show();
    const token = localStorage.getItem(this.TOKEN_KEY);
    let headers: HttpHeaders;
    headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Cache-Control', ' no-cache')
      .set('Accept-Language', this.lang)
      .set('Authorization', 'Bearer ' + token);

    return this.http.put(address, data, { headers })
               .pipe(
                 map(res => res),
                 catchError((err) => {
                   return this.ServerError(err);
                 }));
  }

  ClearSession(): void {
    this.spinner.show();
    const tempLang = localStorage.getItem('lang');
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem('lang', tempLang);
    this.router.navigate(['/'])
        .then(r => {
          this.spinner.hide();
        });
  }

  getSessionLanguage(): string {
    if (!localStorage.getItem('lang')) {
      return JSON.parse(window.atob((this.getToken()).split('.')[1])).preferred_language;
    }
    return localStorage.getItem('lang');
  }

  setLanguage(lang): void {
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', 'en');
      window.location.reload();
    } else {
      if (lang.trim() !== '') {
        localStorage.setItem('lang', lang);
        window.location.reload();
      } else if (this.getToken()) {
        const sessionLanguage = this.getSessionLanguage();
        localStorage.setItem('lang', sessionLanguage);
      }
    }
  }

  getToken(): string {
    return localStorage.getItem(this.TOKEN_KEY);
  }

  ServerLogout(): void {
    // this.stopRefreshTokenTimer();
    this.ClearSession();
  }

  ServerError(err: any): any {
    this.spinner.hide();
    const error = err.error;
    let throwErrorCode = 'GENERAL_ERROR';

    if (err.status === 401) {
      // if(err.message.includes('Invalid refresh token')){
      //   this.ServerLogout();
      // }
      // else{
      //   this.refreshToken().subscribe(x=>{

      //   }, error=>{

      //     this.ServerLogout();

      //   });
      // }

      // this.ServerLogout();
    } else {
      if (error.code && ServerErrorList[error.code]) {
        throwErrorCode = this.checkSubErrors(error);
        if (throwErrorCode) {
          error.message = throwErrorCode;
          return throwError(err.error);
        }

        throwErrorCode = this.translate.transform(ServerErrorList[error.code], []);
        error.message = throwErrorCode;
        return throwError(err.error);
      }

      error.message = throwError;
      return throwError(err.error);
    }

  }

  refreshToken(): Observable<any> {
    let body = new URLSearchParams();
    body.set('refresh_token', localStorage.getItem('refresh_token'));
    body.set('grant_type', 'refresh_token');
    const token = localStorage.getItem(this.TOKEN_KEY);
    let headers: HttpHeaders;
    headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Cache-Control', ' no-cache')
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Accept-Language', this.lang)
      .set('Authorization', 'Bearer ' + token);

    return this.http.post(server.uaaUrl + '/oauth/token', body.toString(), { headers })
               .pipe(map((user) => {

               }), catchError((err) => {
                 this.ServerLogout();
                 return this.ServerError(err);

               }));
  }

  checkSubErrors(error) {
    let message: string;
    if (error.subErrors) {
      const subCodes = error.subErrors.map(item => item.code);
      const subCodesCleanDublicate = Array.from(new Set(subCodes));
      subCodesCleanDublicate.forEach((item: any) => {
        if (ServerErrorList[item]) {
          if (!message) {
            message = this.translate.transform(ServerErrorList[item], []);
          } else {
            message += ', ' + this.translate.transform(ServerErrorList[item], []);
          }
        }
      });
      return message;
    } else {
      return null;
    }

  }

  parseToken(token: string) {
    return JSON.parse(atob(token.split('.')[1]));
  }

  checkIsThereTokenOnTheLocalStorage(): boolean {
    let token = localStorage.getItem('access_token');
    if (token != null) {
      return true;
    }
    return false;
  }

  checkIsThereTokenOnTheLocalStorageAndItIsNotExpired(): boolean {
    if (this.checkIsThereTokenOnTheLocalStorage() != null) {
      let expireDate = new Date();
      let expiresAt = localStorage.getItem('expires_at');
      let currentDate = new Date();
      expireDate.setTime(parseInt(expiresAt));
      return expireDate.getTime() > currentDate.getTime();
    }
    return false;
  }

  //   refreshToken() {
  //     let body = new URLSearchParams();
  //     body.set('refresh_token', localStorage.getItem('refresh_token'));
  //     body.set('grant_type', 'refresh_token');
  //     let options = {
  //       headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  //   };
  //     return this.http.post<any>(server.uaaUrl + '/oauth/token', body.toString(), options)
  //         .pipe(map((user) => {
  //             this.startRefreshTokenTimer();

  //             return user;
  //         }));
  // }

  //   private refreshTokenTimeout;

  //   public startRefreshTokenTimer() {

  //       const expires = new Date(+localStorage.getItem('expires_at') * 1000);
  //       const timeout = expires.getTime() - Date.now() - (60 * 1000);
  //       this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  //   }

  //   public stopRefreshTokenTimer() {
  //       clearTimeout(this.refreshTokenTimeout);
  //   }
  getUserPermissionsFromEndpoint() {
    this.ServerGet(server.functionalRolesMine)
        .subscribe((res: any[]) => {
          const perm = res;
          perm.push(JSON.parse(window.atob((this.getToken()).split('.')[1])).authorities[0]);

          localStorage.setItem('functionalRolesMine', JSON.stringify(res));
        }, err => {
          localStorage.setItem('functionalRolesMine', JSON.stringify([]));
          if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
            this.notification.error(err.message);
            this.spinner.hide();
            window.location.reload();
          }
        }, () => {
          window.location.reload();
        });
  }
}

