<div class="pl-5 pt-5">
  <h4>{{'browseJobs' | translate}}</h4>
</div>

<div class="container-fluid p-0 m-0 ">
  <div class="mt-2">

    <div class="row mt-4 pl-5">
      <div class="col-12 overflow-auto hide-scroll">
        <span (click)="activeTab=0"
              [ngClass]="activeTab==0?'active':''"
              class="tab-span">{{'searchJobs'|translate|titlecase}}</span>
        <span (click)="activeTab=1"
              [ngClass]="activeTab==1?'active':''"
              class="tab-span">{{'jobsApplied'|translate|titlecase}}</span>
        <span (click)="activeTab=2"
              [ngClass]="activeTab==2?'active':''"
              class="tab-span">{{'jobsInvited'|translate|titlecase}}</span>
      </div>
    </div>
    <hr class="hr-style">

    <div *ngIf="activeTab==0"
         class="row">
      <div class="col-12">
        <app-search-jobs></app-search-jobs>
      </div>
    </div>

    <div *ngIf="activeTab==1"
         class="row">
      <div class="col-12">
        <app-jobs-applied></app-jobs-applied>
      </div>
    </div>

    <div *ngIf="activeTab==2"
         class="row">
      <div class="col-12">
        <app-jobs-invited></app-jobs-invited>
        <tlb-empty-page *ngIf="false"
                        [subText]="false"
                        [warningLabel]="'youHasNotBeenInvitedAnyJobYet' | translate"></tlb-empty-page>
      </div>
    </div>

  </div>
</div>
