import { JobRelocationAssistanceModel } from './job-relocation-assistance.model';
import { JobLocationModel } from './job-location.model';
import { JobExperienceRequiredModel } from './job-experience-required.model';
export class JobProfileModel {

  createdAt?: Date;
  createdBy?: any;
  updatedAt?: Date;
  updatedBy?: any;
  id?: string;
  jobSegment: any;
  employer: any;
  title: string;
  description: string;
  classification: any;
  function: any;
  industry: any;
  experienceLevel: string;
  educationLevel: string;
  type: string;
  experienceRequired: JobExperienceRequiredModel;
  relocationAssistance: JobRelocationAssistanceModel;
  location: JobLocationModel;
  skills: string[];
  tags: any[];

  constructor() {
    this.createdAt = null;
    this.createdBy = null;
    this.updatedAt = null;
    this.updatedBy = null;
    this.id = null;
    this.jobSegment = null;
    this.employer = null;
    this.classification = null;
    this.function = null;
    this.industry = null;
    this.title = "";
    this.description = "";
    this.experienceLevel = "";
    this.educationLevel = "";
    this.type = "";
    this.experienceRequired = new JobExperienceRequiredModel();
    this.relocationAssistance = new JobRelocationAssistanceModel();
    this.location = new JobLocationModel();
    this.skills = [];
    this.tags = [];

  }
}
