<div class="container-fluid" >
  <div class="row" style="margin-top: 10px;">
    <div class="col-12 pr-1 pl-0 pb-5">
      <div class="card warning-background pb-5">
        <div class="card-body  text-and-icon">

          <div class="card-text">
            <img src="./assets/images/talentbase/empty-content2.svg"/> <br> <br>
            <!-- <span style="text-align: center;"> {{warningLabel}}</span> -->
            <span class="text-center empty-title"> {{ 'emptyPageTitle' | translate }}</span>
            <p class="empty-desc">{{ 'emptyPageDescription2' | translate }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
