import { GeneralIdNameModel } from "../general";

export class NewsModel {

  id: string;
  status: string;
  createdAt: string;
  createdBy: GeneralIdNameModel;
  updatedAt: string;
  updatedBy: GeneralIdNameModel;
  title: string;
  content: string;

  date: string;

  constructor() {
    this.id = '';
    this.status = '';
    this.createdAt = '';
    this.createdBy = new GeneralIdNameModel();
    this.updatedAt = '';
    this.updatedBy = new GeneralIdNameModel();
    this.title = '';
    this.content = "";
    this.date = "";
  }
}
