import { ReviewService } from './../../../shared/services/recruiter/review.service';
import { RecruiterUserModel } from './../../../shared/models/recruiter/recruiter-user.model';
import { Component, Input, OnInit } from '@angular/core';
import { RecruiterReviewModel } from 'src/app/shared/models';
import { CreateAvatar, RequestPayload } from 'src/app/shared/classes';
import { TranslatePipe } from 'src/app/shared/services';

@Component({
  selector: 'app-recruiters-detail-modal',
  templateUrl: './recruiters-detail-modal.component.html',
  styleUrls: ['./recruiters-detail-modal.component.scss']
})
export class RecruitersDetailModalComponent implements OnInit {


  @Input() recruiter: RecruiterUserModel = null;
  reviews: RecruiterReviewModel[] = [];
  @Input() cameFromReviews: boolean = false;
  createAvatar: CreateAvatar;
  requestPayload: RequestPayload;
  currentPage: number;
  count: number;
  page: number = 1;

  constructor( private reviewService: ReviewService, private translate: TranslatePipe) {
    this.requestPayload = new RequestPayload();
    this.requestPayload.limit = 3;
    this.createAvatar = new CreateAvatar();
  }

  ngOnInit(): void {
  }

  ngOnChanges(){
    if(this.recruiter && this.recruiter.id){
      this.recruiter.functionalRolesText = this.recruiter.functionalRoles.map(m => ' ' + m.name).toString();
      this.recruiter.industriesText = this.recruiter.industries.map(m => ' ' + m.name).toString();
      this.recruiter.requisitionTypesText = this.recruiter.requisitionTypes.map(m => ' ' + this.translate.transform(m + 'REC')).toString();
          this.getReviews(1);
    }

  }

  getReviews(pageNo){
       this.page = pageNo;
    this.reviewService.ServerGetWithText(this.requestPayload.payloadURLWithOutDelete(pageNo) + "&recruiterId=" + this.recruiter.id).subscribe(x => {
      this.reviews = x.content;
      this.count = x.totalElements;
      this.reviews.forEach(f => {
        f.imageText = this.createAvatar.create(f.fullName);
        f.imageColor = this.createAvatar.color();
      });

    });
  }
}
