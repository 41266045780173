<div class="card general-card">

  <div class="top-page">
    <span class="header ml-4">
      {{ 'jobProfiles' | translate }}
    </span>

    <div class="row ml-2 mb-4">
      <div class="col-4">
        <div class="col-12 pr-1 pl-0 filter-div">
          <div class="row">
            <div class="col-10 mt-1">
              <input [(ngModel)]="filterText" #searchInput name="skills" class="form-control filter" id="skill"
              type="text" placeholder="{{'searchForName' | translate}}"
              (keyup)="keyup(searchInput.value)">
            </div>
            <div (click)="search()" class="col-2 mt-2 cursor-pointer"><i class="fa fa-search"></i></div>

          </div>
        </div>

        <label *ngIf="filterText != ''" class="clear-search mt-2" for="" (click)="clearSearch()"><i class="fas fa-times mr-1"
         style="color: #707683;" ></i> {{'clearSearch' | translate}}  </label>
      </div>


      <div class="col-6 ml-auto">
        <div class="row mr-5" style="float: right;">
          <div class="mt-2" (click)="openDetail(null)">
            <i class="fas fa-plus"></i> <span class="create-text mr-2"> {{ 'createNewProfile' | translate }} </span>
          </div>
        </div>
      </div>

    </div>

  </div>

  <div class="card-body card-pad">

    <tlb-empty-page *ngIf="!jobs || jobs.length == 0" [warningLabel]="filterText == ''  ? 'noJobProfileYet' : filterText" [filterText]="filterText"></tlb-empty-page>


      <div *ngFor="let job of jobs" class=" card mt-1 mb-3 profile-cards">
    <div class="card-body ">
      <div class="container-fluid">
        <!-- <div class="row"> -->
        <div class="row">
          <div class="title-text">{{job.title}}</div>
          <div class="ml-3" *ngFor="let tag of job.tags">
            <span class="badge badge-orange"><i class="fas fa-atom mr-1"></i>{{tag.value}}</span>
          </div>

          <span *ngIf="!job.tags || job.tags.length == 0" class="badge badge-orange ml-3"><i class="fas fa-atom mr-1"></i>{{job.industry.name}}</span>
        </div>


        <!-- </div> -->
        <div class="row mt-3">

          <div class="col-9">
            <div class="row">

              <span *ngIf="job.employer" class="col-4 info-text"><i class="fas fa-briefcase"></i>
                {{job.employer?.name}}</span>
              <span *ngIf="!job.employer" class="col-4 info-text"><i class="fas fa-user-cog"></i>
                {{'createdByAdmin' | translate}}</span>
              <span class="col-4 info-text"><i class="fas fa-map-marker-alt"></i> {{job.location?.city?.name}},
                {{job.location?.country?.name}}</span>
              <span class="col-4 info-text"><i class="far fa-calendar"></i> {{job.createdAt | date}}</span>
            </div>
          </div>


          <div class="col-1">
            <button class="button" style="float: right;" (click)="openDeletePopup(job)">{{ 'delete' | translate
              }}</button>
          </div>
          <div class="col-2">
            <button class="button" style="float: right;" (click)="openDetail(job)">{{ 'viewDetails' | translate
              }}</button>
          </div>

        </div>
      </div>

    </div>
  </div>
  </div>

</div>


<general-delete-modal [Title]="'deleteJobProfile'" [Text]="'theJobProfileWillBeRemovedFromYourListAreYouSure'" (selection)="deleteJob($event)"></general-delete-modal>

<app-admin-job-profile-detail [selectedJobProfile]="selectedJob" (savedJobProfiled)="getJobs()">
</app-admin-job-profile-detail>
