import {AnswerModel} from './answer.model';
import {CandidateModel} from './candidate.model';
import {GeneralIdNameModel} from '../general';
import {ApplicationStatus} from '../../enums/application/application-status.enum';

export class ApplicationModel{
  id: string;
  candidate: CandidateModel;
  createdAt: string;
  createdBy: GeneralIdNameModel;
  updatedAt: string;
  updatedBy: GeneralIdNameModel;
  employer: GeneralIdNameModel;
  job: GeneralIdNameModel;
  status: string;
  date: string;
  recruiterId: string;
  answers:AnswerModel;
  location: any;
  jobType: string;
  imageText: string;
  imageColor: string;
  selectableApplicationStatuses: ApplicationStatus[] = [];
  openedCandidateDetail: boolean;
  notes: any[] = [];
}

