<!-- <div class="modal fade bd-example-modal-xl" id="candidateReviewModal" tabindex="-1" role="dialog"
       aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content p-2">
      <div class="modal-body" > -->
        <div class="container-fluid px-5 px-sm-5 pt-5 h-100">
          <!-- <div class="card"> -->
            <div class="row h-100">
              <div class="col-12 col-sm-9 bg-light mx-auto my-auto shadow pt-5">
                  <div class="container">
                    <div class=" pb-3">


                      <div class="row">
                      <div class="col-12">
                        <div class="row justify-content-center p-5" style="background-color: #10163A;">
                          <img src="../../../assets/images/logo.png" width="160" />
                          <!-- <img src="../../../assets/images/small-logo.png" alt="" style="margin-top: -2px;"> <span class="logoName ml-2">{{'logoName' | translate}}</span> -->
                      </div>
                    </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 pt-5 text-center">
                      <h6 class="font-weight-bold pt-5" style="color: #25C771;">{{ 'thankYou' | translate }}</h6>
                    </div>
                  </div>

                  <div class="row pb-5">
                    <div class="col-12 pt-2 pb-5 text-center font-size">
                      <p>We have successfully saved your request.</p>
                    </div>
                  </div>
        </div>
      </div>
    <!-- </div>
  </div>
</div> -->

