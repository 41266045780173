export class RecruiterSpeacializationModel {

  functionalRoles: any[];
  industries: any[];

  constructor() {
  this.functionalRoles = [];
  this.industries = [];
  }
  }
