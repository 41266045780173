import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup }                                                          from '@angular/forms';
import { BehaviorSubject }                                                    from 'rxjs';
import { TlbFormModel }                                                       from '../../models';
import { CountryService }                                                     from '../../services';

@Component({
             selector: 'tlb-form',
             templateUrl: './tlb-form.component.html',
             styleUrls: ['./tlb-form.component.scss'],
             encapsulation: ViewEncapsulation.None
           })
export class TlbFormComponent implements OnInit {

  @Input() forms: TlbFormModel[] = [];
  @Input() formGroup: FormGroup = null;
  @Input() saveButtonActive: boolean = false;
  @Input() cancelButtonActive: boolean = false;
  @Input() saveButtonLabel: string = 'Save';
  phoneNumberCodesOptions: any[] = [];
  ready: boolean = false;
  dropdownSettings = {};
  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;

  constructor(private countryService: CountryService) {
  }

  private _isLoading$ = new BehaviorSubject<boolean>(true);

  get isLoading$() {
    return this._isLoading$.asObservable();
  }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 4,
      allowSearchFilter: false
    };
  }

  ngOnChanges() {

    this.forms.forEach(f => {
      if (f.components.some(x => x.type == 'phoneNumber')) {
        this.countryService.ServerGetWithoutLogin()
            .subscribe(x => {
              let countries = x.content;
              countries.forEach(x => {
                this.phoneNumberCodesOptions.push(
                  { label: x.callingCode + ' (' + x.defaultLocalization.name + ')', value: x.callingCode });
              });

              this.ready = true;

            });
      } else {
        this.ready = true;
      }
    });
  }

  choosePhoto(comp, index, control) {

    const reader = new FileReader();
    const fileUpload = this.fileUpload.nativeElement;

    fileUpload.onchange = (test) => {

      this._isLoading$.next(false);
      const file = fileUpload.files[0];
      this.forms[index].components.filter(x => x == comp)[0].name = file.name;
      this.formGroup.controls[control].setValue(file);
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        setTimeout(() => {

          this.forms[index].components.filter(x => x == comp)[0].imageUrl = event.target.result;

          this._isLoading$.next(true);
        });

      };

    };
    fileUpload.click();

  }

  arrayText(control) {
    this.formGroup.controls[control].setValue(this.formGroup.controls[control].value + ',');
  }

  keyDownHandler(event) {
    if (event.keyCode === 32)
        event.preventDefault();
}

customNumericText(event, min, control) {
  if(min > 0 && (event.keyCode === 48 || event.keyCode === 96) && (this.formGroup.controls[control]?.value == null || this.formGroup.controls[control]?.value?.length ==0)) event.preventDefault();
  if (event.keyCode === 189 || event.keyCode === 69 || event.keyCode === 96 || event.keyCode === 45 || event.keyCode === 32 || event.key == "-" || event.key == "*"
  || event.key == "." || event.key == ",")
      event.preventDefault();
  if(event.keyCode >= 65 && event.keyCode <= 90 ) event.preventDefault();
}

ibanChars(event) {
  if ((event.keyCode >= 48 && event.keyCode < 91) || (event.keyCode >= 96 && event.keyCode <= 105) || event.keyCode == 8) {}
  else event.preventDefault();
}

phoneNumberChars(event) {
  if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) || event.keyCode == 8) {}
  else event.preventDefault();
}
}
