import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RecruiterReviewPostModel } from 'src/app/shared/models';
import { ApplicationService, NotificationService, RecruiterService, ReviewService, server, TranslatePipe } from 'src/app/shared/services';

@Component({
  selector: 'app-candidate-review-screen',
  templateUrl: './candidate-review-screen.component.html',
  styleUrls: ['./candidate-review-screen.component.scss']
})
export class CandidateReviewScreenComponent implements OnInit {

  applicationId: string = "";
  recruiter: any;
  isCandidate: boolean = false;
  comment: string;
  star: string;
  token: string;
  postModel: RecruiterReviewPostModel = new RecruiterReviewPostModel();

  constructor(private route: ActivatedRoute, private translate: TranslatePipe, private applicationService: ApplicationService,
    private reviewService: ReviewService, private notification: NotificationService, private router: Router, private recruiterService: RecruiterService) { }

  ngOnInit() {

    // @ts-ignore
    $('body').css('background-color', '#F5F4FE');
    // @ts-ignore
    $('body').css('background-image', 'none');
    // @ts-ignore
    $('body').css('height', '100%');
    // @ts-ignore
    $('html').css('height', '100%');


    this.applicationId = this.route.snapshot.paramMap.get("id");

    this.route.queryParams.subscribe(params => {
      if (params.token) {
        this.token = params.token;
        this.isCandidate = true;
        this.applicationService.ServerGetWithoutLoginWithText("/" + this.applicationId + "/?token=" + params.token)
          .subscribe(app => {

            this.recruiterService.ServerGetWithoutLoginWithText("/" + app.recruiterId + "/?token=" + params.token)
              .subscribe(rec => {
                this.recruiter = rec;
                this.recruiter.functionalRolesText = this.recruiter.functionalRoles.map(m => ' ' + m.name).toString();
                this.recruiter.industriesText = this.recruiter.industries.map(m => ' ' + m.name).toString();
                this.recruiter.requisitionTypesText = this.recruiter.requisitionTypes.map(m => ' ' + this.translate.transform(m + 'REC')).toString();

              });
          });
      }

      else {
        this.isCandidate = false;
        this.router.navigate(["/dashboard/applications/" + this.applicationId + "/review"]);
        this.applicationService.ServerGetWithText("/" + this.applicationId)
          .subscribe(app => {
            this.recruiterService.ServerGetWithText("/" + app.recruiterId)
              .subscribe(rec => {
                this.recruiter = rec;
                this.recruiter.functionalRolesText = this.recruiter.functionalRoles.map(m => ' ' + m.name).toString();
                this.recruiter.industriesText = this.recruiter.industries.map(m => ' ' + m.name).toString();
                this.recruiter.requisitionTypesText = this.recruiter.requisitionTypes.map(m => ' ' + this.translate.transform(m + 'REC')).toString();

              });

          });
      }
    });
  }

  postReview() {
    if (this.isCandidate) {
      let model: RecruiterReviewPostModel = {
        recruiterId: this.recruiter.id,
        applicationId: this.applicationId,
        comment: this.comment,
        rating: this.postModel.rating,
        communication: this.postModel.communication,
        expertisement: this.postModel.expertisement,
        followUp: this.postModel.followUp,
        industryKnowledge: this.postModel.industryKnowledge,
        informativity: this.postModel.informativity
      }

      this.reviewService.ServerPostWithoutLogin(server.reviews + "/?token=" + this.token, model)
        .subscribe(rec => {
          this.notification.success(this.translate.transform('yourReviewHasBeenSentSuccessfully'));
          this.router.navigate(['/successfully-saved']);
        });
    }

    else {
      let model: RecruiterReviewPostModel = {
        recruiterId: this.recruiter.id,
        applicationId: this.applicationId,
        comment: this.comment,
        rating: this.postModel.rating,
        communication: this.postModel.communication,
        expertisement: this.postModel.expertisement,
        followUp: this.postModel.followUp,
        industryKnowledge: this.postModel.industryKnowledge,
        informativity: this.postModel.informativity
      }

      this.reviewService.ServerPost(model)
        .subscribe(rec => {
          this.notification.success(this.translate.transform('yourReviewHasBeenSentSuccessfully'));
          this.router.navigate(['/successfully-saved']);
        });
    }
  }

}
