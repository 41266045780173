import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-support-page',
  templateUrl: './support-page.component.html',
  styleUrls: ['./support-page.component.scss']
})
export class SupportPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  exactalent() {
    window.open(
      '#', '_blank');
  }

  recruiting() {
    window.open(
      '#', '_blank');
  }

  oracle() {
    window.open(
      '#', '_blank');
  }

  smartRecruiters() {
    window.open(
      '#', '_blank');
  }

  codility() {
    window.open(
      '#', '_blank');
  }

  hackerRank() {
    window.open(
      '#', '_blank');
  }

  imocha() {
    window.open(
      '#', '_blank');
  }

  merger() {
    window.open(
      '#', '_blank');
  }

  criteria() {
    window.open(
      '#', '_blank');
  }
}
