import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../../services/translate/translate.service';

@Pipe({
  name: 'translate',
  pure: false
})
export class TranslatePipe implements PipeTransform {

  // tslint:disable-next-line:variable-name
  constructor(private _translate: TranslateService) {
  }

  transform(value: any, args?: any): any {


    if (this._translate.currentLang == null ||localStorage.getItem('lang')==null ) {
      localStorage.setItem('lang', 'en');
      this._translate.setCurrentLang('en');
      window.location.reload();
    }
    if (!value) {
      return;
    }
    return this._translate.instant(value);
  }

}
