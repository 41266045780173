import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {CandidatePostModel, CoverLetterModel, DropdownOptionsModel, PhoneNumberModel, ResumeModel} from 'src/app/shared/models';
import {CandidateService, CountryService, NotificationService, RecruiterSkillTagsService, server, TranslatePipe} from 'src/app/shared/services';

@Component({
  selector: 'app-add-new-candidate',
  templateUrl: './add-new-candidate.component.html',
  styleUrls: ['./add-new-candidate.component.scss']
})
export class AddNewCandidateComponent implements OnInit {

  candidate: CandidatePostModel;
  phoneNumberCodesOptions: DropdownOptionsModel[] = [];
  resume: any;
  coverLetter: any;
  resumeLabel = '';
  coverLetterLabel = '';
  @ViewChild('coverLetter', {static: false}) coverLetterFile: ElementRef;
  @ViewChild('resume', {static: false}) resumeFile: ElementRef;
  coverLetterChoosed: boolean = false;
  isEdited: boolean = false;
  resumeChoosed: boolean = false;
  tooltip: string = this.translate.transform("pleaseSelect");
  skillTags: any[] = [];
  skillsOptions: any[] = [];
  skillTagText: string = "";

  constructor(private countryService: CountryService, private candidateService: CandidateService, private router: Router,
              private notification: NotificationService, private translate: TranslatePipe, private recruiterSkillTagsService: RecruiterSkillTagsService) {
  }

  ngOnInit(): void {


    this.candidate = new CandidatePostModel();
    this.candidate.coverLetter = new CoverLetterModel();
    this.candidate.phoneNumber = new PhoneNumberModel();
    this.candidate.resume = new ResumeModel();
    this.countryService.ServerGetWithoutLogin().subscribe(x => {
      this.phoneNumberCodesOptions = [];
      x.content.forEach(x => {
        this.phoneNumberCodesOptions.push({label: x.callingCode + ' (' + x.defaultLocalization.name + ')', value: x.callingCode});
      });

    });

    this.getSkills('');


  }

  numbersOnly() {
    this.candidate.phoneNumber.phoneNumber = this.candidate.phoneNumber.phoneNumber.replace(/[^0-9.-]/g, '');
  }

  chooseCoverLetter(event: any) {
    const file = event.target.files[0];
    const url = window.URL.createObjectURL(file);
    this.candidate.coverLetter.url = url;
    this.coverLetter = file;
    this.coverLetterLabel = file.name;

    this.coverLetterChoosed = true;
    this.isEdited = true;
  }


  chooseResume(event: any) {
    const file = event.target.files[0];
    const url = window.URL.createObjectURL(file);
    this.candidate.resume.title = file.name;
    this.candidate.resume.url = url;

    this.resume = file;
    this.resumeLabel = file.name;
    this.resumeChoosed = true;
    this.isEdited = true;

  }

  postCandidate() {
    let array = [];
    if(this.skillTags && this.skillTags.length > 0){
      this.skillTags.forEach(x=>{
        array.push({skillTagId: x.id, name: x.name});
      });

      this.candidate.skillTags = array;
    }

    const formData: any = new FormData();
    const candidate = new Blob([JSON.stringify(this.candidate)], {type: 'application/json'});
    formData.append('candidate', candidate);
    formData.append('resume', this.resume);
    formData.append('coverLetter', this.coverLetter);
    this.resumeChoosed ? formData.append('resume', this.resume) : '';
    this.coverLetterChoosed ? formData.append('coverLetter', this.coverLetter) : '';

    this.candidateService.ServerPostWithFormData(server.candidate, formData)
      .subscribe(res => {
        this.notification.success(this.translate.transform('newCandidateSuccessfullyAdded'));
        this.router.navigate(['/candidate/track-candidate']);
      });

  }


  openResume() {
    window.open(this.candidate.resume.url);
  }

  openCoverLetter() {
    window.open(this.candidate.coverLetter.url);
  }

  selectNumberCode(data){
    let array = data.split(':');
    this.tooltip = this.phoneNumberCodesOptions[array[0]].label;
    //@ts-ignore
    $('#tooltips').tooltip('hide')

  }

  getSkills(data){

    this.skillsOptions = [];
    if (data.length > 2) {

      this.recruiterSkillTagsService.ServerGetWithText('?/filter=status!:DELETED&filter=name~~' + data)
        .subscribe(x => {

          this.skillsOptions = x.content.map(c => ({value: c, label: c.name}));
        });
      // @ts-ignore
      $('.dropdown-toggles').dropdown('show');
    } else {
      this.skillsOptions = [];
      // @ts-ignore
      $('.dropdown-toggles').dropdown('hide');
    }

  }

  selectSkill(event, data){
    this.skillTags.push(data.value);
    this.skillTagText = '';
    this.getSkills('');
  }

  deleteTag(tag){
    const index: number = this.skillTags.indexOf(tag);
    if (index !== -1) {
      this.skillTags.splice(index, 1);
    }
  }

  addSkill(data){
    let model = {
      name: data
    };

    this.recruiterSkillTagsService.ServerPost(model).subscribe(x=>{
      this.skillTags.push(x);
      this.skillTagText = '';
      this.getSkills('');
    });

  }

  keyDownHandlerEmail(event){
      if (event.keyCode === 32)
          event.preventDefault();

  }

  phoneNumberChars(event) {
    if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) || event.keyCode == 8) {}
    else event.preventDefault();
  }

  disableControl(): boolean {
    return this.candidate.email == '' || this.candidate.firstName == '' || this.candidate.lastName == '' || !this.candidate.email.includes('@') ||
    this.candidate.phoneNumber.countryCallingCode == '' || this.candidate.phoneNumber.phoneNumber == '' || this.candidate.phoneNumber.phoneNumber.length < 7;
  }

  backToPage(){
    this.router.navigate(['/candidate/track-candidate'])
  }


}
