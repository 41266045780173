import {environment} from 'src/environments/environment';

let serverAddress: string;
serverAddress = environment.baseUrl;

const parameterUrl = serverAddress + '/parameter/v1';
const uaaUrl = serverAddress + '/uaa/v1';
const recruiterUrl = serverAddress + '/recruiter/v1';
const jobUrl = serverAddress + '/job/v1';
const candidateUrl = serverAddress + '/candidate/v1';
const searchUrl = serverAddress + '/search/v1';
const messageUrl = serverAddress + '/message/v1';
const newsUrl = serverAddress + '/news/v1';
const notificationUrl = serverAddress + '/notification/v1';
const supportUrl = serverAddress + '/support/v1';
const employerUrl = serverAddress + '/employer/v1';
const subscriptionUrl = serverAddress + '/subscription/v1';
const userUrl = serverAddress + '/user/v1';


export const server = {
  signUp: uaaUrl + '/sign-up',
  uaaUrl: uaaUrl,

  // parameters
  industries: parameterUrl + '/industries',  // industry-controller
  currencies: parameterUrl + '/currencies',  // currency-controller
  languages: parameterUrl + '/languages',    // language-controller
  countries: parameterUrl + '/countries',   // location-controller
  cities: '/cities',
  jobFunction: parameterUrl + '/job-functions',   // job-function controller
  jobClassification: parameterUrl + '/job-classifications',  // job-classification controller
  visa: parameterUrl + '/visas',    // visa controller
  functionalRoles: parameterUrl + '/functional-roles',    // functional-role controller
  jobMarket: parameterUrl + '/job-markets',    // job-market controller


  // job
  jobs: jobUrl + '/jobs',
  jobCounts: jobUrl + '/job-counts',
  jobGeneralInfos: '/general-infos',
  jobExperiences: '/experiences',
  jobLocations: '/locations',
  jobCompensationsAndFees: '/compensations-and-fees',
  jobPreQuestions: '/pre-questions',
  jobNotificationsAndCorporates: '/notifications-and-corporates',
  jobTeamMembers: '/team-members',
  jobInvitation: '/invitation',
  jobRestriction: '/restriction',

  //job segment
  jobSegment: jobUrl + '/job-segments',
  jobSegmentTag: jobUrl + '/job-segment-tags',

  // job-profiles
  jobProfiles: jobUrl + '/job-profiles',
  jobProfileForCreateJob: '/job-profiles',

  // candidate
  candidate: candidateUrl + '/candidates',
  submitApplication: candidateUrl + '/submit-application',
  application: candidateUrl + '/applications',
  candidateCounts: candidateUrl + '/candidate-counts',
  applicationCounts: candidateUrl + '/application-counts',

  // user
  user: uaaUrl + '/users',
  totalCount: uaaUrl + '/landing-pages' + '/total-counts',
  usersMe: uaaUrl + '/users' + '/me',
  password: uaaUrl + '/users' + '/me' + '/password',
  mailVerify: uaaUrl + '/users' + '/me' + '/email' + '/verify',
  resetPassword: uaaUrl + '/password-reset-requests',
  resetPasswordPost: uaaUrl + '/users' + '/me' + '/password' + '/reset',
  userCounts: uaaUrl + '/user-counts',

  //message
  conversations: messageUrl + '/conversations',

  //notification
  notifications: notificationUrl + '/notifications',

  //---employer
  employer: employerUrl + '/employers',
  employerme: employerUrl + '/employers/me',
  employerCounts: employerUrl + '/employer-counts',

  //---recruiter
  recruiter: recruiterUrl + '/recruiters',
  recruiterMe: recruiterUrl + '/recruiters/me',
  reviews: recruiterUrl + '/reviews',
  recruiterCounts: recruiterUrl + '/recruiter-counts',
  credits: recruiterUrl + '/credits',
  earnings: recruiterUrl + '/earnings',
  skillTags: recruiterUrl + '/skill-tags',

  //top recruiters
  topRecruiters: recruiterUrl + '/recruiters/top-recruiters',

  //global-search
  globalSearch: searchUrl + '/global-search',

  //news
  news: newsUrl + '/news',
  newsLetter: newsUrl + '/news-letters',

  // purchase-market
  marketPlace: uaaUrl + '/marketplace',
  marketplaceGroups: uaaUrl + '/marketplace-groups',

  //FAQ
  faqCategories: supportUrl + '/faq-categories',
  faqItems: supportUrl + '/faq-items',

  //help center
  contactRequests: supportUrl + '/contact-requests',

  //subscription
  packages: subscriptionUrl + '/packages',
  subscriptionsMinePackage: subscriptionUrl + '/subscriptions/mine/package',
  subscriptionsMine: subscriptionUrl + '/subscriptions/mine',
  subscriptionsMineIyzicoCheckoutForm: subscriptionUrl + '/subscriptions/mine/iyzico/checkout-form',
  subscriptionsPaymentStatus: subscriptionUrl + '/subscriptions/mine/iyzico/payment-status',
  functionalRolesMine: subscriptionUrl + '/functional-roles/mine',
  packageFunctionalRoles: subscriptionUrl + '/functional-roles',
  packageBusinessRoles: subscriptionUrl + '/business-functions',


};
