import { Component, OnInit }                                                                                                                       from '@angular/core';
import { CandidateCountsModel, EmployerCountsModel, JobCountsModel, NewsModel, RecruiterCountsModel, UserCountsModel }                             from 'src/app/shared/models';
import { CandidateCountService, EmployerCountService, JobsCountService, NewsService, RecruiterCountService, RecruiterMeService, UserCountService } from 'src/app/shared/services';
import { QuarterLineCartModel }                                                                                                                    from '../../shared/models/dashboard/quarter-line-cart.model';
import { ProfileEnrichmentService }                                                                                                                from '../../shared/services/user/profile-enrichment-service';
import { Router }                                                                                                                                  from '@angular/router';

@Component({
             selector: 'app-dashboard',
             templateUrl: './dashboard.component.html',
             styleUrls: ['./dashboard.component.scss']
           })
export class DashboardComponent implements OnInit {

  role: string;
  profileEnrichmentScore: number;
  quarterModels: QuarterLineCartModel[];
  candidateCount: number = 0;
  news: NewsModel[] = [];

  jobsCount: number = 0;
  employerCount: number = 0;
  closedJobCount: number = 0;
  recruitersCount: number = 0;

  constructor(private userCountService: UserCountService, private candidateCountService: CandidateCountService,
              private newsService: NewsService,
              private jobCountService: JobsCountService, private employerCountService: EmployerCountService,
              private recruiterCountService: RecruiterCountService,
              private profileEnrichmentService: ProfileEnrichmentService,
              private recruiterMeService: RecruiterMeService,
              private router: Router) {

  }

  ngOnInit(): void {
    this.role = localStorage.getItem('role');
    this.getNews();
    this.role === 'ADMIN' || this.role === 'RECRUITER_MANAGER' || this.role === 'RECRUITER_USER' ? this.getAdminRecruiterCounts() : (this.role == 'EMPLOYER_MANAGER' || this.role === 'EMPLOYER_USER') ? this.getEmployerCounts() : '';

    if (this.role === 'NEW_SUBSCRIBER') {
      this.router.navigateByUrl('/dashboard/pricing')
          .then();
    }

    this.profileEnrichmentService.getProfileEnrichmentScore()
        .subscribe(profileEnrichmentScore => {
          this.profileEnrichmentScore = profileEnrichmentScore;
          if (this.profileEnrichmentScore < 80) {
            //@ts-ignore
            $('#complete-register-popup')
              .modal('show');
          }
        });

  }

  getNews() {
    this.newsService.ServerGet()
        .subscribe(x => {
          this.news = x.content;
          this.news.forEach(f => {
            f.date = new Date(f.createdAt).toLocaleDateString();
          });
        });
  }

  getAdminRecruiterCounts() {
    this.candidateCountService.ServerGet()
        .subscribe(x => {
          let model: CandidateCountsModel = x;

          this.quarterModels = [
            {
              Title: 'totalCandidates',
              SubTitle: model.totalCandidates,
              ImgUrl: 'assets/images/icons/icon-candidate.svg',
              ParagraphIconClass: 'fas fa-long-arrow-alt-up upArrow',
              SinceLastMonth: model.totalCandidatesSinceLastMonth,
              ParagraphText: 'sinceLastMonth',
              color: 'rgb(235 237 250)'
            },
            {
              Title: 'numberOFSubmissions',
              SubTitle: model.totalApplications,
              ImgUrl: 'assets/images/icons/icon-sub.svg',
              ParagraphIconClass: 'fas fa-long-arrow-alt-up upArrow',
              SinceLastMonth: model.totalApplicationsSinceLastMonth,
              ParagraphText: 'sinceLastMonth',
              color: '#FFF8ED'
            },
            {
              Title: 'qualifedSubmissions',
              SubTitle: model.qualifiedApplications,
              ImgUrl: 'assets/images/icons/icon-qua.svg',
              ParagraphIconClass: 'fas fa-long-arrow-alt-up upArrow',
              SinceLastMonth: model.qualifiedApplicationsSinceLastMonth,
              ParagraphText: 'sinceLastMonth'
            },
            {
              Title: 'underReview',
              SubTitle: model.applicationsUnderReview,
              ImgUrl: 'assets/images/icons/icon-under.svg',
              ParagraphIconClass: 'fas fa-long-arrow-alt-up upArrow',
              SinceLastMonth: model.applicationsUnderReviewSinceLastMonth,
              ParagraphText: 'sinceLastMonth'
            }];

        });

    if (this.role == 'ADMIN') {
      this.jobCountService.ServerGet()
          .subscribe(x => {
            let jobCounts: JobCountsModel = x;
            this.jobsCount = jobCounts.totalJobs;
            this.closedJobCount = jobCounts.closedJobs;
          });

      this.recruiterCountService.ServerGet()
          .subscribe(x => {
            let recruiterCount: RecruiterCountsModel = x;
            this.recruitersCount = recruiterCount.totalRecruiters;
          });

      this.employerCountService.ServerGet()
          .subscribe(x => {
            let employerCounts: EmployerCountsModel = x;
            this.employerCount = employerCounts.totalEmployers;
          });
    }

  }

  getEmployerCounts() {
    this.candidateCountService.ServerGet()
        .subscribe(x => {
          let candidate: CandidateCountsModel = x;

          this.jobCountService.ServerGet()
              .subscribe(x => {
                let job: JobCountsModel = x;

                this.userCountService.ServerGet()
                    .subscribe(x => {
                      let user: UserCountsModel = x;

                      this.quarterModels = [{
                        Title: 'totalJobs',
                        SubTitle: job.totalJobs,
                        ImgUrl: 'assets/images/icons/icon-jobs.png',
                        ParagraphIconClass: 'fas fa-long-arrow-alt-up upArrow',
                        SinceLastMonth: job.totalJobsSinceLastMonth,
                        ParagraphText: 'sinceLastMonth'
                      },
                        {
                          Title: 'totalApplications',
                          SubTitle: candidate.totalApplications,
                          ImgUrl: 'assets/images/icons/icon-app.svg',
                          ParagraphIconClass: 'fas fa-long-arrow-alt-up upArrow',
                          SinceLastMonth: candidate.totalApplicationsSinceLastMonth,
                          ParagraphText: 'sinceLastMonth'
                        },
                        {
                          Title: 'waitingForAction',
                          SubTitle: candidate.applicationsWaitingForAction,
                          ImgUrl: 'assets/images/icons/icon-waiting.svg',
                          ParagraphIconClass: 'fas fa-long-arrow-alt-up upArrow',
                          SinceLastMonth: candidate.applicationsWaitingForActionSinceLastMonth,
                          ParagraphText: 'sinceLastMonth'
                        },
                        {
                          Title: 'totalUsers',
                          SubTitle: user.totalUsers,
                          ImgUrl: 'assets/images/icons/icon-user.svg',
                          ParagraphIconClass: 'fas fa-long-arrow-alt-up upArrow',
                          SinceLastMonth: user.totalUsersSinceLastMonth,
                          ParagraphText: 'sinceLastMonth'
                        }];

                    });

              });

        });
  }

}
