import {Injectable} from '@angular/core';
import {applicationDetailPrintPage} from './application-detail-print-page';
import {ApplicationModel} from '../../../../shared/models/applications/application.model';
import {TranslateService} from '../../../../shared/services';
import {DatePipe} from '@angular/common';
import {ApplicationDetailStatusService} from '../application-detail-status.service';

@Injectable({
              providedIn: 'root'
            })
export class ApplicationDetailPrintPageService {

  constructor(private translateService: TranslateService,
              private datePipe: DatePipe,
              private applicationDetailStatusService: ApplicationDetailStatusService) {
  }

  fillPrintPage(application: ApplicationModel): HTMLHtmlElement {
    const htmlElement = document.createElement('html');
    htmlElement.innerHTML = applicationDetailPrintPage;

    (htmlElement.getElementsByClassName('job-image')[0] as HTMLImageElement).src = application.job?.logoUrl && application.job?.logoUrl != '' ? application.job?.logoUrl : '/assets/images/default-logo.png';
    htmlElement.getElementsByClassName('job-title-value')[0].innerHTML = application.job.title;
    htmlElement.getElementsByClassName('job-company-value')[0].innerHTML = application.employer.name;
    htmlElement.getElementsByClassName('application-status-value')[0].innerHTML = this.translateService.instant(
      application.status);
    htmlElement.getElementsByClassName('offer-stage')[0].classList.add(
      this.applicationDetailStatusService.getStatusClass(application.status));

    htmlElement.getElementsByClassName('detail-title-box-value')[0].innerHTML = this.translateService.instant(
      'details');
    htmlElement.getElementsByClassName('detail-candidate-name-title')[0].innerHTML = this.translateService.instant(
      'candidateName');
    htmlElement.getElementsByClassName('candidate-name-value')[0].innerHTML = application.candidate.fullName;
    htmlElement.getElementsByClassName('detail-job-title-title')[0].innerHTML = this.translateService.instant(
      'jobTitle');
    htmlElement.getElementsByClassName('detail-job-title-value')[0].innerHTML = application.job.title;
    htmlElement.getElementsByClassName('detail-recruiter-name-title')[0].innerHTML = this.translateService.instant(
      'recruiterName');
    htmlElement.getElementsByClassName(
      'detail-recruiter-name-value')[0].innerHTML = application.recruiter.user.fullName;
    htmlElement.getElementsByClassName('detail-applied-on-title')[0].innerHTML = this.translateService.instant(
      'appliedOn');
    htmlElement.getElementsByClassName('detail-applied-on-value')[0].innerHTML = this.datePipe.transform(
      application.createdAt, 'dd/MM/YYY');
    htmlElement.getElementsByClassName('job-detail-title-box-value')[0].innerHTML = this.translateService.instant(
      'jobDetails');
    htmlElement.getElementsByClassName('detail-experience-level-title')[0].innerHTML = this.translateService.instant(
      'experienceLevel');
    htmlElement.getElementsByClassName(
      'detail-experience-level-value')[0].innerHTML = this.translateService.instant(
      application.job.experienceLevel);
    htmlElement.getElementsByClassName('detail-industry-title')[0].innerHTML = this.translateService.instant(
      'industry');
    htmlElement.getElementsByClassName('detail-industry-value')[0].innerHTML = application.job.industry.name;
    htmlElement.getElementsByClassName(
      'detail-relocation-assistance-title')[0].innerHTML = this.translateService.instant(
      'relocationAssistance');
    htmlElement.getElementsByClassName(
      'detail-relocation-assistance-value')[0].innerHTML = this.translateService.instant(
      application.job.relocationAssistance.type);
    htmlElement.getElementsByClassName('detail-education-level-title')[0].innerHTML = this.translateService.instant(
      'educationLevel');
    htmlElement.getElementsByClassName('detail-education-level-value')[0].innerHTML = this.translateService.instant(
      application.job.educationLevel);
    htmlElement.getElementsByClassName('detail-compensations-title')[0].innerHTML = this.translateService.instant(
      'compensations');
    htmlElement.getElementsByClassName('detail-compensations-value')[0].innerHTML = this.translateService.instant(
      'from') + ' ' + this.translateService.instant(
      application.job?.compensation?.minAmount) + ' ' + this.translateService.instant(
      'to') + ' ' + this.translateService.instant(
      application.job?.compensation?.maxAmount);
    htmlElement.getElementsByClassName('apply-before-title')[0].innerHTML = this.translateService.instant(
      'applyBefore');
    htmlElement.getElementsByClassName('apply-before-value')[0].innerHTML = this.datePipe.transform(
      application.job?.updatedAt);
    htmlElement.getElementsByClassName('job-nature-title')[0].innerHTML = this.translateService.instant(
      'jobNature');

    let jobNatureHtml = '';
    application.job.skills.forEach((skill) => {
      jobNatureHtml += ` <span  class="badge badge-yellow mr-2">
                            ${skill}
                          </span>`;
    });
    htmlElement.getElementsByClassName('job-nature-value')[0].innerHTML = jobNatureHtml;

    htmlElement.getElementsByClassName('salary-range-title')[0].innerHTML = this.translateService.instant(
      'salaryRange');

    htmlElement.getElementsByClassName('salary-range-value')[0].innerHTML = this.translateService.instant(
      application.job?.compensation?.minAmount) + ' - ' + this.translateService.instant(
      application.job?.compensation?.maxAmount) + ' / ' + this.translateService.instant('yearly');

    htmlElement.getElementsByClassName('location-title')[0].innerHTML = this.translateService.instant(
      'location');
    htmlElement.getElementsByClassName('location-value')[0].innerHTML = this.translateService.instant(
      application.job?.location?.city?.name) + ', ' + this.translateService.instant(
      application.job?.location?.country?.name);

    htmlElement.getElementsByClassName('job-description-title')[0].innerHTML = this.translateService.instant(
      'jobDescription');
    htmlElement.getElementsByClassName('job-description-value')[0].innerHTML = application.job?.description;

    console.log(htmlElement);

    return htmlElement as HTMLHtmlElement;
  }

  print(application: ApplicationModel): void {

    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
    const pri = iframe.contentWindow;
    pri.document.open();
    pri.document.write(this.fillPrintPage(application).innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
    pri.onafterprint = () => {
      document.body.removeChild(iframe);
    };

  }
}
