import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth/auth.service';

declare var $: any;

@Component({
  selector: 'app-login-register-header',
  templateUrl: './login-register-header.component.html',
  styleUrls: ['./login-register-header.component.scss']
})
export class LoginRegisterHeaderComponent implements OnInit {

  isLoggedIn: boolean = false;
  @Input() HeaderMode: string;

  constructor(public router: Router, private authService: AuthService) {
  }

  ngOnInit(): void {
    this.isLoggedIn = this.authService.checkIsThereTokenOnTheLocalStorageAndItIsNotExpired();
    let component = this;

    $(window).on('load', function() {

      $('body').scrollspy({
        target: '.navbar-collapse',
        offset: 195
      });

      $(window).on('scroll', function() {
        if (component.router.url == '/') {
          if ($(window).scrollTop() > 1) {

            $('.fixed-top').addClass('menu-bg');

            $('.sign-in-color').addClass('scroll-text-color');

            $('.sign-up-color').addClass('scroll-text-color');

            $('.sign-up-design').addClass('sign-up-design2');
          } else {

            $('.fixed-top').removeClass('menu-bg');

            $('.sign-in-color').removeClass('scroll-text-color');

            $('.sign-up-color').removeClass('scroll-text-color');

            $('.sign-up-design').removeClass('sign-up-design2');
          }
        } else {
          $('.fixed-top').addClass('menu-bg');

          $('.sign-in-color').addClass('scroll-text-color');

          $('.sign-up-color').addClass('scroll-text-color');

          $('.sign-up-design').addClass('sign-up-design2');
        }

      });

    });
  }

}
