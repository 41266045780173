import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {catchError, finalize, map} from 'rxjs/operators';
import {ApiService} from '../auth';
import {NotificationService} from '../notification';
import {server} from '../server';
import {TranslatePipe} from '../translate';
import {Observable, Subject} from 'rxjs';
import {PaginationDtoApplicationModel} from '../../models';
import {ApplicationModel} from '../../models/applications/application.model';

@Injectable({
    providedIn: 'root'
})
export class ApplicationService extends ApiService {

    clearFilterAndSortSubject: Subject<any> = new Subject<any>();

    constructor(public http: HttpClient, public spinner: NgxSpinnerService, public translate: TranslatePipe,
                public notification: NotificationService) {
        super(http, server.application, spinner, translate, notification);
    }

    getApplications(params: string): Observable<PaginationDtoApplicationModel<ApplicationModel[]>> {
        return this.ServerGetWithText(params);

    }

    CancelApplication(applicationId: string) {
        const headers = this.setHeadersWithToken();
        const options = {headers};
        return this.http.post(server.application + '/' + applicationId + '/cancel', null, options)
            .pipe(
                map(res => res),
                catchError((err) => {
                    return this.ServerError(err);
                }),
                finalize(() => {
                    this.spinner.hide();
                }));

    }

    rejectApplication(applicationId: string): Observable<any> {
        const headers = this.setHeadersWithToken();
        const options = {headers};
        return this.http.post(server.application + '/' + applicationId + '/reject', null, options)
            .pipe(
                map(res => res),
                catchError((err) => {
                    return this.ServerError(err);
                }),
                finalize(() => {
                    this.spinner.hide();
                }));

    }

    resignedApplication(applicationId: string): Observable<any> {
        const headers = this.setHeadersWithToken();
        const options = {headers};
        return this.http.post(server.application + '/' + applicationId + '/resigned', null, options)
            .pipe(
                map(res => res),
                catchError((err) => {
                    return this.ServerError(err);
                }),
                finalize(() => {
                    this.spinner.hide();
                }));

    }

    dismissedApplication(applicationId: string): Observable<any> {
        const headers = this.setHeadersWithToken();
        const options = {headers};
        return this.http.post(server.application + '/' + applicationId + '/dismiss', null, options)
            .pipe(
                map(res => res),
                catchError((err) => {
                    return this.ServerError(err);
                }),
                finalize(() => {
                    this.spinner.hide();
                }));

    }

    ProgressApplication(applicationId: string, status: string): Observable<any> {
        const headers = this.setHeadersWithToken();
        const options = {headers};
        return this.http.post(server.application + '/' + applicationId + '/progress', {status}, options)
            .pipe(
                map(res => res),
                catchError((err) => {
                    return this.ServerError(err);
                }),
                finalize(() => {
                    this.spinner.hide();
                }));

    }

    HireApplication(applicationId: string, model: any): Observable<any> {
        const headers = this.setHeadersWithToken();
        const options = {headers};

        return this.http.post(server.application + '/' + applicationId + '/hire', model, options)
            .pipe(
                map(res => res),
                catchError((err) => {
                    return this.ServerError(err);
                }),
                finalize(() => {
                    this.spinner.hide();
                }));

    }

    getNotes(applicationId: string): Observable<any> {
        const headers = this.setHeadersWithToken();
        const options = {headers};

        return this.http.get(server.application + '/notes?filter=application.id::' + applicationId, options)
            .pipe(
                map(res => res),
                catchError((err) => {
                    return this.ServerError(err);
                }),
                finalize(() => {
                    this.spinner.hide();
                }));
    }

    postNote(model: any): Observable<any> {
        const headers = this.setHeadersWithToken();
        const options = {headers};

        return this.http.post(server.application + '/notes', model, options)
            .pipe(
                map(res => res),
                catchError((err) => {
                    return this.ServerError(err);
                }),
                finalize(() => {
                    this.spinner.hide();
                }));

    }

    putNote(noteId: string, model: any): Observable<any> {
        const headers = this.setHeadersWithToken();
        const options = {headers};

        return this.http.put(server.application + '/notes/' + noteId, model, options)
            .pipe(
                map(res => res),
                catchError((err) => {
                    return this.ServerError(err);
                }),
                finalize(() => {
                    this.spinner.hide();
                }));

    }

    deleteNote(noteId: string): Observable<any> {
        const headers = this.setHeadersWithToken();
        const options = {headers};

        return this.http.delete(server.application + '/notes/' + noteId, options)
            .pipe(
                map(res => res),
                catchError((err) => {
                    return this.ServerError(err);
                }),
                finalize(() => {
                    this.spinner.hide();
                }));

    }

    saveApplication(applicationId: string): Observable<any> {
        return this.ServerPostWithText('/' + applicationId + '/save', {});
    }

    unSaveApplication(applicationId: string): Observable<any> {
        return this.ServerPostWithText('/' + applicationId + '/unsave', {});
    }
}
