<div class="container-fluid px-5">

  <div class="header mt-3">{{'frequentlyAskedQuestions' | translate}}</div>

  <div *ngIf="categories && categories.length > 0" class="switch6 mt-3">
    <label class="switch6-light" onclick="">
        <input [(ngModel)]="selectedCat" type="checkbox" (click)="selectCategory(selectedCat)">
        <span style="cursor: pointer;">
          <span [ngClass]="!selectedCat ? 'white-text' : 'gray-text'">{{ categories[0].name | uppercase }}</span>
          <span [ngClass]="selectedCat ? 'white-text' : 'gray-text'">{{ categories[1].name | uppercase }}</span>
        </span>
        <a class="swtich-button"></a>
    </label>
  </div>

  <div class="mt-5">
    <div class="row">
      <div class="headerSearchBoxDiv ml-3 col-6">
        <i class="fas fa-search mx-2"></i> <input [(ngModel)]="filter" #searchInput (keyup)="search(searchInput.value)" type="text" placeholder="{{'searchForQuestionsHere' | translate}}" class="headerSearchBox">
      </div>
      <br>
             <label *ngIf="filter != ''" class="clear-search mt-3 ml-2" for="" (click)="clearSearch()"><i class="fas fa-times mr-1"
              style="color: #707683;" ></i> {{'clearSearch' | translate}}  </label>

    </div>
  </div>

  <tlb-empty-page *ngIf="!faqItems || faqItems.length == 0" [warningLabel]="filter == ''  ? 'noFaqYet' : filter" [filterText]="filter"></tlb-empty-page>



<div *ngIf="faqItems && faqItems.length > 0">


  <div class="col-12 col-sm-12 mx-auto mt-2" >
    <div class="col-12 col-sm-12 row pl-0 mt-4" style="margin-bottom: -23px;">
      <div class="container-fluid">
        <div class="row">

          <div class="col-1 ml-auto">
          </div>

          <div class="col-11 px-2">
              <div class="row">

                <div class="col-1 col-lg-1 col-md-1 col-xl-1 pt-3 pt-md-3 pt-lg-3 pt-xl-0">
                  <span class="screen-header-text ml-4"> {{'sno' | translate}}</span>

                </div>

                <div class=" col-9 col-lg-9 col-md-9 col-xl-9 pt-3 pt-md-3 pt-lg-3 pt-xl-0">
                  <span class="screen-header-text ml-4">{{'questions' | translate}}</span>

                </div>

                <div class="col-2 col-lg-2 col-md-2 col-xl-2 pt-3 pt-md-3 pt-lg-3 pt-xl-0">

                  <span class="screen-header-text mr-4" style="float: right;">
                    {{'options' | translate}}
                  </span>

                </div>

              </div>

          </div>
        </div>
        </div>
    </div>
    <ngx-sortable [items]="faqItems" [listStyle]="{width:'auto', height:'auto', top: '0px'}" [showHeader]="false"
      class="col-12 border-0" (listSorted)="listOrderChanged($event)">
      <ng-template let-item>
        <div class="col-12 col-sm-12 row pl-0 mt-2">
          <div class="container-fluid">
            <div class="row">

              <div class="card" role="button" style="border: none; width: 8%;" (click)="openEditItem(item)">
                <div class="card-body" style="text-align: center;">
                  <i class="fas fa-expand-arrows-alt drag-icon ml-2"></i>
                </div>
              </div>

              <div class="card  px-2" style="border: none; width: 90%; margin-left: 1%;" role="button">
                <div class="card-body">
                  <div class="row">

                    <div class="col-1 col-lg-1 col-md-1 col-xl-1 pt-3 pt-md-3 pt-lg-3 pt-xl-0 ">
                      <span class="num-quest-text">
                        {{ faqItems.indexOf(item) + 1 }}
                      </span>

                    </div>

                    <div class=" col-9 col-lg-9 col-md-9 col-xl-9 pt-3 pt-md-3 pt-lg-3 pt-xl-0">
                      <span class="num-quest-text">{{item.question}}</span>
                    </div>

                    <div class="col-2 col-lg-2 col-md-2 col-xl-2 pt-3 pt-md-3 pt-lg-3 pt-xl-0">
                      <span style="float: right;">
                        <i class="far fa-edit mr-3" style="color: #3652E3; " (click)="openEditItem(item)"></i>

                        <i class="far fa-trash-alt" style="color: #3652E3;" (click)="openDeleteItem(item)"></i>
                      </span>

                    </div>

                  </div>
                </div>
              </div>

            </div>
            </div>

        </div>
      </ng-template>
    </ngx-sortable>



  </div>


</div>

<div class="row ml-2 mb-5" style="cursor: pointer;" (click)="openEditItem(null)">
  <div class="box"><i class="fas fa-plus" style="color: white;"></i></div>
  <div class="mt-2 col-md-2">
    <span class="create-text">{{'createQuestion' | translate}}</span>
  </div>
</div>
</div>

<general-delete-modal [Title]="'deleteFaqItem'" [Text]="'theFaqItemWillBeRemovedFromYourListAreYouSure'" (selection)="deleteItem($event)"></general-delete-modal>
<app-admin-add-edit-modal *ngIf="selectedCategory && selectedCategory.id" [selectedItem]="selectedItem"
  [itemList]="faqItems" [category]="selectedCategory" (updateOrSaveClick)="selectCategory(!selectedCat)">
</app-admin-add-edit-modal>
