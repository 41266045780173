import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MailVerifyService, server } from 'src/app/shared/services';

@Component({
  selector: 'app-mail-verify',
  templateUrl: './mail-verify.component.html',
  styleUrls: ['./mail-verify.component.scss']
})
export class MailVerifyComponent implements OnInit {

  success: boolean = false;
  error: boolean = false;

  constructor(private mailVerifyService: MailVerifyService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    // @ts-ignore
    $('body').css('background-color', '#F5F4FE');
    // @ts-ignore
    $('body').css('background-image', 'none');
    // @ts-ignore
    $('body').css('height', '100%');
    // @ts-ignore
    $('html').css('height', '100%');

    // this.success = true;
    this.error = true;
    this.activatedRoute.queryParams.subscribe(params => {

      this.mailVerifyService.ServerPostWithoutLogin(server.mailVerify, params)
        .subscribe(res => {
          this.success = true;
          this.error = false;
        });

    });

  }
}
