import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NotificationService, server, TranslatePipe, UserService } from '../../../shared/services';
import { UserModel } from '../../../shared/models/user';

@Component({
  selector: 'app-delete-user-popup',
  templateUrl: './delete-user-popup.component.html',
  styleUrls: ['./delete-user-popup.component.scss']
})
export class DeleteUserPopupComponent implements OnInit {

  users: UserModel[] = [];
  @Input() employer;
  @Output() updateUsersEmitter = new EventEmitter();
  @Input() Title: '';
  @Input() Text: '';
  @Output() selection = new EventEmitter();

  constructor(private translate: TranslatePipe, private notification: NotificationService, private userService: UserService) { }

  ngOnInit(): void {
  }

  deleteUser() {
    this.userService.ServerDelete(server.user + '/' + this.employer.id).subscribe((res: any) => {
      this.notification.success(this.translate.transform('theUserSuccessfullyDeleted'));
      this.updateUsersEmitter.emit();
      // @ts-ignore
      $('#exampleDeleteModal').modal('hide');
    });
  }
}
