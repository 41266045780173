<div class="box row mt-3">
  <div class="col-12 results-text mt-2 ml-2 mb-3">
    <div class="mb-4 header"> {{'filters' | translate}} </div>
    <div>
      <div class="col-12 row headerSearchBoxDiv ml-1 d-none d-lg-inline-block">
        <i class="fas fa-search col-1 mt-1"></i> <input #searchInput
                                                        (keyup)="search(searchInput.value)"
                                                        [(ngModel)]="filter"
                                                        class="headerSearchBox col-11 mt-1"
                                                        type="text">
      </div>

    </div>


    <div class="mt-4">
      <div *ngFor="let industries of allIndustries"
           class="row">
        <div *ngFor="let industry of industries"
             class="col-12 col-xl-3">
          <div class="form-check form-check-inline">
            <input (click)="checkChange(industry)"
                   [checked]="filterArray.indexOf(industry) > -1"
                   class="form-check-input"
                   id="{{industry.id}}"
                   type="checkbox"
                   value="{{industry.id}}">
            <label class="form-check-label text"
                   for="{{industry.id}}">{{industry.defaultLocalization.name}}</label>
          </div>
        </div>
      </div>

    </div>

    <div class="mt-5">
      <button (click)="setFilter()"
              class="btn ok-button mr-3"
              style="float: right;"
              type="button">{{'continue' | translate}}</button>
    </div>
  </div>
</div>

<div class="mb-3">
  <app-saved-job-profiles (useJobProfile)="useProfile($event)"
                          *ngIf="showJobs"
                          [fromInd]="true"
                          [indFilter]="filterIndustry"
                          [type]="'PUBLIC'"></app-saved-job-profiles>
</div>


