import { TlbFormComponentModel } from '.';

export class TlbFormModel {

  header?: string;
  class: string;
  components: TlbFormComponentModel[];
  step?: string;
  header2?: string;
  label?: string;
  modelName?: string;

  constructor() {
    this.header = '';
    this.class = '';
    this.components = [];
    this.step = '';
    this.header2 = '';
    this.label = '';
    this.modelName = '';
  }
}
