export class RecruiterReviewPostModel {

  recruiterId: string;
  applicationId: string;
  comment: string;
  rating: number;
  expertisement: number;
  communication: number;
  followUp: number;
  informativity: number;
  industryKnowledge: number;

constructor() {
  this.recruiterId = null;
  this.applicationId=null;
  this.comment='';
  this.rating = 0;
  this.expertisement = 0;
  this.communication = 0;
  this.followUp = 0;
  this.informativity = 0;
  this.industryKnowledge = 0;

}
}
