import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {PopupService} from '../services/popup/popup.service';

declare var $: any;

@Component({
  selector: 'app-delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.scss']
})
export class DeletePopupComponent implements OnInit {

  @Output() selection = new EventEmitter();

  constructor(private popupService: PopupService) {
  }

  ngOnInit(): void {
  }

  delete(): void {
    const data = {
      popupId: 'deletePopup',
      data: 'Delete'
    };
    this.selection.emit(true);
    this.popupService.nextToSubject(data);

  }

  discard(): void {
    const data = {
      popupId: 'deletePopup',
      data: 'Discard'
    };
    this.popupService.nextToSubject(data);
  }

}
