<div class="content-div">
  <div class="row">
    <div>
      <img
        [src]="recruiter?.profilePhotoUrl ? recruiter?.profilePhotoUrl : '../../../../assets/images/recruiter-avatar.png'"
        alt=""
        height="140"
        style="border-radius: 100px;"
        width="140">
      <!-- <div [style.background-color]="recruiter?.imageColor" id="preimage"><span
          style="color: white;">{{recruiter?.imageText}}</span></div> -->
    </div>
    <div class="ml-4">
      <div class="name-text">{{recruiter?.user?.fullName}}</div>
      <div class="type-text"><span *ngIf="recruiter && recruiter.type"> {{recruiter.type | translate}} </span>
        <span *ngIf="!recruiter || !recruiter.type">{{ 'notSpecified' | translate}}</span>
      </div>
      <div class="col-12">
        <div class="row pb-3 pt-2">
          <span [ngClass]="recruiter?.rating > 0 ? recruiter?.rating < 1 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                style="color: #F1B856;"></span>
          <span [ngClass]="recruiter?.rating > 1 ? recruiter?.rating < 2 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                style="color: #F1B856;"></span>
          <span [ngClass]="recruiter?.rating > 2 ? recruiter?.rating < 3 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                style="color: #F1B856;"></span>
          <span [ngClass]="recruiter?.rating > 3 ? recruiter?.rating < 4 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                style="color: #F1B856;"></span>
          <span [ngClass]="recruiter?.rating > 4 ? recruiter?.rating < 5 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                style="color: #F1B856;"></span>
          <span class="rating-text ml-2">{{recruiter?.rating}} ({{count}} {{'reviews' | translate}})</span>
        </div>
      </div>

      <!-- <div class="row ml-1">
        <div class="mail-phone"> <i class="far fa-envelope mr-2"></i> {{recruiter?.user?.email}} </div>
        <div class="mail-phone ml-4"> <i class="fas fa-phone-alt mr-2"></i>
          {{recruiter?.primaryContactNumber?.countryCallingCode}}
          {{recruiter?.primaryContactNumber?.phoneNumber}} </div>
      </div> -->

      <div *ngIf="recruiter && recruiter.recommended"
           class="mt-1 row ml-1">
        <div class="mt-2">
          <img src="../../../assets/images/recomended.png">
        </div>
        <div class="ml-1">
          <span class="recomended-text ml-1">{{'recomendedByTalentbase' | translate}}</span> <br>
          <span class="recomended-text"
                style="font-weight: 400;"> {{ 'employersGaveThem' | translate }} {{
            recruiter?.rating | number : '1.2-2' }} {{ 'starReviews' | translate }} </span>
        </div>

      </div>
    </div>


  </div>

  <div class="row mt-4"
       style="flex-wrap: nowrap;
              overflow: auto;
              padding: 1rem 0;">

    <div class="mr-3">
      <div class="bottom-texts">
        {{ 'hired' | translate }}
      </div>
      <div class="bottom-numbers">
        {{recruiter?.applicationCounts?.hired}}
      </div>

    </div>

    <div class="mr-3"
         style="border-left: 1px solid #707683;">
      <div class="bottom-texts ml-3">
        {{ 'interviewed' | translate }}
      </div>
      <div class="bottom-numbers ml-3">
        {{recruiter?.applicationCounts?.interviewStage}}
      </div>

    </div>

    <div class="mr-3"
         style="border-left: 1px solid #707683;">
      <div class="bottom-texts ml-3">
        {{ 'rejected' | translate }}
      </div>
      <div class="bottom-numbers ml-3">
        {{recruiter?.applicationCounts?.rejectedByEmployer}}
      </div>

    </div>

    <div class="mr-3"
         style="border-left: 1px solid #707683;">
      <div class="bottom-texts ml-3">
        {{ 'placements' | translate }}
      </div>
      <div class="bottom-numbers ml-3">
        {{recruiter?.totalPlacements}}
      </div>

    </div>

  </div>

</div>


<div style="padding: 2rem; background-color: white;">

  <div class="row ml-2 mb-2">
    <div>
      <button (click)="isAbout = true"
              [ngClass]="isAbout ? 'btn act-tab-button' : 'btn tab-button'">
        {{'about' | translate}}
      </button>
    </div>

    <div>
      <button (click)="isAbout = false"
              [ngClass]="isAbout ? 'btn tab-button' : 'btn act-tab-button'"
              class="ml-2">
        {{'comments' | translate}}
      </button>
    </div>
  </div>

  <div *ngIf="isAbout"
       class="boxes"
       style="padding: 1rem 2rem;">

    <div class="about-me-title mb-2"> {{'aboutMe' | translate}}</div>

    <div class="about-text">

      {{recruiter?.aboutMe}}
    </div>

  </div>
  <tlb-empty-page *ngIf="(!reviews || reviews.length == 0)&& !isAbout"
                  [warningLabel]="'noCommentsYet'" ></tlb-empty-page>
  <div *ngIf="reviews && reviews.length > 0 && !isAbout"
       class="boxes"
       style="padding: 2px 15px 20px 15px;">


    <div *ngFor="let review of reviews"
         class="mt-2"
         style=" padding: 2px 15px 20px 15px;">

      <div class="row mt-2">
        <div>
          <div [style.background-color]="review?.imageColor"
               id="preimage"><span
            style="color: white;">{{review?.imageText}}</span></div>
        </div>
        <div class="ml-4">
          <span class="comm-name">{{review?.fullName}}</span>

          <div class="col-12">
            <div class="row pb-3 pt-2">
              <span [ngClass]="review?.rating > 0 ? review?.rating < 1 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                    style="color: #F1B856;"></span>
              <span [ngClass]="review?.rating > 1 ? review?.rating < 2 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                    style="color: #F1B856;"></span>
              <span [ngClass]="review?.rating > 2 ? review?.rating < 3 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                    style="color: #F1B856;"></span>
              <span [ngClass]="review?.rating > 3 ? review?.rating < 4 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                    style="color: #F1B856;"></span>
              <span [ngClass]="review?.rating > 4 ? review?.rating < 5 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                    style="color: #F1B856;"></span>
              <span (click)="activeReviewCardId = review.id"
                    class="details-text ml-2"> {{'detailsLowerCase' |
                translate}}</span>


              <span *ngIf="activeReviewCardId == review.id"
                    class="card rev-cards">

                <div> <i (click)="activeReviewCardId = null;"
                         class="fas fa-times-circle"
                         style="float: right; color: gray;"></i></div>
                <div class="row"
                     style="align-items: center; align-self: center;">
                  <div class="fa fa-star"
                       style="color: #F1B856; font-size: 28px;"></div>
                  <div class="card-rating">{{review?.rating}}</div>

                </div>

                <div class="avarage-rat mb-3">
                  {{'averageRating' | translate}}
                </div>

                <div style="padding: 0px 0px 0px 16px;">
                  <div class="row pt-2">
                    <span [ngClass]="review?.expertisement > 0 ? review?.expertisement < 1 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                    <span [ngClass]="review?.expertisement > 1 ? review?.expertisement < 2 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                    <span [ngClass]="review?.expertisement > 2 ? review?.expertisement < 3 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                    <span [ngClass]="review?.expertisement > 3 ? review?.expertisement < 4 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                    <span [ngClass]="review?.expertisement > 4 ? review?.expertisement < 5 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                    <span class="ml-5 rate-texts">{{'expertisement' | translate}}</span>
                  </div>

                  <div class="row pt-2">
                    <span [ngClass]="review?.communication > 0 ? review?.communication < 1 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                    <span [ngClass]="review?.communication > 1 ? review?.communication < 2 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                    <span [ngClass]="review?.communication > 2 ? review?.communication < 3 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                    <span [ngClass]="review?.communication > 3 ? review?.communication < 4 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                    <span [ngClass]="review?.communication > 4 ? review?.communication < 5 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                    <span class="ml-5 rate-texts">{{'communication' | translate}}</span>
                  </div>

                  <div class="row pt-2">
                    <span [ngClass]="review?.followUp > 0 ? review?.followUp < 1 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                    <span [ngClass]="review?.followUp > 1 ? review?.followUp < 2 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                    <span [ngClass]="review?.followUp > 2 ? review?.followUp < 3 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                    <span [ngClass]="review?.followUp > 3 ? review?.followUp < 4 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                    <span [ngClass]="review?.followUp > 4 ? review?.followUp < 5 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                    <span class="ml-5 rate-texts">{{'followUp' | translate}}</span>
                  </div>

                  <div class="row pt-2">
                    <span [ngClass]="review?.informativity > 0 ? review?.informativity < 1 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                    <span [ngClass]="review?.informativity > 1 ? review?.informativity < 2 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                    <span [ngClass]="review?.informativity > 2 ? review?.informativity < 3 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                    <span [ngClass]="review?.informativity > 3 ? review?.informativity < 4 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                    <span [ngClass]="review?.informativity > 4 ? review?.informativity < 5 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                    <span class="ml-5 rate-texts">{{'informativity' | translate}}</span>
                  </div>

                  <div class="row pt-2">
                    <span [ngClass]="review?.industryKnowledge > 0 ? review?.industryKnowledge < 1 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                    <span [ngClass]="review?.industryKnowledge > 1 ? review?.industryKnowledge < 2 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                    <span [ngClass]="review?.industryKnowledge > 2 ? review?.industryKnowledge < 3 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                    <span [ngClass]="review?.industryKnowledge > 3 ? review?.industryKnowledge < 4 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                    <span [ngClass]="review?.industryKnowledge > 4 ? review?.industryKnowledge < 5 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                    <span class="ml-5 rate-texts">{{'industryKnowledge' | translate}}</span>
                  </div>

                  <div class="row pt-2">
                    <span [ngClass]="review?.rating > 0 ? review?.rating < 1 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                    <span [ngClass]="review?.rating > 1 ? review?.rating < 2 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                    <span [ngClass]="review?.rating > 2 ? review?.rating < 3 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                    <span [ngClass]="review?.rating > 3 ? review?.rating < 4 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                    <span [ngClass]="review?.rating > 4 ? review?.rating < 5 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                    <span class="ml-5 rate-texts"
                          style="font-weight: 800;">{{'overallSatisfaction' | translate}}</span>
                  </div>
                </div>




              </span>
            </div>
          </div>


          <div class="col-12">
            <span class="comm-text row">
              {{review?.comment}}
            </span>

          </div>
        </div>

      </div>

    </div>

    <div *ngIf="count > 3"
         class="view-text mr-3">
      <div class="row">
        <button (click)="getReviews(page - 1)"
                [disabled]="page == 1"
                class="arrow-buttons mr-3">
          <i class="fas fa-chevron-left"></i>
        </button>
        <button (click)="getReviews(page + 1)"
                [disabled]="page * 3 >= count"
                class="arrow-buttons">
          <i class="fas fa-chevron-right"></i>
        </button>

      </div>
    </div>

  </div>


  <div *ngIf="isAbout"
       class="boxes mt-1"
       style="padding: 1rem 2rem;">
    <div class="row mt-4">
      <div class="col-12 pb-2 titles">
        <b>
          {{'recruitingInfo'|translate}}
        </b>
      </div>
    </div>
    <div class="row mt-2">

      <div class="col-12 col-sm-3 row-non-wrap-start-center-flex">
        <div class="ovals mr-2"></div>
        <span class="desc">{{ 'recruitmentExperience' | translate }}</span>
      </div>
      <div class="col-12 col-sm-8 info-texts">
        <div *ngIf="recruiter && recruiter.individualPracticeTime ;else naContent"
             class="ml-2">{{recruiter?.individualPracticeTime
          | translate}}</div>
        <ng-template #naContent>{{'na' | translate}}</ng-template>

      </div>
    </div>

    <div class="row mt-2">

      <div class="col-12 col-sm-3 row-non-wrap-start-center-flex">
        <div class="ovals mr-2"></div>
        <!-- <img src="./../../../../../assets/images/talentbase/icons/chart-bar.png"> -->
        <span class="desc">{{ 'jobRecruisitionTypes' | translate }}</span>
      </div>
      <div class="col-12 col-sm-8 info-texts">
        <div *ngIf="recruiter && recruiter.requisitionTypesText;else narContent"
             class="ml-2">{{recruiter?.requisitionTypesText}}</div>
        <ng-template #narContent>{{'na' | translate}}</ng-template>

      </div>
    </div>

    <div class="row mt-2">

      <div class="col-12 col-sm-3 row-non-wrap-start-center-flex">
        <div class="ovals mr-2"></div>
        <!-- <img src="./../../../../../assets/images/talentbase/icons/locations.png"> -->
        <span class="desc">{{ 'functionalRoles' | translate }}</span>
      </div>
      <div class="col-12 col-sm-8 info-texts">
        <div *ngIf="recruiter && recruiter.functionalRolesText;else nafContent"
             class="ml-2">{{recruiter?.functionalRolesText}}</div>
        <ng-template #nafContent>{{'na' | translate}}</ng-template>

      </div>
    </div>

    <div class="row mt-2">
      <div class="col-12 col-sm-3 row-non-wrap-start-center-flex">
        <div class="ovals mr-2"></div>
        <!-- <img src="./../../../../../assets/images/talentbase/icons/chart-pie.png"> -->
        <span class="desc">{{ 'industries' | translate }}</span>
      </div>
      <div class="col-12 col-sm-8 info-texts">
        <div *ngIf="recruiter && recruiter.industriesText;else naiContent"
             class="ml-2">{{recruiter?.industriesText}}</div>
        <ng-template #naiContent>{{'na' | translate}}</ng-template>

      </div>
    </div>
  </div>

  <div *ngIf="experiences && experiences.length > 0 && isAbout"
       class="boxes mt-1"
       style="padding: 20px 15px 20px 33px;">

    <div class="col-12 pb-2 titles">
      <b>
        {{'experience'|translate}}
      </b>
    </div>

    <div *ngFor="let experience of experiences"
         class="mt-2"
         style=" border-bottom: 1px solid rgba(18, 17, 39, 0.12); padding: 2px 15px 20px 15px;">

      <div class="row mt-2">
        <div>
          <div [style.background-color]="experience?.imageColor"
               id="preimageExp"><span
            style="color: white;">{{experience?.imageText}}</span></div>
        </div>
        <div class="ml-4">
          <div class="row">
            <div class="exp-title">{{experience?.job?.name}}</div>
            <div class="exp-date">{{experience?.placedAt | date}}</div>
          </div>


          <div class="row">
            <div class="exp-emp">{{experience?.employer?.name}} <i class="fas fa-circle mr-2 ml-2"
                                                                   style="font-size: 5px;"></i> {{experience?.industry?.name}}
            </div>
          </div>

          <!-- <div class="col-12">
            <span class="comm-text row">
              {{review?.comment}}
            </span>

          </div> -->
        </div>

      </div>

    </div>

  </div>
</div>
