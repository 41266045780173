import { TitleCasePipe } from "@angular/common";
import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: '[toUpperCase]'
})

export class ToUpperCaseDirective {

  constructor(private elementRef: ElementRef, public lower: TitleCasePipe) { }

  @HostListener("keyup") titleCaseOnKeyup() {
    const value = this.elementRef.nativeElement.value;
    const titleCaseStr = this.lower.transform(value).substring(0, 1).toUpperCase() +
    value.substring(1);


    this.elementRef.nativeElement.value = titleCaseStr;
  }
}
