import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormType } from 'src/app/shared/enums';
import { TlbFormModel } from 'src/app/shared/models';
import { server, ResetPasswordService, TranslatePipe, NotificationService } from 'src/app/shared/services';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-reset-password-login',
  templateUrl: './reset-password-login.component.html',
  styleUrls: ['./reset-password-login.component.scss']
})
export class ResetPasswordLoginComponent implements OnInit {

  email: string = "";
  sendMail: boolean = false;
  formModel: TlbFormModel[] = [];
  formGroup: FormGroup;
  model: any = {};

  constructor(private resetPasswordService: ResetPasswordService, private router: Router,
    private notification: NotificationService, private translate: TranslatePipe) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {

    this.formModel = [{
      class: 'col-12', components: [
        { label: 'email', control: 'email', required: true, type: FormType.email, style:"border: none; border-bottom: 1px solid gray; border-radius: 0;" },
      ]
    }];

    this.formGroup = new FormGroup({
      email: new FormControl('', Validators.required)
    });
  }

  get form() { return this.formGroup.controls }

  resetPassword() {

    let resetModel = {
      email: this.form.email.value
    }

    this.resetPasswordService.ServerPostWithoutLogin(server.resetPassword, resetModel).subscribe(x => {
      this.sendMail = true;
      this.notification.success(this.translate.transform('weHaveSentYouAnEmailPleaseFollowTheLinkToResetYourPassword'));
      this.goRegister();
    });
  }

  goRegister(){
    this.router.navigateByUrl('sign-up');
  }


}
