// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
/*  baseUrl: 'http://ec2-52-208-151-88.eu-west-1.compute.amazonaws.com:8080',
  // Url of the Identity Provider
  issuer: 'http://ec2-52-208-151-88.eu-west-1.compute.amazonaws.com:8080/uaa/v1',*/
  baseUrl: 'http://91.106.219.249:8080',
  // Url of the Identity Provider
  issuer: 'http://91.106.219.249:8080/uaa/v1',

  tokenEndpoint: 'http://91.106.219.249:8080/uaa/v1/oauth/token',

    // URL of the SPA to redirect the user to after login
    redirectUri: window.location.origin + '/index.html',

    // URL of the SPA to redirect the user after silent refresh
    silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',

    // The SPA's id. The SPA is registerd with this id at the auth-server
    clientId: 'spa',
    dummyClientSecret: '',

    requireHttps: false,

    // set the scope for the permissions the client should request
    // The first three are defined by OIDC. The 4th is a usecase-specific one
    scope: 'web',
    showDebugInformation: true,

    logoutUrl: '/',
    oidc: false,
    timeoutFactor: 0.70,
    requestAccessToken: true,


};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
