export class JobRelocationAssistanceModel {

  type: string;
  additional: string;

 constructor() {
   this.type = null;
   this.additional = "";
 }
 }
