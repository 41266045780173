export class RequestPayload {
  limit: number;
  page: number;

  constructor() {
    this.limit = 2;
  }

  payloadURL(pageNo) {
    this.page = (pageNo - 1);
    return `/?size=${this.limit}&page=${this.page}&filter=status!:DELETED`;
  }

  payloadURLApp(pageNo) {
    this.page = (pageNo - 1);
    return `/?size=${this.limit}&page=${this.page}`;
  }

  payloadURLWithoutQuestionMark(pageNo): string {
    this.page = (pageNo - 1);
    return `&size=${this.limit}&page=${this.page}`;
  }

  payloadURLWithOutDelete(pageNo) {
    this.page = (pageNo - 1);
    return `/?size=${this.limit}&page=${this.page}`;
  }
}
