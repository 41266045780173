<div class="pl-4 pt-4">

  <h3 class="title pl-2">{{'recruiters'|translate}}</h3>
  <div class="col-12 overflow-auto hide-scroll my-3 p-0">
    <span (click)="changeActiveTab(0)" [ngClass]="activeTab==0?'active':''"
      class="tab-span">{{'recruiters'|translate|titlecase}}</span>
    <span (click)="changeActiveTab(1)" [ngClass]="activeTab==1?'active':''"
      class="tab-span">{{'favoriteRecruiters'|translate|titlecase}}</span>
  </div>
  <p *ngIf="activeTab==0" style="color: #737373;">{{'hi' | translate | titlecase}} {{this.empName}}, {{'thereAre' |
    translate}}
    <span style="color: #737373; font-weight: 700;">{{recruiters.length}} {{'recruiters' | translate}}</span>
    {{'recommendedForYouByPicktus'| translate}}
  </p>

  <div class="col-12 pt-3 pr-1 pl-0 filter-div">
    <div class="mb-3" style="display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: baseline;">
      <div class="ml-4" style="display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: center;">
        <i (click)="onFilter()" class="fa fa-search"></i>
        <input (keyup.enter)="onFilter()" [(ngModel)]="filterText" (keypress)="filterControl($event)"
          [ngClass]=" filterText.length > 0 ? 'filter-tocuhed' : '' " class="form-control filter" id="skill"
          name="skills" placeholder="{{'search' | translate}}" type="text">

        <br>
        <label (click)="clearSearch()" *ngIf="filterText != ''" class="clear-search mt-2" for=""><i
            class="fas fa-times mr-1" style="color: #707683;"></i> {{'clearSearch' | translate}} </label>
      </div>
      <div class="pr-3 ml-auto" style="display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: flex-start;
        justify-content: flex-start;
        align-items: center;">
        <app-recruiter-all-filters (clearFilter)="clearFilter($event)" (resultFilter)="getFilteredRec($event)"
          (resultSearch)="search($event)" class="mr-4"></app-recruiter-all-filters>
        <span class="sort-by-text">{{ 'sortBy' | translate }}:</span>
        <tlb-dropdown (searchData)="selectSort1($event)" [dropDownMenuWidth]="'13.1rem'"
          [dropdownName]="sortLabel1 != '' ? sortLabel1 : 'selectSort1'" [isFilter]="false" [items]="sortOptions1"
          [style]="'background: #FFFFFF; border: 1px solid #C8D4DF; box-sizing: border-box; width: 163px; height: 53px; margin: 0px 15px;'"
          [textStyle]="'font-style: normal; font-weight: normal; font-size: 14px; color: #252B42; margin-top: 5px;'">
        </tlb-dropdown>


      </div>

      <div>
      </div>
    </div>
  </div>

  <tlb-empty-page *ngIf="!otherRecruiters || otherRecruiters.length == 0"
    [warningLabel]="filterText == ''  ? 'noRecruitersYet' : filterText" [filterText]="filterText"></tlb-empty-page>

  <div class="col-12 ">
    <ng-container *ngIf="currentPage == 1 && !filtered&&activeTab==0">
      <div *ngFor="let recruiter of recruiters" class="row overflow-auto">
        <div class="card">
          <div class="profile-star-container">
            <div class="profile-container">
              <div class="profile-image-container">
                <div [style.background-color]="recruiter.imageColor" class="preimage"><span
                    style="color: white;">{{recruiter.imageText}}</span></div>
              </div>
              <div class="profile-name-container">
                <span class="users">{{recruiter.user?.fullName}}</span>
                <span class="card-titles"><span *ngIf="recruiter && recruiter.type"> {{recruiter.type | translate}}
                  </span> <span *ngIf="!recruiter || !recruiter.type">{{ 'notSpecified' | translate}}</span></span>
                <div class="recommended-container">
                  <img src="../../../assets/images/recomended.png">
                  <span class="recomended-text ml-1">{{'recomendedByTalentbase' | translate}}</span>
                </div>
                <div class="star-container">
                  <span
                    [ngClass]="recruiter?.rating > 0 ? recruiter?.rating < 1 ? 'fas fa-star-half-alt ml-0' : 'fa fa-star ml-0' : 'far fa-star ml-0'"
                    style="color: #F1B856;"></span>
                  <span
                    [ngClass]="recruiter?.rating > 1 ? recruiter?.rating < 2 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                    style="color: #F1B856;"></span>
                  <span
                    [ngClass]="recruiter?.rating > 2 ? recruiter?.rating < 3 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                    style="color: #F1B856;"></span>
                  <span
                    [ngClass]="recruiter?.rating > 3 ? recruiter?.rating < 4 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                    style="color: #F1B856;"></span>
                  <span
                    [ngClass]="recruiter?.rating > 4 ? recruiter?.rating < 5 ? 'fas fa-star-half-alt mr-0' : 'fa fa-star mr-0' : 'far fa-star mr-0'"
                    style="color: #F1B856;"></span>
                </div>
              </div>
            </div>

          </div>
          <div class="about-member-since-container">
            <div class="about-container">
              {{(recruiter.aboutMe === '') || (!recruiter.aboutMe) ? ('recruiterSampleAboutText' | translate) :
              recruiter.aboutMe }}

            </div>
            <div class="member-since-container">
              {{'memberSince' | translate}} {{recruiter.createdAt | date}}
            </div>
          </div>
          <div class="counts-view-container">
            <div class="counts-container">
              <div class="hired-count-container">
                <span class="numbers">{{recruiter?.applicationCounts?.hired}}</span>
                <p class="status-text">{{'hired'|translate}}</p>
              </div>
              <div class="interviewed-count-container">
                <span class="numbers">{{recruiter?.applicationCounts?.interviewStage}}</span>
                <p class="status-text">{{'interviewed'|translate}}</p>
              </div>
              <div class="rejected-count-container">
                <span class="numbers">{{recruiter?.applicationCounts?.rejectedByEmployer}}</span>
                <p class="status-text">{{'rejected'|translate}}</p>
              </div>
            </div>
            <div class="view-container">
              <div (click)="openDetailModal(recruiter, false, true)" class="text-center align-self-center">
                <a class="btn viewContactButton">{{'viewContactProfile'|translate}}</a>
              </div>
            </div>
            <div class="favorite-container">
              <i (click)="unFavoriteRecruiter(recruiter)" *ngIf="recruiter.favorite" class="fas fa-bookmark"></i>
              <i (click)="favoriteRecruiter(recruiter)" *ngIf="!recruiter.favorite" class="far fa-bookmark"></i>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div
      *ngFor="let recruiter of otherRecruiters  | paginate:{ itemsPerPage: requestPayload.limit, currentPage: currentPage,totalItems:count}"
      class="row overflow-auto">
      <div class="card">
        <div class="profile-star-container">
          <div class="profile-container">
            <div class="profile-image-container">
              <div [style.background-color]="recruiter.imageColor" class="preimage"><span
                  style="color: white;">{{recruiter.imageText}}</span></div>
            </div>
            <div class="profile-name-container">
              <span class="users">{{recruiter.user?.fullName}}</span>
              <span class="card-titles"><span *ngIf="recruiter && recruiter.type"> {{recruiter.type | translate}}
                </span> <span *ngIf="!recruiter || !recruiter.type">{{ 'notSpecified' | translate}}</span></span>
              <div *ngIf="recruiter.recommended" class="recommended-container">
                <img src="../../../assets/images/recomended.png">
                <span class="recomended-text ml-1">{{'recomendedByTalentbase' | translate}}</span>
              </div>
              <div class="star-container">
                <span
                  [ngClass]="recruiter?.rating > 0 ? recruiter?.rating < 1 ? 'fas fa-star-half-alt ml-0' : 'fa fa-star ml-0' : 'far fa-star ml-0'"
                  style="color: #F1B856;"></span>
                <span
                  [ngClass]="recruiter?.rating > 1 ? recruiter?.rating < 2 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                  style="color: #F1B856;"></span>
                <span
                  [ngClass]="recruiter?.rating > 2 ? recruiter?.rating < 3 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                  style="color: #F1B856;"></span>
                <span
                  [ngClass]="recruiter?.rating > 3 ? recruiter?.rating < 4 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                  style="color: #F1B856;"></span>
                <span
                  [ngClass]="recruiter?.rating > 4 ? recruiter?.rating < 5 ? 'fas fa-star-half-alt mr-0' : 'fa fa-star mr-0' : 'far fa-star mr-0'"
                  style="color: #F1B856;"></span>
              </div>
            </div>
          </div>

        </div>
        <div class="about-member-since-container">
          <div class="about-container">
            {{(recruiter.aboutMe === '') || (!recruiter.aboutMe) ? ('recruiterSampleAboutText' | translate) :
            recruiter.aboutMe }}
          </div>
          <div class="member-since-container">
            {{'memberSince' | translate}} {{recruiter.createdAt | date}}
          </div>
        </div>
        <div class="counts-view-container">
          <div class="counts-container">
            <div class="hired-count-container">
              <span class="numbers">{{recruiter?.applicationCounts?.hired}}</span>
              <p class="status-text">{{'hired'|translate}}</p>
            </div>
            <div class="interviewed-count-container">
              <span class="numbers">{{recruiter?.applicationCounts?.interviewStage}}</span>
              <p class="status-text">{{'interviewed'|translate}}</p>
            </div>
            <div class="rejected-count-container">
              <span class="numbers">{{recruiter?.applicationCounts?.rejectedByEmployer}}</span>
              <p class="status-text">{{'rejected'|translate}}</p>
            </div>
          </div>
          <div class="view-container">
            <div (click)="openDetailModal(recruiter, false, true)" class="text-center align-self-center">
              <a class="btn viewContactButton">{{'viewContactProfile'|translate}}</a>
            </div>
          </div>
          <div class="favorite-container">
            <i (click)="unFavoriteRecruiter(recruiter)" *ngIf="recruiter.favorite" class="fas fa-bookmark"></i>
            <i (click)="favoriteRecruiter(recruiter)" *ngIf="!recruiter.favorite" class="far fa-bookmark"></i>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="otherRecruiters && otherRecruiters.length > 0" class=" container-fluid row mt-3 pagination">
      <pagination-controls (pageChange)="currentPage = $event;getRecruiters(currentPage)"
        [autoHide]="count <= requestPayload.limit" nextLabel="{{'next' | translate}}"
        previousLabel="{{'previous' | translate}}" responsive="true"></pagination-controls>
    </div>

    <div *ngIf="false">
      <div *ngIf="currentPage == 1 && !filtered&&activeTab==0" class="row pr-4 pt-3">
        <div *ngFor="let recruiter of recruiters" class="col-12 pb-3 overflow-auto">
          <div class="card pt-4 pb-4 pl-3 pb-4">
            <div class="row" style="display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-content: flex-start;
                    justify-content: space-between;
                    align-items: center;">
              <div class="pb-xl-0 pb-md-4 pb-4">
                <div class="" style="display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: flex-start;
                        align-content: flex-start;
                        align-items: center;">
                  <div class="pl-md-4 pl-3" style="flex: 0 0 18rem;">
                    <div class="" style="    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;">
                      <div style="display: flex;
                              flex-direction: row;
                              flex-wrap: nowrap;
                              align-content: flex-start;
                              justify-content: flex-start;
                              align-items: center;">
                        <div [style.background-color]="recruiter.imageColor" id="preimage"><span
                            style="color: white;">{{recruiter.imageText}}</span></div>
                        <div class="ml-4" style="display: flex;
                              flex-direction: column;
                              flex-wrap: nowrap;
                              align-content: flex-start;
                              justify-content: flex-start;
                              align-items: flex-start;">
                          <div style="display: flex;
                                flex-direction: column;
                                flex-wrap: nowrap;
                                align-content: flex-start;
                                justify-content: flex-start;
                                align-items: flex-start;">
                            <span class="users">{{recruiter.user?.fullName}}</span>
                            <span class="card-titles"><span *ngIf="recruiter && recruiter.type"> {{recruiter.type |
                                translate}} </span> <span *ngIf="!recruiter || !recruiter.type">{{ 'notSpecified' |
                                translate}}</span></span>
                          </div>
                          <div style="display: flex;
                                flex-direction: row;
                                flex-wrap: nowrap;
                                align-content: flex-start;
                                justify-content: flex-start;
                                align-items: flex-start;">
                            <img src="../../../assets/images/recomended.png">
                            <span class="recomended-text ml-1">{{'recomendedByTalentbase' | translate}}</span>
                          </div>
                        </div>
                      </div>
                      <div class=" pt-3" style="display: flex;
                                flex-direction: row;
                                flex-wrap: nowrap;
                                align-content: flex-start;
                                justify-content: flex-start;
                                align-items: center;">
                        <span
                          [ngClass]="recruiter?.rating > 0 ? recruiter?.rating < 1 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                        <span
                          [ngClass]="recruiter?.rating > 1 ? recruiter?.rating < 2 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                        <span
                          [ngClass]="recruiter?.rating > 2 ? recruiter?.rating < 3 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                        <span
                          [ngClass]="recruiter?.rating > 3 ? recruiter?.rating < 4 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                        <span
                          [ngClass]="recruiter?.rating > 4 ? recruiter?.rating < 5 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                          style="color: #F1B856;"></span>
                        <!-- <span class="far fa-star" style="color: #F1B856;"></span> -->
                      </div>

                    </div>
                  </div>
                  <div class="ml-5" style="display: flex;
                          flex-direction: column;
                          flex-wrap: wrap;
                          align-content: flex-start;
                          justify-content: flex-start;
                          align-items: flex-start;">
                    <div class="inds-text" style="display: flex;
                              flex-direction: column;
                              flex-wrap: wrap;
                              align-content: flex-start;
                              justify-content: flex-start;
                              align-items: flex-start;
                              max-height: 3.5rem;
                              overflow: hidden;">
                      {{recruiter.aboutMe}}
                      <!--<span *ngFor="let ind of recruiter.industries"
                            class="inds-text">
                      {{ind.name}}.<br>
                    </span>-->
                    </div>
                    <p class="desc mt-2">

                      {{'inMemberSince' | translate}} {{recruiter.createdAt | date}}
                    </p>

                  </div>
                </div>
              </div>
              <div class="pt-xl-5" style="display: flex;
                                      flex-direction: row;
                                      flex-wrap: nowrap;
                                      align-content: flex-start;
                                      justify-content: flex-start;
                                      align-items: center;flex: 0 0 31.5rem;">
                <div style="display: flex;
                              flex-direction: row;
                              flex-wrap: nowrap;
                              justify-content: flex-start;
                              align-items: stretch;">

                  <div class="mx-3" style="    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;">
                    <div class="right-text">
                      <span class="numbers">{{recruiter?.applicationCounts?.hired}}</span>
                    </div>
                    <div class="right-value-text right-circle">
                      <p class="status-text">{{'hired'|translate}}</p>
                    </div>
                  </div>
                  <div class="" style="text-align: -webkit-center; border-right: 1px solid #EBEEF2;"></div>
                  <div class="mx-3" style="    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;">
                    <div class="right-text">
                      <span class="numbers">{{recruiter?.applicationCounts?.interviewStage}}</span>
                    </div>
                    <div class="right-value-text right-circle">
                      <p class="status-text">{{'interviewed'|translate}}</p>
                    </div>
                  </div>
                  <div class="" style="text-align: -webkit-center; border-right: 1px solid #EBEEF2;"></div>
                  <div class="mx-3" style="    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;">
                    <div class="right-text">
                      <span class="numbers">{{recruiter?.applicationCounts?.rejectedByEmployer}}</span>
                    </div>
                    <div class="right-value-text right-circle">
                      <p class="status-text">{{'rejected'|translate}}</p>
                    </div>
                  </div>
                </div>

                <div class="mx-3" style="    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: center;">
                  <div (click)="openDetailModal(recruiter, false, true)" class="text-center align-self-center">
                    <a class="btn viewContactButton">{{'viewContactProfile'|translate}}</a>
                  </div>
                  <div class="text-center px-4 align-self-center">
                    <i (click)="unFavoriteRecruiter(recruiter)" *ngIf="recruiter.favorite" class="fas fa-bookmark"></i>
                    <i (click)="favoriteRecruiter(recruiter)" *ngIf="!recruiter.favorite" class="far fa-bookmark"></i>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="row pr-4 pt-3 ">
        <div
          *ngFor="let recruiter of otherRecruiters  | paginate:{ itemsPerPage: requestPayload.limit, currentPage: currentPage,totalItems:count}"
          class="col-12 pb-3 overflow-auto">
          <div class="card pt-4 pb-4 pl-3 pb-4">
            <div class="row" style="display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-content: flex-start;
                    justify-content: space-between;
                    align-items: center;">
              <div class="" style="display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: flex-start;
                        align-content: flex-start;
                        align-items: center;">
                <div class="pl-md-4 pl-3" style="flex: 0 0 18rem;">
                  <div class="" style="    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;">
                    <div style="display: flex;
                              flex-direction: row;
                              flex-wrap: nowrap;
                              align-content: flex-start;
                              justify-content: flex-start;
                              align-items: center;">
                      <div [style.background-color]="recruiter.imageColor" id="preimage"><span
                          style="color: white;">{{recruiter.imageText}}</span></div>
                      <div class="ml-4" style="display: flex;
                              flex-direction: column;
                              flex-wrap: nowrap;
                              align-content: flex-start;
                              justify-content: flex-start;
                              align-items: flex-start;">
                        <div style="display: flex;
                                flex-direction: column;
                                flex-wrap: nowrap;
                                align-content: flex-start;
                                justify-content: flex-start;
                                align-items: flex-start;">
                          <span class="users">{{recruiter.user?.fullName}}</span>
                          <span class="card-titles"><span *ngIf="recruiter && recruiter.type"> {{recruiter.type |
                              translate}} </span> <span *ngIf="!recruiter || !recruiter.type">{{ 'notSpecified' |
                              translate}}</span></span>
                        </div>
                        <div *ngIf="recruiter.recommended" style="display: flex;
                                flex-direction: row;
                                flex-wrap: nowrap;
                                align-content: flex-start;
                                justify-content: flex-start;
                                align-items: flex-start;">
                          <img src="../../../assets/images/recomended.png">
                          <span class="recomended-text ml-1">{{'recomendedByTalentbase' | translate}}</span>
                        </div>
                      </div>
                    </div>
                    <div class=" pt-3" style="display: flex;
                                flex-direction: row;
                                flex-wrap: nowrap;
                                align-content: flex-start;
                                justify-content: flex-start;
                                align-items: center;">
                      <span
                        [ngClass]="recruiter?.rating > 0 ? recruiter?.rating < 1 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                        style="color: #F1B856;"></span>
                      <span
                        [ngClass]="recruiter?.rating > 1 ? recruiter?.rating < 2 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                        style="color: #F1B856;"></span>
                      <span
                        [ngClass]="recruiter?.rating > 2 ? recruiter?.rating < 3 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                        style="color: #F1B856;"></span>
                      <span
                        [ngClass]="recruiter?.rating > 3 ? recruiter?.rating < 4 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                        style="color: #F1B856;"></span>
                      <span
                        [ngClass]="recruiter?.rating > 4 ? recruiter?.rating < 5 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"
                        style="color: #F1B856;"></span>
                      <!-- <span class="far fa-star" style="color: #F1B856;"></span> -->
                    </div>

                  </div>
                </div>
                <div class="ml-5" style="display: flex;
                          flex-direction: column;
                          flex-wrap: wrap;
                          align-content: flex-start;
                          justify-content: flex-start;
                          align-items: flex-start;">
                  <div class="inds-text" style="display: flex;
                              flex-direction: column;
                              flex-wrap: wrap;
                              align-content: flex-start;
                              justify-content: flex-start;
                              align-items: flex-start;
                              max-height: 3.5rem;
                              overflow: hidden;">
                    {{recruiter.aboutMe}}
                    <!--<span *ngFor="let ind of recruiter.industries"
                          class="inds-text">
                    {{ind.name}}.<br>
                  </span>-->
                  </div>
                  <div class="inds-text" style="display: flex;
                              flex-direction: column;
                              flex-wrap: wrap;
                              align-content: flex-start;
                              justify-content: flex-start;
                              align-items: flex-start;
                              max-height: 3.5rem;
                              overflow: hidden;">
                    {{recruiter.aboutMe}}
                    <!--<span *ngFor="let ind of recruiter.industries"
                          class="inds-text">
                    {{ind.name}}.<br>
                  </span>-->
                  </div>
                  <p class="desc mt-2">

                    {{'inMemberSince' | translate}} {{recruiter.createdAt | date}}
                  </p>

                </div>
              </div>
              <div class="pt-xl-5" style="display: flex;
                                      flex-direction: row;
                                      flex-wrap: nowrap;
                                      align-content: flex-start;
                                      justify-content: flex-start;
                                      align-items: center;flex: 0 0 31.5rem;">
                <div style="display: flex;
                              flex-direction: row;
                              flex-wrap: nowrap;
                              justify-content: flex-start;
                              align-items: stretch;">

                  <div class="mx-3" style="    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;">
                    <div class="right-text">
                      <span class="numbers">{{recruiter?.applicationCounts?.hired}}</span>
                    </div>
                    <div class="right-value-text right-circle">
                      <p class="status-text">{{'hired'|translate}}</p>
                    </div>
                  </div>
                  <div class="" style="text-align: -webkit-center; border-right: 1px solid #EBEEF2;"></div>
                  <div class="mx-3" style="    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;">
                    <div class="right-text">
                      <span class="numbers">{{recruiter?.applicationCounts?.interviewStage}}</span>
                    </div>
                    <div class="right-value-text right-circle">
                      <p class="status-text">{{'interviewed'|translate}}</p>
                    </div>
                  </div>
                  <div class="" style="text-align: -webkit-center; border-right: 1px solid #EBEEF2;"></div>
                  <div class="mx-3" style="    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;">
                    <div class="right-text">
                      <span class="numbers">{{recruiter?.applicationCounts?.rejectedByEmployer}}</span>
                    </div>
                    <div class="right-value-text right-circle">
                      <p class="status-text">{{'rejected'|translate}}</p>
                    </div>
                  </div>
                </div>

                <div class="mx-3" style="    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: center;">
                  <div (click)="openDetailModal(recruiter, false, true)" class="text-center align-self-center">
                    <a class="btn viewContactButton">{{'viewContactProfile'|translate}}</a>
                  </div>
                  <div class="text-center px-4 align-self-center">
                    <i (click)="unFavoriteRecruiter(recruiter)" *ngIf="recruiter.favorite" class="fas fa-bookmark"></i>
                    <i (click)="favoriteRecruiter(recruiter)" *ngIf="!recruiter.favorite" class="far fa-bookmark"></i>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>


        <div *ngIf="otherRecruiters && otherRecruiters.length > 0" class=" container-fluid row mt-3 pagination">
          <pagination-controls (pageChange)="currentPage = $event;getRecruiters(currentPage)"
            [autoHide]="count <= requestPayload.limit" nextLabel="{{'next' | translate}}"
            previousLabel="{{'previous' | translate}}" responsive="true"></pagination-controls>
        </div>
      </div>
    </div>

  </div>


</div>

<app-recruiters-detail-modal [cameFromReviews]="cameFromReviews" [recruiter]="selectedRec">
</app-recruiters-detail-modal>
