import { PhoneNumberModel }   from '../parameter';
import { GeneralIdNameModel } from './../general/general-id-name.model';
import { UserPostModel }      from '../user';

export class RecruiterUserModel {

  createdAt: string;
  createdBy: GeneralIdNameModel;
  talentbaseReferenceType: string;
  functionalRoles: GeneralIdNameModel[];
  id: string;
  individualPracticeTime: string;
  industries: GeneralIdNameModel[];
  paymentReferenceType: string;
  placementCounts: any;
  primaryContactNumber: PhoneNumberModel;
  requisitionTypes: string[];
  secondaryContactNumber: PhoneNumberModel;
  type: string;
  updatedAt: string;
  updatedBy: GeneralIdNameModel;
  user: UserPostModel;

  functionalRolesText: string;
  industriesText: string;
  requisitionTypesText: string;

  fullName: string;
  star: number;
  reviewCount: number;
  date: string;
  reviews: any[] = [];
  imageText: string;
  imageColor: string;
  applicationCounts: any;
  rating: number;
  totalPlacements: number;
  recommended: boolean;
  favorite: boolean;
  profilePhotoUrl: string;
  about: string;
  aboutMe: string;

  constructor() {

    this.createdAt = '';
    this.createdBy = null;
    this.talentbaseReferenceType = '';
    this.functionalRoles = [];
    this.id = '';
    this.individualPracticeTime = '';
    this.industries = [];
    this.paymentReferenceType = '';
    this.placementCounts = null;
    this.primaryContactNumber = new PhoneNumberModel();
    this.requisitionTypes = [];
    this.secondaryContactNumber = new PhoneNumberModel();
    this.type = '';
    this.updatedAt = '';
    this.updatedBy = new GeneralIdNameModel();
    this.user = new UserPostModel();

    this.fullName = '';
    this.star = 0;
    this.reviewCount = 0;
    this.date = '';
    this.applicationCounts = { hired: 0, rejectedByEmployer: 0, interviewStage: 0 };
    this.rating = 0;
    this.totalPlacements = 0;
    this.recommended = false;
    this.profilePhotoUrl = null;
    this.about = 'No information has been entered yet!';
    this.aboutMe = '';
  }
}
