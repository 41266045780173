export enum ExperienceLevelType{
  INTERNSHIP = "INTERNSHIP",
  ENTRY_LEVEL = "ENTRY_LEVEL",
  ASSOCIATE = "ASSOCIATE",
  MID_SENIOR = "MID_SENIOR",
  DIRECTOR = "DIRECTOR",
  EXECUTIVE = "EXECUTIVE",
  NOT_APPLICABLE = "NOT_APPLICABLE",
  MID_LEVEL = "MID_LEVEL",
  JUNIOR_LEVEL = "JUNIOR_LEVEL"
}
