<div class="row box mt-3">
  <div class="col-12 results-text mt-2 ml-2">
    {{ 'results' | translate }}
  </div>
  <div class="col-12 pr-4 pl-4 mb-3">
    <div *ngFor="let job of jobs" class="container card mt-3" style="border: 1px solid #EDECE7;">
      <div class="card-body">
        <div class="container-fluid">
          <!-- <div class="row"> -->
            <div class="row">
              <div class="title-text">{{job.title}}</div>
              <!-- <span *ngIf="job.tags && job.tags.length > 0"> -->
                <div *ngIf="job.tags && job.tags.length > 0" class="ml-3" >
                  <span *ngFor="let tag of job.tags" class="badge badge-orange ml-3"><i class="fas fa-atom mr-1"></i>{{tag.value}}</span>
                </div>
              <!-- </span> -->

              <span *ngIf="!job.tags || job.tags.length == 0" class="badge badge-orange ml-3"><i class="fas fa-atom mr-1"></i>{{job.industry.name}}</span>
            </div>


          <!-- </div> -->
          <div class="row mt-3">

            <div class="col-9">
              <div class="row">

                <span *ngIf="job.employer" class="col-4 info-text"><i class="fas fa-briefcase"></i> {{job.employer.name}}</span>
                <span *ngIf="!job.employer" class="col-4 info-text"><i class="fas fa-user-cog"></i> {{'createdByAdmin' | translate}}</span>
                <span class="col-4 info-text"><i class="fas fa-map-marker-alt"></i> {{job.location.city.name}},
                  {{job.location.country.name}}</span>
                <span class="col-4 info-text"><i class="far fa-calendar"></i> {{job.createdAt | date}}</span>
              </div>
            </div>

            <div class="col-3">
              <button (click)="useProfile(job)" class="button create-job-btn" style="float: right;">{{ 'createAJob' | translate }}</button>
            </div>

          </div>
        </div>

      </div>
    </div>

  </div>

</div>
