import { Component, OnInit }                                                  from '@angular/core';
import { IndustryService, JobService, JobsProfileService, EmployerMeService } from 'src/app/shared/services';

@Component({
             selector: 'app-post-a-job',
             templateUrl: './post-a-job.component.html',
             styleUrls: ['./post-a-job.component.scss']
           })
export class PostAJobComponent implements OnInit {

  activeTab: number = 2;
  header: string = 'createNewJobProfile';
  jobCount: number = 0;
  industryCount: number = 0;
  jobId = null;
  employerId = '';

  constructor(private jobService: JobService, private industryService: IndustryService,
              private jobProfileService: JobsProfileService,
              private employerMeService: EmployerMeService
  ) {
  }

  ngOnInit() {
    this.getEmployerId();
  }

  getEmployerId() {
    this.employerMeService.ServerGet()
        .subscribe(x => {
          this.employerId = x.id;
          this.getCounts();
        });
  }

  getCounts() {
    this.industryService.ServerGet()
        .subscribe(x => {
          this.industryCount = x.totalElements;
        });

    this.jobProfileService.ServerGetWithText('/?filter=type::EMPLOYER_SPECIFIC&filter=employerId::' + this.employerId)
        .subscribe(x => {
          this.jobCount = x.totalElements;
        });
  }

  changeTab(tabIndex, header) {
    this.jobId = null;
    this.activeTab = tabIndex;
    this.header = header;
  }

  useProfile(event) {
    this.jobId = event;
    this.activeTab = 2;
  }

}
