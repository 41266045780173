<div id="faq" class="section pricing-section" style="background-color: #F5F7FA;">
  <div class="container-fluid px-1 px-xl-5 py-5">
    <div class="px-1 px-xl-5">
      <div class="section-header">
        <div style="color: black" class="faq-header">{{'frequentlyAskedQuestions' | translate}}</div>
      </div>
      <div class="col-12" style="display: flex; justify-content: center;">
        <div *ngIf="categories && categories.length > 0" class="switch6 mt-3 mb-4">
          <label class="switch6-light" onclick="">
            <input [(ngModel)]="selectedCat" type="checkbox" (click)="selectCategory(selectedCat)">
            <span>
              <span [ngClass]="!selectedCat ? 'white-text' : 'gray-text'">{{ categories[0].name | uppercase }}</span>
              <span [ngClass]="selectedCat ? 'white-text' : 'gray-text'">{{ categories[1].name | uppercase }}</span>
            </span>
            <a class="swtich-button"></a>
          </label>
        </div>
      </div>
      <div class="faq-box">
        <div class="accordion" id="accordionExample">
          <div class="card" *ngFor="let item of questions">
            <div class="card-header" [id]="'heading' + item.id">
              <button class="btn btnk" type="button" data-toggle="collapse" [attr.data-target]="'#collapse' + item.id"
                aria-expanded="true" [attr.aria-controls]="'collapse' + item.id" (click)="setIcon(item.id)">
                <div class="row">
                  <div class="col-11"> {{item.question}}</div>
                  <div class="col-1"> <i [ngClass]="item.show ? 'fas fa-times' : 'fa fa-plus'"
                      style="float: right;"></i></div>
                </div>
              </button>
            </div>
            <div [id]="'collapse'+ item.id" class="collapse" [attr.aria-label]="'heading' + item.id"
              data-parent="#accordionExample">
              <div class="card-body ml-5 content">
                {{item.answer}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
