import { Component, OnInit, Input }     from '@angular/core';
import { Package, UsageRestriction }    from '../../../../shared/models/subscription/package';
import { BusinessFunctionModel }        from '../../../../shared/models/subscription/business-function-model';
import { CurrencyModel }                from '../../../../shared/models';
import { PackageService }               from '../../../../shared/services/subscription/package/package.service';
import { UserTypeEnum }                 from '../../../../shared/enums/user/user-type.enum';
import { PackageStatusEnum }            from '../../../../shared/models/subscription/package-status.enum';
import { CurrencyService, NotificationService, server, TranslatePipe }      from '../../../../shared/services';
import { BusinessRoleService }          from '../../../../shared/services/subscription/business-role/business-role.service';
import { PackageFunctionalRoleService } from '../../../../shared/services/subscription/functional-role/package-functional-role.service';
import { CommissionTypeEnum }           from '../../../../shared/models/subscription/commission-type.enum';
import { CommissionModel }              from '../../../../shared/models/subscription/commission-model';
import { PackageCycleEnum }             from '../../../../shared/models/subscription/package-cycle.enum';
import { BusinessFunctionStatusEnum }   from '../../../../shared/models/subscription/business-function-status.enum';

@Component({
             selector: 'app-admin-packet-edit',
             templateUrl: './admin-packet-edit.component.html',
             styleUrls: ['./admin-packet-edit.component.scss']
           })
export class AdminPacketEditComponent implements OnInit {

  newPacket: Package = new Package();
  selectedPacket: Package = new Package();
  packets: Package [] = [];
  allBusinessFunctions: BusinessFunctionModel[] = [];
  allBusinessFunctionsList: BusinessFunctionModel[] = [];
  selectedBusinessFunction: BusinessFunctionModel = new BusinessFunctionModel();
  currencies: CurrencyModel [] = [];
  allFunctionalRoles: string[] = [];
  allFunctionalRolesList: string[] = [];
  selectedFunctionalRole = '';
  private privateUserType: UserTypeEnum = UserTypeEnum.RECRUITER;

  constructor(private packageService: PackageService,
              private currencyService: CurrencyService,
              private businessRoleService: BusinessRoleService,
              private functionalRoleService: PackageFunctionalRoleService,
              private notification: NotificationService, private translate: TranslatePipe) {
  }

  get userType(): UserTypeEnum {
    return this.privateUserType;
  }

  @Input() set userType(value: UserTypeEnum) {
    this.privateUserType = value;
    this.getPackets();
  }

  get UserTypeEnum(): typeof UserTypeEnum {
    return UserTypeEnum;
  }

  get PackageStatusEnum(): typeof PackageStatusEnum {
    return PackageStatusEnum;
  }

  get PackageCycleEnum(): typeof PackageCycleEnum {
    return PackageCycleEnum;
  }

  get CommissionTypeEnum(): typeof CommissionTypeEnum {
    return CommissionTypeEnum;
  }

  ngOnInit(): void {
    this.getAllCurrencies();
    this.getAllBusinessRoles();
    this.getAllFunctionalRoles();
  }

  getPackets(): void {
    this.packageService.ServerGetWithText(`?filter=type::${this.userType}&filter=status!:${PackageStatusEnum.DELETED}`)
        .subscribe(x => {
          this.packets = x.content;
          this.packets.forEach(packet => {
            if (packet.currency) {
              this.currencies.forEach(currency => {
                if (currency.id === packet.currency.id) {
                  packet.currency = currency;
                }
              });
            }
          });
        });
  }

  changePacket(): void {
    if (this.selectedPacket.id === '') {
      // this.newPacket = new Package();
    }

    this.allBusinessFunctions = [...this.allBusinessFunctionsList];
    this.selectedPacket.businessFunctions.forEach(businessFunction => {
      if (businessFunction) {
        this.allBusinessFunctions.forEach(businessFunctionFromAll => {
          if (businessFunctionFromAll.id === businessFunction.id) {
            businessFunction = businessFunctionFromAll;
          }
        });
        const index = this.allBusinessFunctions.indexOf(businessFunction as BusinessFunctionModel);
        this.allBusinessFunctions.splice(index, 1);
      }
    });
    this.selectedBusinessFunction = this.allBusinessFunctions[0];

    this.allFunctionalRoles = [...this.allFunctionalRolesList];
    this.selectedPacket.usageRestrictions.forEach(usageRestriction => {
      let index = -1;
      this.allFunctionalRoles.forEach((functionalRole, i) => {
        if (usageRestriction.functionalRole === functionalRole) {
          index = i;
        }
      });

      this.allFunctionalRoles.splice(index, 1);
    });
    this.selectedFunctionalRole = this.allFunctionalRoles[0];

    if (this.selectedPacket.commission == null) {
      this.selectedPacket.commission = new CommissionModel();
    }

  }

  selectBusinessFunction(): void {
    const businessFunction = this.selectedBusinessFunction;
    const index = this.allBusinessFunctions.indexOf(businessFunction);
    if (index < 0) {
      return;
    }
    this.allBusinessFunctions.splice(index, 1);
    this.selectedPacket.businessFunctions.push(businessFunction);
    this.selectedBusinessFunction = new BusinessFunctionModel();
  }

  unSelectBusinessFunction(businessFunction: BusinessFunctionModel, i: number): void {
    this.selectedPacket.businessFunctions.splice(i, 1);
    this.allBusinessFunctions.push(businessFunction);
  }

  getAllBusinessRoles(): void {
    this.businessRoleService.ServerGetWithText(`?filter=status::${BusinessFunctionStatusEnum.ACTIVE}`)
        .subscribe(x => {
          this.allBusinessFunctions = x.content;
          this.allBusinessFunctionsList = x.content;
        });
  }

  save(): void {
    /*   Object.keys(this.selectedPacket)
             .forEach(key => {
               const value = this.selectedPacket[key];
               if (value === '') {
                 this.selectedPacket[key] = null;
               }
             });*/

    if (this.selectedPacket.id === '') {
      this.packageService.ServerPost(this.selectedPacket)
          .subscribe(x => {
            this.notification.success(this.translate.transform('newPackageSuccessfullyAdded'));
            this.getPackets();
          });
    } else {
      this.packageService.ServerPut(server.packages + '/' + this.selectedPacket.id, this.selectedPacket)
          .subscribe(x => {
            this.notification.success(this.translate.transform('newPackageSuccessfullyUpdated'));
            this.getPackets();
          });
    }

  }

  selectUsageRestriction(): void {
    const functionalRole = this.selectedFunctionalRole;
    const index = this.allFunctionalRoles.indexOf(functionalRole);
    if (index < 0) {
      return;
    }
    this.allFunctionalRoles.splice(index, 1);
    const usageRestriction = new UsageRestriction();
    usageRestriction.functionalRole = functionalRole;
    usageRestriction.countLimit = 0;
    this.selectedPacket.usageRestrictions.push(
      usageRestriction
    );
    this.selectedFunctionalRole = '';
  }

  unSelectUsageRestriction(usageRestriction: UsageRestriction, i: number): void {
    this.selectedPacket.usageRestrictions.splice(i, 1);
    this.allFunctionalRoles.push(usageRestriction.functionalRole);
  }

  openDeletePacket() {
    // @ts-ignore
    $('#generalDeleteModal').modal('show');
 }


  delete(e): void {
    if (this.selectedPacket.id !== '' && e) {
      this.packageService.ServerDelete(`${server.packages}/${this.selectedPacket.id}`)
          .subscribe(() => {

            this.getPackets();

          });
    }
  }

  private getAllCurrencies(): void {
    this.currencyService.ServerGet()
        .subscribe(x => {
          this.currencies = x.content;
        });
  }

  private getAllFunctionalRoles(): void {
    this.functionalRoleService.ServerGet()
        .subscribe(x => {
          this.allFunctionalRoles = x;
          this.allFunctionalRolesList = x;
        });
  }
}
