import { JobVisaRequirementModel } from './job-visa-requirement.model';
import { JobRelocationAssistanceModel } from './job-relocation-assistance.model';
import { JobLocationModel } from '.';


export class JobLocationsModel {

  id: string;
  relocationAssistance: JobRelocationAssistanceModel;
  visaRequirement: JobVisaRequirementModel;
  location: JobLocationModel;

constructor() {
  this.id = "";
  this.relocationAssistance = new JobRelocationAssistanceModel();
  this.visaRequirement = new JobVisaRequirementModel();
  this.location = new JobLocationModel();
}
}
