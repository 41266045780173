<div class="col-12 col-xl-9 col-xxl-8 m-auto px-md-5 px-xl-0">
  <div class="row">
    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12  mt-md-7 d-none d-lg-flex">
      <div class="">
        <div class="d-none d-md-block d-lg-block d-xl-block">
          <div style="width: 401px;">
            <span class="header">Find a job & grow your career!</span>
          </div>

          <div style="width: 458px;" class="mt-3">
            <span class="desc-text">
              Lorem ipsum dolor sit amet, sea putant suscipit invidunt cu.
            </span>
          </div>

        </div>

        <div class="pt-5 d-none d-md-block d-lg-block d-xl-block">
          <img src="./../../../../assets/images/talentbase/left-bg.svg" width="435" height="303"/>
        </div>
      </div>
    </div>

    <div *ngIf="!showRegister" class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mt-7">
      <div class="card border-0 custom-card">
        <div class="row">
          <div class="col-12">
            <span class="joinText">{{ "joinUs" | translate }}</span>
          </div>
          <div class="col-12 mt-3">
             <span class="joinSubText mt-2">
          {{ "toBeginThisJourneyTellUs" | translate }}
        </span>
          </div>
          <div
            class="col-12 py-4 mt-5 "
            [ngClass]="type == 'RECRUITER' ? 'box-active' : 'box'"
            (click)="changeType('RECRUITER')"
          >
            <div class="row">
              <div class="col-12 col-sm-2 text-center text-sm-left">
                <img src="assets/images/chooseRecruiter.png" alt="" width="75"/>
              </div>
              <div class="col-12 col-sm-8 align-self-center  text-center text-sm-left">
                <span class="type-title d-block">{{ "recruiter" | translate }}</span>
                <span class="type-desc d-block">{{ "recruiterDesc" | translate }}</span>
              </div>
              <div
                *ngIf="type == 'RECRUITER'"
                class="col-12 col-sm-2 align-self-center text-center text-sm-right mt-3 mt-sm-0"
                (click)="showRegister = true"
              >
                <i class="fas fa-arrow-right pr-0 pr-sm-4" style="color: #3652e3"></i>
              </div>
            </div>
          </div>
          <div
            class="col-12 py-4 mt-3 "
            [ngClass]="type == 'EMPLOYER_MANAGER' ? 'box-active' : 'box'"
            (click)="changeType('EMPLOYER_MANAGER')"
          >
            <div class="row">
              <div class="col-12 col-sm-2 text-center text-sm-left">
                <img src="assets/images/chooseEmployer.png" alt="" width="75"/>
              </div>
              <div class="col-12 col-sm-8 align-self-center  text-center text-sm-left">
                <span class="type-title d-block">{{ "employer" | translate }}</span>
                <span class="type-desc d-block">{{ "employerDesc" | translate }}</span>
              </div>
              <div
                *ngIf="type == 'EMPLOYER_MANAGER'"
                class="col-12 col-sm-2 align-self-center text-center text-sm-right mt-3 mt-sm-0"
                (click)="showRegister = true"
              >
                <i class="fas fa-arrow-right pr-0 pr-sm-4" style="color: #3652e3"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div *ngIf="showRegister" class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12  mt-7">

      <div class="card border-0 custom-card-reg">
        <div class="row">
          <div class="col-12">
               <span class="createText ml-2">{{
                 "createAccount" | translate
                 }}</span>
          </div>
          <div class="col-12">
         <span class="mt-2 bottomCreateText ml-2">
        {{ "topJobBoardsForProfessionals" | translate }}
      </span>
          </div>
          <div class="col-12">
            <tlb-form
              *ngIf="formModel && formModel.length > 0 && formGroup"
              [forms]="formModel"
              [formGroup]="formGroup"
            ></tlb-form>
          </div>
<div class="col-12">
  <div class="row ml-3 mb-4" style="margin-top: -32px">
    <div>
      <input
        [(ngModel)]="model.termsAgreement"
        type="checkbox"
        id="filledInCheckbox"
      /><label for="filledInCheckbox" class="ml-2">{{
      "iAgreeWith" | translate
      }}</label>
      <label class="ml-1" for="filledInCheckbox" style="color: blue"
      ><a
        target="_blank"
        href="https://www.apple.com/legal/internet-services/terms/site.html"
      >
        {{ "termsOfUse" | translate }}</a
      >
      </label>
    </div>
  </div>
</div>
<div class="col-12">
  <button
    (click)="signUp()"
    class="btn createBtn ml-3"
    id="next"
    type="button"
    [disabled]="
          formGroup && (formGroup.invalid || model.termsAgreement == false)
        "
  >
    {{ "createAccount" | translate }}
  </button>
</div>



        </div>


      </div>

    </div>


    <div class="mt-5"></div>
  </div>

  <div class="success-modal" *ngIf="showSuccessSignUp">
    <div class="success-modal-overlay" (click)="goLogin()"></div>
    <div class="container h-100">
      <div class="row h-100">
        <div class="col-xl-6 m-auto">
          <div class="card shadow">
            <div class="card-body" style="text-align: center">
              <img src="assets/images/mail-sent.png" alt=""/>
              <p class="thank-you-text mt-3">{{ "thankYouForApplying" | translate }}</p>
              <div style="display: flex; justify-content: center">
                <p class="mail-sent-text">
                  {{
                  "aVerificationMailHasBeenSentToYourEmailAccountPleaseCheckYourInboxToVerify"
                    | translate
                  }}
                </p>
              </div>

              <button class="resend-button mt-5">
                {{ "resendVerificationMail" | translate }}
              </button>

              <p (click)="goLogin()" class="trouble-verify mt-5">{{ "proceedToLogin" | translate }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
