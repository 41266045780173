<app-main-header [HeaderMode]="'candidate'"></app-main-header>
<div class="container-fluid px-4 px-sm-5 pt-5 pt-sm-0  h-100">
  <div class="row h-100">
    <div class="col-12 col-xl-9 bg-light mx-auto my-auto shadow p-5 overflow-auto">
      <div class="card" style=" min-width:750px">
        <div class="card-body">
          <div class="row h-100 mt-5">
            <div class="col-12 background mx-auto my-auto shadow p-0 ">
              <div class="card"
                   style="border: none;">
                <div class="card-body">
                  <div class="row ml-1 headers">
                    {{ 'recruInfo' | translate }}
                  </div>

                  <div class="row ml-3 mt-3">
                    <div class="col-5"
                         style="border-right: 1px solid rgba(18, 17, 39, 0.12);">
                      <div class="row">
                        <div [style.background-color]="recruiter?.imageColor"
                             id="preimage"><span style="color: white;">{{recruiter?.imageText}}</span></div>

                        <div class="ml-4">
                          <div class="name-text">
                            {{recruiter?.user?.fullName}}
                          </div>

                          <div class="mt-1">
                            <i class="fas fa-map-marker-alt"></i>
                            <span class="rec-loc-text ml-2"> {{ 'notSpecified' | translate }} </span>
                          </div>

                          <div class="row"
                               style="margin-left: -3px; margin-top: -5px;">
                            <div class="font-size pb-3">
                              <div class="rate">
                                <input [(ngModel)]="star"
                                       type="radio"
                                       id="star5"
                                       name="rate"
                                       value="5" />
                                <label for="star5"
                                       title="5 stars">5 stars</label>
                                <input [(ngModel)]="star"
                                       type="radio"
                                       id="star4"
                                       name="rate"
                                       value="4" />
                                <label for="star4"
                                       title="4 stars">4 stars</label>
                                <input [(ngModel)]="star"
                                       type="radio"
                                       id="star3"
                                       name="rate"
                                       value="3" />
                                <label for="star3"
                                       title="3 stars">3 stars</label>
                                <input [(ngModel)]="star"
                                       type="radio"
                                       id="star2"
                                       name="rate"
                                       value="2" />
                                <label for="star2"
                                       title="2 stars">2 stars</label>
                                <input [(ngModel)]="star"
                                       type="radio"
                                       id="star1"
                                       name="rate"
                                       value="1" />
                                <label for="star1"
                                       title="1 star">1 star</label>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="col-7">
                      <div class="mt-3"
                           style="margin-left: 20%;">
                        <div class="row">
                          <div class="mt-2"><img src="assets/images/top-rec.png"
                                                 alt=""></div>
                          <div style="width: 257px;"
                               class="ml-3">
                            <span class="top-rec-text"> {{ 'topRecruiterForEmployers' | translate }} </span>
                            <span class="top-rec-text"
                                  style="font-weight: 400;"> {{ 'employersGaveThemStarReviews' | translate }} </span>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>

                  <div class="row mt-4">
                    <div class="col-12 col-sm-4">
                      <i class="fas fa-calendar icon icon-color mr-2"></i>
                      <span class="rec-headers">{{ 'recruitmentExperience' | translate }}</span>
                    </div>
                    <div class="col-12 col-sm-8 rec-headers"
                         style="font-weight: 300;">
                      {{recruiter?.individualPracticeTime | translate}}
                    </div>
                  </div>


                  <div class="row mt-2">
                    <div class="col-12 col-sm-4">
                      <i class="fas fa-handshake icon icon-color mr-2"></i>
                      <span class="rec-headers">{{ 'jobRecruisitionTypes' | translate }}</span>
                    </div>
                    <div class="col-12 col-sm-8 rec-headers"
                         style="font-weight: 300;">
                      {{recruiter?.requisitionTypesText}}
                    </div>
                  </div>


                  <div class="row mt-2">
                    <div class="col-12 col-sm-4">
                      <i class="fas fa-sticky-note icon icon-color mr-2"></i>
                      <span class="rec-headers">{{ 'functionalRoles' | translate }}</span>
                    </div>
                    <div class="col-12 col-sm-8 rec-headers"
                         style="font-weight: 300;">
                      {{recruiter?.functionalRolesText}}
                    </div>
                  </div>


                  <div class="row mt-2">
                    <div class="col-12 col-sm-4">
                      <i class="fas fa-building icon icon-color mr-2"></i>
                      <span class="rec-headers">{{ 'industries' | translate }}</span>
                    </div>
                    <div class="col-12 col-sm-8 rec-headers"
                         style="font-weight: 300;">
                      {{recruiter?.industriesText}}
                    </div>
                  </div>

                </div>
              </div>
              <br>
              <div class="card w-100"
                   style="border: none;">
                <div class="card-body">
                  <div class="row ml-1 headers">
                    {{'jobInfo' | translate}}
                  </div>
                  <div class="row mt-3">
                    <div>
                      <div class="row">
                        <div class="col-3">
                          <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-1 mb-3 mb-sm-0"><img
                            [src]="job?.logoUrl && job?.logoUrl != '' ? job.logoUrl : 'assets/images/default-logo.png'"
                            alt=""
                            width="50"
                            height="50"></div>
                        </div>
                        <div class="col-9">
                          <span class="job-name">{{job?.title}}</span> <br>
                          <span class="company-name">{{job?.employer?.name}}</span>
                        </div>
                      </div>

                    </div>
                    <div>
                      <div class="posted-div">
                <span class="posted-text">
                  {{'posted' | translate}} {{job?.postDate}} {{ 'daysAgo' | translate }}
                </span>
                      </div>
                    </div>
                  </div>
                  <br>
                  <div class="row ml-2">
                    <div class="mr-5">
                      <div class="loc-type-div mr-5">
                        <i class="fas fa-map-marker-alt mr-2"></i>
                        <span class="loc-type-text">{{'jobLocation' | translate
                          }}</span>: <span class="loc-type-text"
                                           style="font-weight: 400;">{{job?.location.city.name}},
                        {{job?.location.country.name}}</span>
                      </div>
                    </div>
                    <div class="ml-5">
                      <div class="loc-type-div ml-5">
                        <span class="loc-type-text">{{ job?.type | translate }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br>


              <div class="card w-100"
                   style="border: none;">
                <div class="card-body">
                  <div class="center">
                    <img src="assets/images/email-approve.png"
                         alt="">
                  </div>

                  <div class="center mt-5">
                    <div class="approve-text">
                      {{ 'theRecruiterSpecifiedAboveWantsToApplyToTheJobAboveOnBehalfYouDoYouApprove' | translate }}
                    </div>
                  </div>

                  <div class="row mt-5">
                    <div class="col-6">
                      <button type="button"
                              class="btn  btnReject"
                              (click)="reject()">{{'reject' | translate}}</button>
                    </div>
                    <div class="col-6">
                      <button type="button"
                              class="btn  btnApproval ml-2"
                              (click)="approve()">{{'approve' | translate}}</button>
                    </div>
                  </div>


                </div>
              </div>


            </div>
          </div>

        </div>
