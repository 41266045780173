import {PhoneNumberModel} from './../parameter/phone-number.model';
import {EmployerAddressModel} from '../employer';

export class RecruiterContactInfoModel {

  primaryContactNumber: PhoneNumberModel;
  secondaryContactNumber: PhoneNumberModel;
  address: EmployerAddressModel;
  taxNumber: number;
  taxOffice: string;

  constructor() {
  this.primaryContactNumber = new PhoneNumberModel();
  this.secondaryContactNumber = new PhoneNumberModel();
  this.address = new EmployerAddressModel();
  this.taxNumber = null;
  this.taxOffice = "";
  }
  }
