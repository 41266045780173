import { Component, OnInit } from '@angular/core';
import { Employer } from './employer';
import { CreateAvatar, RequestPayload } from '../../../shared/classes';
import { EmployerService } from 'src/app/shared/services/uaa/employer/employer.service';
import { DropdownOptionsModel } from 'src/app/shared/models';
import { TranslatePipe } from 'src/app/shared/services';

@Component({
  selector: 'app-admin-all-employers',
  templateUrl: './admin-all-employers.component.html',
  styleUrls: ['./admin-all-employers.component.scss']
})
export class AdminAllEmployersComponent implements OnInit {

  constructor(private employerService: EmployerService, private translate: TranslatePipe) {
    this.requestPayload = new RequestPayload();
    this.createAvatar = new CreateAvatar();
    this.requestPayload.limit = 10;

  }

  selectedEmployer: any;
  employerModel: Employer[] = [];
  requestPayload: RequestPayload;
  createAvatar: CreateAvatar;
  currentPage: number;
  count: number;
  filterText: string = "";
  sortLabel: string = this.translate.transform('newest');
  sortOptions: DropdownOptionsModel[] = [
    { label: this.translate.transform('newest'), value: '&sort=createdAt,DESC' },
    { label: this.translate.transform('oldest'), value: '&sort=createdAt,ASC' }
  ];
  sortText: string = "&sort=createdAt,DESC";
  fromSearch: boolean = false;


  ngOnInit(): void {

    this.getEmployers(1);
  }

  selectSort(data) {
    this.sortLabel = data.label;
    this.sortText = data.value;
    this.getEmployers(1);
  }


  openAdminEmployerModal(employer) {
    this.selectedEmployer = employer;
    // @ts-ignore
    $('#adminEmployerDetailModal').modal('show');
  }

  getEmployers(pageNo) {
    this.employerService.ServerGetWithText(this.requestPayload.payloadURL(pageNo) + this.sortText + "&filter=companyName~~" + this.filterText )
      .subscribe((res: any) => {
        this.fromSearch ? this.currentPage = 1 : null;
        this.fromSearch = false;
        this.employerModel = res.content;
        this.count = res.totalElements;

        this.employerModel.forEach(f => {
          f.imageText = this.createAvatar.create(f.createdBy.name);
          f.imageColor = this.createAvatar.color();
          f.industriesText = f.industries.map(m => ' ' + m.name).toString();
        });

      });
  }

  search() {
    this.fromSearch = true;
    this.getEmployers(1);
  }

  clearSearch(){
    this.filterText = "";
   this.search();
  }

}
