import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CityModel } from '../../models';
import { ApiService } from '../auth/api.service';
import { server } from '../server';

@Injectable({
    providedIn: 'root'
})

export class CityService extends ApiService{

  constructor(public http: HttpClient,public spinner:NgxSpinnerService,public translate:TranslatePipe, public notification:NotificationService) {
      super(http, server.cities,spinner,translate,notification)
  }

  getCountryCities(countryId: string): Observable<CityModel[]> {
    return this.ServerGetByIdWithoutLogin(server.countries + '/' + countryId + server.cities)
        .pipe(
            map((res: { content: CityModel[] }) => {
                return res.content;
            })
        );
}
}
