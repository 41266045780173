export class RecruiterModel {
    pageNo: number;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    phoneNumberCode: string;
    whoAreYou:number;
    recruimentFirmText:string;
    othersText:string;
    email: string;
    password: string;

    howLongHaveYouRecruited:number;

    typeOfJobRequisitions:number;

    contactNumberCode:string;
    primaryContactNumber:string;
    secondaryContactNumber:string;

    whatBringsYouToFlybx:number;

    paymentPreferences:number;

    specialization:boolean[];

    industrySpecialization:boolean[];

    location:boolean[];

    roleAtCompany:number;

    websiteAddress:string;

    linkedinCompanyPage:string;

    numberOfRecruiters:number;

    howLongHasCompanyBeenInStaffingAndRecruitingBussiness:number;

    whatTypeOfRecruitingAndStuffingServicesAreOfferedBy:number;


constructor() {
    this.pageNo = 1 ;
    this.firstName='';
    this.lastName='';
    this.phoneNumber='';
    this.phoneNumberCode='';
    this.whoAreYou=0;//0=individual rec, 1=Firm, 2=Other
    this.recruimentFirmText='';
    this.othersText='';
    this.email='';
    this.password='';
    this.howLongHaveYouRecruited=0;
    this.roleAtCompany=0;
    this.websiteAddress='';
    this.linkedinCompanyPage='';
    this.numberOfRecruiters=0;
    this.howLongHasCompanyBeenInStaffingAndRecruitingBussiness=0;
    this.whatTypeOfRecruitingAndStuffingServicesAreOfferedBy=0;
    this.typeOfJobRequisitions=0;
    this.contactNumberCode='';
    this.primaryContactNumber='';
    this.secondaryContactNumber='';
    this.whatBringsYouToFlybx=0;
    this.paymentPreferences=0;
    this.specialization=[];
    for(var i=0; i<17; i++) {
      this.specialization[i]=false;
    }
    this.industrySpecialization=[];
    for(var i=0; i<16; i++) {
      this.industrySpecialization[i]=false;
    }
    this.location=[];
    for(var i=0; i<50; i++) {
      this.location[i]=false;
    }

  }
}
