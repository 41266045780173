<div class="modal fade bd-example-modal-xl" [id]="modalId" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel">
  <div *ngIf="candidateModel" class="modal-dialog  modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title modal-body-header header" id="exampleModalLongTitle">EDIT CANDIDATE</div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">

          <div class="row mt-3">
            <div class="col-4">
              <div class="row position-relative">
                <div class="col-12">
                  <label id="candidate-info-label" class="input-label">{{'candidateInfo' | translate | titlecase
                    }}</label>
                </div>
                <div class="col-12">
                  <small id="candidate-info-label-help"
                    class="form-text text-muted">{{'jobTitlesMustDescribeOnePosition'| translate |
                    firstLetterCapital}}</small>
                </div>
              </div>
            </div>
            <div class="col-8">
              <div class="row">
                <div class="col-6">
                  <label for="first-name" class="input-label-2">{{'firstName' | translate | titlecase}}*</label>
                  <input type="text" class="form-control" id="first-name" [(ngModel)]="candidateModel.firstName">
                </div>
                <div class="col-6">
                  <label for="last-name" class="input-label-2">{{'lastName' | translate | titlecase}}*</label>
                  <input type="text" class="form-control" id="last-name" [(ngModel)]="candidateModel.lastName">
                </div>
                <div class="col-6 mt-2">
                  <label for="e-mail" class="input-label-2">{{'email' | translate | titlecase}}*</label>
                  <input type="text" class="form-control" id="e-mail" [(ngModel)]="candidateModel.email"  (keydown)="keyDownHandlerEmail($event)">
                </div>

                <div class="col-6 mt-2">
                  <label for="phone-number" class="input-label-2">{{'phoneNumber' | translate | titlecase}}*</label>
                  <div class="row">
                    <div id="tooltips" class="col-6" data-toggle="tooltip" data-placement="top" title="{{tooltip}}"
                      [attr.data-original-title]="tooltip">

                      <select class="form-control" (change)="selectNumberCode($event.target.value)"
                        [(ngModel)]="candidateModel.phoneNumber.countryCallingCode">
                        <option *ngFor="let opt of phoneNumberCodesOptions" [ngValue]="opt.value">{{opt.label}}
                        </option>
                      </select>


                    </div>
                    <div class="col-6">

                      <input type="text" class="form-control" id="phone-number" (keydown)="phoneNumberChars($event)" maxlength="15"
                        [(ngModel)]="candidateModel.phoneNumber.phoneNumber">


                    </div>
                  </div>

                </div>
              </div>


              <div class="row">
                <div class="col-12 mt-2">
                  <label class="label" for="skill-tags">{{ 'skillTags' | translate }}</label>
                </div>

                <div class="col-12">
                  <div class="dropdown drp ">
                    <input [(ngModel)]="skillTagText" aria-expanded="false" aria-haspopup="true"
                      class="form-group dropdown-toggles position-relative" data-target="dropdown" type="text"
                      #searchInput (keyup)="getSkills(searchInput.value)"
                      style="background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px; width: 100%; margin-bottom: -1px;" />
                    <div class="dropdown-menu col-12 pb-0" id="dropdown-menu" [ngStyle]="{'display':  skillTagText == '' ? 'none' : ''}">
                      <div class="col-12 drp-menu scrollable-menu p-0 ">
                        <div *ngFor="let item of skillsOptions">
                          <label (click)="selectSkill($event, item)"
                            class="dropdown-item m-0 cursor-pointer scrollable-menu">
                            <span class="ml-2">{{item.label | translate}}</span>
                          </label>

                        </div>
                        <div *ngIf="skillTagText != '' && skillsOptions.length == 0">
                          <label (click)="addSkill(skillTagText)"
                            class="dropdown-item m-0 cursor-pointer scrollable-menu">
                            <span class="ml-2"><i class="fas fa-plus"></i> {{'addNewSkill' | translate}}</span>
                          </label>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>


                <div *ngIf="skillTags && skillTags.length > 0" class="form-group" style="padding: 0px 15px 15px 15px;">
                  <label for="exampleInputEmail1" class="form-label"
                    style="font-family: Roboto; font-style: normal; font-weight: bold; font-size: 14px; line-height: 150%; text-transform: capitalize; color: #2B3A4B;">
                    {{ 'addedSkills' | translate }}
                  </label>

                  <br>
                  <span class="mail-badge ml-2" *ngFor="let tag of skillTags">
                    <span class="badge-text"> {{tag.name}} <i class="fas fa-times ml-1" style="color: #707683;"
                        (click)="deleteTag(tag)"></i> </span>
                  </span>

                </div>
              </div>


            </div>
          </div>
          <div class="row mt-5">
            <div class="col-4">
              <div class="row">
                <div class="col-12">
                  <label for="resume" id="resume-label" class="input-label">{{'uploadResume' | translate | titlecase
                    }}</label>
                </div>
                <div class="col-12">
                  <small id="resume-label-help" class="form-text text-muted">{{'jobTitlesMustDescribeOnePosition'|
                    translate | firstLetterCapital}}</small>
                </div>
              </div>
            </div>
            <div class="col-8">
              <div class="row">
                <div class="col-12">
                  <label for="resume" class="input-label-2">{{'uploadResume' | translate | titlecase }}</label>
                </div>
                <div class="col-12 position-relative">
                  <div class="w-100 cursor-pointer">
                    <input type="text" class="form-control" (dblclick)="openResume()"
                      [(ngModel)]="candidateModel.resume.title">
                  </div>

                  <label for="resume" class="file-label cursor-pointer">
                    Browse
                  </label>
                  <input type="file" class="d-none" id="resume" (change)="chooseResume($event)">
                </div>
              </div>


            </div>
          </div>
          <div class="row mt-5">
            <div class="col-4">
              <div class="row">
                <div class="col-12">
                  <label for="resume" id="cover-letter-label" class="input-label">{{'coverLetter' | translate |
                    titlecase }}</label>
                </div>
                <div class="col-12">
                  <small id="cover-letter-label-help" class="form-text text-muted">{{'jobTitlesMustDescribeOnePosition'|
                    translate | firstLetterCapital}}</small>
                </div>
              </div>
            </div>
            <div class="col-8">
              <div class="row">
                <div class="col-12">
                  <label for="cover-letter" class="input-label-2">{{'coverLetter' | translate | titlecase }}</label>
                  <label for="cover-letter-file" class="btn btn-upload-cover-letter">{{'uploadCoverLetter' | translate |
                    titlecase}}</label>
                  <input type="file" class="d-none" id="cover-letter-file" (change)="chooseCoverLetter($event)">
                </div>
                <div class="col-12" *ngIf="candidateModel.coverLetter.url!=null">
                  <a class="cursor-pointer color-brand" (click)="openCoverLetter()">{{'openCoverLetter' | translate
                    |titlecase}}</a>
                </div>
                <div class="col-12">
                  <textarea name="cover-letter" id="cover-letter" rows="5" class="form-control"
                    [(ngModel)]="candidateModel.coverLetter.content"></textarea>
                </div>
              </div>
            </div>
          </div>


        </div>

        <div class="modal-footer">
          <button class="btn btn-delete-candidate" (click)="openDeleteCandidate()">
            <b>{{ 'deleteCandidate' | translate }}</b></button>
          <button class="btn btn-save-candidate" [disabled]="disableControl()" (click)="postCandidate()"><b>{{ 'saveCandidate' | translate }}</b>
          </button>
        </div>
      </div>
    </div>
  </div>



  <!-- <app-delete-modal [Title]="'deleteCandidate'" [Text]="'theCandidateWillBeRemovedFromYourListAreYouSure'"
    (selection)="deleteCandidate($event)"></app-delete-modal> -->
