<div class="container-fluid">
  <div class="col-12 mb-3 mt-3">

    <div class="top-page mt-2 mb-4">
      <div class="header">
        {{ 'jobProfiles' | translate }}
      </div>
      <div class="sub-header mt-2 mb-4">
        {{ 'allJobProfilesListing' | translate }}
      </div>
    </div>

    <div class="showing-test">
      {{ 'showing' | translate }} {{ ' ' + count }} {{ 'resultOfJobProfiles' | translate }}
    </div>

    <div class="row mb-3">
      <div class="col-8 based-text">
        {{ 'basedOnYourPreferences' | translate }}
      </div>

      <div class="col-4">
        <div class="row" style="float: right;">
          <div class="mt-1 sort-by-text">{{ 'sortBy' | translate }}:</div>
          <div><tlb-dropdown [items]="sortOptions" [dropDownMenuWidth]="'16rem'"
            [style]="'float: right;  border: none; box-sizing: border-box; width: 163px; height: 53px; margin: 0px 15px;'"
            [textStyle]="'font-style: normal; font-weight: normal; font-size: 14px; color: #252B42; margin-top: 5px;'"
            [dropdownName]="sortLabel != '' ? sortLabel : 'selectSort'" (searchData)="selectSort($event)"
            [isFilter]="false">
          </tlb-dropdown></div>
        </div>

      </div>
    </div>

        <app-admin-job-profiles-list [sortText]="sortText" (resultSearch)="search($event)"></app-admin-job-profiles-list>

  </div>

</div>

