<!-- Team Members Modal -->
<div #recruiterListModal
     class="modal fade"
     id="recruiterListModal">
  <div class="modal-dialog modal-lg">
    <div class="modal-content p-2">
      <div class="modal-header m-2 border-0">
        <button type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close">
          <i class="fas fa-times"
             style="font-size: 14px;color: #4F4F4F;"></i>
        </button>
      </div>
      <div class="modal-body container">
        <div class="container-tab-title">
          <h5 [ngClass]="activeTab==RecruiterListTabEnum.AllRecruiters?'active':''"
              (click)="changeTab(RecruiterListTabEnum.AllRecruiters)">
            {{'allRecruiters' | translate}}
          </h5>
          <h5 [ngClass]="activeTab==RecruiterListTabEnum.MyFavorites?'active':''"
              (click)="changeTab(RecruiterListTabEnum.MyFavorites)">
            {{'myFavorites' | translate}}
          </h5>
        </div>
        <div class="container-search-box">
          <input type="text"
                 id="search-box"
                 name="search-box"
                 class="form-control search-box"
                 [(ngModel)]="searchText"
                 (ngModelChange)="searchChangeEvent()"
                 [placeholder]="'search' | translate">
        </div>
        <div class="container-recruiter-list  my-3">
          <div class="container-recruiter-list-header">
            <span>
              {{activeTab==RecruiterListTabEnum.AllRecruiters?recruitersWithRecommendation.recruiterPagination.numberOfElements + recruitersWithRecommendation.recruiters.length:recruitersWithRecommendation.recruiterPagination.numberOfElements}} {{'recruiterListing' | translate}}
            </span>
            <div>
              <span>{{'sortBy' | translate}}:</span>
              <select name="sortBy"
                      id="sortBy"
                      class="ml-2"
                      id="sortBy" [(ngModel)]="sortBy" (ngModelChange)="sortByChange()" >
                <option disabled selected [ngValue]="''">{{'ratings' | translate}}</option>
                <option [ngValue]="'highestFirst'">{{'highestFirst' | translate}}</option>
                <option [ngValue]="'lowestFirst'">{{'lowestFirst' | translate}}</option>
                <option [ngValue]="''">{{'none' | translate}}</option>
              </select>
            </div>
          </div>
          <div class="container-recruiter-list-items">
            <ng-container *ngIf="activeTab===RecruiterListTabEnum.AllRecruiters">
              <span class="title">{{'recommendedRecruiters' | translate}}</span>
              <div class="list-item my-2"
                   *ngFor="let recruiter of recruitersWithRecommendation.recruiters">
                <input type="checkbox"
                       [name]="'selectRecruiter'"
                       (click)="selectRecruiter(recruiter)"
                       [id]="'recommended-selectRecruiter-'+recruiter.id"
                       [checked]="recruiter.isChecked">
                <label [for]="'recommended-selectRecruiter-'+recruiter.id">
                  <div [style.background-color]="recruiter.imageColor"
                       class="preimage mx-2"><span style="color: white;line-height: 36px;">{{recruiter.imageText}}</span></div>
                  {{recruiter.user.fullName}}
                </label>
              </div>
            </ng-container>


            <span class="title" *ngIf="activeTab===RecruiterListTabEnum.AllRecruiters">{{'allRecruiters' | translate}}</span>

          <div class="container-recruiter-list-items-all-recruiters" id="container-recruiter-list-items-all-recruiters" (scroll)="scrollEventForList($event)">
            <div class="list-item my-2"
                 *ngFor="let recruiter of recruitersWithRecommendation.recruiterPagination.content">
              <input type="checkbox"
                     [name]="'selectRecruiter'"
                     (click)="selectRecruiter(recruiter)"
                     [id]="'selectRecruiter-'+recruiter.id"
                     [checked]="recruiter.isChecked">
              <label [for]="'selectRecruiter-'+recruiter.id">
                <div [style.background-color]="recruiter.imageColor"
                     class="preimage mx-2"><span style="color: white;line-height: 36px;">{{recruiter.imageText}}</span></div>
                {{recruiter.user.fullName}}
              </label>
            </div>
          </div>
          </div>
        </div>
        <div class="container-recruiter-list-invited my-3" *ngIf="selectedRecruiters.length > 0">
          <span class="title">{{'invitedRecruiters' | translate}}</span>
          <div class="d-flex-row-center" style="flex-wrap: wrap;">
            <div class="d-flex-row-center invited-recruiters" *ngFor="let recruiter of selectedRecruiters">
              <div [style.background-color]="recruiter.imageColor"
                   class="preimage mx-2 w-24"><span style="color: white;font-size: 0.6rem;    line-height: 24px;">{{recruiter.imageText}}</span></div>
              {{recruiter.user.fullName}}
              <span class="remove-invited-recruiter-span" (click)="removeFromInvitedRecruiters(recruiter)">x</span>
            </div>

          </div>
        </div>
        <div class="container-modal-buttons">
          <button class="btn btn-primary btn-cancel" (click)="cancel()">{{'cancel' | translate}}</button>
          <button class="btn btn-primary ml-3" (click)="confirm()">{{'confirm' | translate}}</button>
        </div>


      </div>

    </div>
  </div>


</div>
