import { JobModel }                                                  from '../../../shared/models/job/job.model';
import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { ApplicationModel }                                          from 'src/app/shared/models';
import { CreateAvatar }                                              from 'src/app/shared/classes';
import { ApplicationService, NotificationService, TranslatePipe }    from '../../../shared/services';
import { ApplicationStatus }                                         from '../../../shared/enums/application/application-status.enum';

@Component({
             selector: 'app-applicants-modal',
             templateUrl: './applicants-modal.component.html',
             styleUrls: ['./applicants-modal.component.scss']
           })
export class ApplicantsModalComponent implements OnInit, OnChanges {

  @Input() job: JobModel;
  @Input() applications: ApplicationModel[] = [];
  @Input() recruiter: boolean = false;
  @Input() fromTrackApplications: boolean = false;
  selectedApplication: ApplicationModel;
  @Output() openUpdateCandidateStatusEmitter = new EventEmitter();
  @Output() openCandidateDetailPopupEmitter = new EventEmitter();
  @Output() refreshDataEmitter = new EventEmitter();
  createAvatar: CreateAvatar;
  role: string = '';
  isHired: boolean = false;
  salary: number = null;
  selectedNote: any = null;
  applicationId: string = null;
  changed: boolean = false;

  constructor(private applicationService: ApplicationService, private notification: NotificationService,
              private translate: TranslatePipe) {
    this.createAvatar = new CreateAvatar();
  }

  ngOnInit(): void {
    this.role = localStorage.getItem('role');
  }

  ngOnChanges(): void {

    this.isHired = false;
    this.applications.forEach(x => {
      x.imageText = this.createAvatar.create(x.candidate.fullName);
      x.imageColor = this.createAvatar.color();
      console.log(x);

      if (!this.fromTrackApplications) {
        this.getNotes(false);
      }
    });
    this.setSelectableApplicationStatuses();
  }

  getNotes(changed?: boolean) {
    this.changed = changed;
    this.applications.forEach(x => {
      this.applicationId = x.id;
      this.applicationService.getNotes(x.id)
          .subscribe(n => {
            x.notes = n.content;
          });
    });
  }

  openCandidateDetail(app) {
    this.applications.forEach(x => {
      x == app ? app.openedCandidateDetail = !app.openedCandidateDetail : x.openedCandidateDetail = false;
      ;
    });
  }

  setSelectableApplicationStatuses(): void {
    switch (this.role) {
      case 'RECRUITER_MANAGER':
      case 'RECRUITER_USER':
        this.applications.forEach(application => {
          application.selectableApplicationStatuses = [];
          application.selectableApplicationStatuses.push(ApplicationStatus.CANCELLED);
        });
        break;
      case 'EMPLOYER_MANAGER':
      case 'EMPLOYER_USER':
        this.applications.forEach(application => {
          application.selectableApplicationStatuses = [];
          if (application.status == ApplicationStatus.SUBMITTED) {
            application.selectableApplicationStatuses.push(
              ApplicationStatus.CANCELLED);
          } else {
            application.selectableApplicationStatuses.push(ApplicationStatus.CLIENT_REVIEW);
            application.selectableApplicationStatuses.push(ApplicationStatus.MANAGER_REVIEW);
            application.selectableApplicationStatuses.push(ApplicationStatus.INTERVIEW_STAGE);
            application.selectableApplicationStatuses.push(ApplicationStatus.OFFER_STAGE);
            application.selectableApplicationStatuses.push(ApplicationStatus.HIRED);
            application.selectableApplicationStatuses.push(ApplicationStatus.CANCELLED);
          }
        });
        break;
    }
  }

  openUpdateCandidateStatus(application: ApplicationModel): void {
    this.openUpdateCandidateStatusEmitter.emit(application);
  }

  openCandidateDetailPopup(application: ApplicationModel): void {
    this.openCandidateDetailPopupEmitter.emit(application);
  }

  changeApplicationStatus(applicationId: string, status: ApplicationStatus): void {
    this.isHired = false;
    this.applications.filter(x => x.id == applicationId)[0].status = status;

    if (status == ApplicationStatus.CANCELLED) {
      this.applicationService.CancelApplication(applicationId)
          .subscribe(x => {
            this.refreshDataEmitter.emit();
          });
    } else if (status == ApplicationStatus.HIRED) {
      this.isHired = true;
      // this.applicationService.HireApplication(applicationId).subscribe(x => {
      //   this.refreshDataEmitter.emit();
      // });
    } else {
      this.applicationService.ProgressApplication(applicationId, status)
          .subscribe(x => {
            this.refreshDataEmitter.emit();
          });
    }

  }

  hireApp(applicationId: string) {

    let model = {
      agreedSalaryAmount: this.salary
    };

    this.applicationService.HireApplication(applicationId, model)
        .subscribe(x => {
          // @ts-ignore
          $('#applicantsModalLong')
            .modal('hide');
          this.refreshDataEmitter.emit();

        });
  }

  openUrl(url) {
    window.open(url);
  }

  addEditNote(note, appId: string) {
    this.selectedNote = note;
    this.applicationId = appId;

    // @ts-ignore
    $('#addEditApplicationNote')
      .modal('show');
  }

  deleteNote(note) {
    this.applicationService.deleteNote(note.id)
        .subscribe(x => {
          this.getNotes(false);
          this.notification.success(this.translate.transform('deleteSuccessful'));
        });
  }

}


