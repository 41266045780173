import { Component, EventEmitter, OnInit, Output }         from '@angular/core';
import { CreateAvatar }              from '../../../classes';
import { TranslatePipe }             from '../../../pipes/translate/translate.pipe';
import { ActivitiesService, JobService, server } from '../../../services';
import { map }                       from 'rxjs/operators';
import { JobModel } from 'src/app/shared/models';
import { Router } from '@angular/router';

@Component({
             selector: 'app-header-notifications',
             templateUrl: './header-notifications.component.html',
             styleUrls: ['./header-notifications.component.scss']
           })
export class HeaderNotificationsComponent implements OnInit {

  @Output() selectedJob = new EventEmitter();
  activities: any[] = [
    /*    {
          createdAt: '2022-03-15T10:31:25.762Z',
          id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          status: 'ACTIVE',
          type: 'JOB_CREATED',
          content: 'Java Developer',
          contentId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          userId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          employerId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          recruiterId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          read: true
        },
        {
          createdAt: '2022-02-27T10:31:25.762Z',
          id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          status: 'ACTIVE',
          type: 'APPLICATION_SUBMITTED',
          content: 'İrem Şimşek',
          contentId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          userId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          employerId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          recruiterId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          read: true
        }*/
  ];

  selectedContent: any = null;
  createAvatar: CreateAvatar;

  constructor(private translate: TranslatePipe, private jobService: JobService, private router: Router,
              private activityService: ActivitiesService
  ) {
    this.createAvatar = new CreateAvatar();
  }

  ngOnInit(): void {
    this.getActivities();

  }

  markAllAsRead(): void {
    this.activities.forEach(activity => {
      this.activityService.ServerPut(`${server.notifications}/${activity.id}/read`, {})
          .subscribe(() => {
            this.getActivities();
          });
    });
  }

  getActivities(): void {
    this.activityService.ServerGetWithText(`?size=10`)
        .pipe(map((response) => response.content))
        .subscribe(x => {
          this.activities = x;
          this.activities.forEach(f => {
            f.imageText = this.createAvatar.create(this.translate.transform(f.content));
            f.imageColor = this.createAvatar.color();
            const time = Math.abs(new Date().getTime() - new Date(f.createdAt).getTime());
            f.postDate = Math.ceil(time / (1000 * 3600 * 24));
          });
        });
  }

  selectedActivity(activity) {

    switch (activity.domainType) {
      case 'JOB':
        this.jobService.ServerGetWithText("/" + activity.contentId).subscribe(j=>{
        this.selectedContent = j;
        });

        break;
      case 'APPLICATION':
        this.router.navigate(["/dashboard/applications/" + activity.contentId]);
        break;
      case 'EARNING':
        this.router.navigate(["/dashboard/payments/"]);
        break;
        case 'EMPLOYER ':

          break;
    }

  }

  OpenModalViewSalary(job: JobModel) {
    this.selectedContent = job;
    // @ts-ignore
    $('#viewSalaryModal').modal('show');
    // @ts-ignore
    $('#jobDetailFromNotification').modal('hide');
  }

  submitCandidate(job: JobModel) {
    this.selectedContent = job;

    // @ts-ignore
    $('#submitCandidateModal').modal('show');
  }
}
