import {Component, OnInit} from '@angular/core';

declare var $: any;

@Component({
    selector: 'app-success-page',
    templateUrl: './success-page.component.html',
    styleUrls: ['./success-page.component.scss']
})
export class SuccessPageComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

    close(): void {
        $('#successPopup')
            .modal('hide');
    }
}
