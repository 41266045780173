import { Component, OnInit } from '@angular/core';
import { FaqCategoryService, FaqItemsService, NotificationService, server, TranslatePipe } from 'src/app/shared/services';

@Component({
  selector: 'app-admin-faq',
  templateUrl: './admin-faq.component.html',
  styleUrls: ['./admin-faq.component.scss']
})

export class AdminFaqComponent implements OnInit {

  categories: any[] = [];
  faqItems: any[] = [];
  selectedCategory: any = {};
  selectedItem: any = null;
  selectedForDelete: any = null;
  selectedCat: boolean = false;
  defaultArray: any = [];
  filter: string = "";

  constructor(private categoryService: FaqCategoryService, private faqItemsService: FaqItemsService, private notification: NotificationService,
    private translate: TranslatePipe) { }

  ngOnInit(): void {
    this.getCategories();
  }

  getCategories() {
    this.categoryService.ServerGet()
      .subscribe(x => {
        this.categories = x.content;
        this.selectCategory(true);
      });
  }

  selectCategory(value) {
    this.selectedCategory = value ? this.categories[0] : this.categories[1];
    this.faqItemsService.ServerGetWithText("/?categoryId=" + this.selectedCategory.id)
      .subscribe(x => {
        this.defaultArray = x.content.filter(a => a.status != "DELETED");
        this.faqItems = this.defaultArray;
      });
  }

  openEditItem(item?) {
    this.selectedItem = item;

    // @ts-ignore
    $('#editFaqItemModal').modal('show');
  }

  openDeleteItem(item) {
    this.selectedForDelete = item;

    // @ts-ignore
    $('#generalDeleteModal').modal('show');
  }

  deleteItem(item) {
    if (item) {

      this.faqItemsService.ServerDelete(server.faqItems + "/" + this.selectedForDelete.id)
        .subscribe(x => {
          this.selectCategory(!this.selectedCat);
          this.notification.success(this.translate.transform('deleteSuccessful'));
        });
    }

  }

  listOrderChanged(a) {
    a.forEach(element => {
      let category = {
        id: element.category.id
      }
      let order = a.indexOf(element);
      let model = {
        id: element.id,
        category: category,
        question: element.question,
        answer: element.answer,
        screenOrder: order
      };

      this.faqItemsService.ServerPut(server.faqItems + "/" + element.id, model)
        .subscribe(x => {
        });

    });

    setTimeout(() => {
      this.selectCategory(!this.selectedCat);
    }, 500);

  }

  search(data) {
    if (this.filter != "") {
      this.faqItems = ((this.faqItems).filter((item: any) => ((item.question).toLowerCase().trim()).indexOf(data.toLowerCase().trim()) > -1));
    }

    else {
      this.faqItems = this.defaultArray;
    }
  }

  clearSearch(){
    this.filter = "";
    this.search('');
  }
}
