<!-- Modal -->
<div class="modal fade" id="messagesForm" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title headerMessages text-uppercase" id="exampleModalLongTitle">{{'messages' | translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <div class="messaging">
            <div class="inbox_msg">
              <div class="inbox_people">
                <div class="headind_srch row">
                  <div class="recent_heading col-6">
                    <h4>{{ 'recent' | translate }}</h4>
                  </div>
                  <div class="col-6">
                    <tlb-dropdown [items]="userOptions" [dropdownName]="'pleaseSelect'"
                    (searchData)="selectUser($event)"></tlb-dropdown>
                  </div>
                </div>
                <div  class="inbox_chat">
                  <div class="chat_list h-100" style=" cursor: default;" *ngIf="!defaultUsers || defaultUsers.length == 0">
                    <tlb-empty-page  [warningLabel]="'No messages yet.'"></tlb-empty-page>
                  </div>

                  <div *ngFor="let user of defaultUsers"  class="chat_list" (click)="changeContent(user.id)" [class]="selectedId == user.id ? 'active_chat' :''">
                    <div class="chat_people">
                      <div class="chat_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil"> </div>
                      <div class="chat_ib">
                        <h5>{{user.name}} <span class="chat_date">{{user.date}}</span></h5>
                        <p>{{user.lastMsg}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mesgs">
                <div class="msg_history">
                   <div *ngIf="selectedId != 0" >
                     <div *ngFor="let msg of messages">
                       <div *ngIf="msg.inc" class="incoming_msg">
                    <div class="incoming_msg_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil"> </div>
                    <div class="received_msg">
                      <div class="received_withd_msg">
                        <p>{{msg.content}}</p>
                        <span class="time_date"> {{msg.time}}    |    {{msg.date}}</span> </div>
                    </div>
                  </div>
                  <div *ngIf="!msg.inc" class="outgoing_msg">
                    <div class="sent_msg">
                      <p>{{msg.content}}</p>
                      <span class="time_date"> {{msg.time}}    |    {{msg.date}}</span> </div>
                  </div>
                     </div>
                </div>
                </div>

                <div class="type_msg">
                  <div class="input_msg_write">
                    <input [(ngModel)]="message" type="text" class="write_msg" placeholder="Type a message" />
                    <button class="msg_send_btn" type="button" (click)="sendMessage()" [style]="this.message == '' || this.message == undefined?'background:gray;cursor: default;':''" [disabled]="this.message == '' || this.message == undefined"><i class="fas fa-paper-plane mr-1"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
