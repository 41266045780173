import { Component, OnInit }      from '@angular/core';
import { PackageService }         from '../../../shared/services/subscription/package/package.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DropdownOptionsModel } from 'src/app/shared/models';
import { CountryService, NotificationService, RecruiterMeService, TranslatePipe } from 'src/app/shared/services';

@Component({
             selector: 'app-checkout',
             templateUrl: './checkout.component.html',
             styleUrls: ['./checkout.component.scss']
           })
export class CheckoutComponent implements OnInit {

  packetId = '';
  invalidNumber: boolean = false;
  phoneNumber: any = {
    countryCallingCode: "",
    phoneNumber: ""
  };
  phoneNumberCodesOptions: DropdownOptionsModel[] = [];
  tooltip: string = "";
  role: string = "";

  constructor(private packageService: PackageService, private countryService: CountryService, private recruiterMeService: RecruiterMeService,
              private route: ActivatedRoute, private notification: NotificationService, private translate: TranslatePipe, private router: Router) {
  }

  ngOnInit(): void {
    this.role = localStorage.getItem('role');

    this.route.paramMap.subscribe(params => {
      // @ts-ignore
      this.packetId = params.params.packetId;
    });

    this.countryService.ServerGetWithoutLogin().subscribe(x => {
      this.phoneNumberCodesOptions = [];
      x.content.forEach(x => {
        this.phoneNumberCodesOptions.push({label: x.callingCode + ' (' + x.defaultLocalization.name + ')', value: x.callingCode});
      });

    });

    // @ts-ignore
    $('.app-content-container')
      .height('90%');
      console.log("tes");

      this.packageControl(false);

  }

  packageControl(fromUpdate?: boolean){
    this.packageService.getCheckoutForm(this.packetId)
        .subscribe(x => {
          this.invalidNumber = false;
          const el = document.createElement('html');
          el.innerHTML = x.checkoutFormContent ?? '';
          for (let i = 0; i < (el.getElementsByTagName('script') as HTMLCollection).length; i++) {
            const script = (el.getElementsByTagName('script') as HTMLCollection)[i].innerHTML;
            const scriptInit = document.createElement('script');
            scriptInit.innerHTML = script;
            document.head.appendChild(scriptInit);
          }
        },
        error => {
          if(error.error.message.includes('Phone number is invalid')){
            if(fromUpdate){
              this.phoneNumber = {
                countryCallingCode: "",
                phoneNumber: ""
              };
              this.notification.error(this.translate.transform('pleaseEnterAValidPhoneNumberToPay'));

            }
            this.invalidNumber = true;
          }
        });
  }

  selectNumberCode(data){
    let array = data.split(':');
    this.tooltip = this.phoneNumberCodesOptions[array[0]].label;
    //@ts-ignore
    $('#tooltips').tooltip('hide')

  }

  updatePhoneNumber(){
    this.recruiterMeService.putPhoneNumber(this.phoneNumber).subscribe(x=>{
      this.packageControl(true);
    });
  }

  goProfileUpdate(){
    this.router.navigate(['dashboard/complete-employer-register/' + this.packetId]);
  }

  phoneNumberChars(event) {
    if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) || event.keyCode == 8) {}
    else event.preventDefault();
  }

}
