export class CoverLetterModel {

  content: string;
  url: string;

  constructor() {
    this.content = "";
    this.url;
  }
}
