export enum PositionAtCompany {
  OWNER_PRESIDENT_CEO = "OWNER_PRESIDENT_CEO",
  FOUNDING_PARTNER = "FOUNDING_PARTNER",
  VP_DIRECTOR = "VP_DIRECTOR",
  MANAGER = "MANAGER",
  EXECUTIVE_RECRUITER = "EXECUTIVE_RECRUITER",
  SENIOR_RECRUITER = "SENIOR_RECRUITER",
  RECRUITER = "RECRUITER",
  OTHER = "OTHER"
}
