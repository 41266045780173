<div class="container-fluid">
  <div class="card mt-4" style="border: 1px solid #C8D4DF;">
    <div class="card-body">
      <div class="header mb-2">{{'bestRecruiters' | translate}}</div>
      <tlb-empty-page *ngIf="!recruiters || recruiters.length == 0" [warningLabel]="'noBestRecYet'" [small]="true"></tlb-empty-page>
      <div *ngIf="recruiters && recruiters.length > 0">
        <div *ngFor="let recruiter of recruiters" style="border-top:1px solid rgba(180, 182, 197, 0.38);">
          <div class="row mt-2 mb-2 px-3 recruiter-row" >
            <div>
              {{recruiters.indexOf(recruiter) + 1}}
            </div>

            <div  class="ml-3">
              <div class="d-flex">
                <div>
                  <div [style.background-color]="recruiter.imageColor"
                       id="preimage"><span style="color: white;">{{recruiter.imageText}}</span></div>
                </div>
                <div class="ml-2">
                  <div class="name-text">{{recruiter.recruiter?.user?.fullName}}</div>
                  <div class="type-text">{{recruiter.recruiter?.talentbaseReferenceType | translate}} </div>
                </div>
              </div>
            </div>

            <div class="star-text ml-auto">
              {{recruiter.star}}

            </div>
          </div>
        </div>

        <hr>

        <div *ngIf="totalCount > 4" class="view-text mr-3" >
          <div class="row">
            <button [disabled]="page == 1" class="arrow-buttons mr-3" (click)="getActivities(page - 1)">
              <i class="fas fa-chevron-left"></i>
            </button>
            <button [disabled]="page * 4 >= totalCount" class="arrow-buttons" (click)="getActivities(page + 1)">
              <i class="fas fa-chevron-right"></i>
            </button>

          </div>
        </div>
      </div>

    </div>

  </div>

</div>
