<!-- Modal -->
<div class="modal fade" [id]="modalId">
  <div class="modal-dialog modal-dialog-centered " >
    <div class="modal-content" >
      <!-- <div class="modal-header">
      </div> -->
      <div class="modal-body py-0" style="background-color: #F5F6F8;">
        <div class="container" >
          <div class="row" >
            <div class="col-12 pr-1 pl-0">
              <div class="card border-0">
                <div  *ngIf="recruiter && recruiter.id != ''" class="card-body" style="background-color: #F5F6F8;">
                  <div class="card mb-3" >
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12 col-xl-1 px-0 ml-5">
                          <div class="row h-100">
                            <div class="row">
                              <div class="col-12">
                                <div id="preimage">
                                  <span style="color: white;">{{recruiter.user?.firstName.charAt(0)}}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-xl-4 p-0">
                          <div class="row h-100">

                            <div class="pl-4">
                              <p class="user-fullname pt-3">
                                {{recruiter.user?.fullName}}

                                <span class="pl-2">
                                  <button class="btn btn-control deactive-button" *ngIf="recruiter.active" (click)="openDeactivePopup()">
                                    <b>{{'deactive'|translate}}</b>
                                  </button>
                                  <button class="btn btn-control active-button" *ngIf="!recruiter.active">
                                    <b>{{'active'|translate}}</b>
                                  </button>
                                </span>
                              </p>

                              <div class="pb-3 pt-2">
                                <span style="color: #F1B856;" [ngClass]="recruiter?.rating > 0 ? recruiter?.rating < 1 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"></span>
                                <span style="color: #F1B856;" [ngClass]="recruiter?.rating > 1 ? recruiter?.rating < 2 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"></span>
                                <span style="color: #F1B856;" [ngClass]="recruiter?.rating > 2 ? recruiter?.rating < 3 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"></span>
                                <span style="color: #F1B856;" [ngClass]="recruiter?.rating > 3 ? recruiter?.rating < 4 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"></span>
                                <span style="color: #F1B856;" [ngClass]="recruiter?.rating > 4 ? recruiter?.rating < 5 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"></span>
                                <!-- <span style="color: #707683;"> ({{recruiter.reviewCount}} {{'reviews'|translate}})</span> -->
                                <!-- <span class="far fa-star" style="color: #F1B856;"></span> -->
                              </div>

                              <div class="pt-3">
                                <p>
                                  <span class="desc ml-0"> {{'registeredOn'|translate}}</span>
                                  <span> {{recruiter.date}}</span>
                                </p>

                                <div class="pt-2 pb-3">
                                  <button class="btn btn-control message-button w-100" (click)="openMessagesPopup()">
                                    <b>{{'message'|translate}}</b>
                                  </button>
                                </div>

                              </div>

                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>


                  <div class="card mb-3">
                    <div class="card-body">


                      <div class="row mt-4">
                        <div class="col-12 pb-2 titles">
                          <b>
                            {{'generalInfo'|translate}}
                          </b>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12 col-lg-9">
                          <div class="row mt-2">
                            <div class="col-12 col-sm-4">
                              <img src="./../../../../../assets/images/talentbase/icons/primay-phone.png">
                              <span class="desc">{{ 'primanyPhone' | translate }}</span>
                            </div>
                            <div class="col-12 col-sm-8">
                              {{recruiter.primaryContactNumber.countryCallingCode}} {{recruiter.primaryContactNumber.phoneNumber}}
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-12 col-sm-4">
                              <img src="./../../../../../assets/images/talentbase/icons/primay-phone.png">
                              <span class="desc">{{ 'secondaryPhone' | translate }}</span>
                            </div>
                            <div class="col-12 col-sm-8" *ngIf="recruiter.secondaryContactNumber && recruiter.secondaryContactNumber.phoneNumber != null">
                              {{recruiter.secondaryContactNumber.countryCallingCode}} {{recruiter.secondaryContactNumber.phoneNumber}}
                            </div>
                            <div class="col-12 col-sm-8" *ngIf="!recruiter.secondaryContactNumber.phoneNumber || recruiter.secondaryContactNumber.phoneNumber == ''">
                              {{ 'na' | translate }}
                            </div>
                          </div>
                        </div>

                      </div>

                      <div class="row">
                        <div class="col-12 col-lg-9">
                          <div class="row mt-2">
                            <div class="col-12 col-sm-4">
                              <img src="./../../../../../assets/images/talentbase/icons/mail.png">
                              <span class="desc">{{ 'email' | translate }}</span>
                            </div>
                            <div class="col-12 col-sm-8">
                              {{recruiter.user?.email}}
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-12 col-sm-4">
                              <img src="./../../../../../assets/images/talentbase/icons/office-building.png">
                              <span class="desc">{{ 'company' | translate }}</span>
                            </div>
                            <div class="col-12 col-sm-8" *ngIf="recruiter.company">
                              {{recruiter.company}}
                            </div>
                            <div class="col-12 col-sm-8" *ngIf="!recruiter.company">
                              {{ 'na' | translate }}
                            </div>
                          </div>
                        </div>
                        <!-- <div class="col-12 col-lg-3">
                          <button class="btn btn-control purple-button w-100" (click)="openMessagesPopup()">
                          <b>{{'message'|translate}}</b>
                          </button>
                        </div> -->
                      </div>
                    </div>
                  </div>


                  <div class="card">
                    <div class="card-body">
                      <div class="row mt-4">
                        <div class="col-12 pb-2 titles">
                          <b>
                            {{'recruitingInfo'|translate}}
                          </b>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-12 col-sm-3">
                          <img src="./../../../../../assets/images/talentbase/icons/briefcase.png">
                          <span class="desc">{{ 'recruitmentExperience' | translate }}</span>
                        </div>
                        <div class="col-12 col-sm-9">
                          {{recruiter.individualPracticeTime | translate}}
                        </div>
                      </div>

                      <div class="row mt-2">
                        <div class="col-12 col-sm-3">
                          <img src="./../../../../../assets/images/talentbase/icons/chart-bar.png">
                          <span class="desc">{{ 'jobRecruisitionTypes' | translate }}</span>
                        </div>
                        <div class="col-12 col-sm-9">
                          {{recruiter.requisitionTypesText}}
                        </div>
                      </div>

                      <div class="row mt-2">
                        <div class="col-12 col-sm-3">
                          <img src="./../../../../../assets/images/talentbase/icons/locations.png">
                          <span class="desc">{{ 'functionalRoles' | translate }}</span>
                        </div>
                        <div class="col-12 col-sm-9">
                          {{recruiter.functionalRolesText}}
                        </div>
                      </div>

                      <div class="row mt-2">
                        <div class="col-12 col-sm-3">
                          <img src="./../../../../../assets/images/talentbase/icons/chart-pie.png">
                          <span class="desc">{{ 'industries' | translate }}</span>
                        </div>
                        <div class="col-12 col-sm-9">
                          {{recruiter.industriesText}}
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>

          </div>

        </div>

      </div>
      <!-- <div class="modal-footer py-4">
        <div class="container">
          <div class="row">
            <div class="col-12 col-sm-6 col-lg-3  ml-auto">
              <button class="form-control close-button" data-dismiss="modal">
                {{'close'|translate}}
              </button>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>

<app-employer-recruiter-review-popup [reviews]="reviews"></app-employer-recruiter-review-popup>
<app-messages *ngIf="selectedRecruiter && selectedRecruiter.id" [selectedUser]="selectedRecruiter" [employer]="format_color_reset"></app-messages>
<app-admin-deactivate-popup></app-admin-deactivate-popup>
