<!-- Modal -->
<div #myModal class="modal fade" id="changePasswordModal">
  <div class="modal-dialog ">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title resul modal-header-title-job" id="exampleModalLongTitle">{{'changePasswordHeader' | translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body container" style="max-width: 600px;">
        <tlb-form *ngIf="formModel && formModel.length > 0 && formGroup" [forms]="formModel" [formGroup]="formGroup"></tlb-form>

      </div>
      <div class="modal-footer container" style="max-width: 550px;">
        <button type="button" class="btn btn-secondary">{{'cancel' | translate}}</button>
        <button type="button" (click)="changePassword()" class="btn btn-next">{{'update' | translate}}</button>
      </div>
    </div>
  </div>
</div>
