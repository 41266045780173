<div class="container-fluid px-3">
    <!-- *ngIf="profileEnrichmentScore<100" -->
    <div [ngClass]="profileEnrichmentScore<100?'container-bg-yellow':'container-bg-green'"
         class="container-fluid container-bg">
        <div class="row">
            <div class="col-8 col-sm-12">
                <h4 *ngIf="profileEnrichmentScore<100">{{'completeYourProfile'|translate}}!</h4>
                <h4 *ngIf="profileEnrichmentScore==100">{{'editYourProfileDetails'|translate}}!</h4>
            </div>
            <div class="col-8 col-sm-10 col-md-12">
                <span>{{'toSuccessfullyRegisterYourProfileAsAnExpertAndToYouAvailableInSearchResults'|translate}}</span>
            </div>
            <div class="col-12 mt-4">
                <button (click)="goToCompleteProfile()" *ngIf="profileEnrichmentScore<100">
                    {{'completeYourProfile'|translate}}<i class="fas fa-arrow-right ml-3"></i>
                </button>
                <button (click)="goToCompleteProfile()" *ngIf="profileEnrichmentScore==100">
                    {{'editYourProfileDetails'|translate}}<i class="fas fa-arrow-right ml-3"></i>
                </button>
            </div>
            <div class="circle-div">
                <div class="bg-circle"></div>
                <div [class]="'p'+profileEnrichmentScore" class="c100 font-size-120 bg-transparent front-c100">
          <span class="h-100">
            <div class="container-fluid h-100">
              <div class="row h-50">
                <div class="col-12 mt-auto" style="    margin-left: -1.5em;    line-height: 1em;"><span
                        class="text-circle"><b>{{profileEnrichmentScore}}%</b></span></div>
              </div>
              <div class="row h-50">
                <div class="col-12" style="margin-left: -1.5em;line-height: 1em;">
                  <span class="text-circle2">{{'completed' |translate|titlecase}}</span>
                </div>
              </div>
            </div>
          </span>
                    <div class="slice">
                        <div class="bar"></div>
                        <div class="fill"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div [ngClass]="profileEnrichmentScore<100?'container-profile-border-top mt-5':' mt-2'"
     class="container-fluid p-0 m-0 ">
    <div class="container-fluid px-3 mt-2">
        <div class="row">
            <div class="col-12">
                <h4 class="profile-heading">{{'profile'|translate|titlecase}}</h4>
            </div>
            <div class="col-12">
                <span class="your-public-profile-span">{{'yourPublicProfile'|translate|firstLetterCapital}}</span>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12 overflow-auto hide-scroll">
        <span (click)="activeTab=0" [ngClass]="activeTab==0?'active':''"
              class="tab-span">{{'publicProfile'|translate|titlecase}}</span>
                <span (click)="activeTab=1" [ngClass]="activeTab==1?'active':''"
                      class="tab-span">{{'settings'|translate|titlecase}}</span>
                <span (click)="activeTab=2" [ngClass]="activeTab==2?'active':''"
                      class="tab-span">{{'billingInfo'|translate|titlecase}}</span>
                <span (click)="activeTab=3" [ngClass]="activeTab==3?'active':''"
                      class="tab-span">{{'helpCenter'|translate|titlecase}}</span>
            </div>
        </div>
        <div class="row px-0">
            <div class="col-12 col-md-10 col-xl-9 col-xxl-7">
                <div class="container-fluid container-tab pt-4 pb-5">
                    <!--Public Profile-->
                    <div *ngIf="activeTab==0" class="row">
                        <div class="col-12 d-flex px-3 px-sm-4">
              <span class="input-name" id="full-name-span">{{generalInfos.firstName + " " +
              generalInfos.lastName}}</span>
                            <div class="row" id="name-surname-input-div" style="display: none">
                                <div class="col-12 d-md-none px-4">
                                    <label class="left-title-span"
                                           for="name">{{ 'name' | translate | translate }}</label>
                                </div>
                                <div class="col-12 col-md-6 px-4 px-sm-2">
                                    <input [(ngModel)]="generalInfos.firstName" class="form-control w-md-auto mr-md-4"
                                           id="name"
                                           name="name" placeholder="{{ 'name' | translate | translate }}" type="text">
                                </div>
                                <div class="col-12 d-md-none px-4">
                                    <label class="left-title-span"
                                           for="surname">{{ 'surname' | translate | translate }}</label>
                                </div>
                                <div class="col-12 col-md-6 px-4 px-sm-2">
                                    <input [(ngModel)]="generalInfos.lastName" class="form-control w-md-auto"
                                           id="surname" name="surname"
                                           placeholder="{{ 'surname' | translate | translate }}" type="text">
                                </div>
                            </div>


                            <i (click)="enableNameInput()" class="fas fa-pen bg-gray align-self-center"></i>
                        </div>
                        <div class="col-12">
                            <hr>
                        </div>

                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 col-md-2">
                                    <span class="left-title-span"
                                          id="e-mail-title-span">{{'email'|translate|titlecase}}</span>
                                </div>
                                <div class="col-12 col-md-10 d-flex">
                                    <div class="row w-md-75">
                                        <div class="col-12 col-sm-8" id="e-mail-div" style="display: none">
                                            <div class="row">
                                                <div class="col-12 d-md-none px-4">
                                                    <label class="left-title-span"
                                                           for="e-mail">{{ 'email' | translate | translate }}</label>
                                                </div>
                                                <div class="col-12">
                                                    <input [(ngModel)]="generalInfos.email"
                                                           class="form-control w-100 d-inline-block " id="e-mail"
                                                           name="e-mail" style="display: none" type="text">
                                                </div>
                                            </div>


                                        </div>
                                        <div class="col-12 ">
                                            <span id="e-mail-span">{{generalInfos.email}}</span>
                                            <button (click)="enableEmail()" class="btn btn-change w-100 w-sm-auto"
                                                    id="btn-e-mail">
                                                {{ 'change' | translate |titlecase}}
                                            </button>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <hr>
                        </div>

                        <div class="col-12 text-center mt-4">
                            <button (click)="saveGeneralInfo()" class="btn btn-save">
                                {{'saveChanges' | translate | titlecase }}
                            </button>
                        </div>

                    </div>
                    <!--Settings-->
                    <div *ngIf="activeTab==1" class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 col-sm-6">
                                    <div class="col-12 w-100 h-100 p-0 border">
                                        <div class="w-100 bg-cream">
                                            <div class="col-12  p-0">
                                                {{'aboutYou'|translate|titlecase}}
                                            </div>
                                        </div>
                                        <div class="row px-3 mt-2">
                                            <div class="col-12">
                                                <span class="span-top-value">{{'name'|translate|titlecase}}</span>
                                            </div>
                                            <div class="col-12">
                        <span class="span-value">{{generalInfos.firstName + " " +
                        generalInfos.lastName}}</span>&nbsp;&nbsp;&nbsp;<a (click)="activeTab=0"
                                                                           class="cursor-pointer"
                                                                           class="span-value-link"><b>{{'editProfile' | translate |
                                                lowercase}}</b></a>
                                            </div>
                                        </div>
                                        <div class="row px-3 mt-2">
                                            <div class="col-12">
                                                <span class="span-top-value">{{'memberSince'|translate|titlecase}}</span>
                                            </div>
                                            <div class="col-12 pb-4">
                                                <span class="span-value">October 21,2021</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 mt-4 mt-sm-0">
                                    <div class="col-12 w-100 h-100 p-0 border">
                                        <div class="w-100 bg-cream">
                                            <div class="col-12 p-0">
                                                {{'connectedAccounts'|translate|titlecase}}
                                            </div>
                                        </div>
                                        <div class="row px-3 mt-2">
                                            <div *ngIf="role=='EMPLOYER_MANAGER' || role === 'EMPLOYER_USER'"
                                                 class="col-12 mt-1">
                                                <i class="fab fa-instagram"></i>&nbsp;&nbsp;
                                                <a [attr.href]="socialLinks.instagram" [target]="'_blank'"
                                                   class="cursor-pointer span-value-link text-decoration-none">
                                                    {{ 'connectInstagramProfile' | translate | titlecase }}
                                                </a> <span (click)="editSocialLink('instagram')"
                                                           class="text-decoration-underline cursor-pointer color-brand">{{'edit' | translate}}</span>
                                            </div>
                                            <div *ngIf="role=='EMPLOYER_MANAGER' || role === 'EMPLOYER_USER'"
                                                 class="col-12 mt-1">
                                                <i class="fab fa-twitter"></i>&nbsp;&nbsp;
                                                <a [attr.href]="socialLinks.twitter" [target]="'_blank'"
                                                   class="cursor-pointer span-value-link text-decoration-none">
                                                    {{ 'companyTwitterProfile' | translate | titlecase }}
                                                </a><span (click)="editSocialLink('twitter')"
                                                          class="text-decoration-underline cursor-pointer color-brand">{{'edit' | translate}}</span>
                                            </div>
                                            <div *ngIf="role=='EMPLOYER_MANAGER' || role === 'EMPLOYER_USER'"
                                                 class="col-12 mt-1">
                                                <i class="fab fa-facebook"></i>&nbsp;&nbsp;
                                                <a [attr.href]="socialLinks.facebook" [target]="'_blank'"
                                                   class="cursor-pointer span-value-link text-decoration-none">
                                                    {{ 'companyFacebookProfile' | translate | titlecase }}
                                                </a><span (click)="editSocialLink('facebook')"
                                                          class="text-decoration-underline cursor-pointer color-brand">{{'edit' | translate}}</span>
                                            </div>
                                            <div class="col-12 mt-1 mb-4">
                                                <i class="fab fa-linkedin"></i>&nbsp;&nbsp;
                                                <a [attr.href]="socialLinks.linkedin" [target]="'_blank'"
                                                   class="cursor-pointer span-value-link text-decoration-none">
                                                    {{ 'companyLinkedinProfile' | translate | titlecase }}
                                                </a><span (click)="editSocialLink('linkendin')"
                                                          class="text-decoration-underline cursor-pointer color-brand">{{'edit' | translate}}</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-12 mt-3">
                                    <div class="col-12 w-100 h-100 p-0 border">
                                        <div class="w-100 bg-cream">
                                            <div class="col-12 p-0">
                                                {{'password'|translate|titlecase}}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 col-sm-6">
                                                <div class="w-100">

                                                    <tlb-form
                                                            *ngIf="formModel && formModel.length > 0 && formGroup"
                                                            [forms]="formModel"
                                                            [formGroup]="formGroup"
                                                    ></tlb-form>
                                                    <!-- <div class="col-12 mt-3">
                                                      <span class="span-top-value">{{'currentPassword' | translate | titlecase}}</span>
                                                    </div>
                                                    <div class="col-12">
                                                      <input (keyup)="resetFeedback()" [(ngModel)]="changePasswordPostModel.currentPassword"
                                                        class="form-control" id="password" name="password" type="password">
                                                      <div class="invalid-feedback" id="current-password-feedback">
                                                        {{passwordFeedbacks.currentPasswordFeedback|translate|firstLetterCapital}}
                                                      </div>
                                                    </div>
                                                    <div class="col-12">
                                                      <span class="span-top-value">{{'newPassword' | translate | titlecase}}</span>
                                                    </div>
                                                    <div class="col-12">
                                                      <input (keyup)="resetFeedback()" [(ngModel)]="changePasswordPostModel.newPassword"
                                                        class="form-control" id="newpassword" name="newpassword" type="password">
                                                      <div class="invalid-feedback" id="new-password-feedback">
                                                        {{passwordFeedbacks.newPasswordFeedback|translate|firstLetterCapital}}
                                                      </div>
                                                    </div>
                                                    <div class="col-12">
                                                      <span class="span-top-value">{{'confirmNewPassword' | translate | titlecase}}</span>
                                                    </div>
                                                    <div class="col-12">
                                                      <input (keyup)="resetFeedback()" [(ngModel)]="rePassword" class="form-control"
                                                        id="repassword" name="repassword" type="password">
                                                      <div class="invalid-feedback" id="re-password-feedback">
                                                        {{passwordFeedbacks.rePasswordFeedback|translate|firstLetterCapital}}
                                                      </div>
                                                    </div>
                                                    -->

                                                    <div class="col-12 mt-4 mb-2">
                                                        <button (click)="changePassword()" class="btn btn-save"
                                                                [disabled]="formGroup.invalid || form.newPassword.value != form.confirmNewPassword.value"
                                                        >{{'changePassword' | translate | titlecase }}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!--Billing-->
                    <div *ngIf="activeTab==2" class="row">

                        <div class="col-12 col-md-6 mb-4" *ngIf="false">
                            <div class="row">
                                <div class="col-6">
                                    <b>{{'paymentMethod'| translate | titlecase}}</b>
                                </div>
                                <div class="col-6 text-right">
                                    <span class="cursor-pointer color-brand">{{'change'|translate |titlecase}}</span>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-12">
                                    <div class="card bg-gray">
                                        <div class="card-body">
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <div class="d-inline-block">
                                                        <img alt="" class="img-fluid"
                                                             src="assets/images/Mastercard.png">
                                                    </div>
                                                    <div class="d-inline-block">
                                                        <div class="col-12">
                                                            <b>*** *** *** *** 2505</b>
                                                        </div>
                                                        <div class="col-12">
                                                            <b>Credit Card</b>
                                                        </div>
                                                        <div class="col-12">
                              <span class="subtitle-credit-card">
                                {{'expiresOn'|translate|firstLetterCapital}}: Dec 2021
                              </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <form [formGroup]="legalInfoForm">
                                <div class="row">
                                    <div class="col-12">
                                        <b>{{'legalInfo'| translate | titlecase}}</b>
                                    </div>
                                    <div class="col-12 col-md-6 mt-2">
                                        <div class="row">
                                            <div class="col-12">
                                                <label class="span-top-value"
                                                       for="company-name">{{'companyName'|translate|titlecase}}*</label>
                                            </div>
                                            <div class="col-12 col-md-10">
                                                <input class="form-control" id="company-name" name="company-name"
                                                       formControlName="companyName"
                                                       type="text">
                                                <div *ngIf="legalInfoForm.get('companyName').errors?.required && (legalInfoForm.get('companyName').touched)"
                                                     class="invalid-feedback d-block">
                                                    {{'mustBeFilled' | translate}}
                                                </div>
                                            </div>
                                        </div>
                                    </div> <!--companyName-->
                                    <div class="col-12 col-md-6 mt-2">
                                        <div class="row">
                                            <div class="col-12">
                                                <label class="span-top-value"
                                                       for="company-address">{{'legalAddress'|translate|titlecase}}
                                                    *</label>
                                            </div>
                                            <div class="col-12 col-md-10">
                                                <input class="form-control" id="company-address" name="company-address"
                                                       formControlName="companyAddress"
                                                       type="text">
                                                <div *ngIf="legalInfoForm.get('companyAddress').errors?.required && (legalInfoForm.get('companyAddress').touched)"
                                                     class="invalid-feedback d-block">
                                                    {{'mustBeFilled' | translate}}
                                                </div>
                                            </div>
                                        </div>
                                    </div> <!--companyAddress-->
                                    <div class="col-12 col-md-6 mt-2">
                                        <div class="row">
                                            <div class="col-12">
                                                <label class="span-top-value"
                                                       for="email-address">{{'emailAddress'|translate|titlecase}}
                                                    *</label>

                                            </div>
                                            <div class="col-12 col-md-10">
                                                <input class="form-control" id="email-address" name="email-address"
                                                       formControlName="emailAddress"
                                                       type="text">
                                                <div *ngIf="legalInfoForm.get('emailAddress').errors?.pattern && (legalInfoForm.get('emailAddress').touched)"
                                                     class="invalid-feedback d-block">
                                                    {{'emailFormatError' | translate}}
                                                </div>
                                                <div *ngIf="legalInfoForm.get('emailAddress').errors?.required && (legalInfoForm.get('emailAddress').touched)"
                                                     class="invalid-feedback d-block">
                                                    {{'mustBeFilled' | translate}}
                                                </div>
                                            </div>
                                        </div>
                                    </div> <!--emailAddress-->
                                    <div class="col-12  mt-4">
                                        <div class="row">
                                            <div class="col-12">
                                                <button class="btn btn-save" [disabled]="legalInfoForm.invalid"
                                                        (click)="saveLegalInfo()">{{'save' | translate}}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <form [formGroup]="billingInfoForm">
                                <div class="row">
                                    <div class="col-12 mt-3">
                                        <b>{{'billingInfo'| translate | titlecase}}</b>
                                    </div>
                                    <div class="col-12 col-md-6 mt-2">
                                        <div class="row">
                                            <div class="col-12">
                                                <label class="span-top-value"
                                                       for="addressLine1">{{'addressLine1'|translate|titlecase}}
                                                    *</label>
                                            </div>
                                            <div class="col-12 col-md-10">
                                                <input class="form-control" id="addressLine1" name="addressLine1"
                                                       formControlName="addressLine1"
                                                       type="text">
                                                <div *ngIf="billingInfoForm.get('addressLine1').errors?.required && (billingInfoForm.get('addressLine1').touched)"
                                                     class="invalid-feedback d-block">
                                                    {{'mustBeFilled' | translate}}
                                                </div>
                                            </div>
                                        </div>
                                    </div> <!--Billing Addres1-->
                                    <div class="col-12 col-md-6 mt-2">
                                        <div class="row">
                                            <div class="col-12">
                                                <label class="span-top-value"
                                                       for="addressLine2">{{'addressLine2'|translate|titlecase}}
                                                    *</label>
                                            </div>
                                            <div class="col-12 col-md-10">
                                                <input class="form-control" id="addressLine2" name="addressLine2"
                                                       formControlName="addressLine2"
                                                       type="text">
                                                <div *ngIf="billingInfoForm.get('addressLine2').errors?.required && (billingInfoForm.get('addressLine2').touched)"
                                                     class="invalid-feedback d-block">
                                                    {{'mustBeFilled' | translate}}
                                                </div>
                                            </div>
                                        </div>
                                    </div> <!--Billing Addres2-->
                                    <div class="col-12 col-md-6 mt-2">
                                        <div class="row">
                                            <div class="col-12">
                                                <label class="span-top-value"
                                                       for="country">{{'country'|translate|titlecase}}*</label>
                                            </div>
                                            <div class="col-12 col-md-10">
                                                <select class="form-control" id="country" name="country"
                                                        change
                                                        formControlName="country">
                                                    <option hidden selected
                                                            value="">{{'selectCountry' |translate |titlecase}}</option>
                                                    <option [value]="country.id"
                                                            *ngFor="let country of countries">{{country.defaultLocalization.name}}</option>
                                                </select>
                                                <div *ngIf="billingInfoForm.get('country').errors?.required && (billingInfoForm.get('country').touched)"
                                                     class="invalid-feedback d-block">
                                                    {{'mustBeFilled' | translate}}
                                                </div>
                                            </div>
                                        </div>
                                    </div> <!--country-->
                                    <div class="col-12 col-md-6 mt-2">
                                        <div class="row">
                                            <div class="col-12">
                                                <label class="span-top-value"
                                                       for="country">{{'city'|translate|titlecase}}*</label>
                                            </div>
                                            <div class="col-12 col-md-10">
                                                <select class="form-control" id="city" name="city"
                                                        formControlName="city">
                                                    <option hidden selected
                                                            value="">{{'selectCity' |translate |titlecase}}</option>
                                                    <option disabled
                                                            value="-1"
                                                            *ngIf="cities.length==0">{{'youHaveNotSelectACountry' |translate |titlecase}}</option>
                                                    <option [value]="city.id"
                                                            *ngFor="let city of cities">{{city.defaultLocalization.name}}</option>
                                                </select>
                                                <div *ngIf="billingInfoForm.get('city').errors?.required && (billingInfoForm.get('city').touched)"
                                                     class="invalid-feedback d-block">
                                                    {{'mustBeFilled' | translate}}
                                                </div>
                                            </div>
                                        </div>
                                    </div> <!--City-->
                                    <div class="col-12 col-md-6 mt-2">
                                        <div class="row">
                                            <div class="col-12">
                                                <label class="span-top-value"
                                                       for="zip-code">{{'zipCode'|translate|titlecase}}*</label>
                                            </div>
                                            <div class="col-12 col-md-10">
                                                <input class="form-control" id="zip-code" name="zip-code" type="text"
                                                       formControlName="billingZipCode">
                                                <div *ngIf="billingInfoForm.get('billingZipCode').errors?.required && (billingInfoForm.get('billingZipCode').touched)"
                                                     class="invalid-feedback d-block">
                                                    {{'mustBeFilled' | translate}}
                                                </div>
                                            </div>
                                        </div>
                                    </div> <!--zipCode-->
                                    <div class="col-12  mt-4">
                                        <div class="row">
                                            <div class="col-12">
                                                <button class="btn btn-save" [disabled]="billingInfoForm.invalid"
                                                        (click)="saveBillingInfo()">{{'save' | translate}}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>


                        <div class="col-12 mt-4">
                            <div class="row">
                                <div class="col-6">
                                    <b>{{'billingHistory'| translate | titlecase}}</b>
                                </div>
                                <div class="col-6 text-right">
                                    <button class="btn btn-download-billing-history">
                                        <b>{{'download'| translate |titlecase}} </b> <i
                                            class="fas fa-cloud-download-alt"></i></button>
                                </div>
                            </div>
                            <div *ngIf="billings && billings.length>0" class="row">
                                <div class="col-12 overflow-auto">
                                    <table class="table">
                                        <thead>
                                        <tr>
                                            <th scope="col"><input (click)="checkAllItemsOnTable($event)"
                                                                   class="table-check-input"
                                                                   id="billing-all" name="billing-1" type="checkbox">
                                            </th>
                                            <th scope="col">{{'date'|translate|titlecase}}</th>
                                            <th scope="col">{{'invoiceNumber'|translate|titlecase}}</th>
                                            <th scope="col">{{'amount'|translate|titlecase}}</th>
                                            <th scope="col"></th>
                                        </tr>
                                        </thead>

                                        <tr *ngFor="let i of billings">
                                            <td><input class="table-check-input" id="billing-{{i}}" name="billing-1"
                                                       type="checkbox"></td>
                                            <td>5/28/15</td>
                                            <td>429</td>
                                            <td>$782.01</td>
                                            <td><span class="cursor-pointer color-brand">PDF</span></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <tlb-empty-page *ngIf="!billings || billings.length == 0" [small]="true" [subText]="false"
                                            [warningLabel]="'No billigns yet.'">
                            </tlb-empty-page>
                        </div>

                    </div>

                    <!-- Help Center -->

                    <div *ngIf="activeTab==3" class="row">

                        <div class="col-12" style="text-align: center;">
                            <b>{{'howCanWeHelpYou'| translate | titlecase}}</b>
                        </div>

                        <div class="col-12 mt-2">
                            <div class="row">
                                <div class="col-12">
                                    <label class="span-top-value" for="title">{{'title'|translate|titlecase}}</label>
                                </div>
                                <div class="col-12">
                                    <input [(ngModel)]="helpCenter.subject" class="form-control" id="title" name="title"
                                           type="text">
                                </div>
                            </div>
                        </div>

                        <div class="col-12 mt-2">
                            <div class="row">
                                <div class="col-12">
                                    <label class="span-top-value"
                                           for="message">{{'message'|translate|titlecase}}</label>
                                </div>
                                <div class="col-12">
                  <textarea [(ngModel)]="helpCenter.message" autocomplete="off" class="form-control" name="message"
                            rows="3" type="text"></textarea>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 mt-2">
                            <div style="float: right;">
                                <button (click)="sendHelpCenter()"
                                        [disabled]="helpCenter.subject == '' || helpCenter.message == ''"
                                        class="btn btn-save" type="button">{{'send' | translate}}</button>
                            </div>

                        </div>

                    </div>

                </div>
            </div>

        </div>
        <div class="row my-4 d-xl-none">
            <div class="col-12 text-center">
                <button (click)="logOut()" class="btn btn-save m-auto">{{ 'logOut' | translate }}</button>
            </div>
        </div>
    </div>
</div>


<div aria-hidden="true" aria-labelledby="edit-social-link-modal" class="modal fade" id="edit-social-link-modal"
     role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="edit-social-link-title">{{modalSettings.title| translate |titlecase}}</h5>
                <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <input [(ngModel)]="modalSettings.value" class="form-control" id="social-link"
                                   name="social-link"
                                   placeholder="http://" type="text">
                        </div>
                        <div class="col-12 mt-3 text-right">
                            <button (click)="saveSocialLink()" class="btn btn-save" type="button">Save changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>


<app-success-page></app-success-page>
