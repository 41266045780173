<div class="container-fluid my-4">
  <div class="row">

    <div class="col-12">

      <div class="top-page mt-2 mb-4">
        <div class="header">
          {{ 'allERecruiters' | translate }}
        </div>
        <div class="sub-header mt-2 mb-4">
          {{ 'allRecruitersListing' | translate }}
        </div>
      </div>

    <app-recruiter-filters (resultFilter)="getFilteredRec($event)" (clearFilter)="clearFilter($event)" (resultSearch)="search($event)"></app-recruiter-filters>
    <tlb-empty-page *ngIf="!recruiters || recruiters.length == 0" [warningLabel]="filterText == ''  ? 'noRecruiterYet' : filterText" [filterText]="filterText"></tlb-empty-page>


    <div class="showing-test mt-3">
      {{ 'showing' | translate }} {{ ' ' + recruiters.length }} {{ 'resultOfRecruiters' | translate }}
    </div>

    <div class="mb-3 based-text">
        {{ 'basedOnYourPreferences' | translate }}
    </div>

  <div *ngIf="recruiters && recruiters.length > 0">
      <div  *ngFor="let recruiter of recruiters | paginate:{ itemsPerPage: requestPayload.limit, currentPage: currentPage,totalItems:count}" class="row mt-3" >
        <div class="col-12 pr-1 pl-0">
          <div class="card">
            <div class="card-body">
              <div class="row px-xl-4">
                <div class="col-1">
                  <div [style.background-color]="recruiter.imageColor" id="preimage" >
                    <span style="color: white;">{{recruiter.imageText}}</span>
                  </div>
                </div>

                <div class="col-12 col-lg-12 col-md-12 col-xl-3 pt-3 pt-md-3 pt-lg-3 pt-xl-0" >
                  <div class="row">
                    <div class="col-12 user-fullname" >
                      {{recruiter.user?.fullName}}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 user-role">
                      <span *ngIf="recruiter && recruiter.type"> {{recruiter.type | translate}} </span> <span *ngIf="!recruiter || !recruiter.type">{{ 'notSpecified' | translate}}</span>

                    </div>
                  </div>
                  <div class="row star-pos">
                    <div class="col-12 col-xl-12">

                      <div class="row pb-3 pt-3 pl-2">
                        <span style="color: #F1B856;" [ngClass]="recruiter?.rating > 0 ? recruiter?.rating < 1 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"></span>
                        <span style="color: #F1B856;" [ngClass]="recruiter?.rating > 1 ? recruiter?.rating < 2 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"></span>
                        <span style="color: #F1B856;" [ngClass]="recruiter?.rating > 2 ? recruiter?.rating < 3 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"></span>
                        <span style="color: #F1B856;" [ngClass]="recruiter?.rating > 3 ? recruiter?.rating < 4 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"></span>
                        <span style="color: #F1B856;" [ngClass]="recruiter?.rating > 4 ? recruiter?.rating < 5 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"></span>
                        <!-- <span style="color: #707683;"> ({{recruiter.reviewCount}} {{'reviews'|translate}})</span> -->
                        <!-- <span class="far fa-star" style="color: #F1B856;"></span> -->
                      </div>
                      <!-- <div class="row pb-3 pt-3 pl-2">
                        <span class="fa fa-star checked size-check"></span>
                        <span class="fa fa-star checked size-check"></span>
                        <span class="fa fa-star checked size-check"></span>
                        <span class="fa fa-star checked size-check"></span>
                        <span class="fa fa-star size-check"></span>
                        <span style="color: #707683;"> ({{recruiter.reviewCount}} {{'reviews'|translate}})</span>
                     </div> -->
                    </div>
                  </div>
                  <!-- <div class="pt-3">
                      <div class="row pt-2">
                         <div class="col-12 col-sm-3">
                        <span class="gray">{{ 'rating' | translate }}</span>
                      </div>
                      <div class="col-12 col-sm-7">
                        {{recruiter.star}}/5
                      </div>
                    </div>

                      <div class="row pt-2">
                        <div class="col-12 col-sm-3 gray">
                         {{ 'reviewsTitle' | translate }}
                        </div>
                        <div class="col-12 col-sm-7">
                          {{recruiter.reviewCount}} {{'reviews'|translate}}
                        </div>
                      </div>
                  </div> -->
                </div>

                <div class="col-12 col-lg-3 col-md-12 col-xl-5">

                     <span class="desc d-flex inline-block pb-1">{{recruiter.user?.email}}</span>
                     <span class="desc d-flex inline-block pb-1">{{recruiter.companyName}}</span>
                     <span class="desc d-flex inline-block"> {{recruiter.updatedAt | date}}</span>
                    <div class="pt-3 pb-3">
                      <i class="fas fa-map-marker-alt"></i><span class="pl-2 desc2">Amsterdam, Netherlands</span>
                    </div>
                </div>

                <div class="col-12 col-lg-12 col-md-12 col-xl-3" >
                    <!-- <div class="col-12" style="text-align-last: end;">
                      <div class="justify-content-center align-self-center user-rating-div m-auto">
                        <div class="p-2 gray">
                         {{'registeredOn' | translate}}
                        </div>
                        <div class="p-1">
                          {{recruiter.date}}
                        </div>
                        <div>
                        </div>
                      </div>
                    </div> -->
                    <div class="p-3 mt-2 pr-5 pr-xl-0">
                      <div class="col-12 ml-3">
                        <!-- <button class="form-control view-button" (click)="openRecruiterDetailPopup(recruiter)">
                          {{ 'viewDetails' | translate }}
                        </button> -->
                        <div class="text-center pr-md-2" (click)="openRecruiterDetailPopup(recruiter)">
                          <a class="btn viewContactButton">{{ 'viewDetails' | translate }}</a>
                         </div>
                      </div>
                    </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        </div>



        <div  class=" container-fluid row mt-3 pagination">
          <pagination-controls (pageChange)="currentPage = $event;getRecruiters(currentPage)"
                               [autoHide]="count <= requestPayload.limit"
                               nextLabel="{{'next' | translate}}"
                               previousLabel="{{'previous' | translate}}"
                               responsive="true"></pagination-controls>
        </div>

      </div>
    </div>

  </div>



</div>



<app-admin-recruiter-detail-popup [modalId]="'adminRecruiterDetailModalFromAllRecruiters'" [recruiter]='selectedRecruiter'></app-admin-recruiter-detail-popup>
