export class ConversationModel {

  createdAt: string;
  createdBy: string;
  id: string;
  lastMessage: any;
  participants: any[];
  status: string;
  unread: boolean;
  updatedAt: string;
  updatedBy: string;

  constructor() {
    this.createdAt = "";
    this.createdBy = "";
    this.id = "";
    this.lastMessage = "";
    this.status = "";
    this.participants = [];
    this.unread = false;
    this.updatedAt = "";
    this.updatedBy = "";
  }
}
