import { Injectable }          from '@angular/core';
import { RecruiterMeService }  from '../recruiter';
import { EmployerMeService }   from '../uaa';
import { Observable, Subject } from 'rxjs';
import { Employer }            from '../../../components/admin-screens/admin-all-employers/employer';

@Injectable({
              providedIn: 'root'
            })
export class ProfileEnrichmentService {

  constructor(private recruiterMeService: RecruiterMeService, private employersMeService: EmployerMeService) {
  }

  getProfileEnrichmentScore(): Observable<number> {
    const subject = new Subject<number>();

    const role = localStorage.getItem('role');
    let profileEnrichmentScore = 0;

    switch (role) {
      case 'RECRUITER_MANAGER':
      case 'RECRUITER_USER':
        this.recruiterMeService.ServerGet()
            .subscribe(me => {
              if (me.user.firstName != null) {
                profileEnrichmentScore += 4;
              }
              if (me.user.lastName != null) {
                profileEnrichmentScore += 4;
              }
              if (me.user.email != null) {
                profileEnrichmentScore += 4;
              }
              if (me.type != null) {
                profileEnrichmentScore += 4;
              }

              if (me.primaryContactNumber.countryCallingCode != null) {
                profileEnrichmentScore += 8;
              }
              if (me.primaryContactNumber.phoneNumber != null) {
                profileEnrichmentScore += 8;
              }

              if (me.talentbaseReferenceType != null) {
                profileEnrichmentScore += 5;
              }
              if (me.individualPracticeTime != null) {
                profileEnrichmentScore += 5;
              }
              if (me.requisitionTypes.length != 0) {
                profileEnrichmentScore += 6;
              }

              if (me.companyName != '') {
                profileEnrichmentScore += 3;
              }
              if (me.positionAtCompany != null) {
                profileEnrichmentScore += 2;
              }
              if (me.website != null) {
                profileEnrichmentScore += 2;
              }
              if (me.linkedinAccount != null) {
                profileEnrichmentScore += 2;
              }
              if (me.recruiterCount != null) {
                profileEnrichmentScore += 2;
              }
              if (me.jobMarkets.length != 0) {
                profileEnrichmentScore += 2;
              }
              if (me.firmPracticeTime != null) {
                profileEnrichmentScore += 3;
              }

              if (me.paymentReferenceType != null) {
                profileEnrichmentScore += 16;
              }

              if (me.industries.length != 0) {
                profileEnrichmentScore += 8;
              }
              if (me.functionalRoles.length != 0) {
                profileEnrichmentScore += 12;
              }
              subject.next(profileEnrichmentScore);
            });
        break;
      case 'EMPLOYER_MANAGER':
      case 'EMPLOYER_USER':
        this.employersMeService.ServerGet()
            .subscribe(me => {

              //GENERAL INFO ?
              profileEnrichmentScore += 20;

              //CONCACT PERSON
              if (me.contactPerson != null) {
                if (me.contactPerson.firstName) {
                  profileEnrichmentScore += 4;
                }
                if (me.contactPerson.lastName) {
                  profileEnrichmentScore += 4;
                }
                if (me.contactPerson.email) {
                  profileEnrichmentScore += 4;
                }
                if (me.contactPerson.phoneNumber != null) {
                  if (me.contactPerson.phoneNumber.countryCallingCode != null) {
                    profileEnrichmentScore += 4;
                  }
                  if (me.contactPerson.phoneNumber.phoneNumber != null) {
                    profileEnrichmentScore += 4;
                  }
                }
              }
              //COMPANY INFO
              if (me.companyName != null) {
                profileEnrichmentScore += 4;
              }
              if (me.website != null) {
                profileEnrichmentScore += 4;
              }
              if (me.companySize != null) {
                profileEnrichmentScore += 4;
              }
              if (me.industries.length != 0) {
                profileEnrichmentScore += 4;
              }
              if (me.about != null) {
                profileEnrichmentScore += 4;
              }

              //ADDRESS INFO
              if (me.address != null) {
                if (me.address.line1 != null) {
                  profileEnrichmentScore += 8;
                }
                if (me.address.country != null) {
                  profileEnrichmentScore += 4;
                }
                if (me.address.city != null) {
                  profileEnrichmentScore += 4;
                }
                if (me.address.zipCode != null) {
                  profileEnrichmentScore += 4;
                }
              }
              //SOCIAL LINKS
              if (me.linkedinAccount != null) {
                profileEnrichmentScore += 4;
              }
              if (me.facebookAccount != null) {
                profileEnrichmentScore += 4;
              }
              if (me.twitterAccount != null) {
                profileEnrichmentScore += 4;
              }
              if (me.instagramAccount != null) {
                profileEnrichmentScore += 4;
              }
              if (me.atsAdditional != null) {
                profileEnrichmentScore += 4;
              }
              subject.next(profileEnrichmentScore);
            });
        break;
    }
    return subject.asObservable();

  }

  canEmployerPublishAJob(): Observable<boolean> {
    const subject = new Subject<boolean>();
    const role = localStorage.getItem('role');
    switch (role) {
      case 'RECRUITER_MANAGER':
      case 'RECRUITER_USER':
        subject.next(false);
        break;
      case 'EMPLOYER_MANAGER':
      case 'EMPLOYER_USER':
        this.employersMeService.ServerGet()
            .subscribe((me: Employer) => {
              let flag = false;

              try {
                if (
                  /*                me.contactPerson &&
                                  me.contactPerson.firstName &&
                                  me.contactPerson.lastName &&
                                  me.contactPerson.email &&
                                  me.contactPerson.phoneNumber &&
                                  me.contactPerson.phoneNumber.countryCallingCode &&
                                  me.contactPerson.phoneNumber.phoneNumber*/
                  me.address.city.id &&
                  me.address.line1 &&
                  me.address.country.id &&
                  me.address.zipCode &&
                  me.companyName &&
                  me.website &&
                  me.about &&
                  me.industries.length > 0
                ) {
                  flag = true;
                }
              } catch (e: any) { // <-- set `e` to `unknown` here
                flag = false;
              }

              // CONTACT PERSON

              subject.next(flag);
            });
        break;
    }
    return subject.asObservable();
  }

}
