import { GeneralIdModel } from "../parameter";

export class EmployerAddressModel {

  line1: string;
  line2: string;
  country: any;
  city: any;
  zipCode: number;

  constructor() {
    this.line1 = ""
    this.line2 = "";
    this.country = null;
    this.city = null;
    this.zipCode = 0;
  }
}
