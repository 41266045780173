import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JobProfileModel } from 'src/app/shared/models';
import { JobSegmentTagService, JobsProfileService, server } from 'src/app/shared/services';
import { PopupModel, PopupService } from 'src/app/shared/services/popup/popup.service';

@Component({
  selector: 'app-admin-job-profiles-list',
  templateUrl: './admin-job-profiles-list.component.html',
  styleUrls: ['./admin-job-profiles-list.component.scss']
})
export class AdminJobProfilesListComponent implements OnInit {


  @Input() sortText: string = "/?sort=createdAt,DESC";
  jobs: JobProfileModel[] = [];
  selectedJob: JobProfileModel = new JobProfileModel();
  filterText: string = "";
  storedItems: any[] = [];
  @Output() resultSearch = new EventEmitter();

  constructor(private jobProfileService: JobsProfileService, private jobSegmentTagService: JobSegmentTagService,
    private popupService: PopupService) { }

  ngOnInit(): void {
    this.getJobs();
  }

  ngOnChanges() {
    this.getJobs();
  }

  ngAfterViewInit(): void {
    const popup = new PopupModel('deletePopup');
    this.popupService.registerPopup(popup);

  }

  getJobs() {
    this.jobProfileService.ServerGetWithText(this.sortText).subscribe(x => {
      this.jobs = x.content as JobProfileModel[];
      this.storedItems = this.jobs;
      this.jobSegmentTagService.ServerGet().subscribe(s => {
        this.jobs.forEach(f => {
          f.tags = s.content?.filter(t => t.jobSegment.id == f.jobSegment?.id);
        });
      });

    });
  }

  openDetail(job) {
    this.selectedJob = job;

    // @ts-ignore
    $('#admin-job-profile-detail-popup').modal('show');
  }

  search() {

    // tslint:disable-next-line:max-line-length
    this.jobs = ((this.storedItems).filter((item: JobProfileModel) =>
      (((item.title).toLowerCase().trim()).indexOf(this.filterText.toLowerCase().trim()) > -1)
    ));


  }

  openDeletePopup(job) {
    this.selectedJob = job;

    // @ts-ignores
    $('#generalDeleteModal').modal('show');
  }

  deleteJob(flag): void {
    if (flag) {
      this.jobProfileService.ServerDelete(server.jobProfiles + '/' + this.selectedJob.id).subscribe(data => {
        // @ts-ignore
        $(generalDeleteModal).modal('hide');

        this.getJobs();
      });

    }

  }

  keyup(data) {
    data == "" ? this.getJobs() : "";
  }

  clearSearch(){
    this.filterText = "";
    this.keyup('');
  }
}
