
<div style="background-color: #F5F6F8;">
  <div class="col-12 col-lg-10 m-auto pb-5" style="padding-top: 100px">

      <div class="card pt-5 pb-5" style="background-color: white;">
        <div class="card-body">
    <div class="row">
      <div class="col-md-1 col-12 text-center mb-4 mb-md-0">

      </div>
      <div class="col-md-10 col-12 text-md-left text-center ">
        <span style="width: 27px;
  height: 27px; text-align: center ;color: #FFFFFF;
  "   class="dot" [ngClass]="step > 1 ? 'green' : step == 1 ? 'blue' : 'white'">1</span> <span class="text-height pl-2 step-header-text">{{'basicInfo' | translate}}</span>
        <hr class="line mx-1" [ngClass]="step > 1 ? 'green-hr' : 'white-hr'" />
        <span style="width: 27px;
  height: 27px;  text-align: center;color: #FFFFFF;
  " class="dot" [ngClass]="step > 2 ? 'green' : step == 2 ? 'blue' : 'white'"> 2</span> <span class="text-height pl-2 step-header-text">{{'contactInfo' | translate}}</span>
        <hr  class="line mx-1" [ngClass]="step > 2 ? 'green-hr' : 'white-hr'" />
        <span style="width: 27px;
  height: 27px;  text-align: center;color: #FFFFFF;
  " class="dot" [ngClass]="step > 3 ? 'green' : step == 3 ? 'blue' : 'white'">3</span> <span class="text-height pl-2 step-header-text">{{'aboutYou' | translate}}</span>
        <hr class="line mx-1" [ngClass]="step > 3 ? 'green-hr' : 'white-hr'" />
        <span style="width: 27px;
  height: 27px;  text-align: center;color: #FFFFFF;
  "   class="dot" [ngClass]="step > 4 ? 'green' : step == 4 ? 'blue' : 'white'">4</span>  <span class="text-height pl-2 step-header-text"> {{'companyInfo' | translate}} </span>
        <hr class="line mx-1" [ngClass]="step > 3 ? 'green-hr' : 'white-hr'" />
        <span style="width: 27px;
  height: 27px;  text-align: center;color: #FFFFFF;
  "  class="dot" [ngClass]="step > 5 ? 'green' : step == 5 ? 'blue' : 'white'">5</span> <span class="text-height pl-2 step-header-text">{{'paymentPreferences' | translate}}</span>
  <hr class="line mx-1" [ngClass]="step > 4 ? 'green-hr' : 'white-hr'" />
        <span  style="width: 27px;
  height: 27px;  text-align: center;color: #FFFFFF;
  "  class="dot" [ngClass]="step > 6 ? 'green' : step == 6 ? 'blue' : 'white'">6</span> <span class="text-height pl-2 step-header-text">{{'specialization' | translate}}</span>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-11 col-12 ml-md-auto text-center text-md-left">
        <h2>{{'completeYourProfile' | translate}}</h2>
        <p class="text-secondary">
          {{'fillInTheDataForProfileItWillTakeACoupleOfMinutes' | translate}}
        </p>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-1"></div>
      <div class="col-md-10 col-11 mx-auto mx-md-0 border rounded p-4" *ngIf="step==1">
        <h4>{{'generalInfo' | translate}}</h4>
        <tlb-form *ngIf="formGeneralInfo && formGeneralInfo.length > 0 && formGroupGeneralInfo" [forms]="formGeneralInfo" [formGroup]="formGroupGeneralInfo"></tlb-form>
      </div>
      <div class="col-md-10 col-11 mx-auto mx-md-0 border rounded p-4" *ngIf="step==2">
        <h4>{{'contactInfo' | translate}}</h4>
        <tlb-form *ngIf="formContactInfo && formContactInfo.length > 0 && formGroupContactInfo" [forms]="formContactInfo" [formGroup]="formGroupContactInfo"></tlb-form>
      </div>
      <div class="col-md-10 col-11 mx-auto mx-md-0 border rounded p-4" *ngIf="step==3">
        <h4>{{'aboutYou' | translate}}</h4>
        <tlb-form *ngIf="formAbout && formAbout.length > 0 && formGroupAbout" [forms]="formAbout" [formGroup]="formGroupAbout"></tlb-form>
      </div>

      <div class="col-md-10 col-11 ml-auto mr-auto border rounded p-4 mt-4" *ngIf="step==3">
        <h4>{{'placementCounts' | translate}}</h4>
        <div class="row mb-3">
          <div class="col-11 ml-auto mr-auto">

            <div id="accordion">
              <div class="card mb-2">
                <div class="card-header" id="headingOne" data-toggle="collapse" data-target="#collapseOne"
                  aria-expanded="true" aria-controls="collapseOne">
                  <h6 class="text-secondary user-select-none">
                    {{'experienceLevel' | translate}}: {{'entryLevel' | translate}}
                  </h6>
                </div>
                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12 m-auto"><label class="text-secondary">{{'permanentDirectHire' |
                          translate}}</label></div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12 m-auto">
                        <tlb-dropdown [items]="plecementCountOptions" [style]="'border: none; border-bottom: 1px solid gray; border-radius: 0;'"
                        [dropdownName]="about.placementCounts?.ENTRY_LEVEL?.permanent != '' &&
                        permanentCount[about.placementCounts?.ENTRY_LEVEL?.permanent] ?
                        permanentCount[about.placementCounts?.ENTRY_LEVEL?.permanent] : 'Please Select'"
                        (searchData)="placementCountsChange($event, 'ENTRY_LEVEL', 'permanent')">
                      </tlb-dropdown>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 m-auto"><label class="text-secondary">{{'temporaryContractHire' |
                          translate}}</label></div>
                    </div>
                    <div class="row">
                      <div class="col-12 m-auto">
                        <tlb-dropdown [items]="plecementCountOptions" [style]="'border: none; border-bottom: 1px solid gray; border-radius: 0;'"
                        [dropdownName]="about.placementCounts?.ENTRY_LEVEL?.temporary != '' &&
                        permanentCount[about.placementCounts?.ENTRY_LEVEL?.temporary] ?
                         permanentCount[about.placementCounts?.ENTRY_LEVEL?.temporary] : 'Please Select'"
                        (searchData)="placementCountsChange($event, 'ENTRY_LEVEL', 'temporary')">
                      </tlb-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card mb-2">
                <div class="card-header" id="headingTwo" data-toggle="collapse" data-target="#collapseTwo"
                  aria-expanded="false" aria-controls="collapseTwo">
                  <h6 class="text-secondary user-select-none">
                    {{'experienceLevel' | translate}}: {{'associate' | translate}}
                  </h6>
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12 m-auto"><label class="text-secondary">{{'permanentDirectHire' |
                          translate}}</label></div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12 m-auto">
                        <tlb-dropdown [items]="plecementCountOptions" [style]="'border: none; border-bottom: 1px solid gray; border-radius: 0;'"
                        [dropdownName]="about.placementCounts?.ASSOCIATE?.permanent != '' &&
                        permanentCount[about.placementCounts?.ASSOCIATE?.permanent] ?
                        permanentCount[about.placementCounts?.ASSOCIATE?.permanent] : 'Please Select'"
                        (searchData)="placementCountsChange($event, 'ASSOCIATE', 'permanent')">
                      </tlb-dropdown>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 m-auto"><label class="text-secondary">{{'temporaryContractHire' |
                          translate}}</label></div>
                    </div>
                    <div class="row">
                      <div class="col-12 m-auto">
                        <tlb-dropdown [items]="plecementCountOptions" [style]="'border: none; border-bottom: 1px solid gray; border-radius: 0;'"
                        [dropdownName]="about.placementCounts?.ASSOCIATE?.temporary != '' &&
                        permanentCount[about.placementCounts?.ASSOCIATE?.temporary] ?
                        permanentCount[about.placementCounts?.ASSOCIATE?.temporary] : 'Please Select'"
                        (searchData)="placementCountsChange($event, 'ASSOCIATE', 'temporary')">
                      </tlb-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card mb-2">
                <div class="card-header" id="headingThree" data-toggle="collapse" data-target="#collapseThree"
                  aria-expanded="false" aria-controls="collapseThree">
                  <h6 class="text-secondary user-select-none">
                    {{'experienceLevel' | translate}}: {{'midsenior' | translate}}
                  </h6>
                </div>
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12 m-auto"><label class="text-secondary">{{'permanentDirectHire' |
                          translate}}</label></div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12 m-auto">
                        <tlb-dropdown [items]="plecementCountOptions" [style]="'border: none; border-bottom: 1px solid gray; border-radius: 0;'"
                        [dropdownName]="about.placementCounts?.MID_SENIOR?.permanent != '' &&
                        permanentCount[about.placementCounts?.MID_SENIOR?.permanent] ?
                        permanentCount[about.placementCounts?.MID_SENIOR?.permanent] : 'Please Select'"
                        (searchData)="placementCountsChange($event, 'MID_SENIOR', 'permanent')">
                      </tlb-dropdown>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 m-auto"><label class="text-secondary">{{'temporaryContractHire' |
                          translate}}</label></div>
                    </div>
                    <div class="row">
                      <div class="col-12 m-auto">
                        <tlb-dropdown [items]="plecementCountOptions" [style]="'border: none; border-bottom: 1px solid gray; border-radius: 0;'"
                        [dropdownName]="about.placementCounts?.MID_SENIOR?.temporary != '' &&
                        permanentCount[about.placementCounts?.MID_SENIOR?.temporary] ?
                        permanentCount[about.placementCounts?.MID_SENIOR?.temporary] : 'Please Select'"
                        (searchData)="placementCountsChange($event, 'MID_SENIOR', 'temporary')">
                      </tlb-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="card mb-2">
                <div class="card-header" id="headingFour" data-toggle="collapse" data-target="#collapseFour"
                  aria-expanded="false" aria-controls="collapseFour">
                  <h6 class="text-secondary user-select-none">
                    {{'experienceLevel' | translate}}: {{'director' | translate}}
                  </h6>
                </div>
                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12 m-auto"><label class="text-secondary">{{'permanentDirectHire' |
                          translate}}</label></div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12 m-auto">
                        <tlb-dropdown [items]="plecementCountOptions" [style]="'border: none; border-bottom: 1px solid gray; border-radius: 0;'"
                        [dropdownName]="about.placementCounts?.DIRECTOR?.permanent != '' &&
                        permanentCount[about.placementCounts?.DIRECTOR?.permanent] ?
                        permanentCount[about.placementCounts?.DIRECTOR?.permanent] : 'Please Select'"
                        (searchData)="placementCountsChange($event, 'DIRECTOR', 'permanent')">
                      </tlb-dropdown>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 m-auto"><label class="text-secondary">{{'temporaryContractHire' |
                          translate}}</label></div>
                    </div>
                    <div class="row">
                      <div class="col-12 m-auto">
                        <tlb-dropdown [items]="plecementCountOptions" [style]="'border: none; border-bottom: 1px solid gray; border-radius: 0;'"
                        [dropdownName]="about.placementCounts?.DIRECTOR?.temporary != '' &&
                        permanentCount[about.placementCounts?.DIRECTOR?.temporary] ?
                        permanentCount[about.placementCounts?.DIRECTOR?.temporary] : 'Please Select'"
                        (searchData)="placementCountsChange($event, 'DIRECTOR', 'temporary')">
                      </tlb-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="card mb-2">
                <div class="card-header" id="headingFive" data-toggle="collapse" data-target="#collapseFive"
                  aria-expanded="false" aria-controls="collapseFive">
                  <h6 class="text-secondary user-select-none">
                    {{'experienceLevel' | translate}}: {{'executive' | translate}}
                  </h6>
                </div>
                <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12 m-auto"><label class="text-secondary">{{'permanentDirectHire' |
                          translate}}</label></div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12 m-auto">
                        <tlb-dropdown [items]="plecementCountOptions" [style]="'border: none; border-bottom: 1px solid gray; border-radius: 0;'"
                        [dropdownName]="about.placementCounts?.EXECUTIVE?.permanent != '' &&
                        permanentCount[about.placementCounts?.EXECUTIVE?.permanent] ?
                        permanentCount[about.placementCounts?.EXECUTIVE?.permanent] : 'Please Select'"
                        (searchData)="placementCountsChange($event, 'EXECUTIVE', 'permanent')">
                      </tlb-dropdown>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 m-auto"><label class="text-secondary">{{'temporaryContractHire' |
                          translate}}</label></div>
                    </div>
                    <div class="row">
                      <div class="col-12 m-auto">
                        <tlb-dropdown [items]="plecementCountOptions" [style]="'border: none; border-bottom: 1px solid gray; border-radius: 0;'"
                        [dropdownName]="about.placementCounts?.EXECUTIVE?.temporary != '' &&
                        permanentCount[about.placementCounts?.EXECUTIVE?.temporary] ?
                        permanentCount[about.placementCounts?.EXECUTIVE?.temporary] : 'Please Select'"
                        (searchData)="placementCountsChange($event, 'EXECUTIVE', 'temporary')">
                      </tlb-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>

      </div>
      <div class="col-md-10 col-11 mx-auto mx-md-0 border rounded p-4" *ngIf="step==4">
        <h4>{{'companyInfo' | translate}}</h4>
        <tlb-form *ngIf="formModelCompanyInfo && formModelCompanyInfo.length > 0 && formGroupCompanyInfo" [forms]="formModelCompanyInfo"  [formGroup]="formGroupCompanyInfo"></tlb-form>
      </div>

      <div class="col-md-10 col-11 mx-auto mx-md-0 border rounded p-4" *ngIf="step==6">
        <h4>{{ 'specializationInfo' | translate}}</h4>
        <tlb-form *ngIf="formSpecialization && formSpecialization.length > 0 && formGroupSpecialization" [forms]="formSpecialization"  [formGroup]="formGroupSpecialization"></tlb-form>
      </div>


      <div class="col-md-10 col-11 mx-auto mx-md-0 border rounded p-4 mt-4" *ngIf="step==5">
        <p>
          How do you wish to receive the payment after making a successful placement?

        </p>
        <h4>{{'paymentPreferences' | translate}}</h4>
        <tlb-form *ngIf="formPaymentType && formPaymentType.length > 0 && formGroupPaymentType" [forms]="formPaymentType"  [formGroup]="formGroupPaymentType"></tlb-form>
      </div>



    </div>
    <div class="row mt-3">
      <div class="col-1"></div>
      <div class="col-md-10 col-11 mx-auto mx-md-0 px-0" style=" display: flex;
      justify-content: space-between;">
        <button class="btn btn-back border mr-3" (click)="goBack()" *ngIf="step>1">← {{'previousStep' | translate}}</button>
        <button class="btn btn-next border" (click)="goNext()" *ngIf="step!=6">{{'nextStep' | translate}} →</button>
        <button class="btn btn-finish border" (click)="finish()" *ngIf="step==6">{{'finish' | translate}}</button>
      </div>
    </div>
  </div>
  </div>
  </div>

  </div>
