<div class="container-fluid mt-4">
  <div class="row">
    <div *ngFor="let quarterModel of quarterModels"
         class="col-12 col-xl-3 col-sm-6 col-xxl-3 my-2 my-xxl-0">
      <div class="card h-100"
           style="border: none;">
        <div class="card-body">
          <div class=""
               style="    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;    flex-wrap: wrap;">
            <div class="mr-3">
              <img alt=""
                   height="60"
                   src="{{quarterModel.ImgUrl}}"
                   width="60">
            </div>
            <div class="">
              <h6 class="card-title m-0 header">{{quarterModel.Title | translate}}</h6>
              <h2 class="card-title number-text">{{quarterModel.SubTitle}}</h2>
              <p class="card-text sub-text"><i class="{{quarterModel.ParagraphIconClass}}"></i>
                +{{quarterModel.SinceLastMonth}} {{quarterModel.ParagraphText | translate  }}</p>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</div>

