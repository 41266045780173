<div *ngIf="application as app"
     class="pl-4 pt-4">
  <div class="navigation-box">
    <span class="home-text cursor-pointer"
          routerLink="dashboard">{{'home' | translate}}</span>
    <span class="saved-applications-text cursor-pointer"
          routerLink="/dashboard/applications"><span class="mr-3"><img alt="next icon"
                                                                       src="assets/images/talentbase/icons/topcoat_next-light.png"></span> {{'applications' | translate}}</span>
  </div>
  <ng-container *ngIf="app?.job as job">
    <div class="job-title-bar">
      <h3>{{job.title}}</h3>
    </div>
    <hr>
    <div class="card border-0 border-radius-0">
      <div class="job-app-status-box">
        <div class="job-image-title-box">
          <div class="job-image-box">
            <img [src]="job?.logoUrl && job?.logoUrl != '' ? job?.logoUrl : './assets/images/default-logo.png'"
                 alt="job image"
                 class="job-image">
          </div>
          <div class="job-box">
            <div class="job-title-box">
              {{job?.title}}
            </div>
            <div class="job-company-box">
              {{app?.employer?.name}}
            </div>
          </div>
        </div>
        <div
          class="status-box">
          <!--        <div #statusOptions
                       class="status-options">
                    <div *ngFor="let applicationStatusEnum of applicationStatusOptions"
                         class="status-option">{{applicationStatusEnum}}</div>
                  </div>-->
          <div class="mr-1 pb-xl-0 pb-md-1 pb-1">
                          <span *ngIf="app?.selectableApplicationStatuses?.length==0"
                                [ngClass]="applicationDetailStatusService.getStatusClass(app.status)"
                                class="btn offer-stage cursor-default"><span class="px-2">{{app.status |
                            translate }}</span></span>
            <button *ngIf="app?.selectableApplicationStatuses?.length>0"
                    [ngClass]="applicationDetailStatusService.getStatusClass(app?.status)"
                    aria-expanded="false"
                    class="btn offer-stage"
                    data-toggle="dropdown"
                    id="application-status-by-employer-dropdown-menu-button">
              <span class="mr-2">{{app?.status | translate }}</span>
              <i class="fas fa-chevron-down"></i>
            </button>
            <div *ngIf="app?.selectableApplicationStatuses.length>0"
                 aria-labelledby="application-status-by-employer-dropdown-menu-button"
                 class="dropdown-menu">
              <a (click)="changeApplicationStatus(status)"
                 *ngFor="let status of app?.selectableApplicationStatuses"
                 class="dropdown-item cursor-pointer">{{status | translate}}</a>
            </div>
          </div>
          <!--        <select
                    [(ngModel)]="app.status"
                    [ngClass]="getStatusClass(app.status)"
                    class="status-select">
                    <option *ngFor="let applicationStatusOption  of applicationStatusOptions "
                            [ngValue]="applicationStatusOption">{{applicationStatusOption | translate}}</option>

                  </select> -->

        </div>
      </div>
    </div>
    <div class="card border-0 border-radius-0 mt-2">
      <div class="detail-box">
        <div class="detail-title-box">
          {{'details' | translate | uppercase}}
        </div>
        <div class="details-box">
          <div class="detail">
            <div class="detail-name">
              {{'candidateName' | translate | titlecase}}
            </div>
            <div class="detail-value">
              {{app?.candidate?.fullName}}
            </div>
          </div>
          <div class="detail">
            <div class="detail-name">
              {{'jobTitle' | translate | titlecase}}
            </div>
            <div class="detail-value">
              {{job?.title}}
            </div>
          </div>
          <div *ngIf="app?.recruiter as recruiter"
               class="detail">
            <div class="detail-name">
              {{'recruiterName' | translate | titlecase}}
            </div>
            <div class="detail-value">
              {{recruiter?.user?.fullName}}
            </div>
          </div>
          <div class="detail">
            <div class="detail-name">
              {{'appliedOn' | translate | titlecase}}
            </div>
            <div class="detail-value">
              {{app?.createdAt | date:'dd/MM/YYY'}}
            </div>
          </div>

        </div>
      </div>
    </div>

    <div *ngIf="app.candidate.coverLetter.url!=null || app.candidate.resume.url!=null" class="card border-0 border-radius-0 mt-2">
      <div class="action-box">
        <div class="action-title-box">
          {{'attachment' | translate | uppercase}}
        </div>
        <div class="actions-box">

          <div class="col-12" *ngIf="app.candidate.coverLetter.url!=null">
            <a class="cursor-pointer color-brand" (click)="openUrl(app.candidate.coverLetter.url)">{{'openCoverLetter' |
              translate |titlecase}}</a>
          </div>

          <div class="col-12" *ngIf="app.candidate.resume.url!=null">
            <a class="cursor-pointer color-brand" (click)="openUrl(app.candidate.resume.url)">{{'openResume' | translate
              |titlecase}}</a>
          </div>



        </div>
      </div>
    </div>

    <div class="card border-0 border-radius-0 mt-2">
      <div class="action-box">
        <div class="action-title-box">
          {{'actions' | translate | uppercase}}
        </div>
        <div class="actions-box">
          <div class="add-note-button-box">
            <button (click)="showAddNoteBox()"
                    class="add-note-button">
              <img alt="plus"
                   class="add-note-button-image"
                   src="assets/images/talentbase/icons/plus.png">
              {{'addNote' | translate | titlecase}}
            </button>
          </div>
          <div class="print-button-box">
            <button (click)="print()"
                    class="print-button">
              <img alt="print"
                   class="print-button-image"
                   src="assets/images/talentbase/icons/printer.png">
              {{'print' | translate | titlecase}}
            </button>
          </div>
          <div class="save-button-box">
            <i class="fa-bookmark cursor-pointer"  [ngClass]="application.save?'fas':'far'" (click)="!application.save?saveApplication(application):unSaveApplication(application)"></i>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="applicationDetailService.addNoteState"
         [formGroup]="noteForm"
         class="card border-0 border-radius-0 mt-2">
      <div class="add-note-box">
        <div class="add-note-title-box">
          {{'addNote' | translate | uppercase}}
        </div>
        <div class="add-note-container">
          <div class="note-title-box">
            <input [(ngModel)]="applicationDetailService.notePostModel.title"
                   [placeholder]="'noteTitle' | translate"
                   class="note-title-input"
                   formControlName="title"
                   type="text">
            <span *ngIf="!noteForm.get('title').valid "
                  class="required-label">*</span>
          </div>
          <div class="note-detail-box">
          <textarea [(ngModel)]="applicationDetailService.notePostModel.note"
                    [placeholder]="'writeNote' | translate"
                    class="note-detail-input"
                    formControlName="note"
                    rows="5"></textarea>
            <span *ngIf="!noteForm.get('note').valid"
                  class="required-label">*</span>
          </div>
          <div class="note-actions-box">
            <div class="note-cancel-box">
              <button (click)="cancelAddNote()"
                      class="note-cancel-button">{{'cancel' | translate}}</button>
            </div>
            <div class="note-save-box">
              <button (click)="addNote()"
                      [disabled]="!noteForm.valid"
                      class="note-save-button">{{'save' | translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card border-0 border-radius-0 mt-2">
      <div class="note-box">
        <div class="note-title-box">
          {{'notes' | translate | uppercase}}
        </div>
        <div *ngIf="applicationDetailService.$notes | async as notes"
             class="notes-box">
          <tlb-empty-page *ngIf="!notes || notes.length == 0"
                          [warningLabel]="'noNotesYet' | translate"></tlb-empty-page>
          <div *ngFor="let note of notes| paginate:{ itemsPerPage: applicationDetailService.requestPayload.limit, currentPage: applicationDetailService.currentPage,totalItems:applicationDetailService.count}"
               class="note-container">
            <div class="note-content">
              <div class="note-title">
                {{note.title}}
              </div>
              <div class="note">
                <span class="mr-3"></span>
                {{note.note}}
              </div>
            </div>
            <div class="note-actions">
              <img (click)="applicationDetailService.editNote(note)"
                   alt=""
                   src="assets/images/talentbase/icons/pencil.png">
              <img (click)="openNoteDeleteModal(note)"
                   alt=""
                   src="assets/images/talentbase/icons/trash.png">
            </div>
          </div>
          <div *ngIf="notes && notes.length > 0"
               class=" container-fluid row mt-3 pagination">
            <pagination-controls (pageChange)="applicationDetailService.currentPage = $event;applicationDetailService.getNotes()"
                                 [autoHide]="applicationDetailService.count <= applicationDetailService.requestPayload.limit"
                                 nextLabel="{{'next' | translate}}"
                                 previousLabel="{{'previous' | translate}}"
                                 responsive="true"></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </ng-container>


</div>

<app-application-detail-agreed-salary-amount-modal (hire)="hireApp($event)"
                                                   [salary]="applicationDetailService.salary"></app-application-detail-agreed-salary-amount-modal>
<app-application-detail-note-delete-modal (delete)="applicationDetailService.deleteNote($event)"
                                          [selectedNote]="selectedNote"></app-application-detail-note-delete-modal>

