export class CandidateCountsModel {

  totalCandidates: number;
  totalCandidatesSinceLastMonth: number;
  totalApplications: number;
  totalApplicationsSinceLastMonth: number;
  qualifiedApplications: number;
  qualifiedApplicationsSinceLastMonth: number;
  applicationsUnderReview: number;
  applicationsUnderReviewSinceLastMonth: number;
  applicationsWaitingForAction: number;
  applicationsWaitingForActionSinceLastMonth: number;

  constructor() {
    this.totalCandidates= 0;
    this.totalCandidatesSinceLastMonth= 0;
    this.totalApplications= 0;
    this.totalApplicationsSinceLastMonth= 0;
    this.qualifiedApplications= 0;
    this.qualifiedApplicationsSinceLastMonth= 0;
    this.applicationsUnderReview= 0;
    this.applicationsUnderReviewSinceLastMonth= 0;
    this.applicationsWaitingForAction= 0;
    this.applicationsWaitingForActionSinceLastMonth= 0;
  }
}
