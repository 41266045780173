import {Component, OnInit} from '@angular/core';
import {ApplicationService, EmployerService, JobService, RecruiterService} from '../../shared/services';
import {Observable} from 'rxjs';
import {ApplicationModel, ApplicationStatusEnum} from '../../shared/models/applications/application.model';
import {map} from 'rxjs/operators';
import {CreateAvatar, RequestPayload} from '../../shared/classes';
import {ApplicationDetailStatusService} from './application-detail/application-detail-status.service';

@Component({
    selector: 'app-applications',
    templateUrl: './applications.component.html',
    styleUrls: ['./applications.component.scss']
})
export class ApplicationsComponent implements OnInit {
    activeTab = 0;

    applications !: Observable<ApplicationModel[]>;
    createAvatar: CreateAvatar;
    sort: 'createdAt,asc' | 'createdAt,desc' = 'createdAt,desc';
    searchText = '';
    filterText = '';
    currentPage = 1;
    count!: number;
    requestPayload: RequestPayload;
    applicationss: any[] = [];

    constructor(private applicationService: ApplicationService,
                private recruiterService: RecruiterService,
                private employerService: EmployerService,
                private jobService: JobService,
                public applicationDetailStatusService: ApplicationDetailStatusService) {
        this.createAvatar = new CreateAvatar();
        this.requestPayload = new RequestPayload();
        this.requestPayload.limit = 10;
    }

    ngOnInit(): void {
        this.getApplications();
    }

    changeActiveTab(tab: number): void {
        this.activeTab = tab;
        this.clearSearch();
        this.clearFilter({});
        this.applicationService.clearFilterAndSortSubject.next();
        this.sort = 'createdAt,desc';
        this.getApplications();
    }

    searchCharControl(event) {
        if (event.keyCode === 223 || event.keyCode === 49 || event.keyCode === 226 || event.keyCode === 221) {
            event.preventDefault();
        }
    }

    getApplications(): void {
        this.applications = this.applicationService.getApplications(
            this.getApplicationFilterText()
        )
            .pipe(
                map(data => {
                    this.count = data.totalElements;
                    this.applicationss = data.content;

                    return data.content;
                }),
                map(data => {
                    data.forEach(application => {
                        application.candidate.imageText = this.createAvatar.create(
                            application.candidate.fullName);
                        application.candidate.imageColor = this.createAvatar.color();
                        application.$recruiter = this.recruiterService.ServerGetWithText(
                            '/' + application.recruiterId);
                        application.$job = this.jobService.ServerGetWithText(
                            '/' + application.job.id)
                            .pipe(map(job => {
                                this.employerService.ServerGetWithText(
                                    '/' + application.employer.id + '/company-logo')
                                    .subscribe((res: any) => {
                                        job.logoUrl = res.url;
                                    });
                                return job;
                            }));
                    });

                    return data;
                })
            );
    }

    getApplicationFilterText(): string {
        let filterText = this.requestPayload.payloadURLApp(this.currentPage);
        filterText += `${this.filterText !== '' ? this.filterText : ''}`;
        filterText += `${this.sort ? '&sort=' + this.sort : ''}`;
        filterText += `${this.activeTab === 1 ? '&filter=save::true' : ''}`;
        filterText += `${this.searchText != '' ? '&filter=freeText~~' + this.searchText : ''}`;

        return filterText;
    }

    getTabClass(tab: number): string {
        return this.activeTab === tab ? 'active' : '';
    }

    getStatusClass(status: ApplicationStatusEnum): string {
        return this.applicationDetailStatusService.getStatusClass(status);
    }

    getFilteredApplications(data: any): void {
        let params = '';
        Object.keys(data)
            .forEach(key => {
                switch (data[key].type) {
                    case 'any':
                        params += `&filter=${data[key].name}**${data[key].data}`;
                        break;
                    case 'equals':
                        params += `&filter=${data[key].name}::${data[key].data}`;
                        break;
                }
            });
        this.filterText = params;
        this.getApplications();

    }

    clearFilter(data: any): void {
        this.filterText = '';
        this.getApplications();

    }

    sortChanged(data: any): void {
        this.sort = data;
        this.getApplications();

    }

    searchTextChanged(data: string): void {
        console.log(data);

        this.searchText = data;
        if (data.length > 2) {
            this.getApplications();
        } else if (data.length === 0) {
            this.getApplications();
        }

    }

    clearSearch() {
        this.searchText = '';
        this.getApplications();
    }

    saveApplication(application: ApplicationModel): void {
        this.applicationService.saveApplication(application.id).subscribe(() => {
            this.getApplications();
        });

    }

    unSaveApplication(application: ApplicationModel): void {
        this.applicationService.unSaveApplication(application.id).subscribe(() => {
            this.getApplications();
        });
    }
}
