import { Component, Input, OnInit, AfterViewChecked } from '@angular/core';
import { SIDEBAR_MENU }                               from './sidebar-menu';

declare var $: any;

@Component({
             selector: 'app-sidebar',
             templateUrl: './sidebar.component.html',
             styleUrls: ['./sidebar.component.scss']
           })
export class SidebarComponent implements OnInit, AfterViewChecked {

  @Input() opened: boolean;
  afterViewCheck: boolean;
  role: string;

  menu = SIDEBAR_MENU;

  constructor() {
    this.afterViewCheck = false;
  }

  ngAfterViewChecked(): void {

    if (this.afterViewCheck === false) {
      window.addEventListener('resize', () => {
        if (window.innerWidth >= 1200) {
          $('#mySidenav')
            .width('220px');
        } else {
          $('#mySidenav')
            .width('0px');
        }
      });
      const hasSubMenu = $('.has-sub-menu');
      const sidebarMenuSubMenu = '.sidebar-menu-sub-menu';
      this.afterViewCheck = true;
      const $subMenus = hasSubMenu.find('.sidebar-menu-sub-menu');
      hasSubMenu.on('click', function(): void {
        if ($(this)
          .find(sidebarMenuSubMenu)
          .hasClass('hidden')) {
          $subMenus.addClass('hidden');
          $(this)
            .find(sidebarMenuSubMenu)
            .removeClass('hidden');
        } else {
          $subMenus.addClass('hidden');
        }
      });
    }
  }

  ngOnInit(): void {
    this.role = localStorage.getItem('role');

  }

  closeMenu(): void {

    $('#mySidenav')
      .width('0px');
  }
}

