import { JobAuthorizedPersonModel } from ".";

export class JobNotificationsAndCorporatesModel {

  id: string;
  atsJobId: string;
  differentNotificationEmail: string;
  authorizedPerson: JobAuthorizedPersonModel;
  emailNotification: boolean;
  pushNotification: boolean;

constructor() {
  this.id = "";
  this.atsJobId = null;
  this.differentNotificationEmail = null;
  this.authorizedPerson = new JobAuthorizedPersonModel();
  this.emailNotification = false;
  this.pushNotification = false;
}
}
