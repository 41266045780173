<div class="container px-xl-5 px-1 mt-4">
  <h5 class="edit-profile pb-1 pt-2">{{ 'editProfile' | translate }}</h5>
</div>
<div class="container px-xl-5 px-1 mt-4">

  <div class="pb-4">
    <div class="card">
      <div class="card-body">
        <div class="container px-xl-5 px-1">
          <div class="row">
            <div class="col-12">
              <p><b>{{ 'paymentPreferences' | translate }}</b></p>
            </div>
            <div class="col-12">
              <p>{{ 'howDoYouWishToReceiveThePaymentAfterMakingASuccessfulPlacement' | translate }}</p>
            </div>
          </div>

          <section>
            <div class="form-check mb-2 border py-3" >
              <div class="pl-2">
                <input [(ngModel)]="recruiterModel.paymentReferenceType" type="radio" class="form-check-input filled-in" name="one" id="filledInCheckbox" value="ELECTRONIC" checked>
                <label class="form-check-label " for="filledInCheckbox">{{ 'ElectronicPaymentAch' | translate }}</label>
              </div>
            </div>

            <div class="form-check mb-2 border py-3" >
              <div class="pl-2">
                <input [(ngModel)]="recruiterModel.paymentReferenceType" type="radio" class="form-check-input filled-in" name="one" id="filledInCheckboxOne" value="MAIL_CHECK" >
              <label class="form-check-label " for="filledInCheckboxOne">{{ 'MailACheck' | translate }}</label>
              </div>
            </div>

            <div class="form-check mb-2 border py-3" >
              <div class="pl-2">
                <input [(ngModel)]="recruiterModel.paymentReferenceType" type="radio" class="form-check-input filled-in" name="one" id="filledInCheckboxTwo" value="NOT_DECIDED">
                <label class="form-check-label " for="filledInCheckboxTwo">{{ 'NotSureAtTheMoment' | translate }}</label>
              </div>
            </div>
          </section>
          <br>

          <div class="row pt-3">
          </div>
          <div class="row">
          </div>

        </div>
      </div>
    </div>
  </div>
  <div>
  </div>

<div class="pb-4">
  <div class="card">
    <div class="card-body">
      <div class="container px-xl-5 px-0">
        <div class="row">
          <div class="col-12">
            <p><b>{{ 'yourSpecialization' | translate }}</b></p>
          </div>
          <div class="col-12">
            <p>{{ 'selectUpToFiveFunctionalRolesThatRepresentYourRecruitingExpertise' | translate }}</p>
          </div>
        </div>
        <div class="row pt-3">
        </div>
        <div class="row">
          <div *ngFor="let role of availableFunctionalRoles" class="col-12">
            <div class="row">
           <div class="col-xl-11 col-md-11 col-10 pb-4">
              <tlb-dropdown [items]="functionalRoleOptions"
              [dropdownName]="role.name"
              (searchData)="selectRole($event, availableFunctionalRoles.indexOf(role))" [isFilter]="false">
            </tlb-dropdown>
            </div>
            <div class="col-xl-1 col-md-1 col-2">
              <button class="search-button" (click)="deleteRole(availableFunctionalRoles.indexOf(role))" type="submit"><i class="fas fa-times search-icon"></i></button>
            </div>
            </div>


        </div>
        </div>

        <div class="row pt-3 pb-1">
          <div class="mt-2 pt-2 add" (click)="addRole()">

              <button class="msg_send_btn"  type="button"><i class="fas fa-plus"></i></button>
              <a class="p-2 pl-3 anchorTagCss">{{'add' | translate}}</a>


          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div>
</div>
<div class="pb-4">
  <div class="card">
    <div class="card-body">
      <div class="container px-xl-5 px-0">
        <div class="row">
          <div class="col-12">
            <p><b>{{ 'industrySpecialization' | translate }}</b></p>
          </div>
          <div class="col-12">
            <p>{{ 'selectUpToFiveIndustriesThatRepresentYourRecruitingExpertise' | translate }}</p>
          </div>
        </div>
        <div class="row pt-2">
        </div>
        <div class="row">
          <div *ngFor="let ind of availableIndustries" class="col-12">
            <div class="row">
              <div class="col-xl-11 col-md-11 col-10 pb-4">
            <tlb-dropdown [items]="industryOptions"
            [dropdownName]="ind.name"
            (searchData)="selectIndustry($event,  availableIndustries.indexOf(ind))" [isFilter]="false">
          </tlb-dropdown>
        </div>
        <div class="col-xl-1 col-md-1 col-2">
          <button class="search-button" (click)="deleteIndustry(availableIndustries.indexOf(ind))" type="submit"><i class="fas fa-times search-icon"></i></button>
        </div>
        </div>
          </div>
          </div>

        <div class="row pt-3 pb-1">
          <div class="mt-2 pt-2 add" (click)="addIndustry()">

              <button class="msg_send_btn" type="button"><i class="fas fa-plus"></i></button>
              <a class="p-2 pl-3 anchorTagCss" >{{'add' | translate}}</a>


           </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-5">
    <a href="#" class="font-weight-bold btnCancel  "> {{'cancels' | translate}} </a>
    <button type="button" class="btn btnUpdateProfile " [disabled]="recruiterModel.paymentReferenceType == defaultModel.paymentReferenceType && !isEdited" (click)="updateProfile()" >{{'updateProfile' | translate}}</button>
  </div>
</div>
</div>
