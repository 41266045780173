<div class="modal fade  bd-example-modal-xl" id="candidateUpdateStatusModal" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel" aria-hidden="true" style="background-color: #000000b3;">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content p-2">
      <!-- <div class="modal-header modal-xl">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div> -->
      <div class="modal-body">
        <div class="container-fluid">
          <div class="row mt-3">
            <div class="">
              <img src="assets/images/avatar_empty.png" style="width: 80px; height: 80px;">
            </div>
            <div class="col">
              <div class="row mt-2">
                <div class="col-12">
                  <b>
                    <h5>
                      {{application?.candidate?.fullName}}
                    </h5>
                  </b>

                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <b>
                    <h6>
                      {{application?.candidate?.resume.title}}
                    </h6>
                  </b>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <span class="gray">
                {{'appliedOn'|translate}} {{application?.date}}
              </span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-3">
              <button class="icon-background" type="button">
                <i class="fas fa-phone-alt"></i>
              </button>
              <span class="pl-4 texts gray">
                {{application?.candidate.phoneNumber.countryCallingCode}}
                {{application?.candidate.phoneNumber.phoneNumber}}
              </span>
            </div>
            <div class="col-3">
              <button class="icon-background" type="button">
                <i class="fas fa-envelope"></i>
              </button>
              <span class="pl-4 pr-xl-3  texts gray">{{application?.candidate.email}}</span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <span class="gray">
                <b>{{'applicationStatus'|translate}}:</b> {{application?.status|translate}}
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <span class="gray">
                {{'newCandidateStatus'|translate}}
              </span>
            </div>
          </div>
          <div *ngIf="application" class="row mt-3">
            <div class="col-8">
              <tlb-dropdown [items]="applicationStatus"
          [dropdownName]="application?.status != '' ? application?.status : 'pleaseSelect'"
          (searchData)="changeApplicationStatus($event)" [disabled]="application.status == 'SUBMITTED' || application.status == 'REJECTED_BY_CANDIDATE'
          || application.status == 'HIRED' || application.status == 'CANCELLED' || application.status == 'REJECTED_BY_EMPLOYER'"></tlb-dropdown>
            </div>
            <div *ngIf="this.tempStatus != 'HIRED'"  class="col-4">
              <button class="form-control green-button" (click)="sendStatusToServer()"  [disabled]="application.status == 'SUBMITTED' || application.status == 'REJECTED_BY_CANDIDATE'
              || application.status == 'HIRED' || application.status == 'CANCELLED' || application.status == 'REJECTED_BY_EMPLOYER'">
                {{'updateCandidateStatus'|translate}}
              </button>
            </div>
          </div>

          <div *ngIf="this.tempStatus == 'HIRED' && application" class="row mt-3">
            <div class="col-8">
              <input [(ngModel)]="agreedSalaryAmount" class="form-control" id="fee" placeholder="{{'agreedSalaryAmount' | translate}}"
                min="0" step=".01" type="number" [disabled]="application.status == 'SUBMITTED' || application.status == 'REJECTED_BY_CANDIDATE'
                || application.status == 'HIRED' || application.status == 'CANCELLED' || application.status == 'REJECTED_BY_EMPLOYER'">
            </div>
            <div class="col-4">
              <button class="form-control green-button" (click)="sendStatusToServer()" [disabled]="application.status == 'SUBMITTED' || application.status == 'REJECTED_BY_CANDIDATE'
              || application.status == 'HIRED' || application.status == 'CANCELLED' || application.status == 'REJECTED_BY_EMPLOYER'">
                {{'updateCandidateStatus'|translate}}
              </button>
            </div>
          </div>



        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-control gray-button w-25" data-dismiss="modal" >

          {{'close'|translate}}
        </button>
      </div>
    </div>
  </div>
</div>
