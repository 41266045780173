<div class="container-fluid">
  <div class="text-center mt-5">
    <img src="./../../../assets/images/talentbase/verify-billing.svg">
    <h4 class="pt-3">{{'thankYou' | translate}}!</h4>
    <p style="font-weight: 900; font-size: 18px;">{{'paymentSuccess' | translate}}</p>
    <div class="pt-4 d-flex justify-content-center">
      <div class="col-xl-10 col-12">
        <div class="card pb-4">
          <div class="card-body">
            <div class="row pt-3 pl-xl-5">
              <div class="col-5">
                <h5 class="d-flex justify-content-start">{{'billing' | translate}}</h5>
              </div>
              <div class="col-1"></div>
              <div class="col-5">
                <h5 class="d-flex justify-content-start">{{'orderDetails' | translate}}</h5>
              </div>
            </div>
            <div class="row pl-xl-5">
              <div class="col-xl-5 col-12 pb-4" style="background-color: #F5F5F5;">
                <div class="text-left">
                  <h6 class="pt-3">Banu Elson</h6>
                  <p>orders@banuelson.com</p>
                  <p>+49 179 111 1010</p>
                  <p>Leibnizstraße 16, Wohnheim 6, No: 8X
                    Clausthal-Zellerfeld, Germany</p>
                </div>
              </div>
              <div class="col-1"></div>
              <div class="col-xl-5 col-12 pb-4" style="background-color: #FEFCE5;">
                <div class="text-left">
                  <h1 class="pt-4">900€<span style="color: #1111117A; font-size: 17px;">/month</span></h1>
                  <h1 class="pt-4">Premium</h1>
                  <p style="color: #1111117A; font-size: 17px;">For most businesses that want to otpimize web queries</p>
                  <button class="btn change-plan-button">Change Plan</button>
                </div>
              </div>
            </div>
            <div class="row pt-5">
              <div class="col-12">
                <button class="btn main-page-button">Go to Main Page</button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
