import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EducationLevelType, ExperienceLevelType, JobProfileType, RelocationAssistanceType } from 'src/app/shared/enums';
import { DropdownOptionsModel, GeneralIdModel, JobProfileModel } from 'src/app/shared/models';
import { CityService, CountryService, IndustryService, JobClassificationService, JobFunctionService, JobsProfileService, JobSegmentService, NotificationService, server, TranslatePipe } from 'src/app/shared/services';

@Component({
  selector: 'app-admin-job-profile-detail',
  templateUrl: './admin-job-profile-detail.component.html',
  styleUrls: ['./admin-job-profile-detail.component.scss']
})
export class AdminJobProfileDetailComponent implements OnInit {

  @Input() selectedJobProfile: JobProfileModel;

  segmentOptions: DropdownOptionsModel[] = [];
  industryOptions: any[] = [];
  classificationOptions: DropdownOptionsModel[] = [];
  functionOptions: DropdownOptionsModel[] = [];
  countryOptions: DropdownOptionsModel[] = [];
  cityOptions: DropdownOptionsModel[] = [];
  industryLabel: string = "";
  classificationLabel: string = "";
  functionLabel: string = "";
  countryLabel: string = "";
  cityLabel: string = "";
  experienceLevel: string = "";
  educationLabel: string = "";
  relocationLabel: string = "";
  segmentLabel: string = "";

  experienceLevelOptions: DropdownOptionsModel[] = [];
  educationLevelOptions: DropdownOptionsModel[] = [];
  relocationAssistanceOptions: DropdownOptionsModel[] = [];
  skillsString: string = "";
  @Output() savedJobProfiled = new EventEmitter();

  textColor: "font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;"

  constructor(private classificationService: JobClassificationService, private functionService: JobFunctionService, private industryService: IndustryService,
    private countryService: CountryService, private cityService: CityService, private notification: NotificationService,
    private translate: TranslatePipe, private jobProfileService: JobsProfileService, private jobSegmentService: JobSegmentService) { }

  ngOnInit(): void {

  }

  ngOnChanges(){

    if(!this.selectedJobProfile || this.selectedJobProfile.id == null){
      this.selectedJobProfile = new JobProfileModel();
    }
    this.skillsString = "";
    this.setOptions();
  }

  setOptions() {

      this.classificationService.ServerGetWithoutLogin().subscribe(x => {
        let classifications = x.content;
        this.classificationOptions = classifications.map(c => ({ value: c.id, label: c.defaultLocalization.name }));
        this.classificationLabel = this.selectedJobProfile && this.selectedJobProfile.id ? this.classificationOptions.filter(x => x.value == this.selectedJobProfile.classification.id)[0]?.label : "";
      });

      this.jobSegmentService.ServerGet().subscribe(x => {
        let segments = x.content;
        this.segmentOptions = segments.map(c => ({ value: c.id, label: c.name }));
        this.segmentLabel = this.selectedJobProfile && this.selectedJobProfile.id && this.selectedJobProfile.jobSegment ? this.segmentOptions.filter(x => x.value == this.selectedJobProfile.jobSegment.id)[0]?.label : "pleaseSelect";
      });

      this.functionService.ServerGetWithoutLogin().subscribe(x => {
        let functions = x.content;
        this.functionOptions = functions.map(c => ({ value: c.id, label: c.defaultLocalization.name }));
        this.functionLabel = this.selectedJobProfile && this.selectedJobProfile.id ? this.functionOptions.filter(x => x.value == this.selectedJobProfile.function.id)[0]?.label : "";
      });
      this.industryService.ServerGetWithoutLogin().subscribe(x => {
        let industries = x.content;
        this.industryOptions = industries.map(c => ({ value: c.id, label: c.defaultLocalization.name }));
        this.industryLabel = this.selectedJobProfile && this.selectedJobProfile.id ? this.industryOptions.filter(x => x.value == this.selectedJobProfile.industry.id)[0]?.label : "";
      });


      this.countryService.ServerGetWithoutLogin().subscribe(x => {
        let countries = x.content;
        this.countryOptions = countries.map(c => ({ value: c.id, label: c.defaultLocalization.name }));
        this.countryLabel = this.selectedJobProfile && this.selectedJobProfile.id ? this.countryOptions.filter(x => x.value == this.selectedJobProfile.location.country.id)[0]?.label : "";
      });

      if(this.selectedJobProfile && this.selectedJobProfile.id){
        this.cityService.getCountryCities(this.selectedJobProfile.location.country.id).subscribe(x => {
          this.cityOptions = x.map(city => ({ value: city.id, label: city.defaultLocalization.name }));
          this.cityLabel = this.selectedJobProfile.id ? this.cityOptions.filter(x => x.value == this.selectedJobProfile.location.city.id)[0]?.label : "";
        });
        this.selectedJobProfile.skills.forEach(x => {
          this.skillsString = this.skillsString == '' ? x : this.skillsString + ',' + x;
        });
      }

      this.experienceLevelOptions = Object.keys(ExperienceLevelType).map(c => ({ value: c, label: c }));
      this.experienceLevel = this.selectedJobProfile.id ? this.selectedJobProfile.experienceLevel : "";

      this.educationLevelOptions = Object.keys(EducationLevelType).map(c => ({ value: c, label: c }));
      this.educationLabel = this.selectedJobProfile.id ? this.selectedJobProfile.educationLevel : "";

      this.relocationAssistanceOptions = Object.keys(RelocationAssistanceType).map(c => ({ value: c, label: c }));
      this.relocationLabel = this.selectedJobProfile.id ? this.selectedJobProfile.relocationAssistance.type : "";
    }

    optionChangeWithId(data, type) {
      switch (type) {
        case 'classification':
          this.selectedJobProfile.classification = new GeneralIdModel();
          this.selectedJobProfile.classification.id = data.value;
          break;
        case 'function':
          this.selectedJobProfile.function = new GeneralIdModel();
          this.selectedJobProfile.function.id = data.value;
          break;
        case 'industry':
          this.selectedJobProfile.industry = new GeneralIdModel();
          this.selectedJobProfile.industry.id = data.value;
          break;
          case 'segment':
            this.selectedJobProfile.jobSegment = new GeneralIdModel();
            this.selectedJobProfile.jobSegment.id = data.value;
            break;
        case 'country':
          this.selectedJobProfile.location.country = new GeneralIdModel();
          this.selectedJobProfile.location.country.id = data.value;
          this.cityService.getCountryCities(data.value).subscribe(x => {
            this.cityOptions = x.map(city => ({ value: city.id, label: city.defaultLocalization.name }));
          });
          break;
        case 'city':
          this.selectedJobProfile.location.city = new GeneralIdModel();
          this.selectedJobProfile.location.city.id = data.value;
          break;
      }
    }


    optionChangeWithString(data, type) {

      switch (type) {
        case 'experience':
          this.selectedJobProfile.experienceLevel = data.value;
          break;
        case 'education':
          this.selectedJobProfile.educationLevel = data.value;
          break;
        case 'relocation':
          this.selectedJobProfile.relocationAssistance.type = data.value;
          break;
      }

    }

    onEnter() {
      this.skillsString = this.skillsString + ",";
    }

    save(){
      this.selectedJobProfile.skills = this.skillsString.split(",");
      this.selectedJobProfile.type = JobProfileType.PUBLIC;
      if(this.selectedJobProfile && this.selectedJobProfile.id){
        this.jobProfileService.ServerPut(server.jobProfiles + "/" + this.selectedJobProfile.id, this.selectedJobProfile).subscribe(x=>{
          this.notification.success(this.translate.transform('theJobProfileUpdated'));
          this.savedJobProfiled.emit();
        });


      }
      else{
        this.jobProfileService.ServerPost(this.selectedJobProfile).subscribe(x=>{
          this.notification.success(this.translate.transform('newJobProfileCreated'));
          this.savedJobProfiled.emit();
        });

      }

    }

}
