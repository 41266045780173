import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import {RequestPayload} from 'src/app/shared/classes';
import {JobType} from 'src/app/shared/enums';
import {ApplicationModel, DropdownOptionsModel, JobModel} from 'src/app/shared/models';
import {ApplicationService, JobService, NotificationService, server, TranslatePipe} from 'src/app/shared/services';

@Component({
  selector: 'app-employer-job-list',
  templateUrl: './employer-job-list.component.html',
  styleUrls: ['./employer-job-list.component.scss']
})
export class EmployerJobListComponent implements OnInit {
  @Input() jobs: JobModel[] = [];
  @Input() count: number;
  @Input() limit: number;
  @Input() filterType: string = "title"
  @Output() updateJobs = new EventEmitter();

  @Output() updatedStatus = new EventEmitter();
  @Output() sorted = new EventEmitter();
  @Output() getJobListAgain = new EventEmitter();
  @Output() setFilter = new EventEmitter();
  currentPage: number;
  requestPayload: RequestPayload;
  applications: ApplicationModel[] = [];
  selectedApplication: ApplicationModel;
  selectedJob: any;
  reviews: any[] = [];
  sortText: string = '&sort=createdAt,DESC';
  filterText: string = '';
  postFilter: string = '';
  sortLabel: string = this.translate.transform('newestFirst');
  sortOptions: DropdownOptionsModel[] = [
    {label: this.translate.transform('newestFirst'), value: '&sort=createdAt,DESC'},
    {label: this.translate.transform('oldestFirst'), value: '&sort=createdAt,ASC'}
  ];
  jobClone: any = null;

  constructor(private translate: TranslatePipe, private applicationService: ApplicationService, private router: Router, private notification: NotificationService,
    private jobService: JobService) {
    this.requestPayload = new RequestPayload();
  }

  ngOnInit(): void {
    this.requestPayload.limit = this.limit;
  }

  pagination(pageNo) {
    this.updateJobs.emit(pageNo);
  }

  public get jobType(): typeof JobType {
    return JobType;
  }

  selectSort(data) {
    this.sortLabel = data.label;
    this.sortText = data.value;
    this.sorted.emit(this.sortText);
    // this.getUsers(1);
  }

  openApplicantsModal(app, job) {
    this.applicationService.ServerGetWithText('?filter=jobId::' + job.id).subscribe(x => {
      this.applications = x.content;
      this.selectedJob = job;
      this.applications.forEach(application => {
        application.location = this.selectedJob.location;
        application.jobType = this.selectedJob.type;
      });
      // @ts-ignore
      $('#applicantsModalLong').modal('show');
    });
  }

  openApprovalEmailModal() {
    // @ts-ignore
    $('#approvalModal').modal('show');
  }

  openJobDetail(job: JobModel) {
    this.selectedJob = job;

    // @ts-ignore
    $('#jobDetailFromEmployerList').modal('show');

  }

  openCloneYesOrNoPopup(job){
    this.jobClone = job;

        // @ts-ignore
    $('#confirmationModal')
    .modal('show');

  }

  cloneJob(event){

    if(event){
      this.jobService.cloneJob(this.jobClone.id).subscribe(x=>{

        this.notification.success(this.translate.transform('theJobSuccessfullyCloned'));
         this.getJobListAgain.emit();
       }, error => {
        this.notification.error(this.translate.transform(error.error.code));
      });
    }

  }

  cancelJob(flag: boolean) {
    if (flag == true) {
      // this.jobService.ServerPut(server.jobs + '/' + this.selectedJob.id + '/status', { status: 'PASSIVE' }).subscribe(x => {
      //   this.pagination(1);
      //   //@ts-ignore
      //   $('#employerJobDetailModal').modal('hide');
      // });
    } else {
      //@ts-ignore
      $('#jobDetailFromEmployerList').modal('show');
    }
  }

  editJobModalShow(job?) {
    job ? this.selectedJob = job : '',

      // @ts-ignore
      $('#editJobModal').modal('toggle');
    //@ts-ignore
    //$('#employerJobDetailModal').modal('hide');
  }

  showCancelJobPopup() {
    //@ts-ignore
    //$('#employerJobDetailModal').modal('hide');
    // @ts-ignore
    $('#generalYesNoPopup').modal('show');

  }

  openReviewModal(reviews) {
    this.reviews = reviews;
    // @ts-ignore
    $('#review-popup').modal('show');
  }

  openUpdateCandidateStatus(application: ApplicationModel) {
    this.selectedApplication = application;
    // @ts-ignore
    $('#candidateUpdateStatusModal').modal('show');
    // @ts-ignore
    $('#candidateUpdateStatusModal').css('z-index', 3000);
    ;
  }

  openCandidateDetailPopup(application: ApplicationModel) {
    this.selectedApplication = application;
    // @ts-ignore
    $('#candidateDetailModal').modal('show');
  }

  updateStatus() {
    // @ts-ignore
    $('#applicantsModalLong').modal('hide');
    this.updatedStatus.emit();
  }

  openCard(job) {
    this.jobs.forEach(x => {
      x == job ? job.opened = !job.opened : x.opened = false;
      ;
    });
  }

  showDeleteJobPopup(){
        // @ts-ignore
        $('#generalDeleteModal').modal('show');
  }

  getJobListAgainEmit() {
    this.getJobListAgain.emit();
  }

  keyup(event){
    this.setFilter.emit(this.filterText);

  }

  clearSearch(){
    this.filterText = "";
    this.setFilter.emit(this.filterText);
  }

  deleteJob(flag): void {

    if (flag) {
      this.jobService.ServerDelete(server.jobs + '/' + this.selectedJob.id).subscribe(data => {
        this.notification.success(this.translate.transform("theJobSuccessfullyDeleted"));
        // @ts-ignore
        $("#jobDetailFromEmployerList").modal('hide');

        // @ts-ignore
        $('#generalDeleteModal').modal('hide');

        this.getJobListAgain.emit();
      });

    }

  }

  openEditJob(job){

    this.jobService.getGeneralInfo(job.id)
    .subscribe(x => {
      this.router.navigate(['/dashboard/postAJob/' + job.id]);
    }, error => {
      this.notification.error(this.translate.transform(error.error.code));
      this.router.navigate(['/dashboard/my-jobs']);

    });
  }

}
