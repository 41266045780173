import {FormType} from 'src/app/shared/enums';
import {TlbFormModel} from 'src/app/shared/models';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService, ResetPasswordPostService, ResetPasswordService, server, TranslatePipe} from 'src/app/shared/services';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {timer} from 'rxjs';
import {take} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

    newPassword: string = '';
    newPasswordAgain: string = '';
    email: string = '';
    code: string = '';

    hasError: boolean = true;

    formModel: TlbFormModel[] = [];
    formGroup: FormGroup;
    model: any = {};
    remainTime = 10;

    constructor(private activatedRoute: ActivatedRoute, private resetPasswordPostService: ResetPasswordPostService, private router: Router,
                private notification: NotificationService, private translate: TranslatePipe, private resetPasswordService: ResetPasswordService,
                private http: HttpClient) {
    }

    ngOnInit(): void {
        // @ts-ignore
        $('body').css('background-color', '#F5F4FE');
        // @ts-ignore
        $('body').css('background-image', 'none');
        // @ts-ignore
        $('body').css('height', '100%');
        // @ts-ignore
        $('html').css('height', '100%');

        this.activatedRoute.queryParams.subscribe(params => {

            let model = {
                email: decodeURI(params.email),
                resetCode: decodeURI(params.code)
            };

            console.log(model.email);

            const endpoint = `/?resetCode=${model.resetCode}`;
            // let endpoint = `/?email=${model.email}&resetCode=${model.resetCode}`;

            this.http.put(server.resetPassword + endpoint, {
                email: model.email
            }).subscribe(x => {
                this.email = model.email;
                this.code = model.resetCode;
                this.createForm();
                this.hasError = false;
            }, err => {
                this.hasError = true;
                timer(1000, 1000).pipe(take(this.remainTime)).subscribe(() => {
                    this.remainTime--;
                });

                setTimeout(() => {
                     this.router.navigate(['/login']);
                }, 10000);
            });

            /*            this.resetPasswordService.ServerGetWithoutLoginWithText(endpoint).subscribe(x => {
                            this.email = model.email;
                            this.code = model.resetCode;
                            this.createForm();
                            this.hasError = false;
                        }, err => {
                            this.hasError = true;
                            timer(1000, 1000).pipe(take(this.remainTime)).subscribe(() => {
                                this.remainTime--;
                            });

                            setTimeout(() => {
                                this.router.navigate(['/login']);
                            }, 10000);
                        });*/
        });
    }

    createForm() {

        this.formModel = [{
            class: 'col-12', components: [
                {
                    label: 'newPassword', control: 'newPassword', required: true, type: FormType.password,
                    showPatternDetailsOnTheBottom: true,
                    patterns: [
                        {pattern: '.*[a-z].*', errorText: 'mustALowerLetter'},
                        {pattern: '.*[A-Z].*', errorText: 'mustAUpperLetter'},
                        {pattern: '.*[0-9].*', errorText: 'mustADigit'},
                        {pattern: '.*[@$!%*.?&:].*', errorText: 'mustASpecialLetter'},
                        {pattern: '^.{6,}$', errorText: 'mustAtLeastSixLetter'},
                    ]
                },
                {
                    label: 'newPasswordAgain', control: 'newPasswordAgain', required: true, type: FormType.password,
                    showPatternDetailsOnTheBottom: false,
                    patterns: [
                        {pattern: '.*[a-z].*', errorText: 'mustALowerLetter'},
                        {pattern: '.*[A-Z].*', errorText: 'mustAUpperLetter'},
                        {pattern: '.*[0-9].*', errorText: 'mustADigit'},
                        {pattern: '.*[@$!%*.?&:].*', errorText: 'mustASpecialLetter'},
                        {pattern: '^.{6,}$', errorText: 'mustAtLeastSixLetter'},
                    ]
                },
            ]
        }];

        this.formGroup = new FormGroup({
            newPassword: new FormControl('', [
                Validators.required,
                Validators.pattern('.*[a-z].*'),
                Validators.pattern('.*[A-Z].*'),
                Validators.pattern('.*[0-9].*'),
                Validators.pattern(
                    '.*[@$!%*.?&:].*'),
                Validators.pattern('^.{6,}$')
            ]),
            newPasswordAgain: new FormControl('', [
                Validators.required,
                Validators.pattern('.*[a-z].*'),
                Validators.pattern('.*[A-Z].*'),
                Validators.pattern('.*[0-9].*'),
                Validators.pattern(
                    '.*[@$!%*.?&:].*'),
                Validators.pattern('^.{6,}$')
            ])
        });
    }

    get form() {
        return this.formGroup.controls;
    }

    resetPassword() {
        console.log(this.email);

        if (this.form.newPassword.value != this.form.newPasswordAgain.value) {
            this.notification.error(this.translate.transform('enteredPasswordsDoNtMatch'));
        } else {
            let resetModel = {
                email: this.email,
                resetCode: this.code,
                newPassword: this.form.newPassword.value
            };

            this.resetPasswordPostService.ServerPostWithoutLogin(server.resetPasswordPost, resetModel).subscribe(x => {
                this.notification.success(this.translate.transform('yourPasswordHasBeenResetSuccessfully'));
                this.router.navigate(['/login']);
            });
        }
    }


    goRegister() {
        this.router.navigateByUrl('sign-up');
    }
}
