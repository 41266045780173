import { JobModel } from './../../models/job/job.model';
import { AuthService }                                           from './../../services/auth/auth.service';
import { Component, OnInit, Renderer2 }                          from '@angular/core';
import { Router }                                                from '@angular/router';
import { CreateAvatar }                                          from '../../classes';
import { ActivitiesService, TranslatePipe, NotificationService } from '../../services';
import { ProfileEnrichmentService }                              from '../../services/user/profile-enrichment-service';

declare var $: any;

@Component({
             selector: 'app-header',
             templateUrl: './header.component.html',
             styleUrls: ['./header.component.scss']
           })
export class HeaderComponent implements OnInit {

  appLogo: string;
  tempUserImg: string;
  fullName = '';
  role = '';
  createAvatar: CreateAvatar;
  imageText: string;
  imageColor: string;
  selectedContent: any = null;
  clicked: boolean = false;

  activities: any[] = [
    // {
    //   createdAt: "2022-03-15T10:31:25.762Z",
    //   id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //   status: "ACTIVE",
    //   type: "JOB_CREATED",
    //   content: "Java Developer",
    //   contentId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //   userId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //   employerId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //   recruiterId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //   read: true
    // },
    // {
    //   createdAt: "2022-02-27T10:31:25.762Z",
    //   id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //   status: "ACTIVE",
    //   type: "APPLICATION_SUBMITTED",
    //   content: "İrem Şimşek",
    //   contentId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //   userId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //   employerId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //   recruiterId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //   read: true
    // }
  ];

  constructor(public router: Router, private authService: AuthService, private renderer: Renderer2,
              private translate: TranslatePipe,
              private activityService: ActivitiesService,
              private profileEnrichmentService: ProfileEnrichmentService,
              private notificationService: NotificationService) {
    this.createAvatar = new CreateAvatar();
    this.setEventListenerForClickOutSideOfFeedDiv();
  }

  get userFullName() {
    const parsedToken = JSON.parse(
      decodeURIComponent(escape(window.atob((this.authService.getToken()).split('.')[1]))));
    const fullNameMerge = parsedToken.first_name + ' ' + parsedToken.last_name;
    return fullNameMerge.trim();
  }

  ngOnInit(): void {

    this.role = localStorage.getItem('role');
    this.fullName = this.userFullName;
    this.imageText = this.createAvatar.create(this.fullName);
    this.imageColor = this.createAvatar.color();

  }

  setEventListenerForClickOutSideOfFeedDiv(): void {
    this.renderer.listen('window', 'click', (e: Event) => {
      let flag = false;
      // @ts-ignore
      e.path.forEach(x => {
        if (x === document.getElementById('feed-div') || e.target === document.getElementById('feed-button')) {
          flag = true;
        }
      });
      if (!flag) {
        // @ts-ignore
        $('#feed-div')
          .hide();
      }
    });
  }

  getActivities(pageNo) {
    this.activityService.ServerGet()
        .subscribe(x => {
          this.activities = x.content;
          this.activities.forEach(f => {
            f.imageText = this.createAvatar.create(this.translate.transform(f.type));
            f.imageColor = this.createAvatar.color();
            let time = Math.abs(new Date().getTime() - new Date(f.createdAt).getTime());
            f.postDate = Math.ceil(time / (1000 * 3600 * 24));
          });

        });
  }

  public toggleSidebar(): void {
    document.getElementById('toggle_sidebar_button')
            .click();
  }

  logOut() {

    this.authService.ServerLogout();
  }

  openMessagesPopup() {
    // @ts-ignore
    $('#messagesForm')
      .modal('show');
  }

  showSearchPopup() {

    // @ts-ignore
    $('#searchModal')
      .modal('show');

  }

  changeStateOfFeed(): void {
    // @ts-ignore
    if ($('#feed-div')
      .is(':hidden')) {
      // @ts-ignore
      $('#feed-div')
        .show();
    } else {
      // @ts-ignore
      $('#feed-div')
        .hide();
    }

  }

  openMenu() {
    $('#mySidenav')
      .width('220px');
  }

  goToPostAJob(): void {
    this.profileEnrichmentService.canEmployerPublishAJob()
        .subscribe(x => {
          if (x) {
            this.router.navigate(['/dashboard/postAJob/null']);
          } else {
            this.notificationService.warning(this.translate.transform('youShouldCompleteProfileBeforeJobPosting'));
            setTimeout(() => {
              this.router.navigate(['/dashboard/complete-employer-register/null']);
            }, 1);
          }
        });

  }

  selectedJob(job){
    this.selectedContent = job;
    this.clicked = true;
          // @ts-ignore
      $('#jobDetailFromNotificationHeader').modal('show');
  }

  OpenModalViewSalary(job: JobModel) {
    this.selectedContent = job;
    // @ts-ignore
    $('#viewSalaryModal').modal('show');
    // @ts-ignore
    $('#jobDetailFromNotification').modal('hide');
  }

  submitCandidate(job: JobModel) {
    this.selectedContent = job;

    // @ts-ignore
    $('#submitCandidateModal').modal('show');
  }


}
