import { Injectable }          from '@angular/core';
import { ApiService }          from '../../auth';
import { server }              from '../../server';
import { HttpClient }          from '@angular/common/http';
import { NgxSpinnerService }   from 'ngx-spinner';
import { TranslatePipe }       from '../../../pipes/translate/translate.pipe';
import { NotificationService } from '../../notification';

@Injectable({
  providedIn: 'root'
})
export class PackageFunctionalRoleService extends ApiService {

  constructor(public http: HttpClient, public spinner: NgxSpinnerService, public translate: TranslatePipe, public notification: NotificationService) {
    super(http, server.packageFunctionalRoles, spinner, translate, notification);
  }
}
