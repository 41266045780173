import { Component, OnInit } from '@angular/core';
import { RecruiterUserModel } from 'src/app/shared/models/recruiter/recruiter-user.model';
import { RecruiterService, ReviewService, TranslatePipe } from 'src/app/shared/services';
import { CreateAvatar, RequestPayload } from '../../../shared/classes';

@Component({
  selector: 'app-admin-all-recruiters',
  templateUrl: './admin-all-recruiters.component.html',
  styleUrls: ['./admin-all-recruiters.component.scss']
})
export class AdminAllRecruitersComponent implements OnInit {

  recruiters: RecruiterUserModel[] = [];
  selectedRecruiter: any;
  requestPayload: RequestPayload;
  currentPage: number;
  count: number;
  filterData: any = {};
  storedItems: any[] = [];
  createAvatar: CreateAvatar;
  filterText: string = "";
  fromSearch: boolean = false;

  constructor(private recruiterService: RecruiterService, private translate: TranslatePipe, private reviewService: ReviewService) {
    this.filterData.individualPracticeTime = "";
    this.filterData.requisitionType = "";
    this.filterData.functionalRoleId = "";
    this.createAvatar = new CreateAvatar();
    this.filterData.industryId = "";
    this.requestPayload = new RequestPayload();
    this.requestPayload.limit = 5;
  }

  ngOnInit(): void {

    this.getRecruiters(1);
  }

  getRecruiters(pageNo) {

    this.recruiterService.ServerGetWithText(this.requestPayload.payloadURL(pageNo) + this.requestFilter())
      .subscribe(x => {
        this.fromSearch ? this.currentPage = 1 : null;
        this.fromSearch = false;
        this.recruiters = x.recruiterPagination.content;
        // this.storedItems = this.recruiters;
        this.count = x.recruiterPagination.totalElements;
        this.recruiters.forEach(f => {
          f.imageText = this.createAvatar.create(f.user.firstName + ' ' + f.user?.lastName);
          f.imageColor = this.createAvatar.color();
          this.reviewService.ServerGetWithText('/?recruiterId=' + f.id).subscribe(x => {
            f.reviews = x.content;
            f.reviews.forEach(x => {
              x.date = new Date(x.createdAt).toLocaleDateString();
            });
            const sum = x.content.reduce((a, b) => a + b.rating, 0);
            const avg = (sum / x.content.length) || 0;
            f.date = new Date(f.createdAt).toLocaleDateString();
            f.reviewCount = x.totalElements;
            f.star = avg;
            f.functionalRolesText = f.functionalRoles.map(m => ' ' + m.name).toString();
            f.industriesText = f.industries.map(m => ' ' + m.name).toString();
            f.requisitionTypesText = f.requisitionTypes.map(m => ' ' + this.translate.transform(m + 'REC')).toString();

          });
        });
      });

  }

  requestFilter() {

    return '&filter=individualPracticeTime**' + this.filterData.individualPracticeTime +
      '&filter=requisitionTypes.requisitionType**' + this.filterData.requisitionType +
      '&filter=functionalRoles.functionalRoleId**' + this.filterData.functionalRoleId +
      '&filter=industries.industryId**' + this.filterData.industryId +
      '&filter=name~~' + this.filterText
  }

  openRecruiterDetailPopup(recruiter) {
    this.selectedRecruiter = recruiter;
    // @ts-ignore
    $('#adminRecruiterDetailModalFromAllRecruiters').modal('show');

  }

  getFilteredRec(e) {

    this.filterData = e;
    this.getRecruiters(1);

  }
  clearFilter(e) {
    this.filterData = e;
    this.getRecruiters(1);
  }

  search(data) {
    this.filterText = data;
    this.fromSearch = true;
    this.getRecruiters(1);

  }

}
