import { PhoneNumberModel } from "../parameter";

export class EmployerContactPersonModel {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: PhoneNumberModel;

  constructor() {
    this.firstName = '';
    this.lastName = '';
    this.phoneNumber = new PhoneNumberModel();
    this.email = '';
  }
}
