<!-- Team Members Modal -->
<div #paymentModal
     class="modal fade"
     id="paymentModal">
  <div class="modal-dialog">
    <div class="modal-content p-2">
      <!--      <div class="modal-header m-2 border-0">
              <button type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close">
                <i class="fas fa-times"
                   style="font-size: 14px;color: #4F4F4F;"></i>
              </button>
            </div>-->
      <div class="modal-body container">
        <div class="container">
          <div class="row">
            <div class="col-12 p-0">
              <h1 class="bold-16">{{'paymentMethod' | translate}}</h1>
            </div>
          </div>
          <div class="row">
            <div class="col-12 border payment-option py-2 mt-3" [ngClass]="paymentMethod.paypal.bgColor==='blue' ?'blue-bg':''" >
              <div class="radio-div">
                <input type="radio"
                       name="payment-method"
                       id="paypal-method"
                       value="paypal"
                       (change)="changePaymentMethod('paypal')">
                <label for="paypal-method"
                       class="label-payment">{{'paypal' | translate}}</label>
              </div>
              <span class="paypal-text-span">{{'youWillBeRedirectedToThePaypalWebsiteAfterSubmittingYourOrder' | translate}}</span>
              <span class="logo-span paypal-logo">
                <img src="assets/images/icons/paypal.png"
                     alt="">
              </span>
            </div>
            <div class="col-12 border py-3 mt-3" [ngClass]="paymentMethod.creditCard.bgColor==='blue' ?'blue-bg':''">
              <div class="row">
                <div class="col-12 payment-option">
                  <div class="radio-div">
                    <input type="radio"
                           name="payment-method"
                           id="credit-method"
                           value="credit-card" checked (change)="changePaymentMethod('creditCard')">
                    <label for="credit-method"
                           class="label-payment">{{'payWithCreditCard' | translate}}</label>
                  </div>
                  <div>
                <span class="mx-1">
                <img src="assets/images/icons/Visa.png"
                     alt="">
              </span>
                    <span class="mx-1">
                <img src="assets/images/icons/Discover.png"
                     alt="">
              </span>
                    <span class="mx-1">
                <img src="assets/images/icons/Maestro.png"
                     alt="">
              </span>
                    <span class="ml-1">
                <img src="assets/images/icons/Mastercard.png"
                     alt="">
              </span>
                  </div>
                </div>
                <div class="col-12 mt-3">
                  <div class="row">
                    <div class="col-6">
                      <label for="card-number"
                             [ngClass]="paymentMethod.creditCard.bgColor==='blue' ?'blue-bg':''"
                             class="card-input">{{'cardNumber' | translate}}</label>
                      <input type="text"
                             name="card-number"
                             class="card-input"
                             id="card-number"
                             type="tel"
                             inputmode="numeric"
                             pattern="[0-9\s]{13,19}"
                             autocomplete="cc-number"
                             maxlength="19"
                             placeholder="xxxx xxxx xxxx xxxx"
                             [ngClass]="paymentMethod.creditCard.bgColor==='blue' ?'blue-bg':''"
                             [(ngModel)]="creditCard.number"
                             (keyup)="onCardNumberKeyUp($event)">
                    </div>
                    <div class="col-6">
                      <label for="expiration-date"
                             [ngClass]="paymentMethod.creditCard.bgColor==='blue' ?'blue-bg':''"
                             class="card-input">{{'expirationDate' | translate}}</label>
                      <input type="text"
                             name="card-number"
                             class="card-input"
                             id="expiration-date"
                             maxlength="5"
                             placeholder="MM/YY"
                             [(ngModel)]="creditCard.expirationDate"
                             [ngClass]="paymentMethod.creditCard.bgColor==='blue' ?'blue-bg':''"
                             (keyup)="onCardExpirationDateKeyUp($event)">
                    </div>
                    <div class="col-6 mt-2">
                      <label for="security-code"
                             [ngClass]="paymentMethod.creditCard.bgColor==='blue' ?'blue-bg':''"
                             class="card-input">{{'cardSecurityCode' | translate}}</label>
                      <input type="text"
                             name="security-code"
                             class="card-input"
                             id="security-code"
                             maxlength="3"
                             placeholder="***"
                             [ngClass]="paymentMethod.creditCard.bgColor==='blue' ?'blue-bg':''"
                             [(ngModel)]="creditCard.securityCode"
                             (keyup)="onCardSecurityCodeKeyUp($event)">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 p-0 grand-total">
              <h2 class="bold-16">{{'grandTotal' | translate}}</h2>
              <h2 class="bold-16">{{'439.00'  |currency}}</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-12 p-0 privacy-div">
              <input type="checkbox"
                     name="privacy"
                     id="privacy"> <label for="privacy" class="privacy-label">{{'pleaseCheckToAcknowledgeOur' | translate}} <span class="color-highlight">{{'privacyTermsPolicy' | translate}}</span></label>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 p-0 d-flex justify-content-end">
              <button class="btn btn-primary">
                {{'pay' | translate}} {{'439.00'  |currency}}
              </button>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>


</div>
