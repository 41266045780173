<div *ngIf="filterData" class=" mt-3 box">
  <div class="col-12 pr-3 pl-3 mt-2 mb-2">

      <span class="filters-text">{{'filters' | translate}}</span>

      <section class="mt-4">
        <div class="form-check mb-2" >
          <input type="radio" (change)="selectRB('')" class="form-check-input filled-in" name="one" id="filledInCheckbox" value="0" checked>
          <label class="form-check-label cb-text" for="filledInCheckbox">{{ 'allJobs' | translate }}</label>
        </div>

        <div class="form-check mb-2" >
          <input type="radio" (change)="selectRB('ACTIVE')" class="form-check-input filled-in" name="one" id="filledInCheckboxOne" value="1" >
          <label class="form-check-label cb-text" for="filledInCheckboxOne">{{ 'activeJobs' | translate }}</label>
        </div>

        <div class="form-check mb-2" >
          <input type="radio" (change)="selectRB('DRAFT')" class="form-check-input filled-in" name="one" id="filledInCheckboxTwo" value="2">
          <label class="form-check-label cb-text" for="filledInCheckboxTwo">{{ 'draftJobs' | translate }}</label>
        </div>

        <div class="form-check mb-4" >
          <input type="radio" (change)="selectRB('CLOSED')" class="form-check-input filled-in" name="one" id="filledInCheckboxThree" value="3">
          <label class="form-check-label cb-text" for="filledInCheckboxThree">{{ 'closedJobs' | translate }}</label>
        </div>
      </section>

    <div>
      <tlb-dropdown [items]="countryOptions" [dropdownName]="countryLabel != '' ? countryLabel : 'country'"
        (searchData)="selectCountry($event)" [style]="'background-color: white; width: 95%;'">
      </tlb-dropdown>
    </div>

    <div class="mt-3">
      <tlb-multiselect-dropdown [alertText]="alertText" (clicked)="clickedCity()" (selectResult)="selectCity($event)" [isFilter]="false" [items]="cities"
        [dropdownName]="cityLabel != '' ? cityLabel : 'city'" [existItems]="selectedCities" [style]="'background-color: white; width: 95%;'">
      </tlb-multiselect-dropdown>

    </div>

    <div class="mt-3">
      <tlb-multiselect-dropdown (selectResult)="selectType($event)" [isFilter]="false" [items]="jobTypeOptions"
        [dropdownName]="jobTypeLabel != '' ? jobTypeLabel : 'jobType'" [defaultItemKey]="''"
        [existItems]="selectedJobTypes" [style]="'background-color: white; width: 95%;'">
      </tlb-multiselect-dropdown>
    </div>

    <div class="mt-3">

      <tlb-multiselect-dropdown (selectResult)="selectFunction($event)" [isFilter]="false" [items]="jobFunctions"
        [dropdownName]="jobFunctionLabel != '' ? jobFunctionLabel : 'jobFunction'" [existItems]="selectedFunctions"
        [dropDownMenuWidth]="'17rem'" [style]="'background-color: white; width: 95%;'">
      </tlb-multiselect-dropdown>
    </div>


    <div class="mt-3">
      <tlb-multiselect-dropdown (selectResult)="selectIndustry($event)" [isFilter]="false" [items]="industries"
        [dropdownName]="industryLabel != '' ? industryLabel : 'industry'" [existItems]="selectedIndustries"
        [dropDownMenuWidth]="'15rem'" [style]="'background-color: white; width: 95%;'">
      </tlb-multiselect-dropdown>
    </div>


    <div class="btn-group-row mt-3 box-left" >

      <div class="mr-1 ">
        <button (click)="clearFilterEmit()" class="btn clear-filter-btn" type="button">{{'discard' |
          translate}}</button>
      </div>
      <div class="">
        <button (click)="emitData()" class="btn apply-filter-btn" type="button">{{'apply' |
          translate}}</button>
      </div>
    </div>

  </div>
</div>
