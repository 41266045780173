import { Component, OnInit } from '@angular/core';
import { CreateAvatar } from 'src/app/shared/classes';
import { DropdownOptionsModel } from 'src/app/shared/models';
import {RecruiterService, TranslatePipe} from 'src/app/shared/services';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import {Content} from './recruiter-top.interface';

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss']
})
export class LeaderboardComponent implements OnInit {

  recruiters: Content[] = [];
  sortedArray: any[] = [];
  filterData: any = {};
  sortLabelRating: string = this.translate.transform('ratings');
  sortLabelPlacement: string = this.translate.transform('placements');
  sortLabelScore: string = this.translate.transform('scores');
  name: string ="";
  sortText: string = "";
  sortOptions1: DropdownOptionsModel[] = [
    { label: this.translate.transform('high'), value: 'desc' },
    { label: this.translate.transform('low'), value: 'asc' }
  ];
  createAvatar: CreateAvatar;

  constructor(private translate: TranslatePipe, private recruiterService: RecruiterService, private authService: AuthService) {
    this.createAvatar = new CreateAvatar();
   }

   ngOnInit() {
    const parsedToken = JSON.parse(decodeURIComponent(escape(window.atob((this.authService.getToken()).split('.')[1]))));
    this.name = parsedToken.first_name;
    this.getRecruiters();
  }

  selectSort(data, type) {
      switch (type) {
        case 'rating':
          this.sortText = "&sort=rating," + data.value;
          this.sortLabelRating = data.label + ' (' +  this.translate.transform('ratingShort') + ')';
          this.sortLabelPlacement = "placements";
          this.sortLabelScore = "scores";

          break;
        case 'placement':
          this.sortText = "&sort=placement," + data.value;
          this.sortLabelPlacement = data.label + ' (' +  this.translate.transform('placementsShort') + ')';
          this.sortLabelRating = "ratings";
          this.sortLabelScore = "scores";

          break;
        case 'score':
          this.sortText = "&sort=score," + data.value;
          this.sortLabelScore = data.label + ' (' +  this.translate.transform('scoreShort') + ')';
          this.sortLabelRating = "ratings";
          this.sortLabelPlacement = "placements";

          break;

      }

    this.getRecruiters();
  }

  getRecruiters() {
    this.recruiterService.ServerGetWithText('/top-recruiters?page=0&size=10&lastWeek=false' + this.sortText).subscribe(
      (res: any) => {
        this.recruiters = res.content;
        let max = this.recruiters.reduce((op, item) => op = op > item.placements ? op : item.placements, 0);
        this.recruiters.forEach(x=>{
          x.placementValue = (100 * x.placements) / max;
          x.imageText = this.createAvatar.create(x.recruiter.user.fullName);
          x.imageColor = this.createAvatar.color();
        });

        this.sortedArray = [...this.recruiters];
        this.sortedArray.sort((n1,n2) => {
          if (n1.delta < n2.delta) {
              return 1;
          }

          if (n1.delta > n2.delta) {
              return -1;
          }

          return 0;
      });


        });
  }

}
