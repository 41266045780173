<div class="modal fade" id="modalNews" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div *ngIf="this.new && this.new.id" class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class=" mt-2 pl-4">
        <h6 class="modal-title font-weight-bold " id="exampleModalLabel">{{this.new.title}}</h6>
        <div class="text-muted font-weight-light">{{this.new.date}}</div>
      </div>

      <div class="modal-body">
        <div class="container"[innerHTML]="this.new.content">
          <!-- <p>
            {{this.new.content}}
          </p> -->
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" (click)="closeNewsModal()" class="btn btnNews">{{ 'close' | translate }}</button>
      </div>
    </div>
  </div>
</div>
