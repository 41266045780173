<div class="container px-xl-5 px-2 mt-xl-4 mt-md-4 mt-2 pb-3">
</div>
<div class="container px-xl-5 px-2 mt-xl-4 mt-md-4 mt-2">

  <div class="pb-4">
    <div class="card">
      <div class="card-body">
        <div *ngIf="emailPreferences" class="container px-xl-5 px-2">
          <div class="row">
            <div class="col-12">
              <p><b>{{ 'emailPreferences' | translate }}</b></p>
            </div>
            <div class="col-12">
              <p>{{ 'pleaseSelectTypes' | translate }}:</p>
            </div>
          </div>
          <section style="padding: 10px" class="border py-3">
            <div class="form-check mb-2">
              <input [(ngModel)]="emailPreferences.applicationStatusChanged" (change)="isEdited=true" type="checkbox" class="form-check-input filled-in" id="filledInCheckbox">
              <label class="form-check-label " for="filledInCheckbox">{{ 'emailWhenApplicationStatus' | translate }}</label>
            </div>
          </section>
          <br>
          <section style="padding: 10px" class="border py-3">
            <div class="form-check mb-2">
              <input [(ngModel)]="emailPreferences.newJobCreated" (change)="isEdited=true" type="checkbox" class="form-check-input filled-in" id="filledInCheckboxOne">
              <label class="form-check-label " for="filledInCheckboxOne">{{ 'emailWhenNewFullTime' | translate }}</label>
            </div>
          </section>
          <br>
          <section style="padding: 10px" class="border py-3">
            <div class="form-check mb-2">
              <input [(ngModel)]="emailPreferences.webinarsAndIntakeCalls" (change)="isEdited=true" type="checkbox" class="form-check-input filled-in" id="filledInCheckboxTwo">
              <label class="form-check-label " for="filledInCheckboxTwo">{{ 'InviteToJobRelatedWebinarsIntake' | translate }}</label>
            </div>
          </section>
          <br>
          <section style="padding: 10px" class="border py-3">
            <div class="form-check mb-2">
              <input [(ngModel)]="emailPreferences.recruitingTipsEtc" (change)="isEdited=true" type="checkbox" class="form-check-input filled-in" id="filledInCheckboxThree">
              <label class="form-check-label " for="filledInCheckboxThree">{{ 'RecrutingTipsSuccessStories' | translate }}</label>
            </div>
          </section>

          <div class="row pt-5 pb-1">
            <div class="col-xl-2 col-md-2 col-12 ml-auto"><button class="form-control save" [disabled]="!isEdited" [style]="isEdited==false?'background: gray;':''" (click)="update()"><b>{{ 'save' | translate }}</b></button></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
  </div>
</div>
