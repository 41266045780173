<div class="card mt-3" style="border: none">
  <div class="card-body">
    <div class="container-fluid p-0">
      <div class="header pl-3">{{headers[step-1] | translate}}</div>

      <div class="container-fluid text-center my-3">

        <div class="row">
          <div class="col-12  text-center row">
            <div style="width: 16.66%;">
              <div class="row">
                <div style="text-align: center ;color: #222221; width: 10%;
                " class="dot" [ngClass]="step > 1 ? 'green' : step == 1 ? 'blue' : 'white'"> <i *ngIf="step >= 1"
                    class="fas fa-check mt-1"></i> </div>
                <div class="line" style="width: 90%; margin-left: 48% !important;"
                  [ngClass]="step >= 1 ? 'green-hr' : 'white-hr'"></div>
              </div>
              <br><span class="text-height">{{'generalInfo' | translate}}</span>

            </div>
            <div style="width: 16.66%;">
              <div class="row">
                <div style="text-align: center;color: #222221; width: 18%;
              " class="dot" [ngClass]="step > 2 ? 'green' : step == 2 ? 'blue' : 'white'"> <i *ngIf="step >= 2"
                    class="fas fa-check mt-1"></i></div>
                <div class="line" style="width: 90%;" [ngClass]="step >= 2 ? 'green-hr' : 'white-hr'"></div>
              </div>
              <br>
              <span class="text-height">{{'skillsExperience' | translate}}</span>

            </div>
            <div style="width: 16.66%;">
              <div class="row">
                <div style="text-align: center;color: #222221; width: 18%;
                " class="dot" [ngClass]="step > 3 ? 'green' : step == 3 ? 'blue' : 'white'"><i *ngIf="step > 3"
                    class="fas fa-check mt-1"></i></div>
                <div class="line" style="width: 90%;" [ngClass]="step >= 3 ? 'green-hr' : 'white-hr'"></div>
              </div>
              <br> <span class="text-height">{{'location' | translate}}</span>

            </div>
            <div style="width: 16.66%;">
              <div class="row">
                <div style="text-align: center;color: #222221; width: 18%;
              " class="dot" [ngClass]="step > 4 ? 'green' : step == 4 ? 'blue' : 'white'"><i *ngIf="step > 4"
                    class="fas fa-check mt-1"></i></div>
                <div class="line" style="width: 75%;" [ngClass]="step >= 4 ? 'green-hr' : 'white-hr'"></div>
              </div>
              <br> <span class="text-height"> {{'preScreenQuestions' | translate}} </span>

            </div>
            <div style="width: 16.66%;">
              <div class="row">
                <div style="text-align: center;color: #222221; width: 18%;
              " class="dot" [ngClass]="step > 5 ? 'green' : step == 5 ? 'blue' : 'white'"><i *ngIf="step > 5"
                    class="fas fa-check mt-1"></i></div>
                <div class="line" style="width: 75%;" [ngClass]="step >= 5 ? 'green-hr' : 'white-hr'"></div>
              </div>
              <br><span class="text-height">{{'compensationFee' | translate}}</span>
            </div>
            <div style="width: 16.66%;">
              <div class="row">
                <div style="text-align: center;color: #222221;
              " class="dot" [ngClass]="step > 6 ? 'green' : step == 6 ? 'blue' : 'white'"><i *ngIf="step > 6"
                    class="fas fa-check mt-1"></i></div>
                <div class="line" style="width: 34% !important;" [ngClass]="step >= 6 ? 'green-hr' : 'white-hr'"></div>
              </div>
              <br><span class="text-height">{{'notification' | translate}}</span>
            </div>

          </div>
        </div>

      </div>
      <div>
        <div>
          <div class="container-fluid">
            <div>
              <form>
                <div *ngIf="step==1">

                  <tlb-form *ngIf="formGeneralInfo && formGeneralInfo.length > 0 && formGroupGeneralInfo"
                    [forms]="formGeneralInfo" [formGroup]="formGroupGeneralInfo"></tlb-form>

                  <div class="col-12 mx-auto mx-md-0 px-0" style=" display: flex;
                    justify-content: space-between;">
                    <button class="btn btn-next border"
                      [disabled]="(formGroupGeneralInfo && formGroupGeneralInfo.invalid) || jobService.loading"
                      (click)="saveGeneralInfos()">{{'nextStep' | translate}} →</button>
                  </div>

                </div>
                <div *ngIf="step==2">

                  <tlb-form *ngIf="formExperience && formExperience.length > 0 && formGroupExperience"
                    [forms]="formExperience" [formGroup]="formGroupExperience"></tlb-form>

                  <div class="col-12 mx-auto mx-md-0 px-0" style=" display: flex;
                    justify-content: space-between;">
                    <button class="btn btn-back mr-3" (click)="pre()">← {{'previousStep' | translate}}</button>
                    <button class="btn btn-next border" (click)="saveExperience()"
                      [disabled]="(formGroupExperience && formGroupExperience.invalid) || jobService.loading">{{'nextStep' | translate}}
                      →</button>
                  </div>

                </div>
                <div *ngIf="step==3">

                  <tlb-form *ngIf="formLocation && formLocation.length > 0 && formGroupLocation" [forms]="formLocation"
                    [formGroup]="formGroupLocation"></tlb-form>


                  <div class="col-12 mx-auto mx-md-0 px-0" style=" display: flex;
                    justify-content: space-between;">
                    <button class="btn btn-back mr-3" (click)="pre()">← {{'previousStep' | translate}}</button>
                    <button class="btn btn-next border" (click)="saveLocations()"
                      [disabled]="(formGroupLocation && formGroupLocation.invalid) || jobService.loading">{{'nextStep' | translate}} →</button>
                  </div>

                </div>

                <div *ngIf="step==4">

                  <div class="form-group">
                    <label class="form-label-small">{{'preScreenQuestion' |
                      translate}}</label>
                    <div *ngFor="let question of questionsArray">
                      <button *ngIf="questionsArray.length != 1" class="search-button" (click)="deleteQuestion(question.index)" type="submit"><i
                          class="fas fa-times search-icon"></i></button>
                      <textarea [(ngModel)]="questions[question.index]" class="form-control form-area search-input mt-2"
                        rows="3" style="height: 100%; width: 96%;" [ngModelOptions]="{standalone: true}"
                        id="{{question.id}}" placeholder="{{'enterQuestion' | translate}}"></textarea>
                    </div>

                  </div>

                  <button id="button-son" type="button" class="btn btn-outline btn-md mt-2 "
                    style="border: 1px solid #3652E3;" (click)="addQuestion()">
                    <span id="yaz" class="btn-label"><i style="padding: 10px"
                        class="fas fa-plus"></i></span>{{'addNewQuestion' |
                    translate}}</button>


                  <div class="col-12 mx-auto mx-md-0 px-0 mt-3" style=" display: flex;
                    justify-content: space-between;">
                    <button class="btn btn-back mr-3" (click)="pre()">← {{'previousStep' | translate}}</button>
                    <button class="btn btn-next border" [disabled]="jobService.loading" (click)="saveQuestions()">{{'nextStep' | translate}} →</button>
                  </div>

                </div>

                <div *ngIf="step==5">

                  <tlb-form *ngIf="formCompensation && formCompensation.length > 0 && formGroupCompensation"
                    [forms]="formCompensation" [formGroup]="formGroupCompensation"></tlb-form>


                  <div class="col-12 mx-auto mx-md-0 px-0" style=" display: flex;
                    justify-content: space-between;">
                    <button class="btn btn-back mr-3" (click)="pre()">← {{'previousStep' | translate}}</button>
                    <button class="btn btn-next border" (click)="saveCompensationAndFees()"
                      [disabled]="(formGroupCompensation && formGroupCompensation.invalid) || jobService.loading">{{'nextStep' | translate}}
                      →</button>
                  </div>

                </div>

                <div *ngIf="step==6">

                  <tlb-form *ngIf="formNotification && formNotification.length > 0 && formGroupNotification"
                    [forms]="formNotification" [formGroup]="formGroupNotification"></tlb-form>
                  <div class="col-12 mx-auto mx-md-0 px-0" style=" display: flex;
                    justify-content: space-between;">
                    <button class="btn btn-back mr-3" (click)="pre()" *ngIf="step>1">← {{'previousStep' |
                      translate}}</button>
                    <button class="btn btn-next border" (click)="saveNotification()"
                      [disabled]="(formGroupNotification && formGroupNotification.invalid) || jobService.loading">{{'nextStep' | translate}}
                      →</button>
                  </div>

                </div>

              </form>
            </div>
          </div>
        </div>

      </div>
    </div>


    <!-- Team Members Modal -->
    <div #myModal class="modal fade" id="teamMemberModal">
      <div class="modal-dialog ">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="background: #E5E5E5;
        border-radius: 100px; width: 24px; height: 24px; font-family: 'Roboto';">
              <span aria-hidden="true" style="color: #4F4F4F;"><i class="fas fa-times"
                  style="font-size: 17px;"></i></span>
            </button>
          </div>
          <div class="modal-body container" style="max-width: 600px;">

            <div style="text-align: center; ">

              <img src="../../../../../assets/images/team-member.png" alt="">
              <div class="modal-header-text">{{ 'jobSuccessfullyCreated' | translate }}</div>
              <div class="modal-sub-header mt-2">{{ 'youCreatedJobSuccessfully' | translate }}</div>
              <div class="modal-sub-header">{{ 'pleaseSelectATeamMemberInCharge' | translate }}</div>

            </div>

            <tlb-form *ngIf="formTeamMember && formTeamMember.length > 0 && formGroupTeamMember"
              [forms]="formTeamMember" [formGroup]="formGroupTeamMember"></tlb-form>

            <div class="row " style="justify-content: center; margin-top: -60px; margin-bottom: 25px;">
              <div class="col-6">
                <button style="width: 90%;" (click)="saveTeamMembers(false)" class="btn btn-primary ml-3" data-dismiss="modal">{{'save' |
                  translate}} </button>
              </div>
              <div class="col-6">

                <button style="width: 90%;" (click)="saveTeamMembers(true)" class="btn btn-success ml-2" data-dismiss="modal">{{'publish' |
                  translate}}</button>
              </div>

            </div>

          </div>

        </div>
      </div>


    </div>



    <!-- Job Accessibility Modal -->
    <div #myModal class="modal fade" id="jobAccessibilityModal">
      <div class="modal-dialog ">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="background: #E5E5E5;
            border-radius: 100px; width: 24px; height: 24px; font-family: 'Roboto';">
              <span aria-hidden="true" style="color: #4F4F4F;"><i class="fas fa-times"
                  style="font-size: 17px;"></i></span>
            </button>
          </div>
          <div class="modal-body container" style="max-width: 600px;">

            <div style="text-align: center; ">

              <img src="../../../../../assets/images/team-member.png" alt="">
              <div class="modal-header-text">{{ 'jobSuccessfullyCreated' | translate }}</div>
              <div class="modal-sub-header mt-2">{{ 'youCreatedJobSuccessfully' | translate }}</div>
              <div class="modal-sub-header">{{ 'pleaseSelectJobAccessibility' | translate }}</div>



            </div>

            <div *ngIf="jobAccessibility == 'INVITATION_ONLY' || jobAccessibility == 'RULE_BASED'" style=" padding: 15px 15px 15px 15px;">
              <div class="warning-box mt-3">
                <div class="row">
                  <div class="col-1">
                    <i class="fas fa-users" style="color: #6361D9;"></i>
                  </div>

                  <div class="col-11 warn-text">
                    <span> {{'theEmployersWillBeAbleToPublishTheJobPublicly' | translate}} </span> <br>
                    <span> {{'orToASpecificGroupOfRecruiters' | translate}} </span> <span style="color: #6361D9;">
                      {{'settings' | translate}} </span>
                  </div>
                </div>
              </div>
            </div>


            <div class="form-group mt-3 " style=" padding: 15px 15px 8px 15px;">
              <label for="exampleInputEmail1" class="form-label"
                style="font-family: Roboto; font-style: normal; font-weight: 500; font-size: 14px; line-height: 150%; text-transform: capitalize; color: #707683;">
                {{ 'jobAccessibility' | translate }}
              </label>
              <tlb-dropdown [items]="jobAccessibilityOptions" [dropdownName]="jobAccessibilityLabel"
                [style]="'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px;'"
                [textStyle]="'font-style: normal; font-weight: 500; font-size: 14px; color: #323332; margin-top: 5px;'"
                [isFilter]="false" (searchData)="changeJobAccessibility($event)">
              </tlb-dropdown>
            </div>

            <div *ngIf="jobAccessibility == 'INVITATION_ONLY'" class="form-group" style=" padding: 0px 15px 15px 15px;">

              <button class="btn btn-primary" (click)="showRecruiterList()">{{'choose' | translate}}</button>
              <div class="container-recruiter-list-invited my-3" *ngIf="selectedRecruitersForInvitation.length > 0">
                <span class="title">{{'invitedRecruiters' | translate}}</span>
                <div class="d-flex-row-center" style="flex-wrap: wrap;">
                  <div class="d-flex-row-center invited-recruiters" *ngFor="let recruiter of selectedRecruitersForInvitation">
                    <div [style.background-color]="recruiter.imageColor"
                         class="preimage mx-2 w-24"><span style="color: white;font-size: 0.6rem;    line-height: 24px;">{{recruiter.imageText}}</span></div>
                    {{recruiter.user.fullName}}
                    <span class="remove-invited-recruiter-span" (click)="removeFromInvitedRecruiters(recruiter)">x</span>
                  </div>

                </div>
              </div>
           <!--   <label for="exampleInputEmail1" class="form-label"
                style="font-family: Roboto; font-style: normal; font-weight: 500; font-size: 14px; line-height: 150%; text-transform: capitalize; color: #707683;">
                {{ 'recruiterEMail' | translate }}
              </label>

              <div class="dropdown drp ">
                <input [(ngModel)]="recText" aria-expanded="false" aria-haspopup="true" class="form-group dropdown-toggles position-relative" data-target="dropdown"
                  type="text"  #searchInput (keyup)="getRecruiters(searchInput.value)"
                  style="background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px; width: 100%; margin-bottom: -1px;"/>
                <div class="dropdown-menu col-12 pb-0" id="dropdown-menu">
                  <div class="col-12 drp-menu scrollable-menu p-0 ">
                    <div *ngFor="let item of recruitersOptions">
                      <label (click)="selectRecruiter($event, item)"
                      class="dropdown-item m-0 cursor-pointer scrollable-menu">
                      <span class="ml-2">{{item.label | translate}}</span>
                    </label>
                    </div>

                  </div>
                </div>
              </div>
-->

              <!-- <input [(ngModel)]="recruiterMail" type="text" class="form-control" autocomplete="off"
                style="background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px; height: 45px;"
                placeholder="{{'enterEMailAddress' | translate}}" (keyup.enter)="addMail()"/> -->
            </div>


            <div *ngIf="jobAccessibility == 'INVITATION_ONLY' && recruiterMailArray && recruiterMailArray.length > 0" class="form-group" style="padding: 0px 15px 15px 15px;">
              <label for="exampleInputEmail1" class="form-label"
                style="font-family: Roboto; font-style: normal; font-weight: bold; font-size: 14px; line-height: 150%; text-transform: capitalize; color: #2B3A4B;">
                {{ 'invitedRecruiters' | translate }}
              </label>

              <br>
              <span class="mail-badge ml-2" *ngFor="let mail of recruiterMailArray">
                <span class="badge-text"> {{mail.user.fullName}} <i class="fas fa-times ml-1" style="color: #707683;" (click)="deleteMail(mail)"></i> </span>
              </span>

            </div>
            <div *ngIf="jobAccessibility == 'pricing'" class="form-group" style="padding: 0px 15px 15px 15px;">
              <label  class="form-label"
                     style="font-family: Roboto; font-style: normal; font-weight: 500; font-size: 14px; line-height: 150%; text-transform: capitalize; color: #707683;">
                {{'upgradeYourPackage'| translate}}
              </label>
            <!--  <span class="mail-badge ml-2">
                <span class="badge-text">{{'upgradeYourPackage'| translate}} </span><br>

              </span>-->
              <button style="width: 94%;" routerLink="/dashboard/pricing" data-dismiss="modal" class="btn btn-primary ml-3">{{'upgrade' |
                translate}}</button>
            </div>


            <div *ngIf="jobAccessibility == 'RULE_BASED'">

              <div class="form-group mt-2" style="padding: 0px 15px 0px 15px;">
                <label for="exampleInputEmail1" class="form-label"
                  style="font-family: Roboto; font-style: normal; font-weight: 500; font-size: 14px; line-height: 150%; text-transform: capitalize; color: #707683;">
                  {{ 'rules' | translate }}
                </label>
                <tlb-dropdown [items]="ruleBasedOptions" [dropdownName]="ruleLabel"
                  [style]="'background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px;'"
                  [textStyle]="'font-style: normal; font-weight: 500; font-size: 14px; color: #323332; margin-top: 5px;'"
                  [isFilter]="false" (searchData)="changeRule($event)">
                </tlb-dropdown>
              </div>

              <div *ngIf="rule == 'rating'" class="form-group mt-2 " style="padding: 8px 15px 8px 15px;">
                <label for="exampleInputEmail1" class="form-label"
                  style="font-family: Roboto; font-style: normal; font-weight: 500; font-size: 14px; line-height: 150%; text-transform: capitalize; color: #707683;">
                  {{ 'minimumRatingToBeApplicable' | translate }}
                </label>

                  <div class="row ml-1">
                    <div class="font-size d-flex pb-3">
                      <div class="rate">
                        <input [(ngModel)]="star" type="radio" id="star5" name="rate" value="5" />
                        <label for="star5" title="5 stars">5 stars</label>
                        <input [(ngModel)]="star" type="radio" id="star4" name="rate" value="4" />
                        <label for="star4" title="4 stars">4 stars</label>
                        <input [(ngModel)]="star" type="radio" id="star3" name="rate" value="3" />
                        <label for="star3" title="3 stars">3 stars</label>
                        <input [(ngModel)]="star" type="radio" id="star2" name="rate" value="2" />
                        <label for="star2" title="2 stars">2 stars</label>
                        <input [(ngModel)]="star" type="radio" id="star1" name="rate" value="1" />
                        <label for="star1" title="1 star">1 star</label>
                      </div>
                    </div>

                    <div class="ml-2 mt-3 up-text">& {{'up' | translate}}</div>
                  </div>
              </div>

              <div *ngIf="rule == 'placement'" class="form-group mt-2 " style="padding: 8px 15px 8px 15px;">
                <label for="exampleInputEmail1" class="form-label"
                  style="font-family: Roboto; font-style: normal; font-weight: 500; font-size: 14px; line-height: 150%; text-transform: capitalize; color: #707683;">
                  {{ 'minimumPlacementToBeApplicable' | translate }}
                </label>

                <input [(ngModel)]="placement" type="number" class="form-control" autocomplete="off"
                style="background: #FFFFFF; border: 1px solid rgba(17, 17, 17, 0.32); box-sizing: border-box; border-radius: 8px;"
                placeholder="{{'placements' | translate}}" name="placement"
                />
              </div>

            </div>


            <div class="row " style="justify-content: center; margin-bottom: 25px;">
              <div class="col-12">
                <button style="width: 94%;" (click)="confirm()" [disabled]="jobAccessibilityConfirmButtonDisabled" data-dismiss="modal" class="btn btn-primary ml-3">{{'confirm' |
                  translate}}</button>
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>


    <app-recruiter-list (selectDone)="selectOfInvitationDone($event)"></app-recruiter-list>
