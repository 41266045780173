import { Component, OnInit }   from '@angular/core';
import { PackageService }      from '../../../shared/services/subscription/package/package.service';
import { Package }             from '../../../shared/models/subscription/package';
import { Router }              from '@angular/router';
import { OAuthService }        from 'angular-oauth2-oidc';
import { NotificationService } from '../../../shared/services';
import { AuthService }         from '../../../shared/services/auth/auth.service';

declare var $: any;

@Component({
             selector: 'app-pricing',
             templateUrl: './pricing.component.html',
             styleUrls: ['./pricing.component.scss']
           })
export class PricingComponent implements OnInit {

  selectedPrice: boolean = true;
  packages: Package[] = [];
  role: string;
  hideSwitch = false;
  isCameFromDashboard = false;
  mine = null;
  count: number;

  constructor(private packageService: PackageService,
              private router: Router,
              protected notification: NotificationService,
              private oauthService: OAuthService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this.scrolling();

    this.role = localStorage.getItem('role');
    this.getPackages('RECRUITER');
    if (this.router.url.includes('dashboard') && localStorage.getItem('access_token')) {
      this.isCameFromDashboard = true;
      this.hideSwitch = true;
      this.packageService.getMine()
          .subscribe(mine => {
            this.mine = mine;
            if (this.mine.currentStatus.status == 'WAITING_FOR_PAYMENT') {
              this.router.navigateByUrl('/dashboard/checkout-form')
                  .then();
            } else if (this.mine.currentStatus.status == 'ACTIVE') {
              if (this.role == 'RECRUITER') {
                this.getPackages('RECRUITER');
              } else if (this.role == 'EMPLOYER_MANAGER' || this.role === 'EMPLOYER_USER') {
                this.getPackages('EMPLOYER');
              }
            } else if (this.mine.currentStatus.status == 'INITIALIZED') {
              if (this.role == 'NEW_SUBSCRIBER') {
                this.getPackages('RECRUITER');
              } else if (this.role == 'EMPLOYER_MANAGER' || this.role === 'EMPLOYER_USER') {
                this.getPackages('EMPLOYER');
              }
            }

          });
    }
  }

  getPackages(type: string) {
    this.packageService.ServerGetWithoutLoginWithText(`?sort=screenOrder&filter=type::`+ type)
        .subscribe(res => {
          this.count = res.totalElements;
          this.packages = [];
          res.content.forEach(x => {
            if (x.type == type) {
              this.packages.push(x);
            }
          });
          this.packages.forEach(x => {
            if (x.price == undefined) {
              x.price = 0;
            }
          });
        });
  }

  scrolling() {
    $('.fixed-top')
      .addClass('menu-bg');
    $('.navbar ')
      .removeClass('fixed-top');
  }

  changeTable(data: any) {
    if (data == true) {
      this.getPackages('RECRUITER');
    } else {
      this.getPackages('EMPLOYER');
    }
  }

  choosePacket(packet: Package) {
    if (this.isCameFromDashboard) {
      if (this.mine.selectedPackage != null) {
        if (this.mine.selectedPackage.id == packet.id) {
          this.notification.warning('youHaveChosenSamePackage');
          return null;
        }
      }
      if (this.mine.currentStatus.status == 'ACTIVE') {
        this.router.navigateByUrl('/dashboard/checkout-form/' + packet.id)
            .then();
      } else {
        this.packageService.changePackage(packet.id)
            .subscribe(x => {
              if (!packet.free) {
                this.router.navigateByUrl('/dashboard/checkout-form')
                    .then();
              } else {
                this.oauthService.refreshToken()
                    .then(x => {
                      const role = JSON.parse(
                        window.atob((localStorage.getItem(this.packageService.TOKEN_KEY)).split('.')[1])).role;
                      localStorage.setItem('role', role);
                      this.router.navigateByUrl('/dashboard')
                          .then(y => {
                            this.authService.getUserPermissionsFromEndpoint();
                          });
                    });
              }

            });
      }

    }
  }
}
