import { PasswordChangeModel } from '../../../shared/models/user-settings/change-password.model';
import { Component, OnInit } from '@angular/core';
import { FormType } from 'src/app/shared/enums';
import { TlbFormModel } from 'src/app/shared/models';
import { NotificationService, PasswordService, server, TranslatePipe } from 'src/app/shared/services';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  formModel: TlbFormModel[] = [];
  formGroup: FormGroup;
  model: any;
  postModel: PasswordChangeModel;

  constructor(private notification: NotificationService, private translate: TranslatePipe, private passwordService: PasswordService) { }

  ngOnInit(): void {
    this.model = {};

    this.createForm();
  }

  createForm() {
    this.formModel = [{
      class: 'col-12', components: [
        { label: 'currentPassword', control: 'currentPassword', required: true, type: FormType.password },
        { label: 'newPassword', control: 'newPassword', required: true, type: FormType.password },
        { label: 'newPasswordAgain', control: 'newPasswordAgain', required: true, type: FormType.password }
      ]
    }];

  this.formGroup = new FormGroup({
    currentPassword: new FormControl('', Validators.required),
    newPassword: new FormControl('', Validators.required),
    newPasswordAgain: new FormControl('', Validators.required)
  });
}

get form() { return this.formGroup.controls }

  changePassword() {
    if (this.form.currentPassword.value == this.form.newPassword.value) {
      this.notification.error(this.translate.transform('oldPasswordAndNewPasswordCannotBeTheSame'));
      return null;
    }
    if (this.form.newPassword.value == this.form.newPasswordAgain.value) {
      this.postModel = new PasswordChangeModel();
      this.postModel.currentPassword = this.form.currentPassword.value;
      this.postModel.newPassword = this.form.newPassword.value.toString();

      this.passwordService.ServerPut(server.password, this.postModel)
        .subscribe(res => {
          this.notification.success(this.translate.transform('yourPasswordHasBeenChangedSuccessfully'));
          // @ts-ignore
          $('#changePasswordModal').modal('hide');

        });

    }
    else {
      this.notification.error(this.translate.transform('enteredPasswordsDoNtMatch'));

    }
  }

}
