<div *ngIf="forms && ready" class="container">
  <div class="row">
    <div class="col-12 mb-5">
      <form action="#" class="form form-label-right" [formGroup]="formGroup">
        <div class="form-group row">
          <div *ngFor="let f of forms" [ngClass]="f.class">

            <div *ngIf="f.header != ''" class="mb-2 pb-2">
              <div class="row mb-1">
                <div class="col-12 text-center">
                  <h5>{{f.header}}</h5>
                </div>
              </div>
            </div>
            <div *ngFor="let component of f.components">
              <div *ngIf="!component.hide">
                <div [ngSwitch]="component.type">

                  <div *ngSwitchCase="'text'" class="mb-2 pb-2">
                    <label class="modal-input-label" [style]="component.labelStyle"> <i *ngIf="component.labelIcon"
                        [ngClass]="component.labelIcon"></i> {{component.label | translate}}
                      <span *ngIf="component.required" class="text-danger">*</span></label>
                    <input *ngIf="!component.disable" type="text" class="form-control" autocomplete="off"
                      [style]="component.style" placeholder="{{component.label | translate}}"
                      name="{{component.control}}" formControlName="{{component.control}}" />
                    <input *ngIf="component.disable" type="text" class="form-control" autocomplete="off"
                      placeholder="{{component.label}}" [style]="component.style" name="{{component.control}}" disabled
                      formControlName="{{component.control | translate}}" />
                  </div>

                  <div *ngSwitchCase="'iban'" class="mb-2 pb-2">
                    <label class="modal-input-label" [style]="component.labelStyle"> <i *ngIf="component.labelIcon"
                        [ngClass]="component.labelIcon"></i> {{component.label | translate}}
                      <span *ngIf="component.required" class="text-danger">*</span></label>
                    <input type="text" class="form-control" autocomplete="off" [style]="component.style"
                      placeholder="{{component.label | translate}}" name="{{component.control}}"
                      formControlName="{{component.control}}" (keypress)="ibanChars($event)" />

                  </div>

                  <div *ngSwitchCase="'doubleNumericText'" class="mb-2 pb-2">
                    <label class="modal-input-label" [style]="component.labelStyle">{{component.label | translate}}<span
                        *ngIf="component.required" class="text-danger">*</span></label>
                    <class class="row">
                      <div class="col-6">
                        <input type="number" class="form-control" autocomplete="off" [style]="component.style"
                          placeholder="{{component.control | translate}}" name="{{component.control}}"
                          (keypress)="customNumericText($event)" min="1" formControlName="{{component.control}}" />
                      </div>
                      <div class="col-6">
                        <input type="number" class="form-control" autocomplete="off" [style]="component.style"
                          (keypress)="customNumericText($event)" min="1" placeholder="{{component.control2 | translate}}"
                          name="{{component.control2}}" formControlName="{{component.control2}}" />
                      </div>
                    </class>
                  </div>

                  <div *ngSwitchCase="'arrayText'" class="mb-2 pb-2">
                    <label class="modal-input-label" [style]="component.labelStyle">{{component.label | translate}}<span
                        *ngIf="component.required" class="text-danger">*</span></label>
                    <input type="text" class="form-control" autocomplete="off" [style]="component.style"
                      placeholder="{{component.placeHolder | translate}}" name="{{component.control}}"
                      formControlName="{{component.control}}" (keyup.enter)="arrayText(component.control)" />
                  </div>


                  <div *ngSwitchCase="'email'" class="mb-2 pb-2">

                    <label class="modal-input-label" [style]="component.labelStyle">{{component.label | translate}}<span
                        *ngIf="component.required" class="text-danger">*</span></label>
                    <input type="email" class="form-control" [style]="component.style" name="{{component.control}}"
                      #searchInput (keypress)="keyDownHandler($event)" placeholder="{{component.label | translate}}"
                      autocomplete="off" formControlName="{{component.control}}" />

                  </div>

                  <div *ngSwitchCase="'numericText'" class="mb-2 pb-2">

                    <label class="modal-input-label" [style]="component.labelStyle">{{component.label | translate}}<span
                        *ngIf="component.required" class="text-danger">*</span></label>
                    <input class="form-control" name="{{component.control}}" [style]="component.style"
                      autocomplete="off" formControlName="{{component.control}}" step="{{component.step}}"
                      placeholder="{{component.label | translate}}" type="number" />

                  </div>

                  <div *ngSwitchCase="'customNumericText'" class="mb-2 pb-2">

                    <label class="modal-input-label" [style]="component.labelStyle">{{component.label | translate}}<span
                        *ngIf="component.required" class="text-danger">*</span></label>
                    <input class="form-control" name="{{component.control}}" [style]="component.style"
                      (keydown)="customNumericText($event, component.min, component.control)" autocomplete="off" formControlName="{{component.control}}"
                      step="{{component.step}}" min="1" placeholder="{{component.label | translate}}" type="number" />

                  </div>

                  <div *ngSwitchCase="'password'" class="mb-2 pb-2">
                    <label class="modal-input-label" [style]="component.labelStyle">{{component.label | translate}}<span
                        *ngIf="component.required" class="text-danger">*</span></label>
                    <input type="password" class="form-control" autocomplete="off"
                      placeholder="{{component.label | translate}}" name="{{component.control}}"
                      [style]="component.style" formControlName="{{component.control}}" />
                  </div>

                  <div *ngSwitchCase="'dropDown'" class="mb-2 pb-2">
                    <label class="modal-input-label" [style]="component.labelStyle">{{component.label | translate}}<span
                        *ngIf="component.required" class="text-danger">*</span></label>
                    <select *ngIf="component.onChange" #selectList (ngModelChange)="component.onChange($event)"
                      class="form-control" name="{{component.control}}" [style]="component.style"
                      formControlName="{{component.control}}" data-live-search="true">
                      <option [ngValue]="null" [disabled]="true">{{'pleaseSelect' | translate}}</option>
                      <option *ngFor="let opt of component.options" [ngValue]="opt.value">{{opt.label}}</option>
                    </select>
                    <select *ngIf="!component.onChange" #selectList class="form-control" [style]="component.style"
                      name="{{component.control}}" formControlName="{{component.control}}" data-live-search="true">
                      <option [ngValue]="null" [disabled]="true">{{'pleaseSelect' | translate}}</option>
                      <option *ngFor="let opt of component.options" [ngValue]="opt.value">{{opt.label}}</option>
                    </select>
                  </div>

                  <div *ngSwitchCase="'multiSelect'" class="mb-2 pb-2">
                    <label class="modal-input-label" [style]="component.labelStyle">{{component.label | translate}}<span
                        *ngIf="component.required" class="text-danger">*</span></label>
                    <ng-multiselect-dropdown style="border: none" [placeholder]="component.label | translate"
                      [settings]="component.msSettings ? component.msSettings : dropdownSettings"
                      [data]="component.options" formControlName="{{component.control}}">
                    </ng-multiselect-dropdown>
                  </div>

                  <div *ngSwitchCase="'multiSelectWithBorder'" class="mb-2 pb-2">
                    <label class="modal-input-label" [style]="component.labelStyle">{{component.label | translate}}<span
                        *ngIf="component.required" class="text-danger">*</span></label>
                    <ng-multiselect-dropdown class="test" style="border: none"
                      [placeholder]="component.label | translate" [settings]="dropdownSettings"
                      [data]="component.options" [formControl]="formGroup.controls[component.control]">
                    </ng-multiselect-dropdown>
                  </div>

                  <div *ngSwitchCase="'textArea'" class="mb-2 pb-2">
                    <label class="modal-input-label" [style]="component.labelStyle">{{component.label | translate}}<span
                        *ngIf="component.required" class="text-danger">*</span></label>
                    <textarea *ngIf="!component.disable" type="text" class="form-control" autocomplete="off" rows="3"
                      [style]="component.style" placeholder="{{component.label | translate}}"
                      name="{{component.control}}" formControlName="{{component.control}}"></textarea>

                    <textarea *ngIf="component.disable" type="text" class="form-control" autocomplete="off" rows="3"
                      [style]="component.style" placeholder="{{component.label | translate}}"
                      name="{{component.control}}" formControlName="{{component.control}}" disabled></textarea>

                  </div>

                  <div *ngSwitchCase="'textAreaWithLimit'" class="mb-2 pb-2">
                    <label class="modal-input-label" [style]="component.labelStyle">{{component.label | translate}}<span
                        *ngIf="component.required" class="text-danger">*</span></label>
                    <textarea *ngIf="!component.disable" type="text" class="form-control" autocomplete="off" rows="3"
                      [style]="component.style" placeholder="{{component.label | translate}}"
                      name="{{component.control}}" formControlName="{{component.control}}"
                      maxlength="{{component.limit}}"></textarea>

                    <textarea *ngIf="component.disable" type="text" class="form-control" autocomplete="off" rows="3"
                      [style]="component.style" placeholder="{{component.label | translate}}"
                      name="{{component.control}}" formControlName="{{component.control}}" disabled
                      maxlength="{{component.limit}}"></textarea>

                    <div
                      [ngClass]="component.limit - this.formGroup.controls[component.control].value?.length <= 20 ? 'text-area-limit-red' : 'text-area-limit'">
                      <span>{{this.formGroup.controls[component.control].value?.length}}
                        /{{component.limit}}</span>
                    </div>

                  </div>

                  <div *ngSwitchCase="'switchButton'" class="mb-2 pb-2">

                    <div class="custom-control custom-switch">
                      <input type="checkbox" class="custom-control-input" id="{{component.control}}"
                        formControlName="{{component.control}}">
                      <label class="custom-control-label" [style]="component.labelStyle"
                        for="{{component.control}}">{{component.label | translate}}
                      </label>
                    </div>
                  </div>

                  <div *ngSwitchCase="'phoneNumber'" class="mb-3">
                    <label class="modal-input-label" [style]="component.labelStyle">{{component.label |
                      translate}}<span *ngIf="component.required" class="text-danger">*</span></label>
                    <div class="row">
                      <div class="col-lg-5 text-center">
                        <select class="form-control" [style]="component.style" name="{{component.control}}"
                          formControlName="{{component.control}}">
                          <option *ngFor="let opt of phoneNumberCodesOptions" [ngValue]="opt.value">{{opt.label}}
                          </option>
                        </select>
                      </div>
                      <div class="col-lg-7 pl-lg-0 mt-2 mt-lg-0">
                        <input [style]="component.style" class="form-control" formControlName="{{component.control2}}"
                          id="{{component.label}}" name="{{component.label}}" (keypress)="phoneNumberChars($event)"
                          placeholder="{{ component.label | translate }}" type="text" minlength="7" maxlength="15" />

                      </div>

                    </div>
                  </div>

                  <div *ngSwitchCase="'media'" class="mb-2 pb-2">
                    <label class="modal-input-label" [style]="component.labelStyle">{{component.label | translate}}<span
                        *ngIf="component.required" class="text-danger">*</span></label>
                    <div *ngIf="isLoading$ | async" class="row">
                      <div class="col-5">
                        <img [src]="component.imageUrl ? component.imageUrl : 'assets/images/uploadImage.jpg'"
                          class="img-fluid upload-image">
                      </div>
                      <div class="col-7 p-0 mt-auto mb-auto">
                        <button (click)="choosePhoto(component, component.index, component.control)" type="button"
                          class="form-control"
                          style="border-bottom-left-radius: 0; border-top-left-radius: 0; cursor: pointer;">
                          Medya Seç
                        </button>
                        <input *ngIf="component.label && component.label != ''" aria-describedby="basic-addon2"
                          class="form-control" readonly required type="text" value="{{component.name}}">
                        <input #fileUpload id="test" name="fileUpload" accept="image/*,video/*" style="display: none;"
                          type="file">
                      </div>
                    </div>
                  </div>

                </div>
                <div class="mb-2 pb-2"
                  *ngIf="component.showPatternDetailsOnTheBottom && formGroup.get(component.control) as fgControl">
                  <span *ngFor="let pattern of component.patterns" class="d-block text-pattern"
                    [ngClass]="fgControl.value.match(pattern.pattern) !==null?'text-success':fgControl.touched?'text-danger':'text-dark'">
                    * {{pattern.errorText|translate}}
                  </span>
                  <!--                      <span
                                                                  class="text-danger"
                                                                  *ngIf="formGroup.get(component.name).errors &&
                                                formGroup.get(component.name).hasError('required')"
                                                          >*</span>-->
                </div>
              </div>
            </div>
          </div>
        </div>

      </form>
    </div>
  </div>

  <!-- Buttons -->
  <div *ngIf="forms && (saveButtonActive || cancelButtonActive)" class="row mb-5">
    <div>
      <button *ngIf="cancelButtonActive" (click)="onCancel()" class="btn btn-prev float-left" id="next" type="button">
        {{ cancelButtonLabel }}
      </button>
    </div>
    <div class="ml-auto">
      <button *ngIf="saveButtonActive" (click)="onSave()" type="submit" class="btn btn-primary font-weight-bold mr-2"
        [disabled]="formGroup.invalid || !isEdited">
        {{ saveButtonLabel }}
      </button>
    </div>
  </div>
</div>
