import {Component, OnInit} from '@angular/core';
import {ProfileEnrichmentService} from '../../shared/services/user/profile-enrichment-service';
import {
    CityService,
    CountryService,
    EmployerMeService,
    HelpCenterService,
    NotificationService,
    PasswordService,
    RecruiterMeService,
    server,
    TranslatePipe,
    UserMeService
} from '../../shared/services';
import {RecruiterMeModel} from '../../shared/models/recruiter/recuiter-me.model';
import {
    CityModel,
    CountryModel,
    EmployerSocialLinkModel,
    PasswordChangeModel,
    RecruiterCompanyInfoModel,
    TlbFormModel,
    UserGeneralInfosModel
} from '../../shared/models';
import {EmployerMeModel} from '../../shared/models/employer/employer-me.model';
import {Router} from '@angular/router';
import {AuthService} from '../../shared/services/auth/auth.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FormType} from 'src/app/shared/enums';
import {BillingInfoService} from '../../shared/services/billing-info/billing-info.service';
import {BillingInfoModel} from '../../shared/services/billing-info/billing-info.model';
import {LegalInfoService} from '../../shared/services/legal-info/legal-info.service';
import {LegalInfoModel} from '../../shared/services/legal-info/legal-info.model';

declare var $: any;
declare var jQuery: any;

@Component({
    selector: 'app-edit-profile',
    templateUrl: './edit-profile.component.html',
    styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {

    role = '';
    profileEnrichmentScore = 0;
    activeTab = 0;
    recruiterMe: RecruiterMeModel;
    employerMe: EmployerMeModel;
    generalInfos: UserGeneralInfosModel = new UserGeneralInfosModel();
    changePasswordPostModel = new PasswordChangeModel();
    rePassword = '';
    socialLinks = {
        instagram: '',
        twitter: '',
        facebook: '',
        linkedin: ''
    };

    passwordFeedbacks = {
        currentPasswordFeedback: '',
        newPasswordFeedback: '',
        rePasswordFeedback: ''
    };
    modalSettings = {
        title: '',
        value: ''
    };
    phoneNumberCodesOptions: any[] = [];
    helpCenter = {
        subject: '',
        message: ''
    };

    billings = [];
    billingInfoModel: BillingInfoModel = new BillingInfoModel();
    legalInfoModel: LegalInfoModel = new LegalInfoModel();

    formModel: TlbFormModel[] = [];
    formGroup: FormGroup;

    billingInfoForm: FormGroup;
    legalInfoForm: FormGroup;
    countries: CountryModel[] = [];
    cities: CityModel[] = [];

    constructor(private profileEnrichmentService: ProfileEnrichmentService,
                private recruiterMeService: RecruiterMeService,
                private employerMeService: EmployerMeService, private userMeService: UserMeService,
                private passwordService: PasswordService, private countryService: CountryService,
                private router: Router, private helpCenterService: HelpCenterService,
                private notification: NotificationService, private translate: TranslatePipe,
                private authService: AuthService,
                private cityService: CityService,
                private billingInfoService: BillingInfoService,
                private legalInfoService: LegalInfoService,
    ) {
    }

    ngOnInit(): void {
        this.createBillingInfoForm();
        this.createLegalInfoForm();
        this.role = localStorage.getItem('role');
        this.countryService.ServerGetWithoutLogin()
            .subscribe(x => {
                this.countries = x.content;
                let countries = x.content;
                countries.forEach(x => {
                    this.phoneNumberCodesOptions.push(
                        {label: x.callingCode + ' (' + x.defaultLocalization.name + ')', value: x.callingCode});
                });

            });
        this.profileEnrichmentService.getProfileEnrichmentScore()
            .subscribe(profileEnrichmentScore => {
                this.profileEnrichmentScore = profileEnrichmentScore;
            });
        // tslint:disable-next-line:only-arrow-functions no-shadowed-variable
        (function($) {
            $.fn.focusTextToEnd = function() {
                this.focus();
                // tslint:disable-next-line:prefer-const
                let $thisVal = this.val();
                this.val('')
                    .val($thisVal);
                return this;
            };
        }(jQuery));


        this.getProfileData();
    }

    createPasswordForm() {
        this.formModel = [{
            class: 'col-12', components: [
                {
                    label: 'currentPassword',
                    control: 'currentPassword',
                    required: true,
                    type: FormType.password
                },
                {
                    label: 'newPassword',
                    control: 'newPassword',
                    required: true,
                    type: FormType.password,
                    showPatternDetailsOnTheBottom: true,
                    patterns: [
                        {pattern: '.*[a-z].*', errorText: 'mustALowerLetter'},
                        {pattern: '.*[A-Z].*', errorText: 'mustAUpperLetter'},
                        {pattern: '.*[0-9].*', errorText: 'mustADigit'},
                        {pattern: '.*[@$!%*.?&:].*', errorText: 'mustASpecialLetter'},
                        {pattern: '^.{6,}$', errorText: 'mustAtLeastSixLetter'},
                    ]
                },
                {
                    label: 'confirmNewPassword',
                    control: 'confirmNewPassword',
                    required: true,
                    type: FormType.password
                }
            ]
        }];

        this.formGroup = new FormGroup({
            currentPassword: new FormControl('', Validators.required),
            newPassword: new FormControl('',
                [
                    Validators.required,
                    Validators.pattern('.*[a-z].*'),
                    Validators.pattern('.*[A-Z].*'),
                    Validators.pattern('.*[0-9].*'),
                    Validators.pattern(
                        '.*[@$!%*.?&:].*'),
                    Validators.pattern('^.{6,}$')
                ]),
            confirmNewPassword: new FormControl('', Validators.required)
        });
    }

    get form() {
        return this.formGroup.controls;
    }

    enableNameInput(): void {

        $('#name-surname-input-div')
            .show();
        $('#full-name-span')
            .hide();
        $('.fa-pen.bg-gray')
            .hide();

    }

    enableContactPhone() {
        $('#contact-number-div')
            .show();
        $('#contact-number-span')
            .hide();
        $('#btn-contactNumber')
            .hide();

    }

    // tslint:disable-next-line:typedef
    checkAllItemsOnTable(event: PointerEvent) {
        // @ts-ignore
        $('.table-check-input')
            // @ts-ignore
            .prop('checked', event.target.checked);
    }

    // tslint:disable-next-line:typedef
    enableEmail() {
        $('#e-mail-div')
            .show();
        $('#e-mail-span')
            .hide();
        // $('#e-mail-title-span')
        //   .hide();
        $('#btn-e-mail')
            .hide();
    }


    saveGeneralInfo(): void {
        this.generalInfos.createdAt = null;
        this.userMeService.putGeneralInfo(this.generalInfos)
            .subscribe(x => {
                location.reload();
            });
    }

    editSocialLink(socialNetwork: string): void {
        switch (socialNetwork) {
            case 'instagram':
                this.modalSettings.value = this.socialLinks.instagram;
                break;
            case 'twitter':
                this.modalSettings.value = this.socialLinks.twitter;
                break;
            case 'facebook':
                this.modalSettings.value = this.socialLinks.facebook;
                break;
            case 'linkendin':
                this.modalSettings.value = this.socialLinks.linkedin;
                break;
        }
        this.modalSettings.title = socialNetwork;
        $('#edit-social-link-modal')
            .modal({
                show: true
            });
    }

    saveSocialLink(): void {
        //this.getProfileData();
        switch (this.role) {
            case 'EMPLOYER_MANAGER':
            case 'EMPLOYER_USER':
                const employerSocialLinkModel = new EmployerSocialLinkModel();
                employerSocialLinkModel.linkedinAccount = this.socialLinks.linkedin;
                employerSocialLinkModel.facebookAccount = this.socialLinks.facebook;
                employerSocialLinkModel.twitterAccount = this.socialLinks.twitter;
                employerSocialLinkModel.instagramAccount = this.socialLinks.instagram;

                switch (this.modalSettings.title) {
                    case 'instagram':
                        employerSocialLinkModel.instagramAccount = this.modalSettings.value;
                        break;
                    case 'twitter':
                        employerSocialLinkModel.twitterAccount = this.modalSettings.value;
                        break;
                    case 'facebook':
                        employerSocialLinkModel.facebookAccount = this.modalSettings.value;
                        break;
                    case 'linkendin':
                        employerSocialLinkModel.linkedinAccount = this.modalSettings.value;
                        break;
                }

                this.employerMeService.putSocialLinks(employerSocialLinkModel)
                    .subscribe(x => {
                        this.modalSettings.title = '';
                        this.modalSettings.value = '';
                        $('#edit-social-link-modal')
                            .modal('hide');
                        this.getProfileData();
                    });

                break;
            case 'RECRUITER_MANAGER':
            case 'RECRUITER_USER':
                this.recruiterMeService.getCompanyInfo()
                    .subscribe(x => {
                        let recruiterCompanyInfoModel = new RecruiterCompanyInfoModel();
                        recruiterCompanyInfoModel = x as RecruiterCompanyInfoModel;
                        switch (this.modalSettings.title) {
                            case 'linkendin':
                                recruiterCompanyInfoModel.linkedinAccount = this.modalSettings.value;
                                this.recruiterMeService.putCompanyInfo(recruiterCompanyInfoModel)
                                    .subscribe(x => {
                                        this.modalSettings.title = '';
                                        this.modalSettings.value = '';
                                        $('#edit-social-link-modal')
                                            .modal('hide');
                                        this.getProfileData();
                                    });
                                break;
                        }
                    });

                break;
        }

    }

    changePassword(): void {
        this.changePasswordPostModel.currentPassword = this.form.currentPassword.value;
        this.changePasswordPostModel.newPassword = this.form.newPassword.value;
        this.passwordService.ServerPut(server.password, this.changePasswordPostModel)
            .subscribe(x => {
                    setTimeout(() => {

                        this.createPasswordForm();
                        // @ts-ignore
                        $('#successPopup')
                            .modal('show');
                    }, 1000);
                },
                error => {
                    if (error.code === 'WRONG_PASSWORD') {
                        $('#current-password-feedback')
                            .show();
                        this.notification.error(this.translate.transform('WRONG_PASSWORD'));
                    }
                });
    }

    resetFeedback(): void {
        $('#current-password-feedback')
            .hide();
        $('#new-password-feedback')
            .hide();
        $('#re-password-feedback')
            .hide();
    }

    popoversDisplay() {

        $('#popover')
            .popover()
            .click(function() {
                setTimeout(function() {
                    $('#popover')
                        .popover('hide');
                }, 2000);
            });

    }

    goToCompleteProfile() {

        if (this.role == 'RECRUITER_MANAGER' || this.role === 'RECRUITER_USER') {
            this.router.navigate(['/dashboard/complete-recruiter-register']);
        } else {
            this.router.navigate(['/dashboard/complete-employer-register/null']);
        }

    }

    sendHelpCenter() {
        this.helpCenterService.ServerPost(this.helpCenter)
            .subscribe(x => {
                this.helpCenter.message = '';
                this.helpCenter.subject = '';
                this.notification.success(this.translate.transform('yourMessageHasBeenSuccessfullyDelivered'));

            });

    }

    logOut() {
        this.authService.ServerLogout();
    }

    private getProfileData(): void {
        this.userMeService.getGeneralInfo()
            .subscribe(generalInfo => {
                this.generalInfos = generalInfo as UserGeneralInfosModel;
                // this.generalInfos.phoneNumber.countryCallingCode = this.generalInfos.phoneNumber.countryCallingCode == null ? '' : this.generalInfos.phoneNumber.countryCallingCode;
                // this.generalInfos.phoneNumber.phoneNumber = this.generalInfos.phoneNumber.phoneNumber == null ? '' : this.generalInfos.phoneNumber.phoneNumber;
            });
        const role = localStorage.getItem('role');
        switch (role) {
            case 'RECRUITER_MANAGER':
            case 'RECRUITER_USER':
                this.recruiterMeService.ServerGet()
                    .subscribe(me => {
                        this.recruiterMe = me;
                        this.socialLinks.linkedin = this.recruiterMe.linkedinAccount;
                    });
                break;
            case 'EMPLOYER_MANAGER':
            case 'EMPLOYER_USER':
                this.employerMeService.ServerGet()
                    .subscribe(me => {
                        this.employerMe = me;
                        this.socialLinks.instagram = this.employerMe.instagramAccount;
                        this.socialLinks.twitter = this.employerMe.twitterAccount;
                        this.socialLinks.facebook = this.employerMe.facebookAccount;
                        this.socialLinks.linkedin = this.employerMe.linkedinAccount;
                    });
                break;
        }

        this.createPasswordForm();
    }

    private createBillingInfoForm(): void {
        this.billingInfoForm = new FormGroup({
            addressLine1: new FormControl('', [
                Validators.required,
            ]),
            addressLine2: new FormControl('', [
                Validators.required,
            ]),
            country: new FormControl('', [
                Validators.required,
            ]),
            city: new FormControl('', [
                Validators.required,
            ]),
            billingZipCode: new FormControl('', [
                Validators.required,
            ]),

        });

        this.billingInfoForm.get('country').valueChanges.subscribe(val => {
            this.cityService.getCountryCities(val)
                .subscribe(x => {
                    this.cities = x;

                });
        });
        const role = localStorage.getItem('role');
        switch (role) {
            case 'RECRUITER_MANAGER':
            case 'RECRUITER_USER':
                this.billingInfoService.getRecruiterBillingInfo().subscribe(x => {
                    this.billingInfoForm.patchValue({
                        addressLine1: x.line1,
                        addressLine2: x.line2,
                        billingZipCode: x.zipCode,
                        country: (x.country !== undefined ? x.country.id : ''),
                        city: (x.city !== undefined ? x.city.id : '')
                    });
                });
                break;
            case 'EMPLOYER_MANAGER':
            case 'EMPLOYER_USER':
                this.billingInfoService.getEmployerBillingInfo().subscribe(x => {
                    this.billingInfoForm.patchValue({
                        addressLine1: x.line1,
                        addressLine2: x.line2,
                        billingZipCode: x.zipCode,
                        country: (x.country !== undefined ? x.country.id : ''),
                        city: (x.city !== undefined ? x.city.id : '')
                    });
                });


                break;
        }


    }

    saveBillingInfo(): void {
        this.billingInfoModel.line1 = this.billingInfoForm.get('addressLine1').value;
        this.billingInfoModel.line2 = this.billingInfoForm.get('addressLine2').value;
        this.billingInfoModel.zipCode = this.billingInfoForm.get('billingZipCode').value;
        this.billingInfoModel.country.id = this.billingInfoForm.get('country').value;
        this.billingInfoModel.city.id = this.billingInfoForm.get('city').value;
        const role = localStorage.getItem('role');
        switch (role) {
            case 'RECRUITER_MANAGER':
            case 'RECRUITER_USER':
                this.billingInfoService.putRecruiterBillingInfo(this.billingInfoModel).subscribe(x => {
                    this.billingInfoForm.patchValue({
                        addressLine1: x.line1,
                        addressLine2: x.line2,
                        billingZipCode: x.zipCode,
                        country: (x.country !== undefined ? x.country.id : ''),
                        city: (x.city !== undefined ? x.city.id : '')
                    });
                    $('#successPopup').modal('show');
                });
                break;
            case 'EMPLOYER_MANAGER':
            case 'EMPLOYER_USER':
                this.billingInfoService.putEmployerBillingInfo(this.billingInfoModel).subscribe(x => {
                    this.billingInfoForm.patchValue({
                        addressLine1: x.line1,
                        addressLine2: x.line2,
                        billingZipCode: x.zipCode,
                        country: (x.country !== undefined ? x.country.id : ''),
                        city: (x.city !== undefined ? x.city.id : '')
                    });
                    $('#successPopup').modal('show');
                });
                break;
        }

    }

    log() {
        console.log(this.billingInfoForm.get('billingAddress'));
    }

    private createLegalInfoForm(): void {
        this.legalInfoForm = new FormGroup({
            companyName: new FormControl('', [
                Validators.required,
            ]),
            companyAddress: new FormControl('', [
                Validators.required,
            ]),
            emailAddress: new FormControl('', [
                Validators.required,
                Validators.pattern('[A-Za-z0-9._%-+!#$%&\'*+=?^_`{|}~]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}'),
            ]),
        });

        const role = localStorage.getItem('role');
        switch (role) {
            case 'RECRUITER_MANAGER':
            case 'RECRUITER_USER':
                this.legalInfoService.getRecruiterLegalInfo().subscribe(x => {
                    this.legalInfoForm.patchValue({
                        companyAddress: x.companyAddress,
                        companyName: x.companyName,
                        emailAddress: x.emailAddress,
                    });
                });
                break;
            case 'EMPLOYER_MANAGER':
            case 'EMPLOYER_USER':
                this.legalInfoService.getEmployerLegalInfo().subscribe(y => {
                    this.legalInfoForm.patchValue({
                        companyAddress: y.companyAddress,
                        companyName: y.companyName,
                        emailAddress: y.emailAddress,
                    });
                });
                break;
        }

    }

    saveLegalInfo(): void {
        this.legalInfoModel.companyAddress = this.legalInfoForm.get('companyAddress').value;
        this.legalInfoModel.companyName = this.legalInfoForm.get('companyName').value;
        this.legalInfoModel.emailAddress = this.legalInfoForm.get('emailAddress').value;
        const role = localStorage.getItem('role');
        switch (role) {
            case 'RECRUITER_MANAGER':
            case 'RECRUITER_USER':
                this.legalInfoService.putRecruiterLegalInfo(this.legalInfoModel).subscribe(x => {
                    this.legalInfoForm.patchValue({
                        companyAddress: x.companyAddress,
                        companyName: x.companyName,
                        emailAddress: x.emailAddress,
                    });
                    $('#successPopup').modal('show');
                });
                break;
            case 'EMPLOYER_MANAGER':
            case 'EMPLOYER_USER':
                this.legalInfoService.putEmployerLegalInfo(this.legalInfoModel).subscribe(x => {
                    this.legalInfoForm.patchValue({
                        companyAddress: x.companyAddress,
                        companyName: x.companyName,
                        emailAddress: x.emailAddress,
                    });
                    $('#successPopup').modal('show');
                });
                break;
        }


    }


}
