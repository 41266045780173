<!-- <div class="modal fade bd-example-modal-xl" id="candidateReviewModal" tabindex="-1" role="dialog"
       aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content p-2">
      <div class="modal-body" > -->
        <app-main-header *ngIf="isCandidate" [HeaderMode]="'candidate'"></app-main-header>
        <div class="container-fluid px-4 px-sm-5 pt-5  h-100">
          <!-- <div class="card"> -->
            <div class="row h-100 overflow-auto">
              <div class="col-12 col-xl-9 bg-light mx-auto my-auto shadow pt-5" style="min-width: 554px;">
                  <div class="container">
                    <div class="info-border px-3 pb-3">
                      <div class="row">
                        <div class="col-12 pb-3 purple pt-3">
                          <h6><b>{{'recruiterInfo' | translate}}</b></h6>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <h6><b>{{recruiter?.user?.fullName}}</b></h6>
                        </div>
                      </div>

                      <div class="row">
                      <div class="col-12">
                        <div class="row pt-3">

                          <div class="col-12 col-md-12 col-lg-12 col-xl-4 mb-2 mb-md-2"><i class="fas fa-calendar purple" ></i>
                            <span class="ml-2 gray font-text">{{ 'recruitmentExperience' | translate }}</span>
                          </div>
                          <div class="col-12 col-md-12 col-lg-12 col-xl-8 mb-2 mb-md-2">
                            <span class="ml-2 font-text">{{recruiter?.individualPracticeTime|translate}}</span>
                          </div>

                          <div class="col-12 col-md-12 col-lg-12 col-xl-4 mb-2 mb-md-2"><i class="fas fa-handshake purple" ></i>
                            <span class="ml-2 gray font-text">{{ 'jobRecruisitionTypes' | translate }}</span>
                          </div>
                          <div class="col-12 col-md-12 col-lg-12 col-xl-8 mb-2 mb-md-2">
                            <span class="ml-2 font-text">{{recruiter?.requisitionTypesText}}</span>
                          </div>

                          <div class="col-12 col-md-12 col-lg-12 col-xl-4 mb-2 mb-md-2"><i class="fas fa-sticky-note purple" ></i>
                            <span class="ml-2 gray font-text">{{ 'functionalRoles' | translate }}</span>
                          </div>
                          <div class="col-12 col-md-12 col-lg-12 col-xl-8 mb-2 mb-md-2">
                            <span class="ml-2 font-text">{{recruiter?.functionalRolesText}}</span>
                          </div>

                          <div class="col-12 col-md-12 col-lg-12 col-xl-4 mb-2 mb-md-2"><i class="fas fa-building purple" ></i>
                            <span class="ml-2 gray font-text">{{ 'industries' | translate }}</span>
                          </div>
                          <div class="col-12 col-md-12 col-lg-12 col-xl-8 mb-2 mb-md-2">
                            <span class="ml-2 font-text">{{recruiter?.industriesText}}</span>
                          </div>
                      </div>
                    </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 pt-4 text-center">
                      <h5>{{ 'recruiterInfoText' | translate }}</h5>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 pt-2 text-center font-size">
                      <p>{{ 'pleaseReviewRecruiter' | translate }}</p>
                    </div>
                  </div>

                      <div class="col-8 m-auto" style="    min-width: 460px;">
                        <div class="row">
                          <div class="col-4" style="align-self: center;">
                           <span>{{'overallSatisfaction' | translate}}</span>
                          </div>

                          <div class="col-8">
                            <div class="rate">
                              <input [(ngModel)]="postModel.rating" type="radio" id="star15" name="rate" value="5" />
                              <label for="star15" title="5 stars">5 stars</label>
                              <input [(ngModel)]="postModel.rating" type="radio" id="star14" name="rate" value="4" />
                              <label for="star14" title="4 stars">4 stars</label>
                              <input [(ngModel)]="postModel.rating" type="radio" id="star13" name="rate" value="3" />
                              <label for="star13" title="3 stars">3 stars</label>
                              <input [(ngModel)]="postModel.rating" type="radio" id="star12" name="rate" value="2" />
                              <label for="star12" title="2 stars">2 stars</label>
                              <input [(ngModel)]="postModel.rating" type="radio" id="star11" name="rate" value="1" />
                              <label for="star11" title="1 star">1 star</label>
                            </div>
                          </div>
                        </div>


                        <div class="row" style="margin-top: -30px;">
                          <div class="col-4" style="align-self: center;">
                            <span>{{'expertisement' | translate}}</span>
                          </div>

                          <div class="col-8">
                            <div class="rateSml">
                              <input [(ngModel)]="postModel.expertisement" type="radio" id="star25" name="rate2" value="5" />
                              <label for="star25" title="5 stars">5 stars</label>
                              <input [(ngModel)]="postModel.expertisement" type="radio" id="star24" name="rate2" value="4" />
                              <label for="star24" title="4 stars">4 stars</label>
                              <input [(ngModel)]="postModel.expertisement" type="radio" id="star23" name="rate2" value="3" />
                              <label for="star23" title="3 stars">3 stars</label>
                              <input [(ngModel)]="postModel.expertisement" type="radio" id="star22" name="rate2" value="2" />
                              <label for="star22" title="2 stars">2 stars</label>
                              <input [(ngModel)]="postModel.expertisement" type="radio" id="star21" name="rate2" value="1" />
                              <label for="star21" title="1 star">1 star</label>
                            </div>
                          </div>
                        </div>

                        <div class="row" style="margin-top: -30px;">
                          <div class="col-4" style="align-self: center;">
                            <span>{{'communication' | translate}}</span>
                          </div>

                          <div class="col-8">
                            <div class="rateSml2">
                              <input [(ngModel)]="postModel.communication" type="radio" id="star35" name="rate3" value="5" />
                              <label for="star35" title="5 stars">5 stars</label>
                              <input [(ngModel)]="postModel.communication" type="radio" id="star34" name="rate3" value="4" />
                              <label for="star34" title="4 stars">4 stars</label>
                              <input [(ngModel)]="postModel.communication" type="radio" id="star33" name="rate3" value="3" />
                              <label for="star33" title="3 stars">3 stars</label>
                              <input [(ngModel)]="postModel.communication" type="radio" id="star32" name="rate3" value="2" />
                              <label for="star32" title="2 stars">2 stars</label>
                              <input [(ngModel)]="postModel.communication" type="radio" id="star31" name="rate3" value="1" />
                              <label for="star31" title="1 star">1 star</label>
                            </div>
                          </div>
                        </div>


                        <div class="row" style="margin-top: -30px;">
                          <div class="col-4" style="align-self: center;">
                            <span>{{'followUp' | translate}}</span>
                          </div>

                          <div class="col-8">
                            <div class="rateSml3">
                              <input [(ngModel)]="postModel.followUp" type="radio" id="star45" name="rate4" value="5" />
                              <label for="star45" title="5 stars">5 stars</label>
                              <input [(ngModel)]="postModel.followUp" type="radio" id="star44" name="rate4" value="4" />
                              <label for="star44" title="4 stars">4 stars</label>
                              <input [(ngModel)]="postModel.followUp" type="radio" id="star43" name="rate4" value="3" />
                              <label for="star43" title="3 stars">3 stars</label>
                              <input [(ngModel)]="postModel.followUp" type="radio" id="star42" name="rate4" value="2" />
                              <label for="star42" title="2 stars">2 stars</label>
                              <input [(ngModel)]="postModel.followUp" type="radio" id="star41" name="rate4" value="1" />
                              <label for="star41" title="1 star">1 star</label>
                            </div>
                          </div>
                        </div>


                        <div class="row" style="margin-top: -30px;">
                          <div class="col-4" style="align-self: center;">
                            <span>{{'informativity' | translate}}</span>
                          </div>

                          <div class="col-8">
                            <div class="rateSml4">
                              <input [(ngModel)]="postModel.informativity" type="radio" id="star55" name="rate5" value="5" />
                              <label for="star55" title="5 stars">5 stars</label>
                              <input [(ngModel)]="postModel.informativity" type="radio" id="star54" name="rate5" value="4" />
                              <label for="star54" title="4 stars">4 stars</label>
                              <input [(ngModel)]="postModel.informativity" type="radio" id="star53" name="rate5" value="3" />
                              <label for="star53" title="3 stars">3 stars</label>
                              <input [(ngModel)]="postModel.informativity" type="radio" id="star52" name="rate5" value="2" />
                              <label for="star52" title="2 stars">2 stars</label>
                              <input [(ngModel)]="postModel.informativity" type="radio" id="star51" name="rate5" value="1" />
                              <label for="star51" title="1 star">1 star</label>
                            </div>
                          </div>
                        </div>


                        <div class="row" style="margin-top: -30px;">
                          <div class="col-4" style="align-self: center;">
                            <span>{{'industryKnowledge' | translate}}</span>
                          </div>

                          <div class="col-8">
                            <div class="rateSml5">
                              <input [(ngModel)]="postModel.industryKnowledge" type="radio" id="star65" name="rate6" value="5" />
                              <label for="star65" title="5 stars">5 stars</label>
                              <input [(ngModel)]="postModel.industryKnowledge" type="radio" id="star64" name="rate6" value="4" />
                              <label for="star64" title="4 stars">4 stars</label>
                              <input [(ngModel)]="postModel.industryKnowledge" type="radio" id="star63" name="rate6" value="3" />
                              <label for="star63" title="3 stars">3 stars</label>
                              <input [(ngModel)]="postModel.industryKnowledge" type="radio" id="star62" name="rate6" value="2" />
                              <label for="star62" title="2 stars">2 stars</label>
                              <input [(ngModel)]="postModel.industryKnowledge" type="radio" id="star61" name="rate6" value="1" />
                              <label for="star61" title="1 star">1 star</label>
                            </div>
                          </div>
                        </div>

                      </div>




                  <div class="row">
                    <div class="col-12 pt-3">
                      <textarea [(ngModel)]="comment" type="text" placeholder="Type your answer here" class="w-100 form-control" rows="4" id="cover-letter-textarea"></textarea>
                    </div>
                  </div>

                  <div class="row pb-4">
                    <div class="col-4"></div>
                    <div class="col-4 pt-5 pb-3">
                      <button class="form-control green-button" (click)="postReview()">
                        {{ 'submit' | translate }}
                      </button>
                    </div>
                    <div class="col-4"></div>
                  </div>
                </div>
              </div>
        </div>
      </div>
    <!-- </div>
  </div>
</div> -->

