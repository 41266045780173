import {CreateNewJobProfileComponent} from './components/job/post-a-job/create-new-job-profile/create-new-job-profile.component';
import {RecruiterGuard} from './shared/services/auth/guards/recruiter.guard';
import {EmployerGuard} from './shared/services/auth/guards/employer.guard';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginLayoutComponent} from './shared/layouts/login-layout/login-layout.component';
import {DashboardLayoutComponent} from './shared/layouts/dashboard-layout/dashboard-layout.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {LoginComponent} from './components/general-user-screens/login/login.component';
import {HeaderComponent} from './shared/components/header/header.component';
import {FooterComponent} from './shared/components/footer/footer.component';
import {SidebarComponent} from './shared/components/sidebar/sidebar.component';
import {TranslatePipe} from './shared/pipes/translate/translate.pipe';
import {MainHeaderComponent} from './shared/components/main-header/main-header.component';
import {SidebarModule} from 'ng-sidebar';
import {NgxSpinnerModule} from 'ngx-spinner';
import {NotificationService} from './shared/services/notification/notification.service';
import {TranslateService, TRANSLATION_PROVIDERS} from './shared/services/translate';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule, DatePipe, registerLocaleData, TitleCasePipe} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {LoginGuard} from './shared/services/auth/guards/login.guard';
import {AuthGuard} from './shared/services/auth/guards/auth.guard';
import {OAuthModule, OAuthService, OAuthStorage} from 'angular-oauth2-oidc';
import {TlbDropdownComponent} from './shared/components/tlb-dropdown/tlb-dropdown.component';
import {MailVerifyComponent} from './components/general-user-screens/mail-verify/mail-verify.component';
import {DashboardQuarterLineCartComponent} from './shared/components/dashboard-quarter-line-cart/dashboard-quarter-line-cart.component';
import {AddNewCandidateComponent} from './components/candidate/add-new-candidate/add-new-candidate.component';
import {CandidateLayoutComponent} from './shared/layouts/candidate-layout/candidate-layout.component';
import {TrackCandidatesComponent} from './components/candidate/track-candidates/track-candidates.component';
import {TrackApplicationsComponent} from './components/candidate/track-applications/track-applications.component';
import {EmployerDashboardComponent} from './components/dashboard/employer-dashboard/employer-dashboard.component';
import {ToUpperCaseDirective} from './shared/directives/to-upper-case.directive';
import {JobService} from './shared/services';
import {RecruiterDashboardComponent} from './components/dashboard/recruiter-dashboard/recruiter-dashboard.component';
import {SearchJobsComponent} from './components/job/search-jobs/search-jobs.component';
import {JobsAppliedComponent} from './components/job/jobs-applied/jobs-applied.component';
import {RecruiterJobsComponent} from './shared/components/recruiter/recruiter-jobs/recruiter-jobs.component';
import {EmployerJobsComponent} from './shared/components/employer/employer-jobs/employer-jobs.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {MyJobsComponent} from './components/job/my-jobs/my-jobs.component';
import {MyjobModalComponent} from './components/job/add-new-job/add-new-job.component';
import {TlbMultiselectDropdownComponent} from './shared/components/tlb-multiselect-dropdown/tlb-multiselect-dropdown.component';
import {JobsFilterComponent} from './components/job/jobs-filter/jobs-filter.component';
import {EmployerRecruiterComponent} from './components/employer/employer-recruiter/employer-recruiter.component';
import {UsersComponent} from './components/users/users.component';
import {UserModalComponent} from './components/users/user-modal/user-modal.component';
import {NotificationsComponent} from './components/notifications/notifications.component';
import {NotificationContentComponent} from './components/notifications/notification-content/notification-content.component';
import {MessagesComponent} from './components/messages/messages.component';
import {
  EmployerRecruiterReviewPopupComponent
} from './components/employer/employer-recruiter/employer-recruiter-review-popup/employer-recruiter-review-popup.component';
import {EmailPreferencesComponent} from './components/recruiter-menu-settings/email-preferences/email-preferences.component';
import {SupportPageComponent} from './components/support-page/support-page.component';
import {StarRatingComponent} from './shared/components/star-rating/star-rating.component';
import {EditProfileComponent} from './components/edit-profile/edit-profile.component';
import {ChangePasswordComponent} from './components/general-user-screens/change-password/change-password.component';
import {DashboardNewsModalComponent} from './components/dashboard/dashboard-news-modal/dashboard-news-modal.component';
import {EditCandidateComponent} from './components/candidate/edit-candidate/edit-candidate.component';
import {MarketPurchaseModalComponent} from './components/market-purchase-modal/market-purchase-modal.component';
import {SupportComponent} from './components/support/support.component';
import {EarningPageComponent} from './components/earning-page/earning-page.component';
import {FullJobDescriptionModalComponent} from './shared/components/full-job-description-modal/full-job-description-modal.component';
import {ResetPasswordLoginComponent} from './components/general-user-screens/reset-password-login/reset-password-login.component';
import {TlbEmptyPageComponent} from './shared/components/tlb-empty-page/tlb-empty-page.component';
import {ApplicantsModalComponent} from './components/applications/applicants-modal/applicants-modal.component';
import {ViewSalaryModalComponent} from './components/job/view-salary-modal/view-salary-modal.component';
import {AdminAllEmployersComponent} from './components/admin-screens/admin-all-employers/admin-all-employers.component';
import {AdminAllJobsComponent} from './components/admin-screens/admin-all-jobs/admin-all-jobs.component';
import {AdminAllRecruitersComponent} from './components/admin-screens/admin-all-recruiters/admin-all-recruiters.component';
import {AdminDashboardComponent} from './components/dashboard/admin-dashboard/admin-dashboard.component';
import {AdminGuard} from './shared/services/auth/guards/admin.guard';
import {GlobalSearchComponent} from './shared/components/global-search/global-search.component';
import {
  AdminRecruiterDetailPopupComponent
} from './components/admin-screens/admin-all-recruiters/admin-recruiter-detail-popup/admin-recruiter-detail-popup.component';
import {AdminEmployerModalComponent} from './components/admin-screens/admin-employer-modal/admin-employer-modal.component';
import {AdminDeactivatePopupComponent} from './components/admin-screens/admin-deactivate-popup/admin-deactivate-popup.component';
import {EmployerJobDetailPopupComponent} from './shared/components/employer-job-detail-popup/employer-job-detail-popup.component';
import {GeneralYesNoPopupComponent} from './shared/components/general-yes-no-popup/general-yes-no-popup.component';
import {EmployerDashboardJobsComponent} from './shared/components/employer/employer-dashboard-jobs/employer-dashboard-jobs.component';
import {EditJobComponent} from './components/job/edit-job/edit-job.component';
import {CandidateDetailPopupComponent} from './shared/components/candidate-detail-popup/candidate-detail-popup.component';
import {
  CandidateUpdateStatusPopupComponent
} from './shared/components/candidate-update-status-popup/candidate-update-status-popup.component';
import {CancelApplicationModalComponent} from './components/applications/cancel-application-modal/cancel-application-modal.component';
import {DeleteUserPopupComponent} from './components/users/delete-user-popup/delete-user-popup.component';
import {
  CandidateReviewScreenComponent
} from './components/candidate-email-screens/candidate-review-screen/candidate-review-screen.component';
import {ApprovalEmailPageComponent} from './components/candidate-email-screens/approval-email-page/approval-email-page.component';
import {SubmitCandidateModalComponent} from './components/applications/submit-candidate-modal/submit-candidate-modal.component';
import {DashboardViewSalaryModalComponent} from './components/dashboard/dashboard-view-salary-modal/dashboard-view-salary-modal.component';
import {RecruiterEditProfileComponent} from './components/recruiter-menu-settings/recruiter-edit-profile/recruiter-edit-profile.component';
import {
  RecruiterEditBasicInfoComponent
} from './components/recruiter-menu-settings/recruiter-edit-basic-info/recruiter-edit-basic-info.component';
import {ResetPasswordComponent} from './components/general-user-screens/reset-password/reset-password.component';
import {TrackCandidatesFilterComponent} from './components/candidate/track-candidates-filter/track-candidates-filter.component';
import {TrackApplicationsFilterComponent} from './components/candidate/track-applications-filter/track-applications-filter.component';
import {RecruiterFiltersComponent} from './components/recruiter-filters/recruiter-filters.component';
import {SuccessfullySavedComponent} from './components/successfully-saved/successfully-saved.component';
import {TransformCapitalizeDirective} from './shared/directives/capitalize/transform-capitalize.directive';
import {TlbEmptyPageDashboardComponent} from './shared/components/tlb-empty-page-dashboard/tlb-empty-page-dashboard.component';
import {AdminFaqComponent} from './components/admin-screens/admin-faq/admin-faq.component';
import {NgxSortableModule} from 'ngx-sortable';
import {AdminAddEditModalComponent} from './components/admin-screens/admin-add-edit-modal/admin-add-edit-modal.component';
import {AuthService} from './shared/services/auth/auth.service';
import {
  EmployerDashboardFilterComponent
} from './components/dashboard/employer-dashboard/employer-dashboard-filter/employer-dashboard-filter.component';
import {
  EmployerDashboardActivityComponent
} from './components/dashboard/employer-dashboard/employer-dashboard-activity/employer-dashboard-activity.component';
import {
  EmployerDashboardBestRecruitersComponent
} from './components/dashboard/employer-dashboard/employer-dashboard-best-recruiters/employer-dashboard-best-recruiters.component';
import {
  RecruiterDashboardCandidateComponent
} from './components/dashboard/recruiter-dashboard/recruiter-dashboard-candidate/recruiter-dashboard-candidate.component';
import {LandingPageComponent} from './components/landing-page/landing-page.component';
import {LandingLayoutComponent} from './shared/layouts/landing-layout/landing-layout.component';
import {SingUpComponent} from './components/landing-page/sing-up/sing-up.component';
import {CompleteRegisterComponent} from './components/landing-page/sing-up/complete-register/complete-register.component';
import {PostAJobComponent} from './components/job/post-a-job/post-a-job.component';
import {SavedJobProfilesComponent} from './components/job/post-a-job/saved-job-profiles/saved-job-profiles.component';
import {IndustryJobProfilesComponent} from './components/job/post-a-job/industry-job-profiles/industry-job-profiles.component';
import {PricingComponent} from './components/landing-page/pricing/pricing.component';
import {MarketplaceComponent} from './components/marketplace/marketplace.component';
import {
  CompleteRegisterPopupComponent
} from './components/landing-page/sing-up/complete-register/complete-register-popup/complete-register-popup.component';
import {
  ComplateRecruiterRegisterComponent
} from './components/landing-page/sing-up/complete-register/complate-recruiter-register/complate-recruiter-register.component';
import {TlbFormComponent} from './shared/components/tlb-form/tlb-form.component';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {LeaderboardComponent} from './components/leaderboard/leaderboard.component';
import {
  AdminJobProfileDetailComponent
} from './components/admin-screens/admin-job-profiles/admin-job-profile-detail/admin-job-profile-detail.component';
import {AdminJobProfilesComponent} from './components/admin-screens/admin-job-profiles/admin-job-profiles.component';
import {ScrollService} from './shared/services/general/scroll.service';
import {LoginRegisterHeaderComponent} from './shared/components/login-register-header/login-register-header.component';
import {MyJobsFilterComponent} from './components/job/my-jobs-filter/my-jobs-filter.component';
import {EmployerJobListComponent} from './shared/components/employer/employer-job-list/employer-job-list.component';
import {CreateJobProfileComponent} from './components/job/create-job-profile/create-job-profile.component';
import {PushNotificationComponent} from './shared/push-notification/push-notification.component';
import {BillingComponent} from './components/billing/billing.component';
import {CreditCardComponent} from './components/landing-page/credit-card/credit-card.component';
import {DeletePopupComponent} from './shared/delete-popup/delete-popup.component';
import {DashboardActivityComponent} from './components/dashboard-activity/dashboard-activity.component';
import {SuccessPageComponent} from './shared/components/success-page/success-page.component';
import {BrowseJobsComponent} from './components/browse-jobs/browse-jobs.component';
import {SearchJobsFilterComponent} from './components/job/search-jobs/search-jobs-filter/search-jobs-filter.component';
import {ProfileEnrichmentService} from './shared/services/user/profile-enrichment-service';
import {FirstLetterCapitalPipe} from './shared/pipes/first-letter-capital/first-letter-capital.pipe';
import {
  AdminJobProfilesListComponent
} from './components/admin-screens/admin-job-profiles/admin-job-profiles-list/admin-job-profiles-list.component';
import {AdminJobsFilterComponent} from './components/admin-screens/admin-all-jobs/admin-jobs-filter/admin-jobs-filter.component';
import {TalentbaseCreditsComponent} from './components/talentbase-credits/talentbase-credits.component';
import {RecruitersDetailModalComponent} from './components/marketplace/recruiters-detail-modal/recruiters-detail-modal.component';
import {HttpConfigInterceptor} from './shared/interceptors/http-config-interceptor';
import {GeneralDeleteModalComponent} from './shared/components/general-delete-modal/general-delete-modal.component';
import {AdminReviewsComponent} from './components/admin-screens/admin-reviews/admin-reviews.component';
import {AdminReviewModalComponent} from './components/admin-screens/admin-reviews/admin-review-modal/admin-review-modal.component';
import {
  AdminEmployerDetailModalComponent
} from './components/admin-screens/admin-all-employers/admin-employer-detail-modal/admin-employer-detail-modal.component';
import {CheckoutComponent} from './components/dashboard/checkout/checkout.component';
import {FinishPaymentComponent} from './components/finish-payment/finish-payment.component';
import {RecruiterDetailsComponent} from './components/marketplace/recruiter-details/recruiter-details.component';
import {AddEditApplicationNoteComponent} from './components/applications/add-edit-application-note/add-edit-application-note.component';
import {AdminPacketsComponent} from './components/admin-screens/admin-packets/admin-packets.component';
import {
  AdminPacketsBussinesFunctionsComponent
} from './components/admin-screens/admin-packets/admin-packets-bussines-functions/admin-packets-bussines-functions.component';
import {AdminPacketEditComponent} from './components/admin-screens/admin-packets/admin-packet-edit/admin-packet-edit.component';
import {TranslateFromLocalizationsPipe} from './shared/pipes/translate/translate-from-localizations.pipe';
import {RecruiterListComponent} from './components/job/post-a-job/create-new-job-profile/recruiter-list/recruiter-list.component';
import {PaymentsComponent} from './components/payments/payments.component';
import {PaymentPopupComponent} from './components/payments/payment-popup/payment-popup.component';
import {SidebarMenuComponent} from './shared/components/sidebar/sidebar-menu/sidebar-menu.component';
import {BaseLayoutComponent} from './shared/layouts/base-layout/base-layout.component';
import {HeaderNotificationsComponent} from './shared/components/header/header-notifications/header-notifications.component';
import {JobsInvitedComponent} from './components/job/jobs-invited/jobs-invited.component';
import {RecruiterAllFiltersComponent} from './components/marketplace/recruiter-all-filters/recruiter-all-filters.component';
import {ConfirmationModalComponent} from './shared/components/confirmation-modal/confirmation-modal.component';
import {DeleteModalComponent} from './shared/components/delete-modal/delete-modal.component';
import {ApplicationsComponent} from './components/applications/applications.component';
import {ApplicationAllFiltersComponent} from './components/applications/application-all-filters/application-all-filters.component';
import {ApplicationDetailComponent} from './components/applications/application-detail/application-detail.component';
import {
  ApplicationDetailNoteDeleteModalComponent
} from './components/applications/application-detail/application-detail-note-delete-modal/application-detail-note-delete-modal.component';
import {
  ApplicationDetailAgreedSalaryAmountModalComponent
} from './components/applications/application-detail/application-detail-agreed-salary-amount-modal/application-detail-agreed-salary-amount-modal.component';
import {FinishEarningPaymentComponent} from './components/finish-payment/finish-earning-payment/finish-earning-payment.component';
import {RelatedJobsModalComponent} from './shared/components/recruiter/related-jobs-modal/related-jobs-modal.component';
import localeTr from '@angular/common/locales/tr';

registerLocaleData(localeTr);

@NgModule({
            declarations: [
              AppComponent,
              LoginLayoutComponent,
              DashboardLayoutComponent,
              DashboardComponent,
              LoginComponent,
              HeaderComponent,
              FooterComponent,
              SidebarComponent,
              TranslatePipe,
              MainHeaderComponent,
              TlbDropdownComponent,
              MailVerifyComponent,
              DashboardQuarterLineCartComponent,
              AddNewCandidateComponent,
              CandidateLayoutComponent,
              TrackCandidatesComponent,
              TrackApplicationsComponent,
              EmployerDashboardComponent,
              ToUpperCaseDirective,
              RecruiterDashboardComponent,
              SearchJobsComponent,
              JobsAppliedComponent,
              RecruiterJobsComponent,
              EmployerJobsComponent,
              MyJobsComponent,
              MyjobModalComponent,
              TlbMultiselectDropdownComponent,
              JobsFilterComponent,
              EmployerRecruiterComponent,
              UsersComponent,
              UserModalComponent,
              NotificationsComponent,
              NotificationContentComponent,
              EditProfileComponent,
              MessagesComponent,
              SupportPageComponent,
              StarRatingComponent,
              RecruiterEditBasicInfoComponent,
              ChangePasswordComponent,
              EmployerRecruiterReviewPopupComponent,
              RecruiterEditProfileComponent,
              EmailPreferencesComponent,
              DashboardNewsModalComponent,
              EditCandidateComponent,
              ResetPasswordComponent,
              MarketPurchaseModalComponent,
              TalentbaseCreditsComponent,
              SupportComponent,
              EarningPageComponent,
              FullJobDescriptionModalComponent,
              GlobalSearchComponent,
              ResetPasswordLoginComponent,
              TlbEmptyPageComponent,
              ApplicantsModalComponent,
              ViewSalaryModalComponent,
              DashboardViewSalaryModalComponent,
              AdminAllJobsComponent,
              AdminAllRecruitersComponent,
              AdminAllEmployersComponent,
              AdminDashboardComponent,
              AdminRecruiterDetailPopupComponent,
              AdminEmployerModalComponent,
              AdminDeactivatePopupComponent,
              EmployerJobDetailPopupComponent,
              GeneralYesNoPopupComponent,
              EmployerDashboardJobsComponent,
              EditJobComponent,
              SubmitCandidateModalComponent,
              CancelApplicationModalComponent,
              CandidateDetailPopupComponent,
              CandidateUpdateStatusPopupComponent,
              DeleteUserPopupComponent,
              CandidateReviewScreenComponent,
              ApprovalEmailPageComponent,
              TrackCandidatesFilterComponent,
              TrackApplicationsFilterComponent,
              RecruiterFiltersComponent,
              SuccessfullySavedComponent,
              TransformCapitalizeDirective,
              TlbEmptyPageDashboardComponent,
              AdminFaqComponent,
              AdminAddEditModalComponent,
              EmployerDashboardFilterComponent,
              EmployerDashboardActivityComponent,
              EmployerDashboardBestRecruitersComponent,
              RecruiterDashboardCandidateComponent,
              LandingPageComponent,
              LandingLayoutComponent,
              SingUpComponent,
              CompleteRegisterComponent,
              PostAJobComponent,
              CreateNewJobProfileComponent,
              SavedJobProfilesComponent,
              IndustryJobProfilesComponent,
              PricingComponent,
              MarketplaceComponent,
              CompleteRegisterPopupComponent,
              ComplateRecruiterRegisterComponent,
              TlbFormComponent,
              LeaderboardComponent,
              AdminJobProfileDetailComponent,
              AdminJobProfilesComponent,
              LoginRegisterHeaderComponent,
              MyJobsFilterComponent,
              EmployerJobListComponent,
              CreateJobProfileComponent,
              PushNotificationComponent,
              BillingComponent,
              CreditCardComponent,
              DeletePopupComponent,
              DashboardActivityComponent,
              SuccessPageComponent,
              BrowseJobsComponent,
              SearchJobsFilterComponent,
              FirstLetterCapitalPipe,
              AdminJobProfilesListComponent,
              AdminJobsFilterComponent,
              RecruitersDetailModalComponent,
              GeneralDeleteModalComponent,
              AdminReviewsComponent,
              AdminReviewModalComponent,
              AdminEmployerDetailModalComponent,
              CheckoutComponent,
              FinishPaymentComponent,
              RecruiterDetailsComponent,
              AddEditApplicationNoteComponent,
              AdminPacketsComponent,
              AdminPacketsBussinesFunctionsComponent,
              AdminPacketEditComponent,
              TranslateFromLocalizationsPipe,
              RecruiterListComponent,
              PaymentsComponent,
              PaymentPopupComponent,
              SidebarMenuComponent,
              BaseLayoutComponent,
              HeaderNotificationsComponent,
              JobsInvitedComponent,
              RecruiterAllFiltersComponent,
              ConfirmationModalComponent,
              DeleteModalComponent,
              RecruiterAllFiltersComponent,
              ApplicationsComponent,
              ApplicationAllFiltersComponent,
              ApplicationDetailComponent,
              ApplicationDetailNoteDeleteModalComponent,
              ApplicationDetailAgreedSalaryAmountModalComponent,
              FinishEarningPaymentComponent,
              RelatedJobsModalComponent
            ],
            imports: [
              BrowserModule,
              AppRoutingModule,
              HttpClientModule,
              SidebarModule,
              NgxSpinnerModule,
              FormsModule,
              ReactiveFormsModule,
              CommonModule,
              BrowserAnimationsModule,
              NgxPaginationModule,
              NgxSortableModule,
              NgMultiSelectDropDownModule.forRoot(),
              OAuthModule.forRoot({
                                    resourceServer: {
                                      allowedUrls: ['http://ec2-52-208-151-88.eu-west-1.compute.amazonaws.com:8080/uaa/api/v1'],
                                      sendAccessToken: true
                                    }
                                  })
            ],
            providers: [AuthService, JobService, NotificationService, TitleCasePipe, TranslatePipe, TranslateService, TRANSLATION_PROVIDERS,
              AuthGuard, LoginGuard, OAuthService, EmployerGuard, AdminGuard, RecruiterGuard, ScrollService, {
                provide: OAuthStorage,
                useValue: localStorage
              },
              ProfileEnrichmentService,
              { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
              DatePipe,
              { provide: LOCALE_ID, useValue: 'tr-TR' },
            ],
            bootstrap: [AppComponent]
          })

export class AppModule {
}
