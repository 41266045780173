import { AuthService } from './../../../shared/services/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { LoginModel } from '../../../shared/models/login/login.model';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { TranslatePipe } from '../../../shared/pipes/translate/translate.pipe';
import { TlbFormModel } from 'src/app/shared/models';
import { FormType } from 'src/app/shared/enums';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  loginUser: LoginModel;
  loginError = false;
  generalError = false;
  formModel: TlbFormModel[] = [];
  formGroup: FormGroup;
  model: any = {};
  errorMessage: string = "";

  constructor(private oauthService: OAuthService, private spinner: NgxSpinnerService, private notification: NotificationService,
    private translate: TranslatePipe, private authService: AuthService, private router: Router) {
    this.loginUser = new LoginModel();
  }

  ngOnInit(): void {

    this.createForm();
  }

  createForm() {

    this.formModel = [{
      class: 'col-12', components: [
        { label: 'email', control: 'email', required: true, type: FormType.email, style:"border: none; border-bottom: 1px solid gray; border-radius: 0;" },
        { label: 'password', control: 'password', required: true, type: FormType.password, style:"border: none; border-bottom: 1px solid gray; border-radius: 0;" },
      ]
    }];

    this.formGroup = new FormGroup({
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    });
  }


  get form() { return this.formGroup.controls }


  checkInputsFilled(): boolean {
    return !((this.form.email.value).trim() === '' || (this.form.password.value).trim() === '');
  }
  get token(): string {
    return this.oauthService.getAccessToken();
  }
  setCompanyId() {
    const companyId = JSON.parse(window.atob((this.token).split('.')[1])).ats_company_id;
    localStorage.setItem('companyId', companyId);
  }
  setRole() {
    const role = JSON.parse(window.atob((this.token).split('.')[1])).role;
    localStorage.setItem('role', role);
  }

  setMyUserId() {
    const userId = JSON.parse(window.atob((this.token).split('.')[1])).sub;
    localStorage.setItem('userId', userId);
  }
  login() {
    this.spinner.show();
    this.loginError = false;
    this.generalError = false;
    if (!this.checkInputsFilled()) {
      this.notification.error(this.translate.transform('loginErrorInfo', []));
      this.spinner.hide();
      return;
    }
    localStorage.removeItem(this.authService.TOKEN_KEY);
    this.oauthService.fetchTokenUsingPasswordFlow(
      this.form.email.value.trim(),
      this.form.password.value
    )
      .then(x => {
        if (this.token) {
          this.errorMessage = "";
          // this.authService.startRefreshTokenTimer();
          this.setRole();
          this.authService.getUserPermissionsFromEndpoint();
          //this.router.navigate(['/dashboard']);
        } else {
          this.authService.ServerError('');
        }
        this.spinner.hide();
        // this.oauthService.setupAutomaticSilentRefresh();
      })
      .catch(err => {
        this.errorMessage = this.translate.transform(err.error.code);

        if (err.error.code === 'INVALID_GRANT') {
          this.loginError = true;
        } else {
          this.generalError = true;
        }
        this.authService.ServerError(err);
        this.spinner.hide();
      });
  }


  goRegister(){
    this.router.navigateByUrl('sign-up');
  }

  forgotPassword(){
    this.router.navigate(['/password-reset-login']);

  }



}
