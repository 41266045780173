export class UserCountsModel {

  totalUsers: number;
  totalUsersSinceLastMonth: number;

  constructor() {
    this.totalUsers = 0;
    this.totalUsersSinceLastMonth = 0;
  }
}
