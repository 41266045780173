import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

  @Input() Title: '';
  @Input() Text: '';
  @Output() selection = new EventEmitter();
  @Input() ImageSrc: './../../../assets/images/talentbase/delete-icon.png';

  constructor() {  }

  ngOnInit(): void {
  }

  selectYesOrNo(flag){
    this.selection.emit(flag);
  }

}
