import { DefaultLocalization } from "./default-localization.model";

export class DefaultModel {
    id: string;
    positions: number;
    localizations: any;
    defaultLocalization: DefaultLocalization;

    constructor() {
        this.id = '';
        this.positions = 0;
        this.localizations = <any>{};
        this.defaultLocalization = new DefaultLocalization();
    }
}
