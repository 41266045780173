import { Component, OnInit } from '@angular/core';
import { CreateAvatar, RequestPayload } from 'src/app/shared/classes';
import { RecruiterUserModel } from 'src/app/shared/models/recruiter/recruiter-user.model';
import { RecruiterService, TopRecruiterService } from 'src/app/shared/services';

@Component({
  selector: 'app-employer-dashboard-best-recruiters',
  templateUrl: './employer-dashboard-best-recruiters.component.html',
  styleUrls: ['./employer-dashboard-best-recruiters.component.scss']
})
export class EmployerDashboardBestRecruitersComponent implements OnInit {

  requestPayload: RequestPayload;
  recruiters: any[] = [];
  star: number = 256;
  prevDisabled: boolean = true;
  nextDisabled: boolean = false;
  totalCount: number = 0;
  page: number = 1;
  createAvatar: CreateAvatar;

  constructor(private topRecruiterService: TopRecruiterService, private rec: RecruiterService) {
    this.requestPayload = new RequestPayload();
    this.requestPayload.limit = 4;
    this.createAvatar = new CreateAvatar();
   }

  ngOnInit() {
    this.getRecruiters(1);
  }

  getRecruiters(pageNo) {
    this.page = pageNo;
    this.topRecruiterService.ServerGetWithText(this.requestPayload.payloadURL(pageNo) + "&lastWeek=false")
      .subscribe(x => {
        this.recruiters = x.content;
        this.totalCount = x.totalElements;
        this.recruiters.forEach(f => {
          this.star -=50;
          f.star = this.star;
          f.imageText = this.createAvatar.create(f.recruiter.user.fullName);
          f.imageColor = this.createAvatar.color();
        });
      });

  }

}
