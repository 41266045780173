import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../auth';
import { server } from '../server';


@Injectable({
  providedIn: 'root'
})
export class ActivitiesService extends ApiService {

  constructor(public http: HttpClient, public spinner: NgxSpinnerService, public translate: TranslatePipe) {
    super(http, server.notifications, spinner, translate)
  }
}
