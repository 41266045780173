<div class="row mt-3 mx-auto">

  <div class="col-12 px-0 res ">
    <div class="card " style="border: 1px solid #C8D4DF;">
      <div class="card-body  ">

        <div
          *ngFor="let job of jobs | paginate:{ itemsPerPage: requestPayload.limit, currentPage: currentPage,totalItems:count}">
          <div
            [ngStyle]="{'border-bottom': job != jobs[jobs.length - 1] ?  '1px solid rgba(180, 182, 197, 0.38)' : 'none'}">

            <div class="container-fluid mt-4 mb-4">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-1 mb-3 mb-sm-0"><img
                    [src]="job.logoUrl && job.logoUrll != '' ? job.logoUrl : './assets/images/default-logo.png'" alt=""
                    width="45"></div>
                <div class="col-12 col-sm-6 col-md-8 col-lg-10 col-xl-11">
                  <div class="row mt-2 ml-2">
                    <div class="emp-name-text">{{job.employer.name}}</div>
                    <div class="ml-2 loc-text row"> <i class="fas fa-circle mt-2" style="font-size: 4px;"></i>
                      <div class="ml-2" style="margin-top: 1px">{{job.location?.city?.name}}, {{job.location?.country?.name}}</div>
                      </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-12" >
                  <div class="row mt-2 title-text">
                    <div class="col-12">{{job.title}}</div>
                  </div>

                  <div class="row mt-1 mb-3 desc-text">
                    <div class="col-12">{{job.description}}</div>
                  </div>
                </div>
                <div class="col-xl-6 col-12">
                  <div class="row">
                    <div class="col-md-4 col-xs-4  col-sm-4  my-xs-2" style="text-align: -webkit-center; border-right: 1px solid #EBEEF2;">
                      <div class="right-text d-xl-inline-block d-sm-block">{{'pendingReview'|translate}}</div>
                      <div class="right-value-text right-circle" style="cursor: pointer;" (click)="openApplicantsModal(job.applications, job)">
                        <b class="pending-reviews1">{{job.pendingReview}}</b>
                      </div>
                    </div>
                    <div class="col-md-4  col-xs-4 col-sm-4  my-xs-2" style="border-right: 1px solid #EBEEF2;">
                      <div class="right-text d-xl-inline-block d-sm-block">
                        {{'underConsideration'|translate}}
                      </div>

                      <div class="right-value-text">
                        <b class="pending-reviews">{{job.underConsideration}}</b>
                      </div>

                    </div>
                    <div class="col-md-4 col-xs-4 col-sm-4 my-xs-2">
                      <div class="right-text d-xl-inline-block d-sm-block">
                        {{'TotalApplications'|translate}}
                      </div>

                      <div class="right-value-text">
                        <b class="pending-reviews">{{job.totalApplications}}</b>
                      </div>

                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>

        </div>

      </div>

    </div>

  </div>
</div>


<div class="row mt-3 pagination">
  <pagination-controls (pageChange)="currentPage = $event;pagination(currentPage)"
    [autoHide]="count <= requestPayload.limit" nextLabel="{{'next' | translate}}"
    previousLabel="{{'previous' | translate}}" responsive="true"></pagination-controls>
</div>

<app-applicants-modal [applications]="applications" [job]="selectedJob"
  (openUpdateCandidateStatusEmitter)="openUpdateCandidateStatus($event)"
  (openCandidateDetailPopupEmitter)="openCandidateDetailPopup($event)"></app-applicants-modal>

<app-candidate-update-status-popup [application]="selectedApplication" (updatedCandidateStatus)="updateStatus()">
</app-candidate-update-status-popup>
<app-candidate-detail-popup [application]="selectedApplication" [job]="selectedJob"
  (openUpdateCandidateStatusEmitter)="openUpdateCandidateStatus($event)" (openReviewsEmitter)="openReviewModal($event)">
</app-candidate-detail-popup>
