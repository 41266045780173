import {Component, Input, OnInit} from '@angular/core';
import { JobService } from 'src/app/shared/services';

@Component({
  selector: 'app-view-salary-modal',
  templateUrl: './view-salary-modal.component.html',
  styleUrls: ['./view-salary-modal.component.scss']
})
export class ViewSalaryModalComponent implements OnInit {
  @Input() selectedJobs;
  @Input() modalId: string = "viewSalaryModal";
  @Input() detailModalId: string = "viewSalaryModals";

  constructor() { }

  ngOnInit(): void {
  }


  openModalDashboard() {

    // @ts-ignore
    $('#' + this.detailModalId).modal('show');
  }
}
