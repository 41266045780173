import { Component, OnInit } from '@angular/core';
import { RecruiterService, ReviewService, TranslatePipe } from 'src/app/shared/services';
import { RecruiterUserModel } from 'src/app/shared/models/recruiter/recruiter-user.model';
import { RequestPayload } from '../../../shared/classes';

@Component({
  selector: 'app-employer-recruiter',
  templateUrl: './employer-recruiter.component.html',
  styleUrls: ['./employer-recruiter.component.scss']
})

export class EmployerRecruiterComponent implements OnInit {

  recruiters: RecruiterUserModel[] = [];
  reviews: any[] = [];
  requestPayload: RequestPayload;
  currentPage: number;
  count: number;
  filterData: any = {};
  storedItems: RecruiterUserModel[] = [];

  constructor(private translate: TranslatePipe, private recruiterService: RecruiterService, private reviewService: ReviewService) {
    this.filterData.individualPracticeTime = "";
    this.filterData.requisitionType = "";
    this.filterData.functionalRoleId = "";
    this.filterData.industryId = "";
    this.requestPayload = new RequestPayload();
    this.requestPayload.limit = 10;
  }

  ngOnInit(): void {

    this.reviews = [];
    this.recruiters = [];
    this.getRecruiters(1);
  }

  getRecruiters(pageNo) {

    this.recruiterService.ServerGetWithText(this.requestPayload.payloadURL(pageNo) + this.requestFilter() + '&filter=status::ACTIVE')
      .subscribe(x => {
        this.recruiters = x.content;
        this.storedItems = this.recruiters;
        this.count = x.totalElements;
        this.recruiters.forEach(f => {
          this.reviewService.ServerGetWithText('/?recruiterId=' + f.id).subscribe(x => {
            f.reviews = x.content;
            f.reviews.forEach(x => {
              x.date = new Date(x.createdAt).toLocaleDateString();
            })
            const sum = x.content.reduce((a, b) => a + b.rating, 0);
            const avg = (sum / x.content.length) || 0;
            f.reviewCount = x.totalElements;
            f.star = avg;
            f.functionalRolesText = f.functionalRoles.map(m => ' ' + m.name).toString();
            f.industriesText = f.industries.map(m => ' ' + m.name).toString();
            f.requisitionTypesText = f.requisitionTypes.map(m => ' ' + this.translate.transform(m + 'REC')).toString();

          });
        });
      });

  }

  requestFilter() {

    return '&filter=individualPracticeTime**' + this.filterData.individualPracticeTime +
      '&filter=requisitionTypes.requisitionType**' + this.filterData.requisitionType +
      '&filter=functionalRoles.functionalRoleId**' + this.filterData.functionalRoleId +
      '&filter=industries.industryId**' + this.filterData.industryId
  }

  openReviewModal(reviews) {
    this.reviews = reviews;

    // @ts-ignore
    $('#review-popup').modal('show');
  }

  getFilteredRec(e) {

    this.filterData = e;
    this.getRecruiters(1);

  }
  clearFilter(e) {
    this.filterData = e;
    this.getRecruiters(1);
  }

  search(data) {

    if (data == "") this.getRecruiters(1);
    // tslint:disable-next-line:max-line-length
    this.recruiters = ((this.storedItems).filter((item: RecruiterUserModel) => ((item.user.fullName).toLowerCase().trim()).indexOf(data.toLowerCase().trim()) > -1));
  }

}
