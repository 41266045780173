export class CreateAvatar {

  create(name) {
    let array = name.split(' ');
    return array.length > 0 ? array[0].charAt(0).toLocaleUpperCase() + array[array.length - 1].charAt(0).toLocaleUpperCase() : array[0].charAt(0).toLocaleUpperCase();
  }

  color(){
    let colors = ['#E1CCFB', '#6D52C0', '#373D57', '#2E643E', '#D4CD67', '#AD9639', '#EF865D', '#E84125'];
    return colors[Math.floor(Math.random() * colors.length)];
  }

}
