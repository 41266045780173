export const SIDEBAR_MENU = {
  recruiter: [
    {
      routerLink: '/dashboard',
      routerLinkActive: null,
      iconSrc: '/assets/images/talentbase/sidemenü-icon/dashboard-icon.png',
      translateTextKey: 'dashboard'
    },
    {
      routerLink: null,
      routerLinkActive: null,
      iconSrc: '/assets/images/talentbase/sidemenü-icon/candidates-icon.png',
      translateTextKey: 'candidates',
      subMenu: [
        {
          routerLink: '/candidate/track-candidate',
          routerLinkActive: null,
          iconSrc: null,
          translateTextKey: 'trackCandidates'
        },
        {
          routerLink: '/candidate/track-application',
          routerLinkActive: null,
          iconSrc: null,
          translateTextKey: 'trackApplications'
        }
      ]
    },
    {
      routerLink: '/dashboard/browse-jobs',
      routerLinkActive: null,
      iconSrc: '/assets/images/talentbase/sidemenü-icon/browse--jobs-icon.png',
      translateTextKey: 'jobs'
    },
    {
      routerLink: '/dashboard/talentbase-credits',
      routerLinkActive: null,
      iconSrc: '/assets/images/talentbase/sidemenü-icon/credits-icon.png',
      translateTextKey: 'picktusCredits'
    },
    {
      routerLink: '/dashboard/earning',
      routerLinkActive: null,
      iconSrc: '/assets/images/talentbase/sidemenü-icon/earnings-icon.png',
      translateTextKey: 'earnings'
    },
    {
      routerLink: '/dashboard/users/0',
      routerLinkActive: null,
      iconSrc: '/assets/images/talentbase/sidemenü-icon/user-icon.png',
      translateTextKey: 'users'
    },
    {
      routerLink: '/support',
      routerLinkActive: null,
      iconSrc: '/assets/images/talentbase/sidemenü-icon/support-icon.png',
      translateTextKey: 'support'
    }
  ],
  employer: [
    {
      routerLink: '/dashboard',
      routerLinkActive: null,
      iconSrc: '/assets/images/talentbase/sidemenü-icon/dashboard-icon.png',
      translateTextKey: 'dashboard'
    },
    {
      routerLink: '/dashboard/my-jobs',
      routerLinkActive: null,
      iconSrc: '/assets/images/talentbase/sidemenü-icon/myjobs-icon.png',
      translateTextKey: 'myJobs'
    },
    {
      routerLink: '/dashboard/applications',
      routerLinkActive: null,
      iconSrc: '/assets/images/talentbase/sidemenü-icon/identification.png',
      translateTextKey: 'applications',
      routerLinkActiveOptions: false
    },
    {
      routerLink: '/recruiters',
      routerLinkActive: null,
      iconSrc: '/assets/images/talentbase/sidemenü-icon/recruiter-icon.png',
      translateTextKey: 'recruiters'
    },
    {
      routerLink: '/dashboard/leaderboard',
      routerLinkActive: null,
      iconSrc: null,
      iconClass: 'fas fa-chart-line',
      translateTextKey: 'leaderboard'
    },
    {
      routerLink: '/dashboard/users/1',
      routerLinkActive: null,
      iconSrc: '/assets/images/talentbase/sidemenü-icon/user-icon.png',
      translateTextKey: 'users'
    },
    {
      routerLink: '/dashboard/payments',
      routerLinkActive: null,
      iconSrc: '/assets/images/talentbase/sidemenü-icon/earnings-icon.png',
      translateTextKey: 'payments'
    },
    {
      routerLink: '/support',
      routerLinkActive: null,
      iconSrc: '/assets/images/talentbase/sidemenü-icon/support-icon.png',
      translateTextKey: 'support'
    }
  ],
  admin: [
    {
      routerLink: '/dashboard',
      routerLinkActive: null,
      iconSrc: null,
      iconClass: 'fas fa-home pr-3',
      translateTextKey: 'dashboard'
    },
    {
      routerLink: '/all-jobs',
      routerLinkActive: null,
      iconSrc: null,
      iconClass: 'fas fa-suitcase pr-3',
      translateTextKey: 'allJobs'
    },
    {
      routerLink: '/all-recruiters',
      routerLinkActive: null,
      iconSrc: null,
      iconClass: 'fas fa-users pr-3',
      translateTextKey: 'allRecruiters'
    },
    {
      routerLink: '/all-employers',
      routerLinkActive: null,
      iconSrc: null,
      iconClass: 'fas fa-user-friends pr-3',
      translateTextKey: 'allEmployers'
    },
    {
      routerLink: '/admin-faq',
      routerLinkActive: null,
      iconSrc: null,
      iconClass: 'fas fa-question-circle pr-3',
      translateTextKey: 'faqMenu'
    },
    {
      routerLink: '/admin-job-profiles',
      routerLinkActive: null,
      iconSrc: null,
      iconClass: 'far fa-list-alt pr-3',
      translateTextKey: 'jobProfiles'
    },
    {
      routerLink: '/admin-reviews',
      routerLinkActive: null,
      iconSrc: null,
      iconClass: 'far fa-comments pr-3',
      translateTextKey: 'recruiterReviews'
    },
    {
      routerLink: '/admin-packages',
      routerLinkActive: null,
      iconSrc: null,
      iconClass: 'fas fa-money-bill pr-3',
      translateTextKey: 'packages'
    }
  ]
};
