<div class="px-xl-5 pb-5">
  <div class="container-fluid mt-4">
    <span class="home-text">{{ 'candidates' | translate }}</span> <span class="user-text">
    <i class="fas fa-chevron-right ml-3 mr-2"></i> {{ 'trackCandidates' | translate }} </span>
    <div class="row">
      <div class="col-12">
        <app-track-candidates-filter (resultSort)="getSortedCandidate($event)"
                                     (resultFilter)="getFilteredCandidate($event)"></app-track-candidates-filter>
      </div>

    </div>

    <div class="row pt-3 overflow-auto">
      <div class="col-12">
        <table class="table"
               style="background-color: #fff;min-width: 900px">
          <thead>
          <tr>

            <th scope="col "><span class="table-headers">{{ 'image' | translate }}</span> </th>
            <th scope="col"><span class="table-headers">{{ 'fullName' | translate }}</span>
            </th>
            <th scope="col"><span class="table-headers">{{ 'skills' | translate }}</span> </th>
            <th scope="col"> <span class="table-headers">{{ 'phoneNumber' | translate }}</span> </th>
            <th scope="col"><span class="table-headers"> {{ 'actions' | translate }} </span></th>
          </tr>
          </thead>
          <tbody *ngFor="let candidate of trackCandidate | paginate:{ itemsPerPage: requestPayload.limit, currentPage: currentPage,totalItems:count}">
          <tr>

            <td>
              <div [style.background-color]="candidate.imageColor"
                   id="preimage"><span style="color: white;">{{candidate.imageText}}</span></div>
            </td>
            <td>
              <span class="name-text"> {{candidate.firstName}} {{candidate.lastName}}</span>

              <span class="email-style">{{candidate.email}}</span>
            </td>
            <td class="skills-text">
              <span *ngFor="let skill of candidate.skillTags.slice(0, 3);let last = last">{{skill.name | firstLetterCapital}} <span *ngIf="!last">,</span>
              </span>
            </td>
            <td class="phone-text">{{candidate.phoneNumber.countryCallingCode}} {{candidate.phoneNumber.phoneNumber}}</td>
            <td>
              <div class="row">
                  <span *ngIf="candidate.status!='DELETED'"
                        (click)="openEditCandidate(candidate)"
                        class="mr-2">
                    <img class="cursor-pointer"
                         src="./../../../../assets/images/talentbase/edit-button.svg">
                  </span>
                <span *ngIf="candidate.status!='DELETED'"
                      (click)="openDeleteCandidate(candidate)"
                      class="mr-2">
                    <img class="cursor-pointer"
                         src="./../../../../assets/images/talentbase/delete-button.svg">
                  </span>
                <span (click)="openApplications(candidate)">
                    <img class="cursor-pointer"
                         src="./../../../../assets/images/talentbase/view-button.svg">
                  </span>
              </div>


            </td>
          </tr>

          </tbody>
        </table>
      </div>
    </div>
    <tlb-empty-page *ngIf="!trackCandidate || trackCandidate.length == 0"
    [warningLabel]="filterTextInput == ''  ? 'noCandidateYet' : filterTextInput" [filterText]="filterTextInput"></tlb-empty-page>

    <div>

    </div>
  </div>
</div>

<app-edit-candidate [candidateModel]="editCandidateModel"
                    [modalId]="'editCandidateModalFromCandidates'"
                    (updateCandidate)="getCandidates(1)"></app-edit-candidate>

<app-applicants-modal [applications]="applications"
                      [recruiter]="true"
                      (refreshDataEmitter)="openApplications(selectedCandidate)"></app-applicants-modal>

<general-delete-modal [Title]="'deleteCandidate'"
                      [Text]="'theCandidateWillBeRemovedFromYourListAreYouSure'"
                      (selection)="deleteCandidate($event)"></general-delete-modal>

<div class="row mt-3 pagination">
  <pagination-controls (pageChange)="currentPage = $event; getCandidates(currentPage)"
                       [autoHide]="count <= requestPayload.limit"
                       nextLabel="{{'next' | translate}}"
                       previousLabel="{{'previous' | translate}}"
                       responsive="true"></pagination-controls>
</div>
