import { Component, OnInit } from '@angular/core';
import { NotificationsModel } from 'src/app/shared/models';
import { ApiNotificationService, TranslatePipe } from 'src/app/shared/services';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  notifications: NotificationsModel[] = [];
  selectedNotification: NotificationsModel;

  constructor(private apiNotificationService: ApiNotificationService, private translate: TranslatePipe) {

  }

  ngOnInit(): void {

    this.getNotifications();
    this.changeContent(1);

  }

  getNotifications() {
    this.apiNotificationService.ServerGet().subscribe(x => {
      this.notifications = x.content;
      this.notifications.forEach(f => {
        f.title = this.translate.transform("update") + (this.notifications.indexOf(f) + 1).toString();
        let time = Math.abs(new Date().getTime() - new Date(f.createdAt).getTime());
        f.day = Math.ceil(time / (1000 * 3600 * 24)).toString();
      });

    });
  }

  changeContent(notification) {
    this.selectedNotification = notification;
  }

}
