import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import { ApiService } from '../auth';
import { server } from '../server';
import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
    providedIn: 'root'
})

export class CurrencyService extends ApiService{

  constructor(public http: HttpClient,public spinner:NgxSpinnerService,public translate:TranslatePipe, public notification:NotificationService) {
      super(http, server.currencies,spinner,translate,notification)
  }
}
