import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {OAuthService} from 'angular-oauth2-oidc';

@Injectable()
export class LoginGuard implements CanActivate {
    constructor(private router: Router, private oauthService: OAuthService) {
    }

    canActivate() {

        if (localStorage.getItem('access_token')) {
            this.router.navigate(['/dashboard']);
            return false;
        } else {
            return true;
        }
    }
}
