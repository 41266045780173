import {GeneralIdNameModel} from '../general';

export class EarningsModel {

  id: string;
  job: GeneralIdNameModel;
  candidate: GeneralIdNameModel;
  employer: GeneralIdNameModel;
  currency: GeneralIdNameModel| null;
  fee: number;
  paymentStatus: string;
  placedAt: string;
  inGuarantee: string;
  placedAtDate: string;
  inGuaranteeDate: string | Date;
  classIcon: string;
  classText: string;
  imageText: string;
  imageColor: string;

  constructor() {
    this.id = '';
    this.job = new GeneralIdNameModel();
    this.candidate = new GeneralIdNameModel();
    this.employer = new GeneralIdNameModel();
    this.currency = new GeneralIdNameModel();
    this.fee = 0;
    this.paymentStatus = '';
    this.placedAt = '';
    this.inGuarantee = '';
    this.placedAtDate = '';
    this.inGuaranteeDate = '';
    this.classIcon = '';
    this.classText = '';
    this.imageText= '';
    this.imageColor= '';
  }
}
