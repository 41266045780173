export const LANG_TR_NAME = 'tr';

export const LANG_TR_TRANS = {

  appName: 'App Name',
  welcome: 'Welcome',
  user: 'User',
  signIn: 'Sign in',
  signUp: 'Sign up',
  dashboard: 'Dashboard',
};
