<div class="container-fluid pt-lg-5 pl-lg-5">
  <span class="page-header">{{'recruitersLeaderboard'|translate}}</span>

  <div class="pr-5 pb-2 img-container" style="    min-height: 100px;
    background-image: url(./../../../assets/images/talentbase/new-leaderboard.png);
    background-size: cover;">

    <img style="opacity: 0" class="w-100" src="./../../../assets/images/talentbase/new-leaderboard.png">

    <div class="centered hello-text ml-5"> {{ 'hello' | translate }}, {{name}} </div>

    <div class="centered ml-5 mt-4 welcome-text">{{ 'welcomeBackToTheLeaderboard' | translate }}</div>
  </div>

  <div class="pr-5">
    <div class="col-12 pt-3 pr-1 pl-0 filter-div">
      <div class="row mb-3" style="display: flex; justify-content: end">
        <div class="row" style="display: flex;
                                 flex-direction: row;
                                 flex-wrap: wrap;
                                 justify-content: flex-end;
                                 align-content: flex-start;
                                 align-items: baseline;">
          <div>
            <span class="sort-by-text">{{ 'sortBy' | translate }}:</span>
          </div>
          <div>
            <tlb-dropdown [items]="sortOptions1" [dropDownMenuWidth]="'16rem'"
              [style]="'background: #FFFFFF; border: 1px solid #C8D4DF; box-sizing: border-box; width: 200px; height: 53px; margin: 0px 5px;'"
              [textStyle]="'font-style: normal; font-weight: normal; font-size: 14px; color: #252B42; margin-top: 5px;'"
              [dropdownName]="sortLabelRating" (searchData)="selectSort($event, 'rating')"
              [isFilter]="false">
            </tlb-dropdown>
          </div>
          <div>
            <tlb-dropdown [items]="sortOptions1" [dropDownMenuWidth]="'16rem'"
              [style]="'background: #FFFFFF; border: 1px solid #C8D4DF; box-sizing: border-box; width: 200px; height: 53px; margin: 0px 5px;'"
              [textStyle]="'font-style: normal; font-weight: normal; font-size: 14px; color: #252B42; margin-top: 5px;'"
              [dropdownName]="sortLabelPlacement" (searchData)="selectSort($event, 'placement')"
              [isFilter]="false">
            </tlb-dropdown>
          </div>
          <div>
            <tlb-dropdown [items]="sortOptions1" [dropDownMenuWidth]="'16rem'"
              [style]="'background: #FFFFFF; border: 1px solid #C8D4DF; box-sizing: border-box; width: 200px; height: 53px; margin: 0px 5px;'"
              [textStyle]="'font-style: normal; font-weight: normal; font-size: 14px; color: #252B42; margin-top: 5px;'"
              [dropdownName]="sortLabelScore" (searchData)="selectSort($event, 'score')"
              [isFilter]="false">
            </tlb-dropdown>
          </div>
        </div>


        <div>
        </div>
      </div>
    </div>
  </div>




  <div class="row" *ngIf="recruiters && recruiters.length > 0">
    <div class="col-12 pt-3 pb-5 pr-lg-5 overflow-auto">
      <div class="card">
        <div class="pl-4 pt-4 pb-4">
          <span class="table-header">{{'recruitersLeaderboard'|translate}}</span>
          <p class="weekly-text">{{'weeklyRecruiterLeaderboard'|translate}}</p>

          <div class="pt-2">
            <table class="table table-xs-responsive table-hover table-borderless col-md-12 ">
              <thead>
                <tr style="background-color: #F3F6F9;">
                  <th scope="col"></th>
                  <th scope="col "><span class="text-uppercase table-headers" style="color: #23252A;">{{'recruiter'|translate}}</span></th>
                  <th scope="col text-uppercase  "><span class=" text-uppercase table-headers">{{'placements'|translate}}</span></th>
                  <th scope="col text-uppercase "><span class=" text-uppercase table-headers">{{'rating'|translate}}</span></th>
                  <th scope="col text-uppercase "><span class=" text-uppercase table-headers">{{'score'|translate}}</span></th>
                  <th scope="col"></th>
                </tr>


              </thead>
              <tbody *ngIf="recruiters && recruiters.length > 0" >
                <tr *ngFor="let recruit of recruiters" >
                  <th scope="row">
                        <img *ngIf="sortedArray.indexOf(recruit) == 0" src="../../../assets/images/talentbase/first.png">
                        <img *ngIf="sortedArray.indexOf(recruit) == 1" src="../../../assets/images/talentbase/second.png">
                        <img *ngIf="sortedArray.indexOf(recruit) == 2" src="../../../assets/images/talentbase/third.png">
                        <span *ngIf="sortedArray.indexOf(recruit) > 2" class="fw-bold">{{sortedArray.indexOf(recruit) + 1}}th</span>
                  </th>
                  <td>
                    <div class="row">
                      <div  [style.background-color]="recruit.imageColor" id="preimage"><span style="color: white;">{{recruit.imageText}}</span></div>
                      <span class="name-text ml-4">{{recruit?.recruiter?.user?.fullName}}</span>
                    </div>
</td>
                  <td>
                    <div class="row">
                      <div class="col-3">
                        <span class="fw-bold">{{recruit?.placements}}</span>
                      </div>

                      <div class="col-9 mt-1">

                        <div class="progress">
                          <div class="progress-bar progress-bar-design" role="progressbar" aria-valuenow="recruit.placements"
                          aria-valuemin="0" aria-valuemax="100" [style.width]="recruit?.placementValue + '%'">
                          </div>
                        </div>

                      </div>


                    </div>

                  </td>
                  <td>
                    <span style="color: #F1B856;" [ngClass]="recruit?.rating > 0 ? recruit?.rating < 1 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"></span>
                    <span style="color: #F1B856;" [ngClass]="recruit?.rating > 1 ? recruit?.rating < 2 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"></span>
                    <span style="color: #F1B856;" [ngClass]="recruit?.rating > 2 ? recruit?.rating < 3 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"></span>
                    <span style="color: #F1B856;" [ngClass]="recruit?.rating > 3 ? recruit?.rating < 4 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"></span>
                    <span style="color: #F1B856;" [ngClass]="recruit?.rating > 4 ? recruit?.rating < 5 ? 'fas fa-star-half-alt' : 'fa fa-star' : 'far fa-star'"></span>
                  </td>
                  <td><span class="fw-bold ml-4">{{recruit?.delta}}</span></td>

                </tr>


              </tbody>
            </table>

          </div>
          <tlb-empty-page-dashboard *ngIf="!recruiters || recruiters.length == 0" [warningLabel]="'LeadBoard has no data'"></tlb-empty-page-dashboard>


        </div>

      </div>

    </div>

  </div>


  <tlb-empty-page *ngIf="!recruiters || recruiters.length == 0"
  [warningLabel]="'noRecruiterYet'"></tlb-empty-page>

</div>
