import { Component, OnInit } from '@angular/core';
import { Router }            from '@angular/router';
import { PackageService }    from '../../shared/services/subscription/package/package.service';
import { OAuthService }      from 'angular-oauth2-oidc';
import { AuthService }       from '../../shared/services/auth/auth.service';

@Component({
             selector: 'app-finish-payment',
             templateUrl: './finish-payment.component.html',
             styleUrls: ['./finish-payment.component.scss']
           })
export class FinishPaymentComponent implements OnInit {

  constructor(private router: Router, private packageService: PackageService,
              private oauthService: OAuthService,
              private authService: AuthService) {
  }

  ngOnInit(): void {
    this.packageService.getPaymentStatus()
        .subscribe(x => {
                     if (x == 'ACTIVE') {

                       //this.router.navigateByUrl('/dashboard').then();
                       this.oauthService.refreshToken()
                           .then(x => {
                             const role = JSON.parse(
                               window.atob((localStorage.getItem(this.packageService.TOKEN_KEY)).split('.')[1])).role;
                             localStorage.setItem('role', role);
                             this.router.navigateByUrl('/dashboard')
                                 .then(y => {
                                   this.authService.getUserPermissionsFromEndpoint();
                                 });
                           });
                     } else {
                       this.router.navigateByUrl('/dashboard')
                           .then();
                     }

                   },

                   (err) => {
                     if ((err.status >= 400 || err.status < 500) && err.status !== 401) {
                       this.router.navigateByUrl('/dashboard')
                           .then();
                       /* this.notification.error(err.message);
                        this.spinner.hide();*/
                     }
                   },
                   () => {
                     this.router.navigateByUrl('/dashboard')
                         .then();
                     //this.spinner.hide();
                   });
  }

}
