export class JobRestrictionModel {

  job: any;
  leastPlacement: number;
  leastRating: number;

constructor() {
  this.job = null;
  this.leastPlacement = 0;
  this.leastRating = 0;
}
}
