import { JobService } from 'src/app/shared/services';
import { JobModel } from 'src/app/shared/models';
import {Component, OnInit, Input, SimpleChanges, SimpleChange} from '@angular/core';

@Component({
  selector: 'app-full-job-description-modal',
  templateUrl: './full-job-description-modal.component.html',
  styleUrls: ['./full-job-description-modal.component.scss']
})
export class FullJobDescriptionModalComponent implements OnInit {

  @Input() job: JobModel;
  @Input() employer: boolean = false;
  selectedJob: JobModel = new JobModel();
  // worklist: boolean;
  @Input() jobSentModal: string;

  constructor(private jobService: JobService) { }

  ngOnInit(): void {
    // this.worklist = false;
  }
  changeWorkListStatus(toAdd: boolean) {
    switch (toAdd) {
      case true:
        this.jobService.addToWorkList(this.job.id).subscribe(x=>{
          this.job.favorite = true;
          // this.worklist=true;
        });
        break;
      case false:
        this.jobService.removeFromWorkList(this.job.id).subscribe(x=>{
          this.job.favorite = false;
          // this.worklist = false;
        });
        break;
      default:
        break;
    }
  }


  /*viewSalaryPopup(job: JobModel) {
    this.selectedJob = job;
    // @ts-ignore
    $('#viewSalaryModal').modal('show');
    // @ts-ignore
    $('#fullJobDescriptionModal').modal('hide');
  }*/

  OpenModalViewSalarys(job: JobModel) {
    this.selectedJob = job;
      // @ts-ignore
    $('#viewSalaryModal').modal('show');
    // @ts-ignore
    $('#fullJobDescriptionModal').modal('hide');
  }

  openSubmitApplicationModal(job){
    this.selectedJob = job;

    // @ts-ignore
   $('#submitCandidateModal').modal('show');
  //  // @ts-ignore
  //  $('#fullJobDescriptionModal').modal('hide');
 }


}

