import { TranslatePipe } from 'src/app/shared/pipes/translate/translate.pipe';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../auth';
import { server } from '../server';


@Injectable({
  providedIn: 'root'
})
export class JobSegmentService extends ApiService {

  constructor(public http: HttpClient, public spinner: NgxSpinnerService, public translate: TranslatePipe, public notification: NotificationService) {
    super(http, server.jobSegment, spinner, translate, notification)
  }
}
