<nav class="navbar navbar-expand-lg navbar-dark pl-3 pr-5 shadow main-header d-none d-xl-flex ">
  <a (click)="router.navigate(['/dashboard'])"
     class="navbar-brand"
     style="cursor: pointer;"><img alt="logo"
                                   src="assets/images/logo.png"></a>
  <button aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          class="navbar-toggler"
          data-target="#navbarSupportedContent"
          data-toggle="collapse"
          style="background-color: gray;"
          type="button">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse ml-5"
       id="navbarSupportedContent">
    <div (click)="showSearchPopup()"
         *ngIf="role !='ADMIN'"
         class="headerSearchBoxDiv ml-1 d-none d-lg-inline-block cursor-pointer">
      <i class="fas fa-search mx-2"></i> {{'search' | translate}}
      <!--<input type="text" placeholder="Enter keywords..." class="headerSearchBox">-->
    </div>
    <ul class="navbar-nav text-light ml-auto my-2 my-lg-0  position-relative">
      <li *ngIf="role =='EMPLOYER_MANAGER' || role === 'EMPLOYER_USER'"
          class="nav-item d-none d-lg-inline mx-2 my-auto cursor">
        <button (click)="goToPostAJob()"
                class="btn post-job-btn"
                id="next"
                type="button">
          {{ 'postAJob' | translate }}
        </button>
      </li>
      <li class="nav-item d-none d-lg-inline mx-4 my-auto  notification-li">
        <i (click)="changeStateOfFeed()"
           class="fas fa-bell d-none d-lg-inline-block notification-icon cursor-pointer"
           id="feed-button"></i>
        <div class="feed-div shadow"
             id="feed-div">
          <app-header-notifications (selectedJob)="selectedJob($event)"></app-header-notifications>
        </div>

      </li>

      <li class="nav-item mx-2 my-auto d-none d-lg-inline">
        <div [style.background-color]="imageColor"
             id="preimage"><span style="color: white;">{{imageText}}</span></div>

      </li>
      <li class="nav-item dropdown my-3 my-lg-0  d-none d-lg-inline ">
        <div aria-expanded="false"
             aria-haspopup="false"
             class="ml-4 profile-menu pr-4"
             data-toggle="dropdown"
             id="dropdownMenuButton">
          <i class="fas fa-chevron-down down-icon"></i>

        </div>
        <div aria-labelledby="dropdownMenuButton"
             class="dropdown-menu mt-4 speech-balloon border-0 shadow pr-3"
             style="
        width: 220px; margin-left: -8rem;
    ">
          <ul class="header-drp-menu">

            <li *ngIf="role=='EMPLOYER_MANAGER' || role === 'EMPLOYER_USER'"
                [routerLinkActiveOptions]="{exact: true}"
                [routerLink]="['/dashboard/edit-profile']"
                class="pb-3"><i class="far fa-user pr-2 dropdown-text-color"
                                routerLinkActive="menu-active2"></i>
              <span class="dropdown-text-color"
                    routerLinkActive="menu-active">{{ 'editProfile' | translate }}</span>
            </li>
            <li *ngIf="role=='RECRUITER_MANAGER'|| role ==='RECRUITER_USER'"
                [routerLinkActiveOptions]="{exact: true}"
                [routerLink]="['/dashboard/edit-profile']"
                class="pb-3"><i class="far fa-user pr-2 dropdown-text-color"
                                routerLinkActive="menu-active2"></i>
              <span class="dropdown-text-color"
                    routerLinkActive="menu-active">{{ 'editProfile' | translate }}</span>
            </li>


            <li (click)="logOut()"><i class="fas fa-sign-out-alt pl-2 logout-color rotateimg180"></i>
              <span class="logout-color">{{ 'logOut' | translate }}</span>
            </li>
          </ul>
        </div>
      </li>
      <li class="pt-3 nav-item d-inline d-lg-none mx-2 my-auto text-center">
        <h1 class="my-2 black">{{fullName}}</h1>
      </li>
      <li [routerLink]="['/dashboard/company-info']"
          class="nav-item d-inline d-lg-none mx-2 my-auto text-center"
          routerLinkActive="active">
        <h2 class="my-3 black">{{ 'companyInfo' | translate }}</h2>
      </li>
      <li [routerLink]="['/dashboard/edit-profile']"
          class="nav-item d-inline d-lg-none mx-2 my-auto text-center"
          routerLinkActive="active">
        <h2 class="my-3 black">{{ 'editProfile' | translate }}</h2>
      </li>
      <li [routerLink]="['/notifications']"
          class="nav-item d-inline d-lg-none mx-2 my-auto cursor text-center"
          routerLinkActive="active">
        <h2 class="my-3 black">{{'notifications'|translate}}</h2>
      </li>
      <li (click)="openMessagesPopup()"
          class="nav-item d-inline d-lg-none mx-2 my-auto text-center">
        <h2 class="my-3 black">{{'messages'|translate}}</h2>
      </li>
      <li (click)="logOut()"
          class="nav-item d-inline d-lg-none mx-2 my-auto text-center">
        <h2 class="my-3">
          <i class="fas fa-sign-out-alt pl-2 logout-color rotateimg180"></i>
          <span class="logout-color">{{ 'logOut' | translate }}</span>
        </h2>
      </li>

    </ul>
  </div>
</nav>

<nav class="navbar navbar-dark  shadow d-xl-none">
  <button (click)="openMenu()"
          class="btn">
    <img alt=""
         src="assets/images/icons/menu.png">
  </button>


  <a (click)="router.navigate(['/dashboard'])"
     class="navbar-brand mr-0"
     style="cursor: pointer;">
    <img src="../../../assets/images/logo.png"
         width="160" />
    <!-- <img src="../../../assets/images/small-logo.png" alt="" style="margin-top: -2px;"> <span class="logoName ml-2">{{'logoName' | translate}}</span> -->
  </a>
  <ul class="navbar-nav flex-row">
    <li class="nav-item d-flex mx-2"
        routerLink="/activity">
      <i class="fas fa-bell d-inline-block align-self-center notification-icon cursor-pointer"></i>
    </li>
    <li [routerLink]="['/dashboard/edit-profile']"
        class="nav-item d-flex mx-2">
      <div [style.background-color]="imageColor"
           class="align-self-center"
           id="preimage"><span style="color: white;">{{imageText}}</span></div>
    </li>
  </ul>
</nav>


<app-messages [selectedUser]="null"></app-messages>

<app-global-search>

</app-global-search>


<app-dashboard-view-salary-modal [selectedJobs]="selectedContent"></app-dashboard-view-salary-modal>
<app-view-salary-modal [selectedJobs]="selectedContent"></app-view-salary-modal>


<app-employer-job-detail-popup
(submitCandidateModalEmitter)="submitCandidate(selectedContent)" (viewSalaryEmitter)="OpenModalViewSalary(selectedContent)"
  [job]="selectedContent" [modalId]="'jobDetailFromNotificationHeader'"></app-employer-job-detail-popup>


