import { Component, Input, OnInit } from '@angular/core';
import { JobModel } from 'src/app/shared/models';

@Component({
  selector: 'app-related-jobs-modal',
  templateUrl: './related-jobs-modal.component.html',
  styleUrls: ['./related-jobs-modal.component.scss']
})
export class RelatedJobsModalComponent implements OnInit {


  @Input() jobs: any[] = [];
  selectedJob: any = null;

  constructor() { }

  ngOnInit() {
  }

  openfullJobDescriptionModal(job: JobModel) {
    this.selectedJob = job;
    // @ts-ignore
    $('#jobDetailFromRelated').modal('show');

    /*    // @ts-ignore
        $('#fullJobDescriptionModal').modal('show');*/
  }


  OpenModalViewSalary(job: JobModel) {
    this.selectedJob = job;
    // @ts-ignore
    $('#viewSalaryModalFromRel').modal('show');
    // @ts-ignore
    $('#fullJobDescriptionModal').modal('hide');
  }

  submitCandidate(job: JobModel) {
    this.selectedJob = job;

    // @ts-ignore
    $('#submitCandidateModal').modal('show');
  }

  candidateReviewScreen() {
    // @ts-ignore
    $('#candidateReviewModal').modal('show');

  }

}
