<div class="container-fluid">
  <div class="col-12">

    <div class="top-page mt-2 mb-4">
      <div class="header">
        {{ 'allEmployers' | translate }}
      </div>
      <div class="sub-header mt-2 mb-4">
        {{ 'allEmployersListing' | translate }}
      </div>
    </div>
    <div class="row">
      <div class="col-3">
              <button class="search-button"  (click)="search()" type="submit"><i class="fa fa-search search-icon"></i></button>
              <input [(ngModel)]="filterText" #searchInput  type="text" placeholder="{{'searchForCompanyName' | translate}}"
                     class="form-control d-inline float-right search-input w-100" style="border: none; border-radius: 4px 0px 0px 4px; height: 40px;">

                     <br>
                     <label *ngIf="filterText != ''" class="clear-search mt-2" for="" (click)="clearSearch()"><i class="fas fa-times mr-1"
                      style="color: #707683;" ></i> {{'clearSearch' | translate}}  </label>

      </div>


      <div class="col-5 ml-auto">

            <div class="row mr-3"  style="float: right;">
              <div class="mt-1 sort-by-text">{{ 'sortBy' | translate }}:</div>
              <div><tlb-dropdown [items]="sortOptions" [dropDownMenuWidth]="'16rem'"
                [style]="'background-color: white;  border: none; box-sizing: border-box; width: 100%; height: 53px; margin: 0px 15px;'"
                [textStyle]="'font-style: normal; font-weight: normal; font-size: 14px; color: #252B42; margin-top: 5px;'"
                [dropdownName]="sortLabel != '' ? sortLabel : 'selectSort'" (searchData)="selectSort($event)"
                [isFilter]="false">
              </tlb-dropdown></div>
            </div>
      </div>
    </div>

    <div class="showing-test mt-3">
      {{ 'showing' | translate }} {{ ' ' + employerModel.length }} {{ 'resultOfEmployers' | translate }}
    </div>

    <div class="mb-3 based-text">
        {{ 'basedOnYourPreferences' | translate }}
    </div>


      <div  *ngFor="let employer of employerModel | paginate:{ itemsPerPage: requestPayload.limit, currentPage: currentPage,totalItems:count}"  class="row mt-3" >
        <div class="col-12">
          <div class="card" style="border-radius: 4px; border: none;">
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <div class="row mb-4">
                    <div class="col-2">
                      <div [style.background-color]="employer.imageColor" id="preimage"><span style="color: white;">{{employer.imageText}}</span></div>
                    </div>

                    <div class="col-12 col-lg-12 col-md-12 col-xl-10 pt-3 pt-md-3 pt-lg-3 pt-xl-0" >
                      <div class="row">
                        <div class="col-12" >
                          <span class="emp-name">{{employer.createdBy?.name}}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <span class="comp-name">{{employer.companyName}}</span>
                        </div>
                      </div>

                    </div>
                  </div>
                  <span class="registered-text">
                    {{'registeredOn' | translate}}
                  </span>
                  <span class="registered-date">
                    {{employer.createdAt | date }}
                  </span>
                </div>

                <div class="col-6">

                  <div class="row">
                    <div class="col-12 col-lg-12 col-md-12 col-xl-6" >

                      <div class="info-box">
                        <div class="row pt-2">
                          <i class="fas fa-user-friends"></i>
                            <span class="info-header ml-2">{{ 'companySize' | translate }}:</span>
                            <span class="info-text ml-2"> {{employer.companySize | translate}} </span>
                        </div>

                        <div class="row mt-4">
                          <i class="fas fa-briefcase"></i>
                          <span class="info-header ml-2">{{ 'industry' | translate }}:</span>
                          <span class="info-text ml-2">{{employer.industriesText}}</span>
                        </div>
                      </div>

                    </div>

                    <div class="col-12 col-lg-12 col-md-12 col-xl-6" >
                      <div class="p-3 mt-2 pr-5 pr-xl-0 border-left">
                        <div class="col-12 ml-3 mt-4">
                          <button (click)="openAdminEmployerModal(employer)" class="form-control view-button">
                            {{ 'viewDetails' | translate }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <tlb-empty-page *ngIf="!employerModel || employerModel.length == 0" [warningLabel]="filterText == ''  ? 'noEmployerYet' : filterText" [filterText]="filterText"></tlb-empty-page>
    </div>
</div>

<div *ngIf="employerModel && employerModel.length > 0" class=" container-fluid row mt-3 pagination">
  <pagination-controls (pageChange)="currentPage = $event;getEmployers(currentPage)"
                       [autoHide]="count <= requestPayload.limit"
                       nextLabel="{{'next' | translate}}"
                       previousLabel="{{'previous' | translate}}"
                       responsive="true"></pagination-controls>
</div>

<app-admin-employer-detail-modal [employer]='selectedEmployer'></app-admin-employer-detail-modal>
