<div class="row mt-3">

  <div class="col-12 pr-1 pl-0">
    <div class="card"
      style="box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1); border-radius: 4px;">
      <div class="card-body">
        <div class="col-12 pr-1 pl-0 filter-div">
          <div class="row mb-2" style="display: flex;
                      flex-direction: row;
                      flex-wrap: wrap;
                      justify-content: space-between;
                      align-items: baseline;">
            <div class="mt-1 ml-3" style="display: flex;
                        flex-wrap: nowrap;
                        align-items: center;
                        justify-content: flex-end;
                        align-content: flex-start;">
              <i class="fa fa-search"></i>
              <input [(ngModel)]="filterText" name="filter" class="form-control filter" id="filter" type="text"
                placeholder="{{'searchForKeyWords' | translate}}" #searchInput (keyup)="keyup(searchInput.value)">
            </div>

            <label *ngIf="filterText != ''" class="clear-search mt-2" for="" (click)="clearSearch()"><i class="fas fa-times mr-1"
              style="color: #707683;" ></i> {{'clearSearch' | translate}}  </label>
            <div class="ml-4 ml-sm-0" style="display: flex;
                        flex-wrap: nowrap;
                        align-items: center;
                        justify-content: flex-end;
                        align-content: flex-start;">
              <span class="sort-by-text">{{ 'sortBy' | translate }}:</span>
              <tlb-dropdown [items]="sortOptions" [dropDownMenuWidth]="'16rem'"
                [style]="'background: #FFFFFF; border: none; box-sizing: border-box; width: 140px; height: 35px'"
                [textStyle]="'font-family: Helvetica Neue; font-style: normal; font-weight: 400; font-size: 14px; line-height: 150%; align-items: center; color: #8C8AA6;'"
                [dropdownName]="sortLabel != '' ? sortLabel : 'selectSort'" (searchData)="selectSort($event)"
                [isFilter]="false">
              </tlb-dropdown>
            </div>
          </div>
        </div>


        <div
          *ngFor="let job of jobs | paginate:{ itemsPerPage: requestPayload.limit, currentPage: currentPage,totalItems:count}">
          <div [ngStyle]="{'border-bottom': job != jobs[jobs.length - 1] ?  '1px solid #E4E4E7' : 'none'}">

            <div class="container-fluid mt-4 mb-4">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-1 mb-3 mb-sm-0"><img
                    [src]="job.logoUrl && job.logoUrll != '' ? job.logoUrl : './assets/images/default-logo.png'" alt=""
                    width="45"></div>
                <div class="col-12 col-sm-5 col-md-9 col-lg-9 col-xl-10">
                  <div class="row mt-2 ml-2">
                    <div class="emp-name-text">{{job.employer?.name}}</div>
                    <div class="ml-2 loc-text row"><i class="fas fa-circle mt-2" style="font-size: 4px;"></i>
                      <div class="ml-2" style="margin-top: 1px">{{job.location?.city?.name}}, {{job.location?.country?.name}}</div>
                      </div>
                  </div>
                </div>

                <div class="col-12 col-sm-1 col-md-1 col-lg-1 col-xl-1" (click)="openCard(job)">
                  <i class="fas fa-chevron-down mt-2" style="color: #A1A1AA;"></i>
                </div>
              </div>
              <!-- <div class="row"> -->

              <div class="row mt-2 title-text">

                <div class="ml-3">{{job.title}}</div>
                <div *ngIf="job.opened"
                  [ngClass]="job.status == 'DRAFT' ? 'orange-badge' : job.status == 'ACTIVE' || job.status == 'BY_JOB_PROFILE' ? 'green-badge' : 'red-badge'"
                  class="ml-3">
                  {{ job.status | translate }}
                </div>


              </div>

              <div *ngIf="!job.opened" class="row desc-text mt-1 mb-3">
                <div class="col-12">{{job.description}}</div>
              </div>

              <div *ngIf="job.opened" class="ml-3">
                <div class="row mb-2 details-gray">
                  <span *ngFor="let skil of job.skills">{{skil}}, </span>
                </div>
                <div class="row mb-2 details-gray">
                  <span>{{ 'totalNumberOfPositions'| translate }}: </span>
                  <span class="ml-1">{{ job.positionCount }}</span>
                </div>

                <div class="row mb-4 details-gray">
                  <span>{{ 'jobClassification'| translate }}: </span>
                  <span class="ml-1">{{ job.classification.name }}</span>
                </div>

                <div class="row mb-4">
                  <div class="mt-2 mt-sm-0 ">
                    <button (click)="openApplicantsModal(job.applications, job)" class="btn view-application-btn">{{
                      'viewAllApplication' | translate }}</button>
                  </div>

                  <div class="mt-2 mt-sm-0 ml-3">
                    <button (click)="openJobDetail(job)" class="btn view-job-detail-btn">{{ 'viewJobDetail' | translate
                      }}</button>
                  </div>

                  <div class="mt-2 mt-sm-0 ml-3" *ngIf="job.status == 'DRAFT'">
                    <button (click)="openEditJob(job)" class="btn edit-job-btn">{{ 'editJob' | translate }}</button>
                  </div>

                  <div class="mt-2 mt-sm-0 ml-sm-3" *ngIf="job.status == 'ACTIVE'">
                    <button (click)="openCloneYesOrNoPopup(job)" class="btn clone-job-btn">{{ 'cloneJob' | translate }}</button>
                  </div>
                </div>

                <div class="row">
                  <div class="col-4">
                    <div class="card" style="box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.08), 0px 4px 4px rgba(51, 51, 51, 0.04);
                        border-radius: 4px; border: none; padding: 12px;">
                      <div class="row">
                        <div class="col-3">
                          <img src="./assets/images/pend-rev.png" alt="" width="35" height="35" class="mt-1">
                        </div>
                        <div class="col-9 box-left">
                          <span class="card-numbers">{{job.pendingReview}}</span>
                          <span class="card-titles">{{'pendingReview'|translate}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="card" style="box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.08), 0px 4px 4px rgba(51, 51, 51, 0.04);
                      border-radius: 4px; border: none; padding: 12px;">
                      <div class="row">
                        <div class="col-3">
                          <img src="./assets/images/und-cons.png" alt="" width="35" height="35" class="mt-1">
                        </div>
                        <div class="col-9 box-left">
                          <span class="card-numbers">{{job.underConsideration}}</span>
                          <span class="card-titles">{{'underConsideration'|translate}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="card" style="box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.08), 0px 4px 4px rgba(51, 51, 51, 0.04);
                        border-radius: 4px; border: none; padding: 12px;">
                      <div class="row">
                        <div class="col-3">
                          <img src="./assets/images/tot-app.png" alt="" width="35" height="35" class="mt-1">
                        </div>
                        <div class="col-9 box-left">
                          <span class="card-numbers">{{job.totalApplications}}</span>
                          <span class="card-titles">{{'TotalApplications'|translate}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>

        </div>

        <tlb-empty-page *ngIf="!jobs || jobs.length == 0"
        [warningLabel]="filterText == ''  ? 'noJobYet' : filterText" [filterText]="filterText"></tlb-empty-page>

      </div>

    </div>

  </div>
</div>


<div class="row mt-3 pagination">
  <pagination-controls (pageChange)="currentPage = $event;pagination(currentPage)"
    [autoHide]="count <= requestPayload.limit" nextLabel="{{'next' | translate}}"
    previousLabel="{{'previous' | translate}}" responsive="true"></pagination-controls>
</div>

<app-applicants-modal [applications]="applications" [job]="selectedJob"
  (openUpdateCandidateStatusEmitter)="openUpdateCandidateStatus($event)"
  (openCandidateDetailPopupEmitter)="openCandidateDetailPopup($event)"></app-applicants-modal>

<app-candidate-update-status-popup [application]="selectedApplication" (updatedCandidateStatus)="updateStatus()">
</app-candidate-update-status-popup>
<app-candidate-detail-popup [application]="selectedApplication" [job]="selectedJob"
  (openUpdateCandidateStatusEmitter)="openUpdateCandidateStatus($event)" (openReviewsEmitter)="openReviewModal($event)">
</app-candidate-detail-popup>

<app-employer-job-detail-popup [job]="selectedJob" [modalId]="'jobDetailFromEmployerList'"
  (showCancelJobPopupEmitter)="showCancelJobPopup()" (showDeleteJobPopup)="showDeleteJobPopup()"
  (editJobModalShowEmitter)="editJobModalShow()" (getJobListAgain)="getJobListAgainEmit()"
  (openApplicantsModalEmitter)="openApplicantsModal($event.applications, $event)"></app-employer-job-detail-popup>

<general-delete-modal [Title]="'deleteJob'" [Text]="'theJobWillBeRemovedFromYourListAreYouSure'"
  (selection)="deleteJob($event)"></general-delete-modal>

  <app-confirmation-modal [Title]="'cloneJob'" [Text]="'theJobWillCloneAreYouSure'"
  (selection)="cloneJob($event)"></app-confirmation-modal>
