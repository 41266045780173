<div *ngIf="filterData"
     class="row">
  <div class="col-12 pr-1 pl-0">
    <div class="card border-0"
         style="background-color: #F5F6F8;">
      <div class="card-body">
        <div class="card-text row">

          <div class="dropdown drp"
               id="filer-dropdown">
            <button (click)="filterState = !filterState"
                    class="filter-button"><img alt="filter icon"
                                               class="filter-icon"
                                               src="assets/images/talentbase/icons/filter.png"> {{'filters' | translate}}
            </button>
            <div [style.display]="filterState ? 'block':'none'"
                 aria-labelledby="dropdownMenuButton"
                 class="dropdown-menu col-12 pb-0"
                 id="filter-dropdown-menu"
                 style="min-width: 18rem;">


              <div class="dropdown-item color-dropdown-item"
                   id="status">

                <tlb-multiselect-dropdown (selectResult)="selectStatus($event)"
                                          [defaultItemKey]="''"
                                          [dropDownMenuWidth]="'15rem'"
                                          [dropdownName]="(statusLabel != '' ? statusLabel : 'status') | translate"
                                          [existItems]="selectedStatuses"
                                          [isFilter]="false"
                                          [items]="statusOptions"
                                          [style]="'text-align: left; border: 1px solid #DCDCDC; box-sizing: border-box; box-shadow: 0px 2px 4px rgb(0 0 0 / 10%); border-radius: 7px; height: 40px;'"
                                          [textStyle]="'font-family: Roboto; font-style: normal; font-weight: 500; font-size: 15px; line-height: 16px; letter-spacing: 0.1px; color: #252B42 !important;'">
                </tlb-multiselect-dropdown>
              </div>
              <div class="dropdown-item color-dropdown-item"
                   id="candidate">

                <tlb-dropdown (onSearch)="onSearchCandidate($event)"
                              (searchData)="selectCandidate($event)"
                              [dropdownName]="(candidateLabel != '' ? candidateLabel : 'candidate') | translate"
                              [items]="candidateOptions"
                              [searchText]="candidateSearchText"
                              [searchType]="'api'"
                              [style]="'text-align: left; border: 1px solid #DCDCDC; box-sizing: border-box; box-shadow: 0px 2px 4px rgb(0 0 0 / 10%); border-radius: 7px; height: 40px;'"
                              [textStyle]="'font-family: Roboto; font-style: normal; font-weight: 500; font-size: 15px; line-height: 16px; letter-spacing: 0.1px; color: #252B42 !important;'">
                </tlb-dropdown>
              </div>
              <div class="dropdown-item color-dropdown-item"
                   id="recruiter">

                <tlb-dropdown (onSearch)="onSearchRecruiter($event)"
                              (searchData)="selectRecruiter($event)"
                              [dropdownName]="(recruiterLabel != '' ? recruiterLabel : 'recruiter') "
                              [items]="recruiterOptions"
                              [searchText]="recruiterSearchText"
                              [searchType]="'api'"
                              [style]="'text-align: left; border: 1px solid #DCDCDC; box-sizing: border-box; box-shadow: 0px 2px 4px rgb(0 0 0 / 10%); border-radius: 7px; height: 40px;'"
                              [textStyle]="'font-family: Roboto; font-style: normal; font-weight: 500; font-size: 15px; line-height: 16px; letter-spacing: 0.1px; color: #252B42 !important;'">
                </tlb-dropdown>
              </div>
              <!--              <div class="dropdown-item color-dropdown-item"
                                 id="employer">

                              <tlb-dropdown (onSearch)="onSearchEmployer($event)"
                                            (searchData)="selectEmployer($event)"
                                            [dropdownName]="employerLabel != '' ? employerLabel : 'employer'"
                                            [items]="employerOptions"
                                            [searchText]="employerSearchText"
                                            [searchType]="'api'"
                                            [style]="'text-align: left; border: 1px solid #DCDCDC; box-sizing: border-box; box-shadow: 0px 2px 4px rgb(0 0 0 / 10%); border-radius: 7px; height: 40px;'"
                                            [textStyle]="'font-family: Roboto; font-style: normal; font-weight: 500; font-size: 15px; line-height: 16px; letter-spacing: 0.1px; color: #252B42 !important;'">
                              </tlb-dropdown>
                            </div>-->
              <div class="dropdown-item color-dropdown-item"
                   id="job">

                <tlb-dropdown (onSearch)="onSearchJob($event)"
                              (searchData)="selectJob($event)"
                              [dropdownName]="(jobLabel != '' ? jobLabel : 'job') | titlecase"
                              [items]="jobOptions"
                              [searchText]="jobSearchText"
                              [searchType]="'api'"
                              [style]="'text-align: left; border: 1px solid #DCDCDC; box-sizing: border-box; box-shadow: 0px 2px 4px rgb(0 0 0 / 10%); border-radius: 7px; height: 40px;'"
                              [textStyle]="'font-family: Roboto; font-style: normal; font-weight: 500; font-size: 15px; line-height: 16px; letter-spacing: 0.1px; color: #252B42 !important;'">
                </tlb-dropdown>
              </div>

              <div class="row mr-4"
                   style="justify-content: end;">

                <div class="mt-2 mb-2 mr-1">
                  <button (click)="clearFilterEmit()"
                          class="btn clear-filter-btn"
                          type="button">{{'discard' |
                    translate}}</button>
                </div>
                <div class="mt-2">
                  <button (click)="emitData()"
                          class="btn apply-filter-btn"
                          type="button">{{'apply' |
                    translate}}</button>
                </div>


              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
