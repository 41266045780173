import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateAvatar } from 'src/app/shared/classes';
import { JobModel } from 'src/app/shared/models';
import { ApplicationService, JobService, NotificationService, RecruiterService, server, TranslatePipe } from 'src/app/shared/services';

@Component({
  selector: 'app-approval-email-page',
  templateUrl: './approval-email-page.component.html',
  styleUrls: ['./approval-email-page.component.scss']
})
export class ApprovalEmailPageComponent implements OnInit {

  applicationId: string = "";
  job: JobModel;
  recruiter: any;
  token: string;
  createAvatar: CreateAvatar;
  star: number = 4;

  constructor(private route: ActivatedRoute, private applicationService: ApplicationService, private recruiterService: RecruiterService,
    private notification: NotificationService, private translate: TranslatePipe, private router: Router, private jobService: JobService) {
      this.createAvatar = new CreateAvatar();
     }

  ngOnInit(): void {
    // @ts-ignore
    $('body').css('background-color', '#F5F4FE');
    // @ts-ignore
    $('body').css('background-image', 'none');
    // @ts-ignore
    $('body').css('height', '100%');
    // @ts-ignore
    $('html').css('height', '100%');

    this.applicationId = this.route.snapshot.paramMap.get("id");

    this.route.queryParams.subscribe(params => {
      this.token = params.token;
      this.applicationService.ServerGetWithoutLoginWithText("/" + this.applicationId + "/?token=" + params.token)
        .subscribe(app => {
          console.log(app);

          this.jobService.ServerGetWithoutLoginWithText("/" + app.job.id + "/?token=" + params.token)
            .subscribe(job => {
              this.job = job;

            });

          this.recruiterService.ServerGetWithoutLoginWithText("/" + app.recruiterId + "?token=" + params.token)
            .subscribe(rec => {
              this.recruiter = rec;
              this.recruiter.functionalRolesText = this.recruiter.functionalRoles.map(m => ' ' + m.name).toString();
              this.recruiter.industriesText = this.recruiter.industries.map(m => ' ' + m.name).toString();
              this.recruiter.requisitionTypesText = this.recruiter.requisitionTypes.map(m => ' ' + this.translate.transform(m + 'REC')).toString();

            });

        });
    });
  }

  approve() {
    this.applicationService.ServerPostWithoutLogin(server.application + "/" + this.applicationId + "/approve/?token=" + this.token, null)
      .subscribe(app => {
        this.notification.success(this.translate.transform('theApplicationApproved'));
        this.router.navigate(['/successfully-saved']);
      });
  }

  reject() {
    this.applicationService.ServerPostWithoutLogin(server.application + "/" + this.applicationId + "/reject/?token=" + this.token, null)
      .subscribe(app => {
        this.notification.success(this.translate.transform('theApplicationRejected'));
        this.router.navigate(['/successfully-saved']);
      });
  }

}
