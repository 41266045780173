<div class="container-fluid px-0">
  <div class="col-xl-11 col-md-10 col-10 m-auto">

      <div class="row px-0">
    <div class="col-xl-9 col-12">
      <app-jobs-filter (clearFilter)="getClearFilterJobs($event)" (resultFilter)="getFilteredJobs($event)"
        (resultSearch)="search($event)" [showAllFilter]="false"></app-jobs-filter>
      <tlb-empty-page *ngIf="!jobs || jobs.length == 0" [warningLabel]="searchText == ''  ?
                       'noJobYet' : searchText" [filterText]="searchText"></tlb-empty-page>

      <app-recruiter-jobs (getJobListAgain)="ngOnInit()" (sorted)="sorted($event)" (updateJobs)="getJobs($event)"
        (updatedStatus)="getJobs(1)" *ngIf="jobs && jobs.length > 0" [count]="count" [descriptionShow]="true"
        [jobs]="jobs" [limit]="5"></app-recruiter-jobs>
    </div>
    <div class="col-xl-3 col-12">
      <app-search-jobs-filter (clearFilter)="getClearFilterJobs($event)" (resultFilter)="getFilteredJobs($event)"
        (resultSearch)="search($event)"></app-search-jobs-filter>
    </div>
  </div>
  </div>

</div>
