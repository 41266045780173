import { Router }                                                                        from '@angular/router';
import { Injectable }                                                                    from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpClient } from '@angular/common/http';

import { Observable, throwError }             from 'rxjs';
import { catchError }                         from 'rxjs/operators';
import { environment }                        from '../../../environments/environment';
import { NotificationService, TranslatePipe } from '../services';
import { flatMap }                            from 'rxjs/internal/operators';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  TOKEN_KEY = 'access_token';
  REFRESH_TOKEN_KEY = 'refresh_token';

  constructor(private http: HttpClient, private router: Router, private notification: NotificationService,
              private translate: TranslatePipe) {
  }

  ClearSession(): void {
    // this.notification.error(this.translate.transform('yourLoginHasExpired'));
    const tempLang = localStorage.getItem('lang');
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem('lang', tempLang);
    this.router.navigate(['/'])
        .then(r => {

        });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /*   return next.handle(req);*/

    return next.handle(req)
               .pipe(catchError(err => {
                 if (err.status === 401) {

                   if (err.error == null) {
                     const formData = new FormData();
                     formData.set('refresh_token', this.getRefreshToken());
                     formData.set('grant_type', 'refresh_token');
                     const httpOptions = {
                       headers: new HttpHeaders({
                                                  Authorization: 'Basic ' + btoa('spa:')
                                                })
                     };
                     return this.http.post(environment.tokenEndpoint, formData, httpOptions)
                                .pipe(flatMap((data: any) => {
                                  localStorage.setItem('role', data.role);
                                  localStorage.setItem(this.TOKEN_KEY, data.access_token);
                                  localStorage.setItem(this.REFRESH_TOKEN_KEY, data.refresh_token);
                                  req = req.clone({
                                                    setHeaders: {
                                                      Authorization: 'Bearer ' + this.getAccessToken()
                                                    }
                                                  });
                                  return next.handle(req);
                                }));
                   } else if (err.error.message.includes('Invalid refresh token')) {
                     this.ClearSession();

                   }
                 }
                 return next.handle(req);
               }));
  }

  test(err): any {
    return throwError(err);
  }

  private checkIHaveRefreshToken(): boolean {
    const refreshToken = localStorage.getItem(this.REFRESH_TOKEN_KEY);
    return refreshToken != null;
  }

  private checkIHaveAccessToken(): boolean {
    const accessToken = localStorage.getItem(this.TOKEN_KEY);
    return accessToken != null;
  }

  private getAccessToken(): string | boolean {
    if (!this.checkIHaveAccessToken()) {
      return false;
    }
    return localStorage.getItem(this.TOKEN_KEY);
  }

  private getRefreshToken(): string {
    if (!this.checkIHaveRefreshToken()) {
      return '';
    }
    return localStorage.getItem(this.REFRESH_TOKEN_KEY);
  }

}
