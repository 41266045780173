<app-header></app-header>
<!--<div id="mySidenav"  [ngClass]="role == 'ADMIN' ? 'sidenav-admin' : 'sidenav'">
  <app-sidebar [opened]="sidebarIsOpen"></app-sidebar>
</div>-->
<div id="mySidenav" class="sidenav">
  <app-sidebar [opened]="sidebarIsOpen"></app-sidebar>
</div>

<div class="app-content-container">
  <router-outlet></router-outlet>
</div>

<!--<div class="left-menu">
  <div class="container">
    <div class="row mt-3">
      <div class="col-12">
        <img alt="logo" src="assets/images/logo.png" class="d-inline-block">
        <img src="assets/images/icons/x.png" alt="" class="d-inline-block float-right mr-3 mt-2">
      </div>

      <div class="col-12 mt-3"  [routerLinkActiveOptions]="{exact: true}"
           [routerLink]="['/dashboard']" routerLinkActive="active">
        <img src="./../../../../assets/images/talentbase/sidemenü-icon/dashboard-icon.png" style="opacity: 0.6;">
        <span class="d-inline pl-3">{{'dashboard'|translate}}</span>
      </div>
    </div>
  </div>
</div>-->

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  color="#339CFF"
  size="default"
  type="ball-spin-clockwise">
</ngx-spinner>
