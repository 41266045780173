import { EmployerContactPersonModel, IndustryModel, EmployerAddressModel } from 'src/app/shared/models';
export class EmployerAccountModel {
  id?: string;
  companyName?:string;
  contactPerson?:EmployerContactPersonModel;
  companySize?:string;
  website?:string;
  industry?:any;
  about?:string;
  address?:EmployerAddressModel;
  linkedinAccount?:string;
  facebookAccount?:string;
  twitterAccount?:string;
  instagramAccount?:string;
  atsAdditional?:string;
  companyLogo?:any;
  companyImage:any;

  constructor() {
    this.id='';
    this.companyName='';
    this.companySize='';
    this.website='';
    this.about='';
    this.linkedinAccount='';
    this.facebookAccount='';
    this.twitterAccount='';
    this.instagramAccount='';
    this.atsAdditional='';
    this.contactPerson=new EmployerContactPersonModel() ;
    this.address=new EmployerAddressModel() ;
    this.industry=null;


  }

}
