<!--
<link href="https://fonts.googleapis.com/css?family=Poppins" rel="stylesheet" />
<div class="col-12 col-lg-9 m-auto">
  <div class="row">
    <div class="mt-4 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
      <div class="pt-5">
        <div class="pt-5 d-none d-md-block d-lg-block d-xl-block">
          <h2>Find a job & grow your career!</h2>
          <p class="desc-text pt-3">
            Lorem ipsum dolor sit amet, sea putant suscipit invidunt cu.
          </p>
        </div>
        <div class="pt-5 d-none d-md-block d-lg-block d-xl-block">
          <img src="./../../../../assets/images/talentbase/left-bg.svg" />
        </div>
      </div>
    </div>

    <div class="mt-4 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
      <div class="card border-0 ml-5">
        <span class="createText ml-2">{{ "forgotPassword" | translate }}</span>
        <span class="mt-2 mb-5 ml-2 bottomCreateText">
          {{ "weWillSendMessageToEmail" | translate }}
        </span>
        <tlb-form
          *ngIf="formModel && formModel.length > 0 && formGroup"
          [forms]="formModel"
          [formGroup]="formGroup"
        ></tlb-form>

        <button
          class="btn createBtn ml-3"
          id="next"
          type="button"
          (click)="resetPassword()"
        >
          {{ "continue" | translate }}
        </button>

        <div style="margin-top: 11rem"></div>
      </div>
    </div>
  </div>
  <div class="mt-5"></div>
</div>
-->
<ng-container *ngIf="!hasError">
    <link href="https://fonts.googleapis.com/css?family=Poppins" rel="stylesheet"/>
    <div>
        <div class="col-12 col-xl-9 m-auto px-1 px-md-5 px-xl-0">
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-md-7 mb-5">
                    <div class="">
                        <div class="pt-5 d-none d-md-block d-lg-block d-xl-block">
                            <h2>Find a job & grow your career!</h2>
                            <p class="desc-text pt-3">
                                Lorem ipsum dolor sit amet, sea putant suscipit invidunt cu.
                            </p>
                        </div>
                        <div class="pt-5 d-none d-md-block d-lg-block d-xl-block">
                            <img src="./../../../../assets/images/talentbase/left-bg.svg" alt="background image"/>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-7 mb-5">
                    <div class="card border-0 custom-card px-0 px-md-4">
                        <span class="createText ml-2">{{ "forgotPassword" | translate }}</span>
                        <span class="mt-2 mb-3 ml-2 bottomCreateText">
          {{ "weWillSendMessageToEmail" | translate }}
        </span>
                        <tlb-form
                                *ngIf="formModel && formModel.length > 0 && formGroup"
                                [forms]="formModel"
                                [formGroup]="formGroup"
                        ></tlb-form>

                        <button
                                class="btn createBtn ml-lg-3"
                                id="next"
                                type="button"
                                [disabled]="
          formGroup && (formGroup.invalid)
        "
                                (click)="resetPassword()"
                        >
                            {{ "continue" | translate }}
                        </button>
                        <div class="col-12 col-sm-6 mt-4 mt-sm-0 d-sm-none">
          <span class="already-have-account"
          >{{ "dontYouHaveAnAccount" | translate }}
              <span (click)="goRegister()" class="sign-in">{{
                  "createAccount" | translate
                  }}</span>
          </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<div class="container-fluid error-popup-container" *ngIf="hasError">
    <div class="row">
        <div class="col-4">
            <img src="assets/images/password-reset.png" alt="password reset">
            <h1>{{'linkExpired'| translate}}</h1>
            <p>{{'passwordResetErrorText' | translate}}</p>
            <p>{{'youWillBeRedirectedToTheLoginPageIn' | translate}}  {{remainTime}} {{'seconds' | translate}}</p>
        </div>
    </div>
</div>




