<div class="modal fade" [id]="modalId" style="background-color: #000000b3;">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header modal-lg">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div *ngIf="selectedJobs && selectedJobs.id" class="modal-body">
        <div>
          <div class="card" style="border: none;">
            <div class="card-body">
              <div class="row">
                <div>
                  <div class="row">
                    <div class="col-3">
                      <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-1 mb-3 mb-sm-0"><img
                          [src]="selectedJobs.logoUrl && selectedJobs.logoUrll != '' ? selectedJobs.logoUrl : './assets/images/default-logo.png'"
                          alt="" width="40" height="40"></div>
                    </div>
                    <div class="col-9">
                      <span class="job-name">{{selectedJobs.title}}</span> <br>
                      <span class="company-name">{{selectedJobs.employer?.name}}</span>
                    </div>
                  </div>

                </div>
                <div>
                  <div class="posted-div">
                    <span class="posted-text">
                      {{'posted' | translate}} {{selectedJobs.postDate}} {{ 'daysAgo' | translate }}
                    </span>
                  </div>
                </div>
              </div>
              <br>
              <div class="row ml-2">
                <div class="mr-5">
                  <div class="loc-type-div mr-5">
                    <i class="fas fa-map-marker-alt mr-2"></i> <span class="loc-type-text">{{'jobLocation' | translate
                      }}</span>: <span class="loc-type-text"
                      style="font-weight: 400;">{{selectedJobs.location.city.name}},
                      {{selectedJobs.location.country.name}}</span>
                  </div>
                </div>
                <div class="ml-5">
                  <div class="loc-type-div ml-5">
                    <span class="loc-type-text">{{ selectedJobs.type | translate }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card mt-3" style="border: none;">
            <div class="card-body">
              <div class="row" style="padding: 10px 18px;">
                <div class="col-12">
                  <span class="desc-text">{{ 'viewSalaryDesc1' | translate }}</span>
                </div>

                <div class="col-12 mt-2">
                  <span class="desc-text" style="font-weight: 400;">{{ 'viewSalaryDesc2' | translate }}</span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="row">
        <div class="col-12 ">
          <div class="modal-footer">
            <button type="button" class="btn cancel-btn" data-dismiss="modal">{{ 'close' | translate }}</button>
            <button (click)="openModalDashboard()" type="button" class="btn ok-btn m-2" data-dismiss="modal">{{ 'ok' |
              translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
