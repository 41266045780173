import { AuditedModel }       from '../general/audited.model';
import { GeneralIdNameModel } from '../general';

export class NoteModel extends AuditedModel {
  id: string;
  application: GeneralIdNameModel;
  title: string;
  note: string;

  constructor() {
    super();
    this.id = '';
    this.application = new GeneralIdNameModel();
    this.title = '';
    this.note = '';
  }
}

export class NotePostModel {
  id: string;
  application: GeneralIdNameModel;
  title: string;
  note: string;

  constructor() {
    this.id = '';
    this.application = new GeneralIdNameModel();
    this.title = '';
    this.note = '';
  }
}
