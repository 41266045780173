<div class="rating-container rating-xs rating-animate">
<div class="rating-stars">
  <span class="empty-stars">
    <span class="star">
      <i class="fas fa-star empty-star"></i>
    </span>
    <span class="star">
      <i class="fas fa-star empty-star"></i>
    </span>
    <span class="star">
      <i class="fas fa-star empty-star"></i>
    </span>
    <span class="star">
      <i class="fas fa-star empty-star"></i>
    </span>
    <span class="star">
      <i class="fas fa-star empty-star"></i>
    </span>
  </span>
  <span class="filled-stars" [style]="'width:' + ratingScore*20+'%;'" >
    <span class="star">
      <i class="fas fa-star"></i>
    </span>
    <span class="star">
      <i class="fas fa-star"></i>
    </span>
    <span class="star">
      <i class="fas fa-star"></i>
    </span>
    <span class="star">
      <i class="fas fa-star"></i>
    </span>
    <span class="star">
      <i class="fas fa-star"></i>
    </span>
  </span>
</div>
</div>
