import { EarningService } from './../../shared/services/recruiter/earning.service';
import { ReviewService } from './../../shared/services/recruiter/review.service';
import {Component, OnInit} from '@angular/core';
import {CreditsModel} from 'src/app/shared/models';
import {CandidateCountService} from 'src/app/shared/services/candidate';
import {CreditService} from 'src/app/shared/services/recruiter';

@Component({
  selector: 'app-talentbase-credits',
  templateUrl: './talentbase-credits.component.html',
  styleUrls: ['./talentbase-credits.component.scss']
})
export class TalentbaseCreditsComponent implements OnInit {

  credits: CreditsModel[] = [];
  totalCredits: number | null = 0;
  spentCredits: number | null = 0;
  applicationQuotaUsed: number;
  applicationQuotaUsedPercent: number;
  applicationQuotaRemaining: number;
  activeTab = 0;
  reviews: any[] = [];
  earnings: any[] = [];

  constructor(
    private candidateCountService: CandidateCountService,
    private creditService: CreditService,
    private reviewService: ReviewService,
    private earningService: EarningService) {
  }

  ngOnInit() {
     this.getCredits();
     this.getCounts();
  }
  getCounts() {
    this.candidateCountService.ServerGet().subscribe(x => {
      this.applicationQuotaUsed = x.applicationQuotaUsed;
      this.applicationQuotaRemaining = x.applicationQuotaRemaining;
      this.applicationQuotaUsedPercent = x.applicationQuotaUsed / (x.applicationQuotaUsed + x.applicationQuotaRemaining) * 100;
    });
  }

  getCredits() {
    this.reviewService.ServerGet().subscribe(a=>{
      this.reviews = a.content;
      let total1 =  this.reviews.reduce((a, b) => a + b.earnedScore, 0);
      this.earningService.ServerGet().subscribe(a=>{
        this.earnings = a.content;
        let total2 =  this.earnings.reduce((a, b) => a + b.earnedScore, 0);

        this.totalCredits = total1 + total2;
      });
    });




    // this.creditService.ServerGet().subscribe(x => {
    //   this.credits = x.content;
    //   this.credits.forEach(f => f.date = new Date(f.createdAt).toLocaleDateString());
    //   this.totalCredits = this.credits.reduce((a, b) => a + b.amount, 0);
    //   let spent = this.credits.filter(f => f.amount < 0).reduce((a, b) => a + b.amount, 0);
    //   let beforeSpent = this.credits.filter(f => f.amount > 0).reduce((a, b) => a + b.amount, 0);
    //   this.spentCredits = 100 * Math.abs(spent) / beforeSpent;
    // });
  }

  openModal() {
    // @ts-ignore
    $('#modalMarketPurchase').modal('show');
  }


}
